import { COMMON_NAME_MAP } from "../../configs/constants";

export const convertToFrontFieldsMap = (data) => {
  let fields = {};
  const commonMap = {};

  for (const key in data) {
    if (data[key].visible) {
      if (COMMON_NAME_MAP[key]) {
        const commonKey = COMMON_NAME_MAP[key];
        if (!commonMap[commonKey]) {
          commonMap[commonKey] = {
            ...data[key],
            common: [],
          };
        }
        commonMap[commonKey].common.push(key);
      } else {
        fields[key] = {
          ...data[key],
          visible: key !== "serial_number",
          required: key === "serial_number" ? false : data[key]?.required,
        };
      }
      if (key === "assign_to") {
        fields[key] = {
          ...fields[key],
          common: ["technicians", "teams"],
        };
      }
    }
  }

  for (const key in commonMap) {
    fields[key] = commonMap[key];
  }

  //remove qr code from clone fields
  const { qr_code, ...fieldsData } = fields;

  fields = {
    images_and_files: {
      visible: true,
      required: false,
      common: ["images", "documents"],
    },
    description: {
      visible: true,
      required: false,
    },
    ...fieldsData,
  };

  return fields;
};
