import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ContextMenuEntry } from "shared/components/ContextMenu";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

export const StatusUpdate = ({ id, status, onUpdate }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const newStatus = status ? "inactive" : "active";

  const onUpdateVendorStatus = () => {
    onUpdate(id)
      .unwrap()
      .then(({ data }) => {
        addSnackBar({
          content: t("vendors.updateStatusConfirmation.success", {
            name: data.name,
            newStatus,
          }),
          type: "success",
        });
      })
      .catch(({ message }) => {
        addSnackBar({
          title: t("vendors.updateStatusConfirmation.fail", {
            newStatus,
          }),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  return (
    <ContextMenuEntry
      onClick={onUpdateVendorStatus}
      confirmation={{
        title: t(`vendors.updateStatusConfirmation.${newStatus}.title`),
        description: t(
          `vendors.updateStatusConfirmation.${newStatus}.description`
        ),
        confirmButtonLabel: t(
          `vendors.updateStatusConfirmation.${newStatus}.confirm`
        ),
      }}
    >
      <Title>
        {t("vendors.changeStatus", {
          newStatus,
        })}
      </Title>
    </ContextMenuEntry>
  );
};

StatusUpdate.propTypes = {
  status: PropTypes.bool,
  onUpdate: PropTypes.func,
  id: PropTypes.number,
};

const Title = styled.div`
  text-transform: capitalize;
`;
