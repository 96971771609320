import { getUser } from "shared/components/Auth";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetUserHashQuery,
  useGetUserQuery,
} from "modules/users/state/usersApi";
import { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";

const intercomID = import.meta.env.VITE_INTERCOM_ID;

/** Sets up the Intercom chat widget */
export const IntercomProvider = () => {
  const user = getUser() ?? {};
  const { data: hashData } = useGetUserHashQuery(user?.id ?? skipToken);
  const { data: userData } = useGetUserQuery(user?.id ?? skipToken);

  useEffect(() => {
    if (intercomID && hashData && userData) {
      Intercom({
        api_base: "https://api-iam.intercom.io",
        app_id: intercomID,
        email: userData.email,
        name: `${userData.name} ${userData.surname}`,
        user_hash: hashData.data,
      });
    }
  }, [userData, hashData]);

  return null;
};
