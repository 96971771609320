import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { getStatusIcon } from "modules/downtimeTracking";

export const Tabs = ({
  tabIndex,
  setTabIndex,
  currentStatus,
  downtimeTrackingEnabled,
}) => {
  const { t } = useTranslation();

  const statusIcon = useMemo(
    () => getStatusIcon(currentStatus, t, "16px"),
    [currentStatus, t]
  );

  const tabs = [
    { label: t("assets.tabs.details") },
    ...(downtimeTrackingEnabled
      ? [
          {
            label: (
              <StatusTitle>
                <span>{t("assets.tabs.status")}</span>
                {statusIcon}
              </StatusTitle>
            ),
          },
        ]
      : []),
  ];

  if (!tabs?.length) {
    return null;
  }

  return (
    <Container>
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={index}
            active={tabIndex === index}
            onClick={() => setTabIndex(index)}
          >
            <div>{tab?.label}</div>
          </Tab>
        );
      })}
    </Container>
  );
};

Tabs.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      created_at: PropTypes.string,
      id: PropTypes.number,
      target_id: PropTypes.number,
      target_type: PropTypes.string,
    })
  ),
  downtimeTrackingEnabled: PropTypes.bool,
};

const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Tab = styled.button`
  position: relative;
  height: 100%;
  padding: 0px 25px;
  font-size: 14px;
  color: var(--color_text_color);

  ${({ active }) =>
    active &&
    css`
      font-weight: 600;
      border: none;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -12px;
        width: 100%;
        height: 2px;
        background-color: var(--color_caribbean_green);
      }
    `}

  &:not(:first-child) {
    padding: 0 46px;
  }
`;

const Container = styled.div`
  padding-right: 20px;
`;
