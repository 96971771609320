import { useTranslation } from "react-i18next";
import { useModalControl } from "shared/lib/hooks";

import { AdminSettingsFormHeader } from "../AdminSettingsFormHeader";
import { List } from "./List";
import { AddReferenceModal } from "./Add";
import { EditReferenceModal } from "./Edit";

import { TablePageLayout } from "../TablePageLayout";
import { useNavigate, Routes, Route } from "react-router-dom";
import { NotAllowed } from "shared/components/NotAllowed";

export const AdministrationReference = ({ actions, module, hasPermission }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getList, addElement, getElement, updateElement, deleteElement } =
    actions;

  const [handleOpenModal, handleCloseModal] = useModalControl(
    `admin-settings/manage-modules/${module}`
  );

  const openDetailsHandler = (id) =>
    id ? handleOpenModal(`${id}/edit`) : handleCloseModal();

  return (
    <TablePageLayout>
      <AdminSettingsFormHeader
        title={t(`settings.referenceLists.${module}.title`)}
        onClose={() => navigate("..")}
        type="table"
        module={module}
      />
      {hasPermission ? (
        <List
          getDataQuery={getList}
          openDetailsHandler={openDetailsHandler}
          module={module}
        />
      ) : (
        <NotAllowed linkTo="/admin-settings" />
      )}
      {/* Modal routes */}
      <Routes>
        <Route
          path="add"
          element={
            <AddReferenceModal
              isShow={hasPermission}
              module={module}
              onClose={handleCloseModal}
              addElement={addElement}
            />
          }
        />
        <Route
          path=":id/edit"
          element={
            <EditReferenceModal
              isShow={hasPermission}
              module={module}
              onClose={handleCloseModal}
              getElement={getElement}
              updateElement={updateElement}
              deleteElement={deleteElement}
            />
          }
        />
      </Routes>
    </TablePageLayout>
  );
};
