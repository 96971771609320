import React from "react";
import styled from "styled-components";

import ImageIcon from "shared/assets/icon/image.svg?react";
import { Button } from "shared/components/Button";
import { ProgressBar } from "shared/components/ProgressBar";

export const PendingStatus = ({ name, progress, onStop }) => {
  return (
    <PendingContainer>
      <ImageAction isDark>
        <ImageIcon />
      </ImageAction>
      <ImageProgressBar name={name} value={progress} />
      <Button onClick={onStop} variant="secondary" size="small">
        Cancel
      </Button>
    </PendingContainer>
  );
};

export const PendingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
`;

export const ImageAction = styled.div`
  color: var(--color_background);
  text-align: center;
  height: 21px;
  & > svg {
    width: 21px;
    height: 21px;
  }
`;

export const ImageProgressBar = styled(ProgressBar)`
  margin: 18px 0 8px;
`;
