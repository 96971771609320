import styled from "styled-components";
import { H2 } from "../Typography";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
`;

export const IconBox = styled.div`
  margin-bottom: ${({ spacious }) => (spacious ? "42px" : "22px")};
  color: var(--color_empty_icon);
`;

export const Heading = styled(H2)`
  width: 414px;
  font-size: 23px;
  line-height: 26px;
  font-weight: 600;
  color: var(--color_empty_heading);
  margin-bottom: 10px;
`;

export const Description = styled.span`
  width: 257px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color_empty_description);

  a {
    color: var(--color_empty_description_link);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;
