import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { NoInternetModal } from "widgets/NoInternetModal/NoInternetModal";

export const NoInternetProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      {!isOnline &&
        createPortal(<NoInternetModal isShow={true} />, document.body)}
      {children}
    </>
  );
};
