import { dropUser } from "../components/Auth";

const portalPathRegex = /\/portal\/\w+/;

export const handlePermissionsMiddleware = (store) => (next) => (action) => {
  const { pathname } = window.location;

  if (
    (action.payload?.status === 403 &&
      action.payload?.data?.message === "No Access" &&
      !portalPathRegex.test(pathname)) ||
    (action.payload?.status === 422 &&
      action.payload?.data?.message === "Invalid entity ID" &&
      !portalPathRegex.test(pathname))
  ) {
    dropUser();
    window.location.href = "/login/permission-denied";
  } else {
    return next(action);
  }
};
