import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ManualDowntimeButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-top: 20px;
`;

export const ReliabilityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color_grey100);
`;
