import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "widgets/Header";
import { EntityUsersList } from "modules/users";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { Container } from "shared/components/Container";
import { usePermission } from "app/providers/PermissionsProvider";
import { ModalLink } from "shared/components/ModalLink";
import { useSetModal } from "widgets/ModalController";

export const List = () => {
  const { t } = useTranslation();
  const { usersListViewPermit, usersDetailsInvitePermit } = usePermission();
  const setModal = useSetModal();

  return (
    <TablePageLayout>
      <Header
        title={t("allUsers.users")}
        searchPlaceholder={t("allUsers.searchPlaceholder")}
        action={
          usersDetailsInvitePermit && (
            <ModalLink
              type="button"
              icon={<AddIcon />}
              modalParams={{ type: "entityUser", id: "new" }}
            >
              {t("allUsers.addNewUser")}
            </ModalLink>
          )
        }
        horizontallySpaciousPage
      />
      {usersListViewPermit && (
        <Container>
          <EntityUsersList
            openUserDetailsHandler={(id) =>
              id ? setModal({ type: "entityUser", id }) : setModal()
            }
          />
        </Container>
      )}
    </TablePageLayout>
  );
};
