import styled from "styled-components";
import { useState } from "react";
import { Pie, PieChart, Sector, SectorProps, Cell } from "recharts";
import { ChartLoadingAnimation } from "modules/reporting/components/charts/ChartLoadingAnimation";
import { formatPercent } from "modules/reporting/lib/formatters";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

/** Props that are shared between the WorkOrdersKPIsDonutChart and DonutChartSkeleton */
const pieProps = {
  startAngle: 450,
  endAngle: 90,
  innerRadius: "40%",
  outerRadius: "100%",
  stroke: "none",
  isAnimationActive: false,
};
/** The amount (in pixels) to increase the outerRadius of the active slice by */
const expandActiveBy = 2;
const margin = expandActiveBy + 1;
const chartSize = 110;

interface WorkOrdersKPIsDonutChartProps {
  label: string;
  data: SliceData[];
  isLoading?: boolean;
  sliceConfigs: Record<string, SliceConfig>;
}

interface SliceData {
  key: string;
  value_percentage: number;
}

interface SliceConfig {
  fill?: string;
  label?: string;
}

interface ActiveSlice extends SliceData {
  index: number;
}

export const WorkOrdersKPIsDonutChart = ({
  label,
  data,
  isLoading,
  sliceConfigs,
}: WorkOrdersKPIsDonutChartProps) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [active, setActive] = useState<ActiveSlice>();

  if (isLoading) {
    return <DonutChartSkeleton />;
  }

  return (
    <Wrapper>
      <PieChart
        width={chartSize}
        height={chartSize}
        margin={{
          top: margin,
          bottom: margin,
          left: margin,
          right: margin,
        }}
      >
        <Pie
          {...pieProps}
          data={data}
          dataKey="value_percentage"
          nameKey="name"
          onMouseOver={(data, index) =>
            setActive({
              ...data,
              index,
            })
          }
          onMouseLeave={() => setActive(undefined)}
          activeIndex={active?.index}
          activeShape={<ActiveShape />}
        >
          {data?.map((entry, index) => {
            const config = sliceConfigs[entry.key] ?? { fill: "#BFBFC3" };
            return <Cell key={`cell-${index}`} fill={config.fill} />;
          })}
        </Pie>
      </PieChart>
      {active ? (
        <Label>
          {sliceConfigs[active.key]?.label ?? ""}:{" "}
          {formatPercent(active.value_percentage, language)}
        </Label>
      ) : (
        <Label>{label}</Label>
      )}
    </Wrapper>
  );
};

/** Makes the active (hovered) sector slightly larger */
const ActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}: SectorProps) => {
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius ? outerRadius + expandActiveBy : undefined}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};

const DonutChartSkeleton = () => {
  return (
    <Wrapper>
      <PieChart
        width={chartSize}
        height={chartSize}
        margin={{
          top: margin,
          bottom: margin,
          left: margin,
          right: margin,
        }}
      >
        <Pie
          {...pieProps}
          dataKey="value"
          data={[{ value: 1, name: "" }]}
          activeIndex={0}
          activeShape={<LoadingShape />}
        />
      </PieChart>
      <Label>
        <Skeleton width="14ch" />
      </Label>
    </Wrapper>
  );
};

const LoadingShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
}: SectorProps) => (
  <ChartLoadingAnimation>
    <Sector {...{ cx, cy, innerRadius, startAngle, endAngle, outerRadius }} />
  </ChartLoadingAnimation>
);

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
`;

const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
`;
