import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Columns } from "./Columns";

export const CloneConfiguration = ({
  rows = [],
  handleSetValue,
  values,
  entity,
}) => {
  const { t } = useTranslation();

  const onSwitch = (field) => {
    handleSetValue(field, {
      ...values[field],
      visible: !values[field]?.visible,
    });
  };

  return (
    <Wrapper>
      <Title>{t(`${entity}.clone.description`)}</Title>
      <Columns rows={rows} onSwitch={onSwitch} />
    </Wrapper>
  );
};

CloneConfiguration.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool,
      disabled: PropTypes.bool,
      name: PropTypes.string,
      field: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  handleSetValue: PropTypes.func,
  values: PropTypes.object,
};

const Wrapper = styled.div`
  padding-top: 32px;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: var(--color_text_color);
  margin-bottom: 20px;
`;
