import React from "react";
import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";
import CloseIcon from "shared/assets/icon/close.svg?react";

export const CloseButton = (props) => {
  return (
    <IconButtonStyled {...props}>
      <CloseIcon />
    </IconButtonStyled>
  );
};

const IconButtonStyled = styled(IconButton).attrs({
  variant: "primary",
  background: "dark",
})`
  width: 24px;
  height: 24px;
`;
