import {
  IProcedureTemplate,
  IProcedureTemplateItem,
} from "modules/procedures/types";
import { Item } from "../TemplateItems/Item";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableItem } from "./DraggableItem";
import { useCallback, useEffect, useMemo, useState } from "react";
import update from "immutability-helper";
import styled from "styled-components";
import {
  useDestroyProcedureTemplateItemMutation,
  useGetItemTypesQuery,
  useUpdateProcedureTemplateItemMutation,
} from "modules/procedures/state/proceduresApi";
import { Button } from "shared/components/Button";
import { TemplateItemCreateModal } from "../../ProcedureTemplateItemModal/TemplateItemCreateModal";
import { TemplateItemEditModal } from "../../ProcedureTemplateItemModal/TemplateItemEditModal";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import { usePermission } from "app/providers/PermissionsProvider";

interface ItemEditorProps {
  procedureTemplate: IProcedureTemplate;
  onUpdate: () => void;
  disabled: boolean;
}

export const ItemEditor = ({
  procedureTemplate,
  onUpdate,
  disabled,
}: ItemEditorProps) => {
  const captureError = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  // State
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState<IProcedureTemplateItem | null>(null);
  const [cards, setCards] = useState<IProcedureTemplateItem[]>(
    procedureTemplate?.items
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const { procedureTemplateItemDeletePermit, procedureTemplateEditPermit } =
    usePermission();

  // Queries & mutations
  const { data: itemTypes } = useGetItemTypesQuery(null);
  const [updateItem] = useUpdateProcedureTemplateItemMutation();
  const [destroyItem] = useDestroyProcedureTemplateItemMutation();

  useEffect(() => {
    setCards(procedureTemplate?.items);
  }, [procedureTemplate?.items]);

  const handleDrop = useCallback(
    (item: IProcedureTemplateItem, newIndex: number) => {
      setIsUpdating(true);
      const i = cards.find((card) => card.id === item.id);
      updateItem({ id: i?.id, body: { ...i, index: newIndex + 1 } })
        .unwrap()
        .then(() => onUpdate())
        .catch((e) =>
          captureError(e, "Error updating item index", { item, newIndex })
        )
        .finally(() => setIsUpdating(false));
    },
    [captureError, cards, onUpdate, updateItem]
  );

  const moveCard = useCallback(
    (_item: IProcedureTemplateItem, dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: IProcedureTemplateItem[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as IProcedureTemplateItem],
          ],
        })
      );
    },
    []
  );

  const onItemDestroy = async (itemId: string | number) => {
    try {
      await destroyItem(itemId).unwrap();
    } catch (e) {
      captureError(e, "Error destroying item", { itemId });
    }
  };

  const onEditItem = (item: IProcedureTemplateItem) => {
    setEditItem(item);
    setShowEditModal(true);
  };

  const onCloseEdit = () => {
    setEditItem(null);
    setShowEditModal(false);
  };

  const showDelete = useMemo(() => {
    return (
      procedureTemplateItemDeletePermit as (
        createdById: string | number
      ) => boolean
    )(procedureTemplate?.created_by_id);
  }, [procedureTemplate?.created_by_id, procedureTemplateItemDeletePermit]);

  const showEdit = useMemo(() => {
    return (
      procedureTemplateEditPermit as (createdById: string | number) => boolean
    )(procedureTemplate?.created_by_id);
  }, [procedureTemplate?.created_by_id, procedureTemplateEditPermit]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <ItemsContainer>
          {cards?.map((item, i) => (
            <DraggableItem
              item={item}
              key={item.id}
              moveCard={moveCard}
              index={i}
              onDrop={handleDrop}
              dragDisabled={disabled || isUpdating}
            >
              <ItemContainer>
                <Item
                  item={item}
                  itemTypes={itemTypes as { [key: string]: string }}
                />
                <Actions>
                  {showEdit && (
                    <Button
                      variant="secondary"
                      onClick={() => onEditItem(item)}
                      style={{ flexGrow: "1" }}
                    >
                      Edit
                    </Button>
                  )}
                  {showDelete && (
                    <Button
                      variant="dangerTransparent"
                      onClick={() => onItemDestroy(item.id)}
                      style={{ flexGrow: "1" }}
                    >
                      Delete
                    </Button>
                  )}
                </Actions>
              </ItemContainer>
            </DraggableItem>
          ))}
          <Button onClick={() => setShowCreateModal(true)}>New item</Button>
        </ItemsContainer>
      </DndProvider>
      <TemplateItemCreateModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        procedureTemplate={procedureTemplate}
      />
      <TemplateItemEditModal
        isOpen={showEditModal}
        onClose={onCloseEdit}
        procedureTemplateItem={editItem || ({} as IProcedureTemplateItem)}
      />
    </>
  );
};

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-around;
`;
