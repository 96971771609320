import styled from "styled-components";
import PropTypes from "prop-types";
import CommentIcon from "shared/assets/icon/comment.svg?react";

export const Comment = ({ value = "" }) => {
  return (
    <>
      {value && (
        <Container>
          <CommentIcon />
          {value}
        </Container>
      )}
    </>
  );
};

Comment.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--color_table_text_color);
`;
