import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";
import EditIcon from "shared/assets/icon/edit.svg?react";
import { IconButton } from "shared/components/IconButton";
import { Input } from "shared/components/Input";

import { commentValidationSchema } from "../../configs/validationSchema";

export const CommentUpdate = ({ onEdit, data, type }) => {
  const { t } = useTranslation();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const { id, content, target_id } = data;

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const cancelHandler = () => {
    editCommentForm.resetForm();
    setIsConfirmationVisible(false);
  };

  const editCommentForm = useForm({
    initialValues: { comment: content },
    validationSchema: commentValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async ({ comment }) => {
      onEdit({ id, target_id, comment, type }).then(() =>
        setIsConfirmationVisible(false)
      );
    },
  });

  return (
    <>
      <StyledIconButton onClick={clickHandler} className="actionIcon">
        <EditIcon />
      </StyledIconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t("work_orders.comments.edit")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          form={editCommentForm}
          disabledSubmit={
            !editCommentForm.dirty ||
            !!Object.values(editCommentForm.errors).length
          }
          description={
            <Box>
              <Label>{t("work_orders.comments.title")}</Label>
              <CommentInput
                name="comment"
                value={editCommentForm.values.comment}
                onChange={editCommentForm.handleChange}
                onBlur={editCommentForm.handleBlur}
                placeholder={t("work_orders.comments.placeholder")}
                errorMessage={
                  editCommentForm.touched.comment &&
                  editCommentForm.errors.comment
                }
                isTextArea
              />
            </Box>
          }
          onProceed={editCommentForm.handleSubmit}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

CommentUpdate.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.number,
    target_id: PropTypes.number,
    target_type: PropTypes.string,
  }),
  onEdit: PropTypes.func,
};

export const StyledIconButton = styled(IconButton)`
  display: none;
  top: 16px;
  right: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 8px;
`;

const Box = styled.div`
  padding: 24px 0;
`;

const CommentInput = styled(Input)`
  max-height: 50vh;
`;
