import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdminSettingsFormHeader } from "../../AdminSettingsFormHeader";
import { Card } from "./Card";

import styled from "styled-components";

const PermissionsMap = (t) => [
  {
    id: 0,
    title: t("permissions.list.admin.title"),
    description: t("permissions.list.admin.description"),
    permissions: Array(7)
      .fill(1)
      .map((_, index) => ({
        id: index,
        access: true,
        text: t(`permissions.list.admin.option${index}`),
      })),
  },
  {
    id: 1,
    title: t("permissions.list.limitedAdmin.title"),
    description: t("permissions.list.limitedAdmin.description"),
    permissions: Array(6)
      .fill(1)
      .map((_, index) => ({
        id: index,
        access: index === 1 ? false : true,
        text: t(`permissions.list.limitedAdmin.option${index}`),
      })),
  },
  {
    id: 2,
    title: t("permissions.list.technician.title"),
    description: t("permissions.list.technician.description"),
    permissions: Array(8)
      .fill(1)
      .map((_, index) => ({
        id: index,
        access: index === 6 || index === 7 ? false : true,
        text: t(`permissions.list.technician.option${index}`),
      })),
  },
  {
    id: 3,
    title: t("permissions.list.requester.title"),
    description: t("permissions.list.requester.description"),
    permissions: Array(3)
      .fill(1)
      .map((_, index) => ({
        id: index,
        access: index === 1 || index === 2 ? false : true,
        text: t(`permissions.list.requester.option${index}`),
      })),
  },
];

export const ViewPermissions = () => {
  const { t } = useTranslation();
  const permissions = PermissionsMap(t);
  const navigate = useNavigate();

  return (
    <>
      <AdminSettingsFormHeader
        title={t("permissions.viewPermissionsTitle")}
        type="view"
        onClose={() => navigate("/admin-settings")}
      />
      <Container>
        {permissions.map(({ id, ...permission }) => (
          <Card key={id} permission={permission} />
        ))}
      </Container>
    </>
  );
};

const Container = styled.div`
  height: calc(100vh - 113px);
  width: 900px;
  margin: 32px auto 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
