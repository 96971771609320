import { useTranslation } from "react-i18next";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import {
  formatPercent,
  formatUnitDuration,
} from "modules/reporting/lib/formatters";
import {
  Separator,
  SummaryItemWrapper,
  SummaryWrapper,
} from "./ReliabilityHistoryChart.styled";
import { Bar } from "./types";

interface SummaryProps {
  bars: Bar[];
  totalTime: number;
}

/** Online/Offline totals above the ReliabilityHistoryChart */
export const ReliabilitySummary = ({ bars, totalTime }: SummaryProps) => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();

  let online = 0;
  let offline = 0;
  bars.forEach((bar) => {
    if (bar.status === "online") {
      online += bar.duration;
    } else if (bar.status === "offline") {
      offline += bar.duration;
    }
  });

  return (
    <SummaryWrapper>
      <SummaryItem
        color={"var(--color_lime)"}
        label={t("assets.reliability.online")}
        time={online}
        pctTime={online / totalTime}
        language={language}
      />
      <Separator />
      <SummaryItem
        color={"var(--color_red)"}
        label={t("assets.reliability.offline")}
        time={offline}
        pctTime={offline / totalTime}
        language={language}
      />
    </SummaryWrapper>
  );
};

interface SummaryItemProps {
  color: string;
  label: string;
  time: number;
  pctTime: number;
  language: string;
}

const SummaryItem = ({
  color,
  label,
  time,
  pctTime,
  language,
}: SummaryItemProps) => (
  <SummaryItemWrapper color={color}>
    <span>
      {label}{" "}
      {formatUnitDuration(time / 1000, "hours", language, {
        maximumFractionDigits: 0,
        unitDisplay: "narrow",
      })}
    </span>
    <span>{formatPercent(pctTime, language)}</span>
  </SummaryItemWrapper>
);
