import { getFormDataFromJSON } from "shared/lib/helpers";
import { OtherCostsCreateInitialValues } from "../configs/constants";

export const getInitialValues = ({ type, data }) => {
  if (type === "create" || !data) {
    return OtherCostsCreateInitialValues;
  }

  const {
    title = "",
    note = "",
    vendor,
    cost = "",
    images = [],
    documents = [],
    uploads_attributes,
  } = data;

  const workOrderEditForm = {
    title,
    note: note ?? "",
    cost,
    vendor: vendor?.id ? { value: vendor.id, label: vendor.name } : vendor,
    uploads_attributes: uploads_attributes
      ? uploads_attributes
      : images.map((image) => ({ ...image, isImage: true })).concat(documents),
  };

  return workOrderEditForm;
};

export const getOtherCostFormatForService = (form) => {
  const { uploads_attributes, vendor, ...otherFields } = form;

  const data = getFormDataFromJSON({
    ...otherFields,
    vendor_id: vendor?.value || "",
  });

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      data.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj.file) {
      data.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    }
  });

  return data;
};
