import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";

import { useDisplayDate } from "shared/lib/hooks";
import { getDatesData } from "shared/lib/helpers/workOrderChart";

import { CustomLegend } from "modules/reporting/components/charts/CustomLegend";
import { CustomTooltip } from "modules/reporting/components/charts/CustomTooltip";
import { useGetWorkOrdersQuery } from "modules/workOrders";

export const WOListChart = ({ id, currentTimePeriod, attribute = "" }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const { currentData: WOData = { data: [] }, isLoading } =
    useGetWorkOrdersQuery(
      {
        format_resource: "light",
        sort: "created_at asc",
        per_page: 10000,
        filter: {
          [attribute]: id,
          created_at_gt: currentTimePeriod?.startDate,
          created_at_lt: currentTimePeriod?.endDate,
        },
      },
      { skip: !id }
    );

  const { currentData: CompletedWOData = { data: [] }, isCompletedLoading } =
    useGetWorkOrdersQuery(
      {
        format_resource: "light",
        sort: "created_at asc",
        per_page: 10000,
        filter: {
          [attribute]: id,
          completed_at_gt: currentTimePeriod?.startDate,
          completed_at_lt: currentTimePeriod?.endDate,
        },
      },
      { skip: !id }
    );

  const transformedData = useMemo(
    () =>
      getDatesData(
        currentTimePeriod?.startDate,
        currentTimePeriod?.endDate,
        WOData.data,
        CompletedWOData.data,
        displayDate
      ),
    [WOData, CompletedWOData, currentTimePeriod, displayDate]
  );

  if (isLoading || isCompletedLoading) return;

  return (
    <Box>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={transformedData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="2 2"
            vertical={false}
            color={"var(--color_grey200)"}
          />
          <XAxis
            dataKey="date"
            tick={{ fill: "var(--color_grey900)", fontSize: 12 }}
            tickSize={3}
            tickMargin={4}
            minTickGap={5}
            tickLine={{ stroke: "var(--color_grey200)" }}
            axisLine={{ stroke: "var(--color_grey200)" }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: "var(--color_grey500)", fontSize: 12 }}
            tickMargin={15}
            allowDecimals={false}
          />
          <Tooltip cursor={{ opacity: 0.2 }} content={<CustomTooltip />} />
          <Bar
            dataKey="createdCount"
            name={t(
              `preventive_maintenances.workOrderHistory.chart.createdCount`
            )}
            fill={"var(--color_primary_dark)"}
            isAnimationActive={false}
          />
          <Bar
            dataKey="completedCount"
            name={t(
              `preventive_maintenances.workOrderHistory.chart.completedCount`
            )}
            isAnimationActive={false}
            fill={"var(--color_dark_blue)"}
          />
          <Legend content={<CustomLegend />} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const Box = styled.div`
  margin-bottom: 32px;
`;
