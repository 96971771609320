import React from "react";
import { Button } from "shared/components/Button";
import { ContextMenu } from "shared/components/ContextMenu";
import PropTypes from "prop-types";

export const HeaderContextMenu = ({ className, disabled, children }) => {
  return (
    <ContextMenu
      disabled={disabled}
      buttonSlot={
        <Button className={className} variant="secondary" disabled={disabled}>
          ...
        </Button>
      }
    >
      {children}
    </ContextMenu>
  );
};

HeaderContextMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
