import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AdminFieldsConfiguration,
  useAddAdminConfigurationMutation,
  useListRows,
} from "modules/adminFieldsConfiguration";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useForm } from "shared/lib/hooks/useForm";
import { AdminSettingsFormHeader } from "shared/components/AdminSettingsFormHeader";
import { NotAllowed } from "shared/components/NotAllowed";
import styled from "styled-components";

export const FieldsAdminSettings = ({
  initialConfiguration,
  type = "",
  additionalField = "",
  additionalType = "",
  updateAddFieldConfiguration = null,
  withDescription,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addSnackBar } = useSnackBar();
  const [addAdminConfiguration] = useAddAdminConfigurationMutation();

  const addForm = useForm({
    initialValues: initialConfiguration,
    enableReinitialize: true,
    onSubmit: async () => {
      if (additionalField) {
        updateAddFieldConfiguration
          ? updateAddFieldConfiguration({ [additionalField]: field })
          : addAdminConfiguration({
              body: { payload: { [additionalField]: field } },
              params: { type: additionalType },
            });
      }
      addAdminConfiguration({
        body: { payload: values },
        params: { type },
      })
        .unwrap()
        .then(() => navigate(-1))
        .catch(({ message }) => {
          addSnackBar({
            title: t("settings.failed"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
        });
    },
  });

  const { [additionalField]: field, ...values } = addForm.values;
  const rows = useListRows(values, type);

  const closeHandler = () => navigate("..");

  return (
    <Wrapper>
      <AdminSettingsFormHeader
        title={t(`${type}s.title`)}
        onSubmit={addForm.handleSubmit}
        onCancel={closeHandler}
        onClose={closeHandler}
        form={addForm}
        type="form"
      />
      {hasPermission ? (
        <AdminFieldsConfiguration
          rows={rows}
          handleSetValue={addForm.handleSetValue}
          values={addForm.values}
          module={t(`${type}s.title`).toLocaleLowerCase()}
          additionalField={additionalField}
          additionalType={additionalType}
          withDescription={withDescription}
        />
      ) : (
        <NotAllowed linkTo="/admin-settings" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
