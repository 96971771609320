import { t } from "i18next";

interface URLAttribute {
  label?: string;
  url?: string;
}

/**
 * Performs additional validation on `urls_attributes` lists for
 *  vendors/assets/parts, and sends error messages to Formik.
 *
 * These forms use `useFormik`, so `FieldArray` isn't an option. That means
 *   the inputs always count as "touched", and using Yup for validation would
 *   show an error message on each input immediately when the URL box is added.
 * That makes for a very annoying UX, so we're doing the additional validation
 *   on save instead.
 */
export function validateURLsAttributesList(
  list: URLAttribute[],
  /** Formik [setFieldError](https://formik.org/docs/api/formik#setfielderror-field-string-errormsg-string--void) function */
  setFieldError: (field: string, errorMsg: string) => void
): boolean {
  let isValid = true;
  list.forEach((item, index) => {
    if (!item.label?.length) {
      isValid = false;
      setFieldError(
        `urls_attributes[${index}].label`,
        t("validation.required")
      );
    }
    if (!item.url?.length) {
      isValid = false;
      setFieldError(`urls_attributes[${index}].url`, t("validation.required"));
    }
  });
  const firstDupe = firstDuplicate(list);
  if (firstDupe !== -1) {
    isValid = false;
    setFieldError(
      `urls_attributes[${firstDupe}].url`,
      t("validation.uniqueness")
    );
  }
  return isValid;
}

const firstDuplicate = (list: URLAttribute[]) => {
  const urls = list.map((item) => item.url);
  return urls.findIndex((item, index) => urls.indexOf(item) !== index);
};
