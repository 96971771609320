import styled from "styled-components";
import { Badge } from "shared/components/Badge";
import { NotTrackingIcon, OfflineIcon, OnlineIcon } from "modules/assets/index";
import { useTranslation } from "react-i18next";

interface AssetStatusBadgeProps {
  className?: string;
  status: "online" | "offline" | "not_tracking";
}

export const AssetStatusBadge = ({
  className,
  status,
}: AssetStatusBadgeProps) => {
  const { t } = useTranslation();

  switch (status) {
    case "online":
      return (
        <OnlineBadge className={className} iconNode={<OnlineIcon />}>
          {t(`assets.statuses.${status}`)}
        </OnlineBadge>
      );
    case "offline":
      return (
        <OfflineBadge className={className} iconNode={<OfflineIcon />}>
          {t(`assets.statuses.${status}`)}
        </OfflineBadge>
      );
    case "not_tracking":
      return (
        <StyledBadge className={className} iconNode={<NotTrackingIcon />}>
          {t(`assets.statuses.${status}`)}
        </StyledBadge>
      );
    default:
      return null;
  }
};

const StyledBadge = styled(Badge)`
  & span {
    display: block;
    white-space: nowrap;
  }
`;

const OnlineBadge = styled(StyledBadge)`
  background-color: var(--color_light_freshman);
`;

const OfflineBadge = styled(StyledBadge)`
  background-color: var(--color_shy_girl);
`;
