import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";

import { formatNumber } from "modules/reporting/lib/formatters";
import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { BarChart } from "modules/reporting/components/charts/BarChart";
import { OntimeVsOverdueChartData } from "./types";
import { bucketData } from "modules/reporting/lib/bucketData";

interface OntimeVsOverdueReportChartProps {
  bucket: ReportBucket;
  data?: OntimeVsOverdueChartData[];
  isFetching: boolean;
}

const reportKey = ReportKey.OntimeVsOverdue;
export const OntimeVsOverdueReportChart = ({
  bucket,
  data,
  isFetching,
}: OntimeVsOverdueReportChartProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  const chartData = bucketData(data ?? [], bucket, language, (points) =>
    points.reduce(
      (bucket, p) => {
        bucket.overdue += p.overdue;
        bucket.on_time += p.on_time;
        bucket.no_due_date += p.no_due_date;
        return bucket;
      },
      { overdue: 0, on_time: 0, no_due_date: 0 }
    )
  );
  return (
    <BarChart
      isLoading={isFetching}
      data={chartData}
      dataAxisKey="date"
      valueFormatter={(val) => formatNumber(val, language)}
      bars={[
        {
          dataKey: "on_time",
          name: t(`reporting.${reportKey}.labels.on_time`) as string,
          fill: "var(--color_mint)",
          stackId: "a",
        },
        {
          dataKey: "overdue",
          name: t(`reporting.${reportKey}.labels.overdue`) as string,
          fill: "var(--color_salmon)",
          stackId: "a",
        },
        {
          dataKey: "no_due_date",
          name: t(`reporting.${reportKey}.labels.no_date`) as string,
          fill: "var(--color_light_grey)",
          stackId: "a",
        },
      ]}
    />
  );
};
