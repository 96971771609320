import styled from "styled-components";
import { H1, Description } from "shared/components/Typography";
import { Link } from "shared/components/Link";
import { useTranslation } from "react-i18next";
import { To } from "react-router-dom";
import { CSSProperties } from "react";

interface NotAllowedProps {
  style?: CSSProperties;
  className?: string;
  linkTo?: To;
}

export const NotAllowed = ({ linkTo, ...wrapperProps }: NotAllowedProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...wrapperProps}>
      <H1>{t("notAllowed.title")}</H1>
      <Description>{t("notAllowed.description")}</Description>
      <Link type="button" to={linkTo ?? "/"}>
        {t("notAllowed.button")}
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
