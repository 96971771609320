import * as Yup from "yup";
import { getSelectSchema } from "shared/lib/helpers/validation";

export const timeValidationSchema = (t, action) =>
  Yup.object().shape({
    amount: Yup.string()
      .test("amount", "positive number", (value, testContext) => {
        if (!/^[0-9:.]+$/.test(value)) {
          return testContext.createError({
            path: `${testContext.path}`,
            message: t("validation.number"),
          });
        }
        if (parseFloat(value) < 0) {
          return testContext.createError({
            path: `${testContext.path}`,
            message: t("validation.positive"),
          });
        }
        if (!/:\S/.test(value)) {
          return testContext.createError({
            path: `${testContext.path}`,
            message: t("validation.incorrectFormat"),
          });
        }
        return true;
      })
      .max(30, t("validation.maxNumber", { limit: 30 }))
      .required(t("validation.required")),
    rate_type: Yup.string().required(t("validation.required")),
    user: getSelectSchema(t, { required: action === "stop" ? false : true }),
  });
