import { ModalParams } from "widgets/ModalController/types";
import { useSetModal } from "widgets/ModalController";
import { RequestPortalView } from "modules/requestPortals/components/RequestPortalView";
import { RequestPortalCreate } from "modules/requestPortals/components/RequestPortalCreate";
import { RequestPortalEdit } from "modules/requestPortals/components/RequestPortalEdit";

interface RequestPortalModalProps {
  params: ModalParams;
}

export const RequestPortalModal = ({ params }: RequestPortalModalProps) => {
  const { type, id, state = "view" } = params;
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new") {
    return (
      <RequestPortalCreate
        isShow={true}
        onClose={onClose}
        onOpen={(id: string) => setModal({ type, id })}
      />
    );
  } else if (state === "view") {
    return (
      <RequestPortalView
        requestPortalId={id}
        onClose={onClose}
        onEdit={(id: string) => setModal({ type, id, state: "edit" })}
        isShow={true}
      />
    );
  } else if (state === "edit") {
    return (
      <RequestPortalEdit requestPortalId={id} onClose={onView} isShow={true} />
    );
  }

  return null;
};
