import styled from "styled-components";
import { useDisplayDate } from "shared/lib/hooks";
import { TextLink } from "shared/components/TextLink";
import { UserAvatar } from "shared/components/UserAvatar";
import { useTranslation } from "react-i18next";

export const RequestRequestedBy = ({ requestData }) => {
  const { created_by: value, created_at } = requestData;
  const displayDate = useDisplayDate();
  const { t } = useTranslation();

  const { name, phone_number, email, surname } = value;
  const isAnonymous = email && email.startsWith("portal_anonymous_user");
  return (
    <RequestedByContainer>
      <UserAvatar user={value} size="sm" />
      <RequestedByInfo>
        <span>
          {name} {surname} on {displayDate(created_at, true)}
        </span>
        {phone_number && <span>{phone_number}</span>}
        {isAnonymous ? (
          <ContactPlaceholder>
            {t("work_order_requests.labels.anonymous")}
          </ContactPlaceholder>
        ) : (
          <TextLink as="a" href={"mailto:" + email}>
            {email}
          </TextLink>
        )}
      </RequestedByInfo>
    </RequestedByContainer>
  );
};

const RequestedByContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
`;

const RequestedByInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  font-family: inherit;
  color: var(--color_text_color);
  line-height: 20px;
`;

const ContactPlaceholder = styled.i`
  color: var(--color_placeholder);
  font-style: italic;
`;
