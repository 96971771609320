import { Deletion } from "features/Deletion";
import { useUpdateUserDetailsMutation } from "modules/users/state/usersApi";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useSetModal } from "widgets/ModalController";

export const DeleteAccessToEntity = ({ title, currentEntity, user }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const setModal = useSetModal();
  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const { entity, entity_id } = currentEntity;

  const proceedHandler = () => {
    updateUserDetails({
      id: user.id,
      body: { permissions: [{ entity_id, user_role_id: 5 }] },
    })
      .unwrap()
      .then(() => {
        setModal();
        addSnackBar({
          content: t(`allUsers.removeAccessToEntityConfirmation.success`, {
            fullName: user.full_name,
            entity,
          }),
          type: "success",
        });
      })
      .catch(({ message }) => {
        addSnackBar({
          content: message,
          type: "error",
        });
      });
  };

  return (
    <Deletion
      titleKey={title}
      confirmation={{
        title: t("allUsers.removeAccessToEntityConfirmation.title"),
        description: t(
          "allUsers.removeAccessToEntityConfirmation.description",
          {
            entity: entity,
          }
        ),
        confirmButtonLabel: t(
          "allUsers.removeAccessToEntityConfirmation.confirm"
        ),
      }}
      customAction={proceedHandler}
    />
  );
};
