import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { addDays, differenceInDays, fromUnixTime } from "date-fns";
import { Tick, XAxisWrapper } from "./ReliabilityHistoryChart.styled";

interface XAxisProps {
  start: Date;
  end: Date;
}

/** Custom XAxis for the ReliabilityHistoryChart */
export const XAxis = ({ start, end }: XAxisProps) => {
  const { language } = useLocaleSettings();

  const startTime = start.getTime();
  const endTime = end.getTime();
  const totalTime = end.getTime() - startTime;

  const days = differenceInDays(end, start);
  let xAxisTicks: Date[] = [];
  if (days <= 1) {
    xAxisTicks = [fromUnixTime((startTime + endTime) / 2000)];
  } else {
    const gap = Math.ceil(days / 5);
    for (let i = Math.floor(gap / 2); i <= days; i += gap) {
      xAxisTicks.push(addDays(start, i));
    }
  }

  const dateFormat = new Intl.DateTimeFormat(language, { dateStyle: "short" });
  return (
    <XAxisWrapper>
      {xAxisTicks.map((tick) => {
        const left = ((tick.getTime() - startTime) / totalTime) * 100;
        return (
          <Tick
            key={tick.getTime()}
            style={{ left: `${left}%` }}
            className={left === 100 ? "isEnd" : undefined}
          >
            {dateFormat.format(tick)}
          </Tick>
        );
      })}
    </XAxisWrapper>
  );
};
