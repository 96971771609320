import React from "react";
import FileIcon from "shared/assets/icon/file.svg?react";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";
import DownloadIcon from "shared/assets/icon/download.svg?react";

import { IconButton } from "shared/components/IconButton";
import { formatFileSize } from "../../lib/helper";

import { filePropType } from "../../propTypes";
import { Actions, Box, Info, Name, Size } from "./File.styled";

export const File = ({ name, size, onDelete, onDownload }) => {
  const handleClick = () => {
    return onDelete ? onDelete(name) : onDownload(name);
  };

  const ActionIcon = onDelete ? TrashCanIcon : DownloadIcon;

  return (
    <Box>
      <FileIcon />
      <Info>
        <Name>{name}</Name>
        <Size>{formatFileSize(size)}</Size>
      </Info>
      {(onDelete || onDownload) && (
        <Actions>
          <IconButton onClick={handleClick}>
            <ActionIcon />
          </IconButton>
        </Actions>
      )}
    </Box>
  );
};

File.propTypes = filePropType;
