import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Pagination } from "features/Pagination";
import {
  getStatusIcon,
  getTimePeriod,
  getNoteText,
} from "modules/downtimeTracking";

import { useDisplayDate } from "shared/lib/hooks";

import {
  Box,
  Container,
  Status,
  Row,
  Details,
  Note,
  SubduedText,
} from "./DowntimeHistoryList.styled";

export const DowntimeHistoryList = ({
  data,
  paginationData,
  currentPage,
  setCurrentPage,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  if (!data) return;

  return (
    <Container>
      {data.data?.map(
        (
          {
            id,
            status,
            note,
            started_at,
            downtime_type,
            ended_at,
            created_by_name,
          },
          index
        ) => {
          const isFirst = index === data.data.length - 1;

          const statusIcon = getStatusIcon(status, t);
          const noteText = getNoteText(status, downtime_type, note, t);

          let timeText = "";
          if (!isFirst) {
            timeText = getTimePeriod(started_at, ended_at);
          }

          return (
            <Box key={id}>
              <Row>
                <Details>
                  <Status>
                    <span>{statusIcon}</span>
                    <span>{timeText}</span>
                  </Status>
                  <SubduedText>
                    {started_at ? displayDate(started_at, true) : ""}
                  </SubduedText>
                </Details>
              </Row>
              <Row>
                <Note>{noteText}</Note>
                {created_by_name && (
                  <Note>
                    <SubduedText>{created_by_name}</SubduedText>
                  </Note>
                )}
              </Row>
            </Box>
          );
        }
      )}
      <Pagination
        paginationData={paginationData}
        label={t("assets.title")}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </Container>
  );
};
