import React from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import styled from "styled-components";

import { useGetRequestPortalQuery } from "modules/requestPortals";
import { FileManager } from "modules/fileManager";
import { downloadFile } from "modules/qrcodes";

import { ViewModal } from "shared/components/Modals";
import { Divider } from "shared/components/Divider";
import { IconButton } from "shared/components/IconButton";
import CopyIcon from "shared/assets/icon/copy.svg?react";
import { TextLink } from "shared/components/TextLink";
import { Description, H2 } from "shared/components/Typography";
import { Button } from "shared/components/Button";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { Details } from "widgets/Details";
import { PDFDocument } from "widgets/BulkQRCodeExport/components/PDFDocument";

const fields = [
  "entity",
  "welcome_text",
  "instructions",
  "link",
  "allow_anonymous",
];

export const RequestPortalView = ({
  isShow,
  onClose,
  onEdit,
  requestPortalId,
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const { currentData: data, error } = useGetRequestPortalQuery(
    requestPortalId ?? skipToken
  );

  if (!requestPortalId || !data) {
    return null;
  }

  if (error) {
    return <div>{t("request_portals.detailsError")}</div>;
  }

  const {
    title: name,
    description,
    images = [],
    documents = [],
    updated_at,
    uuid,
  } = data;

  const url = new URL(`/portal/${uuid}`, window.location.origin);

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const onCopyHandle = () => {
    navigator.clipboard.writeText(url.toString());
    addSnackBar({
      content: t("request_portals.copyLink"),
      type: "success",
    });
  };

  const rows = fields.reduce((visibleRows, field) => {
    const value = data[field];

    if (field === "entity") {
      visibleRows.push({
        label: t("request_portals.labels.entity"),
        value: value ? value.name : "",
      });

      return visibleRows;
    }

    if (field === "welcome_text") {
      visibleRows.push({
        label: t(`request_portals.labels.welcome_text`),
        value: <PreWrap>{value}</PreWrap>,
      });

      return visibleRows;
    }

    if (field === "instructions") {
      visibleRows.push({
        label: t(`request_portals.labels.instructions`),
        value: <PreWrap>{value}</PreWrap>,
      });

      return visibleRows;
    }

    if (field === "link") {
      visibleRows.push({
        label: t("request_portals.labels.link"),
        value: value ? (
          <LinkBox>
            <TextLink as="a" href={url.toString()} target="_blank">
              {url.toString()}
            </TextLink>
            <IconButton onClick={onCopyHandle}>
              <CopyIcon />
            </IconButton>
          </LinkBox>
        ) : null,
      });
      return visibleRows;
    }

    if (field === "allow_anonymous") {
      visibleRows.push({
        label: t("request_portals.labels.allowAnonymous"),
        value: value
          ? t("request_portals.allow_anonymous.yes")
          : t("request_portals.allow_anonymous.no"),
      });
      return visibleRows;
    }
  }, []);

  const proceedHandler = async () => {
    try {
      const doc = (
        <PDFDocument
          qrCodes={[
            {
              qr_code: url.toString(),
              entity: name,
              name: t("request_portals.name"),
              size: 98,
            },
          ]}
          module="request_portals"
          isShowCode={false}
        />
      );
      await downloadFile(doc);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ViewModal
      isOpen={isShow}
      onClose={onClose}
      label={t("request_portals.name")}
      subHeader={
        <>
          <Button
            variant="primary"
            onClick={() => window.open(url.toString(), "_blank")}
          >
            {t("request_portals.visitButton")}
          </Button>
          <Button variant="secondary" onClick={proceedHandler}>
            {t("request_portals.downloadQrCode")}
          </Button>
          <ButtonEdit variant="secondary" onClick={() => onEdit(uuid)}>
            {t("edit")}
          </ButtonEdit>
        </>
      }
    >
      <Name>{name}</Name>
      <Description>{description}</Description>
      <WrapperFileManager value={files} />
      <HeaderDividerStyled />
      <Details
        date={updated_at}
        rows={rows}
        code={url.toString()}
        codeSize={98}
        isShowCodeText={false}
      />
    </ViewModal>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;

const Name = styled(H2)`
  margin-bottom: 20px;
`;

const HeaderDividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const LinkBox = styled.div`
  display: flex;
  gap: 8px;
  color: var(--color_primary_text);
  align-items: center;
  word-break: break-all;
`;

const ButtonEdit = styled(Button)`
  margin-left: auto;
`;

const PreWrap = styled.span`
  white-space: pre-wrap;
`;
