import { downloadFile } from "shared/lib/helpers";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { CMMSAPI } from "app/services";

export const reportingApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getWidgetData: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/${reportId}`,
        params,
      }),
      transformResponse: (res) => res.data,
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    getReportKPIs: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/kpis/${reportId}`,
        params,
      }),
      transformResponse: (res) => res.data,
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    getChartData: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/chart_data/${reportId}`,
        params,
      }),
      transformResponse: (res) => res.data,
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    getTableData: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/table_data/${reportId}`,
        params,
      }),
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    downloadXLS: builder.mutation({
      queryFn: async (
        { reportId, ...params },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `reporting/download/${reportId}`,
          method: "GET",
          params,
          responseHandler: (response) => response.blob(),
        });

        const filename = getReportFilename(reportId, "xls", {
          startDate: params.start_date,
          endDate: params.end_date,
        });
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
  }),
});

export const {
  useGetWidgetDataQuery,
  useGetReportKPIsQuery,
  useGetChartDataQuery,
  useGetTableDataQuery,
  useDownloadXLSMutation,
} = reportingApi;
