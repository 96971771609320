import {
  DatePickerBase,
  DatePickerBaseProps,
} from "shared/components/DatePicker/components/DatePickerBase";
import { parseISO, roundToNearestMinutes, isBefore, isAfter } from "date-fns";
import { convertTimeToEntityTimezone } from "shared/lib/helpers";
import { formatInTimeZone } from "date-fns-tz";
import { entityZonedTimeToUtc } from "shared/lib/helpers/workWithDate";

interface DateTimePickerProps
  extends Pick<
    DatePickerBaseProps,
    | "dateFormat"
    | "name"
    | "onBlur"
    | "errorMessage"
    | "showIcon"
    | "disabled"
    | "filterDate"
  > {
  /** Currently selected date/time – must be in ISO format */
  selected: string | null | undefined;
  onChange: (date: string | undefined) => void;
  /** The earliest date/time that can be selected */
  minDateTime?: string;
  /** The latest date/time that can be selected */
  maxDateTime?: string;
  timeZone?: string;
  dataTestId?: string;
}

/**
 * Date/Time picker component that allows selecting a single date/time.
 * Uses strings for selected/onChange instead of Date objects to avoid timezone issues.
 *
 * Output is always in ISO format / UTC.
 */
export const DateTimePicker = ({
  selected,
  onChange,
  timeZone,
  minDateTime,
  maxDateTime,
  dataTestId,
  ...datePickerProps
}: DateTimePickerProps) => {
  const minDate = minDateTime
    ? convertTimeToEntityTimezone(parseISO(minDateTime), timeZone)
    : undefined;
  const maxDate = maxDateTime
    ? convertTimeToEntityTimezone(parseISO(maxDateTime), timeZone)
    : undefined;
  const { dateFormat } = datePickerProps;

  let selectedDate = selected ? parseISO(selected) : undefined;
  if (!(selectedDate instanceof Date) || isNaN(selectedDate.getTime())) {
    // Guard against 'Invalid Date'
    selectedDate = undefined;
  } else {
    selectedDate = convertTimeToEntityTimezone(selectedDate, timeZone);
  }

  let filterTime: DatePickerBaseProps["filterTime"] | undefined;
  if (minDate && maxDate) {
    filterTime = (d) =>
      maxDate.getTime() > d.getTime() && minDate.getTime() < d.getTime();
  } else if (minDate) {
    filterTime = (d) => minDate.getTime() < d.getTime();
  } else if (maxDate) {
    filterTime = (d) => maxDate.getTime() > d.getTime();
  }

  const timeFormat = dateFormat?.split(" ").slice(1).join(" ");
  return (
    <DatePickerBase
      selected={selectedDate}
      onChange={(date: Date | null) => {
        let nextDate = date;
        if (minDate && date && isBefore(date, minDate)) {
          nextDate = roundToNearestMinutes(minDate, {
            roundingMethod: "ceil",
            nearestTo: 30,
          });
        } else if (maxDate && date && isAfter(date, maxDate)) {
          nextDate = roundToNearestMinutes(maxDate, {
            roundingMethod: "floor",
            nearestTo: 30,
          });
        }

        onChange(
          nextDate
            ? formatUTC(entityZonedTimeToUtc(nextDate, timeZone))
            : undefined
        );
      }}
      placeholderText={dateFormat?.replace(" aa", "")}
      showTimeSelect
      timeFormat={timeFormat}
      minDate={minDate}
      maxDate={maxDate}
      filterTime={filterTime}
      dataTestId={dataTestId}
      {...datePickerProps}
    />
  );
};

function formatUTC(date: Date) {
  return formatInTimeZone(date, "UTC", "yyyy-MM-dd'T'HH:mm:ssXXX");
}
