import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { BarChart } from "modules/reporting/components/charts/BarChart";

import { formatCurrency } from "modules/reporting/lib/formatters";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { MaintenanceHistoryByCostReportChartData } from "./types";
import { bucketData } from "modules/reporting/lib/bucketData";

interface MaintenanceHistoryByCostReportChartProps {
  bucket: ReportBucket;
  data?: MaintenanceHistoryByCostReportChartData[];
  isFetching: boolean;
}

const reportKey = ReportKey.HistoryByCost;
export const MaintenanceHistoryByCostReportChart = ({
  bucket,
  data,
  isFetching,
}: MaintenanceHistoryByCostReportChartProps) => {
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  const chartData = bucketData(data ?? [], bucket, language, (points) =>
    points.reduce(
      (bucket, p) => {
        bucket.labor += p.labor;
        bucket.parts += p.parts;
        bucket.other += p.other;
        return bucket;
      },
      { labor: 0, parts: 0, other: 0 }
    )
  );
  return (
    <BarChart
      isLoading={isFetching}
      data={chartData}
      dataAxisKey="date"
      valueFormatter={(val) =>
        !val
          ? formatCurrency(0, language, currency)
          : formatCurrency(val, language, currency)
      }
      chartMargin={{ top: 5, right: 5, bottom: 5, left: 25 }}
      bars={[
        {
          dataKey: "labor",
          name: t(`reporting.${reportKey}.labels.labor`) as string,
          fill: "var(--color_mint)",
          stackId: "a",
        },
        {
          dataKey: "parts",
          name: t(`reporting.${reportKey}.labels.parts`) as string,
          fill: "var(--color_light_orange)",
          stackId: "a",
        },
        {
          dataKey: "other",
          name: t(`reporting.${reportKey}.labels.other`) as string,
          fill: "var(--color_salmon)",
          stackId: "a",
        },
      ]}
    />
  );
};
