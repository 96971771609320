import { useTranslation } from "react-i18next";

import { NoData } from "modules/reporting/components/NoData";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";
import styled from "styled-components";
import { formatPercent } from "modules/reporting/lib/formatters";
import { BarChart } from "modules/reporting/components/charts/BarChart";
import { transformCategoricalBarChartData } from "modules/reporting/lib/transformCategoricalBarChartData";
import { Hint } from "shared/components/Typography";
import Skeleton from "react-loading-skeleton";

interface CreatedVsCompletedReportWidgetData {
  chart_data: {
    key: string;
    value: number;
  }[];
  other_data: {
    total?: number;
  };
}

export const CreatedVsCompletedReportWidget = () => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();
  const barConfigs = useBarConfigs();

  const { reportPeriod } = useReportPageContext();
  const { data, isFetching } =
    useWidgetData<CreatedVsCompletedReportWidgetData>(
      ReportKey.CreatedVsCompleted,
      reportPeriod
    );

  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  const chartData = transformCategoricalBarChartData(
    barConfigs,
    data?.chart_data ?? []
  );

  const completedPct = data?.other_data?.total;
  const formattedPct = formatPercent(completedPct, language, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  return (
    <Wrapper>
      <BarChart
        data={chartData}
        isLoading={isFetching}
        dataAxisKey="label"
        bars={[{ dataKey: "value", name: "Total" }]}
      />
      {typeof completedPct === "number" ? (
        <CompletedText>{`${formattedPct} ${t(
          `reporting.${ReportKey.CreatedVsCompleted}.widget.completed`
        )}`}</CompletedText>
      ) : (
        <Skeleton height="1em" width="14ch" />
      )}
      <Hint>
        {t(`reporting.${ReportKey.CreatedVsCompleted}.widget.includesPrevious`)}
      </Hint>
    </Wrapper>
  );
};

function useBarConfigs() {
  const { t } = useTranslation();
  return {
    created: {
      order: 0,
      fill: "var(--color_blue)",
      label: t(`reporting.${ReportKey.CreatedVsCompleted}.labels.created`),
    },
    completed: {
      order: 0,
      fill: "var(--color_chart_lime)",
      label: t(`reporting.${ReportKey.CreatedVsCompleted}.labels.completed`),
    },
  };
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  height: 100%;
`;

const CompletedText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
`;
