import { useChangeUserEmailMutation } from "modules/users";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { ResendEmailButton } from "../styled";

export const ResendButton = ({ show, userId, email }) => {
  const { t } = useTranslation();
  const [isDisabledButton, setDisabledButton] = useState(false);
  const [changeEmail] = useChangeUserEmailMutation();

  const resendEmail = async () => {
    changeEmail({
      id: userId,
      body: {
        email,
      },
    });
    setDisabledButton(true);
    setTimeout(() => {
      setDisabledButton(false);
    }, 6000);
  };

  return (
    <>
      {show && (
        <ResendEmailButton
          variant="secondary"
          onClick={resendEmail}
          disabled={isDisabledButton}
        >
          {t("profile.email.resendVerificationEmail")}
        </ResendEmailButton>
      )}
    </>
  );
};
