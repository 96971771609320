import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";

import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import {
  useUpdateRequestPortalMutation,
  useGetRequestPortalQuery,
} from "modules/requestPortals";

import {
  requestPortalValidationSchema,
  getInitialValues,
  getRequestPortalFormatForService,
} from "../../lib/helpers/form";
import { RequestPortalEditor } from "../RequestPortalEditor";

export const RequestPortalEdit = ({ isShow, onClose, requestPortalId }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [updateRequestPortal] = useUpdateRequestPortalMutation();

  const { data } = useGetRequestPortalQuery(requestPortalId ?? skipToken);

  const requestPortalDataForForm = useMemo(
    () => getInitialValues({ type: "edit", data }),
    [data]
  );

  const onCloseModal = () => {
    updateRequestPortalForm.resetForm();
    onClose();
  };

  const { setValues, ...updateRequestPortalForm } = useForm({
    initialValues: requestPortalDataForForm,
    validationSchema: requestPortalValidationSchema(t),
    willUpdatedValues: requestPortalDataForForm,
    enableReinitialize: true,
    onSubmit: async (body) => {
      const requestPortal = getRequestPortalFormatForService(body);
      updateRequestPortal({ id: requestPortalId, body: requestPortal })
        .unwrap()
        .then(() => {
          onCloseModal();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("request_portals.snack.failUpdateTitle"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("request_portals.edit", { id: requestPortalId })}
      onClose={onCloseModal}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={updateRequestPortalForm.handleSubmit}
      disabledSubmit={
        !updateRequestPortalForm.dirty ||
        !!Object.values(updateRequestPortalForm.errors).length
      }
    >
      <RequestPortalEditor form={updateRequestPortalForm} />
    </EditorModal>
  );
};
