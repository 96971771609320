import { useGetProcedureTemplatesQuery } from "../state/proceduresApi";
import { useSearch } from "shared/lib/hooks/useSearch";
import { IProcedureTemplate } from "../types";
import { useMemo } from "react";

interface ProcedureTemplateOptions {
  procedureTemplates: IProcedureTemplate[];
  onProcedureTemplateSearch: (search: string) => void;
  options: { value: string | number; label: string }[];
}

export const useProcedureTemplateOptions = (): ProcedureTemplateOptions => {
  const [procedureTemplates = [], onProcedureTemplateSearch] = useSearch(
    useGetProcedureTemplatesQuery
  );

  const { data: procedureTemplatesData } = procedureTemplates as {
    data: IProcedureTemplate[];
  };

  const options = useMemo(() => {
    return procedureTemplatesData?.map((procedureTemplate) => ({
      value: procedureTemplate.id,
      label: procedureTemplate.name,
    }));
  }, [procedureTemplatesData]);

  return {
    procedureTemplates: procedureTemplatesData,
    onProcedureTemplateSearch,
    options,
  };
};
