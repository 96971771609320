import React from "react";
import { useTranslation } from "react-i18next";

import { roundTimeToNearestMinute } from "modules/workOrderTime/lib/helpers";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";

import { timeValidationSchema } from "../../configs/validationSchema";
import { WorkOrderTimeEditor } from "../WorkOrderTimeEditor";

export const WorkOrderTimeStop = ({ onSubmit, timer, onClose }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    stopTimerForm.resetForm();
    onClose();
  };

  const stopTimerForm = useForm({
    initialValues: {
      amount: roundTimeToNearestMinute(timer),
      rate_type: "regular_rate",
    },
    validationSchema: timeValidationSchema(t, "stop"),
    enableReinitialize: true,
    onSubmit: (body) => {
      onSubmit(body);
      closeModal();
    },
  });

  return (
    <ConfirmationDialog
      title={t(`work_order_time.confirm`)}
      confirmButtonLabel={t("save")}
      cancelButtonLabel={t("cancel")}
      form={stopTimerForm}
      disabledSubmit={!!Object.values(stopTimerForm.errors).length}
      description={
        <WorkOrderTimeEditor form={stopTimerForm} withUsers={false} />
      }
      onProceed={stopTimerForm.handleSubmit}
      onCancel={closeModal}
    />
  );
};
