import { useTranslation } from "react-i18next";

export const useListRows = (data, entity) => {
  const { t } = useTranslation();

  return Object.keys(data).map((el) => {
    return {
      disabled: data[el].required,
      hidden: !data[el].visible,
      name: data[el].label
        ? data[el].label
        : t(`${entity}.clone.settingsLabels.${el}`),
      field: el,
      id: el,
    };
  });
};
