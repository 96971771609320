import styled from "styled-components";
import { Button } from "shared/components/Button";

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: 600;
  line-height: 20px;
`;

export const StyledButton = styled(Button)`
  padding: 4px;
  height: 24px;
`;

export const Wrapper = styled.div`
  width: 100%;
  line-height: 20p;
  font-size: 14px;
  color: var(--color_grey900);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const TimerBox = styled.div`
  display: flex;
  gap: 2px;
`;

export const StopButton = styled(Button)`
  font-weight: 500;
  line-height: 16px;
  height: 24px;
  padding: 4px;
  color: white;
  background: var(--color_corp_red);
  border-color: var(--color_corp_red);
  &:hover {
    background: var(--color_corp_red);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_corp_red);
    box-shadow: 0px 0px 0px 2px var(--color_corp_red);
    border: 1px solid var(--color_background);
  }
`;
