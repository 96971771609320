import QRCodeStyling from "qr-code-styling";
import { pdf, Font } from "@react-pdf/renderer";
import logo from "shared/assets/qr-logo.svg?url";
import RegularFont from "../assets/fonts/Poppins-Regular.ttf";
import BoldFont from "../assets/fonts/Poppins-Bold.ttf";
import SingleQRCodeExampleAsset from "../assets/singleQRCodeExample.png";
import MultipleQRCodeExampleAsset from "../assets/multipleQRCodeExample.png";
import SingleQRCodeExampleLocation from "../assets/singleQRCodeExampleLocation.png";
import MultipleQRCodeExampleLocation from "../assets/multipleQRCodeExampleLocation.png";
import SingleQRCodeExamplePart from "../assets/singleQRCodeExamplePart.png";
import MultipleQRCodeExamplePart from "../assets/multipleQRCodeExamplePart.png";
import NoQRCodes from "../assets/noQRCodes.png";

export const getImages = async (data, size) => {
  const options = {
    width: size ?? 86,
    height: size ?? 86,
    type: "png",
    image: logo,
    data,
    qrOptions: {
      errorCorrectionLevel: "H",
    },
    imageOptions: {
      margin: 3,
      imageSize: 0.5,
      hideBackgroundDots: true,
    },
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const qrCode = new QRCodeStyling({
    ...options,
  });

  const dataURL = await qrCode.getRawData("png");
  const image = await blobToBase64(dataURL);
  return image;
};

export const getSettingsOptions = (t) => [
  {
    key: "single",
    value: t("exportQRCodes.confirmation.singleOption"),
  },
  {
    key: "multiple",
    value: t("exportQRCodes.confirmation.multipleOption"),
  },
];

export const downloadFile = async (doc) => {
  const asPdf = pdf([]);
  asPdf.updateContainer(doc);
  const pdfBlob = await asPdf.toBlob();
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "qrcodes.pdf";
  link.click();
};

export const fonts = Font.register({
  family: "Poppins",
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
      fontWeight: "bold",
    },
  ],
});

export const getName = (arr) => {
  const names = arr.map((item) => item.name);
  return names.join(", ");
};

const imagesSingleMap = {
  assets: SingleQRCodeExampleAsset,
  parts: SingleQRCodeExamplePart,
  locations: SingleQRCodeExampleLocation,
};
const imagesMultipleMap = {
  assets: MultipleQRCodeExampleAsset,
  parts: MultipleQRCodeExamplePart,
  locations: MultipleQRCodeExampleLocation,
};

export const getModalImage = ({ qrCodes, exportOption, type }) => {
  if (!qrCodes || qrCodes.length <= 0) return NoQRCodes;
  return exportOption === "single"
    ? imagesSingleMap[type]
    : imagesMultipleMap[type];
};
