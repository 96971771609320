import { FilterButton } from "modules/filters/components/Filters.styled";
import React from "react";
import { useToggleFilterConfigs } from "modules/filters/hooks/useToggleFilterConfigs";
import PropTypes from "prop-types";
import { produce } from "immer";

/** Buttons that toggle a set of related filter parameters */
export const ToggleFilters = ({ entity, activeFilters, setFilters }) => {
  const toggleFilters = useToggleFilterConfigs(entity);

  return (
    <>
      {toggleFilters?.map((tf, index) => {
        // Indices of any active filters matching the toggleFilter
        const indices = tf.filters
          .map((f) =>
            activeFilters.findIndex(
              (i) =>
                i.field === f.field &&
                i.operator === f.operator &&
                // This isn't perfect, but should be good enough for now
                // `value` can be an array, so this compares the contents instead of the reference
                JSON.stringify(i.value) === JSON.stringify(f.value)
            )
          )
          .filter((i) => i > -1);
        const isActive = indices.length === tf.filters.length;
        return (
          <FilterButton
            key={index}
            isActive={isActive}
            type="button"
            onClick={() => {
              if (isActive) {
                // If the ToggleFilter is already active, remove all of its filters
                setFilters(
                  produce(activeFilters, (draft) =>
                    draft.filter((_, index) => !indices.includes(index))
                  )
                );
              } else {
                // Otherwise, add all of its filters to the filters list
                setFilters(
                  produce(activeFilters, (draft) => draft.concat(...tf.filters))
                );
              }
            }}
          >
            {tf.name}
          </FilterButton>
        );
      })}
    </>
  );
};

ToggleFilters.propTypes = {
  entity: PropTypes.string.isRequired,
  /* Array of active filters */
  activeFilters: PropTypes.array,
  setFilters: PropTypes.func,
};
