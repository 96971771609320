import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";

import { timeValidationSchema } from "../../configs/validationSchema";
import { WorkOrderTimeEditor } from "../WorkOrderTimeEditor";

export const WorkOrderTimeUpdate = ({
  id,
  index,
  onUpdate,
  onDelete,
  onClose,
  usersOptions,
  onUsersSearch,
  value,
  canDeleteTimeWo,
}) => {
  const { t } = useTranslation();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const determinant = id || index;

  const closeModal = () => {
    editTimeForm.resetForm();
    onClose();
  };

  const onOpenDeleteModal = () => {
    setIsShowDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
  };

  const onDeleteHandle = () => {
    onDelete(determinant);
    onCloseDeleteModal();
    closeModal();
  };

  const editTimeForm = useForm({
    initialValues: value,
    validationSchema: timeValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (body) => {
      onUpdate(body, determinant);
      closeModal();
    },
  });

  return (
    <>
      <ConfirmationDialog
        title={t(`work_order_time.edit`)}
        confirmButtonLabel={t("save")}
        cancelButtonLabel={t("discardChanges")}
        form={editTimeForm}
        disabledSubmit={
          !!Object.values(editTimeForm.errors).length || !editTimeForm.dirty
        }
        description={
          <WorkOrderTimeEditor
            usersOptions={usersOptions}
            onUsersSearch={onUsersSearch}
            form={editTimeForm}
          />
        }
        onProceed={editTimeForm.handleSubmit}
        onCancel={closeModal}
        onDelete={canDeleteTimeWo && onOpenDeleteModal}
      />
      {isShowDeleteModal && (
        <ConfirmationDialog
          title={t("work_order_time.delete.confirmation.title")}
          description={t("work_order_time.delete.confirmation.description")}
          confirmButtonLabel={t("work_order_time.delete.confirmation.confirm")}
          onProceed={onDeleteHandle}
          onCancel={onCloseDeleteModal}
        />
      )}
    </>
  );
};
