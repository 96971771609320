import { isPast, parseISO } from "date-fns";

/** Checks if a work order, request, or PM is overdue */
export const isWorkOrderOverdue = (
  due_date: string | undefined,
  status?: string
) => {
  if (!due_date || status === "completed" || status === "paused") return false;
  return isPast(parseISO(due_date));
};
