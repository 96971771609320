import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { WorkOrderOtherCostCreate } from "../WorkOrderOtherCostCreate";
import {
  Box,
  Title,
  StyledButton,
  Wrapper,
  ActionButtons,
} from "./WorkOrderOtherCostsList.styled";
import { WorkOrderOtherCostView } from "../WorkOrderOtherCostView";

export const WorkOrderOtherCostsList = ({
  value = [],
  onCreate,
  onUpdate,
  onDelete,
  titleFontSize,
  permissions = { add: true, edit: true, delete: true },
  withImageZoom,
  withFileDownload,
}) => {
  const { t } = useTranslation();
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);

  const onCloseCreateForm = () => setIsOpenCreateForm(false);
  const onOpenCreateForm = () => setIsOpenCreateForm(true);

  return (
    <>
      <Box>
        <Title fontSize={titleFontSize}>
          {t("work_order_other_costs.title")}
        </Title>
        <ActionButtons>
          {permissions?.add && (
            <StyledButton onClick={onOpenCreateForm} variant="secondary">
              {t("work_order_other_costs.add")}
            </StyledButton>
          )}
        </ActionButtons>
      </Box>
      <Wrapper>
        {value.map((val, index) => (
          <WorkOrderOtherCostView
            key={`${val.title}-${index}`}
            value={val}
            index={index}
            onUpdate={onUpdate}
            onDelete={onDelete}
            permissions={permissions}
            withImageZoom={withImageZoom}
            withFileDownload={withFileDownload}
          />
        ))}
      </Wrapper>
      {isOpenCreateForm && (
        <WorkOrderOtherCostCreate
          onCreate={onCreate}
          onClose={onCloseCreateForm}
        />
      )}
    </>
  );
};

WorkOrderOtherCostsList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      cost: PropTypes.string,
      note: PropTypes.string,
    })
  ),
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  titleFontSize: PropTypes.string,
};
