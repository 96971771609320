import styled from "styled-components";
import { InlineLabel } from "shared/components/widgets";

import Icon from "../../../../assets/status.svg?react";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 16px;
`;

export const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

export const StyledIcon = styled(Icon)`
  outline: none;
`;

export const StyledInlineLabel = styled(InlineLabel)`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
  margin-top: 10px;
`;
