import { useSetModal } from "widgets/ModalController";
import { usePermission } from "app/providers/PermissionsProvider";
import { RequestCreate } from "modules/requests/components/RequestCreate";
import { ModalParams } from "widgets/ModalController/types";
import { RequestView } from "modules/requests/components/RequestView";
import { RequestEdit } from "modules/requests/components/RequestEdit";
import { RequestApprove } from "modules/requests/components/RequestApprove";

interface RequestModalProps {
  params: ModalParams;
}

export const RequestModal = ({ params }: RequestModalProps) => {
  const { type, id, state = "view" } = params;
  const { requestViewPermit, requestCreatePermit } = usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new" && requestCreatePermit) {
    return (
      <RequestCreate
        isShow={true}
        onClose={onClose}
        onOpen={(id: string) => setModal({ type, id })}
      />
    );
  } else if (state === "view" && requestViewPermit) {
    return (
      <RequestView
        isShow={true}
        requestId={id}
        onClose={onClose}
        onEdit={() => setModal({ type, id, state: "edit" })}
        onApprove={() => setModal({ type, id, state: "approve" })}
      />
    );
  } else if (state === "edit") {
    return (
      <RequestEdit
        isShow={true}
        requestId={id}
        onClose={onView}
        onOpen={onView}
      />
    );
  } else if (state === "approve") {
    return (
      <RequestApprove
        isShow={true}
        requestId={id}
        onClose={onView}
        onOpen={onView}
      />
    );
  }
  return null;
};
