import styled from "styled-components";

import SearchIcon from "shared/assets/icon/search.svg?react";

export const ThemeStyles = (theme) => ({
  ...theme,
  borderRadius: 5,
  borderWidth: 1,
  colors: {
    ...theme.colors,
    primary: "var(--color_grey500)",
  },
});

export const ControlContainer = styled.div`
  box-shadow: ${({ menuOpened }) =>
    menuOpened
      ? "0px 0px 0px 1px var(--color_background_light), 0px 0px 0px 3px var(--color_primary)"
      : "none"};
  border-radius: 5px;
  min-height: 40px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  color: var(--color_alert);
  font-size: 14px;
  margin-top: 6px;
`;

export const SearchIconStyled = styled(SearchIcon)`
  color: var(--color_header_search_icon);
  margin-left: 10px;
  cursor: pointer;
`;

export const ClearIconContainer = styled.div`
  background: var(--color_grey500);
  color: var(--color_text_light);
  border-radius: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  box-sizing: border-box;
`;

export const SelectedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 6px;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

export const NoOptionContainer = styled.div`
  padding: 4px 4px 4px 30px;
  color: var(--color_text_color);
  font-size: 14px;
`;

export const OptionWrapper = styled.div`
  padding: 2px;
`;

export const OptionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4px 0;
  align-items: center;
  cursor: pointer;

  color: var(--color_text_color);
  background: ${({ selected }) =>
    selected ? "var(--color_primary_hover)" : "transparent"};
  border-radius: 4px;
  &:hover {
    background: var(--color_primary_hover);
  }
`;

export const OptionCreate = styled.span`
  color: rgba(2, 131, 111, 1);
  font-size: 14px;
  font-weight: 400;
`;

export const SelectStyles = {
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: 14,
  }),

  groupHeading: (base) => ({
    ...base,
    fontSize: 12,
    fontWeight: 600,
    color: "var(--color_grey900)",
    textTransform: "none",
    paddingVertical: "8px",
  }),

  control: (base, state) => {
    return {
      ...base,
      border: `1px solid ${state.hasValue ? "#737278" : "#BFBFC3"}`,
      outline: "none",
      boxShadow: "none",
      minHeight: 40,
    };
  },
};
