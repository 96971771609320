import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useSignUpMutation } from "modules/auth/state/authApi";
import { useForm } from "shared/lib/hooks/useForm";

import { Button } from "shared/components/Button";
import { Input } from "shared/components/Input";
import {
  Box,
  DividerStyled,
  Title,
  ActionBox,
  Container,
  PrivacyPolicyContainer,
} from "../styled";
import { PasswordField } from "modules/auth/components/PasswordField";
import { InlineLabel } from "shared/components/widgets";
import { InviteSignUpFormFieldsInitialValues } from "modules/auth/config/constants";
import { inviteSignUpFormValidationSchema } from "modules/auth/config/validationSchema";
import { InvitationExpired } from "../AdditionalPages";
import { useNavigate } from "react-router-dom";
import { Header } from "../Header";
import { PrivacyPolicy } from "shared/components/PrivacyPolicy";

const tokenExpiredError = "Invitation token is not valid or user not found";

export const InviteSignUpForm = ({ token }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addSnackBar } = useSnackBar();

  const [doSignUp, { error }] = useSignUpMutation();

  const onSubmit = async (body) => {
    doSignUp({
      ...body,
      invitation_token: token,
      password_confirmation: body.password,
    })
      .unwrap()
      .then(() => {
        navigate("/login");
      })
      .catch(({ message }) => {
        if (message !== tokenExpiredError)
          addSnackBar({
            title: t("auth.snack.failCreationTitle"),
            content: JSON.stringify(message),
            type: "error",
          });
      });
  };

  const form = useForm({
    initialValues: InviteSignUpFormFieldsInitialValues,
    validationSchema: inviteSignUpFormValidationSchema(t),
    onSubmit,
  });

  let isTokenExpired =
    error?.message === "Invitation token is not valid or user not found";

  if (isTokenExpired) {
    return <InvitationExpired />;
  }

  return (
    <>
      <Header />
      <Container>
        <Box id="signUpForm">
          <Title>{t("auth.signUp.newUser")}</Title>
          <InlineLabel label={t("auth.signUp.firstNameLabel")} columnLabel>
            <Input
              type="text"
              name="name"
              placeholder={t("auth.signUp.firstNamePlaceholder")}
              value={form.values.name}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              errorMessage={form.touched.name && form.errors.name}
            />
          </InlineLabel>
          <InlineLabel label={t("auth.signUp.lastNameLabel")} columnLabel>
            <Input
              type="text"
              name="surname"
              placeholder={t("auth.signUp.lastNamePlaceholder")}
              value={form.values.surname}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              errorMessage={form.touched.surname && form.errors.surname}
            />
          </InlineLabel>
          <PasswordField form={form} />
          <DividerStyled />
          <PrivacyPolicyContainer>
            <PrivacyPolicy
              preamble={t("privacyPolicy.additionalText", { person: "you" })}
            />
          </PrivacyPolicyContainer>
          <ActionBox>
            <Button
              disabled={!form.isFormValid}
              type="primary"
              size="normal"
              onClick={form.handleSubmit}
            >
              {t("auth.signUp.signUp")}
            </Button>
          </ActionBox>
        </Box>
      </Container>
    </>
  );
};

InviteSignUpForm.propTypes = {};
