import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { commonQrCodePropTypes } from "./propTypes";
import { LogoQrCode } from "./LogoQrCode";

export const LabeledQrCode = ({
  className,
  data,
  isShowCodeText = true,
  size,
}) => {
  if (!data) {
    return null;
  }
  return (
    <Box className={className}>
      <LogoQrCode data={data} size={size} />
      {isShowCodeText && <span>{data}</span>}
    </Box>
  );
};

LabeledQrCode.propTypes = {
  ...commonQrCodePropTypes,
  className: PropTypes.string,
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--color_grey100);
  padding: 15px 15px 14px;

  span {
    color: var(--color_grey900);
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
    letter-spacing: 0.06em;
  }
`;
