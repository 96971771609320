import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

interface KPIGridProps {
  isLoading?: boolean;
  detailKpis: {
    label: string;
    value: string;
  }[];
  kpis: {
    label: string;
    value: string;
  }[];
}

export const CompletedByCategoryKPIGrid = ({
  kpis,
  detailKpis,
  isLoading,
}: KPIGridProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid>
        {kpis.map((i, index) => (
          <Item key={index} isLoading={isLoading} {...i} />
        ))}
      </Grid>
      <DetailsBox>
        <DetailsTitle>
          {isLoading
            ? ""
            : t(`reporting.completed_by_category.detailKpis`, {
                count: detailKpis.length,
              })}
        </DetailsTitle>
        <Grid>
          {detailKpis.map((i, index) => (
            <Item key={index} isLoading={isLoading} {...i} />
          ))}
        </Grid>
      </DetailsBox>
    </>
  );
};

interface ItemProps {
  value: string;
  label: string;
  isLoading?: boolean;
}

const Item = ({ value, label, isLoading }: ItemProps) => {
  return (
    <KpiBox>
      <KPIValue>
        {isLoading ? <Skeleton height="1em" width="5ch" /> : value}
      </KPIValue>
      <KPILabel>{label}</KPILabel>
    </KpiBox>
  );
};

const KpiBox = styled.div``;
const DetailsTitle = styled.div`
  margin-top: -58px;
  background-color: white;
  width: fit-content;
  padding: 0 4px;
  margin-left: 16px;
`;

const DetailsBox = styled.div`
  margin-top: 32px;
  box-sizing: border-box;
  padding-top: 45px;
  border: 2px solid var(--color_grey50);
  border-radius: 8px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  ${DetailsBox} > & {
    margin: 16px auto;
    text-align: center;
  }
`;

const KPIValue = styled.div`
  color: var(--color_dark_blue);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  white-space: nowrap;
`;

const KPILabel = styled.div`
  color: var(--color_grey700);
  font-size: 14px;
  line-height: 1.4;
  white-space: nowrap;
`;
