import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";

import { partValidationSchema } from "../../configs/validationSchema";
import { WorkOrderPartEditor } from "../WorkOrderPartEditor";

export const WorkOrderPartCreate = ({ onCreate, onClose, assetId }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    addPartForm.resetForm();
    onClose();
  };

  const addPartForm = useForm({
    initialValues: {
      part: null,
      quantity: 1,
    },
    validationSchema: partValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (body) => {
      onCreate(body);
      closeModal();
    },
  });

  return (
    <ConfirmationDialog
      title={t(`work_order_parts.add`)}
      confirmButtonLabel={t("save")}
      cancelButtonLabel={t("cancel")}
      form={addPartForm}
      disabledSubmit={
        !addPartForm.dirty || !!Object.values(addPartForm.errors).length
      }
      description={<WorkOrderPartEditor form={addPartForm} assetId={assetId} />}
      onProceed={addPartForm.handleSubmit}
      onCancel={closeModal}
    />
  );
};
