import { Outlet } from "react-router-dom";
import styled from "styled-components";

/** Expanded view of a single report */
export const ReportPage = () => {
  return (
    <ReportPageWrapper>
      <Outlet />
    </ReportPageWrapper>
  );
};

const ReportPageWrapper = styled.div`
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;
