import { InitialValues } from "modules/entities/config/constants";

export const getInitialValues = ({ data, type = "edit", currentTimezone }) => {
  if (type === "create" || !data) {
    return {
      ...InitialValues,
      timezone: currentTimezone,
    };
  }

  const {
    name = "",
    description = "",
    address = "",
    currency = "",
    currency_name = "",
    datetime_format = "",
    timezone = "",
  } = data;

  const entity = {
    name,
    description: description ?? "",
    address: address ?? "",
    currency: {
      value: `${currency} (${currency_name})`,
      label: `${currency} (${currency_name})`,
      common: { currency, currency_name },
    },
    datetime_format: {
      label: datetime_format,
      value: datetime_format,
    },
    timezone: {
      label: timezone,
      value: timezone,
    },
  };

  return entity;
};

export const getEntityFormatForService = (body) => {
  const { currency, datetime_format, timezone, ...otherFields } = body;

  const data = {
    ...otherFields,
    currency: currency.common.currency,
    datetime_format: datetime_format.value,
    timezone: timezone.value,
  };

  return data;
};
