import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import styled from "styled-components";
import { HeaderContextMenu, ViewModal } from "shared/components/Modals";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { ViewHeader } from "shared/components/ViewHeader";
import ThinAddIcon from "shared/assets/icon/thin-add.svg?react";
import { H3 } from "shared/components/Typography";
import { usePermission } from "app/providers/PermissionsProvider";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";

import { Deletion } from "features/Deletion";
import { FileManager } from "modules/fileManager";
import { useVisibleFields } from "modules/adminFieldsConfiguration/lib/hooks";
import { ContactsList } from "modules/contacts";
import { Details } from "widgets/Details";

import {
  useGetVendorQuery,
  useDeleteVendorMutation,
  useUpdateVendorMutation,
  useUpdateVendorStatusMutation,
} from "modules/vendors/state/vendorsApi";
import { getViewRows } from "../../lib/helpers";
import {
  ButtonRight,
  DividerStyled,
  TopContainer,
  ContactsContainer,
} from "./VendorView.styled";
import { StatusUpdate } from "../StatusUpdate";
import { WorkOrderCreate } from "modules/workOrders";
import { Button } from "shared/components/Button";
import { useSetModal } from "widgets/ModalController";

const fields = [
  "urls",
  "hourly_rate",
  "address",
  "locations",
  "tax_id",
  "assign_to_work_orders",
  "parts_supplier",
  "categories",
  "assets",
  "parts",
  "rating",
];

export const VendorView = ({ isShow, onClose, onEdit, vendorId }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [workOrderModal, setWorkOrderModal] = useState(null);
  const setModal = useSetModal();

  const [deleteVendor] = useDeleteVendorMutation();
  const [updateVendor] = useUpdateVendorMutation();
  const [onUpdateStatus] = useUpdateVendorStatusMutation();

  const {
    vendorUpdatePermit,
    vendorDeletePermit,
    vendorChangeStatusPermit,
    vendorContactListPermit,
    woCreatePermit,
  } = usePermission();

  const { currentData: data, error } = useGetVendorQuery(vendorId ?? skipToken);

  const { areVisibleFieldsLoading, isFieldVisible } =
    useVisibleFields("vendor");

  if (!vendorId) {
    return null;
  }

  /* // TODO: Add a loader or a skeleton */
  if (areVisibleFieldsLoading) return null;

  if (!data || error) {
    return <div>Error vendor</div>;
  }

  const { name, id, description, images, documents, updated_at, is_active } =
    data;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const rows = getViewRows(fields, data, isFieldVisible, t);

  const onUpdateContact = async (name, value) => {
    const body = { [name]: value };
    try {
      await updateVendor({
        id: vendorId,
        body,
      }).unwrap();
    } catch ({ message }) {
      addSnackBar({
        title: t("contacts.failedCreation"),
        content: JSON.stringify(message),
        type: "error",
        secondaryButtonLabel: t("close"),
      });
    }
  };

  const handleCreateWO = () =>
    setWorkOrderModal({ vendors: [{ value: vendorId, label: name }] });

  const deleteErrorHandler = (message) => {
    const content =
      message.base?.[0] === RESPONSE_MESSAGE.connected_record
        ? t("vendors.warningDeletionContent")
        : JSON.stringify(message);

    addSnackBar({
      title: t("vendors.warningDeletionTitle"),
      content,
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
  };

  return (
    <>
      <ViewModal
        isOpen={isShow}
        onClose={onClose}
        label={t("vendors.name", { name })}
        subHeader={
          <>
            {woCreatePermit && data?.assign_to_work_orders && (
              <Button onClick={handleCreateWO}>
                <WorkOrderButton>
                  <ThinAddIcon />
                  <span> {t("workOrder.title")}</span>
                </WorkOrderButton>
              </Button>
            )}
            <ButtonRight
              variant="secondary"
              onClick={() => onEdit(vendorId)}
              disabled={!vendorUpdatePermit}
            >
              {t("edit")}
            </ButtonRight>
            <HeaderContextMenu
              disabled={!vendorChangeStatusPermit && !vendorDeletePermit}
            >
              {vendorChangeStatusPermit && (
                <StatusUpdate
                  id={id}
                  status={is_active}
                  onUpdate={onUpdateStatus}
                />
              )}
              {vendorDeletePermit && (
                <Deletion
                  id={vendorId}
                  module={data?.name}
                  onSuccess={onClose}
                  onError={deleteErrorHandler}
                  confirmation={{
                    title: t("vendors.deletionConfirmation.title"),
                    description: t("vendors.deletionConfirmation.description"),
                    confirmButtonLabel: t(
                      "vendors.deletionConfirmation.confirm"
                    ),
                  }}
                  onDelete={deleteVendor}
                />
              )}
            </HeaderContextMenu>
          </>
        }
      >
        <ViewHeader id={id} name={name} description={description} />
        <FileManager value={files} />
        <DividerStyled />
        {isFieldVisible("vendor_contacts") && vendorContactListPermit && (
          <>
            <TopContainer>
              <H3>{t("contacts.title")}</H3>
            </TopContainer>
            <ContactsContainer>
              <ContactsList
                value={data?.vendor_contacts}
                name="vendor_contacts_attributes"
                setValue={onUpdateContact}
              />
            </ContactsContainer>
            <DividerStyled />
          </>
        )}
        <Details date={updated_at} rows={rows} />
      </ViewModal>
      {workOrderModal && (
        <WorkOrderCreate
          isShow={Boolean(workOrderModal)}
          defaultValues={workOrderModal}
          onClose={() => setWorkOrderModal(null)}
          onOpen={setModal}
        />
      )}
    </>
  );
};

const WorkOrderButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
