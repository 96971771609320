import React from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  Name,
  Portal,
  Text,
  Instructions,
  WrapperFileManager,
} from "./PortalView.styled";

import { heading } from "@test-data";

export const PortalView = ({ data }) => {
  const { t } = useTranslation();

  const {
    welcome_text,
    instructions,
    images = [],
    documents = [],
    tenant,
  } = data;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  return (
    <Container>
      <Name>{tenant.customer}</Name>
      <Portal data-testid={heading.requestPortal.id}>
        {t("request_portals.name")}
      </Portal>
      <Text>{welcome_text}</Text>
      <Instructions>{t("portal.instructions")}</Instructions>
      <Text>{instructions}</Text>
      <WrapperFileManager
        value={files}
        withZoom={false}
        withFileDownload={false}
      />
    </Container>
  );
};
