import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { InlineLabel } from "shared/components/widgets";
import { Divider } from "shared/components/Divider";
import { H6 } from "shared/components/Typography";
import { LaborRates } from "../LaborRates";
import { UserEntityPermissions } from "../UserEntityPermissions";
import { PhoneInput } from "shared/components/PhoneInput";

export const InvitedUserEditor = ({ type = "create", form, roleOptions }) => {
  const { t } = useTranslation();

  if (type === "edit") {
    return (
      <>
        <InlineLabel label={t("allUsers.form.job_title")} labelWidth={110}>
          <Input
            name="job_title"
            value={form.values.job_title}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("allUsers.form.job_title")}
            errorMessage={form.touched.job_title && form.errors.job_title}
            height={40}
            fontSize={14}
          />
        </InlineLabel>
        <InlineLabel label={t("allUsers.form.phone_number")} labelWidth={110}>
          <PhoneInput
            value={form.values.phone_number}
            setValue={(value) => form.setFieldValue("phone_number", value)}
            errorMessage={form.touched.phone_number && form.errors.phone_number}
            touched={(value) => form.setTouched({ phone_number: true })}
          />
        </InlineLabel>
        <InlineLabel label={t("allUsers.form.email")} labelWidth={110}>
          <Input
            name="email"
            value={form.values.email}
            placeholder={t("allUsers.form.email")}
            height={40}
            fontSize={14}
            disabled
          />
        </InlineLabel>
        <DividerStyled />
        <UserEntityPermissions form={form} roleOptions={roleOptions} />
        <LaborRates form={form} />
      </>
    );
  }

  if (type === "create")
    return (
      <>
        <Input
          name="email"
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("allUsers.form.email")}
          errorMessage={form.touched.email && form.errors.email}
          height={40}
          fontSize={14}
        />
        <DividerStyled />
        <UserEntityPermissions form={form} roleOptions={roleOptions} />
        <DividerStyled />
        <Name>Additional Details</Name>
        <InlineLabel label={t("allUsers.form.job_title")} labelWidth={110}>
          <Input
            name="job_title"
            value={form.values.job_title}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("allUsers.form.job_title")}
            errorMessage={form.touched.job_title && form.errors.job_title}
            height={40}
            fontSize={14}
          />
        </InlineLabel>
        <InlineLabel label={t("allUsers.form.phone_number")} labelWidth={110}>
          <PhoneInput
            value={form.values.phone_number}
            setValue={(value) => form.setFieldValue("phone_number", value)}
            errorMessage={form.touched.phone_number && form.errors.phone_number}
            touched={(value) => form.setTouched({ phone_number: true })}
          />
        </InlineLabel>
        <LaborRates form={form} />
      </>
    );
};

const DividerStyled = styled(Divider)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

const Name = styled(H6)``;
