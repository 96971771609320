import { useTranslation } from "react-i18next";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { Pagination } from "features/Pagination";
import { CompletedByCategoryTableData } from "./types";
import { PaginationData } from "types/Response";
import {
  formatCurrency,
  formatMTTR,
  formatPercent,
} from "../../../lib/formatters";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";

const reportKey = ReportKey.CompletedByCategory;

interface CompletedByCategoryReportTableProps {
  data?: CompletedByCategoryTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const CompletedByCategoryReportTable = ({
  data,
  paginationData,
  isFetching,
}: CompletedByCategoryReportTableProps) => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();
  const columns = useTableColumns(data);
  const shapedData = data
    ? [...data].map((row) => {
        const cloneRow = structuredClone(row);
        if (cloneRow.category === "uncategorized")
          cloneRow["category"] = t(
            `reporting.${reportKey}.labels.uncategorized`
          );
        return {
          ...cloneRow,
          reactive: t(
            `reporting.${ReportKey.CompletedByCategory}.columns.value_with_percentage`,
            {
              value: row.reactive,
              percentage: formatPercent(row.reactive_percentage, language),
            }
          ),
          preventive: t(
            `reporting.${ReportKey.CompletedByCategory}.columns.value_with_percentage`,
            {
              value: row.preventive,
              percentage: formatPercent(row.preventive_percentage, language),
            }
          ),
          on_time: t(
            `reporting.${ReportKey.CompletedByCategory}.columns.value_with_percentage`,
            {
              value: row.on_time,
              percentage: formatPercent(row.on_time / row.count, language),
            }
          ),
        };
      })
    : [];

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  return (
    <>
      <Table
        columns={columns}
        rows={shapedData}
        getRowKey={(row) => row.id}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(
  data: CompletedByCategoryTableData[] | undefined
): TableColumn<CompletedByCategoryTableData>[] {
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();
  if (!data || !data.length) {
    return [];
  }
  return [
    {
      field: "category",
      name: t(`reporting.${reportKey}.columns.category`),
      sortable: true,
      minWidth: 200,
    },
    {
      field: "count",
      name: t(`reporting.${reportKey}.columns.count`),
      sortable: true,
      minWidth: 30,
    },
    {
      field: "reactive",
      name: t(`reporting.${reportKey}.columns.reactive`),
      sortable: true,
    },
    {
      field: "preventive",
      name: t(`reporting.${reportKey}.columns.preventive`),
      sortable: true,
    },
    {
      field: "mttr",
      name: t(`reporting.${reportKey}.columns.mttr`),
      sortable: true,
      formatValue: (value) => formatMTTR(value, t),
    },
    {
      field: "on_time",
      name: t(`reporting.${reportKey}.columns.on_time`),
      sortable: true,
    },
    {
      field: "cost",
      name: t(`reporting.${reportKey}.columns.cost`),
      sortable: true,
      formatValue: (value) =>
        formatCurrency(parseFloat(value), language, currency),
    },
    {
      field: "avg_cost",
      name: t(`reporting.${reportKey}.columns.avg_cost`),
      sortable: true,
      formatValue: (value) =>
        formatCurrency(parseFloat(value), language, currency),
    },
    {
      field: "avg_time_to_complete",
      name: t(`reporting.${reportKey}.columns.avg_time_to_complete`),
      sortable: true,
      formatValue: (value) => formatMTTR(value, t),
    },
  ];
}
