import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipIcon from "shared/assets/icon/information.svg?react";
import ColorTooltipIcon from "shared/assets/icon/colorInformation.svg?react";
import { IconButton } from "../IconButton";
import { Container } from "./ContextualHelp.styled";

/**
 * A small button with a popover for providing extra information about nearby controls
 */
export const ContextualHelp = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const showHandler = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Container>
      <IconButton data-tooltip-id="icon-tooltip" onClick={showHandler}>
        {isOpen ? <ColorTooltipIcon /> : <TooltipIcon />}
      </IconButton>
      <ReactTooltip
        id="icon-tooltip"
        place="bottom"
        delayShow={300}
        content={content}
        isOpen={isOpen}
        className="tooltip"
      />
    </Container>
  );
};

ContextualHelp.propTypes = {
  content: PropTypes.string,
};
