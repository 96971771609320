import styled from "styled-components";
import { Button } from "shared/components/Button";

export const Wrapper = styled.div`
  width: 400px;
  position: relative;
  border-radius: 10px;
  background: var(--color_background);
  opacity: 1;
  transition: opacity 0.5s ease-in;
  pointer-events: auto;
  &.hidden {
    opacity: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconBox = styled.div`
  border-radius: 10px 0 0 10px;
  background: ${(props) => props.backgroundColor};
  padding: 16px 8px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
`;

export const Heading = styled.div`
  font-weight: 688;
  font-size: 14px;
  line-height: 16px;
  color: var(--color_text_light);
  margin-bottom: 8px;
`;

export const ContentBox = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color_text_light);
  margin-bottom: 8px;
`;

export const ActionBox = styled.div`
  display: flex;
  gap: 8px;
`;

export const ButtonStyled = styled(Button)`
  padding: 4px;
`;
