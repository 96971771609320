import { useMemo } from "react";

export const DOTS = "...";

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const minItemCount = 3; // First , Current and Last pages
const sidesCount = 2; // Count the sibling to the left and right of the current page

export const usePagination = ({
  totalPages,
  currentPage,
  siblingCount = 1,
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = totalPages;
    const itemCount = siblingCount * sidesCount + minItemCount;
    if (itemCount >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    const requiredNumberItemsOnSide = 2; // First and Current page OR Current and Last Page
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = requiredNumberItemsOnSide + sidesCount * siblingCount;
      if (currentPage === 1) {
        leftItemCount = 1 + siblingCount;
      }
      if (currentPage === 2) {
        leftItemCount = requiredNumberItemsOnSide + siblingCount;
      }
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount =
        requiredNumberItemsOnSide + sidesCount * siblingCount;
      if (currentPage === totalPageCount) {
        rightItemCount = 1 + siblingCount;
      }
      if (currentPage === totalPageCount - 1) {
        rightItemCount = requiredNumberItemsOnSide + siblingCount;
      }
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
  }, [totalPages, siblingCount, currentPage]);

  return paginationRange;
};
