import { addDays, format } from "date-fns";

const getFutureDate = (daysInTheFuture = 1) => {
  const futureDate = format(
    addDays(new Date(), daysInTheFuture),
    "MM/dd/yyyy"
  ).toString();
  return `${futureDate} 12:00 PM`;
};

const getDateConverted = (futureDate: string) => {
  const arr = futureDate.split(" ");
  return `${arr[0]} - ${arr[1]} ${arr[2]}`;
};

const getRandomString = () => {
  return Math.random().toString(36).substring(2, 7);
};

const toPattern = (string: string) => {
  return string.replaceAll(" ", "-").toLowerCase();
};

export { getFutureDate, getDateConverted, getRandomString, toPattern };
