import {
  startOfMonth,
  startOfYear,
  subWeeks,
  subMonths,
  subYears,
  subDays,
  endOfDay,
  format,
} from "date-fns/fp";
import { startOfWeek } from "date-fns";
import flow from "lodash/fp/flow";
import { useTranslation } from "react-i18next";

const f = "yyyy-MM-dd'T'HH:mm:ssXXX";

export const useTimePeriodOptions = () => {
  const { t } = useTranslation();

  const today = format(f)(endOfDay(new Date()));
  const startOfWeekFunc = (date) => startOfWeek(date, { weekStartsOn: 1 });

  const options = [
    {
      value: "prevWeek",
      label: t("reporting.periodOptions.prevWeek"),
      startDate: flow(startOfWeekFunc, subWeeks(1), format(f))(new Date()),
      endDate: flow(
        startOfWeekFunc,
        subDays(1),
        endOfDay,
        format(f)
      )(new Date()),
    },
    {
      value: "prevMonth",
      label: t("reporting.periodOptions.prevMonth"),
      startDate: flow(startOfMonth, subMonths(1), format(f))(new Date()),
      endDate: flow(startOfMonth, subDays(1), endOfDay, format(f))(new Date()),
    },
    {
      value: "prevYear",
      label: t("reporting.periodOptions.prevYear"),
      startDate: flow(startOfYear, subYears(1), format(f))(new Date()),
      endDate: flow(startOfYear, subDays(1), endOfDay, format(f))(new Date()),
    },
    {
      value: "thisWeek",
      label: t("reporting.periodOptions.thisWeek"),
      startDate: flow(startOfWeekFunc, format(f))(new Date()),
      endDate: today,
    },
    {
      value: "thisMonth",
      label: t("reporting.periodOptions.thisMonth"),
      startDate: flow(startOfMonth, format(f))(new Date()),
      endDate: today,
    },
    {
      value: "thisYear",
      label: t("reporting.periodOptions.thisYear"),
      startDate: flow(startOfYear, format(f))(new Date()),
      endDate: today,
    },
    {
      value: "30d",
      label: t("reporting.periodOptions.30d"),
      startDate: flow(subDays(30), format(f))(new Date()),
      endDate: today,
    },
  ];

  return options;
};

export const useDefaultTimePeriod = () => useTimePeriodOptions()[1];
