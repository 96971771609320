import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Input } from "shared/components/Input";
import { InlineLabel } from "shared/components/widgets";
import { Dropdown } from "shared/components/Dropdowns";
import { Select } from "shared/components/Select";
import { rateTypeOptions } from "../../configs/constants";
import { usePermission } from "app/providers/PermissionsProvider";

export const WorkOrderTimeEditor = ({
  usersOptions,
  onUsersSearch,
  form,
  withUsers = true,
}) => {
  const { t } = useTranslation();
  const { canShowUserField } = usePermission();

  return (
    <Container>
      <InlineLabel label={t("work_order_time.form.amount")} labelWidth={110}>
        <Input
          name="amount"
          value={form.values.amount}
          onChange={(e) => {
            const inputValue = e.target.value;
            let selection = e.target.selectionStart;

            // Format the inputValue
            let val = inputValue.replace(/\D/g, "").padStart(4, "0");
            let l = val.length;
            val = val.substring(0, l - 2) + ":" + val.substring(l - 2);
            val = val.replace(/^0/, "").padStart(4, "0");

            // Find the next cursor position
            if (inputValue.length <= 1) {
              // If the input was cleared, go to the end
              selection = val.length;
            } else if (
              e.nativeEvent.inputType === "deleteContentBackward" &&
              selection === e.target.value.length
            ) {
              // If the last char was removed with backspace,
              //  keep the cursor at the end
              selection = val.length;
            } else if (
              e.nativeEvent.inputType === "deleteContentForward" &&
              inputValue.length <= 3
            ) {
              // If the delete key was pressed but the value didn't change
              //  length, move the cursor to the right
              selection = selection + 1;
            }

            e.target.value = val;
            e.target.setSelectionRange(selection, selection);
            form.handleChange(e);
          }}
          onBlur={form.handleBlur}
          placeholder={t("work_order_time.form.placeholder.amount")}
          errorMessage={form.touched.amount && form.errors.amount}
        />
      </InlineLabel>
      <InlineLabel label={t("work_order_time.form.rate_type")} labelWidth={110}>
        <Dropdown
          name="rate_type"
          value={form.values.rate_type}
          options={rateTypeOptions}
          defaultOptions={rateTypeOptions}
          setValue={form.handleSetValue}
          errorMessage={form.touched.rate_type && form.errors.rate_type}
          withSearch={false}
          withClear={false}
        />
      </InlineLabel>
      {withUsers && canShowUserField() && (
        <InlineLabel label={t("work_order_time.form.user_id")} labelWidth={110}>
          <Select
            name="user"
            value={form.values.user}
            options={usersOptions}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.user && form.errors.user}
            onSearch={onUsersSearch}
            onTouch={form.handleSetTouched}
          />
        </InlineLabel>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-weight: 500;
`;
