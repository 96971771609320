import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 40px;
`;

export const Box = styled.button`
  width: 100%;
  border: ${({ active }) =>
    active
      ? "2px solid var(--color_primary_text)"
      : "1px solid var(--color_grey200)"};
  background-color: ${({ active }) =>
    active ? "var(--color_primary_hover)" : "var(--color_background_light)"};

  &:hover {
    border-color: var(--color_primary_dark);
    background-color: var(--color_primary_hover);
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:disabled {
    background-color: ${({ active }) =>
      active ? "var(--color_primary_hover)" : "var(--color_background_light)"};
    border: var(--color_primary_dark_40);
    box-shadow: none;
    cursor: initial;
  }
`;
