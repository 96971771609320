export const pickEntriesByKey = (source, targetKeys = []) => {
  const filteredSourceKeys = Object.keys(source).filter((key) =>
    targetKeys.includes(key)
  );
  const target = {};

  for (const key of filteredSourceKeys) {
    target[key] = source[key];
  }

  return target;
};
