import React from "react";
import { HeaderContainer, Label, TopHeader, SubHeader } from "./Modals.styled";
import PropTypes from "prop-types";
import { heading } from "@test-data";

export const EditorHeader = ({
  label = "Editor",
  action = null,
  variant = "dark",
  subHeader,
}) => {
  return (
    <HeaderContainer variant={variant}>
      <TopHeader variant={variant}>
        <Label variant={variant} data-testid={heading.editorModal.id}>
          {label}
        </Label>
        {action}
      </TopHeader>
      {subHeader && <SubHeader variant={variant}>{subHeader}</SubHeader>}
    </HeaderContainer>
  );
};

EditorHeader.propTypes = {
  label: PropTypes.string,
  action: PropTypes.element,
};
