import { useTranslation } from "react-i18next";

export const useListRows = (data, type) => {
  const { t } = useTranslation();

  return Object.keys(data).map((el) => {
    return {
      required: data[el].required,
      visible: data[el].visible,
      name: data[el].label || t(`settings.labels.${type}.${el}`),
      field: el,
      id: el,
    };
  });
};
