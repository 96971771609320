import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ActiveIcon from "../../../assets/icons/active.svg?react";
import Inactive from "../../../assets/icons/inactive.svg?react";
import Sent from "../../../assets/icons/sent.svg?react";
import ExpiredIcon from "../../../assets/icons/cross.svg?react";

const ProfileStatusMap = {
  active: {
    Icon: ActiveIcon,
    color: "status_lime",
  },
  change_email_in_progress: {
    Icon: Sent,
    color: "status_grey",
  },
  inactive: {
    Icon: Inactive,
    color: "status_red",
  },
  invitation_sent: {
    Icon: Sent,
    color: "status_grey",
  },
  invitation_not_sent: {
    Icon: ExpiredIcon,
    color: "status_red",
  },

  verification_pending: {
    Icon: Sent,
    color: "status_grey",
  },

  invitation_expired: {
    Icon: ExpiredIcon,
    color: "status_red",
  },

  verification_expired: {
    Icon: ExpiredIcon,
    color: "status_red",
  },
  default: {
    Icon: "",
    color: "",
  },
};

export const Status = ({ value }) => {
  const { t } = useTranslation();
  const { Icon, color } = ProfileStatusMap[value || "default"];
  return (
    <StatusContainer color={color}>
      <Icon /> <span>{t(`allUsers.status.${value}`)}</span>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: var(--color_grey700);
  border: 2px solid ${({ color }) => `var(--color_${color})`};
  font-falimy: inherit;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  line-height: 16px;
  border-radius: 12px;
  gap: 4px;
`;
