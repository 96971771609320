import { ModalParams } from "widgets/ModalController/types";
import { Link } from "shared/components/Link";
import { useLocation } from "react-router-dom";
import { getModalPath } from "widgets/ModalController/getModalPath";
import { ComponentProps } from "react";

interface ModalLinkProps extends Omit<ComponentProps<typeof Link>, "to"> {
  modalParams: ModalParams;
}

/** A link to open a ModalController-managed modal */
export function ModalLink({ modalParams, ...props }: ModalLinkProps) {
  const location = useLocation();
  const { type, id, state } = modalParams;
  return <Link {...props} to={getModalPath(location, type, id, state)} />;
}
