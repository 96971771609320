import styled from "styled-components";

export const ReactivePreventiveChartWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const ReactivePreventiveChart = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;

  display: flex;
  gap: 2px;
  width: 100%;
  height: 11px;
`;

interface ReactivePreventiveChartBarProps {
  $width: number;
  $color: string;
}

export const ReactivePreventiveChartBar = styled.div<ReactivePreventiveChartBarProps>`
  height: 100%;
  width: ${(p) => p.$width}%;
  background-color: ${(p) => p.$color};
`;
