import React from "react";
import styled from "styled-components";
import { Switch } from "shared/components/Switch";
import SwitchResumeIcon from "../../assets/icons/resume.svg?react";
import SwitchPauseIcon from "../../assets/icons/paused.svg?react";

export const PMTitle = ({
  id,
  value,
  onClick,
  status,
  updateStatus,
  updatingPMs = [],
  isToggleActive,
}) => {
  return (
    <TitleContainer>
      <TitleValue onClick={onClick}>{value}</TitleValue>
      {status !== "completed" && isToggleActive && (
        <Switch
          activated={status === "planned"}
          onClick={() =>
            updateStatus({
              id,
              body: {
                status: status === "planned" ? "paused" : "planned",
              },
            })
          }
          activateIcon={<SwitchResumeIcon />}
          nonActivateIcon={<SwitchPauseIcon />}
          isLoading={updatingPMs.includes(id)}
          disabled={updatingPMs.includes(id)}
        />
      )}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const TitleValue = styled.span`
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
`;
