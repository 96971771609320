import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { getValidFileList } from "../../lib/helper";
import { acceptFormat } from "../../lib/constants";
import { Button } from "shared/components/Button";
import styled from "styled-components";

export const FilesPickerButton = ({
  files,
  setFiles,
  allowedNumberOfFiles,
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const inputRef = useRef();

  const showError = (message) => {
    return addSnackBar({
      title: t("files.image.error.upload"),
      content: JSON.stringify(message),
      type: "error",
      secondaryButtonLabel: t("close"),
    });
  };

  const handleClick = () => {
    return inputRef.current.click();
  };

  const handleSelectFiles = (event) => {
    const { validFileList, error } = getValidFileList(
      files || [],
      event.target.files,
      t,
      allowedNumberOfFiles
    );
    if (error) {
      showError(error);
    }
    setFiles([...(files || []), ...validFileList]);
  };

  return (
    <CustomButton variant="secondary" onClick={handleClick}>
      {t("profile.personalInfo.uploadPicture")}
      <input
        ref={inputRef}
        type="file"
        multiple
        onChange={handleSelectFiles}
        hidden
        accept={acceptFormat.join(", ")}
      />
    </CustomButton>
  );
};

export const CustomButton = styled(Button)``;
