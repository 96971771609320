import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "widgets/Header";
import { List as PreventiveMaintenancesList } from "modules/preventiveMaintenances";
import { Container } from "shared/components/Container/Container";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { usePermission } from "app/providers/PermissionsProvider";
import { ModalLink } from "shared/components/ModalLink";
import { useSetModal } from "widgets/ModalController";

export const PreventiveMaintenancesPage = () => {
  const { t } = useTranslation();
  const { pmsViewPermit } = usePermission();
  const setModal = useSetModal();

  return (
    <TablePageLayout>
      {pmsViewPermit && (
        <>
          <Header
            title={t("preventive_maintenances.title")}
            searchPlaceholder={t("preventive_maintenances.searchPlaceholder")}
            action={
              <ModalLink
                type="button"
                icon={<AddIcon />}
                modalParams={{ type: "wo", id: "new" }}
              >
                {t("work_orders.title_one")}
              </ModalLink>
            }
            horizontallySpaciousPage
          />
          <Container>
            <PreventiveMaintenancesList
              onOpen={(id) => setModal({ type: "pm", id, state: "view" })}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
