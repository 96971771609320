import { useDownloadXLSMutation } from "modules/reporting/state/reportingApi";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useTranslation } from "react-i18next";

/** Fetches report table data for a given `reportId` in XLS format */
export const useDownloadReportXLS = (
  reportKey: string,
  reportPeriod?: ReportPeriod
) => {
  const { t } = useTranslation();
  const [downloadXLS] = useDownloadXLSMutation();
  const { addSnackBar } = useSnackBar();

  return () =>
    downloadXLS({
      reportId: reportKey,
      start_date: reportPeriod?.startDate,
      end_date: reportPeriod?.endDate,
    })
      .unwrap()
      .catch(() => {
        addSnackBar({
          content: t(`reporting.exportMenu.error`),
          type: "warning",
        });
      });
};
