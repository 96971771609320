import styled from "styled-components";
import { H2 } from "shared/components/Typography";
import { Divider } from "shared/components/Divider";

export const ViewHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PMInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled(H2)`
  margin-bottom: 20px;
`;

export const HeaderDividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
