import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { ModalLink } from "shared/components/ModalLink";

const TrimCellComponent = ({ text, isLast, withTrim, id }) => {
  const ref = useRef(null);

  const cell = ref.current;
  const isTrimmed = cell?.offsetWidth < cell?.scrollWidth;

  return (
    <TrimCell
      ref={ref}
      title={isTrimmed ? text : ""}
      isLast={isLast}
      withTrim={withTrim}
    >
      {id ? (
        <ModalLink modalParams={{ type: "location", id }}>{text}</ModalLink>
      ) : (
        text
      )}
    </TrimCell>
  );
};

export const LocationPath = ({ location, withTrim = true }) => {
  if (!location) return null;
  const { path: value } = location;

  const values = value.split(/( > )/)?.filter((s) => s);

  if (withTrim && values.length > 5) {
    values.splice(2, values.length - 6, "...");
  }

  return (
    <Container withTrim={withTrim}>
      {values.map((val, index) => {
        const isTrimmed = withTrim && ![" > ", "..."].includes(val);
        const isLast = index === values.length - 1;

        return (
          <TrimCellComponent
            key={`${index}-${val}`}
            text={val}
            isLast={isLast}
            withTrim={isTrimmed}
            id={isLast ? location.id : undefined}
          />
        );
      })}
    </Container>
  );
};

const TrimCell = styled.span`
  padding-left: 2px;

  ${({ isLast }) =>
    isLast &&
    css`
      max-width: fit-content;
      width: 100%;
      color: var(--color_grey700);
    `}

  ${({ withTrim }) =>
    withTrim &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

const Container = styled.div`
  font-size: 14px;
  max-width: 100%;
  align-items: center;
  color: var(--color_grey400);

  ${({ withTrim }) =>
    withTrim &&
    css`
      display: flex;
    `}
`;
