import React from "react";
import PropTypes from "prop-types";
import {
  ProgreesContainer,
  ProgreesWrapper,
  ProgressStatus,
  Progress,
  NameContainer,
} from "./ProgressBar.styled";

export const ProgressBar = ({ className, name, value }) => (
  <ProgreesContainer className={className}>
    <ProgreesWrapper value={value}>
      <Progress min="0" max="100" />
    </ProgreesWrapper>
    <ProgressStatus>
      <NameContainer>{name}</NameContainer>
      <span>- {value}%</span>
    </ProgressStatus>
  </ProgreesContainer>
);

ProgressBar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
};
