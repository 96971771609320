import React from "react";
import styled from "styled-components";
import { useDisplayDate } from "shared/lib/hooks";

export const WorkOrderRequest = ({ data }) => {
  const displayDate = useDisplayDate();

  const name = `${data.created_by.name || ""} ${
    data.created_by.surname || ""
  } on ${displayDate(data.created_at, true)}`;
  const number = data.created_by.phone_number;
  const email = data.created_by.email;

  return (
    <Container>
      <div>{name}</div>
      <div>{number && number}</div>
      <div>{email && email}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color_text_color);
  font-size: 14px;
  line-height: 20px;
`;
