import React from "react";
import { Document, Page } from "@react-pdf/renderer";
import { muiltipleStyles, singleStyles } from "modules/qrcodes";
import { QRCodeInformationBox } from "./QRCodeInformationBox";

export const PDFDocument = ({
  qrCodes = [],
  type = "single",
  module,
  isShowCode,
}) => {
  return (
    <Document>
      {type === "single" ? (
        qrCodes.map((code) => (
          <Page size={[360, "auto"]}>
            <div style={singleStyles.box}>
              <QRCodeInformationBox
                code={code}
                styles={singleStyles}
                module={module}
                isShowCode={isShowCode}
              />
            </div>
          </Page>
        ))
      ) : (
        <Page size="A4" style={muiltipleStyles.page}>
          <div style={muiltipleStyles.box}>
            {qrCodes.map((code) => (
              <QRCodeInformationBox
                code={code}
                styles={muiltipleStyles}
                module={module}
              />
            ))}
          </div>
        </Page>
      )}
    </Document>
  );
};
