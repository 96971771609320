import React from "react";
import PropTypes from "prop-types";
import { Container, IconWrapper } from "./MenuItem.styled";

export const MenuItem = ({
  value = null,
  onClick = () => null,
  selected = false,
  label = "Option",
  startIcon = null,
  endIcon = null,
  category = null,
}) => {
  return (
    <Container
      onClick={() => onClick(value, label, category)}
      selected={selected}
    >
      {!!startIcon && <IconWrapper isStart>{startIcon}</IconWrapper>}
      {label}
      {!!endIcon && <IconWrapper isEnd>{endIcon}</IconWrapper>}
    </Container>
  );
};

MenuItem.propTypes = {
  vaselectedriant: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
  category: PropTypes.string,
};
