import styled, { css } from "styled-components";

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--color_text_color);
`;

export const InlineLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--color_text_color);
  align-items: center;
  display: flex;
  max-width: 110px;
  width: 100%;
`;

export const InputContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 4px;

  &:not(:first-child) {
    padding-top: 30px;
  }
`;

export const TextBox = styled.div`
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 16px;
`;

export const Box = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;

      &:hover,
      &:active {
        cursor: default;
      }
    `};
`;

export const Container = styled.div`
  background: var(--color_grey10);
  border: 1px solid var(--color_grey100);
  padding: 30px 20px;
  border-radius: 0 0 4px 4px;
`;

export const TypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 40px;
`;

export const TypeContainer = styled.button`
  width: 100%;
  border: ${({ active }) =>
    active
      ? "2px solid var(--color_primary_text)"
      : "1px solid var(--color_grey200)"};
  background-color: ${({ active }) =>
    active ? "var(--color_primary_hover)" : "var(--color_background_light)"};

  &:hover {
    border-color: var(--color_primary_dark);
    background-color: var(--color_primary_hover);
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:disabled {
    background-color: ${({ active }) =>
      active ? "var(--color_primary_hover)" : "var(--color_background_light)"};
    border: var(--color_primary_dark_40);
    box-shadow: none;
    cursor: initial;
  }
`;

export const TextContainer = styled.div`
  padding-top: 30px;
`;

export const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`;

export const RepeatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DropdownsBox = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;
