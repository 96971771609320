import { Description } from "shared/components/Typography";
import styled from "styled-components";

export { getStockOptions } from "./view";
export {
  getPartFormatForService,
  getCostFormatForService,
  getOptionalFields,
  getInitialValues,
  canEditCostFields,
} from "./form";

export { usePartTransactionsTableTools } from "./partTransactions/usePartTransactionsTableTools";
export { usePartsTableTools } from "./usePartsTableTools";

export const getViewRows = (fields, data, t, displayDate) => {
  const rows = fields.reduce((acc, field) => {
    const value = data?.[field];

    if (field === "created_at") {
      acc.push({
        label: t("parts.transaction.created_at"),
        value: displayDate(value, true, true),
      });
    }
    if (field === "quantity") {
      acc.push({
        label: t("parts.transaction.quantity"),
        value: value,
      });
    }
    if (field === "cost") {
      acc.push({
        label: t("parts.transaction.cost"),
        value: value,
      });
    }
    if (field === "note") {
      acc.push({
        label: t("parts.transaction.note"),
        value: <Note>{value}</Note>,
      });
    }

    return acc;
  }, []);

  return { visibleRows: rows };
};

const Note = styled(Description)`
  margin: 0;
  max-width: 100%;
  word-break: break-word;
`;
