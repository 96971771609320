export const InviteSignUpFormFieldsInitialValues = {
  name: "",
  surname: "",
  password: "",
};

export const InviteSignUpFormFields = Object.keys(
  InviteSignUpFormFieldsInitialValues
);

export const LoginInitialValues = { email: "", loginPassword: "" };

export const LoginFormFields = Object.keys(LoginInitialValues);

export const SignUpInitialValues = {
  email: "",
  name: "",
  surname: "",
  password: "",
  company: "",
  acceptCommunication: true,
  acceptTerms: false,
};

export const SignUpFields = Object.keys(SignUpInitialValues);
