import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";

import { otherCostValidationSchema } from "../../configs/validationSchema";
import { WorkOrderOtherCostEditor } from "../WorkOrderOtherCostEditor";
import { getInitialValues } from "../../lib/helpers";

export const WorkOrderOtherCostUpdate = ({
  id,
  index,
  onUpdate,
  onDelete,
  onClose,
  usersOptions,
  onUsersSearch,
  data,
  permissions,
}) => {
  const { t } = useTranslation();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const determinant = id || index;

  const closeModal = () => {
    editOtherCostForm.resetForm();
    onClose();
  };

  const onOpenDeleteModal = () => {
    setIsShowDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
  };

  const onDeleteHandle = () => {
    onDelete(determinant);
    onCloseDeleteModal();
    closeModal();
  };

  const editOtherCostForm = useForm({
    initialValues: getInitialValues({ data, type: "edit" }),
    validationSchema: otherCostValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (body) => {
      onUpdate(body, determinant);
      closeModal();
    },
  });

  return (
    <>
      <ConfirmationDialog
        title={t(`work_order_other_costs.edit`)}
        confirmButtonLabel={t("save")}
        cancelButtonLabel={t("discardChanges")}
        styles={{ "overflow-y": "auto" }}
        form={editOtherCostForm}
        disabledSubmit={
          !editOtherCostForm.dirty ||
          !!Object.values(editOtherCostForm.errors).length
        }
        description={
          <WorkOrderOtherCostEditor
            usersOptions={usersOptions}
            onUsersSearch={onUsersSearch}
            form={editOtherCostForm}
          />
        }
        onProceed={editOtherCostForm.handleSubmit}
        onCancel={closeModal}
        onDelete={permissions?.delete && onOpenDeleteModal}
      />
      {isShowDeleteModal && (
        <ConfirmationDialog
          title={t("work_order_other_costs.delete.confirmation.title")}
          description={t(
            "work_order_other_costs.delete.confirmation.description"
          )}
          confirmButtonLabel={t(
            "work_order_other_costs.delete.confirmation.confirm"
          )}
          onProceed={onDeleteHandle}
          onCancel={onCloseDeleteModal}
        />
      )}
    </>
  );
};
