import { useEffect, useMemo } from "react";
import { FormikHelpers, useFormik } from "formik";
import PropTypes from "prop-types";

interface useFormProps {
  initialValues: object;
  willUpdatedValues?: () => void;
  validationSchema: object;
  onSubmit: (body: object, config: FormikHelpers<object>) => Promise<void>;
  validateOnChange?: boolean;
  enableReinitialize?: boolean;
}

interface CustomFormikFunctions {
  handleSetValue: (name: string, value: object) => void;
  handleSetTouched: (name: string) => void;
  isFormValid: boolean;
  isWasChanged: boolean;
}

export type CustomFormik = CustomFormikFunctions & ReturnType<typeof useFormik>;

export const useForm = ({
  initialValues,
  willUpdatedValues,
  validationSchema,
  onSubmit,
  validateOnChange = true,
  enableReinitialize = true,
}: useFormProps): CustomFormik => {
  const { setValues, ...form } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize,
    validateOnChange,
  });

  const customForm: CustomFormik = form as CustomFormik;

  useEffect(() => {
    willUpdatedValues && setValues(willUpdatedValues);
  }, [willUpdatedValues, setValues]);

  const isWasChanged = useMemo(
    () => JSON.stringify(willUpdatedValues) !== JSON.stringify(form.values),
    [willUpdatedValues, form.values]
  );

  customForm.handleSetValue = async (
    name: string,
    value: object,
    touched = true
  ) => {
    await form.setFieldValue(name, value, true);
    await form.setFieldTouched(name, true, false);
  };

  customForm.handleSetTouched = (name) =>
    form.setFieldTouched(name, true, false);

  customForm.isFormValid =
    Object.keys(form.errors).length === 0 &&
    Object.keys(form.touched).length !== 0;

  customForm.isWasChanged = isWasChanged;

  return customForm;
};

export const propTypesFormik = PropTypes.shape({
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSetValue: PropTypes.func,
  handleSetTouched: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isFormValid: PropTypes.bool,
});

export const defaultPropsFormik = {
  values: {},
  errors: {},
  touched: {},
  handleSubmit: () => null,
  handleChange: () => null,
  handleBlur: () => null,
  handleSetValue: () => null,
  handleSetTouched: () => null,
};
