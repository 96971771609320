export const getStockOptions = ({ min = 0, onHand, desired, nonStock }) => {
  let containerColor = "var(--color_grey100)";
  let percentageColor = "var(--color_grey100)";
  let percentage = 0;

  if (nonStock || (!onHand && !desired)) {
    return { containerColor, percentageColor, percentage };
  }

  if (!desired && onHand && min) {
    if (onHand >= min) {
      containerColor = "var(--color_corp_lime_dark)";
    } else {
      containerColor = "var(--color_corp_red)";
    }

    return { containerColor, percentageColor, percentage };
  }

  percentage = (onHand / desired) * 100;

  if (percentage < 33) {
    percentageColor = "var(--color_corp_red)";
    containerColor = "var(--color_corp_red_light)";
  }

  if (percentage >= 33 && percentage < 66) {
    percentageColor = "var(--color_corp_yellow)";
    containerColor = "var(--color_corp_yellow_light)";
  }

  if (percentage >= 66) {
    percentageColor = "var(--color_corp_lime_dark)";
    containerColor = "var(--color_corp_lime_light)";
  }

  return { containerColor, percentageColor, percentage };
};
