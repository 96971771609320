import { format, parseISO } from "date-fns";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";

const f = "yyyy-MM-dd";

/** Puts together a filename for a report download */
export const getReportFilename = (
  reportId: string,
  ext: "xls" | "pdf",
  reportPeriod?: Partial<ReportPeriod>
) => {
  let rangeString = undefined;
  if (reportPeriod?.startDate && reportPeriod?.endDate) {
    const startFormatted = format(parseISO(reportPeriod.startDate), f);
    const endFormatted = format(parseISO(reportPeriod.endDate), f);
    rangeString = `[${startFormatted}--${endFormatted}]`;
  }

  const parts = [
    format(new Date(), f),
    `${reportId}_report`,
    rangeString,
  ].filter((i) => !!i);

  return `${parts.join(" ")}.${ext}`;
};
