import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import PlannedIcon from "../../assets/icons/planned.svg?react";
import PausedIcon from "../../assets/icons/paused.svg?react";
import CompletedIcon from "../../assets/icons/completed.svg?react";

const StatusMap = {
  planned: {
    Icon: PlannedIcon,
    color: "status_lime",
  },
  paused: {
    Icon: PausedIcon,
    color: "status_grey",
  },
  completed: {
    Icon: CompletedIcon,
    color: "status_green",
  },
};

export const Status = ({ value }) => {
  const { t } = useTranslation();
  const { Icon, color } = StatusMap[value];
  return (
    <StatusContainer color={color}>
      <Icon /> <span>{t(`preventive_maintenances.status.${value}`)}</span>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color_grey700);
  border: 2px solid ${({ color }) => `var(--color_${color})`};
  font-falimy: inherit;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  line-height: 16px;
  border-radius: 12px;
  gap: 4px;
`;
