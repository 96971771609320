import { sortVendors } from "shared/lib/helpers/common";
import { getFormDataFromJSON } from "shared/lib/helpers/formData";

const stockTransactions = ["restock", "usage"];
const nonStockTransactions = ["cost_update", "usage"];

const getVendors = (vendors) => {
  const sortedVendors = sortVendors(vendors);

  return sortedVendors.map(
    ({ id, name, vendor_part_number, is_preferred }) => ({
      vendor: { value: id, label: name },
      is_preferred,
      vendor_part_number,
    })
  );
};

export const getPartFormatForService = (form, options) => {
  const {
    urls_attributes,
    uploads_attributes,
    vendors,
    part_type,
    storeroom,
    measurement_unit,
    assets,
    code,
    ...otherFields
  } = form;

  const { generateQr, type } = options;

  const data = getFormDataFromJSON({
    ...otherFields,
    part_type_id: part_type?.value || "",
    storeroom_id: storeroom?.value || "",
    measurement_unit_id: measurement_unit?.value || "",
    assets_ids: assets.map(({ value }) => value),
  });

  if (Array.isArray(vendors) && vendors.length > 0) {
    vendors.forEach(({ vendor, vendor_part_number, is_preferred }) => {
      data.append(`vendor_parts_attributes[][vendor_id]`, vendor.value);
      data.append(
        `vendor_parts_attributes[][vendor_part_number]`,
        vendor_part_number
      );
      data.append(`vendor_parts_attributes[][is_preferred]`, is_preferred);
    });
  } else {
    data.append(`vendors_ids[]`, []);
  }

  if (generateQr) {
    data.append(`qr_code_attributes[generate_qr_code]`, `true`);
  } else if (code) {
    data.append(`qr_code_attributes[value]`, code);
  } else if (!code && type === "edit") {
    data.append("qr_code_attributes[_destroy_qr_code]", "true");
  }

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      data.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj.file) {
      data.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    }
  });

  urls_attributes.forEach((attribute, index) => {
    const { label, url } = attribute;
    if (label && url) {
      data.append(`urls_attributes[][label]`, label);
      data.append(`urls_attributes[][url]`, url);
    }
  });

  return data;
};

export const getCostFormatForService = (form, nonStock) => {
  const { note, unit_cost, uploads_attributes, quantity } = form;

  const transaction = new FormData();

  transaction.append("type", nonStock ? "cost_update" : "restock");

  if (unit_cost !== null) {
    transaction.append("cost", unit_cost);
  }

  transaction.append("note", note);

  if (!nonStock) {
    transaction.append("quantity", quantity);
  }

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      transaction.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj.file) {
      transaction.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    }
  });

  return transaction;
};

const PartFormOptionalFields = [
  "unit_cost",
  "number",
  "part_type_id",
  "assets_ids",
  "lead_time",
  "vendors_ids",
  "urls_attributes",
];

const PartCreateInitialValues = {
  name: "",
  description: "",
  is_non_stock: false,
  on_hand_qty: "",
  min_qty: "",
  desired_qty: "",
  unit_cost: "",
  number: "",
  area: "",
  lead_time: "",
  measurement_unit: null,
  part_type: null,
  storeroom: null,
  assets: [],
  vendors: [],
  // complex fields
  urls_attributes: [],
  code: "",
  uploads_attributes: [],
};

export const getOptionalFields = (fieldsData) => {
  if (!fieldsData || fieldsData?.status !== "success") {
    return {};
  }

  const { data } = fieldsData;

  return PartFormOptionalFields.reduce((acc, optionalField) => {
    const field = data.find(({ name }) => name === optionalField);
    if (field) {
      const { required } = field;
      acc[optionalField] = { required };
    }
    return acc;
  }, {});
};

export const getInitialValues = ({ type, data }) => {
  if (type === "create" || !data) {
    return PartCreateInitialValues;
  }

  const {
    name,
    description,
    is_non_stock,
    on_hand_qty,
    min_qty,
    desired_qty,
    area,
    measurement_unit,
    number,
    storeroom,
    part_type,
    qr_code,
    assets,
    lead_time,
    unit_cost,
    urls,
    images,
    documents,
    vendors,
  } = data;

  return {
    name,
    description: description ?? "",
    is_non_stock,
    on_hand_qty: on_hand_qty ?? "",
    min_qty: min_qty ?? "",
    desired_qty: desired_qty ?? "",
    area: area ?? "",
    number: number ?? "",
    measurement_unit: measurement_unit
      ? {
          value: measurement_unit.id,
          label: measurement_unit.name,
        }
      : null,
    storeroom: storeroom
      ? { value: storeroom.id, label: storeroom.name }
      : null,
    part_type: part_type
      ? { value: part_type.id, label: part_type.name }
      : null,
    code: qr_code ?? "",
    assets: assets.map(({ id, name }) => ({ value: id, label: name })),
    vendors: getVendors(vendors),
    lead_time: lead_time ?? "",
    unit_cost: unit_cost ?? "",
    urls_attributes: urls,
    uploads_attributes: images
      .map((image) => ({ ...image, isImage: true }))
      .concat(documents),
  };
};

export const canEditCostFields = (data) => {
  return !data?.part_transactions?.some((transaction) =>
    data.is_non_stock
      ? nonStockTransactions.includes(transaction.type)
      : stockTransactions.includes(transaction.type)
  );
};
