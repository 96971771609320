import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

interface KPIGridProps {
  isLoading?: boolean;
  kpis: {
    label: string;
    value: string;
  }[];
}

export const KPIGrid = ({ kpis, isLoading }: KPIGridProps) => {
  return (
    <Grid>
      {kpis.map((i, index) => (
        <Item key={index} isLoading={isLoading} {...i} />
      ))}
    </Grid>
  );
};

interface ItemProps {
  value: string;
  label: string;
  isLoading?: boolean;
}

const Item = ({ value, label, isLoading }: ItemProps) => {
  return (
    <div>
      <KPIValue>
        {isLoading ? <Skeleton height="1em" width="5ch" /> : value}
      </KPIValue>
      <KPILabel>{label}</KPILabel>
    </div>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const KPIValue = styled.div`
  color: var(--color_dark_blue);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  white-space: nowrap;
`;

const KPILabel = styled.div`
  color: var(--color_grey700);
  font-size: 14px;
  line-height: 1.4;
  white-space: nowrap;
`;
