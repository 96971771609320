import styled from "styled-components";

interface ToggleButtonGroupProps {
  /** Current active value */
  value: string;
  onChange: (val: string) => void;
  /** Accessible label for the group */
  ariaLabel: string;
  className?: string;
  options: {
    label: string;
    value: string;
    ariaLabel?: string;
  }[];
}

/** A set of buttons that can be toggled on/off */
export const ToggleButtonGroup = ({
  value,
  onChange,
  ariaLabel,
  className,
  options,
}: ToggleButtonGroupProps) => {
  return (
    <Wrapper role="group" aria-label={ariaLabel} className={className}>
      {options.map((i) => {
        const selected = value === i.value;
        return (
          <ToggleButton
            key={i.value}
            onClick={() => onChange(i.value)}
            className={value === i.value ? "selected" : undefined}
            type="button"
            role="radio"
            data-state={selected ? "on" : "off"}
            aria-checked={selected}
            aria-label={i.ariaLabel}
          >
            {i.label}
          </ToggleButton>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  isolation: isolate;
`;

interface ToggleButtonProps {
  selected?: boolean;
}

const ToggleButton = styled.button<ToggleButtonProps>`
  box-sizing: border-box;
  border: 1px solid var(--color_grey200);
  border-right-width: 0;
  padding: 4px 8px;

  &.selected {
    background-color: var(--color_primary_20);
    // Using a border + box-shadow to prevent element width from changing
    border: 1px solid var(--color_primary_text);
    box-shadow: inset 0 0 0 1px var(--color_primary_text);
  }

  .selected + & {
    // If a button comes after a selected button, remove the left border
    //  to allow the selected item's border to take up the space
    border-left-width: 0;
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-right-width: 1px;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px var(--color_primary_text);
    z-index: 1;

    &.selected {
      box-shadow: inset 0px 0px 0px 1px var(--color_primary_text),
        0 0 0 1px #fff, 0 0 0 3px var(--color_primary_text);
    }
  }
`;
