import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const useModalControl = (path) => {
  const navigate = useNavigate();
  const { search, state } = useLocation();

  const currentSearch = state?.search || search;

  const handleOpenModal = useCallback(
    (url, newTab = false) => {
      const fullPath = `/${path}/${url}${currentSearch}`;
      if (newTab) {
        window.open(fullPath, "_blank");
      } else {
        navigate(fullPath);
      }
    },
    [path, navigate, currentSearch]
  );

  const handleCloseModal = useCallback(() => {
    return navigate(`/${path}${currentSearch}`);
  }, [path, navigate, currentSearch]);

  return [handleOpenModal, handleCloseModal];
};
