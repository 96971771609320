import styled from "styled-components";
import { ActionList } from "./ActionList";

export const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  transition: padding 0.5s;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > ul {
    width: 100%;
  }
`;

export const TextItem = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: var(--color_corp_red);
`;

export const Container = styled.div`
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  height: 100%;
  max-height: calc(100vh);
  width: 266px;
  margin: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--color_background);
  color: var(--color_text_light);
  overflow-x: hidden;
  overflow-y: scroll;
  transition: font-size 0.5s, padding 0.5s, margin 0.5s, width 0.5s;

  &.hidden {
    width: 64px;
    min-width: 64px;
  }
  &.hidden ${Wrapper} {
    padding: 0 8px;
  }
  &.hidden a > div {
    margin-left: 14px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const CollapseButton = styled.div`
  width: 100%;
  border-top: var(--color_border);
`;

export const BottomActionList = styled(ActionList)`
  margin-top: auto;
  padding-bottom: 5px;
  & > div {
    margin: 0;
    padding: 15px 0;
    border-bottom: 1px solid var(--color_border200);
  }
  & > div:last-child {
    border-bottom: none;
  }
`;

export const SidebarButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-weight: 600;
  color: var(--color_text_light);
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px 20px;
  }
  & > div > svg {
    width: 20px;
    margin-right: 10px;
  }
  & > div > svg > path {
    stroke: var(--color_text_light);
  }

  &:hover {
    background: var(--color_primary_hover);
  }
  &.active {
    color: var(--color_text_color);
    & > div > svg > path {
      stroke: var(--color_text_color);
    }
    & > div > svg > circle {
      stroke: var(--color_text_color);
    }
    background: var(--color_primary);
    &:hover {
      background: var(--color_primary);
    }
  }
`;
