import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { usePermission } from "app/providers/PermissionsProvider";
import { BulkDeletion } from "features/BulkDeletion";
import { useDeleteVendorsMutation } from "modules/vendors/state/vendorsApi";

export const BulkActions = ({ selectionModel }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();

  const [deleteVendors] = useDeleteVendorsMutation();

  const { vendorsListDeletePermit } = usePermission();

  const count = selectionModel.length;

  const deletingSuccessHandler = () => {
    addSnackBar({
      content: t("vendors.bulkActions.delete.success", { count }),
      type: "success",
    });
  };

  const deleteErrorHandler = (error) => {
    const names = [];
    error.forEach((error) => {
      names.push(error?.body?.record?.name || "");
    });
    addSnackBar({
      title: t("vendors.bulkActions.delete.warningTitle"),
      content: t("vendors.bulkActions.delete.warningContent", {
        names: names.join(", "),
      }),
      type: "error",
      secondaryButtonLabel: t("close"),
    });
  };

  return (
    <>
      {vendorsListDeletePermit && (
        <BulkDeletion
          ids={selectionModel}
          onDelete={deleteVendors}
          onSuccess={deletingSuccessHandler}
          onError={deleteErrorHandler}
          confirmation={{
            title: t("vendors.bulkActions.delete.confirmation.title"),
            description: t(
              "vendors.bulkActions.delete.confirmation.description",
              {
                count,
              }
            ),
            confirmButtonLabel: t(
              "vendors.bulkActions.delete.confirmation.confirmButtonLabel",
              { count }
            ),
          }}
        />
      )}
    </>
  );
};
