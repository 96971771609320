import React from "react";
import PropTypes from "prop-types";
import { Container, IconWrapper, Label } from "./Chip.styled";

export const Chip = ({
  className,
  label = "Chip",
  endIcon,
  startIcon,
  width,
}) => {
  return (
    <Container
      className={className}
      onClick={(e) => e.stopPropagation()}
      width={width}
    >
      {!!startIcon && <IconWrapper isStart>{startIcon}</IconWrapper>}
      <Label>{label}</Label>
      {!!endIcon && <IconWrapper isEnd>{endIcon}</IconWrapper>}
    </Container>
  );
};

Chip.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
};
