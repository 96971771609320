import React, { useMemo, useState, useEffect } from "react";

import {
  useGetRequestsQuery,
  useExportRequestsMutation,
} from "modules/requests";

import { Pagination } from "features/Pagination";

import {
  useSearch,
  usePageParam,
  useSortParam,
  useFiltersParam,
} from "shared/lib/hooks";

import { useGetLocationsQuery } from "modules/locations";
import { useGetVendorsQuery } from "modules/vendors";
import { useGetAssetsQuery } from "modules/assets";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetWorkOrdersQuery } from "modules/workOrders";
import { useGetRequestPortalsQuery } from "modules/requestPortals";
import { useGetTechniciansQuery } from "modules/users";

import { TopBar } from "widgets/TopBar";
import { NoResults } from "shared/components/NoResults";
import { Table } from "shared/components/Table";
import { EmptyRequestList } from "./EmptyRequestList";
import { ErrorRequestList } from "./ErrorRequestList";
import { BulkActions } from "../BulkActions";
import { useRequestsTableTools } from "modules/requests/lib/hooks/useRequestsTableTools";

import {
  FilterPriorityOptions,
  FilterStatusOptions,
} from "../../../filters/configs/constants";
import { isRequestOverdue } from "modules/requests/lib/helpers/isRequestOverdue";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import { PriorityIconUrlMap } from "shared/components/Priority";
import { useSearchParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters.js";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";

const defaultFilters = [
  {
    field: "status",
    operator: "_in",
    value: ["on_hold", "pending"],
  },
];

export const List = ({
  onOpen = () => {},
  onApprove = () => {},
  withExport,
}) => {
  const [selected, setSelected] = useState([]);
  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const [filters] = useFiltersParam(defaultFilters);

  const { columns, tableSettings, applySettings, areTableSettingsFetching } =
    useRequestsTableTools({ onOpen, onApprove });

  const {
    data,
    error = false,
    isFetching,
  } = useGetRequestsQuery({
    search,
    page,
    per_page: tableSettings?.per_page,
    sort,
    ...stringifyFilters(filters, "work_order_requests"),
  });
  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);

  useEffect(() => {
    if (data) {
      setSelected([]);
    }
  }, [data]);

  const selectedRequests = useMemo(() => {
    if (!selected.length) {
      return selected;
    }

    return selected.reduce((res, item) => {
      const request = data.data.find(({ id }) => id === item);

      if (request) {
        res.push({ id: request.id, status: request.status });
      }
      return res;
    }, []);
  }, [data, selected]);

  const [exportRequests] = useExportRequestsMutation();

  const [assets, onAssetsSearch] = useSearch(useGetAssetsQuery, true);
  const [vendors, onVendorsSearch] = useSearch(useGetVendorsQuery, true);
  const [locations, onLocationsSearch] = useSearch(useGetLocationsQuery, true);
  const [categories, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    true
  );
  const [workOrders, onWorkOrdersSearch] = useSearch(
    useGetWorkOrdersQuery,
    true,
    {},
    "title"
  );
  const [requestPortals, onRequestPortalsSearch] = useSearch(
    useGetRequestPortalsQuery,
    true,
    {},
    "title"
  );
  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    true,
    {},
    "full_name"
  );

  const getFilterOptions = (field) => {
    if (field === "categories") {
      return categories?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "location") {
      return locations?.data.map(({ id, path }) => ({ key: id, value: path }));
    }
    if (field === "asset") {
      return assets?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "vendors") {
      return vendors?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "priority") {
      return FilterPriorityOptions;
    }
    if (field === "status") {
      return FilterStatusOptions;
    }
    if (field === "work_order_id") {
      return workOrders?.data.map(({ id, title }) => ({
        key: id,
        value: title,
      }));
    }
    if (field === "request_portal_id") {
      return requestPortals?.data.map(({ id, title }) => ({
        key: id,
        value: title,
      }));
    }
    if (field === "assign_to") {
      return technicians?.data.reduce((acc, { id, name, surname, status }) => {
        if (status !== "invitation_sent") {
          acc.push({ key: id, value: `${name} ${surname}` });
        }
        return acc;
      }, []);
    }

    return [];
  };

  const getFilterSearch = (field) => {
    if (field === "location") {
      return onLocationsSearch;
    }
    if (field === "categories") {
      return onCategoriesSearch;
    }
    if (field === "asset") {
      return onAssetsSearch;
    }
    if (field === "vendors") {
      return onVendorsSearch;
    }
    if (field === "work_order_id") {
      return onWorkOrdersSearch;
    }
    if (field === "request_portal_id") {
      return onRequestPortalsSearch;
    }
    if (field === "assign_to") {
      return onTechniciansSearch;
    }

    return () => {};
  };

  const hasFilter = Object.keys(filters).length > 0;
  const empty = data?.data?.length === 0 && !isFetching && !hasFilter;
  const notFound =
    data?.data?.length === 0 &&
    !isFetching &&
    (search?.length > 0 || hasFilter);

  if (error) {
    return <ErrorRequestList />;
  } else if (empty && !notFound) {
    return <EmptyRequestList />;
  } else if (empty) {
    return <NoResults query={search} />;
  }

  return (
    <>
      <TopBar
        entity="work_order_requests"
        columns={columns}
        tableSettings={tableSettings}
        setTableSettings={applySettings}
        filterCount={Object.keys(filters).length}
        getOptions={getFilterOptions}
        getSearch={getFilterSearch}
        onExportClick={() => exportRequests({ ...filters, search })}
        withExport={withExport}
        showToggleFilters
        initialFilters={defaultFilters}
      />
      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading || areTableSettingsFetching}
        highlight={search}
        notFound={notFound}
        selectionModel={selected}
        onSelectionModelChange={setSelected}
        entityTranslationKey="work_order_requests.title"
        bulkActionsNode={<BulkActions requests={selectedRequests} />}
        getRowBorder={(row) =>
          isRequestOverdue(row.due_date, row.status)
            ? "var(--color_corp_red)"
            : undefined
        }
        getRowIcon={(row) => (
          <RowDecoratorImage
            src={row.priority ? PriorityIconUrlMap[row.priority] : undefined}
            width="16px"
            height="16px"
          />
        )}
      />
      <Pagination paginationData={data?.meta.pagination} label="Requests" />
    </>
  );
};
