import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

export const RequestLink = ({ uuid }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();

  const onCopyHandle = () => {
    const url = new URL(`/portal/${uuid}`, window.location.origin);
    navigator.clipboard.writeText(url);
    addSnackBar({
      content: t("request_portals.copyLink"),
      type: "success",
    });
  };

  return (
    <Button onClick={onCopyHandle} type="text">
      {t("request_portals.copy")}
    </Button>
  );
};

const Button = styled.button`
  font-size: 14px;
  line-height: 16px;
  color: var(--color_primary_text);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
`;
