export const getTimes = (minutes, times = "") => {
  const hours = times ? times.split(":")?.[0] : Math.floor(minutes / 60);
  const remainingMinutes = times ? times.split(":")?.[1] : minutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(remainingMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

export const roundTimeToNearestMinute = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  const roundedMinutes = Math.round(totalSeconds / 60);
  const roundedHours = Math.floor(roundedMinutes / 60);
  const remainingMinutes = roundedMinutes % 60;

  return `${roundedHours.toString().padStart(2, "0")}:${remainingMinutes
    .toString()
    .padStart(2, "0")}`;
};

export const isTimerOverdue = (date) => {
  const timeDifference = Math.abs(new Date() - new Date(date));
  const eightHoursInMilliseconds = 8 * 60 * 60 * 1000;
  return timeDifference > eightHoursInMilliseconds;
};

export const getFormattedTimer = (startTimerDate) => {
  const currentTime = new Date();
  const startDate = new Date(startTimerDate);
  const elapsedSeconds = Math.floor((currentTime - startDate) / 1000);

  const hours = Math.floor(elapsedSeconds / 3600);
  const minutes = Math.floor((elapsedSeconds % 3600) / 60);
  const seconds = elapsedSeconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
