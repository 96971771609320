import React from "react";
import { Empty } from "../Empty";
import NoResultsImage from "../../assets/no-results.svg?react";
import NoMatchesImage from "../../assets/no-matches.svg?react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";

export const NoResults = ({ query }) => {
  const { t } = useTranslation();

  return (
    <Empty
      icon={query ? <NoResultsImage /> : <NoMatchesImage />}
      heading={t(`noResults.title.${query ? "search" : "filter"}`)}
      description={
        <>
          {t(`noResults.description.${query ? "search" : "filter"}`)}
          {query && (
            <>
              {` ${t("noResults.preposition")} `}
              <Query>“{query}”</Query>
            </>
          )}
        </>
      }
    />
  );
};

NoResults.propTypes = {
  query: PropTypes.string,
};

const Query = styled.span`
  font-weight: 600;
`;
