import React, { Fragment } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TextLink } from "shared/components/TextLink";
import { sortVendors, filterVendorContacts } from "shared/lib/helpers/common";
import { ModalLink } from "shared/components/ModalLink";

export const VendorsList = ({ vendors = [] }) => {
  const { t } = useTranslation();

  if (vendors.length < 1) {
    return null;
  }

  return sortVendors(vendors).map(
    ({ id, name, is_preferred, vendor_contacts = [], vendor_part_number }) => (
      <VendorContainer key={id}>
        <VendorName>
          <ModalLink modalParams={{ type: "vendor", id }}>{name}</ModalLink>
          {is_preferred && (
            <VendorPreferred>{t("parts.details.preferred")}</VendorPreferred>
          )}
        </VendorName>
        {filterVendorContacts(vendor_contacts).map(
          ({ id, name, email, phone }) => (
            <Fragment key={id}>
              {name && <span>{name}</span>}
              {phone && (
                <span>
                  {t("parts.details.phone")}
                  {phone}
                </span>
              )}
              {email && (
                <TextLink as="a" href={"mailto:" + email}>
                  {email}
                </TextLink>
              )}
            </Fragment>
          )
        )}
        {vendor_part_number && (
          <span>
            {t("parts.details.number")}: {vendor_part_number}
          </span>
        )}
      </VendorContainer>
    )
  );
};

const VendorContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color_grey900);
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const VendorName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VendorPreferred = styled.div`
  background: var(--color_dark_blue);
  padding: 0 8px;
  color: var(--color_text_light);
  font-weight: 400;
  border-radius: 12px;
  margin-left: 8px;
`;
