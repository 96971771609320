import { dropUser } from "../components/Auth";

const portalPathRegex = /\/portal\/\w+/;

export const handleUnauthorizedMiddleware = (store) => (next) => (action) => {
  const { pathname } = window.location;

  if (
    action.error &&
    action.payload?.status === 401 &&
    !portalPathRegex.test(pathname)
  ) {
    dropUser();
    window.location.href = "/login/session-close";
  } else {
    return next(action);
  }
};
