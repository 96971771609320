import { useTranslation } from "react-i18next";
import { Link } from "shared/components/Link";
import { Empty } from "shared/components/Empty";
import styled from "styled-components";

import EmptyIcon from "../assets/EmptyList.svg?react";

export const EmptyList = ({ module }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Empty
        icon={<EmptyIcon />}
        heading={t(`settings.referenceLists.${module}.noReference`)}
        description={
          <>
            {t("empty.descriptionUse")}
            <Link to={`${module}/add`}>{t(`empty.${module}`)}</Link>
            {t("empty.descriptionButton")}
          </>
        }
        spacious
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 188px;
`;
