import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 2fr;
  align-items: center;
  gap: 12px;
  padding: 34px;
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 34px;
  font-weight: 600;
`;
