import styled, { css } from "styled-components";

export const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color_text_color);
  background: var(--color_grey50);
  border-radius: 2px;
  max-width: ${({ width }) => width ?? 100}%;
  &.hidden {
    display: none;
  }
`;

export const Label = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  ${({ isEnd }) =>
    isEnd &&
    css`
      margin-left: 5px;
    `}
  ${({ isStart }) =>
    isStart &&
    css`
      margin-right: 5px;
    `}
`;
