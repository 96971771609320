import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { InlineLabel } from "shared/components/widgets";
import { Divider } from "shared/components/Divider";
import { LaborRates } from "../LaborRates";
import { UserEntityPermissions } from "../UserEntityPermissions";
import { PhoneInput } from "shared/components/PhoneInput";
import { Avatar } from "shared/components/Avatar";

export const UserDetailsEditor = ({ form, roleOptions, avatar }) => {
  const { t } = useTranslation();

  return (
    <>
      {avatar && (
        <AvatarContainer>
          <Avatar size="xxl" src={avatar} fallback="" alt="" />
        </AvatarContainer>
      )}
      <InlineLabel label={t("allUsers.form.name")} labelWidth={110} isRequired>
        <Input
          name="name"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("allUsers.form.name")}
          errorMessage={form.touched.name && form.errors.name}
          height={40}
          fontSize={14}
          required
        />
      </InlineLabel>
      <InlineLabel label={t("allUsers.form.surname")} labelWidth={110}>
        <Input
          name="surname"
          value={form.values.surname}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("allUsers.form.surname")}
          errorMessage={form.touched.surname && form.errors.surname}
          height={40}
          fontSize={14}
        />
      </InlineLabel>
      <InlineLabel label={t("allUsers.form.job_title")} labelWidth={110}>
        <Input
          name="job_title"
          value={form.values.job_title}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("allUsers.form.job_title")}
          errorMessage={form.touched.job_title && form.errors.job_title}
          height={40}
          fontSize={14}
        />
      </InlineLabel>
      <InlineLabel label={t("allUsers.form.phone_number")} labelWidth={110}>
        <PhoneInput
          value={form.values.phone_number}
          setValue={(value) => form.setFieldValue("phone_number", value)}
          errorMessage={form.touched.phone_number && form.errors.phone_number}
          touched={(value) => form.setTouched({ phone_number: true })}
        />
      </InlineLabel>
      <InlineLabel label={t("allUsers.form.email")} labelWidth={110} isRequired>
        <Input
          name="email"
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("allUsers.form.email")}
          errorMessage={form.touched.email && form.errors.email}
          height={40}
          fontSize={14}
        />
      </InlineLabel>
      <DividerStyled />

      <UserEntityPermissions form={form} roleOptions={roleOptions} />
      <LaborRates form={form} />
    </>
  );
};

const AvatarContainer = styled.div`
  margin-left: 110px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 52px;
  margin-bottom: 32px;
`;
