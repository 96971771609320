import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import CheckIcon from "shared/assets/Check.svg?react";
import { useDebounce } from "shared/lib/hooks";

import { MenuItem } from "shared/components/MenuItem";
import { MenuList } from "shared/components/MenuList";

import {
  Container,
  DropDownWrapper,
  SearchIconStyled,
  CleareIconStyled,
  HeaderMenu,
  ErrorMessage,
  Description,
} from "./Dropdowns.styled";
import {
  useFloatingDropdown,
  useMergeRefs,
} from "shared/lib/hooks/useFloatingDropdown";

export const Dropdown = React.memo(
  ({
    name = null,
    value,
    setValue = () => null,
    options = [],
    onClick = () => null,
    onSearch = () => null,
    minSearchChar = 3,
    placeholder = "",
    description = "",
    errorMessage = "",
    width = null,
    withSearch = true,
    withClear = true,
    disabled = false,
  }) => {
    const ref = useRef();
    const closeRef = useRef(null);
    const currentOptionRef = useRef({ key: "", value: "" });

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState({
      key: "",
      value: "",
    });

    const { refs, floatingStyles } = useFloatingDropdown({
      isOpen,
      setIsOpen,
      itemCount: options.length,
    });

    useEffect(() => {
      if (currentOptionRef.current?.key !== value && options.length > 0) {
        const selectedOptions = options.find((opt) => opt.key === value);
        currentOptionRef.current.key = value;
        setSelectedOption({ key: value, value: selectedOptions?.value ?? "" });
      }
    }, [value, options]);

    const [searchText, setSearchText] = useState("");
    const debounceSearch = useDebounce(searchText);

    useEffect(() => {
      const isValid = !debounceSearch || debounceSearch.length >= minSearchChar;
      if (isOpen && isValid) {
        onSearch(debounceSearch);
      }
    }, [debounceSearch, minSearchChar, isOpen]);

    useEffect(() => {
      // Reset the search text when the dropdown is unmounted
      return () => onSearch();
    }, [onSearch]);

    const onSetValue = (val) => (name ? setValue(name, val) : setValue(val));

    const onClickHandler = (e) => {
      onClick();
      if (!(closeRef.current && closeRef.current.contains(e.target))) {
        setIsOpen(!isOpen);
      }
    };

    const onSelectHandler = (optKey, optValue) => {
      if (selectedOption.key !== optKey) {
        setSelectedOption({ key: optKey, value: optValue });
        currentOptionRef.current = { key: optKey, value: optValue };
        onSetValue(optKey);
      }
      setIsOpen(false);
      setSearchText("");
    };

    const onClearHandler = (e) => {
      currentOptionRef.current = { key: "", value: "" };
      setSelectedOption({ key: "", value: "" });
      onSetValue("");
    };

    const onBlurHandler = (e) => {
      if (!ref.current.contains(e.relatedTarget)) {
        setIsOpen(false);
        setSearchText("");
        onSearch();
      }
    };

    const searchPlaceholder = !isOpen
      ? selectedOption.value || placeholder
      : searchText || selectedOption.value || placeholder;

    return (
      <Container
        ref={useMergeRefs([ref, refs.setReference])}
        onBlur={onBlurHandler}
        width={width}
        tabIndex={0}
      >
        <DropDownWrapper
          isOpen={isOpen}
          onClick={onClickHandler}
          disabled={disabled}
        >
          {withSearch && <SearchIconStyled />}
          <HeaderMenu
            value={isOpen ? searchText : selectedOption.value}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={searchPlaceholder}
            readOnly={!isOpen}
            withSearch={withSearch}
            withClear={withClear}
          />
          {selectedOption.key && withClear && (
            <CleareIconStyled ref={closeRef} onClick={onClearHandler} />
          )}
        </DropDownWrapper>
        <MenuList
          isOpen={isOpen}
          category={["Selected"]}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          {selectedOption.key &&
            !options.find((opt) => opt.key === selectedOption.key) && (
              <MenuItem
                category="Selected"
                key={selectedOption.key}
                value={selectedOption.key}
                label={selectedOption.value}
                selected={true}
                onClick={onSelectHandler}
                startIcon={<CheckIcon />}
              />
            )}
          {options.map((opt) => (
            <MenuItem
              key={opt.key}
              value={opt.key}
              label={opt.value}
              selected={selectedOption.key === opt.key}
              onClick={onSelectHandler}
              startIcon={selectedOption.key === opt.key && <CheckIcon />}
            />
          ))}
        </MenuList>
        {description && <Description>{description}</Description>}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Container>
    );
  }
);

Dropdown.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  onSeacrhOptions: PropTypes.func,
  onClick: PropTypes.func,
  onSearch: PropTypes.func,
  minSearchChar: PropTypes.number,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};
