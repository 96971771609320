import { ReactNode } from "react";
import styled, { css } from "styled-components";

interface BadgeProps {
  className?: string;
  size?: "small" | "medium" | "large";
  children: ReactNode;
  iconNode: ReactNode;
}

export const Badge = ({
  className,
  size = "medium",
  children,
  iconNode,
}: BadgeProps) => {
  return (
    <Box className={className} size={size}>
      {iconNode}
      <span>{children}</span>
    </Box>
  );
};

const Box = styled.div<Pick<BadgeProps, "size" | "className">>`
  border-radius: 16px;
  background-color: var(--color_grey100);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding-left: 8px;
  padding-right: 8px;

  span {
    color: var(--color_dark_blue);
    font-weight: 400;
    line-height: 16px;
  }

  ${({ size }) =>
    size === "small" &&
    css`
      height: 16px;

      span {
        font-size: 12px;
      }
    `}

  ${({ size }) =>
    size === "medium" &&
    css`
      height: 24px;
      font-size: 12px;
    `}

  ${({ size }) =>
    size === "large" &&
    css`
      height: 32px;
      font-size: 14px;
    `}
`;
