import OpenIcon from "../assets/icons/open.svg?react";
import HoldIcon from "../assets/icons/hold.svg?react";
import CompletedIcon from "../assets/icons/completed.svg?react";
import ProgressIcon from "../assets/icons/progress.svg?react";
import ReviewIcon from "../assets/icons/review.svg?react";
import WaitingCostIcon from "../assets/icons/waitingCost.svg?react";
import WaitingInvoiceIcon from "../assets/icons/waitingInvoice.svg?react";
import WaitingPartsIcon from "../assets/icons/waitingParts.svg?react";
import PMIcon from "../assets/icons/pm.svg?react";
import CompletedCalendarIcon from "../assets/icons/completedCalendar.svg?react";

import OpenIconUrl from "../assets/icons/open.svg?url";
import HoldIconUrl from "../assets/icons/hold.svg?url";
import CompletedIconUrl from "../assets/icons/completed.svg?url";
import ProgressIconUrl from "../assets/icons/progress.svg?url";
import ReviewIconUrl from "../assets/icons/review.svg?url";
import WaitingCostIconUrl from "../assets/icons/waitingCost.svg?url";
import WaitingInvoiceIconUrl from "../assets/icons/waitingInvoice.svg?url";
import WaitingPartsIconUrl from "../assets/icons/waitingParts.svg?url";
import PMIconUrl from "../assets/icons/pm.svg?url";

export const StatusMap = {
  open: {
    Icon: OpenIcon,
    color: "status_blue",
    iconUrl: OpenIconUrl,
  },
  on_hold: {
    Icon: HoldIcon,
    color: "status_grey",
    iconUrl: HoldIconUrl,
  },
  completed: {
    Icon: CompletedIcon,
    color: "status_green",
    iconUrl: CompletedIconUrl,
  },
  in_review: {
    Icon: ReviewIcon,
    color: "status_violet",
    iconUrl: ReviewIconUrl,
  },
  in_progress: {
    Icon: ProgressIcon,
    color: "status_lime",
    iconUrl: ProgressIconUrl,
  },
  waiting_for_cost: {
    Icon: WaitingCostIcon,
    color: "status_orange",
    iconUrl: WaitingCostIconUrl,
  },
  waiting_for_invoice: {
    Icon: WaitingInvoiceIcon,
    color: "status_pink",
    iconUrl: WaitingInvoiceIconUrl,
  },
  waiting_for_parts: {
    Icon: WaitingPartsIcon,
    color: "status_grey",
    iconUrl: WaitingPartsIconUrl,
  },
  planned: {
    Icon: PMIcon,
    color: "status_grey",
    iconUrl: PMIconUrl,
  },
  paused: {
    Icon: PMIcon,
    color: "status_grey",
    iconUrl: PMIconUrl,
  },
  completedCalendar: {
    Icon: CompletedCalendarIcon,
    color: "status_grey",
    iconUrl: CompletedIconUrl,
  },
};

export const CalendarHeaderToolbar = {
  start: "prev,next",
  center: "title",
  end: "dayGridWeek,dayGridMonth",
};

export const CalendarDayHeaderFormat = {
  weekday: "short",
};

export const PrioritySortingMap = {
  none: 0,
  none_pm: 1,
  low: 2,
  low_pm: 3,
  medium: 4,
  medium_pm: 5,
  high: 6,
  high_pm: 7,
  critical: 8,
  critical_pm: 9,
};

// Determines which fields are displayed in the history view.
// If it's not in this list, it doesn't get displayed.
export const historyFields = [
  "title",
  "description",
  "priority",
  "category",
  "user",
  "due_date",
  "location",
  "asset",
  "vendor",
  "checklist",
  "work_order_status",
  "procedure_template_assignments",
  "procedure_completion",
  "procedure_item",
];
