import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checklist } from "./Checklist";
import { Button } from "../Button";
import { Container, Box } from "./ChecklistManager.styled";

const DEFAULT_VALUE = { text: "", checked: false };

export const ChecklistManager = ({
  value = [],
  setValue,
  name,
  errorMessage = [],
}) => {
  const { t } = useTranslation();
  const [checklistData, setChecklistData] = useState([]);

  useEffect(() => {
    if (value.length !== checklistData.length) {
      const sortedData = [...value].sort((a, b) => a.order - b.order);
      setChecklistData(sortedData);
    }
  }, [value, checklistData]);

  const updateValue = (data) => {
    setChecklistData(data);
    const newDate = data.map((item, index) => {
      return { ...item, order: index + 1 };
    });
    setValue(name, newDate);
  };

  const moveItem = (field, indexToSet) => {
    const index = checklistData.findIndex((setting) => setting.order === field);
    const filteredCurrentSettings = checklistData.filter(
      (setting) => setting.order !== field
    );

    if (index !== -1) {
      const newValue1 = [
        ...filteredCurrentSettings.slice(0, indexToSet),
        checklistData[index],
        ...filteredCurrentSettings.slice(
          indexToSet,
          filteredCurrentSettings.length
        ),
      ];
      updateValue(newValue1);
    }

    return checklistData;
  };

  const addClickHandler = () => {
    const maxOrder = checklistData.reduce((max, item) => {
      return item.order > max ? item.order : max;
    }, 0);
    updateValue([...checklistData, { ...DEFAULT_VALUE, order: maxOrder + 1 }]);
  };

  const onChangeHandle = (index, val) => {
    const newValue1 = checklistData.map((v, i) =>
      i === index ? { ...v, text: val } : v
    );
    updateValue(newValue1);
  };

  const onDeleteHandle = (index) => {
    const newValue1 = checklistData.filter((v, i) => i !== index);
    updateValue(newValue1);
  };

  const columnsNode = checklistData?.map(({ text, order }, index) => (
    <Checklist
      key={order}
      text={text}
      index={index}
      moveItem={moveItem}
      onChange={onChangeHandle}
      onDelete={onDeleteHandle}
      order={order}
    />
  ));

  return (
    <Container>
      {columnsNode}
      <Box value={checklistData}>
        <Button
          onClick={addClickHandler}
          variant="secondary"
          disabled={errorMessage?.length > 0}
        >
          {t("work_orders.addChecklist")}
        </Button>
      </Box>
    </Container>
  );
};
