import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";
import { addContactValidationSchema } from "../../configs/validationSchema";
import { ContactEditor } from "../ContactEditor";
import { usePermission } from "app/providers/PermissionsProvider";

export const ContactUpdate = ({
  onClose,
  onConfirm,
  existingContacts,
  index,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const { vendorDeleteContactPermit } = usePermission();

  const INITIAL_VALUES = existingContacts[index];

  const editContactForm = useForm({
    initialValues: INITIAL_VALUES,
    validationSchema: addContactValidationSchema(t, existingContacts, index),
    enableReinitialize: true,
    onSubmit: async (body) => onConfirm(index, body),
  });

  const onCloseModal = () => {
    editContactForm.resetForm();
    onClose();
  };

  const onOpenDeleteModal = () => {
    setIsShowDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
  };

  const onDeleteHandle = () => {
    onDelete(index);
    onCloseDeleteModal();
  };

  return (
    <>
      <ConfirmationDialog
        title={t("contacts.edit")}
        confirmButtonLabel={t("submit")}
        cancelButtonLabel={t("cancel")}
        form={editContactForm}
        disabledSubmit={
          !editContactForm.dirty ||
          !!Object.values(editContactForm.errors).length
        }
        description={
          <ContactEditor
            form={editContactForm}
            existingContacts={existingContacts}
            index={index}
          />
        }
        onProceed={editContactForm.handleSubmit}
        onCancel={onCloseModal}
        onDelete={vendorDeleteContactPermit ? onOpenDeleteModal : null}
      />
      {isShowDeleteModal && vendorDeleteContactPermit && (
        <ConfirmationDialog
          title={t("contacts.delete.confirmation.title")}
          description={t("contacts.delete.confirmation.description")}
          confirmButtonLabel={t("contacts.delete.confirmation.confirm")}
          onProceed={onDeleteHandle}
          onCancel={onCloseDeleteModal}
        />
      )}
    </>
  );
};

ContactUpdate.propTypes = {
  existingContacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      is_primary: PropTypes.bool,
    })
  ),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  index: PropTypes.number,
};
