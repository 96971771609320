import styled from "styled-components";

/**
 * Layout styling for pages that display a lot of data
 */
export const TablePageLayout = styled.main`
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;
