import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDisplayDate } from "shared/lib/hooks";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { Urls, Name, RowButton } from "shared/components/Table";
import { ParentIcon } from "modules/filters";
import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLink } from "shared/components/ModalLink";
import { ModalLinksList } from "shared/components/ModalLinksList";
import { AssetStatusBadge } from "modules/assets/components/AssetStatusBadge";

export const useAssetsTableTools = ({ onOpenAsset, onFilterByParentAsset }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("asset");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    // TODO:
    // remove "filter" & "type" properties after adding logic for all fields
    return [
      {
        field: "name",
        name: t("assets.columns.name"),
        width: 380,
        sortable: true,
        renderCell: (row, formattedValue) => (
          <Name onClick={() => onOpenAsset(row.id)} value={formattedValue} />
        ),
        order: 0,
        filter: true,
        type: "string",
        primary: true,
      },
      {
        field: "id",
        name: t("assets.columns.id"),
        width: 120,
        sortable: true,
        formatValue: (raw) => `#${raw}`,
        hidden: !settings?.["id"]?.visible,
        order: settings?.["id"]?.order,
        filter: true,
        type: "integer",
      },
      {
        field: "status",
        name: t("assets.columns.status"),
        width: 150,
        sortable: true,
        order: settings?.["status"]?.order,
        renderCell: (row) => <AssetStatusBadge status={row.status} />,
        hidden: !settings?.["status"]?.visible,
        filter: true,
        type: "enum",
      },
      {
        field: "parent",
        sortQueryParam: "parent_name",
        name: t("assets.columns.parent"),
        width: 320,
        sortable: true,
        formatValue: (raw) => raw?.name ?? null,
        renderCell: (row, value) => (
          <RowButton
            value={
              <ModalLink modalParams={{ type: "asset", id: row?.parent?.id }}>
                {value}
              </ModalLink>
            }
            visible={row?.parent?.id}
            onClick={() =>
              onFilterByParentAsset({
                id: row?.parent?.id,
                name: row?.parent?.name,
              })
            }
            icon={<ParentIcon />}
          />
        ),
        filter: true,
        hidden: !settings?.["parent"]?.visible,
        order: settings?.["parent"]?.order,
      },
      {
        field: "parts",
        name: t("assets.columns.parts"),
        width: 200,
        sortable: true,
        hidden: !settings?.["parts"]?.visible,
        order: settings?.["parts"]?.order,
        filter: true,
        formatValue: (parts) => (
          <ModalLinksList
            type="part"
            items={parts}
            layout="inline"
            separator=", "
          />
        ),
      },
      {
        field: "description",
        name: t("assets.columns.description"),
        width: 320,
        sortable: true,
        hidden: !settings?.["description"]?.visible,
        order: settings?.["description"]?.order,
        filter: true,
      },
      {
        field: "qr_code",
        name: t("assets.columns.code"),
        width: 200,
        sortable: true,
        hidden: !settings?.["qr_code"]?.visible,
        order: settings?.["qr_code"]?.order,
      },
      {
        field: "asset_types",
        sortQueryParam: "asset_types_name",
        name: t("assets.columns.assetTypes"),
        width: 200,
        sortable: true,
        formatValue: (raw) => raw.map(({ name }) => name).join(", "),
        hidden: !settings?.["asset_types"]?.visible,
        order: settings?.["asset_types"]?.order,
      },
      {
        field: "assign_to",
        sortQueryParam: "assign_to_name",
        name: t("assets.columns.assignedTo"),
        width: 200,
        sortable: true,
        renderCell: (row) => (
          <UserAvatarGroup users={row.technicians} max={4} compact />
        ),
        hidden: !settings?.["assign_to"]?.visible,
        order: settings?.["assign_to"]?.order,
      },
      {
        field: "manufacturer",
        name: t("assets.columns.manufacturer"),
        width: 200,
        sortable: true,
        hidden: !settings?.["manufacturer"]?.visible,
        order: settings?.["manufacturer"]?.order,
        filter: true,
        type: "string",
      },
      {
        field: "model",
        name: t("assets.columns.model"),
        width: 200,
        sortable: true,
        hidden: !settings?.["model"]?.visible,
        order: settings?.["model"]?.order,
        filter: true,
        type: "string",
      },
      {
        field: "serial_number",
        name: t("assets.columns.serialNumber"),
        width: 200,
        sortable: true,
        hidden: !settings?.["serial_number"]?.visible,
        order: settings?.["serial_number"]?.order,
        filter: true,
        type: "string",
      },
      {
        field: "location",
        sortQueryParam: "location_name",
        name: t("assets.columns.location"),
        width: 340,
        sortable: true,
        renderCell: (row) => <LocationPath location={row.location} />,
        hidden: !settings?.["location"]?.visible,
        order: settings?.["location"]?.order,
      },
      {
        field: "purchase_cost",
        name: t("assets.columns.purchaseCost"),
        width: 200,
        sortable: true,
        hidden: !settings?.["purchase_cost"]?.visible,
        order: settings?.["purchase_cost"]?.order,
        filter: true,
        type: "integer",
      },
      {
        field: "purchase_date",
        name: t("assets.columns.purchaseDate"),
        width: 200,
        sortable: true,
        formatValue: (raw) => displayDate(raw),
        hidden: !settings?.["purchase_date"]?.visible,
        order: settings?.["purchase_date"]?.order,
        filter: true,
        type: "date",
      },
      {
        field: "warranty_exp_date",
        name: t("assets.columns.warrantyExpDate"),
        width: 300,
        sortable: true,
        formatValue: (raw) => displayDate(raw),
        hidden: !settings?.["warranty_exp_date"]?.visible,
        order: settings?.["warranty_exp_date"]?.order,
        filter: true,
        type: "date",
      },
      {
        field: "warranty_expiration_notification",
        name: t("assets.columns.warrantyExpirationNotification"),
        width: 150,
        sortable: true,
        hidden: !settings?.["warranty_expiration_notification"]?.visible,
        order: settings?.["warranty_expiration_notification"]?.order,
        filter: true,
        type: "integer",
      },
      {
        field: "life_expectancy",
        name: t("assets.columns.lifeExpectancy"),
        width: 150,
        sortable: true,
        hidden: !settings?.["life_expectancy"]?.visible,
        order: settings?.["life_expectancy"]?.order,
        filter: true,
        type: "integer",
      },
      {
        field: "replacement_cost",
        name: t("assets.columns.replacementCost"),
        width: 150,
        sortable: true,
        hidden: !settings?.["replacement_cost"]?.visible,
        order: settings?.["replacement_cost"]?.order,
        filter: true,
        type: "integer",
      },
      {
        field: "salvage_value",
        name: t("assets.columns.salvageValue"),
        width: 150,
        sortable: true,
        hidden: !settings?.["salvage_value"]?.visible,
        order: settings?.["salvage_value"]?.order,
        filter: true,
        type: "integer",
      },
      {
        field: "vendors",
        name: t("assets.columns.vendors"),
        width: 200,
        sortable: true,
        sortQueryParam: "vendors_name",
        formatValue: (vendors) => (
          <ModalLinksList
            type="vendor"
            items={vendors}
            layout="inline"
            separator=", "
          />
        ),
        order: settings?.vendors.order,
        hidden: !settings?.vendors.visible,
      },
      {
        field: "created_by_name",
        name: t("assets.columns.createdBy"),
        width: 200,
        sortable: true,
        hidden: !settings?.["created_by_name"]?.visible,
        order: settings?.["created_by_name"]?.order,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
      },
      {
        field: "created_at",
        name: t("assets.columns.createdWhen"),
        width: 200,
        sortable: true,
        formatValue: (raw) => displayDate(raw, true),
        hidden: !settings?.["created_at"]?.visible,
        order: settings?.["created_at"]?.order,
        filter: true,
        type: "date",
      },
      {
        field: "updated_by_name",
        name: t("assets.columns.updatedBy"),
        width: 150,
        sortable: true,
        hidden: !settings?.["updated_by_name"]?.visible,
        order: settings?.["updated_by_name"]?.order,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
      },
      {
        field: "updated_at",
        name: t("assets.columns.updatedWhen"),
        width: 200,
        sortable: true,
        formatValue: (raw) => displayDate(raw, true),
        hidden: !settings?.["updated_at"]?.visible,
        order: settings?.["updated_at"]?.order,
        filter: true,
        type: "date",
      },
      {
        field: "urls",
        sortQueryParam: "urls_label",
        name: t("assets.columns.urls"),
        width: 360,
        sortable: true,
        order: settings?.["urls"]?.order,
        renderCell: ({ urls }) => <Urls values={urls} />,
        hidden: !settings?.["urls"]?.visible,
        filter: false,
      },
    ]
      .filter(({ field }) => settings?.[field]?.visible_global !== false)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [
    tableSettings?.data.payload,
    onOpenAsset,
    t,
    displayDate,
    onFilterByParentAsset,
  ]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};
