import { useTranslation } from "react-i18next";

import { Dropdown } from "shared/components/Dropdowns";

import {
  frequencyOptions,
  INITIAL_SCHEDULE_VALUES,
} from "../../configs/constants";
import { getOccurrenceOptions } from "../../lib/helpers/form";

import {
  InlineLabel,
  RepeatsContainer,
  DropdownsBox,
} from "./ScheduleForm.styled";

export const Repeats = ({
  name,
  setValue,
  disabled = false,
  occurrence,
  frequency,
  scheduleType,
}) => {
  const { t } = useTranslation();

  const occurrenceOptions = getOccurrenceOptions(frequency, scheduleType);
  const handleFrequencyChange = (val) => {
    setValue(name, {
      ...INITIAL_SCHEDULE_VALUES,
      repeat_frequency: val,
      type: scheduleType,
    });
    setValue(`${name}.time_to_complete`, 1);
  };

  const handleOccurrenceChange = (val) => {
    setValue(`${name}.repeat_occurrence`, val);
    setValue(`${name}.time_to_complete`, 1);
  };

  return (
    <RepeatsContainer>
      <InlineLabel>
        {t("work_orders.form.pm_schedule_attributes.repeats_every")}
      </InlineLabel>
      <DropdownsBox>
        <Dropdown
          name="repeat_occurrence"
          value={occurrence}
          options={occurrenceOptions}
          setValue={(n, val) => handleOccurrenceChange(val)}
          width="109px"
          withSearch={false}
          withClear={false}
          disabled={disabled}
        />
        <Dropdown
          name="repeat_frequency"
          value={frequency}
          options={frequencyOptions}
          setValue={(n, val) => handleFrequencyChange(val)}
          width="175px"
          withSearch={false}
          withClear={false}
          disabled={disabled}
        />
      </DropdownsBox>
    </RepeatsContainer>
  );
};
