import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const ActionList = ({ className, actions }) => {
  return (
    <Container className={className}>
      {actions.map((contentBlock, blockIndex) => (
        <ContentBlock key={blockIndex}>
          {contentBlock
            .filter((item) => item)
            .map((content, contentIndex) => (
              <React.Fragment key={contentIndex}>{content}</React.Fragment>
            ))}
        </ContentBlock>
      ))}
    </Container>
  );
};

ActionList.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
};

ActionList.defaultProps = {
  className: "",
};

const Container = styled.ul`
  width: 100%;
`;
const ContentBlock = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;
