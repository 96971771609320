import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Input } from "shared/components/Input";

export const CloneNumber = ({ count, setCount, entity }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (
      (e.target.value <= 99 && /^[1-9]\d*$/.test(e.target.value)) ||
      !e.target.value
    ) {
      setCount(e.target.value);
    }
  };

  const handleBlur = () => !count && setCount(1);

  return (
    <ItemNumbers>
      <ItemNumberTitle>{t(`${entity}.clone.itemNumberTitle`)}</ItemNumberTitle>
      <Input
        type="number"
        name="name"
        width="60"
        value={count}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </ItemNumbers>
  );
};

CloneNumber.propTypes = {
  count: PropTypes.number,
  setCount: PropTypes.func,
};

const ItemNumbers = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

const ItemNumberTitle = styled.div`
  white-space: nowrap;
  color: var(--color_text_color);
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`;
