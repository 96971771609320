import React from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import {
  PortalView,
  PortalCreate,
  Logo,
  useGetRequestPortalQuery,
} from "modules/portals";

export const PortalPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { currentData: data, error } = useGetRequestPortalQuery(
    id ?? skipToken
  );

  if (!id || !data) {
    return null;
  }

  if (error) {
    return <div>{t("portal.detailsError")}</div>;
  }

  return (
    <PageLayout>
      <ContentWrapper>
        <Container>
          <PortalView data={data} />
          <PortalCreate
            requestId={data.id}
            entity={data.entity}
            allow_anonymous={data.allow_anonymous}
          />
        </Container>
        <Footer>
          {t("portal.poweredBy")} <Logo />
        </Footer>
      </ContentWrapper>
    </PageLayout>
  );
};

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 76px 100px;

  @media (max-width: 750px) {
    padding: 20px 10px;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: end;
  gap: 10px;
  font-size: 14px;
  margin: 60px 10% 0;
  line-height: 20px;
  color: var(--color_dark_blue);
`;

const PageLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
