import { CMMSAPI } from "app/services";

export const internalCollectionsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCurrency: builder.query({
      query: () => ({
        url: "/internal/collections/currencies",
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      providesTags: ["Currency"],
    }),

    getDateFormat: builder.query({
      query: () => ({
        url: "/internal/collections/datetime_formats",
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      providesTags: ["DateFormat"],
    }),

    getTimeZones: builder.query({
      query: (params) => ({
        url: "/internal/collections/timezones",
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      providesTags: ["TimeZones"],
    }),
  }),
});

export const {
  useLazyGetCurrencyQuery,
  useGetCurrencyQuery,
  useLazyGetTimeZonesQuery,
  useGetTimeZonesQuery,
  useGetDateFormatQuery,
  useLazyGetDateFormatQuery,
} = internalCollectionsApi;
