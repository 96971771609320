import React from "react";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

import {
  SelectStyles,
  ThemeStyles,
  ErrorMessage,
} from "./SelectDropdownStyled";

import { Control } from "./Control";
import { ClearIndicator } from "./ClearIndicator";
import { Option, NoOptionsMessage } from "./Option";

export const SelectInnerSearch = ({
  options,
  setValue,
  value,
  isMulti,
  name,
  errorMessage,
  menuPlacement = "auto",
  noOptionsMessage,
  isSearch = true,
  customStyles,
  onBlur = () => {},
  onTouch = () => {},
}) => {
  const { t } = useTranslation();

  const onSelectBlur = (value) => {
    onBlur(value);
    onTouch(name);
  };

  return (
    <>
      <ReactSelect
        options={options}
        value={value}
        isClearable={false}
        isSearchable={isSearch}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        onBlur={onSelectBlur}
        placeholder={t("startTyping")}
        components={{
          IndicatorSeparator: null,
          ...(isSearch && { Control }),
          ClearIndicator,
          Option,
          NoOptionsMessage,
        }}
        styles={SelectStyles}
        onChange={(value) => setValue(name, value)}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => noOptionsMessage}
        theme={ThemeStyles}
        {...customStyles}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};
