import * as Sentry from "@sentry/react";
import { useSearchParams } from "react-router-dom";
import { SearchParamUpdateQueue } from "./SearchParamUpdateQueue";

type SetSearchParam<T> = (newVal: T | null) => void;
interface UseSearchParamStateOptions<T> {
  /**
   * A custom function to convert the state value into a string to be stored in URLSearchParameters
   * @default `JSON.stringify`
   */
  serialize?: (value: T) => string;
  /**
   * A custom function to convert from the stored string back into the original type
   * @default `JSON.parse`
   */
  deserialize?: (value: string | null) => T | null;
}

const queue = new SearchParamUpdateQueue();
const defaultOptions = {
  serialize: (val: unknown) => JSON.stringify(val),
  deserialize: (val: string | null) => (val ? JSON.parse(val) : null),
};

// A list of invalid values that have already been reported to Sentry
const invalidValuesRecorded: string[] = [];

/** Synchronizes a piece of state using the URLSearchParameters, leaving other parameters in place */
function useSearchParamState<T>(
  key: string,
  /** The value to return if the key is not found in the URL */
  defaultValue: T,
  options?: UseSearchParamStateOptions<T>
): [T, SetSearchParam<T>] {
  const { serialize, deserialize } = { ...defaultOptions, ...options };
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get(key);

  // `param` is coming from the URL, so it may be missing or invalid. Wrapping
  //    `deserialize` in a try/catch means that the user doesn't have to manually handle errors.
  let value = defaultValue;
  if (param) {
    try {
      value = deserialize(param);
    } catch (e) {
      // Ignore the error and return the `defaultValue`
      // If we're in production, log the error to Sentry
      if (
        process.env.VITE_DEPLOY_ENV === "production" &&
        !invalidValuesRecorded.includes(param)
      ) {
        invalidValuesRecorded.push(param);
        Sentry.captureMessage(
          `Invalid search param value for key "${key}": ${param}`
        );
      }
    }
  }

  const update: SetSearchParam<T> = (newVal) => {
    queue.enqueue(key, newVal === null ? null : serialize(newVal));
    queueMicrotask(() => {
      if (queue.count()) {
        // `setSearchParams` pushes to the history stack, so avoid calling it if the params are
        //    the same
        const updatedParams = queue.performUpdates(searchParams);
        if (updatedParams.toString() !== searchParams.toString()) {
          setSearchParams(updatedParams);
        }
      }
    });
  };

  return [value, update];
}

export { useSearchParamState };
