import { useTranslation } from "react-i18next";
import { getUser } from "shared/components/Auth";

export const useGetLocaleSettings = () => {
  const { i18n } = useTranslation();
  const user = getUser();

  return {
    language: i18n.language,
    currency: user.currency ?? "USD",
    timezone: user.timezone,
    dateFormat: user.dateFormat,
  };
};
