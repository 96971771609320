import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetCustomerQuery } from "modules/customer/state/customerApi";
import styled, { css } from "styled-components";

export const Tenant = ({ showText }) => {
  const id = JSON.parse(localStorage.getItem("user"))?.customerId;
  const { data } = useGetCustomerQuery(id ?? skipToken);

  return <TenantText $showText={showText}>{data?.name}</TenantText>;
};

const TenantText = styled.p`
  color: white;
  margin: 0;
  font-size: 14px;
  transform-origin: top center;
  transition: all 0.25s;

  ${(p) => {
    if (p.$showText) {
      return css`
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.25s;
      `;
    } else {
      return css`
        opacity: 0;
        transform: scale(0.5);
      `;
    }
  }}
`;
