import {
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetAdminCategoriesQuery,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from "modules/categories";
import { AdministrationReference } from "shared/components/AdministrationReference";
import { usePermission } from "app/providers/PermissionsProvider";

const actions = {
  getList: useGetAdminCategoriesQuery,
  getElement: useGetCategoryQuery,
  addElement: useAddCategoryMutation,
  updateElement: useUpdateCategoryMutation,
  deleteElement: useDeleteCategoryMutation,
};

export const Categories = () => {
  const { adminSettingsCategoriesPermit } = usePermission();
  return (
    <AdministrationReference
      actions={actions}
      module="categories"
      hasPermission={adminSettingsCategoriesPermit}
    />
  );
};
