import PropTypes from "prop-types";
import { entityPropType } from "shared/lib/propTypes";
// TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
// import { pickEntriesByKey } from "shared/lib/filterObjectByKeys";
// import { teamPropType } from "../teams";

export const locationDetailsPropType = {
  ...entityPropType,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  address: PropTypes.string,
  code: PropTypes.string,
  parentId: PropTypes.number,
  entityId: PropTypes.number,
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // teams: PropTypes.arrayOf(
  //   PropTypes.shape(pickEntriesByKey(teamPropType, ["id", "name"]))
  // ),
};
