import React from "react";
import { useTranslation } from "react-i18next";

import { useDisplayDate } from "shared/lib/hooks";

import CheckedCheckbox from "../../assets/icons/checkedCheckbox.svg?react";
import EmptyCheckbox from "../../assets/icons/emptyCheckbox.svg?react";
import SystemAvatar from "../../assets/icons/systemAvatar.svg?react";
import { getHistoryContent } from "../../lib/helpers/index";
import {
  Container,
  Name,
  Content,
  Box,
  NameContainer,
  Info,
  Image,
  Created,
  ContentView,
  ItemBox,
  ChecklistContainer,
  Title,
} from "./WorkOrderHistoryList.styled";
import { UserAvatar } from "shared/components/UserAvatar";
import { ShiftBy } from "shared/components/ShiftBy";
import { Hint } from "shared/components/Typography";

const View = ({ value = [], type }) => {
  if (!Array.isArray(value)) return null;

  return value.map((val, index) => {
    if (val == null) return null;

    return (
      <React.Fragment key={index}>
        <ItemBox type={type}>{val}</ItemBox>
        {index + 1 < value.length ? "," : ""}
      </React.Fragment>
    );
  });
};

const ChecklistItemView = ({ content }) => {
  return (
    <Content>
      {content.key && <Title>{content.key}</Title>}
      <ChecklistContainer checked={content.to === "true"}>
        {content.to === "true" ? <CheckedCheckbox /> : <EmptyCheckbox />}
        <div>{content.value}</div>
      </ChecklistContainer>
      {content.description && <Hint>{content.description}</Hint>}
    </Content>
  );
};

const GenericView = ({ content }) => {
  return (
    <Content>
      <Title>{content.key}</Title>
      {content.description && <Hint>{content.description}</Hint>}
      <View value={content.from} type="from" />
      <View value={content.to} type="to" />
    </Content>
  );
};

export const WorkOrderHistoryList = ({ value = [] }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  return (
    <Container>
      {value.map((history) => {
        const { modifier, created_at, modifier_name } = history;

        const name =
          modifier_name === "System" ? "Scheduled event" : modifier?.full_name;

        const contents = getHistoryContent(history, t);

        return (
          <Box key={history.id}>
            {modifier ? (
              <ShiftBy y={4}>
                <UserAvatar user={modifier} size="md" />
              </ShiftBy>
            ) : (
              <Image>
                <SystemAvatar />
              </Image>
            )}
            <Info>
              <NameContainer>
                <Name>{name}</Name>
                <Content>
                  {contents.map((content) => {
                    return (
                      <React.Fragment key={content.key}>
                        {Object.prototype.hasOwnProperty.call(
                          content,
                          "value"
                        ) ? (
                          <ChecklistItemView content={content} />
                        ) : (
                          <GenericView content={content} />
                        )}
                      </React.Fragment>
                    );
                  })}
                </Content>
              </NameContainer>
              <Created>{displayDate(created_at, true)}</Created>
            </Info>
          </Box>
        );
      })}
    </Container>
  );
};
