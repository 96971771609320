import styled from "styled-components";

/**
 * Applies skeleton pulse animation to child SVG elements.
 * The `fill` attribute will override the loading animation, so it must be `undefined` on all children.
 */
export const ChartLoadingAnimation = styled.g`
  fill: var(--color_grey100);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    50% {
      fill: var(--color_grey50);
    }
  }
`;
