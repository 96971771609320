import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDisplayDate } from "shared/lib/hooks";
import { useGetEntitiesQuery } from "modules/entities";
import { Status } from "../components/UsersList/Status";
import { Name } from "shared/components/Table";
import { getUser } from "shared/components/Auth";
import { CellTextContainer } from "shared/components/Table/components/Table/styled";

export const useUsersTableTools = ({ onOpen, oneEntity = false }) => {
  const { t } = useTranslation();

  const displayDate = useDisplayDate();

  const { data: entities, isLoading: isEntitiesFetching } =
    useGetEntitiesQuery();

  const { entity } = getUser();

  const entitiesColumn = entities?.data.length
    ? [...entities.data?.map((entity) => entity.name)]
    : [];

  const columns = useMemo(() => {
    return [
      {
        field: "full_name",
        name: t("allUsers.columns.title"),
        width: 300,
        sortable: true,
        renderCell: (row, value) => {
          const invitedUser =
            row.status === "active" ||
            row.status === "inactive" ||
            row.status === "change_email_in_progress";

          const name = invitedUser ? (
            value
          ) : (
            <CellTextContainer>{row.email}</CellTextContainer>
          );

          return <Name onClick={() => onOpen(row.id)} value={name} />;
        },
        primary: true,
        order: 0,
      },
      {
        field: "status",
        name: t("allUsers.columns.status"),
        width: 200,
        renderCell: ({ status }) => <Status value={status} />,
        sortable: true,
      },
      {
        field: "email",
        name: t("allUsers.columns.email"),
        width: 300,
        formatValue: (value) => (value ? value : null),
        sortable: true,
      },
      ...(oneEntity
        ? [
            {
              field: "permissions",
              name: "Role",
              filter: false,
              width: 250,
              formatValue: (value) => {
                const role = value.find(
                  ({ entity_id }) => entity_id === entity
                );

                return role === "no_access" || role === undefined
                  ? t("allUsers.roles.no_access")
                  : t(`allUsers.roles.${role?.role}`);
              },
            },
          ]
        : entitiesColumn.map((item, index) => ({
            field: `permissions-${index}`,
            name: item,
            filter: false,
            width: 250,
            renderCell: (row) => {
              const role = row.permissions.find(
                ({ entity }) => entity === item
              )?.role;
              return role === "no_access" || role === undefined
                ? t("allUsers.roles.no_access")
                : t(`allUsers.roles.${role}`);
            },
          }))),
      {
        field: "activity",
        sortQueryParam: "activity_last_login",
        name: t("allUsers.columns.last_visit"),
        width: 200,
        formatValue: ({ last_login }) => displayDate(last_login),
        sortable: true,
      },
      // TODO: Temporary decision according to {https://clickmaint.atlassian.net/browse/CMFB-1431}
      // {
      //   field: "last_visit",
      //   name: t("allUsers.columns.logLast90Days"),
      //   width: 200,
      //   formatValue: (value) => displayDate(value),
      //   sortable: true,
      // },
    ];
  }, [displayDate, t, entities, onOpen]);

  return {
    columns,
    isEntitiesFetching,
  };
};
