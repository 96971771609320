import { useConfirmEmailQuery } from "modules/auth";
import { SignUpEmailExpired } from "modules/auth/components/AdditionalPages";
import { useEffect } from "react";
import { dropUser } from "shared/components/Auth";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useSearchParams } from "react-router-dom";

export const ConfirmEmailPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("confirmation_token");
  const { addSnackBar } = useSnackBar();

  useEffect(() => {
    dropUser();
  }, []);

  const { error } = useConfirmEmailQuery({ token });

  const isExpiredToken = error?.message?.email?.[0].includes(
    "needs to be confirmed within about"
  );

  const email = error?.message?.data?.email;

  useEffect(() => {
    if (error?.error) {
      addSnackBar({
        content: error?.error,
        type: "error",
      });
    }
    if (!error && !isExpiredToken) {
      window.location.href = "/login";
    }
  }, [error, isExpiredToken]);

  if (isExpiredToken) {
    return <SignUpEmailExpired email={email} />;
  }

  return null;
};
