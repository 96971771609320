import { createContext, useContext } from "react";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";

interface ReportPageContext {
  reportPeriod: ReportPeriod;
  setReportPeriod: (val: ReportPeriod) => void;
}

const Context = createContext<ReportPageContext | null>(null);

export const useReportPageContext = () => {
  const value = useContext(Context);
  if (!value) {
    throw new Error(
      "useReportPageContext must be used within a ReportPageProvider"
    );
  }
  return value;
};

export const ReportPageProvider = Context.Provider;
