import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { ListBox } from "shared/components/ListBox";
import { useState } from "react";
import { DatePickerBase } from "shared/components/DatePicker";
import { format, isAfter, parseISO } from "date-fns";
import { Button } from "shared/components/Button";
import * as Styled from "./ReportPeriodSelectPopoverContent.styled";
import { useTranslation } from "react-i18next";

interface ReportPeriodSelectPopoverContentProps {
  selected: ReportPeriod;
  onApply: (value: ReportPeriod) => void;
  onCancel: () => void;
  options?: ReportPeriod[];
}

export const ReportPeriodSelectPopoverContent = ({
  selected,
  onApply,
  onCancel,
  options,
}: ReportPeriodSelectPopoverContentProps) => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(selected);
  const selectedStartDate = current.startDate
    ? parseISO(current.startDate)
    : undefined;
  const selectedEndDate = current.endDate
    ? parseISO(current.endDate)
    : undefined;

  const setCustomRange = (
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    setCurrent({
      value: "custom",
      startDate,
      endDate,
    });
  };

  return (
    <Styled.Wrapper>
      <ListBox.Root
        aria-label="Report Period"
        items={options}
        selectionMode="single"
        selectedKeys={[current.value]}
        onSelectionChange={(keys) => {
          const key = (keys as Set<string>).values().next().value;
          setCurrent(options?.find((i) => i.value === key) ?? current);
        }}
      >
        {(item) => (
          <ListBox.Item id={item.value}>
            {t(`reporting.periodOptions.${item.value}`)}
          </ListBox.Item>
        )}
      </ListBox.Root>
      <Styled.CalendarWrapper>
        <Styled.CalendarTitle>Start</Styled.CalendarTitle>
        <DatePickerBase
          selected={selectedStartDate}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onChange={(date: Date | null) => {
            const startDate = date ? format(date, "yyyy-MM-dd") : undefined;
            let endDate = current.endDate;
            if (date && selectedEndDate && isAfter(date, selectedEndDate)) {
              endDate = startDate;
            }
            setCustomRange(startDate, endDate);
          }}
          inline
        />
        <Styled.CalendarTitle>End</Styled.CalendarTitle>
        <DatePickerBase
          selected={selectedEndDate}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          minDate={selectedStartDate}
          onChange={(date: Date | null) => {
            setCustomRange(
              current.startDate,
              date ? format(date, "yyyy-MM-dd") : undefined
            );
          }}
          inline
        />
      </Styled.CalendarWrapper>
      <Styled.ButtonsWrapper>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => onApply(current)}
          disabled={!current.startDate || !current.endDate}
        >
          Apply
        </Button>
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
};
