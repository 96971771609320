import i18n from "i18next";

export const notificationOptions = [
  { value: "0", label: i18n.t("assets.notificationOptions.never") },
  {
    value: 1,
    label: i18n.t("assets.notificationOptions.day"),
  },
  {
    value: 7,
    label: i18n.t("assets.notificationOptions.week"),
  },
  {
    value: 30,
    label: i18n.t("assets.notificationOptions.month"),
  },
  {
    value: 90,
    label: i18n.t("assets.notificationOptions.threeMonth"),
  },
];

export const statusOptions = [
  { value: "online", label: i18n.t("assets.statuses.online") },
  { value: "offline", label: i18n.t("assets.statuses.offline") },
  { value: "not_tracking", label: i18n.t("assets.statuses.not_tracking") },
];

export const initialValues = {
  name: "",
  description: "",
  uploads_attributes: [],
  location: "",
  asset_types: [],
  model: "",
  manufacturer: "",
  serial_number: "",
  parent: "",
  vendors: [],
  parts: [],
  purchase_date: "",
  warranty_expiration_notification: { value: "0", label: "Never" },
  life_expectancy: "",
  warranty_exp_date: "",
  purchase_cost: "",
  replacement_cost: "",
  salvage_value: "",
  urls_attributes: [],
  assign_to: [],
};

export const AssetFormOptionalFields = [
  "location_id",
  "asset_types_ids",
  "model",
  "manufacturer",
  "serial_number",
  "purchase_date",
  "qr_code_attributes",
  "parent_id",
  "assign_to",
  "urls_attributes",
  "vendors_ids",
  "parts_ids",
  "warranty_exp_date",
  "life_expectancy",
  "purchase_cost",
  "replacement_cost",
  "salvage_value",
];
