import styled from "styled-components";
import { t } from "i18next";
import ReportingIcon from "modules/reporting/assets/reporting-icon.svg?react";

/** "Not Enough Data" message for ReportListPage widgets */
export const NoData = () => {
  return (
    <Wrapper>
      <ReportingIcon />
      <span>{t("reporting.noData")}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;

  color: var(--color_grey500);
  font-size: 12px;
  line-height: 2;
`;
