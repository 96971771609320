interface ListItem {
  id: string | number;
}

/**
 * Gets a list of specific tags for a query from an array
 *
 * https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#specific-tag
 */
export function getTagList(type: string, list: ListItem[] | null | undefined) {
  if (!Array.isArray(list)) return [];
  return list?.map((item) => ({
    type,
    id: item.id,
  }));
}
