interface BarConfig {
  order: number;
  fill: string;
  label: string;
}

/**
 * Transforms data for use by a categorical bar chart:
 * - Adds `order`, `fill`, and `label` to each item from `bars` object
 * - Removes items without a valid config
 * - Sorts items according to `order`
 *
 * @param barConfigs - Object defining how each category should be represented on the chart
 * @param data - Chart data
 */
export function transformCategoricalBarChartData<
  T extends { key: string; value: number }
>(barConfigs: Record<string, BarConfig>, data: T[]) {
  return data
    .map((i) =>
      barConfigs[i.key] ? { ...barConfigs[i.key], value: i.value } : undefined
    )
    .filter((i) => i)
    .sort((a, b) => (a && b ? a.order - b.order : 0));
}
