import { Button } from "shared/components/Button";
import styled from "styled-components";

export const CustomerContainer = styled.div`
  display: flex;
  padding: 32px 0;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--color_grey100);
`;

export const Logo = styled.div`
  width: 100px;
  height: 100px;
`;

export const SubContainer = styled.div`
  padding-top: 7px;
  margin-left: 16px;
`;

export const EditButton = styled(Button)`
  margin-top: 16px;
  padding: 0;
`;

export const Box = styled.div`
  width: 450px;
  padding: 0 16px;
`;
