import styled from "styled-components";
import { Divider } from "shared/components/Divider";
import { Switch } from "shared/components/Switch";

export const Wrapper = styled.div`
  padding-top: 32px;
`;

export const DescriptionBox = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: var(--color_text_color);
  margin-bottom: 30px;
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 115%;
  color: var(--color_text_color);
  margin-bottom: 8px;
`;

export const StyledSwitch = styled(Switch)`
  margin-top: 20px;
`;

export const Description = styled.div`
  margin-left: 48px;
`;

export const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
