import { CMMSAPI } from "app/services";

export const qrcodesApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    generateQRCodes: builder.mutation({
      query: ({ type, ...body }) => ({
        url: `/${type}/qr_codes`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "QRCode", id: "QRCodesList" }],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const { useGenerateQRCodesMutation } = qrcodesApi;
