import { useTranslation } from "react-i18next";
import { formatCurrency, formatNumber } from "modules/reporting/lib/formatters";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { Pagination } from "features/Pagination";
import { PartsInventoryTableData } from "./types";
import { PaginationData } from "types/Response";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";

const reportKey = ReportKey.PartsInventory;

interface PartsInventoryReportTableProps {
  data?: PartsInventoryTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const PartsInventoryReportTable = ({
  data,
  paginationData,
  isFetching,
}: PartsInventoryReportTableProps) => {
  const { t } = useTranslation();
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  return (
    <>
      <Table
        columns={columns}
        rows={data}
        getRowKey={(row) => row.assignee}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(): TableColumn<PartsInventoryTableData>[] {
  const { t, i18n } = useTranslation();
  const { currency } = useLocaleSettings();
  const language = i18n.language;
  return [
    {
      field: "part_name",
      name: t(`reporting.${reportKey}.columns.part_name`),
      sortable: true,
      minWidth: 200,
    },
    {
      field: "unit_of_measure",
      name: t(`reporting.${reportKey}.columns.unit_of_measure`),
      sortable: true,
    },
    {
      field: "part_type",
      name: t(`reporting.${reportKey}.columns.part_type`),
      sortable: true,
      minWidth: 200,
    },
    {
      field: "cost",
      name: t(`reporting.${reportKey}.columns.cost`),
      sortable: true,
      formatValue: (val) => formatCurrency(parseFloat(val), language, currency),
    },
    {
      field: "quantity",
      name: t(`reporting.${reportKey}.columns.quantity`),
      sortable: true,
      formatValue: (val) => formatNumber(parseFloat(val), language),
    },
    {
      field: "average_cost",
      name: t(`reporting.${reportKey}.columns.average_cost`),
      sortable: true,
      formatValue: (val) => formatCurrency(parseFloat(val), language, currency),
    },
    {
      field: "work_order_count",
      name: t(`reporting.${reportKey}.columns.work_order_count`),
      sortable: true,
    },
  ];
}
