import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export const Container = styled.div`
  // max height without header
  height: calc(100vh - var(--header-height));

  padding-left: 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({ horizontallySpacious }) =>
    horizontallySpacious
      ? css`
          padding-right: 40px;
        `
      : null};
`;

Container.propTypes = {
  horizontallySpacious: PropTypes.bool,
};
