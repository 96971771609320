import { useUpdateProcedureItem } from "modules/procedures/hooks/useUpdateProcedureItem";
import { ChangeEvent, useMemo, useState } from "react";
import { Label } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { VerticalItem } from "./styled";
import { ItemProps } from "./types";

interface TextInputProps extends ItemProps {
  type?: "text" | "number";
}

export const TextInput = ({
  item,
  onChange,
  type = "text",
}: TextInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | number>(
    item.value?.data as string
  );

  const parsedValue = useMemo(() => {
    return type === "number" ? Number(value) : value;
  }, [value, type]);

  useUpdateProcedureItem(item, { data: parsedValue }, value, 2500);

  let placeholder;
  if (item.item_type === "number") {
    placeholder = t("procedures.items.number.placeholder");
  } else if (item.item_type === "text") {
    placeholder = t("procedures.items.text.placeholder");
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <VerticalItem>
      <Label>{item.name}</Label>
      <Input
        value={value}
        type={type}
        isTextArea={item.item_type === "text"}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </VerticalItem>
  );
};
