import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../lib/formatters";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";

interface KPIGridProps {
  isLoading?: boolean;
  totalCosts: string;
  part_type_counts: {
    label: string;
    value: string;
  }[];
  part_counts: {
    label: string;
    value: string;
  }[];
  part_types: {
    label: string;
    value: string;
  }[];
  parts: {
    label: string;
    value: string;
  }[];
  mode: string;
}

export const PartsInventoryKPIGrid = ({
  part_counts,
  part_type_counts,
  parts,
  part_types,
  totalCosts,
  isLoading,
  mode,
}: KPIGridProps) => {
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();
  const kpi_type = mode === "parts" ? "parts" : "part_types";
  const counts = mode === "parts" ? part_counts : part_type_counts;
  const displayData = mode === "parts" ? parts : part_types;
  return (
    <>
      <KPILabel>{t(`reporting.parts_inventory.kpis.total_cost`)}</KPILabel>
      <KPIValue>
        {formatCurrency(parseFloat(totalCosts), language, currency)}
      </KPIValue>
      <DetailsBox>
        <DetailsTitle>
          {isLoading
            ? ""
            : t(`reporting.parts_inventory.kpis.${kpi_type}_usage`, {
                count: counts.length,
              })}
        </DetailsTitle>
        <Grid>
          {counts.map((i, index) => (
            <Item key={index} isLoading={isLoading} {...i} />
          ))}
        </Grid>
      </DetailsBox>
      <DetailsBox>
        <DetailsTitle>
          {isLoading
            ? ""
            : t(`reporting.parts_inventory.kpis.${kpi_type}_cost`, {
                count: displayData.length,
              })}
        </DetailsTitle>
        <Grid>
          {displayData.map((i, index) => (
            <Item key={index} isLoading={isLoading} {...i} />
          ))}
        </Grid>
      </DetailsBox>
    </>
  );
};

interface ItemProps {
  value: string;
  label: string;
  isLoading?: boolean;
}

const Item = ({ value, label, isLoading }: ItemProps) => {
  return (
    <KpiBox>
      <KPIValue>
        {isLoading ? <Skeleton height="1em" width="5ch" /> : value}
      </KPIValue>
      <KPILabel>{label}</KPILabel>
    </KpiBox>
  );
};

const KpiBox = styled.div``;
const DetailsTitle = styled.div`
  margin-top: -58px;
  background-color: white;
  width: fit-content;
  padding: 0 4px;
  margin-left: 16px;
`;

const DetailsBox = styled.div`
  margin-top: 32px;
  box-sizing: border-box;
  padding-top: 45px;
  border: 2px solid var(--color_grey50);
  border-radius: 8px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  ${DetailsBox} > & {
    margin: 16px auto;
    text-align: center;
  }
`;

const KPIValue = styled.div`
  color: var(--color_dark_blue);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  white-space: nowrap;
`;

const KPILabel = styled.div`
  color: var(--color_grey700);
  font-size: 14px;
  line-height: 1.4;
  white-space: nowrap;
`;
