import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import { statusOptions } from "modules/assets";
import {
  DowntimeType,
  updateStatusValidationSchema,
} from "modules/downtimeTracking";

import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Select } from "shared/components/Select";
import { useForm } from "shared/lib/hooks/useForm";
import { Input } from "shared/components/Input";

import {
  Container,
  SelectedLabel,
  StyledIcon,
  StyledInlineLabel,
} from "./BulkStatus.styled";

export const BulkStatus = ({ ids = [], onUpdate, count }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const onClose = () => {
    form.resetForm();
    setIsConfirmationVisible(false);
  };

  const form = useForm({
    initialValues: {
      status: "",
      downtime_type: "unplanned",
      note: "",
    },
    validationSchema: updateStatusValidationSchema({ t, bulkAction: true }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        downtime_trackings_attributes: {
          note: values.note,
          status: values.status.value,
          ...(values.status.value === "offline"
            ? { downtime_type: values.downtime_type }
            : {}),
        },
      };

      onUpdate({ ids, body })
        .unwrap()
        .then(() => {
          addSnackBar({
            content: t("assets.bulkActions.status.success", {
              count,
              newValue: t(`assets.statuses.${values.status.value}`),
            }),
            type: "success",
          });
          onClose();
        })
        .catch((error) => {
          addSnackBar({
            title: t("assets.bulkActions.status.fail"),
            type: "error",
          });
          onClose();
        });
    },
  });

  return (
    <>
      <IconButton onClick={clickHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-status" />
        <ReactTooltip
          id="icon-tooltip-status"
          place="bottom"
          content={t("assets.bulkActions.status.hint")}
          opacity="1"
        />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t("assets.bulkActions.status.confirmation.title")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          disabledSubmit={!form.dirty || !!Object.values(form.errors).length}
          description={
            <Container>
              <SelectedLabel>
                {t(
                  `assets.bulkActions.status.confirmation.selected_${
                    count > 1 ? "other" : "one"
                  }`,
                  { count }
                )}
              </SelectedLabel>
              <StyledInlineLabel
                label={t("assets.bulkActions.status.fields.status")}
                labelWidth={110}
              >
                <Select
                  options={statusOptions}
                  name="status"
                  value={form.values.status}
                  setValue={form.handleSetValue}
                  onBlur={form.handleBlur}
                  errorMessage={form.touched.status && form.errors.status}
                  onTouch={form.handleSetTouched}
                  noOptionsMessage={t("noOptions")}
                  placeholder={t(
                    "assets.bulkActions.status.placeholders.status"
                  )}
                  isSearchable={false}
                  isClearable={false}
                />
              </StyledInlineLabel>
              {form.values.status?.value === "offline" && (
                <StyledInlineLabel
                  label={t("assets.bulkActions.status.fields.type")}
                  labelWidth={110}
                >
                  <DowntimeType form={form} name="downtime_type" />
                </StyledInlineLabel>
              )}
              <StyledInlineLabel
                label={t("assets.bulkActions.status.fields.note")}
                labelWidth={110}
              >
                <Input
                  name="note"
                  value={form.values.note}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  placeholder={t("assets.bulkActions.status.placeholders.note")}
                  errorMessage={form.touched.note && form.errors.note}
                  isTextArea
                />
              </StyledInlineLabel>
            </Container>
          }
          onProceed={form.handleSubmit}
          onCancel={onClose}
        />
      )}
    </>
  );
};

BulkStatus.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onUpdate: PropTypes.func.isRequired,
  count: PropTypes.number,
};
