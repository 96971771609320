import { PartTransactionsList } from "./List";
import { DividerStyled } from "./styled";
import { ViewPartTransaction } from "./ViewPartTransaction";
import { useState } from "react";

export const PartTransactions = ({ partId }) => {
  const [showTransaction, setShowTransaction] = useState();

  return (
    <>
      <DividerStyled />
      <PartTransactionsList
        partId={partId}
        openPartTransactionHandler={(id) => setShowTransaction(id)}
      />
      <ViewPartTransaction
        transactionId={showTransaction}
        isShow={!!showTransaction}
        closePartTransactionHandler={() => setShowTransaction()}
      />
    </>
  );
};
