import type { ModalType } from "widgets/ModalController/types";
import { ModalLink } from "shared/components/ModalLink";
import { Fragment, ReactNode } from "react";
import styled from "styled-components";

interface ModalLinksListProps {
  type: ModalType;
  items?: Array<{ id: string | number; name: string }>;
  layout: "inline" | "stacked";
  separator?: ReactNode;
}

/** Simple layouts for lists of ModalLinks */
export const ModalLinksList = ({
  type,
  items,
  layout,
  separator,
}: ModalLinksListProps) => {
  if (!items) return null;

  if (layout === "inline") {
    return (
      <>
        {items.map((item, i) => (
          <Fragment key={item.id}>
            <ModalLink modalParams={{ type, id: item.id }}>
              {item.name}
            </ModalLink>
            {i < items.length - 1 && separator}
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <List>
      {items.map((item, i) => (
        <li key={item.id}>
          <ModalLink modalParams={{ type, id: item.id }}>{item.name}</ModalLink>
          {i < items.length - 1 && separator}
        </li>
      ))}
    </List>
  );
};

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
