import React, { useCallback, useState } from "react";
import { SnackBarContainer } from "shared/components/SnackBarContainer";

export const SnackBarContext = React.createContext();

let id = 1;

const SnackBarProvider = ({ children }) => {
  const [snackBars, setSnackBars] = useState([]);

  const addSnackBar = useCallback(
    (content) => {
      setSnackBars((snackBars) => [
        ...snackBars,
        {
          id: id++,
          ...content,
        },
      ]);
    },
    [setSnackBars]
  );

  const removeSnackBar = useCallback(
    (id) => {
      setSnackBars((snackBars) => snackBars.filter((t) => t.id !== id));
    },
    [setSnackBars]
  );

  return (
    <SnackBarContext.Provider value={{ addSnackBar, removeSnackBar }}>
      <SnackBarContainer snackBars={snackBars} />
      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
