import * as Yup from "yup";
import { getSelectSchema } from "shared/lib/helpers/validation";

export const otherCostValidationSchema = (t, action) =>
  Yup.object().shape({
    title: Yup.string()
      .required(t("validation.required"))
      .max(255, t("validation.stringMaxLength", { stringMaxLength: 255 })),
    note: Yup.string().max(
      1000,
      t("validation.stringMaxLength", { stringMaxLength: 1000 })
    ),
    vendor: getSelectSchema(t, { required: false }),
    cost: Yup.number()
      .typeError(t("validation.positive"))
      .min(0, t("validation.positive"))
      .max(
        99999999999999999999.99,
        t("validation.fractionalNumberMaxLength", {
          integerLength: 20,
          decimalLength: 2,
        })
      )
      .required(t("validation.required")),
  });
