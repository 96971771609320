export const allChildrenHaveSubchildren = (children) => {
  return children?.every((child) => child.children.length === 0);
};

export const countChildren = (items, level = 0) => {
  let count = 0;

  // count all the children except the last one
  for (let i = 0; i < items.length - 1; i++) {
    count += 1;
    count += countChildren(items[i].children, level + 1);
  }

  // count all the children of last one except 0 level
  if (items.length > 0) {
    const lastChild = items[items.length - 1];
    count += 1;

    if (lastChild && level !== 0) {
      count += countChildren(lastChild.children, level + 1);
    }
  }

  return count;
};

export const sortChildren = (data) => {
  return data.map((item) => {
    const sortedChildren =
      item.children && item.children.length > 0
        ? [...item.children].sort((a, b) => a.name.localeCompare(b.name))
        : item.children;

    return {
      ...item,
      children: sortedChildren ? sortChildren(sortedChildren) : [],
    };
  });
};

export const getAllChildrenIds = (data) => {
  let ids = [];

  function findCurrentAndCollectIds(node) {
    if (node.current) {
      collectIds(node);
      return true;
    }

    if (node.children) {
      for (let child of node.children) {
        if (findCurrentAndCollectIds(child)) return true;
      }
    }
    return false;
  }

  function collectIds(node) {
    if (node.children) {
      for (let child of node.children) {
        ids.push(child.id);
        collectIds(child);
      }
    }
  }

  findCurrentAndCollectIds({ children: data });
  return ids;
};
