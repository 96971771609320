import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const categoriesApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params) => ({
        url: "categories",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Categories", id: "CategoriesList" },
        ...getTagList("Categories", result?.data),
      ],
    }),
    getAdminCategories: builder.query({
      query: (params) => ({
        url: "/admin/categories",
        params,
      }),
      providesTags: (result) => [
        { type: "AdminCategories", id: "AdminCategoriesList" },
        ...getTagList("AdminCategories", result?.data),
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getCategory: builder.query({
      query: (id) => `/admin/categories/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, { id }) => [
        { type: "AdminCategories", id },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    updateCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/categories/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      invalidatesTags: ["AdminCategories"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/admin/categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "AdminCategories", id }],
      transformErrorResponse: (response) => response.data,
    }),
    addCategory: builder.mutation({
      query: (body) => ({
        url: "/admin/categories",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "AdminCategories", id: "AdminCategoriesList" }],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetAdminCategoriesQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} = categoriesApi;
