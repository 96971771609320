import React from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "shared/components/Empty";
import AllUsers from "../../assets/icons/empty.svg?react";

export const ErrorAllUsersList = () => {
  const { t } = useTranslation();

  return <Empty icon={<AllUsers />} heading={t("allUsers.error")} spacious />;
};
