import { CMMSAPI } from "app/services";

export const fieldConfigurationApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUserFieldConfiguration: builder.query({
      query: ({ type }) => `/user_field_configurations?type=${type}`,
      providesTags: (result, error, { type }) => [
        { type: "FieldConfiguration", id: type },
      ],
    }),
    updateUserFieldConfiguration: builder.mutation({
      query: ({ type, body }) => ({
        url: `/user_field_configurations?type=${type}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { type }) => [
        { type: "FieldConfiguration", id: type },
      ],
    }),
  }),
});

export const {
  useGetUserFieldConfigurationQuery,
  useUpdateUserFieldConfigurationMutation,
} = fieldConfigurationApi;
