import React from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import { usePermission } from "app/providers/PermissionsProvider";

const PartAdminConfigurationFields = [
  "unit_cost",
  "number",
  "part_type",
  "assets",
  "vendors",
  "lead_time",
  "urls",
];

export const PartsAdminSettings = () => {
  const { adminSettingsPartPermit } = usePermission();
  const { data } = useGetAdminConfigurationQuery({ type: "part" });

  if (!data || !data?.data) return null;

  const {
    data: { payload },
  } = data;

  const currentConfiguration = getConfigurationFields(
    PartAdminConfigurationFields,
    payload
  );

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="part"
      hasPermission={adminSettingsPartPermit}
    />
  );
};
