import styled from "styled-components";
import { ComponentPropsWithoutRef } from "react";
import { Container as SidebarContainer } from "widgets/SideBar/components/styled";

/** Manages text show/hide animation for the Sidebar */
export const SidebarItemLabel = (props: ComponentPropsWithoutRef<"span">) => {
  return <Text {...props} />;
};

const Text = styled.span`
  ${SidebarContainer} & {
    transition: font-size 0.3s 0.15s, opacity 0.2s 0.3s;
    font-size: 14px;
    opacity: 1;
  }

  ${SidebarContainer}.hidden & {
    transition: font-size 0.3s, opacity 0.2s;
    font-size: 0;
    opacity: 0;
  }
`;
