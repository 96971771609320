export const initialValues = {
  name: "",
  tax_id: "",
  vendor_contacts_attributes: [],
  description: "",
  urls_attributes: [],
  hourly_rate: "",
  address: "",
  locations: [],
  assign_to_work_orders: false,
  parts_supplier: false,
  categories: [],
  assets: [],
  parts: [],
  rating: undefined,
  uploads_attributes: [],
};

export const VendorFormOptionalFields = [
  "rating",
  "tax_id",
  "vendor_contacts_attributes",
  "urls_attributes",
  "address",
  "hourly_rate",
  "locations_ids",
  "categories_ids",
  "assets_ids",
  "parts_ids",
];
