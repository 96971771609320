import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ isShowInfoQuantity }) =>
    isShowInfoQuantity
      ? css`
          justify-content: space-between;
        `
      : css`
          justify-content: end;
        `}
  padding: 16px var(--intercom-offset) 24px 0;
`;

export const Wrapper = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  background: var(--color_background_light);
  border-radius: 4px;
  border: 1px solid var(--color_border);

  & > li:first-child {
    border-radius: 4px 0 0 4px;
  }
  & > li:last-child {
    border-radius: 0 4px 4px 0;
  }
  & > li {
    display: flex;
    width: 36px;
    height: 37px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    cursor: pointer;
    svg > path {
      fill: var(--color_gray500);
    }
  }
  & > li:last-child {
    margin-right: 0;
  }
`;

export const Button = styled.li`
  background: var(--color_background_light);
  color: var(--color_primary_text);

  svg {
      fill: currentColor;
  }
  
  ${(props) =>
    props.paginationItemSelected &&
    css`
      background-color: var(--color_primary);
      border: none !important;
    `}
  ${(props) =>
    props.paginationItemDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  ${(props) =>
    props.paginationItemPrev &&
    css`
      & svg {
        transform: rotate(-180deg);
      }
    `}
  }
`;
export const Dots = styled.li`
  color: var(--color_primary_text);
  border: none !important;
`;

export const PaginationInfoWrapper = styled.li`
  padding: 8px 12px;
  background: var(--color_background_light);
  border: 1px solid var(--color_grey200);
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: var(--color_grey500);
`;
