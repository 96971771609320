import styled, { css } from "styled-components";
import { ReactNode } from "react";

interface RowDecoratorProps {
  checkbox?: ReactNode;
  icon?: ReactNode;
  checked?: boolean;
}

/**
 * Displays a row checkbox and/or icon, and switches between them based on the row's state.
 */
export const RowDecorator = ({
  checkbox,
  icon,
  checked,
}: RowDecoratorProps) => {
  return (
    <Wrapper hasIcon={!!icon} hasCheckbox={!!checkbox} checked={checked}>
      <Checkbox>{checkbox}</Checkbox>
      <Icon>{icon}</Icon>
    </Wrapper>
  );
};

interface WrapperProps {
  hasIcon: boolean;
  hasCheckbox: boolean;
  checked?: boolean;
}

const Checkbox = styled.span``;
const Icon = styled.span``;

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  flex-grow: 0;

  margin-right: 8px;
  border-radius: 4px;
  overflow: hidden;

  ${Checkbox},
  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${Checkbox} {
    // Checkbox should be invisible if there's an icon, but be available to be focused via keyboard/screen reader
    opacity: ${(p) => (p.hasIcon ? "0" : "1")};
  }

  ${Icon} {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(p) => (p.hasIcon ? "1" : "0")};
    pointer-events: none;
    aria-hidden: true;
  }

  ${(p) =>
    p.hasCheckbox &&
    css`
      &:hover,
      &:focus-within {
        ${Checkbox} {
          opacity: 1;
        }

        ${Icon} {
          opacity: 0;
        }
      }
    `}

  ${(p) =>
    p.checked &&
    css`
      ${Checkbox} {
        opacity: 1;
      }

      ${Icon} {
        opacity: 0;
      }
    `}
`;
