import { t } from "i18next";
import { Input } from "shared/components/Input";
import { CustomFormik } from "shared/lib/hooks/useForm";
import styled from "styled-components";

interface EditorProps {
  form: CustomFormik;
}

export const Editor = ({ form }: EditorProps) => {
  return (
    <Container>
      <Input
        name="name"
        value={form.values.name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("procedures.inputs.name")}
        errorMessage={form.touched.title ? (form.errors.title as string) : ""}
        height={50}
        fontSize={20}
        required
        disabled={false}
        aria-label="Name"
      />

      <Input
        name="description"
        value={form.values.description}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("procedures.inputs.description")}
        errorMessage={form.touched.title ? (form.errors.title as string) : ""}
        height={200}
        fontSize={20}
        required
        disabled={false}
        aria-label="Description"
        isTextArea
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
