import React, { useState } from "react";

import EditIcon from "shared/assets/icon/edit.svg?react";
import { formatCurrency } from "shared/lib/helpers/common";

import { getTimes } from "../../lib/helpers";
import {
  UserBox,
  Cell,
  GeneralCell,
  Box,
  StyledIconButton,
} from "./WorkOrderTimeView.styled";
import { WorkOrderTimeUpdate } from "../WorkOrderTimeUpdate";
import { useDisplayDate } from "shared/lib/hooks";
import { usePermission } from "app/providers/PermissionsProvider";
import { UserAvatar } from "shared/components/UserAvatar";

export const WorkOrderTimeView = ({
  value = {},
  users,
  usersOptions,
  onUsersSearch,
  onUpdate,
  onDelete,
  index,
  columns = 4,
  type,
  status,
  techniciansIds,
}) => {
  const {
    created_at,
    amount_in_minutes,
    amount,
    total_time_cost,
    user,
    rate_type,
    id,
  } = value;

  const displayDate = useDisplayDate();

  const { canViewTimeWO, canUpdateTimeWO } = usePermission();

  const canUpdateTime = canUpdateTimeWO(user.id, techniciansIds, status);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const selectedUser =
    users?.data?.find((user) => user.id === value.user?.value) || {};
  const userData = type === "creation" ? selectedUser : user;

  const date = created_at ? created_at : new Date();
  // const time = amount_in_minutes
  //   ? getTimes(amount_in_minutes)
  //   : getTimes(null, amount);
  const time = getTimes(amount_in_minutes, amount);
  const cost = formatCurrency(total_time_cost);
  const userName = userData.full_name || "";
  const editFormValues = {
    user: { value: userData.id, label: userData.full_name },
    amount: time,
    rate_type,
  };

  const openEditModal = () => setIsShowEditModal(true);
  const closeEditModal = () => setIsShowEditModal(false);

  return (
    <Box columns={columns}>
      <UserBox>
        <UserAvatar user={user} size="xs" />
        <Cell>{userName}</Cell>
      </UserBox>
      <GeneralCell>{displayDate(date, true)}</GeneralCell>
      <GeneralCell>{time}</GeneralCell>
      {canViewTimeWO() !== "partial" && cost && (
        <GeneralCell>{cost}</GeneralCell>
      )}
      {canUpdateTime && (
        <StyledIconButton onClick={openEditModal} className="actionIcon">
          <EditIcon />
        </StyledIconButton>
      )}

      {isShowEditModal && (
        <WorkOrderTimeUpdate
          id={id}
          index={index}
          data={value}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onClose={closeEditModal}
          usersOptions={usersOptions}
          onUsersSearch={onUsersSearch}
          value={editFormValues}
          canDeleteTimeWo={canUpdateTime}
        />
      )}
    </Box>
  );
};
