import MarkerIcon from "./MarkerIcon.svg?react";
import { SidebarButton } from "widgets/SideBar/components/styled";
import { t } from "i18next";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getUser } from "shared/components/Auth";
import { useGetUserQuery } from "modules/users";
import { skipToken } from "@reduxjs/toolkit/query";
import { SidebarItemLabel } from "widgets/SideBar/components/SidebarItemLabel";
import { Tooltip } from "react-tooltip";

/** Triggers the Marker.io feedback widget */
export const BugReportButton = ({ collapsed }: { collapsed: boolean }) => {
  const { enableMarkerIoFeedback } = useFlags();
  const user = getUser() ?? {};
  const { data } = useGetUserQuery(user?.id ?? skipToken);

  if (!enableMarkerIoFeedback) {
    return null;
  }

  return (
    <>
      <SidebarButton
        data-tooltip-id={"bug-report"}
        onClick={() => submitFeedback(data)}
      >
        <div>
          <MarkerIcon />
          <SidebarItemLabel>{t("sidebar.bugReport")}</SidebarItemLabel>
        </div>
      </SidebarButton>
      <Tooltip
        id="bug-report"
        content={t("sidebar.bugReport") || ""}
        hidden={!collapsed}
        opacity={1.0}
        place="right"
        offset={15}
        positionStrategy="fixed"
      />
    </>
  );
};

function submitFeedback(user?: any) {
  const Marker = window.Marker;
  if (!Marker) {
    return;
  }

  if (user && import.meta.env.VITE_DEPLOY_ENV === "production") {
    Marker.setReporter({
      email: user.email,
      fullName: user.full_name,
    });

    Marker.setCustomData({
      tenant: user.tenant,
      jobTitle: user.job_title,
    });
  }

  Marker.capture("fullscreen");
}
