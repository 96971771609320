export const maxSizeB = 100 * 1024 * 1024;

export const acceptFormat = [
  "image/*",
  "audio/*",
  "video/*",
  "font/*",
  "text/*",
  "application/json",
  "application/ld+json",
  "application/pdf",
  "application/zip",
  "application/x-tar",
  "application/vnd.rar",
  "application/x-7z-compressed",
  "application/xml",
  "application/xhtml+xml",
  "application/vnd.ms-powerpoint",
  "application/x-httpd-php",
  "application/ogg",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];
export const prohibitedFileFormats = [
  ".action",
  ".apk",
  ".pkg",
  ".app",
  ".bat",
  ".bin",
  ".cab",
  ".cmd",
  ".com",
  ".command",
  ".cpl",
  ".csh",
  ".ex",
  ".exe",
  ".gadget",
  ".inf",
  ".ins",
  ".inx",
  ".ipa",
  ".isu",
  ".job",
  ".jse",
  ".ksh",
  ".lnk",
  ".msc",
  ".msi",
  ".msp",
  ".mst",
  ".osx",
  ".out",
  ".paf",
  ".pif",
  ".prg",
  ".ps1",
  ".reg",
  ".rgs",
  ".run",
  ".scr",
  ".sct",
  ".shb",
  ".shs",
  ".u3p",
  ".vb",
  ".vbe",
  ".vbs",
  ".vbscript",
  ".workflow",
  ".ws",
  ".wsf",
  ".wsh",
  ".0xe",
  ".73k",
  ".89k",
  ".a6p",
  ".ac",
  ".acc",
  ".acr",
  ".actm",
  ".ahk",
  ".air",
  ".arscript",
  ".as",
  ".asb",
  ".awk",
  ".azw2",
  ".beam",
  ".btm",
  ".cel",
  ".celx",
  ".chm",
  ".cof",
  ".crt",
  ".dek",
  ".dld",
  ".dmc",
  ".docm",
  ".dotm",
  ".dxl",
  ".ear",
  ".ebm",
  ".ebs",
  ".ebs2",
  ".ecf",
  ".eham",
  ".elf",
  ".es",
  ".ex4",
  ".exopc",
  ".ezs",
  ".fas",
  ".fky",
  ".fpi",
  ".frs",
  ".fxp",
  ".gs",
  ".ham",
  ".hms",
  ".hpf",
  ".iim",
  ".ipf",
  ".isp",
  ".jar",
  ".js",
  ".jsx",
  ".kix",
  ".lo",
  ".ls",
  ".mam",
  ".mcr",
  ".mel",
  ".mpx",
  ".mrc",
  ".ms",
  ".mxe",
  ".nexe",
  ".obs",
  ".ore",
  ".otm",
  ".pex",
  ".plx",
  ".potm",
  ".ppam",
  ".ppsm",
  ".pptm",
  ".prc",
  ".pvd",
  ".pwc",
  ".pyc",
  ".pyo",
  ".qpx",
  ".rbx",
  ".rox",
  ".rpj",
  ".s2a",
  ".sbs",
  ".sca",
  ".scar",
  ".csb",
  ".script",
  ".smm",
  ".spr",
  ".tcp",
  ".thm",
  ".tlb",
  ".tms",
  ".udf",
  ".upx",
  ".url",
  ".vlx",
  ".vpm",
  ".wcm",
  ".widget",
  ".wiz",
  ".wpk",
  ".wpm",
  ".xap",
  ".xbap",
  ".xlam",
  ".xlm",
  ".xlsm",
  ".xltm",
  ".xqt",
  ".xys",
  ".zl9",
];
