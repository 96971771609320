import React from "react";
import styled from "styled-components";

import ImageIcon from "shared/assets/icon/image.svg?react";
import { Button } from "shared/components/Button";

export const RejectedStatus = ({
  name,
  errorMessage,
  onDelete = null,
  onRetry,
}) => {
  return (
    <RejectedContainer>
      <ImageAction isDark>
        <ImageIcon />
      </ImageAction>
      <FileName>{name}</FileName>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <ActionWrapper>
        <Button onClick={onRetry} variant="secondary" size="small">
          Retry
        </Button>
        {onDelete && (
          <Button onClick={onDelete} variant="tertiary" size="small">
            Remove
          </Button>
        )}
      </ActionWrapper>
    </RejectedContainer>
  );
};

const RejectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  font-size: 14px;
  line-height: 20px;
`;

const ImageAction = styled.div`
  color: var(--color_background);
  text-align: center;
  height: 21px;
  & > svg {
    width: 21px;
    height: 21px;
  }
`;

const FileName = styled.div`
  margin-top: 18px;
  color: var(--color_grey900);
`;

const ErrorMessage = styled.div`
  color: var(--color_alert);
`;

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  & > button:last-child {
    margin-left: 15px;
  }
  & > button:first-child {
    margin-left: 0;
  }
`;
