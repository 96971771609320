import React from "react";
import styled from "styled-components";

export const Spinner = ({ height }) => {
  return (
    <SpinnerContainer height={height}>
      <Spin />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? height : `calc(100vh - 50px)`)};
`;

const Spin = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid rgb(0, 240, 180);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
`;
