/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLazyGetWorkOrderTimesQuery } from "modules/workOrderTime";
import { getUser } from "shared/components/Auth";
import { getFormattedTimer, isTimerOverdue } from "./helpers";

export const useTimer = (workOrderId, statusChangeTrigger) => {
  const [timer, setTimer] = useState("");
  const [timerRunning, setTimerRunning] = useState(true);

  const currentUserId = getUser()?.id;

  const [triggerWorkOrderTimesRefetch, { currentData: woTimesData }] =
    useLazyGetWorkOrderTimesQuery();
  const [triggerActiveUserTimesRefetch, { currentData: activeUserTimesData }] =
    useLazyGetWorkOrderTimesQuery();

  useEffect(() => {
    if (workOrderId) {
      triggerWorkOrderTimesRefetch({ "filter[work_order_id_eq]": workOrderId });
    }
    if (currentUserId) {
      triggerActiveUserTimesRefetch({
        "filter[created_by_id_eq]": currentUserId,
        "filter[is_active_true]": 1,
      });
    }
  }, [workOrderId, currentUserId, statusChangeTrigger]);

  const activeWOTimer = woTimesData?.data?.find(
    (time) => time.is_active && time.created_by_id === Number(currentUserId)
  );
  const startTimerDate = activeWOTimer?.started_at;
  const isTimesOverdue = isTimerOverdue(startTimerDate);

  const timesValue = woTimesData?.data?.filter((val) => !val.is_active);

  useEffect(() => {
    if (startTimerDate && timerRunning && workOrderId) {
      const intervalId = setInterval(() => {
        const formattedTimer = getFormattedTimer(startTimerDate);
        setTimer(formattedTimer);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [startTimerDate, timerRunning, workOrderId]);

  return {
    isUserActiveTimer: !!activeUserTimesData?.data?.length > 0,
    activeTimer: activeUserTimesData?.data[0],
    isWOActiveTimer: !!activeWOTimer,
    timer,
    isTimesOverdue,
    setTimerRunning,
    timesValue,
  };
};
