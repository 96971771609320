import { useTranslation } from "react-i18next";
import { LightModal } from "shared/components/Modals/LightModal";
import { PhoneInput } from "shared/components/PhoneInput";
import { InlineLabel } from "shared/components/widgets";
import styled from "styled-components";

export const Modal = ({ show, form, onClose, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <LightModal
      isOpen={show}
      onClose={onClose}
      onCancel={onClose}
      secondaryButtonLabel={t("discardChanges")}
      label={t("profile.phoneNumber.modalTitle")}
      onSubmit={handleSubmit}
      disabledSubmit={!form.isWasChanged || !form.isFormValid}
    >
      <Box>
        <InlineLabel label={t("profile.phoneNumber.title")} labelWidth={140}>
          <PhoneInput
            value={form.values.phone_number}
            setValue={(value) => form.setFieldValue("phone_number", value)}
            errorMessage={form.touched.phone_number && form.errors.phone_number}
            touched={(value) => form.setTouched({ phone_number: true })}
          />
        </InlineLabel>
      </Box>
    </LightModal>
  );
};

const Box = styled.div`
  width: 480px;
  padding: 32px 16px;
`;
