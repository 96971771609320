import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button } from "shared/components/Button";
import { Hyperlink } from "./Hyperlink";

const name = "urls_attributes";
const DEFAULT_VALUE = { label: "", url: "" };

export const HyperlinkManager = ({ value = [], onBlur, setValue, errors }) => {
  const addClickHandler = () => {
    setValue(name, [...value, DEFAULT_VALUE]);
  };

  const onChangeHandle = (field, index, val) => {
    const newValue = value.map((v, i) =>
      i === index ? { ...v, [field]: val } : v
    );
    setValue(name, newValue);
  };

  const onDeleteHandle = (index) => {
    const newValue = value.filter((v, i) => i !== index);
    setValue(name, newValue);
  };

  return (
    <Wrapper>
      <ListWrapper>
        {value.map((val, index) => {
          return (
            <Hyperlink
              key={`${val}-${index}`}
              value={val}
              errors={errors && errors[index]}
              onChange={onChangeHandle}
              onBlur={onBlur}
              onDelete={onDeleteHandle}
              index={index}
            />
          );
        })}
      </ListWrapper>
      <Button onClick={addClickHandler} variant="secondary">
        Add URL
      </Button>
      {errors && typeof errors === "string" && <Error>{errors}</Error>}
    </Wrapper>
  );
};

HyperlinkManager.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  onBlur: PropTypes.func,
  errors: PropTypes.array,
  setValue: PropTypes.func,
};

const Wrapper = styled.div`
  position: relative;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

const Error = styled.div`
  position: absolute;
  color: var(--color_alert);
  font-size: 14px;
  top: calc(100% + 6px);
`;
