import { CMMSAPI } from "app/services";

export const commentsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    addComment: builder.mutation({
      query: ({ id, comment, type }) => ({
        url: "comments",
        method: "POST",
        body: { content: comment, target_type: type, target_id: id },
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (result, error, arg) => {
        return arg.type === "WorkOrderRequest"
          ? ["RequestDetails"]
          : ["WorkOrderDetails"];
      },
    }),
    updateComment: builder.mutation({
      query: ({ id, comment, type, target_id }) => ({
        url: `comments/${id}`,
        method: "PATCH",
        body: { content: comment, target_type: type, target_id },
      }),
      invalidatesTags: (result, error, arg) => {
        return arg.type === "WorkOrderRequest"
          ? ["RequestDetails"]
          : ["WorkOrderDetails"];
      },
      transformErrorResponse: (response) => response.data,
    }),
    deleteComment: builder.mutation({
      query: ({ id }) => ({
        url: `/comments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => {
        return arg.type === "WorkOrderRequest"
          ? ["RequestDetails"]
          : ["WorkOrderDetails"];
      },
    }),
  }),
});

export const {
  useAddCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} = commentsApi;
