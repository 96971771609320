import { CMMSAPI } from "app/services";

export const customerApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: (id) => ({
        url: `/customers/${id}`,
      }),
      providesTags: ["Customers"],
      transformResponse: (response) => response.data,
    }),
    editCustomer: builder.mutation({
      query: ({ id, body }) => ({
        method: "PATCH",
        url: `/customers/${id}`,
        body,
      }),
      invalidatesTags: () => ["Customers"],
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const { useGetCustomerQuery, useEditCustomerMutation } = customerApi;
