import styled from "styled-components";
import { Button } from "shared/components/Button";

import { H2, Description } from "shared/components/Typography";

export const Box = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
`;

export const ButtonsBox = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const CustomButton = styled(Button)`
  margin-left: auto;
`;

export const Role = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
`;

export const Name = styled(H2)`
  margin-bottom: 5px;
  overflow-wrap: anywhere;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 16px;
`;

export const Email = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: var(--color_header_search_clear_color);
  overflow-wrap: anywhere;
`;

export const SubTitle = styled(Description)`
  margin-bottom: 0;
`;

export const NameContainer = styled.div`
  overflow: hidden;
`;
