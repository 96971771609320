import styled, { css } from "styled-components";
import { Button } from "shared/components/Button";

export const ButtonEdit = styled(Button)`
  margin-left: auto;
`;

export const Statuses = styled.div`
  display: flex;
  width: 100%;
  margin-right: 12px;
  position: relative;
`;

export const Box = styled.div`
  align-items: center;
  display: flex;
  width: 91px;
  gap: 8px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  line-height: 14px;
  font-size: 12px;
  color: var(--color_text_color);
  display: flex;
  justify-content: center;
  gap: 4px;
  white-space: nowrap;
  padding: 8px 10px;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: ${({ children }) =>
      children === "Completed" ? "4px" : "0 4px 4px 0"};
  }

  ${(props) =>
    props.isSelected &&
    css`
      cursor: initial;
      pointer-events: none;
      background: ${`var(--color_${props.color})`};
    `}
`;
