import styled from "styled-components";

interface ProcedureTitleProps {
  onClick: () => void;
  value: string;
}

export const ProcedureTitle = ({ onClick, value }: ProcedureTitleProps) => {
  return (
    <TitleContainer onClick={onClick}>
      <SubString>{value}</SubString>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const SubString = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;
