import React from "react";
import CloseIcon from "shared/assets/icon/close.svg?react";
import RowIcon from "shared/assets/icon/row.svg?react";

import { Button } from "shared/components/Button";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import {
  Backdrop,
  Container,
  CloseIconButton,
  RowContainer,
  ContentBox,
  Img,
  Footer,
  Description,
  PrimeInfo,
  SecondaryInfo,
} from "./ImageView.styled";

export const DialogImageView = ({
  showDialog,
  scr,
  fileName,
  secondaryInformation = "",
  onClose = () => null,
  closeButtonLabel = "Close",
  onDownload,
  downloadButtonLabel = "Download",
  onNext,
  onPrev,
}) => {
  if (!showDialog) return null;
  return createPortal(
    <Backdrop>
      <Container>
        <CloseIconButton onClick={onClose}>
          <span>{closeButtonLabel}</span>
          <CloseIcon />
        </CloseIconButton>
        {onPrev && (
          <RowContainer onClick={onPrev} isPrev>
            <RowIcon />
          </RowContainer>
        )}
        <ContentBox>
          <Img src={scr} alt={fileName} />
        </ContentBox>
        {onNext && (
          <RowContainer onClick={onNext} isNext>
            <RowIcon />
          </RowContainer>
        )}
        <Footer>
          <Description>
            <PrimeInfo>{fileName}</PrimeInfo>
            <SecondaryInfo>{secondaryInformation}</SecondaryInfo>
          </Description>
          {onDownload && (
            <Button onClick={onDownload} variant="primary" size="small">
              {downloadButtonLabel}
            </Button>
          )}
        </Footer>
      </Container>
    </Backdrop>,
    document.body
  );
};

DialogImageView.propTypes = {
  showDialog: PropTypes.bool,
  scr: PropTypes.string,
  fileName: PropTypes.string,
  secondaryInformation: PropTypes.string,
  onClose: PropTypes.func,
  closeButtonLabel: PropTypes.string,
  onDownload: PropTypes.func,
  downloadButtonLabel: PropTypes.string,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
};
