import { useLocation } from "react-router-dom";
import { WorkOrderModal } from "modules/workOrders/components/WorkOrderModal/WorkOrderModal";
import { PMModal } from "modules/preventiveMaintenances/components/PMModal/PMModal";
import { ModalType } from "widgets/ModalController/types";
import { decodeModalParam } from "widgets/ModalController/modalParamUtils";
import { RequestModal } from "modules/requests/components/RequestModal";
import { AssetModal } from "modules/assets/components/AssetModal";
import { LocationModal } from "modules/locations/components/LocationModal";
import { PartModal } from "modules/parts/components/PartModal";
import { UserModal } from "modules/users/components/UserModal";
import { VendorModal } from "modules/vendors/components/VendorModal";
import { RequestPortalModal } from "modules/requestPortals/components/RequestPortalModal";
import { ProcedureTemplateModal } from "modules/procedures/components/ProcedureTemplateModal";
import { ProcedureModal } from "modules/procedures/components/ProcedureModal";
import { ProceduresProvider } from "modules/procedures/components/ProcedureModal/context/ProceduresProvider";

/** Displays globally-available modals, controlled by the `m` query param */
export const ModalController = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const modalParams = decodeModalParam(params.get("m"));

  if (!modalParams) return null;
  switch (modalParams.type as ModalType) {
    case "wo":
      return <WorkOrderModal params={modalParams} />;
    case "pm":
      return <PMModal params={modalParams} />;
    case "request":
      return <RequestModal params={modalParams} />;
    case "asset":
      return <AssetModal params={modalParams} />;
    case "location":
      return <LocationModal params={modalParams} />;
    case "part":
      return <PartModal params={modalParams} />;
    case "entityUser":
      return <UserModal params={modalParams} userType="entityUser" />;
    case "user":
      return <UserModal params={modalParams} userType="user" />;
    case "vendor":
      return <VendorModal params={modalParams} />;
    case "portal":
      return <RequestPortalModal params={modalParams} />;
    case "procedureTemplate":
      return <ProcedureTemplateModal params={modalParams} />;
    case "procedure":
      return (
        <ProceduresProvider>
          <ProcedureModal params={modalParams} />;
        </ProceduresProvider>
      );
    default:
      return null;
  }
};
