import { CustomerView } from "modules/customer";
import { EditCustomerName } from "modules/customer/components/EditCustomerName";
import { AllEntities, CreateEntity, EditEntity } from "modules/entities";
import { Route, Routes, useNavigate } from "react-router-dom";
import { usePermission } from "app/providers/PermissionsProvider";
import styled from "styled-components";

export const GeneralSettings = () => {
  const navigate = useNavigate();
  const { adminSettingsCustomerPermit, adminSettingsEntitiesPermit } =
    usePermission();

  const updateEntityDetailsHandler = (id) =>
    id
      ? navigate(`/admin-settings/general/entity/${id}/edit`)
      : navigate("/admin-settings/general");
  const handleCloseModal = () => navigate("/admin-settings/general");

  return (
    <Box>
      {adminSettingsCustomerPermit && <CustomerView />}
      {adminSettingsEntitiesPermit && (
        <AllEntities openEntityEditor={updateEntityDetailsHandler} />
      )}
      {/* Modals */}
      <Routes>
        <Route
          path="customer/:customerId/edit"
          element={
            <EditCustomerName
              isShow={adminSettingsCustomerPermit}
              onCloseModal={handleCloseModal}
            />
          }
        />
        <Route path="entity">
          <Route
            path=":entityId/edit"
            element={
              <EditEntity
                isShow={adminSettingsEntitiesPermit}
                onCloseModal={handleCloseModal}
              />
            }
          />
          <Route
            path="add"
            element={
              <CreateEntity
                isShow={adminSettingsEntitiesPermit}
                onCloseModal={handleCloseModal}
              />
            }
          />
        </Route>
      </Routes>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto 40px;
  width: 80%;
`;
