import styled from "styled-components";
import { AssetStatusBadge } from "modules/assets/components/AssetStatusBadge";

export const InlineDetailsValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InlineAssetStatusBadge = styled(AssetStatusBadge)`
  margin-left: 8px;
`;
