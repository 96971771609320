import styled from "styled-components";
import {
  ResponsiveContainer,
  Pie,
  PieChart,
  Sector,
  SectorProps,
  Cell,
} from "recharts";
import { ChartLoadingAnimation } from "modules/reporting/components/charts/ChartLoadingAnimation";
import { useDonutChartContext } from "modules/reporting/components/charts/DonutChart/DonutChart";
import React from "react";

/** Props that are shared between the DonutChart and DonutChartSkeleton */
const pieProps = {
  startAngle: 450,
  endAngle: 90,
  innerRadius: "70%",
  outerRadius: "100%",
  stroke: "none",
  isAnimationActive: false,
  paddingAngle: 0,
};
/** The amount (in pixels) to increase the outerRadius of the active slice by */
const expandActiveBy = 4;
const margin = expandActiveBy + 1;

interface DonutChartChartProps {
  children?: React.ReactNode;
}

/** The *actual* the Donut Chart */
export const DonutChartChart = ({ children }: DonutChartChartProps) => {
  const { data, isLoading, sliceConfigs, active, setActive } =
    useDonutChartContext();

  if (isLoading) {
    return <DonutChartSkeleton />;
  }

  return (
    <Wrapper>
      <ChartWrapper>
        <ResponsiveContainer>
          <PieChart
            margin={{
              top: margin,
              bottom: margin,
              left: margin,
              right: margin,
            }}
          >
            <Pie
              {...pieProps}
              data={data}
              dataKey="value"
              nameKey="name"
              onMouseOver={(data, index) =>
                setActive({
                  key: data.payload.key,
                  value: data.payload.value,
                  value2: data.payload.value2,
                  index,
                })
              }
              onMouseLeave={() => setActive(undefined)}
              activeIndex={active?.index}
              activeShape={<ActiveShape />}
            >
              {data?.map((entry, index) => {
                const config = sliceConfigs[entry.key] ?? { fill: "#BFBFC3" };
                return <Cell key={`cell-${index}`} fill={config.fill} />;
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {children}
      </ChartWrapper>
    </Wrapper>
  );
};

/** Makes the active (hovered) sector slightly larger */
const ActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}: SectorProps) => {
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius ? outerRadius + expandActiveBy : undefined}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};

const DonutChartSkeleton = () => {
  return (
    <Wrapper>
      <ChartWrapper>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              {...pieProps}
              dataKey="value"
              data={[{ value: 1, name: "" }]}
              activeIndex={0}
              activeShape={<LoadingShape />}
            />
          </PieChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </Wrapper>
  );
};

const LoadingShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
}: SectorProps) => (
  <ChartLoadingAnimation>
    <Sector {...{ cx, cy, innerRadius, startAngle, endAngle, outerRadius }} />
  </ChartLoadingAnimation>
);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

/**
 * Wrapping `ResponsiveContainer` in an absolute-positioned container improves resize
 *    behaviour and performance
 */
const ChartWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
