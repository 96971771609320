import styled from "styled-components";
import { User } from "modules/users/types/User";
import MessageIcon from "modules/users/assets/icons/message.svg?react";
import { Avatar } from "shared/components/Avatar";
import { ComponentProps } from "react";

export interface UserAvatarProps
  extends Pick<ComponentProps<typeof Avatar>, "size"> {
  user: User;
}

/** Wraps the generic Avatar component with some convenient defaults for the User object */
export const UserAvatar = ({ user, size }: UserAvatarProps) => {
  if (!user) {
    return null;
  }
  const img = user.images?.[0]?.attachment_urls?.small;

  let fallback, color;
  if (
    !["active", "inactive", "change_email_in_progress"].includes(user.status)
  ) {
    fallback = <InvitationPending />;
  } else {
    color = fallbackColors[user.id % fallbackColors.length];

    fallback = `${firstChar(user.name)}${
      size !== "xs" ? firstChar(user.surname) : ""
    }`;
  }

  return (
    <Avatar
      size={size}
      src={img}
      fallback={fallback}
      alt={`${user.name} ${user.surname}`}
      color={color}
    />
  );
};

function firstChar(str?: string) {
  return str?.charAt(0) ?? "";
}

const fallbackColors = [
  "#FCF1EF",
  "#F4F0FF",
  "#F1F1FF",
  "#E9F3FC",
  "#ECF4EE",
  "#FDF1DD",
];

const InvitationPending = styled(MessageIcon)`
  width: min(66%, 50px);
  height: auto;
`;
