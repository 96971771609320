import {
  useFloating,
  offset,
  flip,
  size,
  useMergeRefs,
} from "@floating-ui/react";
import { useEffect } from "react";

// Re-exporting so imports can be combined
export { useMergeRefs };

/**
 * Sets default floating-ui settings for Dropdown components
 *
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {function} props.setIsOpen
 * @param {number=} props.itemCount - Number of items in the dropdown
 * @param {string} props.placement
 */
export const useFloatingDropdown = ({
  isOpen,
  setIsOpen,
  itemCount,
  placement = "bottom",
}) => {
  const props = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 20,
      }),
    ],
  });

  // Update position when the content length changes
  useEffect(() => {
    props.update();
  }, [itemCount, props]);

  return props;
};
