import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetPartQuery, useDeletePartMutation } from "modules/parts";

import { HeaderContextMenu, ViewModal } from "shared/components/Modals";
import { Button } from "shared/components/Button";
import { ContextMenuEntry } from "shared/components/ContextMenu";
import { ViewHeader } from "shared/components/ViewHeader";
import { VendorsList } from "shared/components/VendorsList";
import { LinksList } from "shared/components/LinksList";

import { formatCurrency } from "modules/reporting/lib/formatters";
import { useVisibleFields } from "modules/adminFieldsConfiguration/lib/hooks";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { usePermission } from "app/providers/PermissionsProvider";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";

import { Deletion } from "features/Deletion";
import { Stock } from "./Stock";
import { FileManager } from "modules/fileManager";
import { Details } from "widgets/Details";

import { CostUpdate } from "./CostUpdate";
import { useGetLocaleSettings } from "shared/lib/hooks";
import { PartTransactions } from "../PartTransactions/PartTransactions";
import { ModalLink } from "shared/components/ModalLink";

const fields = [
  "min_qty",
  "storeroom",
  "area",
  "number",
  "part_type",
  "assets",
  "vendors",
  "lead_time",
  "urls",
];

const stockFields = ["min_qty", "storeroom", "area"];

export const PartView = ({ isShow, onClose, onEdit, onClone, partId }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const { language, currency } = useGetLocaleSettings();

  const [deletePart] = useDeletePartMutation();

  const {
    partUpdatePermit,
    partDeletePermit,
    partClonePermit,
    partRestockPermit,
    partUpdateCostPermit,
  } = usePermission();

  const { currentData: data, error } = useGetPartQuery(partId ?? skipToken);

  const { isFieldVisible, areVisibleFieldsLoading } = useVisibleFields("part");

  if (!partId) {
    return null;
  }

  if (areVisibleFieldsLoading) return null;

  if (!data || error) {
    return <div>Error part</div>;
  }

  const {
    name,
    id,
    description,
    images,
    documents,
    is_non_stock,
    unit_cost,
    min_qty,
    desired_qty,
    on_hand_qty,
    updated_at,
    qr_code,
    measurement_unit,
    available,
    reserved,
  } = data;

  const isShowCostUpdate = is_non_stock
    ? partUpdateCostPermit
    : partRestockPermit;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const rows = fields.reduce((visibleRows, field) => {
    if (!isFieldVisible(field)) {
      return visibleRows;
    }

    const value = data[field];

    if (stockFields.includes(field) && is_non_stock) {
      return visibleRows;
    }

    if (field === "urls" && Array.isArray(value)) {
      visibleRows.push({
        label: t("parts.details.urls"),
        value: <LinksList links={value} />,
        multiline: true,
      });

      return visibleRows;
    }

    if (field === "vendors") {
      visibleRows.push({
        label: t("parts.details.vendors"),
        value: <VendorsList vendors={value} />,
        multiline: true,
      });

      return visibleRows;
    }

    if (field === "assets") {
      const links = value.map(({ name, id }) => (
        <ModalLink
          style={{ display: "block" }}
          modalParams={{ type: "asset", id }}
        >
          {name}
        </ModalLink>
      ));
      visibleRows.push({
        label: t("parts.details.assets"),
        value: <div>{links}</div>,
        multiline: true,
      });
    }

    if (typeof value === "string" || typeof value === "number") {
      visibleRows.push({ label: t(`parts.details.${field}`), value });
    }

    if (typeof value === "object" && !Array.isArray(value)) {
      visibleRows.push({
        label: t(`parts.details.${field}`),
        value: value?.name,
      });
    }

    return visibleRows;
  }, []);

  const deleteErrorHandler = (message) => {
    const content =
      message.base?.[0] === RESPONSE_MESSAGE.connected_record
        ? t("parts.warningDeletionContent")
        : JSON.stringify(message);

    addSnackBar({
      title: t("parts.warningDeletionTitle"),
      content,
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
  };

  return (
    <ViewModal
      isOpen={isShow}
      onClose={onClose}
      label={t("parts.name", { name })}
      subHeader={
        <>
          {isShowCostUpdate && (
            <CostUpdate
              nonStock={is_non_stock}
              partId={partId}
              onHand={on_hand_qty ?? 0}
              unitCost={unit_cost}
              unit={measurement_unit?.short_name}
            />
          )}
          <ButtonRight
            variant="secondary"
            onClick={() => onEdit(partId)}
            disabled={!partUpdatePermit}
          >
            {t("edit")}
          </ButtonRight>
          <HeaderContextMenu disabled={!partClonePermit && !partDeletePermit}>
            {partClonePermit && (
              <ContextMenuEntry onClick={() => onClone(partId)}>
                {t("clone")}
              </ContextMenuEntry>
            )}
            {partDeletePermit && (
              <Deletion
                id={partId}
                module={data?.name}
                onSuccess={onClose}
                onError={deleteErrorHandler}
                confirmation={{
                  title: t("parts.deletionConfirmation.title"),
                  description: t("parts.deletionConfirmation.description"),
                  confirmButtonLabel: t("parts.deletionConfirmation.confirm"),
                }}
                onDelete={deletePart}
              />
            )}
          </HeaderContextMenu>
        </>
      }
    >
      <ViewHeader id={id} name={name} description={description} />
      <FileManager value={files} />
      <Stock
        isNonStock={is_non_stock}
        unitCostCurrency={formatCurrency(unit_cost ?? 0, language, currency)}
        min={min_qty}
        desired={desired_qty}
        onHand={on_hand_qty}
        unit={measurement_unit?.short_name}
        available={available}
        reserved={reserved}
      />
      <Details date={updated_at} rows={rows} code={qr_code} />

      <PartTransactions partId={partId} />
    </ViewModal>
  );
};

const ButtonRight = styled(Button)`
  margin-left: auto;
`;
