import { components } from "react-select";
import { ControlContainer, SearchIconStyled } from "./SelectDropdownStyled";

export const Control = ({ children, menuIsOpen, ...props }) => {
  return (
    <ControlContainer menuOpened={menuIsOpen}>
      <components.Control {...props}>
        {props.selectProps?.isSearchable && <SearchIconStyled />}
        {children}
      </components.Control>
    </ControlContainer>
  );
};
