import { useUpdateProcedureItem } from "modules/procedures/hooks/useUpdateProcedureItem";
import { ChangeEvent, useState } from "react";
import { Label } from "react-aria-components";
import { VerticalItem } from "./styled";
import { ItemProps } from "./types";

export const MultipleChoice = ({ item, onChange }: ItemProps) => {
  const [selected, setSelected] = useState<string | void>(item.value?.selected);

  useUpdateProcedureItem(item, { selected }, selected);

  const onSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value);
    onChange(e.target.value);
  };

  return (
    <VerticalItem>
      <Label>{item.name}</Label>
      {item.value.choices?.map((choice) => (
        <fieldset>
          <Label>{choice}</Label>
          <input
            type="radio"
            id={choice}
            value={choice}
            onChange={onSelectionChange}
            checked={choice === selected}
          />
        </fieldset>
      ))}
    </VerticalItem>
  );
};
