import React, { memo } from "react";
import { Cell, CellTextContainer, Row as RowStyled, Td } from "./styled";
import { Highlighter } from "./Highlighter";
import { Checkbox } from "shared/components/Checkbox";
import { RowDecorator } from "shared/components/Table/components/RowDecorator";

export const Row = memo(
  ({
    row,
    columns,
    highlight,
    selectionModel,
    onSelectionModelChange,
    getRowBorder,
    getRowIcon,
  }) => {
    const rowBorder = getRowBorder ? getRowBorder(row) : undefined;
    const checked = selectionModel && selectionModel.includes(row.id);

    const checkboxClickHandler = () => {
      onSelectionModelChange(
        checked
          ? selectionModel.filter((id) => id !== row.id)
          : [...selectionModel, row.id]
      );
    };

    // This should really be a separate column, but that's out of scope for this refactor
    let decorator;
    if (!!selectionModel || !!getRowIcon) {
      decorator = (
        <RowDecorator
          icon={getRowIcon && getRowIcon(row)}
          checkbox={
            !!selectionModel && (
              <Checkbox checked={checked} onClick={checkboxClickHandler} />
            )
          }
          checked={checked}
        />
      );
    }

    return (
      <RowStyled data-testclass="row">
        {columns.map(
          (
            {
              field,
              basicField,
              type,
              width,
              renderCell,
              formatValue,
              notPrimary,
              edit,
              textAndBadge,
            },
            index
          ) => {
            const cellKey = `${row.id}-${field}}`;
            const rawValue = basicField ? row[basicField][field] : row[field];

            const highlightValue =
              typeof highlight === "object" ? highlight[field] : highlight;
            const value = formatValue ? formatValue(rawValue) : rawValue;

            const formattedValue = (
              <CellTextContainer>
                {highlightValue ? (
                  <Highlighter value={value} highlight={highlightValue} />
                ) : (
                  value
                )}
              </CellTextContainer>
            );

            const props = {
              notPrimary,
              textAndBadge,
              key: cellKey,
              hasIcon: !!decorator,
              width,
              type,
              style: {
                borderLeft:
                  index === 0
                    ? `4px solid ${rowBorder ?? "transparent"}`
                    : undefined,
              },
            };

            if (renderCell) {
              return (
                <Cell {...props} edit={edit} data-testclass={`${field}-column`}>
                  {index === 0 && decorator}
                  {renderCell(row, formattedValue)}
                </Cell>
              );
            }

            return (
              <Td
                {...props}
                notPrimary={notPrimary}
                data-testclass={`${field}-column`}
              >
                {index === 0 && decorator}
                {formattedValue}
              </Td>
            );
          }
        )}
      </RowStyled>
    );
  }
);
