import { initialValuesMap } from "../config/constants";

export const getInitialValues = ({
  type = "create",
  data,
  module,
  entities: AllEntities,
}) => {
  if (type === "create" || !data) {
    return initialValuesMap[module];
  }

  const { name = "", entities = [] } = data;

  const isAllEntities = AllEntities?.length === entities?.length;
  const isAllEntitiesIds = AllEntities?.map(({ id }) => id);

  const entitiesValues = isAllEntities
    ? [
        {
          value: "all",
          label: "All",
          ids: isAllEntitiesIds,
        },
      ]
    : entities.map(({ name, id }) => ({
        label: name,
        value: name,
        ids: [id],
      }));

  return {
    name,
    entities: entitiesValues,
  };
};

export const getEntitiesOptions = (entities, module) => {
  const allEntitiesIds = [];

  const options = entities?.map(({ name, id }) => {
    allEntitiesIds.push(id);

    return {
      value: name,
      label: name,
      ids: [id],
    };
  });

  const chosenModule = module === "storerooms" ? "storerooms" : "others";

  const returnedOptions = {
    storerooms: !!entities?.length ? [...options] : [],
    others: !!entities?.length
      ? [{ value: "all", label: "All", ids: allEntitiesIds }, ...options]
      : [],
  };

  return returnedOptions[chosenModule];
};

export const getValues = (body) => {
  const { name, entities } = body;

  const entitiesIds = entities.map(({ ids }) => ids).flat();

  return { name, entities_ids: entitiesIds };
};
