import styled from "styled-components";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";
import { DragKnob } from "../DragKnob";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  padding: ${({ value }) => (value.length > 0 ? "0 45px" : "0")};
`;

export const ChecklistBox = styled.div`
  display: flex;
  gap: 8px;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  padding-top: 8px;
  padding-bottom: 8px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const TrashCanIconBox = styled.div`
  padding: 10px;
  cursor: pointer;
`;

export const TrashCanIconStyled = styled(TrashCanIcon)`
  color: var(--color_primary_text);
`;

export const StyledDragKnob = styled(DragKnob)`
  border: none;
  padding-top: 10px;
`;

export const IndexBox = styled.div`
  width: 16px;
  padding-top: 10px;
  font-size: 14px;
  color: var(--color_text_color);
`;

export const Error = styled.div`
  position: absolute;
  color: var(--color_alert);
  font-size: 14px;
  top: calc(100% + 6px);
`;
