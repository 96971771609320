import { useTranslation } from "react-i18next";
import { RadioButton, RadioButtonGroup } from "shared/components/RadioButton";

const Types = ["reactive", "preventive"];

export const WorkOrderType = ({
  value = "reactive",
  onChange,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <RadioButtonGroup value={value}>
      {Types.map((type) => (
        <RadioButton
          key={type}
          value={type}
          onSelect={onChange}
          disabled={isDisabled}
        >
          <span>{t(`work_orders.type.${type}`)}</span>
        </RadioButton>
      ))}
    </RadioButtonGroup>
  );
};
