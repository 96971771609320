import { ModalParams } from "widgets/ModalController/types";
import { useSetModal } from "widgets/ModalController";
import { usePermission } from "app/providers/PermissionsProvider";
import { AssetCreate } from "modules/assets/components/AssetCreate";
import { AssetView } from "modules/assets/components/AssetView";
import { AssetUpdate } from "modules/assets/components/AssetUpdate";
import { AssetClone } from "modules/assets/components/AssetClone";

interface AssetModalProps {
  params: ModalParams;
}

export const AssetModal = ({ params }: AssetModalProps) => {
  const { type, id, state = "view" } = params;
  const {
    assetCreatePermit,
    assetViewPermit,
    assetUpdatePermit,
    assetClonePermit,
  } = usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new" && assetCreatePermit) {
    return (
      <AssetCreate
        isShow={true}
        onClose={onClose}
        onOpen={(id: string) => setModal({ type, id })}
      />
    );
  } else if (state === "view" && assetViewPermit) {
    return (
      <AssetView
        isShow={true}
        assetId={id}
        onClose={onClose}
        onEdit={() => setModal({ type, id, state: "edit" })}
        onClone={() => setModal({ type, id, state: "clone" })}
      />
    );
  } else if (state === "edit" && assetUpdatePermit) {
    return (
      <AssetUpdate
        isShow={true}
        assetId={id}
        onClose={onView}
        onSuccess={onView}
      />
    );
  } else if (state === "clone" && assetClonePermit) {
    return <AssetClone isShow={true} assetId={id} onClose={onView} />;
  }

  return null;
};
