import React, { useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import DownloadIcon from "shared/assets/icon/download.svg?react";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import {
  Container,
  Text,
  BoldText,
  Icon,
  ClickableText,
} from "./FilesPicker.styled";
import { getValidFileList } from "../../lib/helper";
import { acceptFormat } from "../../lib/constants";

export const FilesPicker = ({ files, setFiles, allowedNumberOfFiles }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [isActive, setIsActive] = useState(false);
  const [isFileHover, setIsFileHover] = useState(false);

  const inputRef = useRef();

  const showError = (message) => {
    return addSnackBar({
      title: t("files.image.error.upload"),
      content: JSON.stringify(message),
      type: "error",
      secondaryButtonLabel: t("close"),
    });
  };

  const handleClick = () => {
    return inputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsFileHover(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsFileHover(false);

    const { validFileList, error } = getValidFileList(
      files || [],
      event.dataTransfer.files,
      t,
      allowedNumberOfFiles
    );
    if (error) {
      showError(error);
    }
    setFiles([...(files || []), ...validFileList]);
  };

  const handleSelectFiles = (event) => {
    const { validFileList, error } = getValidFileList(
      files || [],
      event.target.files,
      t,
      allowedNumberOfFiles
    );
    if (error) {
      showError(error);
    }
    setFiles([...(files || []), ...validFileList]);
  };

  return (
    <Container
      onDragOver={handleDragOver}
      onDragLeave={() => setIsFileHover(false)}
      onDrop={handleDrop}
      compressed={Boolean(files && files.length)}
      isActive={isActive}
      isFileHover={isFileHover}
    >
      {isFileHover ? (
        <Text>
          <Trans i18nKey="files.filePicker.hover" components={[<BoldText />]} />
        </Text>
      ) : (
        <React.Fragment>
          <Icon>
            <DownloadIcon />
          </Icon>
          <Text>
            <Trans
              i18nKey="files.filePicker.placeholder"
              components={[<BoldText />]}
            />
            <ClickableText
              onClick={handleClick}
              onMouseDown={() => setIsActive(true)}
              onMouseUp={() => setIsActive(false)}
            >
              {t("files.filePicker.upload")}
            </ClickableText>
            <input
              ref={inputRef}
              type="file"
              multiple
              onChange={handleSelectFiles}
              hidden
              accept={acceptFormat.join(", ")}
            />
          </Text>
        </React.Fragment>
      )}
    </Container>
  );
};
