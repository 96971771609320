import { useGetTableDataQuery } from "modules/reporting/state/reportingApi";
import { Response } from "types/Response";
import { useFiltersParam, usePageParam, useSortParam } from "shared/lib/hooks";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";

/** Fetches table data for a given `reportId` */
export const useGetTableData = <T extends object[]>(
  reportId: string,
  reportPeriod?: ReportPeriod,
  entity = "work_orders"
) => {
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const [filters] = useFiltersParam([]);

  const state = useGetTableDataQuery({
    reportId,
    start_date: reportPeriod?.startDate,
    end_date: reportPeriod?.endDate,
    per_page: 25,
    page,
    sort,
    ...stringifyFilters(filters, entity),
  });

  return {
    ...state,
    data: state.data as Response<T> | undefined,
  };
};
