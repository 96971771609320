import React from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import { usePermission } from "app/providers/PermissionsProvider";

const LocationsAdminConfigurationFields = [
  "description",
  "address",
  "qr_code",
  "parent",
  "path",
];

export const LocationsAdminSettings = () => {
  const { adminSettingsLocationsPermit } = usePermission();
  const { data } = useGetAdminConfigurationQuery({ type: "location" });

  if (!data || !data?.data) return null;

  const {
    data: { payload },
  } = data;

  const currentConfiguration = getConfigurationFields(
    LocationsAdminConfigurationFields,
    payload
  );

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="location"
      hasPermission={adminSettingsLocationsPermit}
    />
  );
};
