import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import markerSDK from "@marker.io/browser";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import App from "./app/App";

// For validating the deployed version
window.client_version = `${import.meta.env.VITE_RELEASE}-${
  import.meta.env.VITE_DEPLOY_ENV
}`;

if (import.meta.env.PROD) {
  // Initialize the Marker.io widget
  markerSDK.loadWidget({
    project: "6616a4dea0ba71894b42b7fa",
  });

  const maskSentryReplay = import.meta.env.VITE_DEPLOY_ENV === "production";

  // Only initialize Sentry when running a deployed version of the app
  Sentry.init({
    release: import.meta.env.VITE_RELEASE,
    environment: import.meta.env.VITE_DEPLOY_ENV,
    dsn: "https://4d977159ee83beae64b86dd95baae214@o4506373586288640.ingest.sentry.io/4506587412496384",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [import.meta.env.VITE_SERVER_API_URL],
      }),
      new Sentry.Replay({
        maskAllText: maskSentryReplay,
        maskAllInputs: maskSentryReplay,
        blockAllMedia: maskSentryReplay,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LD_ID ?? "",
    options: {
      application: {
        id: "client",
        version: import.meta.env.VITE_RELEASE,
      },
      bootstrap: "localStorage",
    },
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>
  );
})();
