import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { ConfirmationDialog } from "shared/components/Dialog";
import RemoveIcon from "shared/assets/icon/remove.svg?react";
import { IconButton } from "shared/components/IconButton";

export const CommentDelete = ({ id, onDelete, type }) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
  };

  const proceedHandler = () => {
    if (id) {
      onDelete({ id, type })
        .unwrap()
        .then(() => {
          setIsConfirmationVisible(false);
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("action.deleteFailed", { module: "Comment" }),
            content: JSON.stringify(message),
            type: "error",
          });
        })
        .finally(() => setIsConfirmationVisible(false));
    }
  };

  return (
    <>
      <StyledIconButton onClick={clickHandler} className="actionIcon">
        <RemoveIcon />
      </StyledIconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t("work_orders.comments.deletionConfirmation.title")}
          confirmButtonLabel={t(
            "work_orders.comments.deletionConfirmation.confirm"
          )}
          description={t(
            "work_orders.comments.deletionConfirmation.description"
          )}
          cancelButtonLabel={t("cancel")}
          onProceed={proceedHandler}
          onCancel={cancelHandler}
          isDestructive
        />
      )}
    </>
  );
};

CommentDelete.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onDelete: PropTypes.func,
};

export const StyledIconButton = styled(IconButton)`
  display: none;
  top: 16px;
  right: 16px;
`;
