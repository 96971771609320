import styled, { css } from "styled-components";

import { getUser } from "shared/components/Auth";
import { useGetUserQuery } from "modules/users";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { UserAvatar } from "shared/components/UserAvatar";
import { SidebarItemLabel } from "widgets/SideBar/components/SidebarItemLabel";

export const Person = ({ hidden }) => {
  const { id: userId = "", name = "", surname = "" } = getUser() ?? {};
  const { currentData: userInfo } = useGetUserQuery(userId ?? skipToken);
  const userName = `${userInfo?.name ?? name} ${userInfo?.surname ?? surname}`;

  return (
    <Wrapper isHidden={hidden}>
      <UserAvatar user={userInfo} size="sm" />
      <Text>{userName}</Text>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  margin-right: 10px !important;
  transition: margin 0.5s;
  & > div {
    margin-right: 8px;
  }
  ${({ isHidden }) =>
    isHidden &&
    css`
      transition: 0.5s;
    `};
`;

const Text = styled(SidebarItemLabel)`
  font-weight: 500;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
