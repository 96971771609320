import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Container,
  CategoryWrapper,
  CategoryLabel,
  Wrapper,
} from "./MenuList.styled";

export const MenuList = forwardRef(
  ({ isOpen, category = [], children, className = "", style }, ref) => {
    const categoryList = new Map([["", []]]);
    category.forEach((cat) => categoryList.set(cat, []));
    React.Children.toArray(children).forEach((child) => {
      if (child && categoryList.get(child.props.category || "")) {
        categoryList.set(child.props.category || "", [
          ...categoryList.get(child.props.category || ""),
          child,
        ]);
      }
    });
    const renderChildren = [];
    categoryList.forEach((e) => renderChildren.push(e));
    const nonCategoryChildren = renderChildren.shift();
    return (
      isOpen && (
        <Container className={className} ref={ref} style={style}>
          <Wrapper>
            {renderChildren.map(
              (childByCategory) =>
                !!childByCategory.length && (
                  <CategoryWrapper>
                    <CategoryLabel>
                      {childByCategory[0]?.props.category}
                    </CategoryLabel>
                    {childByCategory}
                  </CategoryWrapper>
                )
            )}
            {nonCategoryChildren}
          </Wrapper>
        </Container>
      )
    );
  }
);

MenuList.propTypes = {
  isOpen: PropTypes.bool,
  category: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};
