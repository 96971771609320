import styled from "styled-components";

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
  padding-right: 16px;
`;
