import styled from "styled-components";

import { Divider } from "shared/components/Divider";
import { Button } from "shared/components/Button";
import { WOCompleteFanfare } from "shared/components/WOCompleteFanfare";

export const Overdue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  height: 36px;
  background: var(--color_corp_red);
  color: var(--color_text_light);
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
`;

export const DividerStyled = styled(Divider)`
  margin-top: 32px;
`;

export const AdditionalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 0 0;
`;

export const Timer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 4px;
  width: 100%;
  height: 42px;
  background: rgba(255, 229, 227, 1);
  color: rgba(179, 91, 94, 1);
  line-height: 24px;
  font-size: 20px;
`;

export const StopTimerButton = styled(Button)`
  line-height: 16px;
  height: 24px;
  padding: 4px;
  color: rgba(5, 31, 64, 1);
  position: absolute;
  right: 32px;
  background: var(--color_corp_red);
  border-color: var(--color_corp_red);
  &:hover {
    background: var(--color_corp_red);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_corp_red);
    box-shadow: 0px 0px 0px 2px var(--color_corp_red);
    border: 1px solid var(--color_background);
  }
`;

export const DueDate = styled.span`
  font-weight: 600;
`;

export const Fanfare = styled(WOCompleteFanfare)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Buttons = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  align-items: center;
`;
