import React from "react";
import styled, { css } from "styled-components";
import DoubleRow from "shared/assets/DoubleRow.svg?react";
import { SidebarItemLabel } from "widgets/SideBar/components/SidebarItemLabel";

export const CollapseButton = ({ hidden, setHidden }) => {
  function openNav() {
    setHidden(false);
    document.getElementById("sideBar").classList.remove("hidden");
    document.getElementById("app").style.marginLeft = "266px";
  }

  function closeNav() {
    setHidden(true);
    document.getElementById("sideBar").classList.add("hidden");
    document.getElementById("app").style.marginLeft = "64px";
  }

  const onActionClick = () => (hidden ? openNav() : closeNav());
  return (
    <Container>
      <Line />
      <Wrapper isHidden={hidden} onClick={onActionClick}>
        <DoubleRow />
        <SidebarItemLabel>Collapse sidebar</SidebarItemLabel>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 40px;
  cursor: pointer;
`;

const Line = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  border-top: 1px solid var(--color_border200);
`;

const Wrapper = styled.div`
  height: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  svg {
    transition: 0.5s;
    transform: rotate(180deg);
  }
  span {
    transition: 0.5s;
    margin-left: 10px;
    color: var(--color_text_light);
  }
  ${({ isHidden }) =>
    isHidden &&
    css`
      transition: 0.5s;
      padding-left: calc(50% - 8px);
      svg {
        transform: rotate(360deg);
      }
    `};
`;
