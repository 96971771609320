import { useGetChartDataQuery } from "modules/reporting/state/reportingApi";
import { useFiltersParam } from "shared/lib/hooks";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";

/** Fetches chart data for a given `reportId` */
export const useGetChartData = <T,>(
  reportId: string,
  reportPeriod?: ReportPeriod,
  params?: object,
  entity = "work_orders"
) => {
  const [filters] = useFiltersParam([]);

  const state = useGetChartDataQuery({
    reportId,
    start_date: reportPeriod?.startDate,
    end_date: reportPeriod?.endDate,
    ...(params ?? {}),
    ...stringifyFilters(filters, entity),
  });

  return {
    ...state,
    data: state.data as T | undefined,
  };
};
