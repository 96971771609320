import { prohibitedFileFormats, maxSizeB } from "./constants";

export const getFileWithNewName = (bits, name) => {
  try {
    return new File(bits, name, {
      type: bits[0].type,
    });
  } catch (e) {
    var myBlob = new Blob(bits);
    myBlob.lastModified = new Date();
    myBlob.name = name;
    return myBlob;
  }
};

export const getUnicFileName = (existingFiles, currentFile) => {
  const fileName = currentFile.name;
  let fileCounter = 1;
  let newFileName = currentFile.name;
  const checkName = (name) =>
    existingFiles.some((existingFile) => existingFile.name === name);
  while (checkName(newFileName)) {
    const fileSplit = fileName.split(".");
    const extension = fileSplit.pop();
    newFileName = `${fileSplit.join(".")}(${fileCounter}).${extension}`;
    fileCounter++;
  }
  return newFileName;
};

export const formatFileSize = (size) => {
  const kiloByte = 1024;
  const megaByte = kiloByte * 1024;
  if (size >= megaByte) {
    return (size / megaByte).toFixed(2) + " MB";
  } else if (size >= kiloByte) {
    return (size / kiloByte).toFixed(2) + " kB";
  } else {
    return size + " B";
  }
};

export const getFormatForService = (files = []) => {
  return files.map((file) => ({
    file: file,
    isImage: file.type?.includes("image/"),
    name: file.name,
    size: formatFileSize(file.size),
    url: URL.createObjectURL(file),
    alt: file.name,
    error: null,
  }));
};

export const getValidFileList = (
  selectedFiles = [],
  files,
  translation,
  allowedNumberOfFiles
) => {
  let validFileList = [];
  let filesWithInvalidFormat = [];
  let filesWithInvalidSize = [];
  let error = null;

  Array.from(files).forEach((file) => {
    let currentFile = file;
    const isAvaiableFileFormat = !prohibitedFileFormats.includes(
      "." + file.name?.split(".").pop()
    );
    const isAvaiableFileSize = file.size < maxSizeB;
    if (isAvaiableFileFormat && isAvaiableFileSize) {
      const unicName = getUnicFileName(selectedFiles || [], file);
      if (currentFile.name !== unicName) {
        currentFile = getFileWithNewName([file], unicName);
      }
      validFileList.push(currentFile);
    } else if (!isAvaiableFileFormat) {
      filesWithInvalidFormat.push(currentFile.name);
    } else if (!isAvaiableFileSize) {
      filesWithInvalidSize.push(currentFile.name);
    }
  });
  if (filesWithInvalidFormat.length > 0) {
    error = translation("m44", {
      count: filesWithInvalidFormat.length,
      ns: "errors",
    });
  }
  if (filesWithInvalidSize.length > 0) {
    error = translation("m1", {
      count: filesWithInvalidSize.length,
      ns: "errors",
    });
  }

  if (
    allowedNumberOfFiles <
    selectedFiles.filter(({ isRemove }) => !isRemove).length +
      validFileList.length
  ) {
    validFileList = validFileList.slice(
      0,
      allowedNumberOfFiles - selectedFiles.length
    );
    error = translation("m7", { ns: "errors", number: allowedNumberOfFiles });
  }
  validFileList = getFormatForService(validFileList);
  return { validFileList, error };
};
