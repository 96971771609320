import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useGetTechniciansQuery } from "modules/users";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSearch } from "shared/lib/hooks/useSearch";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useForm } from "shared/lib/hooks/useForm";
import Icon from "../../../assets/icons/user.svg?react";

import { Select } from "shared/components/Select";
import { getAssignToIds, getAssignToOptions } from "shared/lib/helpers/common";

const INITIAL_VALUES = { assign_to: [] };

export const BulkAssignees = ({
  count,
  ids = [],
  onUpdate,
  module = "work_orders",
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const [teams, setSearchTeams] = useSearch(useGetTeamsQuery, true);
  const [technicians, setSearchTechnicians] = useSearch(
    useGetTechniciansQuery,
    true,
    {},
    "full_name"
  );
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const assignToOptions = getAssignToOptions(teams, technicians);
  const assignToOptions = getAssignToOptions(technicians);

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
    form.resetForm();
  };

  const getSearchFunction = (value) => {
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // setSearchTeams(value);
    setSearchTechnicians(value);
  };

  const getAssigneesNames = () =>
    form.values.assign_to.map(({ label }) => label).join(", ");

  const form = useForm({
    initialValues: INITIAL_VALUES,
    enableReinitialize: true,
    onSubmit: async ({ assign_to }) => {
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      // const { teams_ids, technicians_ids } = getAssignToIds(assign_to);
      const { technicians_ids } = getAssignToIds(assign_to);
      // onUpdate({ ids, teams_ids, technicians_ids })
      onUpdate({ ids, technicians_ids })
        .unwrap()
        .then(() => {
          setIsConfirmationVisible(false);
          addSnackBar({
            content: t(`${module}.bulkActions.updateAssignees.success`, {
              count,
              assignees: getAssigneesNames(),
            }),
            type: "success",
          });
        })
        .catch(() => {
          addSnackBar({
            title: t("work_orders.bulkActions.updateAssignees.fail"),
            type: "error",
          });
        });
    },
  });

  return (
    <>
      <IconButton onClick={clickHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-assigned" />
        <ReactTooltip
          id="icon-tooltip-assigned"
          place="bottom"
          content={t("work_orders.bulkActions.hint.assignees")}
          opacity="1"
        />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t(
            "work_orders.bulkActions.updateAssignees.confirmation.title"
          )}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          description={
            <Description>
              <SelectedLabel>
                {t(
                  `${module}.bulkActions.updateAssignees.confirmation.selected_${
                    count > 1 ? "other" : "one"
                  }`,
                  { count }
                )}
              </SelectedLabel>
              <FieldBox>
                <NewLabel>
                  {t(
                    "work_orders.bulkActions.updateAssignees.confirmation.new"
                  )}
                </NewLabel>
                <SelectContainer>
                  <Select
                    options={assignToOptions}
                    name="assign_to"
                    value={form.values.assign_to}
                    setValue={form.handleSetValue}
                    isMulti
                    onBlur={form.handleBlur}
                    noOptionsMessage={t("noOptions")}
                    errorMessage={
                      form.touched.assign_to && form.errors.assign_to
                    }
                    onSearch={getSearchFunction}
                    onTouch={form.handleSetTouched}
                  />
                </SelectContainer>
              </FieldBox>
            </Description>
          }
          onProceed={form.handleSubmit}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

BulkAssignees.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onUpdate: PropTypes.func.isRequired,
  count: PropTypes.number,
  module: PropTypes.string,
};

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 16px;
`;

const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

const FieldBox = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  width: 100%;
  max-width: 480px;
`;

const SelectContainer = styled.div`
  flex-grow: 1;
`;

const NewLabel = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
`;

const StyledIcon = styled(Icon)`
  outline: none;
`;
