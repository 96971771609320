import { useSearchParamState } from "shared/lib/hooks/useSearchParamState";

/**
 * Gets/sets the "search" URL query parameter
 * @param defaultVal - The value to return if the key is not found in the URL
 */
export function useSearchParam(defaultVal?: string) {
  return useSearchParamState("search", defaultVal, {
    serialize: (val) => (val ? val.toString() : ""),
    deserialize: (val) => val || defaultVal,
  });
}
