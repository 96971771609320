import { useCallback } from "react";
import {
  useGetUserFieldConfigurationQuery,
  useUpdateUserFieldConfigurationMutation,
} from "modules/fieldConfiguration/state/fieldConfigurationApi";

export const useTableSettings = (type) => {
  const result = useGetUserFieldConfigurationQuery(
    { type },
    { refetchOnMountOrArgChange: true }
  );

  const [updateSettings] = useUpdateUserFieldConfigurationMutation();

  const applySettings = useCallback(
    ({ columnsSettings, perPage }) => {
      const payload = {};

      for (const [index, setting] of columnsSettings.entries()) {
        payload[setting.field] = {
          visible: !setting.hidden,
          order: index + 1,
        };
      }

      updateSettings({
        type,
        body: {
          per_page: perPage,
          payload,
        },
      });
    },
    [type, updateSettings]
  );

  return { result, applySettings };
};
