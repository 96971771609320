import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StatusMap = {
  weak: {
    color: "priority_red",
    value: "Weak",
  },
  medium: { color: "priority_orange", value: "Medium" },
  strong: { color: "priority_lime", value: "Strong" },
};

export const PasswordStrength = ({ status }) => {
  const { t } = useTranslation();
  return (
    <PasswordStrengthBox>
      <span>{t("auth.signUp.passwordStrength")}</span>
      <StatusText color={StatusMap[status].color}>
        {StatusMap[status].value}
      </StatusText>
    </PasswordStrengthBox>
  );
};

const StatusText = styled.span`
  color: ${({ color }) => `var(--color_${color})`};
`;

const PasswordStrengthBox = styled.div`
  display: flex;
  margin: 8px 0 16px;
  font-size: 14px;
  & > span {
    margin-right: 4px;
    font-size: 14px;
  }
`;
