import { StyleSheet, Font } from "@react-pdf/renderer";
import styled from "styled-components";
import BoldFont from "../../assets/fonts/Poppins-Bold.ttf";
import RegularFont from "../../assets/fonts/Poppins-Regular.ttf";

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 32px;
`;

export const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

export const Image = styled.img`
  width: 100%;
  max-height: 100%;
`;

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
      fontWeight: "bold",
    },
  ],
});

export const pageStyle = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 50,
    paddingHorizontal: 35,
    fontFamily: "Poppins",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    color: "#000000",
  },
  border: {
    borderBottom: "1.5px solid #000000",
    marginTop: "16px",
    marginBottom: "16px",
  },
  h3: {
    fontWeight: "700",
    fontSize: "16px",
  },
  h4: {
    fontSize: "10px",
    fontWeight: "700",
  },
  image: {
    width: 67,
    height: 67,
  },
  icon: {
    width: 10,
    height: 10,
  },
  logo: {
    width: 20,
    height: 20,
  },
  description: {
    gap: "16px",
  },
  boldText: {
    fontWeight: "700",
  },
  tinyText: {
    fontSize: "6px",
  },
  smallText: {
    fontSize: "8px",
    color: "#000000",
  },
  mediumText: {
    fontSize: "10px",
    color: "#000000",
  },
  greyText: {
    color: "#737278",
  },
  detail: {
    marginBottom: "5px",
    marginTop: "5px",
    fontSize: "8px",
  },
  detailColumns: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "0px 10px",
  },
  detailColumn: {
    flexBasis: "30%",
  },
  commentsColumns: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "0px 10px",
  },
  commentsColumn: {
    flexBasis: "47%",
  },
  comment: {
    borderBottom: "1px solid #BFBFC3",
    marginBottom: "4px",
    paddingBottom: "4px",
    width: "240px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontFalimy: "inherit",
    color: "#000000",
  },
  imageRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px 5px",
  },
  documentIcon: {
    alignItems: "center",
    width: "67px",
    height: "67px",
    border: "1px solid #737278",
    borderRadius: "12px",
    paddingTop: "20px",
    textAlign: "center",
  },
});
