import styled from "styled-components";

export const Name = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color_primary_text);
`;

export const Content = styled.span`
  word-break: break-word;
  font-size: 14px;
  line-height: 20px;
  color: var(--color_grey900);
`;

export const Box = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  word-break: break-all;
`;

export const Created = styled.span`
  color: var(--color_background);
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: auto;
`;

export const Container = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;

  background: var(--color_text_light);
  border: 2px solid var(--color_text_light);
  position: relative;

  &:hover {
    background: var(--color_primary_hover);

    & .actionIcon {
      display: flex;
    }
  }
`;

export const Edit = styled.div`
  display: flex;
  gap: 16px;
`;
