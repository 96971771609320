import React, { Fragment } from "react";
import { TextLink } from "../../../TextLink";

export const Urls = ({ values }) => (
  <div>
    {values.map(({ label, url }, index) => (
      <Fragment key={`${label}-${url}`}>
        {url.startsWith("/") ? (
          <TextLink to={url}>{label}</TextLink>
        ) : (
          <TextLink as="a" href={url} target="_blank">
            {label}
          </TextLink>
        )}
        {index === values.length - 1 ? null : ", "}
      </Fragment>
    ))}
  </div>
);
