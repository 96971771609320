import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Divider } from "shared/components/Divider";
import { getUser } from "shared/components/Auth";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import {
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingMutation,
} from "modules/users";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";
import { Wrapper } from "../styled";
import { Setting } from "./Setting";
import { usePermission } from "app/providers/PermissionsProvider";

const defaultSettings = {
  is_notify_creator_new_comment_on_wo: true,
  is_notify_creator_wo_overdue: true,
  is_notify_creator_wo_status_changed: true,
  is_notify_creator_new_preventive_wo: true,
  is_notify_assignee_new_wo: true,
  is_notify_assignee_new_comment_on_wo: true,
  is_notify_assignee_wo_overdue: true,
  is_notify_assignee_wo_status_changed: true,
  is_notify_wo_for_review: true,
  is_notify_new_wo_request: true,
  is_notify_asset_status_offline: true,
  is_notify_asset_warranty_exp: true,
  is_notify_part_hits_min: true,
  is_notify_daily_report: true,
  is_notify_weekly_report: true,
};

export const NotificationSettingsView = () => {
  const { t } = useTranslation();
  const { id: userId = "" } = getUser() ?? {};
  const {
    notificationsEmailWorkOrderAssetPermission,
    notificationsEmailWorkOrderAssignedPermission,
    notificationsEmailWorkOrderCreatedPermission,
    notificationsEmailWorkOrderPartPermission,
    notificationsEmailWorkOrderReportPermission,
    notificationsEmailWorkOrderRequestPermission,
    notificationsEmailWorkOrderToReviewPermission,
  } = usePermission();

  const { addSnackBar } = useSnackBar();

  const { currentData } = useGetNotificationSettingsQuery(userId);
  const settingInfo = currentData?.data || defaultSettings;

  const [updateNotificationSetting] = useUpdateNotificationSettingMutation();
  const { data: statusData } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });

  const handleNotificationUpdate = async (setting, value) => {
    try {
      await updateNotificationSetting([{ [setting]: value }]);
    } catch ({ message }) {
      addSnackBar({
        title: t("action.updateFailed", { module: "Notifications" }),
        content: JSON.stringify(message),
        type: "error",
        secondaryButtonLabel: t("close"),
      });
    }
  };

  return (
    <Wrapper>
      <Title>{t("work_orders.title")}</Title>
      {notificationsEmailWorkOrderCreatedPermission && (
        <>
          <SubTitle>{t("notificationSettings.created_by_me")}</SubTitle>
          <SettingsGroup>
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_creator_new_comment_on_wo"
              settingValue={settingInfo.is_notify_creator_new_comment_on_wo}
              description={t(
                "notificationSettings.fields.is_notify_creator_new_comment_on_wo"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_creator_wo_overdue"
              settingValue={settingInfo.is_notify_creator_wo_overdue}
              description={t(
                "notificationSettings.fields.is_notify_creator_wo_overdue"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_creator_wo_status_changed"
              settingValue={settingInfo.is_notify_creator_wo_status_changed}
              description={t(
                "notificationSettings.fields.is_notify_creator_wo_status_changed"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_creator_new_preventive_wo"
              settingValue={settingInfo.is_notify_creator_new_preventive_wo}
              description={t(
                "notificationSettings.fields.is_notify_creator_new_preventive_wo"
              )}
            />
          </SettingsGroup>
        </>
      )}
      {notificationsEmailWorkOrderAssignedPermission && (
        <>
          <SubTitle>{t("notificationSettings.assigned_to_me")}</SubTitle>
          <SettingsGroup>
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_assignee_new_wo"
              settingValue={settingInfo.is_notify_assignee_new_wo}
              description={t(
                "notificationSettings.fields.is_notify_assignee_new_wo"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_assignee_new_comment_on_wo"
              settingValue={settingInfo.is_notify_assignee_new_comment_on_wo}
              description={t(
                "notificationSettings.fields.is_notify_assignee_new_comment_on_wo"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_assignee_wo_overdue"
              settingValue={settingInfo.is_notify_assignee_wo_overdue}
              description={t(
                "notificationSettings.fields.is_notify_assignee_wo_overdue"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_assignee_wo_status_changed"
              settingValue={settingInfo.is_notify_assignee_wo_status_changed}
              description={t(
                "notificationSettings.fields.is_notify_assignee_wo_status_changed"
              )}
            />
          </SettingsGroup>
        </>
      )}
      {statusData &&
        statusData.data.payload.in_review.required &&
        notificationsEmailWorkOrderToReviewPermission && (
          <>
            <SubTitle>{t("notificationSettings.sent_for_review")}</SubTitle>
            <SettingsGroup>
              <Setting
                handleNotificationUpdate={handleNotificationUpdate}
                settingKey="is_notify_wo_for_review"
                settingValue={settingInfo.is_notify_wo_for_review}
                description={t(
                  "notificationSettings.fields.is_notify_wo_for_review"
                )}
              />
            </SettingsGroup>
          </>
        )}
      <DividerStyled />
      {notificationsEmailWorkOrderRequestPermission && (
        <>
          <Title>{t("work_order_requests.title")}</Title>
          <SettingsGroup>
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_new_wo_request"
              settingValue={settingInfo.is_notify_new_wo_request}
              description={t(
                "notificationSettings.fields.is_notify_new_wo_request"
              )}
            />
          </SettingsGroup>
          <DividerStyled />
        </>
      )}
      {notificationsEmailWorkOrderAssetPermission && (
        <>
          <Title>{t("assets.title")}</Title>
          <SettingsGroup>
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_asset_status_offline"
              settingValue={settingInfo.is_notify_asset_status_offline}
              description={t(
                "notificationSettings.fields.is_notify_asset_status_offline"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_asset_warranty_exp"
              settingValue={settingInfo.is_notify_asset_warranty_exp}
              description={t(
                "notificationSettings.fields.is_notify_asset_warranty_exp"
              )}
            />
          </SettingsGroup>
          <DividerStyled />
        </>
      )}

      {notificationsEmailWorkOrderPartPermission && (
        <>
          <Title>{t("parts.title")}</Title>
          <SettingsGroup>
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_part_hits_min"
              settingValue={settingInfo.is_notify_part_hits_min}
              description={t(
                "notificationSettings.fields.is_notify_part_hits_min"
              )}
            />
          </SettingsGroup>
          <DividerStyled />
        </>
      )}
      {notificationsEmailWorkOrderReportPermission && (
        <>
          <Title>{t("notificationSettings.reports")}</Title>
          <SettingsGroup>
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_daily_report"
              settingValue={settingInfo.is_notify_daily_report}
              description={t(
                "notificationSettings.fields.is_notify_daily_report"
              )}
            />
            <Setting
              handleNotificationUpdate={handleNotificationUpdate}
              settingKey="is_notify_weekly_report"
              settingValue={settingInfo.is_notify_weekly_report}
              description={t(
                "notificationSettings.fields.is_notify_weekly_report"
              )}
            />
          </SettingsGroup>
          <DividerStyled />
        </>
      )}
    </Wrapper>
  );
};

export const SettingsGroup = styled.div`
  margin-top: 20px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
`;

export const SubTitle = styled.div`
  margin-top: 22px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 20px;
`;
