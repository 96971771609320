import { getNameSchema } from "shared/lib/helpers/validation";
import * as Yup from "yup";

export const validationSchema = (t) => {
  const schema = {
    name: getNameSchema(t, { required: true }),
  };

  return Yup.object(schema);
};
