import {
  useGetAllUsersQuery,
  useExportUsersMutation,
} from "modules/users/state/usersApi";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Table } from "shared/components/Table";
import { useFiltersParam, usePageParam, useSortParam } from "shared/lib/hooks";
import { Link } from "shared/components/Link";

import { Pagination } from "features/Pagination";
import { NoResults } from "shared/components/NoResults";
import { ErrorAllUsersList } from "./ErrorAllUsersList";
import { useUsersTableTools } from "modules/users/lib/useUsersTableTools";

import { TopBar } from "widgets/TopBar";
import { FilterProfileOptions } from "modules/filters/configs/constants";
import { UserAvatar } from "shared/components/UserAvatar";
import { useSetModal } from "widgets/ModalController";
import { useSearchParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters.js";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";

export const AllUsersList = () => {
  const { t } = useTranslation();
  const setModal = useSetModal();

  const [exportUserMutation] = useExportUsersMutation();

  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const [filters] = useFiltersParam([]);

  const { columns, isEntitiesFetching } = useUsersTableTools({
    onOpen: (id) => (id ? setModal({ type: "user", id }) : setModal()),
  });

  const { data, error, isFetching } = useGetAllUsersQuery({
    search,
    page,
    sort,
    ...stringifyFilters(filters, "users"),
  });
  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);

  const getFilterOptions = (field) => {
    if (field === "status") return FilterProfileOptions;
    return [];
  };

  const hasFilter = Object.keys(filters).length > 0;
  const empty = data?.data?.length === 0 && !isFetching && !hasFilter;

  if (empty) {
    return <NoResults query={search} />;
  } else if (error) {
    return <ErrorAllUsersList />;
  }

  return (
    <Wrapper>
      <TopBar
        entity="users"
        columns={columns}
        getOptions={getFilterOptions}
        onExportClick={() => {
          exportUserMutation({ ...filters, search });
        }}
        buttons={
          <ViewPermissionsButton
            variant="secondary"
            to="/admin-settings/permissions/view"
            type="button"
          >
            {t("allUsers.viewPermissions")}
          </ViewPermissionsButton>
        }
      />

      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading || isEntitiesFetching}
        highlight={search}
        getRowIcon={(row) => <UserAvatar size="sm" user={row} />}
      />

      <Pagination
        paginationData={data?.meta.pagination}
        label={t("allUsers.title")}
      />
    </Wrapper>
  );
};

const ViewPermissionsButton = styled(Link)`
  margin-left: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
