import { useTranslation } from "react-i18next";
import ReactSelect, { components } from "react-select";
import { Option } from "shared/components/Select/Option";
import { reportPeriodOptions } from "modules/reporting/lib/reportPeriodOptions";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import SettingsIcon from "shared/assets/icon/table/settings.svg?react";

interface ReliabilityHistoryPeriodSelectProps {
  value: ReportPeriod;
  onChange: (val: ReportPeriod | null) => void;
}

export const ReliabilityHistoryPeriodSelect = ({
  value,
  onChange,
}: ReliabilityHistoryPeriodSelectProps) => {
  const { t } = useTranslation();

  return (
    <ReactSelect
      name={t("reporting.periodSelect.name") as string}
      placeholder={t("reporting.periodSelect.placeholder")}
      options={reportPeriodOptions.map((i) => ({
        ...i,
        label: t(`reporting.periodOptions.${i.value}`),
      }))}
      isSearchable={false}
      isClearable={false}
      value={value}
      onChange={(val) => onChange(val)}
      components={{
        Option,
        IndicatorSeparator: null,
        DropdownIndicator,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          fontSize: 12,
          border: "none",
          minHeight: "unset",
        }),
        menu: (base) => ({
          ...base,
          width: "max-content",
          minWidth: "100%",
          right: 0,
          left: "unset",
        }),
      }}
    />
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <SettingsIcon />
    </components.DropdownIndicator>
  );
};
