import styled, { css } from "styled-components";

import { InlineLabel } from "shared/components/widgets";
import { Badge } from "shared/components/Badge";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 16px;
`;

export const StyledInlineLabel = styled(InlineLabel)`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
  margin-top: 10px;
`;

const BadgeStyle = css`
  ${({ height }) =>
    height &&
    css`
      height: ${({ height }) => height};
    `}
`;

export const StyledBadge = styled(Badge)`
  ${BadgeStyle}
  width: max-content;
`;

export const OnlineBadge = styled(Badge)`
  ${BadgeStyle};
  background-color: var(--color_light_freshman);
  width: max-content;
`;

export const OfflineBadge = styled(Badge)`
  ${BadgeStyle};
  background-color: var(--color_shy_girl);
  width: max-content;
`;
