import { TooltipProps } from "recharts";
import styled from "styled-components";

/**
 * Custom Recharts Tooltip content. Props are automatically passed by Recharts.
 *
 * [Recharts documentation](https://recharts.org/en-US/api/Tooltip)
 */
export const CustomTooltip = ({
  active,
  payload,
  label,
  formatter,
}: TooltipProps<number, string>) => {
  if (!active || !payload) {
    return null;
  }

  return (
    <TooltipWrapper>
      <span>{label}</span>
      {payload.map((i, idx) => (
        <span key={i.name}>
          {i.name}:{" "}
          {formatter && typeof i.value === "number" && i.name
            ? formatter(i.value, i.name, i, idx, payload)
            : i.value}
        </span>
      ))}
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid var(--color_grey200);
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;

  padding: 8px;
`;
