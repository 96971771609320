import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SnackBar } from "../SnackBar";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
`;

export const SnackBarContainer = ({ snackBars = [] }) => {
  return createPortal(
    <Wrapper>
      {snackBars.map((snackBar) => (
        <SnackBar
          key={snackBar.id}
          id={snackBar.id}
          type={snackBar.type}
          title={snackBar.title}
          secondaryButtonLabel={snackBar.secondaryButtonLabel}
          primaryButtonLabel={snackBar.primaryButtonLabel}
          handlePrimaryButtonClick={snackBar.handlePrimaryButtonClick}
        >
          {snackBar.content}
        </SnackBar>
      ))}
    </Wrapper>,
    document.body
  );
};

SnackBarContainer.propTypes = {
  snackBars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      type: PropTypes.oneOf(["success", "error"]),
      title: PropTypes.string,
      primaryButtonLabel: PropTypes.string,
      secondaryButtonLabel: PropTypes.string,
      handlePrimaryButtonClick: PropTypes.func,
    })
  ),
};
