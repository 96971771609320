import { IProcedureTemplateItem } from "modules/procedures/types";
import { ReactNode, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Identifier, XYCoord } from "dnd-core";
import styled from "styled-components";

interface DraggableItemProps {
  item: IProcedureTemplateItem;
  moveCard: (
    item: IProcedureTemplateItem,
    dragIndex: number,
    hoverIndex: number
  ) => void;
  children: ReactNode;
  index: number;
  onDrop: (item: IProcedureTemplateItem, newIndex: number) => void;
  dragDisabled: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DraggableItem = ({
  item: procTemplateItem,
  moveCard,
  index,
  onDrop,
  dragDisabled,
  children,
}: DraggableItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    type: "ITEM",
    canDrag: !dragDisabled,
    item: () => {
      return { id: procTemplateItem.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "ITEM",
    canDrop: () => !dragDisabled,
    hover: (item: { id: string; index: number }, monitor) => {
      // Mostly copied from example here: https://react-dnd.github.io/react-dnd/examples/sortable/simple
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(procTemplateItem, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item) => {
      onDrop(procTemplateItem, item.index);
    },
  });

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;
  const cursor = dragDisabled ? "wait" : "move";
  return (
    <Draggable
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity, cursor }}
    >
      {children}
    </Draggable>
  );
};

const Draggable = styled.div`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  backgroundcolor: white;
  cursor: move;
`;
