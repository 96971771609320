import React from "react";
import { TableSkeleton } from "shared/components/Skeleton";
import { NoResults } from "shared/components/NoResults";
import { BodyContainer, Box, ScrollBox } from "./Table.styled";
import PropTypes from "prop-types";

import { Body } from "./Body";
import { Head } from "./Head";

export const Table = ({
  className,
  columns = [],
  rows = [],
  selectionModel,
  onSelectionModelChange,
  highlight,
  showSkeleton,
  notFound,
  search,
  entityTranslationKey,
  bulkActionsNode,
  getRowKey = (row) => row.id,
  getRowBorder,
  getRowIcon,
}) => {
  const displayColumns = columns.filter(({ hidden }) => !hidden);
  const gridTemplateColumns = displayColumns
    .map((c) => {
      const minWidth = getWidthVal(c.minWidth);
      const width = getWidthVal(c.width) ?? "auto";
      return minWidth ? `minmax(${minWidth}, ${width})` : width;
    })
    .join(" ");

  if (showSkeleton) {
    return (
      <Box className={className}>
        <div style={{ display: "grid", gridTemplateColumns }}>
          <TableSkeleton columns={displayColumns} />
        </div>
      </Box>
    );
  }

  if (notFound) {
    return <NoResults query={search} />;
  }

  return (
    <Box className={className}>
      <ScrollBox>
        <BodyContainer style={{ gridTemplateColumns }}>
          <Head
            columns={displayColumns}
            rows={rows}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            entityTranslationKey={entityTranslationKey}
            bulkActionsNode={bulkActionsNode}
          />
          <Body
            rows={rows}
            getRowKey={getRowKey}
            getRowBorder={getRowBorder}
            getRowIcon={getRowIcon}
            columns={displayColumns}
            highlight={highlight}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
          />
        </BodyContainer>
      </ScrollBox>
    </Box>
  );
};

/**
 * Gets a valid CSS value from a string or number
 * @param value
 * @returns {string|undefined}
 */
function getWidthVal(value) {
  if (typeof value === "number") {
    return `${value}px`;
  } else if (typeof value === "string") {
    return value;
  }
  return undefined;
}

Table.defaultProps = {
  selectionModel: null,
  entityTranslationKey: null,
  bulkActionsNode: null,
};

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  rows: PropTypes.array,
  selectionModel: PropTypes.array,
  onSelectionModelChange: PropTypes.func,
  highlight: PropTypes.string,
  showSkeleton: PropTypes.bool,
  notFound: PropTypes.bool,
  search: PropTypes.string,
  entityTranslationKey: PropTypes.string,
  bulkActionsNode: PropTypes.node,
  getRowKey: PropTypes.func,
  /** Function to get a `border-left-color` for each row */
  getRowBorder: PropTypes.func,
  /** Icon to display in the first column, in place of checkbox when the row isn't selected */
  getRowIcon: PropTypes.func,
};
