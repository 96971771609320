import { useTranslation } from "react-i18next";
import { Header } from "widgets/Header";
import { LocationList } from "modules/locations";
import { Container } from "shared/components/Container";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalLink } from "shared/components/ModalLink";

export const List = () => {
  const { t } = useTranslation();
  const setModal = useSetModal();
  const { locationCreatePermit, locationsListPermit } = usePermission();

  return (
    <TablePageLayout>
      {locationsListPermit && (
        <>
          <Header
            title={t("locations.title", { count: 2 })}
            searchPlaceholder={t("locations.searchPlaceholder")}
            action={
              locationCreatePermit && (
                <ModalLink
                  type="button"
                  icon={<AddIcon />}
                  modalParams={{ type: "location", id: "new" }}
                >
                  {t("locations.title", { count: 1 })}
                </ModalLink>
              )
            }
            horizontallySpaciousPage
          />
          <Container>
            <LocationList
              onOpenLocation={(id) => setModal({ type: "location", id })}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
