import { useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { DateTimePicker } from "shared/components/DatePicker";
import { ChecklistManager } from "shared/components/Checklist";

import { Select, SelectCreatable } from "shared/components/Select";
import { Divider } from "shared/components/Divider";
import { PriorityInput } from "shared/components/Priority";

import { useSearch } from "shared/lib/hooks/useSearch";

import {
  getLocationsOptions,
  getAssetsOptions,
  getVendorsOptions,
  setAsset,
} from "shared/lib/helpers/data";

import {
  getOptions,
  getAssignToOptions,
  getAssignToIds,
  getTimezoneDateFormats,
} from "shared/lib/helpers";
import { Switch } from "shared/components/Switch";

import { FileManager } from "modules/fileManager";
import { useGetAllAssetsQuery, AssetCreate } from "modules/assets";
import { useGetAllVendorsQuery, VendorCreate } from "modules/vendors";
import { useGetAllLocationsQuery, LocationCreate } from "modules/locations";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetTechniciansQuery } from "modules/users";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";
import { useGetWorkOrderStatusesQuery } from "modules/workOrders/state/workOrdersApi";
import { defaultStatus, getStatusesOptions } from "../../lib/helpers/form";
import {
  ScheduleForm,
  INITIAL_SCHEDULE_VALUES,
} from "modules/preventiveMaintenances";
import { WorkOrderTimeList } from "modules/workOrderTime";
import { WorkOrderPartList } from "modules/workOrderParts";
import { WorkOrderOtherCostsList } from "modules/workOrderOtherCosts";

import { WorkOrderType } from "./WorkOrderType";
import { usePermission } from "app/providers/PermissionsProvider";
import { formatISO } from "date-fns";
import { useProcedureTemplateOptions } from "modules/procedures/hooks/useProcedureTemplateOptions";
import { ProcedureTemplateEdit } from "modules/procedures/components/ProcedureTemplateModal";
import { useCreateProcedureTemplateMutation } from "../../../procedures/state/proceduresApi";

import { field, pattern } from "@test-data";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";

export const WorkOrderEditor = ({
  type,
  module = "work_order",
  form,
  optionalFields,
  hasUncompletedWO,
  hasWorkOrders,
  scheduleType,
  scheduleStatus,
  disabledFields = [],
  permission = {
    parts: true,
    time: true,
    cost: true,
  },
}) => {
  const { enableProcedures } = useFlags();
  const { t } = useTranslation();
  const { pmsDetailsAddPermit } = usePermission();
  const { dateTimePickerFormat } = getTimezoneDateFormats();
  const captureException = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  const isEditDisabled = type === "edit";

  const { data: statusConfiguration } = useGetAdminConfigurationQuery(
    {
      type: "work_order_status",
    },
    { skip: !(module === "work_order" && !isEditDisabled) }
  );

  const [statusesData, onStatusesSearch] = useSearch(
    useGetWorkOrderStatusesQuery,
    module === "work_order" && !isEditDisabled
  );

  const statuses = useMemo(
    () => getStatusesOptions(statusesData?.data, statusConfiguration),
    [statusesData, statusConfiguration]
  );

  const [locations = [], onLocationsSearch] = useSearch(
    useGetAllLocationsQuery,
    optionalFields.hasOwnProperty("location_id"),
    undefined,
    "path_name"
  );

  const [categories = { data: [] }, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    optionalFields.hasOwnProperty("categories_ids")
  );

  const [assets = [], onAssetsSearch] = useSearch(
    useGetAllAssetsQuery,
    optionalFields.hasOwnProperty("asset_id")
  );

  const [vendors = [], onVendorsSearch] = useSearch(
    useGetAllVendorsQuery,
    optionalFields.hasOwnProperty("vendors_ids"),
    { "filter[assign_to_work_orders_true]": 1 }
  );

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const [teams, onTeamsSearch] = useSearch(
  //   useGetTeamsQuery,
  //   optionalFields.hasOwnProperty("assign_to")
  // );
  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    optionalFields.hasOwnProperty("assign_to"),
    {},
    "full_name"
  );

  const { onProcedureTemplateSearch, options: procedureTemplateOptions } =
    useProcedureTemplateOptions();
  const [createProcedureTemplate] = useCreateProcedureTemplateMutation();
  const [procedureSearchTerm, setProcedureSearchTerm] = useState("");
  const [modalTemplate, setModalTemplate] = useState();

  const { location, asset, vendors: formVendors } = form.values;

  const statusesOptions = statuses?.map(({ id, name }) => ({
    value: id,
    label: t(`work_orders.status.${name}`),
  }));

  const locationsOptions = getLocationsOptions(
    locations,
    {
      location,
      asset,
      vendors: formVendors,
    },
    "path"
  );

  const assetsOptions = getAssetsOptions(assets, {
    location,
    asset,
    vendors: formVendors,
  });

  const vendorsOptions = getVendorsOptions(vendors, {
    location,
    asset,
    vendors: formVendors,
  });

  const categoriesOptions = getOptions(categories);

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const assignToOptions = getAssignToOptions(teams, technicians);

  const assignToOptions = getAssignToOptions(technicians);
  const { technicians_ids } = getAssignToIds(form.values?.assign_to);

  // const assetStatus = useMemo(
  //   () =>
  //     assets?.find((asset) => asset.id === form.values.asset?.value)?.status,
  //   [assets, form.values.asset]
  // );

  const handleTypeUpdate = async (value) => {
    const { handleSetValue } = form;
    await handleSetValue(
      "pm_schedule_attributes",
      value === "preventive" ? INITIAL_SCHEDULE_VALUES : undefined
    );
    await handleSetValue("status", defaultStatus);
    if (value === "preventive") {
      await handleSetValue("due_date", "");
    }
    await handleSetValue("type", value);
  };

  const handleTimeCreate = (values) => {
    form.handleSetValue("work_order_times_attributes", [
      values,
      ...form.values.work_order_times_attributes,
    ]);
  };

  const handleTimeUpdate = (value, index) => {
    const newValue = [...form.values.work_order_times_attributes].map(
      (val, i) => (index === i ? value : val)
    );
    form.handleSetValue("work_order_times_attributes", newValue);
  };

  const handleTimeDelete = (index) => {
    const newValue = [...form.values.work_order_times_attributes];
    newValue.splice(index, 1);
    form.handleSetValue("work_order_times_attributes", newValue);
  };

  const handlePartCreate = (value) => {
    const existingPartIndex =
      form.values.part_transactions_attributes.findIndex(
        (part) => part.part.value === value.part.value
      );

    const updatedParts =
      existingPartIndex !== -1
        ? [...form.values.part_transactions_attributes].map((part, index) => {
            return index === existingPartIndex
              ? { ...part, quantity: part.quantity + value.quantity }
              : part;
          })
        : [value, ...form.values.part_transactions_attributes];

    form.handleSetValue("part_transactions_attributes", updatedParts);
  };

  const handlePartUpdate = (value, index) => {
    const newValue = [...form.values.part_transactions_attributes].map(
      (val, i) => (index === i ? value : val)
    );
    form.handleSetValue("part_transactions_attributes", newValue);
  };

  const handlePartDelete = (index) => {
    const newValue = [...form.values.part_transactions_attributes];
    newValue.splice(index, 1);
    form.handleSetValue("part_transactions_attributes", newValue);
  };

  const handleOtherCostCreate = (values) => {
    form.handleSetValue("work_order_other_costs_attributes", [
      values,
      ...form.values.work_order_other_costs_attributes,
    ]);
  };

  const handleOtherCostUpdate = (value, index) => {
    const newValue = [...form.values.work_order_other_costs_attributes].map(
      (val, i) => (index === i ? value : val)
    );
    form.handleSetValue("work_order_other_costs_attributes", newValue);
  };

  const handleOtherCostDelete = (index) => {
    const newValue = [...form.values.work_order_other_costs_attributes];
    newValue.splice(index, 1);
    form.handleSetValue("work_order_other_costs_attributes", newValue);
  };

  const isWOModule = module === "work_order";
  const isOneTimeSchedule = scheduleType === "one_time";
  const isScheduleCompleted = scheduleStatus === "completed";

  const isScheduleDisabled =
    isEditDisabled &&
    (isWOModule ||
      (isOneTimeSchedule && (isScheduleCompleted || hasUncompletedWO)));

  const onAssignToSearch = (value) => {
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // onTeamsSearch(value);
    onTechniciansSearch(value);
  };

  const assetStatus = useMemo(() => {
    const selectedAssetId = Number.parseInt(form.values.asset?.value);
    return assets?.find((asset) => asset.id === selectedAssetId)?.status;
  }, [assets, form.values.asset]);

  const isShowPmSchedule =
    module === "pm" ? pmsDetailsAddPermit : !isEditDisabled;

  return (
    <>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="title"
        value={form.values.title}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("work_orders.form.title")}
        errorMessage={form.touched.title && form.errors.title}
        height={50}
        fontSize={20}
        required
        disabled={disabledFields.includes("title")}
        data-testid={pattern.inputId(field.title.db)}
      />
      {optionalFields.hasOwnProperty("description") && (
        <InlineLabel
          label={t("work_orders.form.description")}
          labelWidth={110}
          isRequired={optionalFields.description.required}
        >
          <Input
            name="description"
            value={form.values.description}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("work_orders.form.description")}
            errorMessage={form.touched.description && form.errors.description}
            isTextArea
            data-testid={pattern.inputId(field.description.db)}
          />
        </InlineLabel>
      )}
      {!disabledFields.includes("priority") &&
        optionalFields.hasOwnProperty("priority") && (
          <InlineLabel
            label={t("work_orders.form.priority")}
            labelWidth={110}
            isRequired={optionalFields.priority.required}
          >
            <PriorityInput
              value={form.values.priority}
              onChange={(value) => {
                const { handleSetValue } = form;
                handleSetValue("priority", value);
              }}
            />
          </InlineLabel>
        )}
      {!disabledFields.includes("categories_ids") &&
        optionalFields.hasOwnProperty("categories_ids") && (
          <InlineLabel
            label={t("work_orders.form.category")}
            labelWidth={110}
            isRequired={optionalFields.categories_ids.required}
          >
            <Select
              options={categoriesOptions}
              name="categories"
              value={form.values.categories}
              setValue={form.handleSetValue}
              isMulti
              onBlur={form.handleBlur}
              noOptionsMessage={t("noOptions")}
              errorMessage={form.touched.categories && form.errors.categories}
              onSearch={onCategoriesSearch}
              onTouch={form.handleSetTouched}
              dataTestId={pattern.inputId(field.category.db)}
            />
          </InlineLabel>
        )}
      {!disabledFields.includes("assign_to") &&
        optionalFields.hasOwnProperty("assign_to") && (
          <InlineLabel
            label={t("work_orders.form.assign_to")}
            labelWidth={110}
            isRequired={optionalFields.assign_to.required}
          >
            <Select
              options={assignToOptions}
              name="assign_to"
              value={form.values.assign_to}
              setValue={form.handleSetValue}
              isMulti
              onBlur={form.handleBlur}
              noOptionsMessage={t("noOptions")}
              errorMessage={form.touched.assign_to && form.errors.assign_to}
              onSearch={onAssignToSearch}
              onTouch={form.handleSetTouched}
              dataTestId={pattern.inputId(field.assignedTo.db)}
            />
          </InlineLabel>
        )}
      {!disabledFields.includes("type") && (
        <InlineLabel
          label={t("work_orders.form.type")}
          labelWidth={110}
          tooltipContent={t("work_orders.form.typeTooltip")}
        >
          <WorkOrderType
            value={form.values.type}
            onChange={(value) => handleTypeUpdate(value)}
            isDisabled={isEditDisabled}
          />
        </InlineLabel>
      )}
      {(form.values.type === "reactive" ||
        (module === "work_order" && isEditDisabled)) &&
        optionalFields.hasOwnProperty("due_date") &&
        !disabledFields.includes("due_date") && (
          <InlineLabel
            label={t("work_orders.form.due_date")}
            labelWidth={110}
            isRequired={optionalFields.due_date.required}
          >
            <DateTimePicker
              name="due_date"
              onChange={(val) => form.handleSetValue("due_date", val)}
              selected={form.values.due_date}
              dateFormat={dateTimePickerFormat}
              showIcon
              minDateTime={formatISO(new Date())}
              dataTestId={pattern.inputId(field.dueDate.db)}
            />
          </InlineLabel>
        )}
      {form.values.type === "preventive" &&
        isShowPmSchedule &&
        !disabledFields.includes("pm_schedule_attributes") && (
          <InlineLabel
            label={t("work_orders.form.pm_schedule_attributes.schedule")}
            labelWidth={110}
            multiline
          >
            <ScheduleForm
              name="pm_schedule_attributes"
              value={form.values.pm_schedule_attributes}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              errorMessage={
                form?.touched?.pm_schedule_attributes?.start_date &&
                form?.errors?.pm_schedule_attributes
              }
              disabled={isScheduleDisabled}
              hasWorkOrders={hasWorkOrders}
            />
          </InlineLabel>
        )}
      {optionalFields.hasOwnProperty("asset_id") && (
        <InlineLabel
          label={t("work_orders.form.assets")}
          labelWidth={110}
          isRequired={optionalFields.asset_id.required}
        >
          <SelectCreatable
            options={assetsOptions}
            name="asset"
            value={form.values.asset}
            setValue={(name, asset) => {
              setAsset(name, asset, locations, assets, form);
            }}
            onBlur={form.handleBlur}
            noOptionsMessage={t(
              "work_order_requests.form.depended.assets.noOptions"
            )}
            errorMessage={form.touched.asset && form.errors.asset}
            optionCreateModal={<AssetCreate />}
            onSearch={onAssetsSearch}
            onTouch={form.handleSetTouched}
            dataTestId={pattern.inputId(field.asset.db)}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("location_id") && (
        <InlineLabel
          label={t("work_orders.form.location")}
          labelWidth={110}
          isRequired={optionalFields.location_id.required}
        >
          <SelectCreatable
            options={locationsOptions}
            name="location"
            value={form.values.location}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            noOptionsMessage={t(
              "work_order_requests.form.depended.locations.noOptions"
            )}
            errorMessage={form.touched.location && form.errors.location}
            optionCreateModal={<LocationCreate />}
            onSearch={onLocationsSearch}
            onTouch={form.handleSetTouched}
            dataTestId={pattern.inputId(field.location.db)}
          />
        </InlineLabel>
      )}
      {type === "create" &&
        assetStatus === "online" &&
        !disabledFields.includes("set_asset_to_offline") && (
          <InlineLabel label="" labelWidth={110}>
            <Switch
              activated={form.values.set_asset_to_offline}
              onClick={() => {
                const { handleSetValue } = form;
                handleSetValue(
                  "set_asset_to_offline",
                  !form.values.set_asset_to_offline
                );
              }}
            >
              {t("work_orders.form.setToOffline")}
            </Switch>
          </InlineLabel>
        )}
      {optionalFields.hasOwnProperty("vendors_ids") && (
        <InlineLabel
          label={t("work_orders.form.vendor")}
          labelWidth={110}
          isRequired={optionalFields.vendors_ids.required}
        >
          <SelectCreatable
            options={vendorsOptions}
            name="vendors"
            value={form.values.vendors}
            setValue={form.handleSetValue}
            isMulti
            menuPlacement="top"
            onBlur={form.handleBlur}
            noOptionsMessage={t(
              "work_order_requests.form.depended.vendors.noOptions"
            )}
            errorMessage={form.touched.vendors && form.errors.vendors}
            onSearch={onVendorsSearch}
            onTouch={form.handleSetTouched}
            dataTestId={pattern.inputId(field.vendors.db)}
            optionCreateModal={<VendorCreate defaultChecked="workOrders" />}
          />
        </InlineLabel>
      )}
      {enableProcedures && (
        <InlineLabel label={t("work_orders.form.procedures")} labelWidth={110}>
          <SelectCreatable
            options={procedureTemplateOptions}
            name="procedure_templates"
            isMulti
            menuPlacement="top"
            onSearch={(value) => {
              setProcedureSearchTerm(value);
              onProcedureTemplateSearch(value);
            }}
            ariaLabel="Procedures"
            value={form.values.procedure_templates}
            setValue={form.handleSetValue}
            preCreateHook={async () => {
              if (!procedureSearchTerm) return;

              try {
                const response = await createProcedureTemplate({
                  procedure_template: {
                    name: procedureSearchTerm,
                  },
                }).unwrap();
                setModalTemplate(response.data);
              } catch (e) {
                captureException(e, "Failed to create procedure template", {
                  procedureSearchTerm,
                });
              }
            }}
            optionCreateModal={
              modalTemplate?.id ? (
                <ProcedureTemplateEdit
                  procedureTemplateId={modalTemplate?.id}
                />
              ) : (
                <></>
              )
            }
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("checklist") &&
        !disabledFields.includes("checklist") && (
          <InlineLabel
            label={t("work_orders.form.checklist")}
            labelWidth={110}
            isRequired={optionalFields.checklist.required}
            multiline
          >
            <ChecklistManager
              name="checklist"
              value={form.values.checklist}
              onChange={form.handleChange}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              errorMessage={form.touched.checklist && form.errors.checklist}
              required={optionalFields.checklist.required}
            />
          </InlineLabel>
        )}
      {module === "work_order" &&
        !isEditDisabled &&
        form.values.type === "reactive" && (
          <InlineLabel label={t("work_orders.form.status")} labelWidth={110}>
            <Select
              options={statusesOptions}
              name="status"
              value={form.values.status}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={t("noOptions")}
              errorMessage={form.touched.status && form.errors.status}
              onSearch={onStatusesSearch}
              onTouch={form.handleSetTouched}
              dataTestId={pattern.inputId(field.statusWorkOrders.db)}
            />
          </InlineLabel>
        )}
      {!isEditDisabled && (
        <>
          <DividerStyled />
          <CostAndTimeContainer>
            {permission.time && form.values.type === "reactive" && (
              <WorkOrderTimeList
                onCreate={handleTimeCreate}
                onUpdate={handleTimeUpdate}
                onDelete={handleTimeDelete}
                value={form.values.work_order_times_attributes}
                columns={2}
                withTimer={false}
                type="creation"
                techniciansIds={technicians_ids}
              />
            )}
            {permission.parts && (
              <WorkOrderPartList
                onCreate={handlePartCreate}
                onUpdate={handlePartUpdate}
                onDelete={handlePartDelete}
                value={form.values.part_transactions_attributes}
                assetId={asset?.value}
                module={form.values.type === "preventive" ? "PM" : "work order"}
                type="edit"
              />
            )}
            {permission.cost && form.values.type === "reactive" && (
              <WorkOrderOtherCostsList
                value={form.values.work_order_other_costs_attributes}
                onCreate={handleOtherCostCreate}
                onUpdate={handleOtherCostUpdate}
                onDelete={handleOtherCostDelete}
                withImageZoom={false}
                withFileDownload={false}
              />
            )}
          </CostAndTimeContainer>
        </>
      )}
    </>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 32px;
`;

const CostAndTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 0 0;
`;
