import {
  getFormDataFromJSON,
  addFilesForFormData,
  addQrCodeForFormData,
} from "shared/lib/helpers/formData";
import {
  AssetFormOptionalFields,
  initialValues,
  notificationOptions,
} from "../../configs/constants";
import { getAssignToValues, getAssignToIds } from "shared/lib/helpers";

export const getInitialValues = ({ type, data }) => {
  if (type === "create" || !data) {
    return initialValues;
  }

  const {
    name = "",
    description,
    location,
    asset_types = [],
    model,
    manufacturer,
    serial_number,
    purchase_date,
    parent,
    // TODO: Fix hardcoded color when backend adds it
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    //teams = [],
    technicians = [],
    urls,
    vendors = [],
    parts = [],
    qr_code,
    warranty_exp_date,
    warranty_expiration_notification,
    life_expectancy,
    purchase_cost,
    replacement_cost,
    salvage_value,
    images = [],
    documents = [],
  } = data;

  const assetEditForm = {
    name,
    description: description ?? "",
    model: model ?? "",
    manufacturer: manufacturer ?? "",
    serial_number: serial_number ?? "",
    purchase_date: purchase_date ?? "",
    purchase_cost: purchase_cost ?? "",
    location: location ? { value: location?.id, label: location?.path } : "",
    asset_types: (asset_types || []).map((type) => {
      return { value: type.id, label: type.name };
    }),
    parent: parent ? { value: parent?.id, label: parent?.name } : "",
    urls_attributes: urls,
    vendors: (vendors || []).map((vendor) => {
      return { value: vendor.id, label: vendor.name };
    }),
    parts: (parts || []).map((part) => {
      return { value: part.id, label: part.name };
    }),
    qr_code_attributes: qr_code ?? "",
    warranty_exp_date: warranty_exp_date ?? "",
    warranty_expiration_notification:
      warranty_expiration_notification || warranty_expiration_notification === 0
        ? {
            value: warranty_expiration_notification,
            label: notificationOptions.find(
              (opt) =>
                Number(opt.value) === Number(warranty_expiration_notification)
            )?.label,
          }
        : "",
    life_expectancy: life_expectancy ?? "",
    replacement_cost: replacement_cost ?? "",
    salvage_value: salvage_value ?? "",
    uploads_attributes: images
      .map((image) => ({ ...image, isImage: true }))
      .concat(documents),
    // TODO: Fix hardcoded color when backend adds it
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // assign_to: getAssignToValues(teams, technicians),
    assign_to: getAssignToValues(technicians),
  };

  return assetEditForm;
};

export const getFormatForService = (body, options = {}) => {
  const {
    uploads_attributes,
    qr_code_attributes,
    location,
    asset_types,
    vendors,
    parent,
    parts,
    warranty_expiration_notification,
    assign_to,
    ...bodyAtr
  } = body;

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  //const {teams_ids, technicians_ids } = getAssignToIds(assign_to);
  const { technicians_ids } = getAssignToIds(assign_to);

  const formData = getFormDataFromJSON({
    ...bodyAtr,
    location_id: location?.value || "",
    asset_types_ids: asset_types.map(({ value }) => value),
    parent_id: parent?.value || "",
    vendors_ids: vendors.map(({ value }) => value),
    parts_ids: parts.map(({ value }) => value),
    warranty_expiration_notification:
      warranty_expiration_notification?.value || "",
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // teams_ids,
    technicians_ids,
  });
  addFilesForFormData(formData, uploads_attributes);
  addQrCodeForFormData(formData, qr_code_attributes, options);
  formData.append("status", "online");

  return formData;
};

export const getAssignOptionsList = (props) => {
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const teams = props.teamsOptions.map((opt) => ({
  //   key: opt.id,
  //   value: opt.name,
  // }));
  // const teamsOptions = teams?.map((el) => {
  //   return { ...el, category: "Teams", key: el.key + "_Teams" };
  // });
  const technicians = props.techniciansOptions.map((opt) => ({
    key: opt.id,
    value: opt.name,
  }));
  const techniciansOptions = technicians?.map((el) => {
    return { ...el, category: "Technicians", key: el.key + "_Technicians" };
  });
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // return [...teamsOptions, ...techniciansOptions];
  return [...techniciansOptions];
};

export const getOptionalFields = (fieldsData) => {
  if (!fieldsData || fieldsData?.status !== "success") {
    return {};
  }

  const { data } = fieldsData;

  return AssetFormOptionalFields.reduce((acc, optionalField) => {
    const field = data.find(({ name }) => name === optionalField);
    if (field) {
      const { required } = field;
      acc[optionalField] = { required };
    }
    return acc;
  }, {});
};
