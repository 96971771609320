import CheckIcon from "shared/assets/Check.svg?react";
import {
  OptionWrapper,
  OptionContainer,
  SelectedIconContainer,
  NoOptionContainer,
} from "./SelectDropdownStyled";

export const Option = ({ children, isSelected, innerProps }) => {
  return (
    <OptionWrapper {...innerProps}>
      <OptionContainer selected={isSelected}>
        <SelectedIconContainer visible={isSelected}>
          <CheckIcon />
        </SelectedIconContainer>
        {children}
      </OptionContainer>
    </OptionWrapper>
  );
};

export const NoOptionsMessage = ({ children, ...props }) => (
  <NoOptionContainer {...props}>{children}</NoOptionContainer>
);
