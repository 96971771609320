import { useGetWidgetDataQuery } from "modules/reporting/state/reportingApi";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";

interface ReportWidgetData {
  chart_data: object[];
  other_data: object;
}

/** Fetches Report Widget data for a given `reportId` */
export const useWidgetData = <T extends ReportWidgetData>(
  reportId: string,
  reportPeriod?: ReportPeriod
) => {
  const state = useGetWidgetDataQuery({
    reportId,
    start_date: reportPeriod?.startDate,
    end_date: reportPeriod?.endDate,
  });
  const data = state.data as T | undefined;
  return {
    ...state,
    data,
  };
};
