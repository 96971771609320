import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { formatCurrency } from "shared/lib/helpers/common";
import { usePermission } from "app/providers/PermissionsProvider";
import { useGetLocaleSettings } from "shared/lib/hooks";

const fields = ["labor", "parts", "other"];

export const WorkOrderTotals = ({ value = {} }) => {
  const { t } = useTranslation();
  const { canShowTotalCost } = usePermission();
  const { currency } = useGetLocaleSettings();

  const data = fields.filter((field) => !!value[field]);

  const fieldsAccess = canShowTotalCost([...data, "total"]);

  return (
    <div>
      {data.map(
        (key) =>
          fieldsAccess[key] && (
            <Box>
              <div>{t(`work_orders.totals.${key}`)}</div>
              <div>{formatCurrency(value[key], currency)}</div>
            </Box>
          )
      )}
      {data.length > 0 && fieldsAccess.total && (
        <TotalBox>
          <div>{t(`work_orders.totals.total`)}</div>
          <div>{formatCurrency(value.total, currency)}</div>
        </TotalBox>
      )}
    </div>
  );
};

const BoxStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 14px;
  line-height: 20px;
`;

const Box = styled.div`
  ${BoxStyles}
  color: var(--color_grey400);
  border-bottom: 1px dashed var(--color_grey200);

  &:last-child {
    border-bottom: 1px solid var(--color_grey200);
  }
`;

const TotalBox = styled.div`
  ${BoxStyles}
  color: var(--color_grey900);
  font-weight: 500;
`;
