import { StyleSheet } from "@react-pdf/renderer";

const generalStyles = {
  informationBox: {
    maxWidth: "200px",
    flexDirection: "column",
    fontSize: "8px",
    fontFamily: "Poppins",
    color: "#333238",
    justifyContent: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    width: 87,
    height: 87,
  },
  fontSize: {
    fontSize: "12px",
  },
};

export const muiltipleStyles = StyleSheet.create({
  ...generalStyles,
  page: {
    marginTop: "20px",
  },
  container: {
    width: "330px",
    flexDirection: "row",
    gap: 15,
    border: "1px solid #DCDCDE",
    padding: 10,
  },
  box: { alignSelf: "center" },
});

export const singleStyles = StyleSheet.create({
  ...generalStyles,
  container: {
    flexDirection: "row",
    gap: 15,
    padding: 10,
  },
});
