import type { ModalType } from "./types";
import { encodeModalParam } from "widgets/ModalController/modalParamUtils";

/**
 * Gets the URL for a modal.
 * Only used for modals that are rendered by `ModalController`
 */
export function getModalPath(
  location: {
    search: string;
    pathname: string;
  },
  type: ModalType,
  id: string | number,
  state?: string
) {
  const params = new URLSearchParams(location.search);
  params.delete("m");
  params.append("m", encodeModalParam(type, id, state));
  return `${location.pathname}?${decodeURI(params.toString())}`;
}
