import { DonutChart } from "modules/reporting/components/charts/DonutChart";
import { StatusMap } from "modules/workOrders/config/constants";

import { DonutChartWidgetLayout } from "modules/reporting/components/widgets/DonutChartWidgetLayout";
import { NoData } from "modules/reporting/components/NoData";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useTranslation } from "react-i18next";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";

interface BacklogByStatusReportWidgetData {
  chart_data: {
    key: string;
    value: number;
  }[];
  other_data: never;
}

export const BacklogByStatusReportWidget = () => {
  const sliceConfigs = useSliceConfigs();
  const { data, isFetching } = useWidgetData<BacklogByStatusReportWidgetData>(
    ReportKey.BacklogByStatus,
    reportPeriodCurrent
  );

  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  return (
    <DonutChartWidgetLayout>
      <DonutChart.Root
        data={data?.chart_data ?? []}
        isLoading={isFetching}
        sliceConfigs={sliceConfigs}
      >
        <DonutChart.Legend />
        <DonutChart.Chart>
          <DonutChart.Label />
        </DonutChart.Chart>
      </DonutChart.Root>
    </DonutChartWidgetLayout>
  );
};

function useSliceConfigs() {
  const { t } = useTranslation();
  return {
    open: {
      fill: "var(--color_open)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.open`),
      icon: StatusMap.open.iconUrl,
    },
    on_hold: {
      fill: "var(--color_on_hold)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.on_hold`),
      icon: StatusMap.on_hold.iconUrl,
    },
    in_progress: {
      fill: "var(--color_in_progress)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.in_progress`),
      icon: StatusMap.in_progress.iconUrl,
    },
    in_review: {
      fill: "var(--color_in_review)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.in_review`),
      icon: StatusMap.in_review.iconUrl,
    },
    waiting_for_parts: {
      fill: "var(--color_waiting_for_parts)",
      label: t(
        `reporting.${ReportKey.BacklogByStatus}.labels.waiting_for_parts`
      ),
      icon: StatusMap.waiting_for_parts.iconUrl,
    },
    waiting_for_cost: {
      fill: "var(--color_waiting_for_cost)",
      label: t(
        `reporting.${ReportKey.BacklogByStatus}.labels.waiting_for_cost`
      ),
      icon: StatusMap.waiting_for_cost.iconUrl,
    },
    waiting_for_invoice: {
      fill: "var(--color_waiting_for_invoice)",
      label: t(
        `reporting.${ReportKey.BacklogByStatus}.labels.waiting_for_invoice`
      ),
      icon: StatusMap.waiting_for_invoice.iconUrl,
    },
  };
}
