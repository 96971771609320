import { useState } from "react";

/**
 * Wraps a query to manage search state
 * @param useQuery {function=}
 * @param isShow {boolean=}
 * @param otherParams {object=}
 * @param searchField {string=}
 * @param args {object=}
 * @returns {[{data: any},(value: (((prevState: string) => string) | string)) => void]}
 */
export const useSearch = (
  useQuery = () => [],
  isShow = true,
  otherParams = {},
  searchField = "name",
  args
) => {
  const [search, setSearch] = useState("");
  const searchParams = search
    ? { [`filter[${searchField}_cont]`]: search }
    : {};
  const params = { ...searchParams, ...otherParams };
  const { data: defaultList } = useQuery(params, { skip: !isShow, ...args });
  return [defaultList, setSearch];
};
