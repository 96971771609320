import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FileManager } from "modules/fileManager";
import { useGetEntitiesQuery } from "modules/entities";

import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Select } from "shared/components/Select";
import { useSearch } from "shared/lib/hooks/useSearch";
import { getOptions } from "shared/lib/helpers";
import { Switch } from "shared/components/Switch";

import { field, pattern } from "@test-data";

export const RequestPortalEditor = ({ form }) => {
  const { t } = useTranslation();

  const [entities = [], onEntitiesSearch] = useSearch(
    useGetEntitiesQuery,
    true
  );

  const entitiesOptions = getOptions(entities);

  return (
    <Container>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="title"
        value={form.values.title}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("request_portals.form.title")}
        errorMessage={form.touched.title && form.errors.title}
        height={50}
        fontSize={20}
        required
        data-testid={pattern.inputId(field.title.db)}
      />
      <InlineLabel
        label={t("request_portals.form.description")}
        labelWidth={110}
        fontSize="14px"
      >
        <Input
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("request_portals.form.description")}
          errorMessage={form.touched.description && form.errors.description}
          isTextArea
          data-testid={pattern.inputId(field.description.db)}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.welcome_text")}
        labelWidth={110}
        fontSize="14px"
      >
        <Input
          name="welcome_text"
          value={form.values.welcome_text}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("request_portals.form.welcome_text")}
          errorMessage={form.touched.welcome_text && form.errors.welcome_text}
          height="72"
          isTextArea
          data-testid={pattern.inputId(field.welcomeText.db)}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.instructions")}
        labelWidth={110}
        fontSize="14px"
      >
        <Input
          name="instructions"
          value={form.values.instructions}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("request_portals.form.instructions")}
          errorMessage={form.touched.instructions && form.errors.instructions}
          isTextArea
          data-testid={pattern.inputId(field.instructions.db)}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.entity")}
        labelWidth={110}
        fontSize="14px"
      >
        <Select
          options={entitiesOptions}
          name="entity"
          value={form.values.entity}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          errorMessage={form.touched.entity && form.errors.entity}
          onSearch={onEntitiesSearch}
          onTouch={form.handleSetTouched}
          dataTestId={pattern.inputId(field.entity.db)}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.allowAnonymous")}
        labelWidth={110}
        fontSize="14px"
      >
        <Switch
          activated={form.values.allow_anonymous}
          onClick={() =>
            form.handleSetValue("allow_anonymous", !form.values.allow_anonymous)
          }
          dataTestId={pattern.inputId(field.allowAnonymous.db)}
        />
      </InlineLabel>
    </Container>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;

const Container = styled.div`
  color: var(--color_grey900);

  & input,
  textarea {
    color: var(--color_grey900);
  }
`;
