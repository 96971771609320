import {
  useAddAssetTypeMutation,
  useDeleteAssetTypeMutation,
  useGetAdminAssetTypesQuery,
  useGetAssetTypeQuery,
  useUpdateAssetTypeMutation,
} from "modules/assetTypes";
import { AdministrationReference } from "shared/components/AdministrationReference";
import { usePermission } from "app/providers/PermissionsProvider";

const actions = {
  getList: useGetAdminAssetTypesQuery,
  getElement: useGetAssetTypeQuery,
  addElement: useAddAssetTypeMutation,
  updateElement: useUpdateAssetTypeMutation,
  deleteElement: useDeleteAssetTypeMutation,
};

export const AssetTypes = () => {
  const { adminSettingsAssetTypesPermit } = usePermission();
  return (
    <AdministrationReference
      actions={actions}
      module="asset-types"
      hasPermission={adminSettingsAssetTypesPermit}
    />
  );
};
