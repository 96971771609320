import styled from "styled-components";
import confetti from "canvas-confetti";
import { forwardRef, useImperativeHandle, useRef } from "react";

interface WOCompleteFanfareProps {
  duration?: number;
  className?: string;
}

export interface WOCompleteFanfareHandle {
  play: () => void;
}

/** Displays an animated check mark and some confetti */
export const WOCompleteFanfare = forwardRef<
  WOCompleteFanfareHandle,
  WOCompleteFanfareProps
>(({ duration = 1500, className }, handleRef) => {
  const circleRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  useImperativeHandle(
    handleRef,
    () => ({
      play: () => {
        const circleEl = circleRef.current;
        const svgEl = svgRef.current;
        if (!circleEl || !svgEl) return;

        const circleFrames = [
          { opacity: 0, scale: "50%", easing: "ease-out" },
          { opacity: 1, scale: "100%", offset: 0.2 },
          { opacity: 1, scale: "100%", offset: 0.8 },
          { opacity: 0, scale: "50%", easing: "ease-in" },
        ];
        const svgFrames = [
          { strokeDashoffset: 26 },
          { strokeDashoffset: 26, offset: 0.3, easing: "ease-in-out" },
          { strokeDashoffset: 0, offset: 0.7, easing: "ease-in-out" },
          { strokeDashoffset: 0 },
        ];

        const animProps = { duration };
        circleEl.animate(circleFrames, animProps);
        svgEl.animate(svgFrames, animProps);

        const rect = circleEl.getBoundingClientRect();
        const confettiOrigin = {
          x: (rect.left + rect.width / 2) / window.innerWidth,
          y: rect.top / document.documentElement.clientHeight,
        };
        razzleDazzle(confettiOrigin);
      },
    }),
    [duration]
  );

  return (
    <div className={className} aria-hidden>
      <Circle ref={circleRef}>
        <Check viewBox="0 0 21 15.8" ref={svgRef}>
          <path
            d="m60.362 66.753 5.816 6.093 11.184-11.717"
            transform="translate(-58.362 -59.088)"
            style={{
              fill: "none",
              stroke: "#fff",
              strokeWidth: 3,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeDasharray: 26,
            }}
          />
        </Check>
      </Circle>
    </div>
  );
});

const cmArrow = confetti.shapeFromPath({
  path: "m174.309 100.138 41.137-82.532 40.563 82.568-15.762-4.22a97.38 97.38 0 0 0-50.306 0zm-13.218 10.615a15.288 15.288 0 0 0 15.755 5.045l17.172-4.587a81.795 81.795 0 0 1 42.16 0l17.275 4.622c1.326.354 2.693.535 4.065.538a15.027 15.027 0 0 0 11.67-5.55 14.588 14.588 0 0 0 1.851-15.855L229.075 9.532a15.071 15.071 0 0 0-13.581-8.405h-.076a14.982 14.982 0 0 0-13.537 8.333l-42.582 85.407a14.56 14.56 0 0 0 1.792 15.886z",
});

function razzleDazzle(origin: { x: number; y: number }) {
  const baseScale = 1.5;
  const confettiProps = {
    origin,
    ticks: 100,
    disableForReducedMotion: true,
    shapes: [cmArrow],
    colors: ["#00F0B4", "#FF786E", "#B4F000"],
  };
  confetti({
    ...confettiProps,
    particleCount: 50,
    spread: 26,
    startVelocity: 55,
    scalar: baseScale,
  });
  confetti({
    ...confettiProps,
    particleCount: 40,
    spread: 60,
    scalar: baseScale,
  });
  confetti({
    ...confettiProps,
    particleCount: 70,
    spread: 100,
    decay: 0.91,
    scalar: 0.8 * baseScale,
  });
  confetti({
    ...confettiProps,
    particleCount: 20,
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2 * baseScale,
  });
  confetti({
    ...confettiProps,
    particleCount: 20,
    spread: 120,
    startVelocity: 45,
    scalar: baseScale,
  });
}

const Circle = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #5f8a38;
  border-radius: 50%;
  opacity: 0;
`;

const Check = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
`;
