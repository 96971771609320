import PhoneNumber from "react-phone-number-input";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";

export const PhoneInput = ({
  value = "",
  setValue,
  errorMessage,
  name = "phone_number",
  onBlur,
  touched,
  ...rest
}) => {
  const [country, setCountry] = useState("");

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          setCountry(response?.data?.country);
        })
        .catch((error) => {
          setCountry("US");
        });
    };
    fetchData();
  }, []);

  return (
    <PhoneContainer>
      <Phone
        {...rest}
        value={value}
        onChange={setValue}
        onBlur={(e) => {
          setValue(e.target.value);
          touched();
        }}
        defaultCountry={country}
        international
      />
      {errorMessage && <Error className="error">{errorMessage}</Error>}
    </PhoneContainer>
  );
};

const PhoneContainer = styled.div`
  position: relative;

  .PhoneInputCountryIconImg {
    max-height: 100%;
    max-width: 100%;
  }
`;

const Error = styled.div`
  position: absolute;
  color: var(--color_alert);
  font-size: 14px;
  top: calc(100% + 6px);
  left: 62px;
`;

const Phone = styled(PhoneNumber)`
  display: flex;
  height: 40px;
  width: 100%;
  & > div {
    display: flex;
    align-items: center;
    & > select {
      order: 2;
      max-width: 20px;
      margin-right: 2px;
      border: none;
    }

    & div:nth-child(2) {
      display: flex;
      align-items: center;
      order: 1;
      width: 40px;
      max-width: 40px;
    }
  }

  & input {
    flex-grow: 1;
    outline: transparent;
    font-weight: 400;
    font-size: 14px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color_grey200);

    &:focus {
      box-shadow: 0px 0px 0px 1px var(--color_background_light),
        0px 0px 0px 3px var(--color_primary);

      &,
      &:placeholder-shown {
        border-color: var(--color_grey900);
      }
    }
  }
`;
