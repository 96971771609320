import React from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import { usePermission } from "app/providers/PermissionsProvider";

const VendorAdminConfigurationFields = [
  "rating",
  "tax_id",
  "vendor_contacts",
  "urls",
  "address",
  "hourly_rate",
  "locations",
  "categories",
  "assets",
  "parts",
];

export const VendorsAdminSettings = () => {
  const { adminSettingsVendorPermit } = usePermission();
  const { data } = useGetAdminConfigurationQuery({ type: "vendor" });

  if (!data || !data?.data) return null;

  const {
    data: { payload },
  } = data;

  const currentConfiguration = getConfigurationFields(
    VendorAdminConfigurationFields,
    payload
  );

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="vendor"
      hasPermission={adminSettingsVendorPermit}
    />
  );
};
