import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FileManager } from "modules/fileManager";
import { useGetVendorsQuery } from "modules/vendors";

import { Input } from "shared/components/Input";
import { InlineLabel } from "shared/components/widgets";
import { Select } from "shared/components/Select";
import { useSearch } from "shared/lib/hooks/useSearch";
import { getOptions } from "shared/lib/helpers";
import { useGetLocaleSettings } from "shared/lib/hooks";

export const WorkOrderOtherCostEditor = ({ form }) => {
  const { t } = useTranslation();
  const { currency } = useGetLocaleSettings();

  const [vendors = { data: [] }, onVendorsSearch] = useSearch(
    useGetVendorsQuery,
    true
  );

  const vendorsOptions = getOptions(vendors);

  return (
    <Container>
      <InlineLabel
        label={t("work_order_other_costs.form.title")}
        labelWidth={110}
      >
        <Input
          name="title"
          value={form.values.title}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("work_order_other_costs.form.title")}
          errorMessage={form.touched.title && form.errors.title}
          height={40}
          fontSize={14}
          required
        />
      </InlineLabel>
      <InlineLabel
        label={t("work_order_other_costs.form.note")}
        labelWidth={110}
      >
        <Input
          name="note"
          value={form.values.note}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("work_order_other_costs.form.note")}
          errorMessage={form.touched.note && form.errors.note}
          isTextArea
        />
      </InlineLabel>
      <InlineLabel
        label={t("work_order_other_costs.form.vendor")}
        labelWidth={110}
      >
        <Select
          options={vendorsOptions}
          name="vendor"
          value={form.values.vendor}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          noOptionsMessage={t("noOptions")}
          errorMessage={form.touched.vendor && form.errors.vendor}
          onSearch={onVendorsSearch}
          onTouch={form.handleSetTouched}
        />
      </InlineLabel>
      <InlineLabel
        label={t("work_order_other_costs.form.cost")}
        labelWidth={110}
      >
        <Input
          name="cost"
          value={form.values.cost}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          onKeyDown={(e) => e.key === "e" && e.preventDefault()}
          placeholder={t("work_order_other_costs.form.cost")}
          errorMessage={form.touched.cost && form.errors.cost}
          height={40}
          fontSize={14}
          endAddon={currency}
          type="number"
        />
      </InlineLabel>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
        allowedNumberOfFiles={3}
        imageListClassMame="imageList"
      />
    </Container>
  );
};

const Container = styled.div`
  font-weight: 500;
`;

const WrapperFileManager = styled(FileManager)`
  margin: 30px 0;

  & .imageList {
    gap: 4px;
    flex-wrap: nowrap;

    & > div,
    & > button {
      width: 148px;
      height: 148px;
    }
  }
`;
