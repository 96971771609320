import { Divider } from "shared/components/Divider";
import { IconButton } from "shared/components/IconButton";
import { Description } from "shared/components/Typography";
import styled from "styled-components";

export const ListContainer = styled.div``;

export const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const NoteButton = styled(IconButton)`
  margin-right: 15px;
`;

export const EditButton = styled(IconButton)`
  & > svg > path {
    fill: grey;
  }
`;

export const Transaction = styled(Description)`
  color: ${({ color }) => `var(--color_${color})`};
  font-weight: "600";
  margin-bottom: 5px;
`;

export const Quantity = styled(Description)`
  color: ${({ color }) => `var(--color_${color})`};
  margin-bottom: 0;
`;
