import React from "react";
import { useTranslation } from "react-i18next";

import { Deletion } from "features/Deletion";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { HeaderContextMenu } from "shared/components/Modals";

import {
  useDeletePMMutation,
  useUpdatePMMutation,
  usePrintPMsMutation,
} from "modules/workOrders";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";

import PlannedIcon from "../../assets/icons/planned.svg?react";
import PausedIcon from "../../assets/icons/paused.svg?react";
import CompletedIcon from "../../assets/icons/completed.svg?react";
import { ButtonEdit, Statuses, Box, StyledButton } from "./PMHeader.styled";
import { BulkPdfExport } from "shared/components/BulkPdfExport";
import PrintPMsPdfExample from "../../assets/PrintPMsPdfExample.svg?react";
import { PMsPdfPage } from "../PMsPdfPage";
import { ActivateComponent } from "./ActivateComponent";
import { ContextMenuEntry } from "shared/components/ContextMenu";
import { useFlags } from "launchdarkly-react-client-sdk";

const StatusMap = [
  {
    value: "planned",
    icon: <PlannedIcon />,
    color: "corp_lime",
  },
  {
    value: "paused",
    icon: <PausedIcon />,
    color: "grey200",
  },
];

export const PMHeader = ({
  id,
  onEdit,
  name,
  onClose,
  onClone,
  type,
  status,
  hasUncompletedWo,
  permissions,
  lastActiveWOId,
}) => {
  const { t } = useTranslation();
  const { enablePmCloning } = useFlags();
  const { addSnackBar } = useSnackBar();

  const [deletePM] = useDeletePMMutation();
  const [updatePM] = useUpdatePMMutation();
  const [printPMs] = usePrintPMsMutation();

  const onDeleteError = (message) => {
    if (message.base?.[0] === RESPONSE_MESSAGE.pm_is_linked) {
      addSnackBar({
        title: t("preventive_maintenances.deletion.error.title"),
        content: t("preventive_maintenances.deletion.error.content"),
        type: "warning",
        secondaryButtonLabel: t("close"),
      });
    }
  };

  const onSelectHandler = (status) => {
    updatePM({
      id,
      body: { status },
    })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("preventive_maintenances.snack.failUpdateTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const { edit, pdf, delete: pmDelete } = permissions;

  return (
    <>
      {edit && (
        <ActivateComponent
          id={id}
          lastActiveWOId={lastActiveWOId}
          status={status}
          hasUncompletedWo={hasUncompletedWo}
        />
      )}
      <Statuses>
        {permissions.toggleStatus && (
          <>
            {type === "one_time" && status === "completed" ? (
              <StyledButton
                variant="secondary"
                color="grey10"
                size="small"
                onClick={() => onSelectHandler("completed")}
                icon={<CompletedIcon />}
                isSelected
              >
                {t(`preventive_maintenances.status.completed`)}
              </StyledButton>
            ) : (
              <>
                {StatusMap.map((item) => {
                  const { value, icon, color } = item;
                  return (
                    <StyledButton
                      key={value}
                      variant="secondary"
                      size="small"
                      icon={icon}
                      color={color}
                      onClick={() => onSelectHandler(value)}
                      isSelected={status === value}
                      disabled={type === "one_time" && hasUncompletedWo}
                    >
                      {t(`preventive_maintenances.status.${value}`)}
                    </StyledButton>
                  );
                })}
              </>
            )}
          </>
        )}
      </Statuses>
      <Box>
        <div>
          <ButtonEdit
            variant="secondary"
            onClick={() => onEdit(id)}
            disabled={!edit}
          >
            {t("edit")}
          </ButtonEdit>
        </div>
        <HeaderContextMenu>
          {pdf && (
            <BulkPdfExport
              ids={id}
              type="link"
              title={t("printPMs.confirmation.singleTitle")}
              resourceName="PMs"
              getExportResources={printPMs}
              PdfExampleSvg={PrintPMsPdfExample}
              PageComponent={PMsPdfPage}
            />
          )}
          {enablePmCloning && (
            <ContextMenuEntry onClick={onClone}>
              {t("preventive_maintenances.clone.action")}
            </ContextMenuEntry>
          )}
          {pmDelete && (
            <Deletion
              id={id}
              module={name}
              onSuccess={onClose}
              confirmation={{
                title: t("preventive_maintenances.deletion.confirmation.title"),
                description: t(
                  "preventive_maintenances.deletion.confirmation.description"
                ),
                confirmButtonLabel: t(
                  "preventive_maintenances.deletion.confirmation.confirm"
                ),
              }}
              onDelete={deletePM}
              onError={onDeleteError}
            />
          )}
        </HeaderContextMenu>
      </Box>
    </>
  );
};
