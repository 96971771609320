import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "shared/lib/hooks";

import ClearIcon from "shared/assets/icon/clear.svg?react";
import CheckIcon from "shared/assets/Check.svg?react";

import { Chip } from "shared/components/Chip";
import { MenuItem } from "shared/components/MenuItem";
import { MenuList } from "shared/components/MenuList";

import {
  useFloatingDropdown,
  useMergeRefs,
} from "shared/lib/hooks/useFloatingDropdown";

import {
  Container,
  Description,
  DropDownWrapper,
  ErrorMessage,
  // Header,
  // Placeholder,
  RemoveButton,
  SearchIconStyled,
  CleareIconStyled,
  SelectedOptionsWrapper,
  HeaderMenu,
} from "./Dropdowns.styled";

export const MultiSelectDropdown = ({
  name = null,
  value = [],
  setValue = () => null,
  options = [],
  defaultOptions = [],
  onClick = () => null,
  onSearch = () => null,
  placeholder = "",
  description = "",
  errorMessage = "",
  minSearchChar = 3,
  width = null,
  withSearch = true,
  withClear = true,
  chipWidth,
}) => {
  const ref = useRef();
  const closeRef = useRef();
  const optionsMapRef = useRef(new Map());
  const selectedOptionsRef = useRef([]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hiddenOptions, setHiddenOptions] = useState([]);

  const { refs, floatingStyles } = useFloatingDropdown({
    isOpen,
    setIsOpen,
    itemCount: options.length,
  });

  const onSetValue = (val) => (name ? setValue(name, val) : setValue(val));

  useEffect(() => {
    if (selectedOptionsRef.current !== value) {
      setSelectedOptions(value || []);
      selectedOptionsRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    options.forEach((el) => optionsMapRef.current.set(el.key, el.value));
    defaultOptions.forEach((el) => optionsMapRef.current.set(el.key, el.value));
    optionsMapRef.current = new Map(optionsMapRef.current);
  }, [options, defaultOptions]);

  useEffect(() => {
    const container = ref.current;
    const containerWidth = container.offsetWidth - 95;
    let curentWith = 0;
    let hiddenTagsList = [];
    const tagsList = [...container.querySelectorAll(".tag")];
    tagsList.forEach((tag, index) => {
      if (!tag.offsetWidth || curentWith + tag.offsetWidth > containerWidth) {
        hiddenTagsList = [...hiddenTagsList, index];
      }
      curentWith = curentWith + tag.offsetWidth + 20;
    });

    if (hiddenTagsList.length === selectedOptions.length) {
      hiddenTagsList.shift();
    }

    setHiddenOptions(hiddenTagsList);
  }, [selectedOptions]);

  const notShowOption = selectedOptions.filter(
    (sOpt) => !options.some((opt) => opt.key === sOpt)
  );

  const [searchText, setSearchText] = useState("");
  const debounceSearch = useDebounce(searchText);

  useEffect(() => {
    const isValid = !debounceSearch || debounceSearch.length >= minSearchChar;
    if (isOpen && isValid) {
      onSearch(debounceSearch);
    }
  }, [debounceSearch, minSearchChar, isOpen]);

  useEffect(() => {
    // Reset the search text when the dropdown is unmounted
    return () => onSearch();
  }, [onSearch]);

  const onClickHandler = (e) => {
    onClick();
    if (!(closeRef.current && closeRef.current.contains(e.target))) {
      setIsOpen(!isOpen);
    }
  };

  const onBlurHandler = (e) => {
    if (!ref.current.contains(e.relatedTarget)) {
      setIsOpen(false);
      setSearchText("");
      onSearch();
    }
  };

  const onDeleteTagHandler = (key) => {
    const currentValue = selectedOptions.filter((val) => val !== key);
    selectedOptionsRef.current = currentValue;
    setSelectedOptions(currentValue);
    onSetValue(currentValue);
  };

  const onClearHandler = (e) => {
    onSetValue([]);
    selectedOptionsRef.current = [];
    setSelectedOptions([]);
  };

  const onSelectHandler = (key) => {
    const checked = selectedOptions.includes(key);
    const currentValue = !checked
      ? [...selectedOptions, key]
      : selectedOptions.filter((val) => val !== key);
    selectedOptionsRef.current = currentValue;
    setSelectedOptions(currentValue);
    return onSetValue(currentValue);
  };

  return (
    <Container
      ref={useMergeRefs([ref, refs.setReference])}
      onBlur={onBlurHandler}
      width={width}
      tabIndex={0}
    >
      <DropDownWrapper isOpen={isOpen} onClick={onClickHandler}>
        <DropDownWrapper isOpen={isOpen} onClick={onClickHandler}>
          <SearchIconStyled />
          <HeaderMenu
            value={isOpen ? searchText : ""}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={!selectedOptions.length ? placeholder : undefined}
            readOnly={!isOpen}
            withSearch={withSearch}
            withClear={withClear}
          />
          <SelectedOptionsWrapper visible={(isOpen && !searchText) || !isOpen}>
            {selectedOptions.map((key, index) => (
              <Chip
                className={`tag ${
                  hiddenOptions.includes(index) ? "hidden" : ""
                }`}
                width={chipWidth}
                key={key}
                label={optionsMapRef.current.get(key)}
                endIcon={
                  <RemoveButton onClick={() => onDeleteTagHandler(key)}>
                    <ClearIcon />
                  </RemoveButton>
                }
              />
            ))}
            {hiddenOptions.length > 0 && (
              <span className="tag-counter">+{hiddenOptions.length}</span>
            )}
          </SelectedOptionsWrapper>
          {!!selectedOptions.length && (
            <CleareIconStyled ref={closeRef} onClick={onClearHandler} />
          )}
        </DropDownWrapper>
      </DropDownWrapper>
      <MenuList
        isOpen={isOpen}
        category={["Selected"]}
        ref={refs.setFloating}
        style={floatingStyles}
      >
        {!!notShowOption.length &&
          notShowOption.map((hiddenOptId) => (
            <MenuItem
              categoty="Selected"
              key={hiddenOptId}
              value={hiddenOptId}
              label={optionsMapRef.current.get(hiddenOptId)}
              selected={true}
              onClick={onSelectHandler}
              startIcon={<CheckIcon />}
            />
          ))}
        {options.map((opt) => (
          <MenuItem
            key={opt.key}
            value={opt.key}
            label={opt.value}
            selected={selectedOptions.includes(opt.key)}
            onClick={onSelectHandler}
            startIcon={selectedOptions.includes(opt.key) && <CheckIcon />}
          />
        ))}
      </MenuList>
      {description && <Description>{description}</Description>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

MultiSelectDropdown.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  setValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string,
    })
  ),
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string,
    })
  ),
  onSeacrhOptions: PropTypes.func,
  onClick: PropTypes.func,
  onSearch: PropTypes.func,
  minSearchChar: PropTypes.number,
  width: PropTypes.string,
  withSearch: PropTypes.bool,
  withClear: PropTypes.bool,
};
