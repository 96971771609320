import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useClick, useDismiss, useInteractions } from "@floating-ui/react";
import { useTranslation } from "react-i18next";

import { MenuItem } from "shared/components/MenuItem";
import CheckIcon from "shared/assets/Check.svg?react";
import ChevronIcon from "shared/assets/icon/chevron-down.svg?react";
import { useDebounce } from "shared/lib/hooks";
import {
  FilterButton,
  FilterCount,
} from "modules/filters/components/Filters.styled";
import {
  useFloatingDropdown,
  useMergeRefs,
} from "shared/lib/hooks/useFloatingDropdown";
import {
  ClearButton,
  DropdownHeader,
  FastFilterDropdown,
  FastFilterWrapper,
  HeaderLabel,
  MenuListStyled,
  NoOptionsPlaceholder,
  SearchIconStyled,
  SearchInput,
  SearchWrapper,
} from "./FastFilter.styled";

/**
 * A quick filter button/dropdown for the table TopBar
 *
 * @param {Object} props
 * @param {string} props.name
 * @param {{key: string | number, value: string}[]} props.options
 * @param {string[]} props.value
 * @param {(val: string[]) => void} props.onChange
 * @param {(val: string[]) => void} props.onSearch
 * @param {() => void} props.onReset
 */
export const FastFilter = ({
  name,
  options = [],
  value,
  onChange,
  onSearch,
  onReset,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const debounceSearch = useDebounce(searchText);

  const { refs, floatingStyles, context } = useFloatingDropdown({
    isOpen,
    setIsOpen,
    itemCount: options.length,
    placement: "bottom-start",
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
  ]);

  const toggleOption = (key) => {
    const options = value.slice();
    const optIndex = options.indexOf(key);
    if (optIndex === -1) {
      options.push(key);
    } else {
      options.splice(optIndex, 1);
    }
    onChange(options);
  };

  useEffect(() => {
    if (typeof onSearch === "function") {
      onSearch(debounceSearch);
    }
  }, [debounceSearch, onSearch]);

  useEffect(() => {
    if (!isOpen && onSearch) {
      setSearchText("");
      onSearch();
    }
  }, [isOpen, onSearch]);

  return (
    <FastFilterWrapper>
      <FilterButton
        type="button"
        isActive={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
        ref={useMergeRefs([buttonRef, refs.setReference])}
        {...getReferenceProps()}
      >
        <span>{name}</span>
        {value.length > 0 && <FilterCount>{value.length}</FilterCount>}
        <ChevronIcon />
      </FilterButton>

      {isOpen && (
        <FastFilterDropdown
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <DropdownHeader>
            <HeaderLabel>{name}</HeaderLabel>
            <ClearButton onClick={onReset} disabled={!value.length}>
              {t("filters.buttons.clearAll")}
            </ClearButton>
          </DropdownHeader>
          {typeof onSearch === "function" && (
            <SearchWrapper>
              <SearchIconStyled />
              <SearchInput
                type="search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={t("searchPlaceholder")}
              />
            </SearchWrapper>
          )}
          <MenuListStyled isOpen={true}>
            {options.length === 0 && (
              <NoOptionsPlaceholder>{t("noOptions")}</NoOptionsPlaceholder>
            )}
            {options.map((opt) => (
              <MenuItem
                key={opt.key}
                value={opt.key}
                label={opt.value}
                selected={value.includes(opt.key)}
                onClick={() => toggleOption(opt.key)}
                startIcon={value.includes(opt.key) && <CheckIcon />}
              />
            ))}
          </MenuListStyled>
        </FastFilterDropdown>
      )}
    </FastFilterWrapper>
  );
};

const OptionKey = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
FastFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(OptionKey),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: OptionKey,
      value: PropTypes.string,
    })
  ),
};
