import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Description, H2, Hint } from "shared/components/Typography";

export const ViewHeader = ({ id, name, description }) => {
  return (
    <>
      <Hint># {id}</Hint>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </>
  );
};

ViewHeader.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
};

const Name = styled(H2)`
  margin-bottom: 20px;
`;
