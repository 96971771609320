import * as Yup from "yup";
import {
  getSelectSchema,
  getStringSchema,
  getUrlSchema,
  getMultiSelectSchema,
  getCostSchema,
  getDescriptionSchema,
  getNameSchema,
} from "shared/lib/helpers/validation";

const getPositiveNumberSchema = (t, field = { required: false }) =>
  Yup.number()
    .min(0, t("validation.positive"))
    .test("Required test", "Required", function (value, testContext) {
      if (field.required) {
        return value && value > 0
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

const getMinQtyValue = (t) =>
  Yup.number().test(
    "test Min_qty",
    t("m179", { ns: "errors" }),
    (value, testContext) => {
      if (value > testContext.parent.desired_qty) {
        return testContext.createError({
          path: `${testContext.path}`,
          message: t("m179", { ns: "errors" }),
        });
      }

      return true;
    }
  );

const getDesireValue = (t) =>
  Yup.number().test(
    "test Max_qty",
    t("m178", { ns: "errors" }),
    (value, testContext) => {
      if (value < testContext.parent.min_qty) {
        return testContext.createError({
          path: `${testContext.path}`,
          message: t("m178", { ns: "errors" }),
        });
      }

      return true;
    }
  );
const getVendorsSchema = (t, field) =>
  Yup.array(
    Yup.object({
      vendor: Yup.object(),
      vendor_part_number: Yup.string()
        .nullable()
        .max(255, t("validation.stringMaxLength", { stringMaxLength: 255 })),
      is_preferred: Yup.boolean(),
    })
  ).test("Required test", "Required", (value, testContext) => {
    if (field.required) {
      return value && value.length > 0
        ? true
        : testContext.createError({
            path: `${testContext.path}`,
            message: t("validation.required"),
          });
    }
    return true;
  });

export const partValidationSchema = (t, optionalFields) => {
  const AddPartFormDefaultSchema = {
    name: getNameSchema(t, { required: true }),
    description: getDescriptionSchema(t),
    is_non_stock: Yup.boolean().required(),
    on_hand_qty: getPositiveNumberSchema(t),
    min_qty: Yup.number()
      .concat(getPositiveNumberSchema(t))
      .concat(getMinQtyValue(t)),
    desired_qty: Yup.number()
      .concat(getPositiveNumberSchema(t))
      .concat(getDesireValue(t)),
    area: Yup.string(),
    code: Yup.string(),
    measurement_unit: Yup.object().nullable(),
    storeroom: Yup.object().nullable(),
    assets: Yup.array(),
    vendors: Yup.array(),
    uploads_attributes: Yup.array(),
    part_type: Yup.object().nullable(),
  };

  const schema = Object.keys(optionalFields).reduce(
    (updatedSchema, fieldKey) => {
      const field = optionalFields[fieldKey];
      if (fieldKey === "assets_ids") {
        updatedSchema["assets"] = getMultiSelectSchema(t, field);
      } else if (fieldKey === "vendors_ids") {
        updatedSchema["vendors"] = getVendorsSchema(t, field);
      } else if (fieldKey === "number") {
        updatedSchema[fieldKey] = getStringSchema(t, { ...field, max: 255 });
      } else if (fieldKey === "urls_attributes") {
        updatedSchema[fieldKey] = getUrlSchema(t, field);
      } else if (fieldKey === "part_type_id") {
        updatedSchema["part_type"] = getSelectSchema(t, field);
      } else if (fieldKey === "lead_time") {
        updatedSchema["lead_time"] = Yup.number().min(
          0,
          t("validation.positive")
        );
      } else {
        updatedSchema[fieldKey] = getPositiveNumberSchema(t, field);
      }
      return updatedSchema;
    },
    AddPartFormDefaultSchema
  );

  return Yup.object(schema);
};

export const costValidationSchema = (t) =>
  Yup.object({
    unit_cost: getCostSchema(t).nullable(),
    quantity: Yup.number()
      .min(0, t("validation.positive"))
      .max(9999, t("validation.maxNumber", { limit: 9999 })),
    note: getDescriptionSchema(t),
    uploads_attributes: Yup.array().max(3),
  });
