import { useTranslation } from "react-i18next";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { Pagination } from "features/Pagination";
import { OntimeVsOverdueTableData } from "./types";
import { PaginationData } from "types/Response";

const reportKey = ReportKey.OntimeVsOverdue;

interface OntimeVsOverdueReportTableProps {
  data?: OntimeVsOverdueTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const OntimeVsOverdueReportTable = ({
  data,
  paginationData,
  isFetching,
}: OntimeVsOverdueReportTableProps) => {
  const { t } = useTranslation();
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  return (
    <>
      <Table
        columns={columns}
        rows={data}
        getRowKey={(row) => row.assignee}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(): TableColumn<OntimeVsOverdueTableData>[] {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return [
    {
      field: "assignee",
      name: t(`reporting.${reportKey}.columns.assignee`),
      minWidth: 200,
      sortable: true,
    },
    {
      field: "on_time",
      name: t(`reporting.${reportKey}.columns.on_time`),
      minWidth: 120,
      type: "number",
      sortable: true,
      renderCell: (row) =>
        `${formatNumber(row.on_time, language)} (${formatPercent(
          row.on_time_percentage,
          language
        )})`,
    },
    {
      field: "no_due_date",
      name: t(`reporting.${reportKey}.columns.no_due_date`),
      minWidth: 120,
      type: "number",
      sortable: true,
      renderCell: (row) =>
        `${formatNumber(row.no_due_date, language)} (${formatPercent(
          row.no_due_date_percentage,
          language
        )})`,
    },
    {
      field: "overdue",
      name: t(`reporting.${reportKey}.columns.overdue`),
      minWidth: 120,
      type: "number",
      sortable: true,
      renderCell: (row) =>
        `${formatNumber(row.overdue, language)} (${formatPercent(
          row.overdue_percentage,
          language
        )})`,
    },
    {
      field: "reactive",
      name: t(`reporting.${reportKey}.columns.reactive`),
      minWidth: 120,
      type: "number",
      sortable: true,
      renderCell: (row) =>
        `${formatNumber(row.reactive, language)} (${formatPercent(
          row.reactive_percentage,
          language
        )})`,
    },
    {
      field: "preventive",
      name: t(`reporting.${reportKey}.columns.preventive`),
      minWidth: 120,
      type: "number",
      sortable: true,
      renderCell: (row) =>
        `${formatNumber(row.preventive, language)} (${formatPercent(
          row.preventive_percentage,
          language
        )})`,
    },
  ];
}
