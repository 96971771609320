import {
  Tabs as RACTabs,
  TabList as RACTabList,
  Tab,
  TabPanel,
} from "react-aria-components";
import styled from "styled-components";

const StyledTabs = styled(RACTabs)`
  padding-top: 10px;

  display: flex;
  color: var(--text-color);

  &[data-orientation="horizontal"] {
    flex-direction: column;
  }
`;

const StyledTabList = styled(RACTabList)`
  display: flex;
  border-bottom: 1px solid var(--color_grey100);
`;

const StyledTab = styled(Tab)`
  position: relative;
  padding: 4px 20px;
  font-size: 14px;
  color: var(--color_text_color);
  outline: none;
  cursor: pointer;

  &[href] {
    text-decoration: none;
  }

  &[data-selected] {
    box-shadow: inset 0 -2px 0 var(--color_caribbean_green);
  }

  &[data-focus-visible]:after {
    content: "";
    position: absolute;
    inset: 4px;
    border-radius: 4px;
    border: 2px solid var(--color_caribbean_green);
  }
`;

const StyledTabPanel = styled(TabPanel)`
  outline: none;

  &[data-focus-visible] {
    outline: 2px solid var(--color_caribbean_green);
  }
`;

/** https://react-spectrum.adobe.com/react-aria/Tabs.html */
export const Tabs = {
  Root: StyledTabs,
  TabList: StyledTabList,
  Tab: StyledTab,
  TabPanel: StyledTabPanel,
};
