import styled from "styled-components";

import { Checkbox } from "shared/components/Checkbox";

import { InlineLabel, InlineContainer } from "./ScheduleForm.styled";

export const RepeatsOn = ({
  name,
  parentName,
  value,
  setValue,
  disabled = false,
  options,
  label = "",
  columns,
  rows,
}) => {
  const onClick = (key) => {
    if (value && value.length === 1 && value.includes(key)) return;
    const newValue = value.includes(key)
      ? value.filter((v) => v !== key)
      : [...value, key];
    setValue(`${parentName}.${name}`, newValue);
    setValue(`${parentName}.time_to_complete`, 1);
  };

  return (
    <InlineContainer>
      <InlineLabel>{label}</InlineLabel>
      <Box columns={columns} rows={rows}>
        {options.map(({ key, label }) => {
          return (
            <CheckboxContainer key={key}>
              <Checkbox
                checked={!!value.includes(key)}
                disabled={disabled}
                onClick={(e) => onClick(key, e.target.checked)}
              />
              <Text>{label}</Text>
            </CheckboxContainer>
          );
        })}
      </Box>
    </InlineContainer>
  );
};

const Box = styled.div`
  display: grid;
  gap: 10px;
  width: 290px;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-template-rows: ${({ rows }) => `repeat(${rows}, 1fr)`};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  padding-top: 5px;
`;
