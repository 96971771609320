import styled, { css } from "styled-components";
import { ButtonProps } from "shared/components/Button/Button";

const PrimaryStyle = css`
  background-color: var(--color_primary);
  color: var(--color_background);
  border: 1px solid var(--color_primary);
  &:hover {
    background: var(--color_primary_dark);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0px 0px 0px 2px var(--color_primary_dark);
    border: 1px solid var(--color_background);
  }
  &:disabled {
    background: var(--color_primary_20);
    border-color: var(--color_primary_dark_40);
    color: var(--color_primary_dark_40);
    box-shadow: none;
  }
`;

const PrimaryDarkStyle = css`
  background-color: var(--color_primary);
  color: var(--color_background);
  border: 1px solid var(--color_primary);
  &:hover {
    background: var(--color_primary_dark);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0px 0px 0px 2px var(--color_primary_dark);
    border: 1px solid var(--color_background);
  }
  &:disabled {
    background: var(--color_primary_20);
    border-color: var(--color_primary_dark_40);
    color: var(--color_primary_dark_40);
    box-shadow: none;
  }
`;

const SecondaryStyle = css`
  color: var(--color_primary_text);
  background-color: var(--color_background_light);
  border: 1px solid var(--color_grey100);
  &:hover {
    background: var(--color_primary_hover);
    border: 1px solid var(--color_primary_dark);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0px 0px 0px 2px var(--color_primary_dark);
    border: 1px solid var(--color_background_light);
  }
  &:disabled {
    background: var(--color_grey10);
    border-color: var(--color_grey100);
    color: var(--color_grey200);
    box-shadow: none;
  }
`;

const SecondaryDarkStyle = css`
  background-color: var(--color_background);
  color: var(--color_primary);
  border: 1px solid var(--color_primary);
  &:hover {
    background: var(--color_primary_hover);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0px 0px 0px 2px var(--color_primary_dark);
    border: 1px solid var(--color_background);
  }
  &:disabled {
    background: var(--color_grey10);
    border-color: var(--color_grey100);
    color: var(--color_grey200);
    box-shadow: none;
  }
`;

const TertiaryStyle = css`
  color: var(--color_primary_text);
  background-color: var(--color_background_light);
  border: 1px solid transparent;
  &:hover {
    color: var(--color_dark_blue);
    background: var(--color_primary_hover);
    border: 1px solid var(--color_primary_dark);
  }
  &:focus {
    color: var(--color_dark_blue);
    background: var(--color_primary_hover);
    box-shadow: 0px 0px 0px 2px var(--color_primary_dark);
    border: 1px solid var(--color_background_light);
  }
  &:active {
    color: var(--color_dark_blue);
    background: var(--color_primary_dark);
    box-shadow: 0px 0px 0px 2px var(--color_primary_dark);
    border: 1px solid var(--color_background_light);
  }
  &:disabled {
    background: var(--color_grey10);
    border-color: var(--color_grey100);
    color: var(--color_grey200);
    box-shadow: none;
  }
`;

const DangerStyle = css`
  color: var(--color_header_title_color);
  background-color: var(--color_corp_red);
  border: 1px solid transparent;
  &:hover {
    color: var(--color_dark_blue);
    background: var(--color_corp_red_dark);
    border: 1px solid var(--color_corp_red_dark);
  }
  &:focus {
    color: var(--color_dark_blue);
    background: var(--color_corp_red_dark);
    box-shadow: 0px 0px 0px 2px var(--color_corp_red_dark);
    border: 1px solid var(--color_background_light);
  }
  &:active {
    color: var(--color_dark_blue);
    background: var(--color_corp_red_dark);
    box-shadow: 0px 0px 0px 2px var(--color_corp_red_dark);
    border: 1px solid var(--color_corp_red_dark);
  }
  &:disabled {
    background: var(--color_grey10);
    border-color: var(--color_grey100);
    color: var(--color_grey200);
    box-shadow: none;
  }
`;

const DangerTransparentStyle = css`
  color: var(--color_corp_red_dark);
  background-color: var(--color_background_light);
  border: 1px solid var(--color_corp_red_dark);
  &:hover {
    color: var(--color_dark_blue);
    background: var(--color_corp_red_dark);
    border: 1px solid var(--color_corp_red_dark);
  }
  &:focus {
    color: var(--color_dark_blue);
    background: var(--color_corp_red_dark);
    box-shadow: 0px 0px 0px 2px var(--color_corp_red_dark);
    border: 1px solid var(--color_background_light);
  }
  &:active {
    color: var(--color_dark_blue);
    background: var(--color_corp_red_dark);
    box-shadow: 0px 0px 0px 2px var(--color_corp_red_dark);
    border: 1px solid var(--color_corp_red_dark);
  }
  &:disabled {
    background: var(--color_grey10);
    border-color: var(--color_grey100);
    color: var(--color_grey200);
    box-shadow: none;
  }
`;

const NormalSizeStyle = css`
  gap: 6px;
  padding: 12px;
`;

const SmallSizeStyle = css`
  padding: 4px 8px;
`;

export const Box = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 4px;

  ${({ variant }) => (!variant || variant === "primary") && PrimaryStyle}
  ${({ variant }) => variant === "primaryDark" && PrimaryDarkStyle}
  ${({ variant }) => variant === "secondary" && SecondaryStyle}
  ${({ variant }) => variant === "secondaryDark" && SecondaryDarkStyle}
  ${({ variant }) => variant === "tertiary" && TertiaryStyle}
  ${({ variant }) => variant === "danger" && DangerStyle}
  ${({ variant }) => variant === "dangerTransparent" && DangerTransparentStyle}

  ${({ size }) => size === "normal" && NormalSizeStyle}
  ${({ size }) => size === "small" && SmallSizeStyle}

  ${({ icon }) =>
    !!icon &&
    css`
      padding-right: 23px;
      padding-left: 23px;
    `}

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonSpinner = styled.div`
  --button-spinner-stroke: 2px;
  --button-spinner-size: 1lh;

  box-sizing: border-box;
  width: var(--button-spinner-size);
  height: var(--button-spinner-size);

  border-width: var(--button-spinner-stroke);
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;

  border-radius: 50%;
  animation: spin 2s linear infinite;
`;
