import styled from "styled-components";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { Modal, ModalHeader, CloseButton } from "shared/components/Modals";
import { ScrollFadeBox } from "shared/components/ScrollFadeBox";
import { ModalContentWrapper } from "shared/components/Modals/components/Modals.styled";
import { WOList } from "shared/components/WOList/WOList";

export const CalendarDateEvents = ({
  date,
  events = [],
  onClose,
  onOpenWorkOrder,
  onOpenPM,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalContentWrapper>
        <ModalHeader
          label={format(date, "EEEE, MMMM d, yyyy")}
          action={<CloseButton onClick={onClose} background="light" />}
          variant="light"
        />
        <Total>{events.length + t("work_orders.calendar.total")}</Total>
        <ScrollFadeBox disableTop>
          <WOList
            events={events}
            onOpenWorkOrder={onOpenWorkOrder}
            onOpenPM={onOpenPM}
            date={date}
          />
        </ScrollFadeBox>
      </ModalContentWrapper>
    </Modal>
  );
};

const Total = styled.p`
  margin: 32px 16px 0px 16px;
  padding-bottom: 16px;
  line-height: 20px;
  font-size: 14px;
  color: rgba(51, 50, 56, 1);
  border-bottom: 1px solid rgba(220, 220, 222, 1);
`;
