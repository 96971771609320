import { useState, useEffect } from "react";

/**
 * `useState` with localStorage persistence
 * @param key - localStorage key
 * @param defaultValue - The value to use if the key is not found in
 *    localStorage, or the stored value can't be parsed
 */
export const usePersistentState = <T,>(
  key: string,
  defaultValue: T
): [T, (val: T) => void] => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key) ?? "";
    return safeParse(storedValue, defaultValue);
  });

  // Keep localStorage up-to-date with changes to the state
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

/**
 * Parse a JSON string, returning a default value if the string is invalid
 * @param value - The JSON string to parse
 * @param defaultValue - The value to return if the string is invalid
 */
function safeParse<T>(value: string, defaultValue: T) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
}
