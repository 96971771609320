import React, { useState } from "react";
import { Header } from "../Header";

import { ForgotPasswordEmailForm } from "./ForgotPasswordEmailForm";
import { ForgotPasswordEmailSent } from "./ForgotPasswordEmailSent";

const map = {
  0: (setTabs) => <ForgotPasswordEmailForm setTabs={setTabs} />,
  1: (setTabs) => <ForgotPasswordEmailSent setTabs={setTabs} />,
};

export const ForgotPassword = () => {
  const [tabs, setTabs] = useState(0);

  return (
    <>
      <Header showAction />
      {map[tabs](setTabs)}
    </>
  );
};
