import { pdf } from "@react-pdf/renderer";
import { VendorsList } from "shared/components/VendorsList";
import { LinksList } from "shared/components/LinksList";

import { WorkOrderRequest } from "../../components/WorkOrderView/WorkOrderRequest";
import { ModalLink } from "shared/components/ModalLink";
import { LocationPath } from "shared/components/LocationPath";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { getTimePeriod } from "modules/downtimeTracking";
import { formatISO } from "date-fns";
import {
  InlineAssetStatusBadge,
  InlineDetailsValueContainer,
} from "./helpers.styled";

export const getViewRows = (
  fields,
  data,
  isFieldVisible,
  t,
  displayDate,
  downtimeTrackingEnabled
) => {
  return fields.reduce((visibleRows, field) => {
    if (field === "schedule" && data.type === "preventive") {
      visibleRows.push({
        label: t("work_orders.details.schedule"),
        value: (
          <ModalLink modalParams={{ type: "pm", id: data.pm_schedule_id }}>
            PM #{data.pm_schedule_id}
          </ModalLink>
        ),
      });
      return visibleRows;
    }

    if (!isFieldVisible(field)) {
      return visibleRows;
    }

    const value = data[field];

    if (field === "request" && data.work_order_request) {
      visibleRows.push({
        label: t(`work_orders.details.request`),
        value: (
          <ModalLink
            modalParams={{ type: "request", id: data.work_order_request.id }}
          >
            {t(`work_orders.details.request_num`, {
              id: data.work_order_request.id,
            })}
          </ModalLink>
        ),
      });

      visibleRows.push({
        label: t(`work_orders.details.requested_by`),
        value: <WorkOrderRequest data={data.work_order_request} />,
      });
      return visibleRows;
    }

    if (field === "created_by" && data.type === "reactive") {
      visibleRows.push({
        label: t("work_orders.details.created_by"),
        value: `${
          data.created_by?.name ? data.created_by?.name : ""
        } on ${displayDate(data.created_at, true)}`,
      });
      return visibleRows;
    }

    if (
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      // field === "assign_to" &&
      // (isFieldVisible("teams") || isFieldVisible("technicians"))
      field === "assign_to" &&
      isFieldVisible("teams")
    ) {
      visibleRows.push({
        label: t("work_orders.details.assign_to"),
        value: <UserAvatarGroup users={data.technicians} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "type") {
      visibleRows.push({
        label: t("work_orders.details.type"),
        value: value && t(`work_orders.type.${value}`),
      });
      return visibleRows;
    }

    if (field === "categories") {
      visibleRows.push({
        label: t("work_orders.details.categories"),
        value: value.map(({ name }) => name).join(", "),
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "location") {
      visibleRows.push({
        label: t("work_orders.details.location"),
        value: <LocationPath location={value} withTrim={false} />,
      });
      return visibleRows;
    }

    if (field === "vendors") {
      visibleRows.push({
        label: t("work_orders.details.vendors"),
        value: <VendorsList vendors={value} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "due_date") {
      visibleRows.push({
        label: t("work_orders.details.due_date"),
        value: value && displayDate(value, true),
      });
      return visibleRows;
    }

    if (field === "asset") {
      visibleRows.push({
        label: t("work_orders.details.asset"),
        value: value ? (
          <InlineDetailsValueContainer>
            <ModalLink modalParams={{ type: "asset", id: value.id }}>
              {value.name}
            </ModalLink>
            {downtimeTrackingEnabled ? (
              <InlineAssetStatusBadge status={value.status} />
            ) : null}
          </InlineDetailsValueContainer>
        ) : null,
      });
      return visibleRows;
    }

    if (field === "downtime_tracking") {
      const newValue = value
        ? value.ended_at
          ? `Completed: ${
              getTimePeriod(value.started_at, value.ended_at) || "<1m"
            }`
          : `Ongoing: ${
              getTimePeriod(value.started_at, formatISO(new Date())) || "<1m"
            }`
        : null;

      visibleRows.push({
        label: t("work_orders.details.downtime_tracking"),
        value: newValue,
      });
      return visibleRows;
    }

    if (Array.isArray(value) && value.length > 0) {
      const links = value.map(({ name, id }) => ({
        label: name,
        url: `/${field}/${id}`,
      }));

      visibleRows.push({
        label: t(`work_orders.details.${field}`),
        value: <LinksList links={links} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (typeof value === "string" || typeof value === "number") {
      visibleRows.push({ label: t(`work_orders.details.${field}`), value });
    }

    if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      field !== "created_by"
    ) {
      visibleRows.push({
        label: t(`work_orders.details.${field}`),
        value: value?.name,
      });
    }

    return visibleRows;
  }, []);
};

export const getDueDate = (dueDate, isOverdue, isComplete, displayDate) => {
  const dueTimeValue = `: Due ${displayDate(dueDate, true)}`;
  if (isComplete) {
    return dueTimeValue;
  }

  let leftTimeValue;
  if (isOverdue) {
    leftTimeValue = "0m left";
  } else {
    const currentDueDate = displayDate(dueDate, true, false);

    const currentDate = displayDate(new Date(), true, false);
    const timeDifference = currentDueDate - currentDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    leftTimeValue = `${days}d ${hours}h ${minutes}m left`;
  }
  return `${dueTimeValue} - ${leftTimeValue}`;
};

export const getOverdueDate = (dueDate) => {
  return `Overdue since  ${dueDate}`;
};

export const getStatusesOptions = (
  map,
  data,
  statusConfiguration,
  woChangeCompleteStatusPermit
) => {
  if (!data || !statusConfiguration) return [];
  const options = map.map((opt) => {
    const id = data.data?.find((st) => st.name === opt.value)?.id;
    return { ...opt, key: id };
  });
  const isShowReview = !!statusConfiguration.data.payload?.in_review?.required;
  let optionsData;
  if (isShowReview) {
    optionsData = woChangeCompleteStatusPermit
      ? options
      : options.filter((status) => status.value !== "completed");
  } else {
    optionsData = options.filter((status) => status.value !== "in_review");
  }
  return optionsData;
};

export const downloadFile = async (doc) => {
  const asPdf = pdf([]);
  asPdf.updateContainer(doc);
  const pdfBlob = await asPdf.toBlob();
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "work_order.pdf";
  link.click();
};

export { getHistoryContent } from "./getHistoryContent";
