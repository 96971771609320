import { useTranslation } from "react-i18next";
import {
  formatUnitDuration,
  formatNumber,
  formatPercent,
} from "modules/reporting/lib/formatters";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { Status } from "shared/components/Status";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { BacklogByStatusTableData } from "./types";

const reportKey = ReportKey.BacklogByStatus;
const statusOrder: Record<string, number> = {
  open: 0,
  on_hold: 1,
  in_progress: 2,
  in_review: 3,
  waiting_for_parts: 4,
  waiting_for_cost: 5,
  waiting_for_invoice: 6,
};

interface BacklogByStatusReportTableProps {
  data?: BacklogByStatusTableData[];
  isFetching: boolean;
}

export const BacklogByStatusReportTable = ({
  data,
  isFetching,
}: BacklogByStatusReportTableProps) => {
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  const tableData = data
    ?.map((i) => ({ ...i, id: i.status }))
    .sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
  return (
    <Table
      columns={columns}
      rows={tableData}
      showSkeleton={isFetching}
      notFound={!isFetching && !data}
    />
  );
};

function useTableColumns(): TableColumn<BacklogByStatusTableData>[] {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return [
    {
      field: "status",
      name: t(`reporting.${reportKey}.columns.status`),
      minWidth: 200,
      renderCell: (row) => <Status value={row.status} />,
    },
    {
      field: "amount",
      name: t(`reporting.${reportKey}.columns.amount`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatNumber(val, language),
    },
    {
      field: "amount_percentage",
      name: t(`reporting.${reportKey}.columns.amount_percentage`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatNumber(val, language, { style: "percent" }),
    },
    {
      field: "overdue",
      name: t(`reporting.${reportKey}.columns.overdue`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatNumber(val, language),
    },
    {
      field: "avg_age",
      name: t(`reporting.${reportKey}.columns.avg_age`),
      minWidth: 120,
      type: "number",
      formatValue: (val) =>
        formatUnitDuration(val, "days", language, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
    },
    {
      field: "reactive",
      name: t(`reporting.${reportKey}.columns.reactive`),
      minWidth: 120,
      type: "number",
      renderCell: (row) =>
        `${formatNumber(row.reactive, language)} (${formatPercent(
          row.reactive_percentage,
          language
        )})`,
    },
    {
      field: "preventive",
      name: t(`reporting.${reportKey}.columns.preventive`),
      minWidth: 120,
      type: "number",
      renderCell: (row) =>
        `${formatNumber(row.preventive, language)} (${formatPercent(
          row.preventive_percentage,
          language
        )})`,
    },
  ];
}
