import { Switch } from "shared/components/Switch";
import { ContextualHelp } from "shared/components/ContextualHelp";
import { Input } from "shared/components/Input";
import { InlineLabel } from "shared/components/widgets";
import { H6 } from "shared/components/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getUser } from "shared/components/Auth";
import { useEffect, useMemo } from "react";
import { Divider } from "shared/components/Divider";

const fields = ["regular_rate", "overtime_rate1", "overtime_rate2"];

export const LaborRates = ({ form }) => {
  const isShowField = useMemo(() => {
    return Object.values(form.values.permissions).some(
      (p) => !!p && (p.value === 1 || p.value === 2 || p.value === 3)
    );
  }, [form.values.permissions]);

  useEffect(() => {
    !isShowField && form.setFieldValue("can_be_assigned_to_wo", false);
  }, [isShowField]);

  const { t } = useTranslation();
  const { currency = "USD" } = getUser() || {};

  const handleRegularRateChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      form.setFieldValue("regular_rate", e.target.value);
      form.setFieldValue("overtime_rate1", (e.target.value * 1.5).toFixed(2));
      form.setFieldValue("overtime_rate2", (e.target.value * 2).toFixed(2));
    } else {
      fields.forEach((value) => form.setFieldValue(value, ""));
    }
  };

  return (
    <>
      {isShowField && (
        <>
          <DividerStyled />
          <NameBox>
            <Name>{t("allUsers.form.blocks.assignToWorkOrder")}</Name>
            <ContextualHelp
              content={t("allUsers.form.blocks.assignWorkOrderTooltip")}
            />
          </NameBox>
          <Switch
            activated={form.values.can_be_assigned_to_wo}
            onClick={() => {
              const { handleSetValue } = form;
              handleSetValue(
                "can_be_assigned_to_wo",
                !form.values.can_be_assigned_to_wo
              );
            }}
          >
            {t("allUsers.form.canAssignToWorkOrder")}
          </Switch>
          {form.values?.can_be_assigned_to_wo && (
            <>
              <InlineLabel
                label={t("allUsers.form.regular_rate")}
                labelWidth={110}
              >
                <Input
                  name="regular_rate"
                  value={form.values.regular_rate}
                  onChange={handleRegularRateChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  placeholder={t("allUsers.form.ratePlaceholder")}
                  errorMessage={
                    form.touched.regular_rate && form.errors.regular_rate
                  }
                  height={40}
                  fontSize={14}
                  endAddon={`${currency}/hr`}
                  type="number"
                />
              </InlineLabel>
              <InlineLabel
                label={t("allUsers.form.overtime_rate1")}
                labelWidth={110}
              >
                <Input
                  name="overtime_rate1"
                  value={form.values.overtime_rate1}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  placeholder={t("allUsers.form.overtime_rate1")}
                  errorMessage={
                    form.touched.overtime_rate1 && form.errors.overtime_rate1
                  }
                  type="number"
                  height={40}
                  fontSize={14}
                  endAddon={`${currency}/hr`}
                />
              </InlineLabel>
              <InlineLabel
                label={t("allUsers.form.overtime_rate2")}
                labelWidth={110}
              >
                <Input
                  name="overtime_rate2"
                  value={form.values.overtime_rate2}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  placeholder={t("allUsers.form.overtime_rate2")}
                  errorMessage={
                    form.touched.overtime_rate2 && form.errors.overtime_rate2
                  }
                  type="number"
                  height={40}
                  fontSize={14}
                  endAddon={`${currency}/hr`}
                />
              </InlineLabel>
            </>
          )}
        </>
      )}
    </>
  );
};

const NameBox = styled.div`
  margin-bottom: 16px;
  align-items: center;
  position: relative;
  display: flex;
`;

const Name = styled(H6)`
  margin-right: 4px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 32px;
  margin-bottom: 32px;
`;
