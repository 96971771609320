import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";
import { filePropType } from "../../propTypes";
import { File } from "../File";

export const FileList = ({
  className,
  files,
  collapsedAmount = 3,
  onDownload,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const filesLength = files.length;
  const isCollapsed = Boolean(filesLength > collapsedAmount);

  const filesLeft = filesLength - collapsedAmount;

  const showMoreClickHandler = () => {
    setCollapsed(true);
  };

  return (
    filesLength > 0 && (
      <Box className={className}>
        {files
          .slice(0, !collapsed && isCollapsed ? collapsedAmount : filesLength)
          .map((file) => (
            <File
              key={file.name}
              onDownload={onDownload}
              onDelete={onDelete}
              {...file}
            />
          ))}
        {!collapsed && isCollapsed && (
          <UnfoldButton onClick={showMoreClickHandler}>
            {t("files.file.collapsed", { count: filesLeft })}
          </UnfoldButton>
        )}
      </Box>
    )
  );
};

FileList.propTypes = {
  files: PropTypes.arrayOf(filePropType),
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
};

const Box = styled.div`
  margin-top: 20px;
`;

const UnfoldButton = styled.button`
  text-decoration: none;
  color: var(--color_primary_text);
  margin-top: 8px;

  &:hover {
    text-decoration: underline;
  }
`;
