import styled from "styled-components";

export const Schedule = ({ type, frequency }) => {
  let schedule = "";
  if (type === "one_time") {
    return (schedule = "One-time");
  } else if (frequency === "hour") {
    schedule = `${type}: Hourly`;
  } else if (frequency === "day") {
    schedule = `${type}: Daily`;
  } else if (frequency === "week") {
    schedule = `${type}: Weekly`;
  } else if (frequency === "month") {
    schedule = `${type}: Monthly`;
  } else if (frequency === "year") {
    schedule = `${type}: Yearly`;
  }
  return <Container>{schedule}</Container>;
};

const Container = styled.div`
  text-transform: capitalize;
`;
