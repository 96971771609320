import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetUserSessionQuery } from "modules/users";
import { useTranslation } from "react-i18next";
import { dropUser } from "shared/components/Auth";
import { Box, Title } from "../styled";
import { Session } from "./Session";

export const Sessions = ({ userId }) => {
  const { t } = useTranslation();
  const { data: sessions, error } = useGetUserSessionQuery(userId ?? skipToken);

  if (error?.message === "Token revoked") {
    dropUser();
    window.location.href = "/login";
  }
  return (
    <Box>
      <Title>{t("profile.sessions")}</Title>
      {sessions?.data?.map(({ id, ...device }) => (
        <Session key={id} device={{ ...device, id }} />
      ))}
    </Box>
  );
};
