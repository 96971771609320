import { CMMSAPI } from "app/services";

export const formFieldsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getFormFields: builder.query({
      query: ({ type, action_type }) => ({
        url: "/fields",
        params: { type, action_type },
      }),
    }),
  }),
});

export const { useGetFormFieldsQuery } = formFieldsApi;
