import styled from "styled-components";
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  BarProps,
  YAxisProps,
  XAxisProps,
} from "recharts";
import { BaseAxisProps } from "recharts/types/util/types";
import { ChartLoadingAnimation } from "../ChartLoadingAnimation";
import { CustomTooltip } from "../CustomTooltip";
import { CustomLegend } from "../CustomLegend";
import { ReactElement } from "react";

interface BarChartProps {
  data?: unknown[];
  dataAxisKey?: string;
  bars?: BarProps[];
  isLoading?: boolean;
  className?: string;
  valueFormatter?(value: number): string;

  chartMargin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  layout?: "vertical" | "horizontal";
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  yAxis2Props?: YAxisProps;
  xAxis2Props?: XAxisProps;
  tooltipOverride?: ReactElement;
}

interface AxisProps extends BaseAxisProps {
  tickMargin?: number;
  minTickGap?: number;
}

export const BarChart = ({
  data = [],
  dataAxisKey = "x",
  bars = [],
  isLoading,
  className,
  valueFormatter = (val, index?: number, name?: string) => val.toString(),
  chartMargin,
  layout = "horizontal",
  yAxisProps,
  yAxis2Props,
  xAxisProps,
  xAxis2Props,
  tooltipOverride,
}: BarChartProps) => {
  if (isLoading) {
    return <BarChartSkeleton {...{ className, layout }} />;
  }

  // @ts-ignore
  const labelAxisProps: AxisProps = {
    axisLine: false,
    tickLine: false,
    tick: { fill: "var(--color_grey500)", fontSize: 12 },
    tickFormatter: valueFormatter,
    type: "number",
  };
  const dataAxisProps: AxisProps = {
    dataKey: dataAxisKey,
    type: "category",
    tick: { fill: "var(--color_grey900)", fontSize: 12 },
    tickSize: 3,
    minTickGap: 10,
    tickMargin: 4,
    tickLine: { stroke: "var(--color_grey200)" },
    axisLine: { stroke: "var(--color_grey200)" },
  };

  // @ts-ignore
  return (
    <Wrapper className={className}>
      <ChartWrapper>
        <ResponsiveContainer>
          <RechartsBarChart
            data={data}
            margin={chartMargin}
            barCategoryGap={"20%"}
            layout={layout}
          >
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              color={"var(--color_grey200)"}
            />
            <YAxis
              {...(layout === "horizontal" ? labelAxisProps : dataAxisProps)}
              {...yAxisProps}
            />
            {yAxis2Props && (
              <YAxis
                {...(layout === "horizontal" ? labelAxisProps : dataAxisProps)}
                {...yAxis2Props}
              />
            )}
            <XAxis
              {...(layout === "horizontal" ? dataAxisProps : labelAxisProps)}
              {...xAxisProps}
            />
            {xAxis2Props && (
              <XAxis
                {...(layout === "horizontal" ? dataAxisProps : labelAxisProps)}
                {...xAxis2Props}
              />
            )}
            <Tooltip
              cursor={{ opacity: 0.2 }}
              formatter={valueFormatter}
              content={tooltipOverride ? tooltipOverride : <CustomTooltip />}
            />
            {bars.map((bar) => (
              <Bar
                // @ts-ignore: dataKey works just fine
                key={bar.dataKey}
                isAnimationActive={false}
                {...bar}
              />
            ))}
            {bars.length > 1 && <Legend content={<CustomLegend />} />}
          </RechartsBarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </Wrapper>
  );
};

const BarChartSkeleton = ({
  className,
  layout,
}: Pick<BarChartProps, "className" | "layout">) => {
  const data = [
    { x: "1", y: 100 },
    { x: "2", y: 285 },
    { x: "3", y: 200 },
    { x: "4", y: 75 },
  ];

  const labelAxisProps: AxisProps = {
    dataKey: "y",
    axisLine: false,
    tickLine: false,
    tick: { display: "none" },
    type: "number",
  };
  const dataAxisProps: AxisProps = {
    dataKey: "x",
    tick: { display: "none" },
    tickLine: false,
    axisLine: { stroke: "var(--color_grey200)" },
    type: "category",
  };

  return (
    <Wrapper className={className}>
      <ChartWrapper>
        <ResponsiveContainer>
          <RechartsBarChart
            data={data}
            margin={{ top: 10, right: 10 }}
            barCategoryGap={"20%"}
            layout={layout}
          >
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              color={"var(--color_grey200)"}
            />
            <YAxis
              {...(layout === "horizontal" ? labelAxisProps : dataAxisProps)}
            />
            <XAxis
              {...(layout === "horizontal" ? dataAxisProps : labelAxisProps)}
            />
            <Bar
              dataKey="y"
              isAnimationActive={false}
              // @ts-ignore - BarProps is missing x and y, but they do exist
              shape={({ x, y, width, height }) => (
                <ChartLoadingAnimation>
                  <rect {...{ x, y, width, height }} />
                </ChartLoadingAnimation>
              )}
            />
          </RechartsBarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ChartWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
