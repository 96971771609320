import { CMMSAPI } from "app/services";

export const adminFieldsConfigurationApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAdminConfiguration: builder.query({
      query: (params) => ({
        url: "/admin/field_configurations",
        params,
      }),
      providesTags: () => [
        { type: "AdminConfigurations", id: "AdminConfigurationsList" },
      ],
    }),
    addAdminConfiguration: builder.mutation({
      query: ({ body, params }) => ({
        url: "/admin/field_configurations",
        method: "POST",
        body: body,
        params,
      }),
      invalidatesTags: [
        { type: "AdminConfigurations", id: "AdminConfigurationsList" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAdminConfigurationQuery,
  useAddAdminConfigurationMutation,
} = adminFieldsConfigurationApi;
