import { AssetCostsAndDowntimeChartData } from "./types";
import { BarProps } from "recharts";
import styled from "styled-components";
import { ToggleButtonGroup } from "modules/reporting/components/ToggleButtonGroup";
import {
  formatCurrency,
  formatPercent,
} from "modules/reporting/lib/formatters";
import { useTranslation } from "react-i18next";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { BarChart } from "modules/reporting/components/charts/BarChart";
import { NoData } from "modules/reporting/components/NoData";

interface AssetsCostsAndDowntimeReportChartProps {
  data?: AssetCostsAndDowntimeChartData;
  isFetching: boolean;
  mode: AssetsCostsAndDowntimeReportChartMode;
  setMode: (mode: AssetsCostsAndDowntimeReportChartMode) => void;
}

export type AssetsCostsAndDowntimeReportChartMode = "cost" | "downtime";
const reportKey = ReportKey.AssetCostsAndDowntime;

export const AssetCostsAndDowntimeReportChart = ({
  data,
  isFetching,
  mode,
  setMode,
}: AssetsCostsAndDowntimeReportChartProps) => {
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();

  let chartData = [];
  let valueFormatter: (value: number) => string;
  let bars: BarProps[];
  if (mode === "downtime") {
    chartData = data?.by_status || [];
    valueFormatter = (val) => formatPercent(val, language);
    bars = [
      {
        name: t(`reporting.${reportKey}.labels.downtime_pct`) as string,
        dataKey: "downtime_pct",
        fill: "#FF786E",
      },
    ];
  } else {
    chartData = data?.by_cost || [];
    valueFormatter = (val) => formatCurrency(val, language, currency);
    bars = [
      {
        name: t(`reporting.${reportKey}.labels.cost`) as string,
        dataKey: "cost",
        fill: "var(--color_chart_green)",
      },
    ];
  }

  if (!isFetching && !chartData?.length) {
    return <NoData />;
  }

  return (
    <Wrapper>
      <ModeOptions
        value={mode}
        onChange={(val) =>
          setMode(val as AssetsCostsAndDowntimeReportChartMode)
        }
        ariaLabel={t(`reporting.${reportKey}.buttons.label`)}
        options={[
          {
            label: t(`reporting.${reportKey}.buttons.cost`),
            value: "cost",
          },
          {
            label: t(`reporting.${reportKey}.buttons.downtime`),
            value: "downtime",
          },
        ]}
      />
      <BarChart
        isLoading={isFetching}
        layout="vertical"
        bars={bars}
        data={chartData}
        dataAxisKey="name"
        valueFormatter={valueFormatter}
        xAxisProps={{
          domain: mode === "downtime" ? [0, 1] : [0, "auto"],
        }}
        yAxisProps={{
          width: 150,
          tickFormatter: (val) =>
            val.length > 14 ? val.substring(0, 14) + "..." : val,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ModeOptions = styled(ToggleButtonGroup)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  padding-bottom: 8px;
`;
