import { FileManager } from "modules/fileManager";
import { getViewRows } from "modules/parts/lib/helpers";
import { useLazyGetPartTransactionQuery } from "modules/parts";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LightModal } from "shared/components/Modals/LightModal";
import { useDisplayDate } from "shared/lib/hooks";
import styled from "styled-components";
import { Details } from "widgets/Details";

const fields = ["created_at", "quantity", "cost", "note"];

export const ViewPartTransaction = ({
  transactionId,
  isShow,
  closePartTransactionHandler,
}) => {
  const displayDate = useDisplayDate();
  const { t } = useTranslation();
  const [trigger, { currentData: transaction }] =
    useLazyGetPartTransactionQuery();

  useEffect(() => {
    if (isShow) {
      trigger(transactionId);
    }
  }, [isShow, transactionId, trigger]);

  const { visibleRows } = getViewRows(fields, transaction, t, displayDate);

  const closeModal = () => {
    closePartTransactionHandler();
  };

  const files = transaction
    ? [
        ...transaction?.images.map((file) => ({ ...file, isImage: true })),
        ...transaction?.documents,
      ]
    : [];

  return (
    <LightModal
      isOpen={isShow}
      onClose={closeModal}
      label={t("parts.transaction.title", { id: transactionId })}
    >
      <ContentBox>
        <Details label="" rows={visibleRows} />
        <FileManageWrapper value={files} />
      </ContentBox>
    </LightModal>
  );
};

const ContentBox = styled.div`
  width: 512px;
  padding: 32px 16px;
`;

const FileManageWrapper = styled(FileManager)`
  margin-top: 32px;
  & > div > div {
    flex: 1;
  }
`;
