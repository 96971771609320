import React from "react";
import PropTypes from "prop-types";

import { InviteSignUpForm, SignUpForm } from "modules/auth";
import { useSearchParams } from "react-router-dom";

export const SignUpPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  document.title = "Sign Up";

  return token ? <InviteSignUpForm token={token} /> : <SignUpForm />;
};

SignUpPage.propTypes = {
  history: PropTypes.object,
};
