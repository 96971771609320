import { createApi } from "@reduxjs/toolkit/query/react";
import qs from "qs";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const portalsApi = createApi({
  reducerPath: "portalsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_SERVER_API_URL}/api/v1`,
    paramsSerializer: (params) => {
      return qs.stringify(params, {
        encode: false,
        arrayFormat: "brackets",
      });
    },
  }),
  tagTypes: ["Portal"],
  endpoints: (builder) => ({
    getRequestPortal: builder.query({
      query: (id) => `/portal/${id}/request_portals/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "PortalDetails", id: query },
      ],
    }),
    getPortalFormFields: builder.query({
      query: ({ type, action_type, id }) => ({
        url: `/portal/${id}/fields`,
        params: { type, action_type },
      }),
    }),
    getList: builder.query({
      query: ({ id, type, ...params }) => ({
        url: `/portal/${id}/${type}`,
        params,
      }),
      providesTags: (result, error, { type }) => [{ type, id: `${type}List` }],
    }),
    addPortal: builder.mutation({
      query: ({ id, body }) => ({
        url: `/portal/${id}/requests`,
        method: "POST",
        body: body,
      }),
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useAddPortalMutation,
  useGetRequestPortalQuery,
  useGetPortalFormFieldsQuery,
  useLazyGetPortalFormFieldsQuery,
  useGetListQuery,
} = portalsApi;

export default portalsApi;
