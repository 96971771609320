import React, { useState } from "react";

import EditIcon from "shared/assets/icon/edit.svg?react";
import { formatCurrency } from "shared/lib/helpers/common";

import { GeneralCell, Box, StyledIconButton } from "./WorkOrderPartView.styled";
import { WorkOrderPartEdit } from "../WorkOrderPartEdit/WorkOrderPartEdit";
import { useGetLocaleSettings } from "shared/lib/hooks";
import { ModalLink } from "shared/components/ModalLink";

export const WorkOrderPartView = ({
  value = {},
  index,
  onUpdate,
  onDelete,
  workOrderStatus,
  type,
  module,
  disabled,
  permissions,
}) => {
  const { part, quantity, id, total_cost } = value;
  const { currency } = useGetLocaleSettings();

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const openEditModal = () => setIsShowEditModal(true);
  const closeEditModal = () => setIsShowEditModal(false);

  const isShowCost =
    module !== "PM" && type !== "edit" && workOrderStatus === "completed";

  return (
    <Box columns={isShowCost ? 2 : 1}>
      {type === "edit" ? (
        part.label
      ) : (
        <ModalLink modalParams={{ type: "part", id: part.value }}>
          {part.label}
        </ModalLink>
      )}
      <GeneralCell>
        {quantity} {part.measurement_unit?.short_name || "unit"}
      </GeneralCell>
      {isShowCost && (
        <GeneralCell>
          {workOrderStatus === "completed"
            ? formatCurrency(total_cost, currency)
            : ""}
        </GeneralCell>
      )}
      {permissions.edit && (
        <StyledIconButton
          onClick={openEditModal}
          className="actionIcon"
          disabled={disabled}
        >
          <EditIcon />
        </StyledIconButton>
      )}

      {isShowEditModal && (
        <WorkOrderPartEdit
          id={id}
          index={index}
          data={value}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onClose={closeEditModal}
          value={value}
          module={module}
        />
      )}
    </Box>
  );
};
