import React from "react";
import PropTypes from "prop-types";
import { Document, Page } from "@react-pdf/renderer";
import { pageStyle } from "./BulkPdfExport.styled";
import { PDFFooter } from "shared/components/PDFFooter";

export const PDFDocument = ({ exportResources, PageComponent }) => {
  return (
    <Document>
      {exportResources.map((resource) => (
        <Page style={pageStyle.page} size="LETTER" key={resource.id}>
          <PageComponent resource={resource} />
          <PDFFooter />
        </Page>
      ))}
    </Document>
  );
};

PDFDocument.propTypes = {
  exportResources: PropTypes.array.isRequired,
  PageComponent: PropTypes.node.isRequired,
};
