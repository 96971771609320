import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";
import { VendorCreate } from "modules/vendors/components/VendorCreate";
import { VendorUpdate } from "modules/vendors/components/VendorUpdate";
import { VendorView } from "modules/vendors/components/VendorView";

interface VendorModalProps {
  params: ModalParams;
}

export const VendorModal = ({ params }: VendorModalProps) => {
  const { type, id, state = "view" } = params;
  const { vendorCreatePermit, vendorUpdatePermit, vendorViewPermit } =
    usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new" && vendorCreatePermit) {
    return (
      <VendorCreate
        isShow={true}
        onOpen={(id: string) => setModal({ type, id })}
        onClose={onClose}
      />
    );
  } else if (state === "view" && vendorViewPermit) {
    return (
      <VendorView
        vendorId={id}
        onClose={onClose}
        onEdit={(id: string) => setModal({ type, id, state: "edit" })}
        isShow={true}
      />
    );
  } else if (state === "edit" && vendorUpdatePermit) {
    return (
      <VendorUpdate
        vendorId={id}
        isShow={true}
        onClose={onView}
        onSuccess={(id: string) => setModal({ type, id })}
      />
    );
  }

  return null;
};
