import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";
import { PartCreate } from "modules/parts/components/PartCreate";
import { PartView } from "modules/parts/components/PartView";
import { PartEdit } from "modules/parts/components/PartEdit";
import { PartClone } from "modules/parts/components/PartClone";

interface PartModalProps {
  params: ModalParams;
}

export const PartModal = ({ params }: PartModalProps) => {
  const { type, id, state = "view" } = params;
  const {
    partCreatePermit,
    partViewPermit,
    partUpdatePermit,
    partClonePermit,
  } = usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new" && partCreatePermit) {
    return (
      <PartCreate
        isShow={true}
        onClose={onClose}
        onOpen={(id: string) => setModal({ type, id })}
      />
    );
  } else if (state === "view" && partViewPermit) {
    return (
      <PartView
        partId={id}
        onClose={onClose}
        onEdit={(id: string) => setModal({ type, id, state: "edit" })}
        onClone={(id: string) => setModal({ type, id, state: "clone" })}
        isShow={true}
      />
    );
  } else if (state === "edit" && partUpdatePermit) {
    return (
      <PartEdit partId={id} isShow={true} onClose={onView} onOpen={onView} />
    );
  } else if (state === "clone" && partClonePermit) {
    return <PartClone partId={id} isShow={true} onClose={onView} />;
  }

  return null;
};
