import { useTranslation } from "react-i18next";
import { Empty } from "shared/components/Empty";
import { usePermission } from "app/providers/PermissionsProvider";
import PartsIcon from "../../assets/icons/empty.svg?react";
import { ModalLink } from "shared/components/ModalLink";

export const EmptyPartList = () => {
  const { t } = useTranslation();
  const { partCreatePermit } = usePermission();

  return (
    <Empty
      icon={<PartsIcon />}
      heading={t("parts.noParts")}
      description={
        partCreatePermit && (
          <>
            {t("empty.descriptionUse")}
            <ModalLink modalParams={{ type: "part", id: "new" }}>
              {t("empty.parts")}
            </ModalLink>
            {t("empty.descriptionButton")}
          </>
        )
      }
      spacious
    />
  );
};
