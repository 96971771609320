import { usePermission } from "app/providers/PermissionsProvider";
import { IProcedureTemplate } from "modules/procedures/types";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { IconButton } from "shared/components/IconButton";
import styled from "styled-components";
import { useSetModal } from "widgets/ModalController";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";
import { useCallback, useState } from "react";
import { ConfirmationDialog } from "shared/components/Dialog";
import {
  useLinkProcedureTemplatesMutation,
  useUnlinkProcedureTemplateMutation,
} from "modules/procedures/state/proceduresApi";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import { ProcedureLinkModal } from "modules/procedures/components";
import { MultiValue, SingleValue } from "react-select";
import { OptionType } from "shared/components/Select/SelectCreatable";
import { useFlags } from "launchdarkly-react-client-sdk";

interface TemplateListProps {
  pmSchedule: {
    id: string | number;
    procedure_templates: IProcedureTemplate[];
  };
}

export const TemplateList = ({ pmSchedule }: TemplateListProps) => {
  const { enableProcedures } = useFlags();
  const { procedure_templates: templates } = pmSchedule;
  const { t } = useTranslation();
  const setModal = useSetModal();
  const { procedureTemplateDeletePermit } = usePermission();
  const captureException = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  // state
  const [templateToUnlink, setTemplateToUnlink] =
    useState<IProcedureTemplate | void>();
  const [showModal, setShowModal] = useState(false);
  const [isLinkingInProgress, setIsLinkingInProgress] = useState(false);
  const [templatesToLink, setTemplatesToLink] = useState<OptionType[]>([]);

  // requests & mutations
  const [unlinkTemplate] = useUnlinkProcedureTemplateMutation();
  const [triggerLinkProcedures] = useLinkProcedureTemplatesMutation();

  const onTemplateUnlink = async () => {
    if (templateToUnlink == null) return;
    const payload = {
      procedureTemplateId: templateToUnlink.id,
      targetId: pmSchedule.id,
      targetType: "PmSchedule",
    };

    try {
      await unlinkTemplate(payload).unwrap();
    } catch (e) {
      captureException(
        e,
        "Error when attempting to remove procedure template assignment",
        payload
      );
    }

    setTemplateToUnlink();
  };

  const linkProcedures = async () => {
    setIsLinkingInProgress(true);
    const procedureTemplateIds = templatesToLink.map((t) => t.value);
    const payload = {
      targetId: pmSchedule.id,
      targetType: "PmSchedule",
      procedureTemplateIds,
    };
    try {
      await triggerLinkProcedures(payload).unwrap();
    } catch (e) {
      captureException(e, "Failed to delete procedure", payload);
    }

    setIsLinkingInProgress(false);
    setShowModal(false);
  };

  const allowDelete = useCallback(
    (createdById: number | string) =>
      (
        procedureTemplateDeletePermit as (
          createdById: string | number
        ) => boolean
      )(createdById),
    [procedureTemplateDeletePermit]
  );

  const onModalClose = () => {
    setShowModal(false);
    setTemplatesToLink([]);
  };

  const onSelect = (
    _: string,
    value: MultiValue<OptionType> | SingleValue<OptionType>
  ) => {
    setTemplatesToLink(value as OptionType[]);
  };

  if (!enableProcedures) {
    return null;
  }

  return (
    <>
      <FlexContainer>
        <Title>{t("procedures.title")}</Title>
        <StyledButton
          variant="secondary"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t("procedures.add_procedure")}
        </StyledButton>
      </FlexContainer>

      {templates.length > 0 && (
        <ListContainer>
          {templates.map((template) => (
            <li key={template.id}>
              <Item>
                {template.name}
                <FlexContainer>
                  <Button
                    onClick={() =>
                      setModal({ type: "procedureTemplate", id: template.id })
                    }
                  >
                    {t("procedures.view_procedure")}
                  </Button>
                  <>
                    <IconButton
                      aria-label="Delete procedure"
                      onClick={() => setTemplateToUnlink(template)}
                      disabled={!allowDelete(template?.created_by_id)}
                    >
                      <TrashCanIcon />
                    </IconButton>
                  </>
                </FlexContainer>
              </Item>
            </li>
          ))}
        </ListContainer>
      )}
      {templateToUnlink != null && (
        <ConfirmationDialog
          title={t("procedures.unlink_from_pm_dialog.confirm_title")}
          description={t(
            "procedures.unlink_from_pm_dialog.confirm_description"
          )}
          confirmButtonLabel={
            t("procedures.unlink_from_pm_dialog.confirm_button") as string
          }
          cancelButtonLabel={t("cancel") as string}
          onProceed={() => onTemplateUnlink()}
          onCancel={() => setTemplateToUnlink()}
          onClose={() => setTemplateToUnlink()}
          isDestructive
        />
      )}

      <ProcedureLinkModal
        isOpen={showModal}
        onClose={onModalClose}
        onSubmit={linkProcedures}
        value={templatesToLink}
        onChange={onSelect}
        isSubmitting={isLinkingInProgress}
        submitDisabled={templatesToLink.length === 0}
      />
    </>
  );
};

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`;

const ListContainer = styled.ul`
  padding: 0.5rem 0 0 1rem;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 1;
  padding: 4px;
  height: 24px;
`;
