import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ConfirmationDialog } from "../Dialog";

export const ContextMenuEntry = ({
  children,
  to,
  onClick,
  warning = false,
  disabled,
  confirmation,
}) => {
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const clickHandler = () => {
    if (disabled) return;
    if (confirmation) {
      setIsConfirmationVisible(true);
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  const proceedHandler = () => {
    if (onClick) {
      onClick();
      setIsConfirmationVisible(false);
    }
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
  };

  return (
    <>
      <Entry
        to={to}
        onClick={clickHandler}
        warning={warning}
        disabled={disabled}
      >
        {children}
      </Entry>
      {isConfirmationVisible && (
        <ConfirmationDialog
          {...confirmation}
          onProceed={proceedHandler}
          onCancel={cancelHandler}
          isDestructive={warning}
        />
      )}
    </>
  );
};

ContextMenuEntry.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  to: PropTypes.string,
  onClick: PropTypes.func,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
  confirmation: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
  }),
};

const Entry = styled.div.attrs(({ to }) => ({
  as: to ? Link : "button",
}))`
  border-radius: 4px;
  padding: 8px 16px 8px 28px;
  color: ${({ warning }) =>
    warning ? "var(--color_corp_red)" : "var(--color_grey900)"};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;

  &:hover {
    background-color: ${({ warning }) =>
      warning ? "var(--color_corp_red_20)" : "var(--color_corp_mint_20)"};
  }

  &:focus {
    box-shadow: 0 0 0 2px inset
        ${({ warning }) =>
          warning ? "var(--color_corp_red)" : "var(--color_primary_dark)"},
      0 0 0 3px inset var(--color_background_light);
  }

  &:active {
    background-color: ${({ warning }) =>
      warning ? "var(--color_corp_red_40)" : "var(--color_corp_mint_40)"};
  }

  &:disabled {
    opacity: 0.5;
    cursor: unset;
    background: var(--color_grey10);
    color: var(--color_grey200);
  }
`;
