import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFlags } from "launchdarkly-react-client-sdk";

import { NavLink } from "shared/components/NavLink";

import FilesIcon from "shared/assets/icon/menu/Files.svg?react";
import WrenchIcon from "shared/assets/icon/menu/Wrench.svg?react";
import BoxIcon from "shared/assets/icon/menu/Box.svg?react";
import ListIcon from "shared/assets/icon/menu/List.svg?react";
import ProceduresIcon from "shared/assets/icon/menu/Procedures.svg?react";
import AssetsIcon from "shared/assets/icon/menu/Assets.svg?react";
import DiagramIcon from "shared/assets/icon/menu/Diagram.svg?react";
import LocationIcon from "shared/assets/icon/menu/Location.svg?react";
import TeamsIcon from "shared/assets/icon/menu/Teams.svg?react";
import VendorsIcon from "shared/assets/icon/menu/Vendors.svg?react";

import SettingsIcon from "shared/assets/icon/menu/Settings.svg?react";

import { Container, Wrapper, BottomActionList } from "./components/styled";
import { Person } from "./components/Person";
import { ActionList } from "./components/ActionList";
import { Entities } from "./components/Entities";
import { CollapseButton } from "./components/CollapseButton";
import { Logo } from "./components/Logo";
import { usePermission } from "app/providers/PermissionsProvider";
import { BugReportButton } from "./components/BugReportButton";
import { Tenant } from "./components/Tenant/Tenant";

export const SideBar = () => {
  const [hidden, setHidden] = useState(false);

  const { t } = useTranslation();

  const {
    entitiesPermit,
    requestsListPermit,
    woListPermit,
    assetsListPermit,
    locationsListPermit,
    partsListPermit,
    vendorsListPermit,
    usersPermit,
    profilePermit,
    adminPermit,
    pmsViewPermit,
    reportsPermit,
    procedureTemplateListView,
  } = usePermission();

  const { enableProcedures } = useFlags();

  return (
    <Container id="sideBar">
      <Wrapper>
        <Logo showText={!hidden} />
        <Tenant showText={!hidden} />
        <ActionList
          actions={[
            [
              entitiesPermit && <Entities hidden={hidden} />, // TODO TBD in US W01.1.3 Navigation bar: View entities
              requestsListPermit && (
                <NavLink
                  href="/requests"
                  icon={FilesIcon}
                  text={t("sidebar.requests")}
                  collapsed={hidden}
                />
              ),
            ],
            [
              woListPermit && (
                <NavLink
                  href="/work-orders"
                  icon={WrenchIcon}
                  text={t("sidebar.workOrders")}
                  collapsed={hidden}
                />
              ),
              pmsViewPermit && (
                <NavLink
                  href="/preventive-maintenances"
                  icon={ListIcon}
                  text={t("sidebar.pms")}
                  collapsed={hidden}
                />
              ),
              procedureTemplateListView && enableProcedures && (
                <NavLink
                  href="/procedures"
                  icon={ProceduresIcon}
                  text={t("sidebar.procedures")}
                  collapsed={hidden}
                />
              ),
            ],
            [
              assetsListPermit && (
                <NavLink
                  href="/assets"
                  icon={AssetsIcon}
                  text={t("sidebar.assets")}
                  collapsed={hidden}
                />
              ),
              locationsListPermit && (
                <NavLink
                  href="/locations"
                  icon={LocationIcon}
                  collapsed={hidden}
                  text={t("sidebar.locations")}
                />
              ),
              partsListPermit && (
                <NavLink
                  href="/parts"
                  icon={BoxIcon}
                  collapsed={hidden}
                  text={t("sidebar.parts")}
                />
              ),
              vendorsListPermit && (
                <NavLink
                  href="/vendors"
                  icon={VendorsIcon}
                  collapsed={hidden}
                  text={t("sidebar.vendors")}
                />
              ),
              usersPermit && (
                <NavLink
                  href="/users"
                  icon={TeamsIcon}
                  collapsed={hidden}
                  text={t("sidebar.users")}
                />
              ),
            ],
            [
              reportsPermit && (
                <NavLink
                  href="/reporting"
                  icon={DiagramIcon}
                  collapsed={hidden}
                  text={t("sidebar.reporting")}
                />
              ),
            ],
          ]}
        />

        <BottomActionList
          actions={[
            [<BugReportButton collapsed={hidden} />],
            [
              profilePermit && (
                <NavLink href="/profile">
                  <Person hidden={hidden} />
                </NavLink>
              ),

              adminPermit && (
                <NavLink
                  href="/admin-settings/general"
                  icon={SettingsIcon}
                  collapsed={hidden}
                  text={t("sidebar.settings")}
                />
              ),
            ],
          ]}
        />
        <CollapseButton hidden={hidden} setHidden={setHidden} />
      </Wrapper>
    </Container>
  );
};
