import styled from "styled-components";

export const Box = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  background: none;
  width: 24px;
  height: 24px;
  margin: 0 4px;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "inherit")};

  &:hover,
  &:focus {
    background-color: var(--color_table_sort_hover);
    cursor: pointer;
  }

  &:active {
    background-color: var(--color_grey100);
  }

  &:disabled {
    opacity: 0.3;
  }

  svg {
    display: block;
    transform: rotate(${({ upsideDown }) => (upsideDown ? "180deg" : "0deg")});
  }
`;
