import styled, { css } from "styled-components";
import { ReactNode } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

interface AuthPageLayoutProps {
  children: ReactNode;
}

/** Layout for login/signup/forgot password pages */
export const AuthPageLayout = ({ children }: AuthPageLayoutProps) => {
  const { enableLoginPageMarketingPanel } = useFlags();
  return (
    <Wrapper enableRightPanel={enableLoginPageMarketingPanel}>
      <FormWrapper>{children}</FormWrapper>
      <Frame
        src="https://www.clickmaint.com/signup-landing-page"
        title="Click Maint"
      />
    </Wrapper>
  );
};

const FormWrapper = styled.div`
  padding: 24px;
`;

const Frame = styled.iframe`
  display: none;
  box-sizing: border-box;
  border: none;
  border-left: 1px solid var(--color_border);
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div<{ enableRightPanel: boolean }>`
  display: grid;
  height: 100vh;

  ${(p) =>
    p.enableRightPanel &&
    css`
      @media (min-width: 1200px) {
        grid-template-columns: minmax(500px, 2fr) 6fr;
        & ${Frame} {
          display: block;
        }
      }
    `}
`;
