import { PartInventoryRecord, PartsInventoryChartData } from "./types";
import { useTranslation } from "react-i18next";
import { NoData } from "../../NoData";
import { BarChart } from "../../charts/BarChart";
import { formatCurrency } from "../../../lib/formatters";
import { ReportKey } from "../../../types/ReportKey";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";
import { XAxisProps, YAxisProps } from "recharts";

interface PartsInventoryReportChartProps {
  mode: string;
  setMode: (mode: string) => void;
  data?: PartsInventoryChartData;
  isFetching: boolean;
}

const MAX_RECORDS = 8;
export const PartsInventoryReportChart = ({
  mode,
  setMode,
  data,
  isFetching,
}: PartsInventoryReportChartProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const reportKey = ReportKey.PartsInventory;
  const { currency } = useLocaleSettings();

  if (!isFetching && (data === undefined || data?.parts.length === 0)) {
    return <NoData />;
  }

  const shapeData = (data: PartInventoryRecord[] | undefined) => {
    if (data === undefined || !data?.length) {
      return [];
    }
    data = [...data]
      .sort((a, b) => {
        return b.cost - a.cost;
      })
      .slice(0, MAX_RECORDS);
    return data;
  };

  const partsData = shapeData(data?.parts) ?? [];
  const partTypesData = shapeData(data?.part_types) ?? [];
  const displayData = mode === "parts" ? partsData : partTypesData;
  const partsBars = [
    {
      dataKey: "cost",
      xAxisId: "cost",
      name: t(`reporting.${reportKey}.labels.cost`) as string,
      fill: "var(--color_mint)",
    },
  ];
  const partTypeBars = [
    {
      dataKey: "cost",
      xAxisId: "cost",
      name: t(`reporting.${reportKey}.labels.cost`) as string,
      fill: "var(--color_mint)",
    },
  ];

  const xAxisProps: XAxisProps = {
    dataKey: "cost",
    xAxisId: "cost",
    type: "number",
    allowDecimals: true,
    domain: [0, Math.max(...displayData.map((item) => item.cost)) * 1.2],
    orientation: "bottom",
    tickFormatter: (value: number) => formatCurrency(value, language, currency),
  };
  const dataKey = mode === "parts" ? "part" : "part_type";
  const yAxisProps: YAxisProps = {
    dataKey: dataKey,
    type: "category",
    orientation: "left",
    width: 220,
    tickMargin: 10,
    tickFormatter: (value: string) =>
      value.length > 27 ? value.substring(0, 24) + "..." : value,
  };
  const displayBars = mode === "parts" ? partsBars : partTypeBars;
  return (
    <BarChart
      isLoading={isFetching}
      data={displayData}
      layout={"vertical"}
      yAxisProps={yAxisProps}
      xAxisProps={xAxisProps}
      dataAxisKey={mode === "parts" ? "part_name" : "part_type"}
      bars={displayBars}
    />
  );
};
