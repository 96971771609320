import { t } from "i18next";
import { ToggleButtonGroup } from "modules/reporting/components/ToggleButtonGroup";
import { ReportBucket } from "modules/reporting/types/ReportBucket";

interface ReportBucketOptionsProps {
  selected: ReportBucket;
  onChange: (val: ReportBucket) => void;
  className?: string;
}

/** A group of buttons for selecting a Report Bucket (day, week, month, year) */
export const ReportBucketOptions = ({
  selected,
  onChange,
  className,
}: ReportBucketOptionsProps) => {
  return (
    <ToggleButtonGroup
      className={className}
      value={selected}
      onChange={(val) => onChange(val as ReportBucket)}
      ariaLabel="Data grouping"
      options={[
        {
          label: t(`reporting.bucketOptions.day_short`),
          value: "day",
          ariaLabel: t(`reporting.bucketOptions.day`) as string,
        },
        {
          label: t(`reporting.bucketOptions.week_short`),
          value: "week",
          ariaLabel: t(`reporting.bucketOptions.week`) as string,
        },
        {
          label: t(`reporting.bucketOptions.month_short`),
          value: "month",
          ariaLabel: t(`reporting.bucketOptions.month`) as string,
        },
        {
          label: t(`reporting.bucketOptions.year_short`),
          value: "year",
          ariaLabel: t(`reporting.bucketOptions.year`) as string,
        },
      ]}
    />
  );
};
