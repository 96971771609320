import {
  useGetAdminStoreroomsQuery,
  useGetStoreroomQuery,
  useAddStoreroomMutation,
  useDeleteStoreroomMutation,
  useUpdateStoreroomMutation,
} from "modules/storerooms";
import { AdministrationReference } from "shared/components/AdministrationReference";
import { usePermission } from "app/providers/PermissionsProvider";

const actions = {
  getList: useGetAdminStoreroomsQuery,
  getElement: useGetStoreroomQuery,
  addElement: useAddStoreroomMutation,
  updateElement: useUpdateStoreroomMutation,
  deleteElement: useDeleteStoreroomMutation,
};

export const Storerooms = () => {
  const { adminSettingsStoreroomsPermit } = usePermission();
  return (
    <AdministrationReference
      actions={actions}
      module="storerooms"
      hasPermission={adminSettingsStoreroomsPermit}
    />
  );
};
