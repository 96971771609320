import { useTranslation } from "react-i18next";

import { Button } from "shared/components/Button";
import { Box, Container, Title } from "../styled";

export const PasswordExpired = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box id="passwordInvitationExpired">
        <Title>{t("auth.resetPassword.passwordExpiredTitle")}</Title>
        <p>{t("auth.resetPassword.passwordExpiredDescription")}</p>

        <Button type="primary" size="normal" onClick={onSubmit}>
          {t("auth.resetPassword.resendPasswordButton")}
        </Button>
      </Box>
    </Container>
  );
};
