import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconButton } from "shared/components/IconButton";
import { ContextMenuEntry } from "shared/components/ContextMenu";
import { DarkDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { downloadFile } from "shared/lib/downloadPdf";
import { Description, SelectedLabel } from "./BulkPdfExport.styled.js";
import { PDFDocument } from "./PDFDocument";
import PrinterIcon from "../../assets/icon/pdf/print.svg?react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";

const StyledPrinterIcon = styled(PrinterIcon)`
  outline: none;
`;

export const BulkPdfExport = ({
  selectedLabel,
  ids = [],
  type,
  title,
  resourceName,
  getExportResources,
  PdfExampleSvg,
  PageComponent,
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [exportResources, setExportResources] = useState([]);

  const openHandler = () => {
    generateHandle();
  };

  const closeHandler = () => {
    setIsOpenModal(false);
  };

  const generateHandle = () => {
    getExportResources({ ids })
      .unwrap()
      .then((data) => {
        setExportResources(data);
        setIsOpenModal(true);
      })
      .catch((err) => {
        addSnackBar({
          content: JSON.stringify(err.message),
          type: "error",
          secondaryButtonLabel: "Close",
        });
      });
  };

  const proceedHandler = async () => {
    setDisabledSubmit(true);
    try {
      const doc = (
        <PDFDocument
          exportResources={exportResources}
          PageComponent={PageComponent}
        />
      );
      await downloadFile(doc, resourceName);
    } catch (error) {
      console.error(error);
    } finally {
      setDisabledSubmit(false);
      setIsOpenModal(false);
    }
  };

  return (
    <>
      {type === "button" && (
        <IconButton onClick={openHandler}>
          <StyledPrinterIcon data-tooltip-id="icon-tooltip-print" />
          <ReactTooltip
            id="icon-tooltip-print"
            place="bottom"
            content={t("work_orders.bulkActions.hint.print")}
            opacity="1"
          />
        </IconButton>
      )}
      {type === "link" && (
        <ContextMenuEntry onClick={openHandler}>
          {t(`print${resourceName}.exportPdf`)}
        </ContextMenuEntry>
      )}
      <DarkDialog
        isOpen={isOpenModal}
        label={title}
        primaryButtonLabel={t(
          `print${resourceName}.confirmation.confirmButtonLabel`
        )}
        onClose={closeHandler}
        onSubmit={proceedHandler}
        disabledSubmit={disabledSubmit}
      >
        <Description>
          {selectedLabel && <SelectedLabel>{selectedLabel}</SelectedLabel>}
          <PdfExampleSvg alt="modalImage" />
        </Description>
      </DarkDialog>
    </>
  );
};

BulkPdfExport.propTypes = {
  ids: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  selectedLabel: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
  PageComponent: PropTypes.func.isRequired,
  getExportResources: PropTypes.func.isRequired,
  PdfExampleSvg: PropTypes.shape({
    type: PropTypes.oneOf(["img", "svg"]),
  }),
};

BulkPdfExport.default = {
  selectedLabel: null,
  title: "",
};
