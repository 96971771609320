import { DonutChart } from "modules/reporting/components/charts/DonutChart";
import { PriorityIconUrlMap } from "shared/components/Priority";
import { DonutChartWidgetLayout } from "modules/reporting/components/widgets/DonutChartWidgetLayout";
import { NoData } from "modules/reporting/components/NoData";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useTranslation } from "react-i18next";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";

interface BacklogByPriorityReportWidgetData {
  chart_data: {
    key: string;
    value: number;
  }[];
  other_data: never;
}

export const BacklogByPriorityReportWidget = () => {
  const sliceConfigs = useSliceConfigs();
  const { data, isFetching } = useWidgetData<BacklogByPriorityReportWidgetData>(
    ReportKey.BacklogByPriority,
    reportPeriodCurrent
  );

  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  return (
    <DonutChartWidgetLayout>
      <DonutChart.Root
        data={data?.chart_data ?? []}
        isLoading={isFetching}
        sliceConfigs={sliceConfigs}
      >
        <DonutChart.Legend />
        <DonutChart.Chart>
          <DonutChart.Label />
        </DonutChart.Chart>
      </DonutChart.Root>
    </DonutChartWidgetLayout>
  );
};

function useSliceConfigs() {
  const { t } = useTranslation();
  return {
    none: {
      fill: "var(--color_grey400)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.none`),
      icon: PriorityIconUrlMap.none,
    },
    low: {
      fill: "var(--color_priority_lime)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.low`),
      icon: PriorityIconUrlMap.low,
    },
    medium: {
      fill: "var(--color_priority_yellow)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.medium`),
      icon: PriorityIconUrlMap.medium,
    },
    high: {
      fill: "var(--color_priority_orange)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.high`),
      icon: PriorityIconUrlMap.high,
    },
    critical: {
      fill: "var(--color_priority_red)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.critical`),
      icon: PriorityIconUrlMap.critical,
    },
  };
}
