import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const assetTypesApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAssetTypes: builder.query({
      query: (params) => ({
        url: "asset_types",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "AssetTypes", id: "AssetTypesList" },
        ...getTagList("AssetTypes", result?.data),
      ],
    }),
    getAdminAssetTypes: builder.query({
      query: (params) => ({
        url: "admin/asset_types",
        params,
      }),
      providesTags: (result) => [
        { type: "AdminAssetTypes", id: "AdminAssetTypesList" },
        ...getTagList("AdminAssetTypes", result?.data),
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getAssetType: builder.query({
      query: (id) => `/admin/asset_types/${id}`,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      providesTags: (result, error, { id }) => [
        { type: "AdminAssetTypes", id },
      ],
    }),
    updateAssetType: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/asset_types/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      invalidatesTags: ["AdminAssetTypes"],
    }),
    deleteAssetType: builder.mutation({
      query: (id) => ({
        url: `/admin/asset_types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "AdminAssetTypes", id }],
      transformErrorResponse: (response) => response.data,
    }),
    addAssetType: builder.mutation({
      query: (body) => ({
        url: "/admin/asset_types",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "AdminAssetTypes", id: "AdminAssetTypesList" }],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAssetTypesQuery,
  useGetAdminAssetTypesQuery,
  useGetAssetTypeQuery,
  useUpdateAssetTypeMutation,
  useDeleteAssetTypeMutation,
  useAddAssetTypeMutation,
} = assetTypesApi;
