import { userDetailsCreateInitialValues } from "../../config/constant";
import { getFormDataFromJSON } from "shared/lib/helpers";

export const configureRoles = (roles, t) => {
  return roles?.data
    ?.map(({ name, id }) => ({
      value: id,
      label: t(`allUsers.roles.${name}`),
    }))
    .reverse();
};

export const getInitialValues = ({
  type,
  data,
  entities,
  noAccessRoleId,
  userType,
  currentEntity,
  t = () => {},
}) => {
  const roles = {
    limited_admin: t(`allUsers.roles.limited_admin`),
    admin: t(`allUsers.roles.admin`),
    technician: t(`allUsers.roles.technician`),
    requester: t(`allUsers.roles.requester`),
    no_access: t(`allUsers.roles.no_access`),
  };

  const isAuthenticatedUser =
    data?.status === "active" ||
    data?.status === "inactive" ||
    data?.status === "change_email_in_progress";

  const isEntityUser = userType === "entityUser";

  const allEntities = entities?.data?.length
    ? entities.data.reduce((acc, { name }) => {
        acc[name] = {
          value: noAccessRoleId,
          label: t(`allUsers.roles.no_access`),
        };
        return acc;
      }, {})
    : {};

  if (type === "create" || !data) {
    return {
      ...userDetailsCreateInitialValues,

      permissions: {
        ...(isEntityUser
          ? {
              [currentEntity]: {
                value: noAccessRoleId,
                label: t(`allUsers.roles.no_access`),
              },
            }
          : allEntities),
      },
    };
  }

  const {
    name = "",
    surname = "",
    job_title = "",
    phone_number = "",
    email = "",
    permissions = [],
    can_be_assigned_to_wo = false,
    regular_rate = "",
    overtime_rate1 = "",
    overtime_rate2 = "",
  } = data;

  const userEntities = permissions?.reduce(
    (acc, { entity, user_role_id, role }) => {
      acc[entity] = { value: user_role_id, label: roles[role] };

      return acc;
    },
    {}
  );

  let userForm = {
    job_title,
    phone_number,
    email,
    permissions: {
      ...(!isEntityUser && allEntities),
      ...userEntities,
    },
    can_be_assigned_to_wo,
    regular_rate: regular_rate ?? "",
    overtime_rate1: overtime_rate1 ?? "",
    overtime_rate2: overtime_rate2 ?? "",
  };

  if (isAuthenticatedUser) {
    userForm = { ...userForm, name, surname };
  }

  return userForm;
};

export const phoneNumberValidation = (phoneNumber) =>
  phoneNumber.length <= 4 ? "" : phoneNumber.replace(/\s/g, "");

export const imageValidation = (images) => {
  return images.length ? images[0].url : "";
};

export const getUserFormatForService = (form, entities) => {
  const {
    permissions,
    phone_number,
    images = [],
    password,
    oldPassword,
    ...otherFields
  } = form;

  const permissionValidFormat = entities?.data?.length
    ? entities?.data.map((entity) => {
        return {
          ...(permissions[entity.name] && {
            user_role_id: permissions[entity.name].value,
            entity_id: Object.keys(permissions).includes(entity.name)
              ? entity.id
              : null,
          }),
        };
      })
    : null;

  const data = getFormDataFromJSON({
    ...otherFields,
    ...(permissions && { permissions: permissionValidFormat }),

    phone_number: phoneNumberValidation(phone_number || ""),
  });

  if (images.length) {
    images.forEach((fileObj, index) => {
      if (fileObj.isRemove) {
        data.append(`uploads_attributes[${index}][remove]`, fileObj.id);
      } else if (fileObj.file) {
        data.append(`uploads_attributes[${index}][upload]`, fileObj.file);
      }
    });
  }

  return data;
};
