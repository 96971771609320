import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { formatReportBucket } from "modules/reporting/lib/formatters";
import {
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  parseISO,
  format,
} from "date-fns";

const startOfFns: Record<ReportBucket, (date: Date | number) => Date> = {
  day: startOfDay,
  week: startOfWeek,
  month: startOfMonth,
  year: startOfYear,
};

interface ChartData {
  date: string;
}

/**
 * Summarize chart data into week/month/year buckets.
 *
 * Assumes that the data is already sorted by date, and there are no gaps in the data.
 */
export function bucketData<T extends ChartData>(
  data: T[],
  bucket: ReportBucket,
  /** Current language, for formatting date labels */
  language: string,
  /** Function for summarizing the points in a bucket */
  sumFn: (points: T[]) => Omit<T, "date">
): T[] {
  if (!data) {
    return [];
  } else if (bucket === "day") {
    return data.map((i) => ({
      ...i,
      date: formatReportBucket(i.date, bucket, language),
    }));
  }

  // Build a map of bucket start dates to data point arrays
  const buckets: Record<string, T[]> = {};
  data?.forEach((i) => {
    const bucketStart = format(
      startOfFns[bucket](parseISO(i.date)),
      "yyyy-MM-dd"
    );
    if (!buckets[bucketStart]) {
      buckets[bucketStart] = [];
    }
    buckets[bucketStart].push(i);
  });

  return (
    Object.entries(buckets)
      // Get the summarized data for each bucket
      .map(([label, points]) => ({ ...sumFn(points), date: label } as T))
      // Format the bucket labels
      .map((i) => ({
        ...i,
        date: formatReportBucket(i.date, bucket, language),
      }))
  );
}
