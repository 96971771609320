import { useTranslation } from "react-i18next";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useParams } from "react-router-dom";
import { ConfirmationDialog } from "shared/components/Dialog";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";

import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";
import { useState } from "react";

export const DeleteReference = ({
  closeModal,
  deleteElement,
  variant,
  name,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { addSnackBar } = useSnackBar();

  const [deleteElementById] = deleteElement();

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const onDeleteHandle = () => {
    deleteElementById(id)
      .unwrap()
      .then(() => {
        setIsShowDeleteModal(false);
        closeModal();
        addSnackBar({
          content: t(
            `settings.referenceLists.${variant}.snack.deleteSuccessMessage`,
            {
              name,
            }
          ),
          type: "success",
        });
      })
      .catch(() => {
        setIsShowDeleteModal(false);
        addSnackBar({
          title: t(`settings.referenceLists.${variant}.snack.failDeleteTitle`),
          content: t(
            `settings.referenceLists.${variant}.snack.failDeleteContent`
          ),
          type: "warning",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const onCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
  };

  const openConfirmDialog = () => {
    setIsShowDeleteModal(true);
  };

  return (
    <>
      <StyledIconButton onClick={openConfirmDialog}>
        <DeleteBox>
          <TrashCanIcon />
          <span>{t("delete")}</span>
        </DeleteBox>
      </StyledIconButton>
      {isShowDeleteModal && (
        <ConfirmationDialog
          title={t(
            `settings.referenceLists.${variant}.deleteConfirmation.title`
          )}
          description={t(
            `settings.referenceLists.${variant}.deleteConfirmation.description`
          )}
          confirmButtonLabel={t(
            `settings.referenceLists.${variant}.deleteConfirmation.confirm`
          )}
          onProceed={onDeleteHandle}
          onCancel={onCloseDeleteModal}
          isDestructive
        />
      )}
    </>
  );
};

export const DeleteBox = styled.div`
  display: flex;
  gap: 8px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & .left-button {
    margin-left: auto;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin: 0 auto 0 16px;
  color: var(--color_primary_text);
`;
