import styled, { css } from "styled-components";

export const Container = styled.div`
  margin-top: 24px;
`;

export const Name = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color_primary_text);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color_grey900);
`;

export const Box = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 8px 0;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
`;

export const Info = styled.div`
  display: flex;
  width: 100%;
  word-break: break-all;
`;

export const Image = styled.div`
  min-width: 32px;
  min-height: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: var(--color_table_missing_icon_color);

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const Created = styled.span`
  color: var(--color_background);
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: auto;
`;

export const ContentView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ItemBox = styled.div`
  width: fit-content;
  padding: 0 2px;
  border-radius: 6px;
  line-height: 20px;
  color: ${({ type }) =>
    type === "from" ? "var(--color_red)" : "var(--color_grey900)"};
  background: ${({ type }) =>
    type === "from" ? "rgba(255, 120, 110, 0.3)" : "rgba(180, 240, 0, 0.5)"};
  ${(props) =>
    props.type === "from" &&
    css`
      text-decoration: line-through from-font var(--color_corp_red);
    `}
`;

export const ChecklistContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: 4px;
  align-items: center;
  background: ${({ checked }) =>
    checked ? "rgba(255, 120, 110, 0.3)" : "rgba(180, 240, 0, 0.5)"};
  padding: 0 2px;
  border-radius: 6px;
  line-height: 20px;
  ${(props) =>
    props.checked &&
    css`
      text-decoration: line-through from-font var(--color_corp_red);
    `}
  color: ${({ checked }) =>
    checked ? "var(--color_red)" : "var(--color_grey900)"};
`;

export const Title = styled.div`
  font-weight: 600;
  margin-right: 2px;
`;
