import React from "react";
import PropTypes from "prop-types";
import { useExportAssetMutation } from "modules/assets";
import { EmptyAssetList } from "./EmptyAssetList";
import { List } from "./List";

export const AssetList = ({ onOpenAsset, withExport }) => {
  const [exportAsset] = useExportAssetMutation();

  return (
    <List
      onOpenAsset={onOpenAsset}
      onExportClick={exportAsset}
      emptyListNode={<EmptyAssetList />}
      withExport={withExport}
    />
  );
};

AssetList.propTypes = {
  onOpenAsset: PropTypes.func,
};
