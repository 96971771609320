import styled from "styled-components";
import { ReactNode } from "react";

export interface AvatarProps {
  size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  src?: string;
  fallback: ReactNode;
  color?: string;
  alt?: string;
}

export const Avatar = ({ size, src, fallback, color, alt }: AvatarProps) => {
  return (
    <Root className={`size-${size}`}>
      {src ? (
        <AvatarImage src={src} alt={alt} />
      ) : (
        <Fallback $color={color}>{fallback}</Fallback>
      )}
    </Root>
  );
};

const Root = styled.span`
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  display: block;
  flex-shrink: 0;
  width: var(--avatar-size);
  height: var(--avatar-size);

  border-radius: 50%;
  font-weight: 500;
  user-select: none;

  &.size-xs {
    --avatar-size: 16px;
    font-size: 11px;
  }
  &.size-sm {
    --avatar-size: 24px;
    font-size: 11px;
  }
  &.size-md {
    --avatar-size: 32px;
    font-size: 14px;
  }
  &.size-lg {
    --avatar-size: 48px;
    font-size: 18px;
  }
  &.size-xl {
    --avatar-size: 64px;
    font-size: 28px;
  }
  &.size-xxl {
    --avatar-size: 96px;
    font-size: 32px;
  }

  position: relative;
  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    border: 1px solid #1f1e2414;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
`;

const Fallback = styled.span<{ $color?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-transform: uppercase;
  border-radius: inherit;
  background-color: ${(p) => p.$color};
  color: var(--color_text_color);
`;
