import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getOptions } from "modules/entities/lib/helpers";
import {
  useGetCurrencyQuery,
  useGetDateFormatQuery,
} from "modules/internalCollections/state/internalCollectionsApi";
import { SelectInnerSearch } from "shared/components/Select/SelectInnerSearch";

export const EntityEditor = ({ form, timezones }) => {
  const { t } = useTranslation();

  const { data: currency } = useGetCurrencyQuery();
  const { data: dateFormat } = useGetDateFormatQuery();

  const [timezonesOptions, dateFormatOptions, currencyOptions] = getOptions({
    timezones,
    dateFormat,
    currency,
  });

  return (
    <Box>
      <InlineLabel
        isRequired
        label={t("generalSettings.entity.name")}
        labelWidth={110}
      >
        <Input
          name="name"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("generalSettings.entity.name")}
          errorMessage={form.touched.name && form.errors.name}
          height={40}
          fontSize={14}
        />
      </InlineLabel>
      <DescriptionInlineLabel
        label={t("generalSettings.entity.description")}
        labelWidth={110}
        multiline
      >
        <Input
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("generalSettings.entity.description")}
          errorMessage={form.touched.description && form.errors.description}
          height={60}
          fontSize={14}
          isTextArea
        />
      </DescriptionInlineLabel>
      <InlineLabel label={t("generalSettings.entity.address")} labelWidth={110}>
        <Input
          name="address"
          value={form.values.address}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("generalSettings.entity.address")}
          errorMessage={form.touched.address && form.errors.address}
          height={40}
          fontSize={14}
        />
      </InlineLabel>
      <InlineLabel
        label={t("generalSettings.entity.currency")}
        labelWidth={110}
      >
        <SelectInnerSearch
          name="currency"
          options={currencyOptions}
          value={form.values.currency}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          errorMessage={form.touched.currency && form.errors.currency}
        />
      </InlineLabel>
      <InlineLabel
        label={t("generalSettings.entity.dateFormat")}
        labelWidth={110}
      >
        <SelectInnerSearch
          name="datetime_format"
          options={dateFormatOptions}
          value={form.values.datetime_format}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          errorMessage={
            form.touched.datetime_format && form.errors.datetime_format
          }
          isSearch={false}
        />
      </InlineLabel>

      <InlineLabel
        label={t("generalSettings.entity.timeZone")}
        labelWidth={110}
      >
        <SelectInnerSearch
          name="timezone"
          options={timezonesOptions}
          value={form.values.timezone}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          errorMessage={form.touched.timezone && form.errors.timezone}
        />
      </InlineLabel>
    </Box>
  );
};

const Box = styled.div`
  width: 480px;
  padding: 32px 16px;
`;

const DescriptionInlineLabel = styled(InlineLabel)`
  margin-top: 50px;
`;
