import { IProcedureTemplateItem } from "modules/procedures/types";
import { Heading, InlineHint } from "shared/components/Typography";
import styled from "styled-components";

interface ItemProps {
  item: IProcedureTemplateItem;
  itemTypes: { [key: string]: string };
}

const GenericItem = ({ item, itemTypes }: ItemProps) => (
  <div key={item.index}>
    <Heading>
      <InlineHint>#{item.index}</InlineHint> - {item.name}
    </Heading>
    <Description>
      Type: <em>{itemTypes ? itemTypes[item.item_type] : item.item_type}</em>
    </Description>
  </div>
);

const MultipleChoiceItem = ({ item, itemTypes }: ItemProps) => (
  <div key={item.index}>
    <Heading>
      <InlineHint>#{item.index}</InlineHint> - {item.name}
    </Heading>
    <Description>
      <p>
        Type: <em>{itemTypes ? itemTypes[item.item_type] : item.item_type}</em>
      </p>
      <p>Choices:</p>
      <ChoiceList>
        {item?.options?.choices?.map((choice, index) => (
          <Choice key={index}>{choice}</Choice>
        ))}
      </ChoiceList>
    </Description>
  </div>
);

export const Item = ({ item, itemTypes }: ItemProps) => {
  switch (item.item_type) {
    case "multiple_choice":
      return <MultipleChoiceItem item={item} itemTypes={itemTypes} />;

    default:
      return <GenericItem item={item} itemTypes={itemTypes} />;
  }
};

const ChoiceList = styled.ul`
  margin-left: 1rem;
`;

const Choice = styled.li`
  font-style: italic;
`;

const Description = styled.div`
  color: var(--color_text_color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0;
  white-space: pre-wrap;
`;
