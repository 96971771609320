import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "shared/lib/hooks/useForm";

import { Button } from "shared/components/Button";

import { Box, DividerStyled, Title, ActionBox, Container } from "../styled";
import { PasswordField } from "modules/auth/components/PasswordField";
import { passwordValidationSchema } from "modules/auth/config/validationSchema";
import { useResetPasswordMutation } from "modules/auth/state/authApi";

import { PasswordExpired } from "../AdditionalPages";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useSearchParams } from "react-router-dom";

const initialValues = {
  password: "",
};

export const ResetPasswordForm = ({ setTabs }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { addSnackBar } = useSnackBar();
  const [tokenExpired, setTokenExpired] = useState(false);

  const [doResetPassword] = useResetPasswordMutation();

  const onSubmit = async (body) => {
    doResetPassword({
      ...body,
      reset_password_token: token,
      password_confirmation: body.password,
    })
      .unwrap()
      .then(() => {
        setTabs((prev) => prev + 1);
      })
      .catch(({ error }) => {
        if (error === "Invalid or expired reset token.") {
          setTokenExpired(true);
        } else
          addSnackBar({
            content: error,
            type: "error",
          });
      });
  };

  const form = useForm({
    initialValues,
    validationSchema: passwordValidationSchema(t),
    onSubmit,
  });

  if (tokenExpired) {
    return <PasswordExpired onSubmit={form.handleSubmit} />;
  }

  return (
    <Container>
      <Box>
        <Title>{t("auth.resetPassword.newPassword")}</Title>
        <PasswordField form={form} variant={"password"} />
        <DividerStyled />
        <ActionBox>
          <Button
            disabled={!form.isFormValid}
            type="primary"
            size="normal"
            onClick={form.handleSubmit}
          >
            {t("auth.resetPassword.updatePasswordButton")}
          </Button>
        </ActionBox>
      </Box>
    </Container>
  );
};
