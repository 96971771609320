export const getTrackingFormatForService = (form, isManualType) => {
  const { status, downtime_type, note, started_at, ended_at, work_order_id } =
    form;

  const formData = new FormData();
  formData.append(`downtime_trackings_attributes[status]`, status);
  formData.append(`downtime_trackings_attributes[note]`, note);

  if (status === "offline") {
    formData.append(
      `downtime_trackings_attributes[downtime_type]`,
      downtime_type
    );
  }

  if (isManualType) {
    formData.append(`downtime_trackings_attributes[started_at]`, started_at);
    formData.append(`downtime_trackings_attributes[ended_at]`, ended_at);

    if (work_order_id && status === "offline") {
      formData.append(
        "downtime_trackings_attributes[work_order_id]",
        work_order_id
      );
    }
  }

  return formData;
};
