import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReactiveVsPreventiveChartData } from "./types";
import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { BarChart } from "modules/reporting/components/charts/BarChart";

import { LineChart } from "modules/reporting/components/charts/LineChart";
import { bucketData } from "modules/reporting/lib/bucketData";

interface ReactiveVsPreventiveReportChartProps {
  bucket: ReportBucket;
  data?: ReactiveVsPreventiveChartData[];
  isFetching: boolean;
}

const reportKey = ReportKey.ReactiveVsPreventive;
export const ReactiveVsPreventiveReportChart = ({
  bucket,
  data,
  isFetching,
}: ReactiveVsPreventiveReportChartProps) => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  const chartData = bucketData(data ?? [], bucket, language, (points) =>
    points.reduce(
      (bucket, p) => {
        bucket.reactive += p.reactive;
        bucket.preventive += p.preventive;
        return bucket;
      },
      { reactive: 0, preventive: 0 }
    )
  );

  if (chartData && chartData.length > 1) {
    return (
      <LineChart
        isLoading={isFetching}
        data={chartData}
        xAxisKey="date"
        lines={[
          {
            dataKey: "reactive",
            name: t(`reporting.${reportKey}.labels.reactive`) as string,
            stroke: "var(--color_salmon)",
            strokeWidth: 2,
            type: "monotone",
          },
          {
            dataKey: "preventive",
            name: t(`reporting.${reportKey}.labels.preventive`) as string,
            stroke: "var(--color_mint)",
            strokeWidth: 2,
            type: "monotone",
          },
        ]}
      />
    );
  }

  return (
    <BarChart
      isLoading={isFetching}
      data={chartData}
      dataAxisKey="date"
      bars={[
        {
          dataKey: "reactive",
          name: t(`reporting.${reportKey}.labels.reactive`) as string,
          fill: "var(--color_salmon)",
        },
        {
          dataKey: "preventive",
          name: t(`reporting.${reportKey}.labels.preventive`) as string,
          fill: "var(--color_mint)",
        },
      ]}
    />
  );
};
