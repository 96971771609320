import { useCallback, useMemo } from "react";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration/state/adminFieldsConfigurationApi";

export const useVisibleFields = (type) => {
  const { data, isLoading } = useGetAdminConfigurationQuery({
    type,
  });

  const fields = useMemo(() => {
    if (!data) {
      return null;
    }

    return Object.entries(data.data.payload).map(([key]) => key);
  }, [data]);

  const visibleFields = useMemo(() => {
    if (!data) {
      return null;
    }

    return Object.entries(data.data.payload)
      .filter(([, { visible }]) => visible)
      .map(([key]) => key);
  }, [data]);

  const isFieldVisible = useCallback(
    (name) => {
      if (!visibleFields) {
        return false;
      }

      if (!fields.includes(name)) {
        return true;
      }

      return visibleFields.includes(name);
    },
    [visibleFields, fields]
  );

  return { visibleFields, areVisibleFieldsLoading: isLoading, isFieldVisible };
};
