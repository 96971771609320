import { Fragment } from "react";
import { parseISO, clamp } from "date-fns";
import {
  Wrapper,
  Chart,
  TrackBar,
  TrackLabel,
  Track,
} from "./ReliabilityHistoryChart.styled";
import { XAxis } from "./XAxis";
import { ReliabilitySummary } from "./ReliabilitySummary";
import { AssetStatus, Bar } from "./types";

interface DowntimeHistoryChartProps {
  startDate: string;
  endDate: string;
  data?: {
    created_at: string;
    updated_at: string;
    created_by_id: number;
    updated_by_id: number | null;
    id: number;
    downtime_type: string | null;
    note: string;
    status: AssetStatus;
    target_type: string;
    target_id: number;
    started_at: string;
    ended_at: string | null;
  }[];
}

const chartColors = {
  online: "var(--color_lime)",
  offline: "var(--color_red)",
  not_tracking: "var(--color_grey500)",
};

export const ReliabilityHistoryChart = ({
  data,
  startDate,
  endDate,
}: DowntimeHistoryChartProps) => {
  if (!data) {
    return null;
  }

  const start = parseISO(startDate);
  const end = clamp(parseISO(endDate), { start, end: new Date() });
  const startTime = start.getTime();
  const totalTime = end.getTime() - startTime;

  const bars: Bar[] = data
    .map((event) => {
      const barStart = clamp(parseISO(event.started_at), { start, end });
      const barEnd = event.ended_at
        ? clamp(parseISO(event.ended_at), { start, end })
        : end;

      const duration = barEnd.getTime() - barStart.getTime();
      return {
        start: barStart.getTime(),
        end: barEnd.getTime(),
        duration,
        status: event.status,
      };
    })
    .filter((b) => b.duration);

  const tracks = [
    { name: "Online", bars: bars.filter((i) => i.status === "online") },
    { name: "Offline", bars: bars.filter((i) => i.status === "offline") },
  ];
  const notTracking = bars.filter((i) => i.status === "not_tracking");
  if (notTracking.length) {
    tracks.push({
      name: "Not Tracking",
      bars: notTracking,
    });
  }

  return (
    <Wrapper>
      <ReliabilitySummary {...{ bars, totalTime }} />
      <Chart aria-hidden={true}>
        {tracks.map((track, tIndex) => (
          <Fragment key={tIndex}>
            <TrackLabel>{track.name}</TrackLabel>
            <Track key={tIndex}>
              {track.bars.map((bar) => (
                <TrackBar
                  key={[bar.start, bar.end, bar.duration].join("-")}
                  style={{
                    left: `${((bar.start - startTime) / totalTime) * 100}%`,
                    width: `${(bar.duration / totalTime) * 100}%`,
                    backgroundColor: chartColors[bar.status],
                  }}
                />
              ))}
            </Track>
          </Fragment>
        ))}
        <XAxis {...{ start, end }} />
      </Chart>
    </Wrapper>
  );
};
