import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View, Image } from "@react-pdf/renderer";

import { pageStyle } from "shared/components/BulkPdfExport/BulkPdfExport.styled.js";
import { useDisplayDate } from "shared/lib/hooks";
import { formatDateWithDayOfWeek } from "shared/lib/helpers/common";
import { getRepeatsText } from "modules/preventiveMaintenances";
import { convertTimeToCompleteHoursToDays } from "../../lib/helpers/index";

import DocumentIcon from "shared/assets/icon/pdf/document.png";
import CriticalIcon from "shared/assets/icon/pdf/critical.png";
import CompletedIcon from "shared/assets/icon/pdf/completed.png";
import HighIcon from "shared/assets/icon/pdf/high.png";
import HoldIcon from "shared/assets/icon/pdf/hold.png";
import LowIcon from "shared/assets/icon/pdf/low.png";
import MediumIcon from "shared/assets/icon/pdf/medium.png";
import NoneIcon from "shared/assets/icon/pdf/none.png";
import ProgressIcon from "shared/assets/icon/pdf/progress.png";
import { PDFChecklist } from "shared/components/PDFChecklist";

const StatusMap = {
  completed: CompletedIcon,
  planned: ProgressIcon,
  paused: HoldIcon,
};

const PriorityMap = {
  none: NoneIcon,
  low: LowIcon,
  medium: MediumIcon,
  high: HighIcon,
  critical: CriticalIcon,
};

export const PMsPdfPage = ({ resource = {} }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    template_work_order: {
      asset,
      location,
      title: name,
      priority,
      description,
      images = [],
      documents = [],
      vendors = [],
      categories = [],
      type,
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      // teams = [],
      technicians = [],
      checklist,
      parts_transactions,
    },
    id,
    updated_at,
    status,
    type: pm_type,
    start_date,
    time_to_complete,
    next_order_date,
    created_at,
    created_by,
    repeat_occurrence,
    repeat_frequency,
    on_month_day,
    on_week_days,
    in_months,
  } = resource;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const partsTransactions = parts_transactions.map((val) => ({
    ...val,
    part: { ...val.part, label: val.part.name, value: val.part.id },
  }));

  const StatusIcon = StatusMap[status];
  const PriorityIcon = PriorityMap[priority];

  const createdByName =
    created_by && `${created_by.name || ""} ${created_by.surname || ""}`;
  const created = created_at && `on ${displayDate(created_at)}`;

  const repeatText =
    pm_type === "one_time"
      ? t(
          "work_orders.form.pm_schedule_attributes.information.work_order_detail",
          {
            value: next_order_date
              ? formatDateWithDayOfWeek(next_order_date, true)
              : "",
          }
        )
      : getRepeatsText({
          repeat_occurrence: repeat_occurrence,
          repeat_frequency: repeat_frequency,
          on_month_day: on_month_day,
          on_week_days: on_week_days,
          in_months: in_months,
          scheduleType: pm_type,
        });

  return (
    <>
      <View style={pageStyle.row}>
        <div style={pageStyle.row}>
          <Image src={PriorityIcon} style={pageStyle.icon} />
          <Text style={{ ...pageStyle.smallText, ...pageStyle.boldText }}>
            Priority {t(`work_orders.priority.${priority}`)}
          </Text>
        </div>
        <Text style={{ ...pageStyle.smallText, ...pageStyle.boldText }}>
          ID {id}
        </Text>
      </View>
      <div style={pageStyle.section}>
        <View style={pageStyle.description}>
          <Text style={pageStyle.h3}>{name}</Text>
          <Text style={pageStyle.mediumText}>{description}</Text>
        </View>
      </div>
      <View style={pageStyle.border} />
      <div style={pageStyle.section}>
        <View style={pageStyle.row}>
          <Text style={pageStyle.h4}>Details</Text>
          <Text style={pageStyle.tinyText}>
            Last update on {displayDate(updated_at)}
          </Text>
        </View>
        <div style={pageStyle.detailColumns}>
          <div style={pageStyle.detailColumn}>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Status</Text>
              <div style={pageStyle.row}>
                <View style={pageStyle.row}>
                  {StatusIcon && (
                    <Image src={StatusIcon} style={pageStyle.icon} />
                  )}
                  <Text style={pageStyle.boldText}>
                    {status && t(`preventive_maintenances.status.${status}`)}
                  </Text>
                </View>
              </div>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Starting from</Text>
              <Text style={pageStyle.boldText}>
                {displayDate(start_date, true)}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Time to complete</Text>
              <Text style={pageStyle.boldText}>
                {time_to_complete
                  ? convertTimeToCompleteHoursToDays(time_to_complete)
                  : ""}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Work Type</Text>
              <Text style={pageStyle.boldText}>
                {" "}
                {type && t(`work_orders.type.${type}`)}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Schedule</Text>
              <Text style={pageStyle.boldText}>
                {" "}
                {pm_type && t(`work_orders.schedule_type.${pm_type}`)}
              </Text>
              <Text>{repeatText}</Text>
            </View>
          </div>
          <div style={pageStyle.detailColumn}>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Category</Text>
              <Text style={pageStyle.boldText}>
                {categories.map(({ name }) => name).join(", ")}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Location</Text>
              <Text style={pageStyle.boldText}>{location?.path}</Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Asset</Text>
              <Text style={pageStyle.boldText}>{asset?.name}</Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Assigned to</Text>
              <Text style={pageStyle.boldText}>
                {technicians
                  .map(({ name, surname }) => `${name} ${surname}`)
                  .join(", ")}
                {/* TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1431}, will be returned in R3/R4*/}
                {/* {teams
                  .concat(technicians)
                  .map(({ name }) => name)
                  .join(", ")} */}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Created</Text>
              <Text style={pageStyle.boldText}>{createdByName}</Text>
              <Text>{created}</Text>
            </View>
          </div>
          <div style={pageStyle.detailColumn}>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Vendor</Text>
              {vendors.map((vendor) => (
                <View>
                  <Text style={pageStyle.boldText}>{vendor.name}</Text>
                  {vendor.vendor_contacts.map((contact) => (
                    <View>
                      {!!contact.is_primary && (
                        <View>
                          <Text>{contact?.name}</Text>
                          <Text>{contact?.phone}</Text>
                          <Text>{contact?.email}</Text>
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <View style={pageStyle.border} />
          <div style={pageStyle.section}>
            <Text style={pageStyle.h4}>Media and files attached</Text>
            <div style={pageStyle.detail}>
              <View style={pageStyle.imageRow}>
                {images.map((image) => (
                  <Image src={image.url} style={pageStyle.image} />
                ))}
                {documents.map((file) => (
                  <View style={pageStyle.documentIcon}>
                    <Image src={DocumentIcon} style={pageStyle.logo} />
                    <Text style={pageStyle.tinyText}>{file.name}</Text>
                  </View>
                ))}
              </View>
            </div>
          </div>
        </>
      )}
      {checklist && checklist.length > 0 && <View style={pageStyle.border} />}
      <div style={pageStyle.section}>
        <div style={pageStyle.commentsColumns}>
          {checklist && checklist.length > 0 && (
            <div style={pageStyle.commentsColumn}>
              <Text style={pageStyle.h4}>
                {t("work_orders.details.checklist")}
              </Text>
              <PDFChecklist items={checklist} />
            </div>
          )}
        </div>
      </div>
      {partsTransactions.length > 0 && (
        <>
          <View style={pageStyle.border} />
          <div style={pageStyle.section}>
            <Text style={pageStyle.h4}>{t("work_order_parts.title")}</Text>
            <div style={pageStyle.detail}>
              <View style={pageStyle.detail}>
                {partsTransactions.map(({ part, quantity }) => (
                  <View style={pageStyle.row}>
                    <Text>{part.label}</Text>
                    <Text>
                      {quantity} {part.measurement_unit?.short_name || "unit"}
                    </Text>
                  </View>
                ))}
              </View>
            </div>
          </div>
        </>
      )}
    </>
  );
};
