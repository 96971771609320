import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import { EditorModal } from "shared/components/Modals";
import { useGetFormFieldsQuery } from "modules/formFields";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { usePermission } from "app/providers/PermissionsProvider";

import { useUpdateRequestMutation, useGetRequestQuery } from "modules/requests";

import {
  requestValidationSchema,
  getInitialValues,
  getOptionalFields,
  getRequestFormatForService,
} from "../../lib/helpers/form";

import { RequestEditor } from "../RequestEditor";

export const RequestEdit = ({ isShow, onClose, onOpen, requestId }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [updateRequest, updateRequestRes] = useUpdateRequestMutation();

  const { canUpdateRequest } = usePermission();

  const { data: fields } = useGetFormFieldsQuery({
    type: "work_order_requests",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields({ fields, type: "edit" });

  const { data } = useGetRequestQuery(requestId ?? skipToken);

  const requestDataForForm = useMemo(
    () => getInitialValues({ type: "edit", data }),
    [data]
  );

  const onCloseModal = () => {
    updateRequestForm.resetForm();
    onClose();
  };

  const { setValues, ...updateRequestForm } = useForm({
    initialValues: requestDataForForm,
    validationSchema: requestValidationSchema(t, optionalFields),
    willUpdatedValues: requestDataForForm,
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const request = getRequestFormatForService(body);
      updateRequest({ id: requestId, body: request })
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("work_order_requests.snack.successUpdateTitle"),
            content: t("work_order_requests.snack.successUpdateMessage", {
              name: data.title,
            }),
            type: "success",
            primaryButtonLabel: t("work_order_requests.snack.view"),
            secondaryButtonLabel: t("close"),
            handlePrimaryButtonClick: () => onOpen(data.id),
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            setFieldError("title", t("m11", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("work_order_requests.snack.failUpdateTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  if (!canUpdateRequest(data?.status, data?.created_by_id)) return null;

  return (
    <EditorModal
      isOpen={isShow}
      label={t("work_order_requests.edit", { id: requestId })}
      onClose={onCloseModal}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={updateRequestForm.handleSubmit}
      disabledSubmit={
        !updateRequestForm.isWasChanged || !updateRequestForm.isFormValid
      }
      isSubmitLoading={updateRequestRes.isLoading || updateRequestRes.isSuccess}
      form={updateRequestForm}
    >
      <RequestEditor
        type="edit"
        form={updateRequestForm}
        optionalFields={optionalFields}
      />
    </EditorModal>
  );
};
