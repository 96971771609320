import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import {
  formatCurrency,
  formatMTTR,
  formatNumber,
  formatPercent,
} from "modules/reporting/lib/formatters";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { CompletedByCategoryKPIs } from "./types";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";

const reportKey = ReportKey.CompletedByCategory;
export const useCompletedByCategoryKPIs = (reportPeriod: ReportPeriod) => {
  const { data, ...rest } = useGetKPIs<CompletedByCategoryKPIs>(
    reportKey,
    reportPeriod
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { currency } = useLocaleSettings();
  const topCosts = data?.top_costs
    ? Object.entries(data?.top_costs).reduce(
        (
          acc: {
            label: string;
            value: string;
          }[],
          [key, value]
        ) => {
          acc.push({
            label: t(`reporting.${reportKey}.kpis.top_expenses`, {
              category: key,
            }),
            value: formatCurrency(value, language, currency),
          });
          return acc;
        },
        []
      )
    : [];

  const mttrValue = (
    mttr: number | undefined,
    t: (t: string, p: object) => string
  ) => {
    if (typeof mttr === "number") {
      return formatMTTR(mttr, t);
    } else {
      return "-";
    }
  };
  return {
    ...rest,
    data: {
      detailKpis: topCosts,
      kpis: [
        {
          value: formatNumber(data?.completed, language),
          label: t(`reporting.${reportKey}.kpis.completed`),
        },
        {
          value:
            typeof data?.avg_ttc == "number"
              ? formatMTTR(data?.avg_ttc, t)
              : "-",
          label: t(`reporting.${reportKey}.kpis.avg_ttc`),
        },
        {
          value: formatNumber(data?.on_time, language),
          label: t(`reporting.${reportKey}.kpis.on_time`),
        },
        {
          value: formatPercent(data?.on_time_percentage, language, {
            maximumFractionDigits: 2,
          }),
          label: t(`reporting.${reportKey}.kpis.on_time_percentage`),
        },
        {
          value: mttrValue(data?.mttr, t),
          label: t(`reporting.${reportKey}.kpis.mttr`),
        },
        {
          value: formatCurrency(data?.total_cost, language, currency),
          label: t(`reporting.${reportKey}.kpis.total_cost`),
        },
      ],
    },
  };
};
