import styled from "styled-components";

import { FileManager } from "modules/fileManager";

import Texture from "../../assets/texture.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 35%;
  color: var(--color_dark_blue);
  background-image: url(${Texture});
  padding-right: 20px;

  @media (min-width: 1100px) {
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      left: 40%;
      transform: translateX(-50%);
      width: 1px;
      height: calc(100% - 20px);
      top: -76px;
      background-color: var(--color_grey100);
    }
  }
`;

export const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;

export const Name = styled.div`
  font-size: 23px;
  line-height: 26.45px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const Portal = styled.div`
  font-size: 32px;
  line-height: 36.8px;
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
`;

export const Instructions = styled.div`
  line-height: 16px;
  font-weight: 500;
  font-size: 14px;
  white-space: pre-wrap;
`;
