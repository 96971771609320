import React from "react";
import PropTypes from "prop-types";
import CopyIcon from "shared/assets/icon/copy.svg?react";
import EditIcon from "shared/assets/icon/edit.svg?react";
import { IconButton } from "shared/components/IconButton";
import { usePermission } from "app/providers/PermissionsProvider";
import {
  NameBox,
  Name,
  Role,
  EmailBox,
  PhoneBox,
  ContactBox,
  PrimaryBox,
  Container,
  InputBox,
  StyledIconButton,
} from "./ContactView.styled";

export const ContactView = ({ value = {}, onCopy, onEdit, index }) => {
  const onCopyHandle = () => onCopy(value.email);

  const { vendorUpdateContactPermit } = usePermission();

  return (
    <Container>
      <InputBox>
        {value.name && (
          <NameBox>
            <Name>{value.name}</Name>
            {value.is_primary && <PrimaryBox>Primary Contact</PrimaryBox>}
          </NameBox>
        )}
        {value.role && <Role>{value.role}</Role>}
        <ContactBox>
          {value.email && (
            <EmailBox>
              <div>{value.email}</div>
              <IconButton onClick={onCopyHandle}>
                <CopyIcon />
              </IconButton>
            </EmailBox>
          )}
          <PhoneBox>
            {value.phone} {value.extension ? `Ext. ${value.extension}` : ""}
          </PhoneBox>
        </ContactBox>
        {vendorUpdateContactPermit && (
          <StyledIconButton onClick={() => onEdit(index)} className="editIcon">
            <EditIcon />
          </StyledIconButton>
        )}
      </InputBox>
    </Container>
  );
};

ContactView.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    is_primary: PropTypes.bool,
  }),
  onCopy: PropTypes.func,
};
