import { Font, StyleSheet } from "@react-pdf/renderer";

import BoldFont from "shared/assets/fonts/Poppins-Bold.ttf";
import SemiBoldFont from "shared/assets/fonts/Poppins-SemiBold.ttf";
import RegularFont from "shared/assets/fonts/Poppins-Regular.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: RegularFont },
    { src: SemiBoldFont, fontWeight: "semibold" },
    { src: BoldFont, fontWeight: "bold" },
  ],
});

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 50,
    paddingHorizontal: 35,
    fontFamily: "Poppins",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,

    color: "var(--color_grey900)",
    fontSize: 8,
    fontWeight: 600,
  },
  h1: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 20,
    borderBottom: "1px solid var(--color_background_dark)",
  },
  h2: {
    fontSize: 10,
    fontWeight: 600,
  },
  h3: {
    fontSize: 8,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  kpis: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 8,
  },
  kpiItem: {
    marginRight: 16,
  },
  kpiLabel: {
    fontSize: 8,
    fontWeight: 400,
  },
  kpiValue: {
    fontSize: 10,
    fontWeight: 600,
  },
  chart: {
    marginTop: 8,
  },
});
