import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { createBaseQuery } from "shared/state";

export const CMMSAPI = createApi({
  reducerPath: "cmms-api",
  baseQuery: createBaseQuery(),
  keepUnusedDataFor: 10 * 60,
  tagTypes: [
    "AdminCategories",
    "AdminConfigurations",
    "AdminPartTypes",
    "AdminStorerooms",
    "AllUsers",
    "Asset",
    "AssetDetails",
    "AssetTypes",
    "Categories",
    "Comment",
    "Currency",
    "Customers",
    "DateFormat",
    "DowntimeTracking",
    "Entity",
    "Entities",
    "EntityUsers",
    "FieldConfiguration",
    "File",
    "FormFields",
    "Location",
    "LocationDetails",
    "Notifications",
    "PartTransactions",
    "PartTypes",
    "Parts",
    "PreventiveMaintenance",
    "Procedure",
    "ProcedureDetails",
    "ProcedureTemplate",
    "ProcedureTemplateDetails",
    "ProcedureItemTypes",
    "QRCode",
    "QRCodesList",
    "Report",
    "RequestPortals",
    "Requests",
    "Sessions",
    "Storerooms",
    "Teams",
    "Technicians",
    "TimeZones",
    "Vendor",
    "VendorDetails",
    "WorkOrder",
    "WorkOrderOtherCost",
    "WorkOrderTime",
    "WorkOrderDetails",
  ],
  endpoints: () => ({}),
});
