import styled from "styled-components";
import { User } from "modules/users/types/User";
import { UserAvatar } from "shared/components/UserAvatar";
import { Tooltip } from "react-tooltip";
import { createPortal } from "react-dom";

interface UserAvatarGroupProps {
  users: User[];
  className?: string;
  /** Maximum number of users to show before "+N" */
  max?: number;
  /** If true, displays a row of user avatars. If false, displays a list of avatars and names. */
  compact?: boolean;
}

/** Displays a list of user avatars and names */
export const UserAvatarGroup = ({
  users,
  className,
  max,
  compact,
}: UserAvatarGroupProps) => {
  if (!users.length) {
    return null;
  }

  let maxUsers = max || users.length;
  if (users.length - maxUsers === 1) {
    // If there is only one extra user, show it instead of "+1"
    maxUsers++;
  }

  const remainingCount = Math.max(users.length - maxUsers, 0);
  const truncatedList = users
    .slice(0, maxUsers)
    .map((u) => ({ ...u, status: "active" }));

  // This should really use a popover on click, but we don't currently have a component for that
  const count = !!remainingCount && (
    <>
      <RemainingCount data-tooltip-id="remaining-tooltip">
        +{remainingCount}
      </RemainingCount>
      {createPortal(
        <StyledTooltip id="remaining-tooltip" place="top" opacity={1}>
          <UserAvatarGroup users={users.slice(maxUsers)} />
        </StyledTooltip>,
        document.body
      )}
    </>
  );

  if (compact && truncatedList.length > 1) {
    return (
      <CompactList className={className}>
        {truncatedList.map((user) => (
          <li
            key={user.id}
            data-tooltip-id="item-tooltip"
            data-tooltip-content={`${user.name} ${user.surname}`}
          >
            <UserAvatar user={user} size="sm" />
          </li>
        ))}
        {count}
        {createPortal(
          <StyledTooltip id="item-tooltip" place="top" opacity={1} />,
          document.body
        )}
      </CompactList>
    );
  }

  return (
    <List>
      {truncatedList.map((user) => (
        <Item key={user.id}>
          <UserAvatar user={user} size="sm" />
          <span>
            {user.name} {user.surname}
          </span>
        </Item>
      ))}
      {count}
    </List>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 0;
`;

const CompactList = styled.ul`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RemainingCount = styled.span`
  color: var(--color_text_color);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  width: 24px;
  user-select: none;
`;

const StyledTooltip = styled(Tooltip)`
  background-color: #000;
`;
