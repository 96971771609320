import PropTypes from "prop-types";
import React from "react";
import { ForgotPassword } from "modules/auth/components/RecoveryPassword";

export const ForgotPasswordPage = () => {
  document.title = "Forgot Password";

  return <ForgotPassword />;
};

ForgotPasswordPage.propTypes = {
  history: PropTypes.object,
};
