import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { LightModal } from "shared/components/Modals/LightModal";
import { InlineLabel } from "shared/components/widgets";

import styled from "styled-components";

export const Modal = ({ show, form, onClose, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <LightModal
        isOpen={show}
        onClose={onClose}
        onCancel={onClose}
        secondaryButtonLabel={t("discardChanges")}
        label={t("profile.email.modalTitle")}
        onSubmit={handleSubmit}
        disabledSubmit={!form.isWasChanged || !form.isFormValid}
      >
        <Box>
          <InlineLabel label={t("profile.email.title")} labelWidth={110}>
            <Input
              name="email"
              value={form.values.email}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeholder={t("profile.email.title")}
              errorMessage={form.touched.email && form.errors.email}
              height={40}
              fontSize={14}
            />
          </InlineLabel>
        </Box>
      </LightModal>
    </>
  );
};

const Box = styled.div`
  width: 480px;
  padding: 25px 16px 38px;
`;
