import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";
import { addContactValidationSchema } from "../../configs/validationSchema";
import { ContactEditor } from "../ContactEditor";
import { INITIAL_VALUES } from "../../configs/constants";

export const ContactCreate = ({ onClose, onConfirm, existingContacts }) => {
  const { t } = useTranslation();

  const addContactForm = useForm({
    initialValues: INITIAL_VALUES,
    validationSchema: addContactValidationSchema(t, existingContacts),
    enableReinitialize: true,
    onSubmit: (body) => onConfirm(body),
  });

  const onCloseModal = () => {
    addContactForm.resetForm();
    onClose();
  };

  return (
    <ConfirmationDialog
      title={t("contacts.add")}
      confirmButtonLabel={t("submit")}
      cancelButtonLabel={t("cancel")}
      form={addContactForm}
      disabledSubmit={
        !addContactForm.dirty || !!Object.values(addContactForm.errors).length
      }
      description={
        <ContactEditor
          form={addContactForm}
          existingContacts={existingContacts}
        />
      }
      onProceed={addContactForm.handleSubmit}
      onCancel={onCloseModal}
    />
  );
};

ContactCreate.propTypes = {
  existingContacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      is_primary: PropTypes.bool,
    })
  ),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
