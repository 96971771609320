import i18n from "i18next";

export const rateTypeOptions = [
  { key: "regular_rate", value: i18n.t("work_order_time.rate_type.regular") },
  {
    key: "overtime_rate1",
    value: i18n.t("work_order_time.rate_type.overtime_1"),
  },
  {
    key: "overtime_rate2",
    value: i18n.t("work_order_time.rate_type.overtime_2"),
  },
];
