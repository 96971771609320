import { ReactNode, useContext } from "react";
import styled from "styled-components";
import { RadioButtonGroupContext } from "./RadioButtonGroup";

export type RadioButtonValue = string | number;

interface RadioButtonProps {
  children: ReactNode;
  value: RadioButtonValue;
  onSelect: (value: RadioButtonValue) => void;
}

export const RadioButton = ({
  children,
  onSelect,
  value,
}: RadioButtonProps) => {
  const { value: activeValue, disabled } = useContext(RadioButtonGroupContext);

  return (
    <RadioButtonContainer
      disabled={disabled}
      active={activeValue === value}
      onMouseDown={() => {
        if (disabled) return;
        onSelect(value);
      }}
    >
      {children}
    </RadioButtonContainer>
  );
};

const RadioButtonContainer = styled.button<{ active?: boolean }>`
  width: 100%;
  border: ${({ active }) =>
    active
      ? "2px solid var(--color_primary_text)"
      : "1px solid var(--color_grey200)"};
  background-color: ${({ active }) =>
    active ? "var(--color_primary_hover)" : "var(--color_background_light)"};

  &:hover {
    border-color: var(--color_primary_dark);
    background-color: var(--color_primary_hover);
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:disabled {
    background-color: ${({ active }) =>
      active ? "var(--color_primary_hover)" : "var(--color_background_light)"};
    border: var(--color_primary_dark_40);
    box-shadow: none;
    cursor: initial;
  }
`;
