import { InlineLabel } from "shared/components/widgets";
import { H6 } from "shared/components/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { SelectInnerSearch } from "shared/components/Select/SelectInnerSearch";

/** Dropdowns for entity-specific user permissions */
export const UserEntityPermissions = ({ form, roleOptions }) => {
  const { t } = useTranslation();

  return (
    <>
      <Name>{t("allUsers.form.blocks.entities")}</Name>
      <ErrorMessage>{form.dirty && form.errors.permissions}</ErrorMessage>
      <EntityList>
        {Object.entries(form.values?.permissions).map(([key]) => (
          <InlineLabel key={key} label={key} labelWidth={110} columnLabel>
            <SelectInnerSearch
              name={`permissions["${key}"]`}
              isSearch={false}
              value={form.values?.permissions[key]}
              options={roleOptions}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              placeholder={t("allUsers.form.startTyping")}
            />
          </InlineLabel>
        ))}
      </EntityList>
    </>
  );
};

const Name = styled(H6)`
  margin-right: 4px;
`;

const EntityList = styled.div`
  & > *:first-child {
    margin-top: 8px;
  }
`;

export const ErrorMessage = styled.div`
  min-height: 1lh;
  color: var(--color_alert);
  font-size: 14px;
  margin-top: 6px;
`;
