export const ChartColors = [
  "var(--color_mint)",
  "var(--color_claret)",
  "var(--color_chart_lime)",
  "var(--color_dark_purple)",
  "var(--color_caput_mortuum)",
  "var(--color_blue)",
  "var(--color_dark_orange)",
  "var(--color_salmon)",
  "var(--color_dark_red)",
  "var(--color_pink)",
  "var(--color_taupe)",
  "var(--color_light_grey)",
  "var(--color_brand_orange_red)",
];
