import styled from "styled-components";
import { ReactNode } from "react";
import { IconButton } from "shared/components/IconButton";
import ExpandIcon from "../../assets/expand-icon.svg?react";

interface ReportListWidgetProps {
  label: string;
  onClickExpand?: () => void;
  children: ReactNode;
}

/** Container component for the items on the ReportListPage */
export const ReportListWidget = ({
  label,
  onClickExpand,
  children,
}: ReportListWidgetProps) => {
  return (
    <Wrapper>
      <Header>
        <WidgetLabel>{label}</WidgetLabel>
        {onClickExpand && (
          <IconButton variant="primary" onClick={onClickExpand}>
            <ExpandIcon />
          </IconButton>
        )}
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 250px;
  min-width: 250px;

  border-radius: 10px;
  border: 1px solid var(--color_grey100);
  background: var(--color_background_light);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const WidgetLabel = styled.strong`
  color: var(--color_dark_blue);
  font-weight: 600;
`;

const Content = styled.div`
  flex: 1 1 auto;
`;
