import { StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import Checkbox from "./checkbox.png?url";
import Checked from "./checkbox-checked.png?url";

interface PDFChecklistProps {
  items: {
    text: string;
    order: string;
    checked: string;
  }[];
}

export const PDFChecklist = ({ items }: PDFChecklistProps) => {
  return (
    <View>
      {items.map((item, index) => (
        <View key={index} style={styles.checklistItem}>
          <Image
            key={index}
            style={styles.checkbox}
            src={item.checked === "true" ? Checked : Checkbox}
          />
          <Text style={{ fontWeight: "bold" }}>{item.order}.</Text>
          <Text style={styles.checklistItemText}>{item.text}</Text>
        </View>
      ))}
    </View>
  );
};

export const styles = StyleSheet.create({
  checkbox: {
    width: 12,
    height: 12,
    flexShrink: 0,
  },
  checklistItem: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    color: "#000000",
    fontSize: 8,
    marginTop: 4,
  },
  checklistItemText: {
    width: "100%",
    paddingBottom: 4,
    borderBottom: "1px solid #BFBFC3",
  },
});
