import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { WorkOrderPartCreate } from "../WorkOrderPartCreate";
import {
  Box,
  Title,
  StyledButton,
  ActionButtons,
  Wrapper,
} from "./WorkOrderPartList.styled";
import { WorkOrderPartView } from "../WorkOrderPartView";

export const WorkOrderPartList = ({
  type,
  module,
  value = [],
  onCreate,
  onUpdate,
  onDelete,
  titleFontSize,
  assetId,
  workOrderStatus,
  permissions = { add: true, edit: true, delete: true },
}) => {
  const { t } = useTranslation();
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);

  const onCloseCreateForm = () => setIsOpenCreateForm(false);
  const onOpenCreateForm = () => setIsOpenCreateForm(true);

  return (
    <>
      <Box>
        <Title fontSize={titleFontSize}>{t("work_order_parts.title")}</Title>
        {permissions?.add && (
          <ActionButtons>
            <StyledButton onClick={onOpenCreateForm} variant="secondary">
              {t("work_order_parts.add")}
            </StyledButton>
          </ActionButtons>
        )}
      </Box>
      <Wrapper>
        {value.map((val, index) => (
          <WorkOrderPartView
            key={val.id}
            value={val}
            index={index}
            onUpdate={onUpdate}
            workOrderStatus={workOrderStatus}
            onDelete={onDelete}
            type={type}
            module={module}
            permissions={permissions}
          />
        ))}
      </Wrapper>
      {isOpenCreateForm && (
        <WorkOrderPartCreate
          onCreate={onCreate}
          onClose={onCloseCreateForm}
          assetId={assetId}
        />
      )}
    </>
  );
};

WorkOrderPartList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      part: PropTypes.object,
      quantity: PropTypes.string,
    })
  ),
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  titleFontSize: PropTypes.string,
  assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workOrderStatus: PropTypes.string,
};
