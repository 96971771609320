import {
  getFormDataFromJSON,
  addFilesForFormData,
} from "shared/lib/helpers/formData";
import ClearIcon from "shared/assets/icon/clear.svg?react";
import CheckIcon from "shared/assets/Check.svg?react";
import { StarRatings } from "shared/components/StarRatings";
import { LinksList } from "shared/components/LinksList";

import {
  VendorFormOptionalFields,
  initialValues,
} from "modules/vendors/configs/constants";
import { formatCurrency } from "shared/lib/helpers/common";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLinksList } from "shared/components/ModalLinksList";

export const getFormatForVendorService = (body) => {
  const {
    uploads_attributes,
    locations,
    categories,
    assets,
    parts,
    ...bodyAtr
  } = body;
  const formData = getFormDataFromJSON({
    ...bodyAtr,
    locations_ids: locations.map(({ value }) => value),
    categories_ids: categories.map(({ value }) => value),
    assets_ids: assets.map(({ value }) => value),
    parts_ids: parts.map(({ value }) => value),
  });
  addFilesForFormData(formData, uploads_attributes);
  formData.append("is_active", true);

  return formData;
};

export const getInitialValues = ({ type, data }) => {
  if (type === "create" || !data) {
    return initialValues;
  }

  const {
    name = "",
    description,
    locations,
    tax_id,
    vendor_contacts,
    urls,
    hourly_rate,
    address,
    assign_to_work_orders,
    parts_supplier,
    categories,
    assets,
    parts,
    rating,
    images = [],
    documents = [],
  } = data;

  const assetEditForm = {
    name,
    tax_id: tax_id ?? "",
    vendor_contacts_attributes: vendor_contacts,
    description: description ?? "",
    urls_attributes: urls,
    hourly_rate: hourly_rate ?? "",
    address: address ?? "",
    locations: (locations || []).map((location) => ({
      value: location.id,
      label: location.path,
    })),
    assign_to_work_orders: assign_to_work_orders,
    parts_supplier: parts_supplier,
    categories: (categories || []).map((category) => ({
      value: category.id,
      label: category.name,
    })),
    assets: (assets || []).map((asset) => ({
      value: asset.id,
      label: asset.name,
    })),
    parts: (parts || []).map((part) => ({ value: part.id, label: part.name })),
    rating,
    uploads_attributes: images
      .map((image) => ({ ...image, isImage: true }))
      .concat(documents),
  };

  return assetEditForm;
};

export const getSearchFunction = (name, props) => {
  const MAP = {
    locations_ids: props.onSearchLocations,
    categories_ids: props.onSearchCategories,
    vendors_ids: props.onSearchVendors,
    assets_ids: props.onSearchParts,
    parts_ids: props.onSearchParts,
  };
  return MAP[name];
};

export const getViewRows = (fields, data, isFieldVisible, t) => {
  return fields.reduce((visibleRows, field) => {
    if (!isFieldVisible(field)) {
      return visibleRows;
    }

    const value = data[field];
    if (field === "assign_to_work_orders") {
      visibleRows.push({
        label: t("vendors.details.assign_to_work_orders"),
        value: value ? <CheckIcon /> : <ClearIcon color="#FF786E" />,
      });
      return visibleRows;
    }

    if (field === "parts_supplier") {
      visibleRows.push({
        label: t("vendors.details.parts_supplier"),
        value: value ? <CheckIcon /> : <ClearIcon color="#FF786E" />,
      });
      return visibleRows;
    }

    if (field === "hourly_rate") {
      visibleRows.push({
        label: t("vendors.details.hourly_rate"),
        value: formatCurrency(value),
      });
      return visibleRows;
    }

    if (field === "rating") {
      visibleRows.push({
        label: t("vendors.details.rating"),
        value: <StarRatings value={value} edit={false} width="17px" />,
      });
      return visibleRows;
    }

    if (field === "urls" && Array.isArray(value)) {
      visibleRows.push({
        label: t("vendors.details.urls"),
        value: <LinksList links={value} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "locations") {
      visibleRows.push({
        label: t("vendors.details.locations"),
        value: value?.map((val) => (
          <LocationPath key={val.id} location={val} withTrim={false} />
        )),
        multiline: true,
      });
      return visibleRows;
    }

    if (["assets", "parts"].includes(field) && Array.isArray(value)) {
      // Map the field name to corresponding ModalType
      const modalType = {
        assets: "asset",
        parts: "part",
        locations: "location",
      }[field];
      visibleRows.push({
        label: t(`vendors.details.${field}`),
        value: (
          <ModalLinksList type={modalType} items={value} layout="stacked" />
        ),
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "categories") {
      visibleRows.push({
        label: t(`vendors.details.categories`),
        value: value.map((i) => i.name).join(", "),
      });
      return visibleRows;
    }

    if (typeof value === "string") {
      visibleRows.push({ label: t(`vendors.details.${field}`), value });
    }

    if (typeof value === "object" && !Array.isArray(value)) {
      visibleRows.push({
        label: t(`vendors.details.${field}`),
        value: value?.name,
      });
    }

    return visibleRows;
  }, []);
};

export const getOptionalFields = (fieldsData) => {
  if (!fieldsData || fieldsData?.status !== "success") {
    return {};
  }

  const { data } = fieldsData;

  return VendorFormOptionalFields.reduce((acc, optionalField) => {
    const field = data.find(({ name }) => name === optionalField);
    if (field) {
      const { required } = field;
      acc[optionalField] = { required };
    }
    return acc;
  }, {});
};
