import styled from "styled-components";

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: var(--color_grey900);
  font-size: 14px;
  line-height: 20px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
`;

export const Container = styled.div`
  padding: 32px 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2px;
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Note = styled.span`
  color: var(--color_grey900);
  font-size: 14px;
  line-height: 20px;
`;

export const SubduedText = styled.span`
  color: var(--color_grey500);
  line-height: 16px;
  font-size: 12px;
`;
