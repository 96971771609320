export const ADD_LOCATION_INITIAL_VALUE = {
  name: "",
  address: "",
  description: "",
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // teams_ids: [],
  code: null,
  parent_id: "",
  uploads_attributes: null,
};

export const LocationsFormOptionalFields = [
  "parent",
  "description",
  "address",
  "qr_code",
];
