import {
  SearchFilterOptions,
  useSearchAssetOptions,
  useSearchAssetTypeOptions,
  useSearchCategoryOptions,
  useSearchLocationOptions,
  useSearchStatusOptions,
  useSearchTechnicianOptions,
  useSearchVendorOptions,
} from "modules/filters/hooks/searchFilterOptions";
import {
  FilterPriorityOptions,
  FiltersMap,
  FilterTypeOptions,
} from "modules/filters/configs/constants";
import { TopBar } from "widgets/TopBar";
import { ReactNode } from "react";

interface ReportActionBarProps {
  filterEntity: keyof typeof FiltersMap;
  filters: any;
  buttons: ReactNode;
}

export const ReportActionBar = ({
  filterEntity,
  filters,
  buttons,
}: ReportActionBarProps) => {
  const statusSearch = useSearchStatusOptions();
  const assetsSearch = useSearchAssetOptions();
  const vendorsSearch = useSearchVendorOptions();
  const categoriesSearch = useSearchCategoryOptions();
  const locationsSearch = useSearchLocationOptions();
  const techniciansSearch = useSearchTechnicianOptions();
  const assetTypesSearch = useSearchAssetTypeOptions();

  const fieldMap: Record<string, SearchFilterOptions> = {
    type: { filterOptions: FilterTypeOptions },
    priority: { filterOptions: FilterPriorityOptions },
    assign_to: techniciansSearch,
    categories: categoriesSearch,
    location: locationsSearch,
    asset: assetsSearch,
    vendors: vendorsSearch,
    status: statusSearch,
    asset_types: assetTypesSearch,
  };

  return (
    // @ts-ignore: Need to fix TopBar types
    <TopBar
      entity={filterEntity}
      columns={filters}
      withExport={false}
      buttons={buttons}
      getOptions={(f: string) => fieldMap[f]?.filterOptions ?? []}
      getSearch={(f: string) => fieldMap[f]?.setSearch ?? (() => {})}
    />
  );
};
