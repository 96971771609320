import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetAssetsQuery } from "modules/assets";
import { useGetPMQuery } from "modules/workOrders";
import { useEffect, useMemo, useState } from "react";
import { Button } from "shared/components/Button";
import {
  Actions,
  CloseButton,
  Modal,
  ModalHeader,
} from "shared/components/Modals";
import { BodyWrapper } from "shared/components/Modals/components/Modals.styled";
import { SelectCreatable } from "shared/components/Select";
import { OptionType } from "shared/components/Select/SelectCreatable";
import { Description, Hint } from "shared/components/Typography";
import { useSearch } from "shared/lib/hooks";
import styled from "styled-components";
import {
  ViewHeader,
  PMInfo,
  Name,
  HeaderDividerStyled,
} from "../StyledSubComponents";
import { useClonePMMutation } from "modules/workOrders";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useTranslation } from "react-i18next";

interface PMCloneProps {
  isOpen: boolean;
  onClose: () => void;
  onView: (id: number) => void;
  preventiveMaintenanceId: string | number;
}

interface PM {
  id?: string | number;
  template_work_order?: {
    title: string;
    description: string;
  };
}

export const PMClone = ({
  isOpen,
  onClose,
  onView,
  preventiveMaintenanceId,
}: PMCloneProps) => {
  const { t } = useTranslation();
  const [clonePMMutation] = useClonePMMutation();
  const { currentData } = useGetPMQuery(preventiveMaintenanceId ?? skipToken);
  const { addSnackBar } = useSnackBar();

  const [assets, onAssetsSearch] = useSearch(useGetAssetsQuery, true);
  const [pmData, setPmData] = useState<PM>({});
  const [selectedAsset, setSelectedAsset] = useState<OptionType>();

  const { id } = pmData || {};
  const { title, description } = pmData?.template_work_order || {};

  const assetsOptions = useMemo(
    () =>
      assets?.data?.map((asset: { name: string; id: string | number }) => ({
        label: asset.name,
        value: asset.id,
      })),
    [assets]
  );

  useEffect(() => {
    setPmData(currentData);
  }, [currentData]);

  const handlePrimaryButtonClick = (id: number) => {
    onView(id);
  };

  const handleClone = async () => {
    if (selectedAsset == null) return;

    const res = (await clonePMMutation({
      id,
      body: { asset_id: selectedAsset.value },
    })) as {
      data?: { id?: number; title?: string };
      error?: { message?: string };
    };

    if (res.error) {
      addSnackBar({
        title: t("preventive_maintenances.clone.snackBar.error.title"),
        content: res.error?.message,
        type: "error",
      });
      return;
    }

    const pm = res?.data;
    const pmID = pm?.id;

    addSnackBar({
      title: t("preventive_maintenances.clone.snackBar.success.title"),
      content: t("preventive_maintenances.clone.snackBar.success.content"),
      type: "success",
      primaryButtonLabel: t("view"),
      secondaryButtonLabel: t("close"),
      handlePrimaryButtonClick: () => handlePrimaryButtonClick(pmID as number),
    });

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={function (): void {
        throw new Error("Function not implemented.");
      }}
      allowOverflow
    >
      <ModalContentWrapper>
        <ModalHeader
          label={t("preventive_maintenances.clone.title")}
          action={<CloseButton onClick={onClose} />}
        />
        <BodyWrapper>
          <ViewHeader>
            <PMInfo>
              <Hint>
                <>#{id}</>
              </Hint>
            </PMInfo>
            <Name>
              {t("preventive_maintenances.clone.header_label") + title}
            </Name>
            <Description>{description}</Description>
          </ViewHeader>
          <HeaderDividerStyled />
          <Description>
            {t("preventive_maintenances.clone.assetPrompt")}
          </Description>
          <SelectCreatable
            options={assetsOptions}
            onSearch={onAssetsSearch as () => void}
            value={selectedAsset}
            setValue={(_, value) => setSelectedAsset(value as OptionType)}
            name={t("assets.title")}
          />
        </BodyWrapper>
        <Actions>
          <>
            <Button
              onMouseDown={() => onView(id as number)}
              variant="secondary"
            >
              {t("Cancel")}
            </Button>
            <Button onMouseDown={handleClone} disabled={selectedAsset == null}>
              {t("Clone")}
            </Button>
          </>
        </Actions>
      </ModalContentWrapper>
    </Modal>
  );
};

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
`;
