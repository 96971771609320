export const getUrl = (tenant, path) => {
  if (!tenant) {
    return `${import.meta.env.VITE_SERVER_API_URL}${path}`;
  }

  const url = new URL(path, import.meta.env.VITE_SERVER_API_URL);
  url.hostname = `${tenant}.${url.hostname}`;

  return url.toString();
};
