import styled from "styled-components";

export const ProgreesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgreesWrapper = styled.div`
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#02836f ${({ value }) => value}%, #ececef 0);
`;

export const Progress = styled.progress`
  visibility: hidden;
  height: 0;
  width: 0;
`;

export const ProgressStatus = styled.div`
  font-siz: 14px;
  color: var(--color_grey900);
  display: flex;
  align-items: center;
`;

export const NameContainer = styled.span`
  text-overflow: ellipsis;
  width: 96px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
`;
