import styled from "styled-components";

export const VerticalItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HorizontalItem = styled.div`
  display: flex;
  justify-content: space-between;
`;
