import styled from "styled-components";
import { H3 } from "shared/components/Typography";

export const ReportPageLayoutWrapper = styled.div`
  --hPadding: 40px;
  --gap: 16px;

  height: calc(100vh - var(--header-height));
  max-height: calc(100vh - var(--header-height));
  max-width: 100%;
  overflow: auto;
  padding: 20px 40px 0 var(--hPadding);

  display: grid;
  grid:
    "kpis" auto
    "chart" auto
    "bar" auto
    "table" 1fr / 1fr;
  gap: var(--gap);

  @media (min-width: 1300px) {
    grid:
      "kpis chart" auto
      "bar bar" auto
      "table table" 1fr / 1fr 3fr;
  }
`;

export const SectionHeader = styled(H3)`
  margin-bottom: 16px;
`;

export const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

export const ActionBarWrapper = styled.div`
  grid-area: bar;
  border-top: 1px solid var(--color_grey100);
  margin-right: calc(var(--hPadding) * -1);
`;

export const TableWrapper = styled.div`
  grid-area: table;
  min-width: 0;
  margin-right: calc(var(--hPadding) * -1);
  margin-top: calc(var(--gap) * -1);

  display: flex;
  flex-direction: column;

  // On shorter screens, limit the table height to the screen height
  max-height: calc(100vh - var(--header-height));
  overflow: unset;

  // When the page is tall enough, the table will fill the remaining vertical space
  //    under the KPIs and chart
  @media (min-height: 900px) {
    max-height: unset;
    overflow: hidden;
  }
`;
