import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useEmailInvitationMutation } from "modules/users/state/usersApi";
import styled from "styled-components";
import { Button } from "shared/components/Button";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

export const InvitationCancelButton = ({ user }) => {
  const { t } = useTranslation();
  const [emailInvitation] = useEmailInvitationMutation();
  const { addSnackBar } = useSnackBar();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const isCancelInvitationDisable = user?.status === "invitation_expired";

  const cancelHandler = () => {
    setIsConfirmationVisible((prev) => !prev);
  };

  const proceedHandler = () => {
    if (user?.id) {
      emailInvitation({ id: user.id, type: "cancel_invitation" })
        .unwrap()
        .then(() => {
          setIsConfirmationVisible(false);
          addSnackBar({
            content: t("allUsers.cancelInvitation.success"),
            type: "success",
          });
        })
        .catch(() => {
          setIsConfirmationVisible(false);
          addSnackBar({
            content: t("allUsers.cancelInvitation.fail"),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    }
  };

  return (
    <>
      <CustomButton
        variant="danger"
        onClick={() => {
          setIsConfirmationVisible(true);
        }}
        disabled={isCancelInvitationDisable}
      >
        {t("allUsers.cancelInvitation.buttonTitle")}
      </CustomButton>

      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t("allUsers.cancelInvitation.title")}
          confirmButtonLabel={t("allUsers.cancelInvitation.confirm")}
          description={t("allUsers.cancelInvitation.description")}
          cancelButtonLabel={t("allUsers.cancelInvitation.cancel")}
          onProceed={proceedHandler}
          onCancel={cancelHandler}
          isDestructive
        />
      )}
    </>
  );
};

export const CustomButton = styled(Button)`
  margin-left: auto;
`;
