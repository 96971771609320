import { useUpdateUserDetailsMutation } from "modules/users/state/usersApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContextMenuEntry } from "shared/components/ContextMenu";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

const variants = {
  active: "changeStatusToActiveConfirmation",
  inactive: "changeStatusToInactiveConfirmation",
};

export const ChangeStatus = ({ status, fullName, id }) => {
  const { t } = useTranslation();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const { addSnackBar } = useSnackBar();

  const proceedHandler = () => {
    updateUserDetails({ id, body: { status } })
      .unwrap()
      .then(() => {
        setIsConfirmationVisible(false);
        addSnackBar({
          content: t(`allUsers.${variants[status]}.success`, {
            fullName,
          }),
          type: "success",
        });
      })
      .catch(({ message }) => {
        setIsConfirmationVisible(false);
        addSnackBar({
          content: message,
          type: "error",
        });
      });
  };
  const cancelHandler = () => {
    setIsConfirmationVisible((prev) => !prev);
  };

  return (
    <>
      <ContextMenuEntry onClick={() => setIsConfirmationVisible(true)}>
        {t(`allUsers.make.${status}`)}
      </ContextMenuEntry>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t(`allUsers.${variants[status]}.title`)}
          confirmButtonLabel={t(`allUsers.${variants[status]}.confirm`)}
          description={t(`allUsers.${variants[status]}.description`)}
          onProceed={() => proceedHandler()}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};
