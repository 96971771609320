import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { usePermission } from "app/providers/PermissionsProvider";
import { useLazyGetUserPermissionsQuery } from "modules/users";
import { getUser } from "shared/components/Auth";
import { setUser } from "shared/components/Auth";

/** Redirects users to an appropriate default page based on their role */
export const HomePage = () => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [trigger] = useLazyGetUserPermissionsQuery();
  const { setPermissions } = usePermission();

  const user = getUser();
  useEffect(() => {
    trigger(user?.id)
      .unwrap()
      .then(({ data }) => {
        const userData = {
          ...user,
          permissions: data?.permissions,
          role: data?.role,
        };
        setPermissions(data?.permissions);
        setUser(userData);
        const url = data?.role === "requester" ? "/requests" : "/work-orders";
        setRedirectUrl(url);
      });
  }, []);

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  return null;
};
