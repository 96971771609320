import { CMMSAPI } from "app/services";

export const requestPortalsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getRequestPortals: builder.query({
      query: (params) => ({
        url: "/request_portals",
        params,
      }),
      providesTags: (result) => [
        { type: "RequestPortal", id: "RequestPortalsList" },
        ...(result?.data?.map(({ uuid }) => ({
          type: "RequestPortal",
          id: uuid,
        })) ?? []),
      ],
    }),
    addRequestPortal: builder.mutation({
      query: (body) => ({
        url: "/request_portals",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "RequestPortal", id: "RequestPortalsList" }],
      transformErrorResponse: (response) => response.data,
    }),
    getRequestPortal: builder.query({
      query: (id) => `/request_portals/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "RequestPortalDetails", id: query },
      ],
    }),
    updateRequestPortal: builder.mutation({
      query: ({ id, body }) => ({
        url: `/request_portals/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "RequestPortal", id },
        { type: "RequestPortalDetails", id },
      ],
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetRequestPortalsQuery,
  useAddRequestPortalMutation,
  useGetRequestPortalQuery,
  useUpdateRequestPortalMutation,
} = requestPortalsApi;
