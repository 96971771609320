import styled from "styled-components";
import { Description } from "shared/components/Typography";
import { ModalHeader } from "shared/components/Modals/components/ModalHeader";
import { CloseButton } from "shared/components/Modals/components/CloseModal";
import { useTranslation } from "react-i18next";

interface ModalFallbackProps {
  onClose: () => void;
  variant?: "light" | "dark";
}

/** Default ErrorBoundary fallback for Modals */
export const ModalFallback = ({ onClose, variant }: ModalFallbackProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalHeader
        variant={variant}
        label={t("fallback.error")}
        action={<CloseButton background={variant} onClick={onClose} />}
      />
      <Content>
        <Description>
          <p>{t("fallback.description_a")}</p>
          <p>{t("fallback.description_b")}</p>
        </Description>
      </Content>
    </>
  );
};

const Content = styled.div`
  padding: 16px;
`;
