import styled from "styled-components";
import { Link } from "shared/components/Link";
import AssetIcon from "shared/assets/icon/asset.svg?react";
import WrenchIcon from "shared/assets/icon/wrench.svg?react";
import VendorIcon from "shared/assets/icon/menu/Vendors.svg?react";
import PortalIcon from "shared/assets/icon/menu/Portals.svg?react";

export const LinkContainer = styled.div`
  display: flex;
  gap: 18px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 32px;
`;

export const ModuleTitle = styled.div`
  font-weight: 600;
  line-height: 115%;
`;

export const ModuleLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: var(--color_text_color);
`;

export const AssetIconStyled = styled(AssetIcon)`
  width: 24px;
  height: 24px;
`;

export const WrenchIconStyled = styled(WrenchIcon)`
  width: 24px;
  height: 24px;
`;

export const VendorIconStyled = styled(VendorIcon)`
  width: 24px;
  height: 24px;
`;

export const PortalIconStyled = styled(PortalIcon)`
  width: 24px;
  height: 24px;
`;

export const DescriptionBox = styled.div`
  margin: 8px 0 24px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color_text_color);
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: var(--color_text_color);
  margin: 33px 0 10px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-basis: 45%;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: var(--color_grey10);
  border-radius: 4px;
  width: 100%;
  text-decoration: none;
  &:hover {
    background: var(--color_primary_hover);
    color: var(--color_dark_blue);
  }
`;
