import styled from "styled-components";

export const Name = ({ onClick, value, className }) => (
  <NameContainer onClick={onClick} className={className}>
    {value}
  </NameContainer>
);

const NameContainer = styled.div`
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
`;
