import { Link as RRLink } from "react-router-dom";
import { LinkRouter } from "./Link.styled";
import React from "react";

interface LinkProps extends React.ComponentProps<typeof RRLink> {
  icon?: React.ReactNode;
  type?: "button" | "text";
  variant?: "primary" | "primaryDark" | "secondary" | "secondaryDark";
}

export const Link = ({ icon, children, ...rest }: LinkProps) => (
  <LinkRouter {...rest}>
    {icon}
    <span>{children}</span>
  </LinkRouter>
);
