import React from "react";
import { useTranslation } from "react-i18next";
import { Empty } from "shared/components/Empty";
import RequestPortalsIcon from "../../assets/icons/empty.svg?react";
import { ModalLink } from "shared/components/ModalLink";

export const EmptyRequestPortalsList = () => {
  const { t } = useTranslation();

  return (
    <Empty
      icon={<RequestPortalsIcon />}
      heading={t("request_portals.empty")}
      description={
        <>
          {t("empty.descriptionUse")}
          <ModalLink modalParams={{ type: "portal", id: "new" }}>
            {t("empty.request_portals")}
          </ModalLink>
          {t("empty.descriptionButton")}
        </>
      }
      spacious
    />
  );
};
