import { format } from "date-fns";
import {
  DateFormats,
  DatePickerFormats,
  FullDateFormats,
} from "../constants/forms";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const fnsDateFormat = (date, formatParams) => {
  return format(date, formatParams);
};

/**
 * WARNING: This uses `utcToZonedTime` from `date-fns-tz` library, which is very counterintuitive.
 * I highly recommend reading:
 * - [The date-fns-tz docs](https://github.com/marnusw/date-fns-tz/tree/v2.0.1?tab=readme-ov-file#utctozonedtime)
 * - [This article](https://masteringjs.io/tutorials/date-fns/tz#converting-to-different-timezones)
 *
 * @param value {Date}
 * @param timeZone {(string | undefined | null)}
 * @returns {Date}
 */
export const convertTimeToEntityTimezone = (value, timeZone = null) => {
  const entityTimezone =
    timeZone || JSON.parse(localStorage.getItem("user"))?.timezone; // get entity timezone
  if (entityTimezone) {
    return utcToZonedTime(value, entityTimezone.split(":")[0]);
  }
  return value;
};

/** See [zonedTimeToUTC docs](https://github.com/marnusw/date-fns-tz?tab=readme-ov-file#zonedtimetoutc) for explanation
 * @param value {Date}
 * @param timeZone {(string | undefined | null)}
 * @returns {Date}
 */
export const entityZonedTimeToUtc = (value, timeZone = null) => {
  const entityTimezone =
    timeZone || JSON.parse(localStorage.getItem("user"))?.timezone;
  const tzCode = entityTimezone.split(":")[0];
  return zonedTimeToUtc(value, tzCode);
};

export const getTimezoneDateFormats = (
  defaultTimeZone = null,
  defaultDateFormat = null
) => {
  const entityTimezone =
    defaultTimeZone || JSON.parse(localStorage.getItem("user"))?.timezone;
  const entityDateFormat =
    defaultDateFormat || JSON.parse(localStorage.getItem("user"))?.dateFormat;

  const onlyDateFormat = DateFormats[entityDateFormat];
  const dateTimeFormat = FullDateFormats[entityDateFormat];
  const dateTimePickerFormat = DatePickerFormats[entityDateFormat];

  return {
    timezone: entityTimezone,
    onlyDateFormat,
    dateTimeFormat,
    dateTimePickerFormat,
  };
};
