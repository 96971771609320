export const ReportKey = {
  BacklogByAssignee: "wo_by_assignee",
  BacklogByPriority: "backlog_by_priority",
  BacklogByStatus: "backlog_by_status",
  MaintenanceHistoryByAssignee: "history_by_assignee",
  HistoryByCost: "history_by_costs",
  OntimeVsOverdue: "on_time_overdue",
  PartsInventory: "parts_inventory",
  ReactiveVsPreventive: "reactive_preventive",
  CreatedVsCompleted: "created_completed",
  AssetCostsAndDowntime: "asset_costs_downtime",
  AssetCosts: "asset_costs",
  CompletedByCategory: "completed_by_category",
} as const;
