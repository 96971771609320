import { ReportActionBarProps } from "modules/reporting/types/ReportActionBarProps";
import { useReportFilterOptions } from "modules/reporting/lib/useReportFilterOptions";
import { ReportActionBar } from "modules/reporting/components/ReportActionBar";

export const BacklogByPriorityReportActionBar = ({
  buttons,
}: ReportActionBarProps) => {
  const filterOptions = useReportFilterOptions();
  const filters = [
    filterOptions.status,
    filterOptions.categories,
    filterOptions.assignTo,
    filterOptions.type,
    filterOptions.dueDate,
    filterOptions.location,
    filterOptions.asset,
    filterOptions.vendors,
  ];

  return (
    <ReportActionBar
      filterEntity="work_orders"
      filters={filters}
      buttons={buttons}
    />
  );
};
