import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";
import { LocationCreate } from "modules/locations/components/LocationCreate";
import { LocationUpdate } from "modules/locations/components/LocationUpdate";
import { LocationView } from "modules/locations/components/LocationView";

interface LocationModalProps {
  params: ModalParams;
}

export const LocationModal = ({ params }: LocationModalProps) => {
  const { type, id, state = "view" } = params;
  const { locationViewPermit, locationCreatePermit, locationUpdatePermit } =
    usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new" && locationCreatePermit) {
    return (
      <LocationCreate
        isShow={true}
        onOpen={(id) => setModal({ type, id })}
        onClose={onClose}
      />
    );
  } else if (state === "view" && locationViewPermit) {
    return (
      <LocationView
        isShow={true}
        locationId={id}
        onEdit={() => setModal({ type, id, state: "edit" })}
        onClose={onClose}
      />
    );
  } else if (state === "edit" && locationUpdatePermit) {
    return (
      <LocationUpdate
        isShow={true}
        locationId={id}
        onSuccess={onView}
        onClose={onView}
      />
    );
  }

  return null;
};
