import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Switch } from "shared/components/Switch";

export const Columns = ({ rows, onSwitch }) => {
  const columnsNode = rows.map(({ field, name, hidden, disabled }) => (
    <SwitchBox key={field}>
      <Switch
        activated={!hidden}
        disabled={disabled}
        onClick={() => onSwitch(field, !hidden)}
      >
        {name}
      </Switch>
    </SwitchBox>
  ));

  return <Box>{columnsNode}</Box>;
};

Columns.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool,
      disabled: PropTypes.bool,
      name: PropTypes.string,
      field: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  onSwitch: PropTypes.func,
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwitchBox = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
