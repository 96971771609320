import styled from "styled-components";

export const Cell = styled.div`
  width: ${({ width }) => `${width}px` ?? "auto"};
  margin: 0;
  border-bottom: 1px solid var(--color_border);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 10px;
  z-index: 0;

  & > * {
    flex-shrink: 0;
    width: 100%;
  }

  &:first-child {
    position: sticky;
    left: 0;
    background-color: var(--color_table_background);
    border-right: 1px solid var(--color_border);
    overflow: initial;
    z-index: 1;
  }

  &:first-child::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: -8px;
    width: 8px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const Row = styled.div`
  display: contents;
`;

export const Th = styled(Cell)`
  min-height: 48px;
`;
