import { ContextMenu, ContextMenuEntry } from "shared/components/ContextMenu";
import { IconButton } from "shared/components/IconButton";
import ExportIcon from "shared/assets/icon/table/export.svg?react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface ExportMenuProps {
  downloadXLS: () => void;
  downloadPDF: () => void;
  isPDFReady: boolean;
}

/** Export options for reports */
export const ExportMenu = ({
  downloadXLS,
  downloadPDF,
  isPDFReady,
}: ExportMenuProps) => {
  const { t } = useTranslation();
  return (
    <ContextMenu
      buttonSlot={
        <IconButtonStyled variant="primary">
          <ExportIcon />
        </IconButtonStyled>
      }
    >
      <ContextMenuEntry onClick={downloadXLS}>
        {t("reporting.exportMenu.xls")}
      </ContextMenuEntry>
      <ContextMenuEntry onClick={downloadPDF} disabled={!isPDFReady}>
        {isPDFReady
          ? t("reporting.exportMenu.pdf")
          : t("reporting.exportMenu.pdfLoading")}
      </ContextMenuEntry>
    </ContextMenu>
  );
};

const IconButtonStyled = styled(IconButton)`
  height: fit-content;
`;
