import { isAfter, parseISO } from "date-fns";

// TODO: update properties after adding PM to calendar

const today = new Date();

export const transformEvents = (events, timezone) => {
  return {
    // the timezone code from BE
    timezone: timezone.split(":")[0],
    events: events.map(
      ({
        id,
        title,
        due_date,
        priority,
        status,
        is_overdue,
        is_pm_template,
        resource_id,
        created_by_id,
        // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
        // teams,
        technicians,
        pm_schedule,
        completed_at,
      }) => {
        const isCompleted = status === "completed";

        const eventDate = isCompleted && completed_at ? completed_at : due_date;

        const isPm =
          is_pm_template &&
          pm_schedule &&
          pm_schedule?.id &&
          isAfter(parseISO(due_date), today);

        return {
          title: title,
          start: eventDate,
          end: eventDate,
          editable: !(isPm || isCompleted),
          id,
          pmId: pm_schedule?.id,
          woId: resource_id,
          priority: `${priority}${isPm || isCompleted ? "_pm" : ""}`,
          status: isPm
            ? pm_schedule?.status
            : `${status}${isCompleted ? "Calendar" : ""}`,
          isOverdue: is_overdue && !isCompleted,
          isCompleted,
          isPlanned: isPm,
          dueDate: due_date,
          createdById: created_by_id,
          // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
          // assigned: teams
          //   .concat(technicians)
          //   .map(({ name }) => ({ name, color: "#FFACA6" })),
          assigned: technicians,
        };
      }
    ),
  };
};
