import CloseIcon from "shared/assets/icon/close.svg?react";
import { ClearIconContainer } from "./SelectDropdownStyled";

export const ClearIndicator = ({ innerProps }) => {
  return (
    <ClearIconContainer {...innerProps}>
      <CloseIcon />
    </ClearIconContainer>
  );
};
