import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { StatusMap } from "modules/workOrders/config/constants";

export const Status = ({ value }) => {
  const { t } = useTranslation();
  const { Icon, color } = StatusMap[value];
  return (
    <StatusContainer color={`var(--color_${color})`}>
      <Icon /> <span>{t(`work_orders.status.${value}`)}</span>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ color }) => color};
  font-falimy: inherit;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  line-height: 16px;
  border-radius: 12px;
  gap: 4px;
  background: white;
`;
