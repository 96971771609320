import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { FormCheckbox } from "shared/components/FormCheckbox";
import { propTypesFormik } from "shared/lib/hooks/useForm";
import { PhoneInput } from "shared/components/PhoneInput";
import { usePermission } from "app/providers/PermissionsProvider";
import styled from "styled-components";

export const ContactEditor = ({ form, existingContacts, index }) => {
  const { t } = useTranslation();

  const { vendorContactsMakePrimaryPermit } = usePermission();

  return (
    <>
      <InlineLabel label={t("contacts.form.name")} labelWidth={110} isRequired>
        <Input
          name="name"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("contacts.form.name")}
          errorMessage={form.touched.name && form.errors.name}
          required
        />
      </InlineLabel>
      <InlineLabel label={t("contacts.form.role")} labelWidth={110}>
        <Input
          name="role"
          value={form.values.role}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("contacts.form.role")}
          errorMessage={form.touched.role && form.errors.role}
        />
      </InlineLabel>
      <InlineLabel label={t("contacts.form.email")} labelWidth={110}>
        <Input
          name="email"
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("contacts.form.email")}
          errorMessage={form.touched.email && form.errors.email}
        />
      </InlineLabel>
      <InlineLabel label={t("contacts.form.phone")} labelWidth={110}>
        <PhoneContainer>
          <PhoneInput
            name="phone"
            value={form.values.phone}
            setValue={(value) => form.setFieldValue("phone", value)}
            errorMessage={form.touched.phone && form.errors.phone}
            touched={(value) => form.setTouched({ phone: true })}
          />
          <Input
            name="extension"
            value={form.values.extension}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("contacts.form.extension")}
            errorMessage={form.touched.extension && form.errors.extension}
          />
        </PhoneContainer>
      </InlineLabel>
      {vendorContactsMakePrimaryPermit &&
        !existingContacts?.some(
          (el, i) => el.is_primary && i !== index && !el._destroy
        ) && (
          <InlineLabel label="" labelWidth={110}>
            <FormCheckbox
              checked={form.values.is_primary}
              onClick={() =>
                form.handleSetValue("is_primary", !form.values.is_primary)
              }
              label={t("contacts.form.primaryContact")}
              name="is_primary"
            />
          </InlineLabel>
        )}
    </>
  );
};

ContactEditor.propTypes = {
  existingContacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      is_primary: PropTypes.bool,
    })
  ),
  form: propTypesFormik,
  index: PropTypes.number,
};

const PhoneContainer = styled.div`
  display: flex;
  gap: 10px;
  & > *:nth-child(odd) {
    width: 254px;
  }

  & > *:nth-child(even) {
    width: 100px;
  }

  & .error {
    position: initial;
  }
`;
