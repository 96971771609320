import { VendorsList } from "shared/components/VendorsList";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { LinksList } from "shared/components/LinksList";

import { Progression } from "../../components/Progression";
import { getRepeatsText } from "./form";
import { convertTimeToCompleteHoursToDays } from ".";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLink } from "shared/components/ModalLink";

export const getViewRows = (
  fields,
  workOrderFields,
  data,
  isFieldVisible,
  t,
  displayDate
) => {
  const woFields = workOrderFields.reduce((visibleRows, field) => {
    if (field === "schedule") {
      visibleRows.push({
        label: t(`preventive_maintenances.details.schedule`),
        value: `${t(`work_orders.schedule_type.${data.type}`)}: ${
          data.type === "one_time"
            ? t(
                "work_orders.form.pm_schedule_attributes.information.work_order_detail",
                {
                  value: displayDate(data.next_order_date, true, true),
                }
              )
            : getRepeatsText({
                repeat_occurrence: data.repeat_occurrence,
                repeat_frequency: data.repeat_frequency,
                on_month_day: data.on_month_day,
                on_week_days: data.on_week_days
                  ?.map(Number)
                  .sort((a, b) => a - b)
                  .map(String),
                in_months: data.in_months
                  ?.map(Number)
                  .sort((a, b) => a - b)
                  .map(String),
                scheduleType: data.type,
              })
        }`,
        multiline: true,
      });
      return visibleRows;
    }

    if (!isFieldVisible(field)) {
      return visibleRows;
    }

    const woData = data.template_work_order;

    const value = woData[field];

    if (field === "created_by") {
      visibleRows.push({
        label: t("work_orders.details.created_by"),
        value: `${woData.created_by?.name} on ${displayDate(
          woData.created_at
        )}`,
      });
      return visibleRows;
    }

    if (
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      // field === "assign_to" &&
      // (isFieldVisible("teams") || isFieldVisible("technicians"))
      field === "assign_to" &&
      isFieldVisible("technicians")
    ) {
      visibleRows.push({
        label: t("work_orders.details.assign_to"),
        value: <UserAvatarGroup users={woData.technicians} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "type") {
      visibleRows.push({
        label: t("work_orders.details.type"),
        value: value && t(`work_orders.type.${value}`),
      });
      return visibleRows;
    }

    if (field === "categories") {
      visibleRows.push({
        label: t("work_orders.details.categories"),
        value: value.map(({ name }) => name).join(", "),
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "location") {
      visibleRows.push({
        label: t("work_orders.details.location"),
        value: <LocationPath location={value} withTrim={false} />,
      });
      return visibleRows;
    }

    if (field === "vendors") {
      visibleRows.push({
        label: t("work_orders.details.vendors"),
        value: <VendorsList vendors={value} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (field === "asset") {
      visibleRows.push({
        label: t("work_orders.details.asset"),
        value: value ? (
          <ModalLink modalParams={{ type: "asset", id: value.id }}>
            {value.name}
          </ModalLink>
        ) : null,
      });
      return visibleRows;
    }

    if (Array.isArray(value) && value.length > 0) {
      const links = value.map(({ name, id }) => ({
        label: name,
        url: `/${field}/${id}`,
      }));

      visibleRows.push({
        label: t(`work_orders.details.${field}`),
        value: <LinksList links={links} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (typeof value === "string" || typeof value === "number") {
      visibleRows.push({ label: t(`work_orders.details.${field}`), value });
    }

    if (typeof value === "object" && !Array.isArray(value)) {
      visibleRows.push({
        label: t(`work_orders.details.${field}`),
        value: value?.name,
      });
    }

    return visibleRows;
  }, []);

  const pmFields = fields.reduce((visibleRows, field) => {
    if (!isFieldVisible(field)) {
      return visibleRows;
    }

    const value = data[field];

    if (field === "start_date") {
      visibleRows.push({
        label: t("preventive_maintenances.details.start_date"),
        value: displayDate(value, true),
      });
      return visibleRows;
    }

    if (field === "progression") {
      visibleRows.push({
        label: t("preventive_maintenances.details.progression"),
        value: (
          <Progression
            progression={data.progression}
            status={data.status}
            width="148px"
          />
        ),
      });
      return visibleRows;
    }

    if (field === "time_to_complete") {
      visibleRows.push({
        label: t("preventive_maintenances.details.time_to_complete"),
        value: value ? convertTimeToCompleteHoursToDays(value) : null,
      });
      return visibleRows;
    }

    if (Array.isArray(value) && value.length > 0) {
      const links = value.map(({ name, id }) => ({
        label: name,
        url: `/${field}/${id}`,
      }));

      visibleRows.push({
        label: t(`preventive_maintenances.details.${field}`),
        value: <LinksList links={links} />,
        multiline: true,
      });
      return visibleRows;
    }

    if (typeof value === "string" || typeof value === "number") {
      visibleRows.push({
        label: t(`preventive_maintenances.details.${field}`),
        value,
      });
    }

    if (typeof value === "object" && !Array.isArray(value)) {
      visibleRows.push({
        label: t(`preventive_maintenances.details.${field}`),
        value: value?.name,
      });
    }

    return visibleRows;
  }, []);

  return [...pmFields, ...woFields];
};
