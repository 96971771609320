import React from "react";
import { useTranslation } from "react-i18next";
import { BulkDeletion } from "features/BulkDeletion";
import {
  useDeleteWorkOrdersMutation,
  useUpdateWorkOrdersPriorityMutation,
  useUpdateWorkOrdersAssignesMutation,
  useUpdateWorkOrdersStatusMutation,
  useUpdateWorkOrdersDueDateMutation,
  usePrintWorkOrdersMutation,
} from "modules/workOrders";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { BulkPriority } from "./BulkPriority";
import { BulkAssignees } from "./BulkAssignees";
import { BulkStatus } from "./BulkStatus";
import { BulkDueDate } from "./BulkDueDate";
import { BulkPdfExport } from "shared/components/BulkPdfExport";
import { usePermission } from "app/providers/PermissionsProvider";
import { WorkOrdersPdfPage } from "../WorkOrdersPdfPage";
import PrintWorkOrderExample from "../../assets/PrintWorkOrderExample.svg?react";

export const BulkActions = ({ selectionModel, setUpdateStatusError }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();

  const count = selectionModel.length;

  const [deleteWorkOrders] = useDeleteWorkOrdersMutation();
  const [updateWorkOrdersPriority] = useUpdateWorkOrdersPriorityMutation();
  const [updateWorkOrdersAssignes] = useUpdateWorkOrdersAssignesMutation();
  const [updateWorkOrdersStatus] = useUpdateWorkOrdersStatusMutation();
  const [updateWorkOrdersDueDate] = useUpdateWorkOrdersDueDateMutation();
  const [printWorkOrders] = usePrintWorkOrdersMutation();

  const {
    woListPrintPermit,
    woListDeletePermit,
    woListChangeDueDatePermit,
    woListChangeAssigneePermit,
    woListChangeStatusPermit,
    woListChangePriorityPermit,
  } = usePermission();

  const deleteSuccessHandler = () => {
    addSnackBar({
      content: t("work_orders.bulkActions.delete.success", {
        count,
      }),
      type: "success",
    });
  };

  return (
    <>
      {woListChangeDueDatePermit && (
        <BulkDueDate
          ids={selectionModel}
          count={count}
          onUpdate={updateWorkOrdersDueDate}
        />
      )}
      {woListChangeAssigneePermit && (
        <BulkAssignees
          ids={selectionModel}
          count={count}
          onUpdate={updateWorkOrdersAssignes}
        />
      )}
      {woListChangeStatusPermit && (
        <BulkStatus
          ids={selectionModel}
          count={count}
          onUpdate={updateWorkOrdersStatus}
          setUpdateStatusError={setUpdateStatusError}
        />
      )}
      {woListChangePriorityPermit && (
        <BulkPriority
          ids={selectionModel}
          count={count}
          onUpdate={updateWorkOrdersPriority}
        />
      )}
      {woListPrintPermit && (
        <BulkPdfExport
          ids={selectionModel}
          selectedLabel={t("printWorkOrders.confirmation.selected", { count })}
          type="button"
          title={t("printWorkOrders.confirmation.bulkTitle")}
          resourceName="WorkOrders"
          getExportResources={printWorkOrders}
          PdfExampleSvg={PrintWorkOrderExample}
          PageComponent={WorkOrdersPdfPage}
        />
      )}
      {woListDeletePermit && (
        <BulkDeletion
          ids={selectionModel}
          onDelete={deleteWorkOrders}
          onSuccess={deleteSuccessHandler}
          confirmation={{
            title: t("work_orders.bulkActions.delete.confirmation.title"),
            description: t(
              "work_orders.bulkActions.delete.confirmation.description",
              {
                count: selectionModel.length,
              }
            ),
            confirmButtonLabel: t(
              "work_orders.bulkActions.delete.confirmation.confirmButtonLabel",
              { count: selectionModel.length }
            ),
          }}
        />
      )}
    </>
  );
};
