import styled, { css } from "styled-components";
import { IconButton } from "shared/components/IconButton";

export const UserBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & svg {
    color: var(--color_table_missing_icon_color);
  }
`;

export const CellStyle = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Cell = styled.div`
  ${CellStyle}
`;

export const GeneralCell = styled.div`
  ${CellStyle}
  text-align: end;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
`;

export const Box = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => `60% repeat(${columns}, 1fr)`};
  gap: 0 10px;
  padding: 5px;

  &:hover {
    background: var(--color_primary_hover);
    grid-template-columns: ${({ columns }) => `60% repeat(${columns}, 1fr) 5%`};

    & .actionIcon {
      display: flex;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  display: none;
  top: 16px;
  right: 16px;
`;
