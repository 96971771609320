import React from "react";
import styled from "styled-components";
import { Column } from "./Column";
import { ScrollFadeBox } from "shared/components/ScrollFadeBox";

export const Columns = ({
  columnsSettings,
  setFieldVisibility,
  setFieldOrder,
}) => {
  const columnsNode = columnsSettings.map(({ field, name, hidden }, index) => (
    <Column
      key={field}
      index={index}
      field={field}
      hidden={hidden}
      setFieldVisibility={setFieldVisibility}
      setFieldOrder={setFieldOrder}
    >
      {name}
    </Column>
  ));

  return (
    <ScrollFadeBox>
      <Box>{columnsNode}</Box>
    </ScrollFadeBox>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;
