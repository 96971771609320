import { skipToken } from "@reduxjs/toolkit/dist/query";
import { EditEntity, Entity, useGetEntityQuery } from "modules/entities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUser, setUser } from "../Auth";

import { Spinner } from "../Spinner";
import { LightModal } from "../Modals/LightModal";
import Icon from "./assets/building-modern.svg?react";

import { ContentBox, IconContainer, Text } from "./styled";

export const DefaultEntity = () => {
  const { t } = useTranslation();
  const [showDefaultEntity, setShowDefaultEntity] = useState(false);
  const [editEntity, setEditEntity] = useState(false);

  const { entity = "", activity = "", ...rest } = getUser() ?? {};

  const { currentData: data } = useGetEntityQuery(entity ?? skipToken);

  // from api we get login_count and invitation_details params, if login_count = 1 and invitation_details array have null value, it means that user logged in for the first time
  useEffect(() => {
    if (
      entity &&
      activity?.loginCount === 1 &&
      Object.values(activity?.invitationDetails).includes(null)
    ) {
      setShowDefaultEntity(true);
    }
  }, []);

  const onClose = () => {
    setShowDefaultEntity(false);
    setUser({ entity, ...rest, activity: { ...activity, loginCount: 2 } });
  };

  return (
    <>
      <LightModal
        isOpen={showDefaultEntity}
        onClose={onClose}
        onSubmit={onClose}
        label={t("defaultEntity.label")}
        primaryButtonLabel={t("defaultEntity.submit")}
      >
        <ContentBox>
          <IconContainer>
            <Icon />
          </IconContainer>
          <Text>{t("defaultEntity.text")}</Text>
          {data ? (
            <Entity
              entity={data}
              defaultEntity
              openEntityEditor={() => {
                setEditEntity(true);
              }}
            />
          ) : (
            <Spinner height="100%" />
          )}
        </ContentBox>
      </LightModal>
      <EditEntity
        isShow={editEntity}
        onCloseModal={() => setEditEntity(false)}
        defaultEntityId={entity}
        deleteOpportunity={false}
      />
    </>
  );
};
