import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Button } from "shared/components/Button";
import { ContactView } from "../ContactView";
import { ContactCreate } from "../ContactCreate";
import { ContactUpdate } from "../ContactUpdate";
import { sortContacts } from "../../lib/helpers";
import { usePermission } from "app/providers/PermissionsProvider";

export const ContactsList = ({ name, value = [], setValue }) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const [editContact, setEditContact] = useState("");

  const { vendorCreateContactPermit, vendorUpdateContactPermit } =
    usePermission();

  const contactsList = sortContacts(value);

  const openModalHandler = () => {
    setIsShowModal(true);
  };

  const closeModalHandler = () => {
    setIsShowModal(false);
  };

  const openEditModalHandler = (index) => {
    setEditContact(index);
  };

  const closeEditModalHandler = () => {
    setEditContact("");
  };

  const addClickHandler = (newContact) => {
    const updatedContacts = [...contactsList, newContact];
    setValue(name, updatedContacts);
    closeModalHandler();
  };

  const onChangeHandle = (index, newValue) => {
    const updatedContacts = contactsList.map((contact, i) =>
      i === index ? newValue : contact
    );
    setValue(name, updatedContacts);
    closeEditModalHandler();
  };

  const onDeleteHandle = (index) => {
    const newValue = contactsList.map((c, i) =>
      i === index ? { ...c, _destroy: true } : c
    );
    setValue(name, newValue);
    closeEditModalHandler();
  };

  const onCopyHandle = (email) => {
    navigator.clipboard.writeText(email);
    addSnackBar({
      content: t("contacts.copy", { email }),
      type: "success",
    });
  };

  return (
    <>
      <Wrapper>
        {contactsList.map((val, index) => {
          return (
            !val._destroy && (
              <ContactView
                key={`${val}-${index}`}
                value={val}
                index={index}
                onDelete={onDeleteHandle}
                onCopy={onCopyHandle}
                onEdit={openEditModalHandler}
              />
            )
          );
        })}
      </Wrapper>
      {vendorCreateContactPermit && (
        <Button onClick={openModalHandler} variant="secondary">
          {t("contacts.add")}
        </Button>
      )}
      {isShowModal && (
        <ContactCreate
          onClose={closeModalHandler}
          onConfirm={addClickHandler}
          existingContacts={contactsList}
        />
      )}
      {editContact !== "" && vendorUpdateContactPermit && (
        <ContactUpdate
          onClose={closeEditModalHandler}
          onConfirm={onChangeHandle}
          existingContacts={contactsList}
          index={editContact}
          onDelete={onDeleteHandle}
        />
      )}
    </>
  );
};

ContactsList.propTypes = {
  name: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      is_primary: PropTypes.bool,
    })
  ),
  setValue: PropTypes.func,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;
