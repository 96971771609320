import { IconButton } from "../IconButton";
import styled from "styled-components";

export const ActionBox = styled.div`
  display: flex;
  gap: 8px;
`;

export const DeleteBox = styled.div`
  display: flex;
  gap: 8px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & .left-button {
    margin-left: auto;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin: 0 auto 0 16px;
  color: var(--color_primary_text);
`;
