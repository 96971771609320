import { formatCurrency } from "modules/reporting/lib/formatters";
import { Status } from "modules/users/components/UsersList/Status";
import { getUser } from "shared/components/Auth";
import {
  Email,
  Role,
} from "../../components/UserDetailsView/UserDetailsView.styled";

export const getViewRows = (fields, data, t, displayDate, language) => {
  const canBeAssign = data["can_be_assigned_to_wo"];

  const { entity, currency = "USD" } = getUser();

  const currentEntity = data?.permissions.find(
    ({ entity_id }) => entity_id === entity
  );

  const rows = fields.reduce((acc, field) => {
    const value = data[field];

    if (field === "phone_number") {
      acc.push({
        label: t("allUsers.info.phone"),
        value: value,
      });
    }

    if (field === "status") {
      acc.push({
        label: t("allUsers.info.status"),
        value: <Status value={value} />,
      });
    }

    if (field === "activity") {
      acc.push({
        label: t("allUsers.info.last_visit"),
        value: displayDate(data?.activity?.last_login),
      });
    }

    if (field === "email") {
      acc.push({
        label: t("allUsers.info.email"),
        value: <Email>{value}</Email>,
      });
    }

    if (field === "can_be_assigned_to_wo") {
      acc.push({
        label: t("allUsers.info.can_be_assigned_to_wo"),
        value: canBeAssign
          ? t("allUsers.info.can_be_assigned_to_wo_true")
          : t("allUsers.info.can_be_assigned_to_wo_false"),
      });
    }

    if (["regular_rate", "overtime_rate1", "overtime_rate2"].includes(field)) {
      canBeAssign &&
        acc.push({
          label: t(`allUsers.info.${field}`),
          value: `${
            formatCurrency(value, language, currency) ?? ""
          } ${currency}/hr`,
        });
    }

    if (field === "permissions") {
      acc.push({
        label: t("allUsers.info.entities"),
        value: value?.map(({ entity, role }) => {
          if (role !== "no_access") {
            return (
              <Role>
                {entity} - {t(`allUsers.roles.${role}`)}
              </Role>
            );
          }
        }),
        multiline: true,
      });
    }

    return acc;
  }, []);

  return { visibleRows: rows, currentEntity };
};
