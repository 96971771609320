import styled from "styled-components";
import { Divider } from "shared/components/Divider";
import { Button } from "shared/components/Button";

export const ButtonRight = styled(Button)`
  margin-left: auto;
`;

export const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactsContainer = styled.div`
  margin: 10px 0 30px;
`;
