import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Urls, Name } from "shared/components/Table";
import { useDisplayDate } from "shared/lib/hooks";
import { StarRatings } from "shared/components/StarRatings";
import CheckIcon from "shared/assets/Check.svg?react";
import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";
import { formatCurrency } from "shared/lib/helpers/common";
import { ModalLinksList } from "shared/components/ModalLinksList";

export const useVendorsTableTools = ({ onOpen }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("vendor");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    return [
      {
        field: "name",
        name: t("vendors.columns.name"),
        width: 360,
        sortable: true,
        primary: true,
        renderCell: (row, formattedValue) => (
          <Name onClick={() => onOpen(row.id)} value={formattedValue} />
        ),
        order: 0,
      },
      {
        field: "id",
        name: t("vendors.columns.id"),
        width: 120,
        sortable: true,
        formatValue: (raw) => `#${raw}`,
        hidden: !settings?.["id"]?.visible,
        order: settings?.["id"]?.order,
        type: "integer",
      },
      {
        field: "description",
        name: t("vendors.columns.description"),
        width: 320,
        sortable: true,
        hidden: !settings?.["description"]?.visible,
        order: settings?.["description"]?.order,
      },
      {
        field: "tax_id",
        name: t("vendors.columns.taxId"),
        width: 120,
        sortable: true,
        hidden: !settings?.["tax_id"]?.visible,
        order: settings?.["tax_id"]?.order,
      },
      {
        field: "vendor_contacts",
        name: t("vendors.columns.vendorContacts"),
        width: 320,
        sortable: true,
        sortQueryParam: "vendor_contacts_name",
        formatValue: (raw) => raw.map(({ name }) => name).join(", "),
        hidden: !settings?.["vendor_contacts"]?.visible,
        order: settings?.["vendor_contacts"]?.order,
      },
      {
        field: "assign_to_work_orders",
        name: t("vendors.columns.assignToWorkOrders"),
        width: 160,
        sortable: true,
        hidden: !settings?.["assign_to_work_orders"]?.visible,
        order: settings?.["assign_to_work_orders"]?.order,
        renderCell: ({ assign_to_work_orders }) =>
          assign_to_work_orders && <CheckIcon />,
      },
      {
        field: "parts_supplier",
        name: t("vendors.columns.partsSupplier"),
        width: 160,
        sortable: true,
        hidden: !settings?.["parts_supplier"]?.visible,
        order: settings?.["parts_supplier"]?.order,
        renderCell: ({ parts_supplier }) => parts_supplier && <CheckIcon />,
      },
      {
        field: "categories",
        sortQueryParam: "categories_name",
        name: t("vendors.columns.categories"),
        width: 200,
        sortable: true,
        formatValue: (raw) => raw.map(({ name }) => name).join(", "),
        hidden: !settings?.["categories"]?.visible,
        order: settings?.["categories"]?.order,
      },
      {
        field: "parts",
        sortQueryParam: "parts_name",
        name: t("vendors.columns.parts"),
        width: 200,
        sortable: true,
        formatValue: (parts) => (
          <ModalLinksList
            type="part"
            items={parts}
            layout="inline"
            separator=", "
          />
        ),
        hidden: !settings?.["parts"]?.visible,
        order: settings?.["parts"]?.order,
      },
      {
        field: "hourly_rate",
        name: t("vendors.columns.hourlyRate"),
        width: 160,
        sortable: true,
        hidden: !settings?.["hourly_rate"]?.visible,
        order: settings?.["hourly_rate"]?.order,
        formatValue: (value) => formatCurrency(value),
      },
      {
        field: "locations",
        sortQueryParam: "locations_name",
        name: t("vendors.columns.locations"),
        width: 340,
        sortable: true,
        renderCell: (row) => (
          <Locations>
            <ModalLinksList
              type="location"
              layout="inline"
              items={row.locations}
              separator=", "
            />
          </Locations>
        ),
        hidden: !settings?.locations?.visible,
        order: settings?.locations?.order,
      },
      {
        field: "assets",
        sortQueryParam: "assets_name",
        name: t("vendors.columns.assets"),
        width: 320,
        sortable: true,
        formatValue: (assets) => (
          <ModalLinksList
            type="asset"
            items={assets}
            layout="inline"
            separator=", "
          />
        ),
        hidden: !settings?.["assets"]?.visible,
        order: settings?.["assets"]?.order,
      },
      {
        field: "address",
        name: t("vendors.columns.address"),
        width: 320,
        sortable: true,
        hidden: !settings?.["address"]?.visible,
        order: settings?.["address"]?.order,
      },
      {
        field: "created_by_name",
        name: t("vendors.columns.createdBy"),
        width: 90,
        sortable: true,
        hidden: !settings?.["created_by_name"]?.visible,
        order: settings?.["created_by_name"]?.order,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
      },
      {
        field: "created_at",
        name: t("vendors.columns.createdWhen"),
        width: 200,
        sortable: true,
        formatValue: (raw) => displayDate(raw, true),
        hidden: !settings?.["created_at"]?.visible,
        order: settings?.["created_at"]?.order,
        filter: true,
        type: "date",
      },
      {
        field: "rating",
        name: t("vendors.columns.rating"),
        width: 160,
        sortable: true,
        hidden: !settings?.["rating"]?.visible,
        order: settings?.["rating"]?.order,
        renderCell: ({ rating }) => (
          <StarRatings value={rating} width="17px" edit={false} />
        ),
      },
      {
        field: "urls",
        sortQueryParam: "urls_label",
        name: t("vendors.columns.urls"),
        width: 360,
        sortable: true,
        order: settings?.["urls"]?.order,
        hidden: !settings?.["urls"]?.visible,
        renderCell: ({ urls }) => <Urls values={urls} />,
        filter: false,
      },
    ]
      .filter(({ field }) => settings?.[field]?.visible_global !== false)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [t, tableSettings?.data.payload, displayDate, onOpen]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};

const Locations = styled.div`
  width: 100%;
`;
