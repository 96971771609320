import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getProgressionOptions } from "../../lib/helpers";
import { ModalLink } from "shared/components/ModalLink";

export const Progression = ({
  progression = {},
  status = "",
  width = `84px`,
}) => {
  const { t } = useTranslation();

  const { id, percentage } = progression;

  if (id) {
    return (
      <StyledLink modalParams={{ type: "wo", id }}>
        {t("preventive_maintenances.active")}
      </StyledLink>
    );
  }

  const { percentageColor, containerColor, percentageValue, showPercentage } =
    getProgressionOptions({
      percentage,
      status,
      id,
    });

  return (
    <Box>
      <StockContainer containerColor={containerColor} width={width}>
        <StockPercentage
          width={percentageValue}
          percentageColor={percentageColor}
        />
      </StockContainer>
      {showPercentage && `${percentageValue}%`}
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color_table_text_color);
  font-size: 14px;
  line-height: 20px;
`;

const StockContainer = styled.div`
  height: 6px;
  width: ${({ width }) => width};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: ${({ containerColor }) => containerColor};
  overflow: hidden;
`;

const StockPercentage = styled.div`
  width: ${({ width }) => width}%;
  background-color: ${({ percentageColor }) => percentageColor};
  border-radius: 4px 0 0 4px;
`;

const StyledLink = styled(ModalLink)`
  font-size: 14px;
`;
