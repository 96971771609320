import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const PrimaryStyle = css`
  background-color: var(--color_primary);
  color: var(--color_background);
  border: 1px solid var(--color_primary);
  &:hover {
    background: var(--color_primary_dark);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0 0 0 2px var(--color_primary_dark);
    border: 1px solid var(--color_background);
  }
`;

const PrimaryDarkStyle = css`
  background-color: var(--color_primary);
  color: var(--color_background);
  border: 1px solid var(--color_primary);
  &:hover {
    background: var(--color_primary_dark);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0 0 0 2px var(--color_primary_dark);
    border: 1px solid var(--color_background);
  }
`;

const SecondaryStyle = css`
  color: var(--color_primary_text);
  background-color: var(--color_background_light);
  border: 1px solid var(--color_grey100);
  &:hover {
    background: var(--color_primary_hover);
    border: 1px solid var(--color_primary_dark);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0 0 0 2px var(--color_primary_dark);
    border: 1px solid var(--color_background_light);
  }
`;

const SecondaryDarkStyle = css`
  background-color: var(--color_background);
  color: var(--color_primary);
  border: 1px solid var(--color_primary);
  &:hover {
    background: var(--color_primary_hover);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_primary_dark);
    box-shadow: 0 0 0 2px var(--color_primary_dark);
    border: 1px solid var(--color_background);
  }
`;

const Button = css`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 12px;
  text-decoration: none;
  ${({ variant }) => (!variant || variant === "primary") && PrimaryStyle}
  ${({ variant }) => variant === "primaryDark" && PrimaryDarkStyle}
  ${({ variant }) => variant === "secondary" && SecondaryStyle}
  ${({ variant }) => variant === "secondaryDark" && SecondaryDarkStyle}
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : "4px"};
  ${({ icon }) =>
    !!icon &&
    css`
      padding-right: 23px;
      padding-left: 23px;
    `}
  &:hover {
    cursor: pointer;
  }
`;

const Text = css`
  color: var(--color_primary_text);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const LinkRouter = styled(Link)`
  ${({ type }) => (!type || type === "text") && Text}
  ${({ type }) => type === "button" && Button}
`;
