import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";
import ZoomIcon from "shared/assets/icon/zoom.svg?react";
import { useFetch } from "shared/lib/hooks/useFetch";
import { getFullUrl } from "shared/lib/helpers";

import { PendingStatus } from "./components/PendingStatus";
import { RejectedStatus } from "./components/RejectedStatus";
import { FulfilledStatus } from "./components/FulfilledStatus";
import { getUser } from "../../../../shared/components/Auth/index.js";

export const Image = ({ url, name, onDelete = null, onView = null }) => {
  const imageUrl = getFullUrl(url);
  const loadImage = useRef(false);
  const { fetch, fetchAbort, isLoading, progress, error } = useFetch();

  const handleClick = () => {
    if (!onDelete && !onView) {
      return () => null;
    }
    return onDelete ? onDelete(name) : onView(name);
  };

  const ActionIcon = onDelete ? TrashCanIcon : ZoomIcon;
  const ActionLabel = onDelete ? "Delete" : "Zoom In";

  const user = getUser();

  useEffect(() => {
    if (imageUrl && !loadImage.current) {
      loadImage.current = "#";
      fetch(imageUrl, {
        credentials: "include",
        headers: { "X-Entity-Id": user?.entity },
      }).then((file) => {
        loadImage.current = URL.createObjectURL(file);
      });
    }
  }, [imageUrl, fetch]);

  const handleStopLoading = () => {
    fetchAbort();
  };

  const handleRetry = () => {
    fetch(imageUrl)
      .then((file) => {
        loadImage.current = URL.createObjectURL(file);
      })
      .catch(() => (loadImage.current = true));
  };

  const handleDeleteImage = () => {
    onDelete && onDelete(name);
  };

  return (
    <ImageContainer>
      {isLoading && (
        <PendingStatus
          name={name}
          progress={progress}
          onStop={handleStopLoading}
        />
      )}
      {error && (
        <RejectedStatus
          name={name}
          errorMessage={"Upload failed"}
          onDelete={onDelete ? handleDeleteImage : null}
          onRetry={handleRetry}
        />
      )}
      {!isLoading && !error && loadImage.current && (
        <FulfilledStatus
          url={loadImage.current}
          name={name}
          ActionIconNode={ActionIcon}
          label={ActionLabel}
          onClick={handleClick}
          isAction={Boolean(onDelete || onView)}
        />
      )}
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  position: relative;
  width: 174px;
  height: 174px;
  border-radius: 10px;
  border: 1px solid var(--color_grey100);
  overflow: hidden;
  cursor: pointer;
`;
