import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Highlighter = ({ value = "", highlight = null }) => {
  if (typeof value !== "string") {
    return value;
  }

  const startIndex = highlight
    ? value
        .toLowerCase()
        .search(highlight.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
    : null;

  if (typeof startIndex === "number" && startIndex >= 0 && highlight) {
    const endIndex = highlight.length + startIndex;

    return (
      <>
        {value.substring(0, startIndex)}
        <Highlighted>{value.substring(startIndex, endIndex)}</Highlighted>
        {value.substring(endIndex)}
      </>
    );
  }

  return value;
};

Highlighter.propTypes = {
  value: PropTypes.string.isRequired,
  highlight: PropTypes.string,
};

const Highlighted = styled.span`
  background-color: var(--color_primary);
`;
