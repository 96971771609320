import styled, { css } from "styled-components";
import SearchIcon from "shared/assets/icon/search.svg?react";
import CloseIcon from "shared/assets/icon/close.svg?react";
import RowIcon from "shared/assets/Row.svg?url";
import { IconButton } from "../IconButton";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  ${({ width }) =>
    width &&
    css`
      max-width: ${({ width }) => width};
    `}
`;

export const DropDownWrapper = styled.div`
  width: 100%;

  &::after {
    content: url(${RowIcon});
    position: absolute;
    right: 15px;
    top: 10px;
    transition: 0.5s;
    transform: rotate(90deg);
    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(270deg);
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

export const HeaderMenu = styled.input`
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-left: ${({ withSearch }) => (withSearch ? "30px" : "12px")};
  padding-right: ${({ withClear }) => (withClear ? "40px" : "32px")};
  outline: transparent;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color_grey500);
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--color_grey500);
  }

  &:placeholder-shown {
    border-color: var(--color_grey200);
  }

  &:focus {
    box-shadow: 0px 0px 0px 1px var(--color_background_light),
      0px 0px 0px 3px var(--color_primary);

    &,
    &:placeholder-shown {
      border-color: var(--color_grey900);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: var(--color_grey10);

      &,
      &:hover,
      &:placeholder-shown {
        border-color: var(--color_grey100);
      }

      &,
      &::placeholder {
        color: var(--color_grey500);
      }
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      &,
      &:placeholder-shown {
        border-width: 2px;
        border-color: var(--color_alert);
      }
    `}
`;

export const Header = styled.div`
  display: flex;
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  min-height: ${({ height }) => (height ? `${height}px` : "40px")};
  padding: 7px 60px 7px 35px;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  border-radius: 5px;
  border: 1px solid var(--color_grey500);

  ${({ isPlaceholderShown }) =>
    isPlaceholderShown &&
    css`
      border: 1px solid var(--color_grey200);
      svg {
        color: var(--color_placeholder);
      }
    `}

  &:hover {
    border: 1px solid var(--color_grey500);
  }
`;

export const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  top: 12px;
  color: var(--color_header_search_icon);
`;

export const CleareIconStyled = styled(CloseIcon)`
  position: absolute;
  background: var(--color_grey500);
  color: var(--color_text_light);
  border-radius: 15px;
  padding: 5px;
  right: 35px;
  top: 10px;

  box-sizing: border-box;
  width: unset;
  height: unset;
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  color: var(--color_placeholder);
`;

export const CurrentValue = styled.div`
  display: flex;
  align-items: center;
  color: var(--color_grey900);
`;

export const SelectedOptionsWrapper = styled.div`
  width: 100%;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  position: absolute;
  top: 7px;
  left: 28px;
  margin-left: 5px;
  & > div {
    margin-right: 5px;
  }
  & > div:last-child {
    margin-right: 0;
  }
`;

export const Description = styled.div`
  padding-top: 5px;
  padding-left: 25px;
  color: var(--color_placeholder);
  font-size: 14px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  color: var(--color_alert);
  font-size: 14px;
  top: calc(100% + 6px);
`;

export const RemoveButton = styled(IconButton)`
  width: 16px;
  height: 16px;
  color: var(--color_background);
`;
