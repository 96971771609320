import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

/* CSS vars are in `vars.css` – putting them in a `.css` file enables better IDE support */

export const GlobalStyle = () => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Styles />
    </>
  );
};

const fontFamily = `"Poppins", "Open-Sans", Helvetica, sans-serif`;

const Styles = createGlobalStyle`
    
  body {
    margin: 0;
    padding: 0;
    font-family: ${fontFamily};
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  li {
    list-style-type: none;
  }
  
  #root {
    isolation: isolate;
  }

  #app {
    transition: margin-left .5s;
    margin-left: 266px;
    min-height: 100vh;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }

  button {
    font-family: ${fontFamily};
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(180, 240, 0, 0.1);
  }

  .fc .fc-daygrid-day-top {
    flex-direction: row;
    font-size: 10px;
    font-weight: 500;
    font-family: ${fontFamily};
    color: rgba(51, 51, 51, 1);
  }

  .fc .fc-daygrid-day-number {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    font-family: ${fontFamily};
  }

  .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    background-color: rgba(180, 240, 0, 1);
  }

  .fc .fc-daygrid-day.fc-day-other {
    background-color: rgba(218, 220, 224, 0.6);
  }

  .fc .fc-day-other .fc-daygrid-day-top {
    opacity: 1;
  }

  .fc .fc-toolbar-chunk:not(:first-child, :last-child) {
    flex-grow: 1;
  }

  .fc .fc-toolbar-title {
    font-size: 25px;
    font-weight: 500;
    font-family: ${fontFamily};
    line-height: 30px;
  }

  .fc .fc-button-group > .fc-button, .fc .fc-button-group > .fc-button:active, .fc .fc-button-group > .fc-button:focus {
    border: none;
    background: none;
    color: rgba(2, 131, 111, 1);
    box-shadow: none;
  }

  .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
  }

  .fc .fc-button-group > .fc-button.fc-dayGridWeek-button, .fc .fc-button-group > .fc-button.fc-dayGridMonth-button {
    font-size: 14px;
    font-weght: 400;
    color: rgba(51, 50, 56, 1);
    line-height: 16px;
    border: 1px solid rgba(191, 191, 195, 1);
    text-transform: capitalize;
  }

  .fc .fc-button-group > .fc-button.fc-button-active {
    border: 2px solid rgba(2, 131, 111, 1);
    background: rgba(0, 240, 180, 0.2);
    box-shadow: none !important;
  }

  .fc .fc-button-primary:not(:disabled):focus {
    box-shadow: none;
    color: rgba(51, 50, 56, 1);
  }

  .fc thead {
    border: 1px solid #ddd;
  }

  .fc thead th {
    font-weight: 400;
    font-size: 14px;
    background: rgba(236, 236, 239, 1);
    border: none;
  }

  .fc thead th:last-child {
    border-right: 1px solid #ddd;
  }

  .fc .fc-daygrid-more-link {
    color: rgba(2, 131, 111, 1);
    width: 100%;
    padding: 5px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 20px;
    border: 1px solid transparent;
  }

  .fc .fc-daygrid-more-link:hover {
    background-color: rgba(0, 240, 180, 0.2);
  }

  .fc .fc-daygrid-day-bottom {
    margin: 0;
    padding: 0px 4px;
  }
`;

export default GlobalStyle;
