import { ReportActionBarProps } from "modules/reporting/types/ReportActionBarProps";
import { ReportActionBar } from "modules/reporting/components/ReportActionBar";
import { useTranslation } from "react-i18next";

export const AssetCostsAndDowntimeReportActionBar = ({
  buttons,
}: ReportActionBarProps) => {
  const { t } = useTranslation();

  return (
    <ReportActionBar
      filterEntity="assets"
      filters={[
        {
          field: "name",
          name: t("assets.columns.name"),
        },
        {
          field: "id",
          name: t("assets.columns.id"),
        },
        {
          field: "qr_code",
          name: t("assets.columns.code"),
        },
        {
          field: "asset_types",
          name: t("assets.columns.assetTypes"),
        },
        {
          field: "assign_to",
          name: t("assets.columns.assignedTo"),
        },
        {
          field: "warranty_exp_date",
          name: t("assets.columns.warrantyExpDate"),
        },
        {
          field: "location",
          name: t("assets.columns.location"),
        },
      ]}
      buttons={buttons}
    />
  );
};
