import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";

import { getInitialValues } from "../../lib/helpers";
import { otherCostValidationSchema } from "../../configs/validationSchema";
import { WorkOrderOtherCostEditor } from "../WorkOrderOtherCostEditor";

export const WorkOrderOtherCostCreate = ({ onCreate, onClose }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    addOtherCostForm.resetForm();
    onClose();
  };

  const addOtherCostForm = useForm({
    initialValues: getInitialValues({ type: "create" }),
    validationSchema: otherCostValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (body) => {
      onCreate(body);
      closeModal();
    },
  });

  return (
    <ConfirmationDialog
      title={t(`work_order_other_costs.add`)}
      confirmButtonLabel={t("save")}
      cancelButtonLabel={t("cancel")}
      styles={{ "overflow-y": "auto" }}
      form={addOtherCostForm}
      disabledSubmit={
        !addOtherCostForm.dirty ||
        !!Object.values(addOtherCostForm.errors).length
      }
      description={<WorkOrderOtherCostEditor form={addOtherCostForm} />}
      onProceed={addOtherCostForm.handleSubmit}
      onCancel={closeModal}
    />
  );
};
