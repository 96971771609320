import React, { useState } from "react";
import styled from "styled-components";

import { IconButton } from "shared/components/IconButton";
import { usePermission } from "app/providers/PermissionsProvider";

import OnHoldIcon from "../../assets/icons/onHoldGrey.svg?react";
import DeclineIcon from "../../assets/icons/declineGrey.svg?react";
import ApproveIcon from "../../assets/icons/approveGrey.svg?react";
import { RequestStatusUpdate } from "../RequestStatusUpdate";

export const RequestTitle = ({ id, value, onClick, onApprove, status }) => {
  const [modal, setModal] = useState(false);

  const { requestDeclinePermit, requestPutOnHoldPermit, requestApprovePermit } =
    usePermission();

  return (
    <RequestTitleContainer>
      <RequestTitleValue onClick={onClick}>{value}</RequestTitleValue>
      {requestApprovePermit &&
        (status === "pending" || status === "on_hold") && (
          <IconButton onClick={() => onApprove(id)}>
            <ApproveIcon id="approve-icon" />
          </IconButton>
        )}
      {requestDeclinePermit &&
        (status === "pending" || status === "on_hold") && (
          <IconButton onClick={() => setModal("declined")}>
            <DeclineIcon id="decline-icon" />
          </IconButton>
        )}
      {requestPutOnHoldPermit && status === "pending" && (
        <IconButton onClick={() => setModal("on_hold")}>
          <OnHoldIcon id="onhold-icon" />
        </IconButton>
      )}
      {modal && (
        <RequestStatusUpdate
          id={id}
          status={modal}
          onClose={() => setModal(false)}
        />
      )}
    </RequestTitleContainer>
  );
};

const RequestTitleContainer = styled.div`
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;
  & svg {
    stroke: #bfbfc3;
  }
`;

const RequestTitleValue = styled.div`
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
  flex-grow: 1;
`;
