import React, { forwardRef } from "react";
import { Box, ButtonSpinner } from "./Button.styled";

export interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
  icon?: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  variant?:
    | "primary"
    | "primaryDark"
    | "secondary"
    | "secondaryDark"
    | "tertiary"
    | "danger"
    | "dangerTransparent";
  size?: "normal" | "small";
  children: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ icon, children, disabled, size = "normal", isLoading, ...props }, ref) => (
    <Box {...props} disabled={disabled || isLoading} ref={ref} size={size}>
      {isLoading ? <ButtonSpinner /> : icon}
      <span>{children}</span>
    </Box>
  )
);
