import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { defaultReportPeriod } from "modules/reporting/lib/reportPeriodOptions";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";

/**
 * Manages ReportPeriod state.
 * Returns `[reportPeriod, setReportPeriod]`, similar to `useState`.
 */
export const useReportPeriod = () => {
  return usePersistentState<ReportPeriod>("reportPeriod", defaultReportPeriod);
};
