import React from "react";
import { useTranslation } from "react-i18next";
import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { useAddRequestPortalMutation } from "modules/requestPortals";

import {
  requestPortalValidationSchema,
  getInitialValues,
  getRequestPortalFormatForService,
} from "../../lib/helpers/form";
import { RequestPortalEditor } from "../RequestPortalEditor";

export const RequestPortalCreate = ({ isShow, onClose, onOpen }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [addRequestPortal] = useAddRequestPortalMutation();

  const onCloseModal = () => {
    addRequestPortalForm.resetForm();
    onClose();
  };

  const addRequestPortalForm = useForm({
    initialValues: getInitialValues({ type: "create" }),
    validationSchema: requestPortalValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (body) => {
      const requestPortal = getRequestPortalFormatForService(body);
      addRequestPortal(requestPortal)
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("request_portals.snack.successCreationTitle"),
            content: t("request_portals.snack.successCreationMessage", {
              name: data.title,
            }),
            type: "success",
            primaryButtonLabel: t("request_portals.snack.view"),
            secondaryButtonLabel: t("close"),
            handlePrimaryButtonClick: () => onOpen(data.uuid),
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("request_portals.snack.failCreationTitle"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("request_portals.createNew")}
      onClose={onCloseModal}
      onSubmit={addRequestPortalForm.handleSubmit}
      disabledSubmit={
        !addRequestPortalForm.dirty ||
        !!Object.values(addRequestPortalForm.errors).length
      }
    >
      <RequestPortalEditor form={addRequestPortalForm} />
    </EditorModal>
  );
};
