import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import PendingIcon from "../../assets/icons/pending.svg?react";
import ApprovedIcon from "../../assets/icons/approved.svg?react";
import OnHoldIcon from "../../assets/icons/onhold.svg?react";
import DeclinedIcon from "../../assets/icons/declined.svg?react";
import CompletedIcon from "../../assets/icons/completed.svg?react";

const StatusMap = {
  pending: {
    Icon: PendingIcon,
    color: "status_orange",
  },
  approved: {
    Icon: ApprovedIcon,
    color: "status_lime",
  },
  on_hold: {
    Icon: OnHoldIcon,
    color: "status_grey",
  },
  declined: {
    Icon: DeclinedIcon,
    color: "status_red",
  },
  completed: {
    Icon: CompletedIcon,
    color: "status_green",
  },
};

export const Status = ({ value }) => {
  const { t } = useTranslation();
  const { Icon, color } = StatusMap[value];
  return (
    <StatusContainer color={color}>
      <Icon /> <span>{t(`work_order_requests.status.${value}`)}</span>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color_grey700);
  border: 2px solid ${({ color }) => `var(--color_${color})`};
  font-falimy: inherit;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  line-height: 16px;
  border-radius: 12px;
  gap: 4px;
  flex-shrink: 0;
`;
