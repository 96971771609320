import { formatDateValue, downloadFile } from "shared/lib/helpers";
import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const usersApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    //get admin, limited_admin, technician users, who can be assigned to work order
    getTechnicians: builder.query({
      query: (params) => ({
        url: "users",
        params: {
          ...params,
          "filter[can_be_assigned_to_wo_true]": 1,
          "filter[user_permissions_user_role_id_in]": [1, 2, 3],
          "filter[status_not_eq]": "inactive",
        },
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Technicians", id: "TechniciansList" },
        ...getTagList("Technicians", result?.data),
      ],
    }),
    getAllUsers: builder.query({
      query: (params) => ({
        url: "admin/users",
        params,
      }),
      providesTags: (result) => [
        { type: "AllUsers", id: "AllUsersList" },
        ...getTagList("AllUsers", result?.data),
      ],
    }),
    getEntityUsers: builder.query({
      query: (params) => ({
        url: "users",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "EntityUsers" },
        ...getTagList("EntityUsers", result?.data),
      ],
    }),
    getUser: builder.query({
      query: (id) => `users/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "AllUsers", id }],
    }),
    exportUsers: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `users/download_report`,
          method: "POST",
          params,
          responseHandler: (response) => response.blob(),
        });
        const filename = formatDateValue(new Date()) + "-users.xls";
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
    emailInvitation: builder.mutation({
      query: ({ id, type }) => ({
        url: `users/${id}/${type}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "AllUsers", id },
        { type: "EntityUsers", id },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    updateUserDetails: builder.mutation({
      query: ({ id, body }) => ({
        url: `users/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "AllUsers", id },
        { type: "EntityUsers", id },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    inviteUser: builder.mutation({
      query: ({ body }) => ({
        url: `users/invite`,
        method: "Post",
        body,
      }),
      invalidatesTags: () => [
        { type: "EntityUsers" },
        { type: "AllUsers", id: "AllUsersList" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: "/users/logout",
        method: "DELETE",
      }),
      transformErrorResponse: (response) => response.data,
    }),
    getUsersRoles: builder.query({
      query: () => ({
        url: "/user_roles",
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: () => [{ type: "AllUsers", id: "UsersRole" }],
    }),
    getNotificationSettings: builder.query({
      query: () => ({
        url: `users/user_notification_settings`,
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: () => [{ type: "Notifications", id: "UserNotifications" }],
    }),
    updateNotificationSetting: builder.mutation({
      query: (body) => ({
        url: `users/user_notification_settings`,
        method: "PUT",
        body,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: () => [
        { type: "Notifications", id: "UserNotifications" },
      ],
    }),
    getUserSession: builder.query({
      query: () => ({
        url: "user_sessions/me",
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: (result) => [
        "Sessions",
        ...getTagList("Sessions", result?.data),
      ],
    }),
    changeUserPassword: builder.mutation({
      query: ({ id, body }) => ({
        url: `users/${id}/change_password`,
        method: "PUT",
        body,
      }),
      transformErrorResponse: (response) => response.data,
    }),
    changeUserEmail: builder.mutation({
      query: ({ id, body }) => ({
        url: `users/${id}/change_email`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "AllUsers", id: "AllUsersList" }],
      transformErrorResponse: (response) => response.data,
    }),
    deleteSession: builder.mutation({
      query: (id) => ({
        url: `user_sessions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sessions"],
      transformErrorResponse: (response) => response.data,
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response) => response.data,
    }),
    getUserPermissions: builder.query({
      query: (id) => `permissions/user/${id}`,
      transformErrorResponse: (response) => response.data,
      providesTags: () => [{ type: "Entity" }],
    }),
    getUserHash: builder.query({
      query: (id) => `users/${id}/user_hash`,
    }),
  }),
});

export const {
  useGetTechniciansQuery,
  useGetAllUsersQuery,
  useGetUserQuery,
  useUpdateUserDetailsMutation,
  useLazyGetUsersRolesQuery,
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingMutation,
  useInviteUserMutation,
  useLogoutUserMutation,
  useExportUsersMutation,
  useEmailInvitationMutation,
  useGetEntityUsersQuery,
  useGetUserSessionQuery,
  useChangeUserPasswordMutation,
  useChangeUserEmailMutation,
  useDeleteSessionMutation,
  useDeleteUserMutation,
  useGetUserPermissionsQuery,
  useLazyGetUserPermissionsQuery,
  useGetUserHashQuery,
} = usersApi;
