import React from "react";
import styled from "styled-components";
import { getFormattedTimer } from "modules/workOrderTime/lib/helpers";
import { useEffect, useState } from "react";
import TimerIcon from "../../assets/icons/timer.svg?react";

export const WorkOrderTitle = ({ onClick, value, startedTimerAt }) => {
  const [timer, setTimer] = useState("");

  useEffect(() => {
    if (startedTimerAt) {
      const intervalId = setInterval(() => {
        const formattedTimer = getFormattedTimer(startedTimerAt);
        setTimer(formattedTimer);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [startedTimerAt]);

  return (
    <TitleContainer onClick={onClick}>
      <SubString>{value}</SubString>
      {startedTimerAt && timer && (
        <Timer>
          <TimerIcon />
          <div>{timer}</div>
        </Timer>
      )}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const Timer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  width: 78px;
  background: var(--color_corp_red);
  padding: 0 4px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: white;
  gap: 3px;
`;

const SubString = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;
