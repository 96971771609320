import styled, { css } from "styled-components";
import { H1 } from "../Typography";
import { IconButton } from "../IconButton";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
`;

export const Box = styled.div`
  background-color: var(--color_background_light);
  border-radius: 4px;
  min-width: 512px;
  max-width: 768px;
  max-height: calc(100vh - 50px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.24);
  ${(props) =>
    props.styles &&
    css`
      ${props.styles}
    `}
`;

export const Header = styled.div`
  padding: 18px 16px 8px;
  display: flex;
  align-items: center;
`;

export const Title = styled(H1)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 624;
  color: var(--color_grey900);
  margin-right: auto;
`;

export const CloseIconButton = styled(IconButton).attrs({
  variant: "primary",
})`
  width: 24px;
  height: 24px;
`;

export const Body = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color_grey900);
  padding: 8px 16px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 8px 16px 16px;
`;
