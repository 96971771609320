import zxcvbn from "zxcvbn";
import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { PasswordStrength } from "./PasswordStrength";

import { FormCheckbox } from "shared/components/FormCheckbox";
import { InlineLabel } from "shared/components/widgets";
import { passwordRegexp } from "shared/lib/constants/forms";
import styled, { css } from "styled-components";

const statusMap = {
  0: "weak",
  1: "weak",
  2: "medium",
  3: "medium",
  4: "strong",
};

export const PasswordField = ({
  form,
  variant = "create",
  columnLabel = true,
}) => {
  const { t } = useTranslation();

  const password = form.values?.password;
  const result = zxcvbn(password);
  const num = result.score;

  const isLengthApproved = password.length >= 8;
  const isContainsApproved = passwordRegexp.test(password);

  const titleMap = {
    create: t("auth.signUp.createPasswordLabel"),
    password: t("auth.signUp.passwordLabel"),
    update: t("profile.password.newPassword"),
  };

  return (
    <>
      <InlineLabel
        label={titleMap[variant]}
        columnLabel={columnLabel}
        labelWidth={columnLabel ? 300 : 120}
        styles={{ fontWeight: 500 }}
      >
        <Input
          type="password"
          name="password"
          placeholder={titleMap[variant]}
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isPassword
        />
      </InlineLabel>
      <Box variant={variant}>
        <PasswordStrength status={statusMap[num]} />
        <FormCheckbox
          label={t("auth.signUp.passwordLength")}
          checkboxClassName="password"
          disabled
          checked={isLengthApproved}
          multiline
        />
        <FormCheckbox
          label={t("auth.signUp.passwordContains")}
          checkboxClassName="password"
          disabled
          checked={isContainsApproved}
          multiline
        />
      </Box>
    </>
  );
};

const UpdatePasswordStyle = css`
  margin-left: 130px;
`;

const Box = styled.div`
  ${({ variant }) => variant === "update" && UpdatePasswordStyle}
`;
