import styled from "styled-components";
import { LegendProps } from "recharts";

/**
 * Custom Legend for Recharts charts
 */
export const CustomLegend = ({ payload }: LegendProps) => {
  return (
    <Wrapper>
      {
        // Filter out the 'Total' value so it doesn't show in the legend
        payload
          ?.filter((item) => item.value !== "Total")
          .map((item) => (
            // @ts-ignore: Types are incorrect, `dataKey` exists
            <LegendItem key={item.dataKey}>
              <Icon color={item.color} />
              <span>{item.value}</span>
            </LegendItem>
          ))
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  color: var(--color_grey900);
  font-size: 12px;
  flex-wrap: wrap;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Icon = styled.span`
  display: inline-block;
  width: 16px;
  height: 10px;
  background-color: ${(p) => p.color};
`;
