import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";

import { getFormatForService, getFormatForForm } from "modules/locations";
import { ADD_LOCATION_INITIAL_VALUE } from "modules/locations/configs/constants";
import { addLocationValidationSchema } from "modules/locations/configs/validationSchema";
import { LocationEditor } from "modules/locations/components/LocationEditor";
import {
  useGetLocationQuery,
  useUpdateLocationMutation,
} from "modules/locations";
import { FileManager } from "modules/fileManager";

// TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
// import { useGetTeamsQuery } from "modules/teams";

import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";

import { useForm } from "shared/lib/hooks/useForm";
import { EditorModal } from "shared/components/Modals";
import { getOptionalFields } from "modules/locations/lib/helper";
import { useGetFormFieldsQuery } from "modules/formFields";

export const LocationUpdate = ({ isShow, onClose, onSuccess, locationId }) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const [teamsSeach, setTeamsSeach] = useState("");

  const [generateQr, setGenerateQr] = useState(false);

  const { data } = useGetFormFieldsQuery({
    type: "location",
    action_type: "upsert",
  });
  const { data: locationData } = useGetLocationQuery(locationId ?? skipToken);

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const { data: teams } = useGetTeamsQuery(
  //   isShow ? { ...(teamsSeach ? { search: teamsSeach } : {}) } : skipToken
  // );

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [updateLocation, updateLocationRes] = useUpdateLocationMutation();

  const locationsDataForForm = useMemo(() => {
    return getFormatForForm(isShow, locationData, ADD_LOCATION_INITIAL_VALUE);
  }, [locationData, isShow]);

  const optionalFields = getOptionalFields(data);

  // TODO perhaps this should be moved inside the module
  const { setValues, ...updateLocationFrom } = useForm({
    initialValues: ADD_LOCATION_INITIAL_VALUE,
    willUpdatedValues: locationsDataForForm,
    validationSchema: addLocationValidationSchema(optionalFields, t),
    onSubmit: async (body, { setFieldError }) => {
      const dataForApi = getFormatForService(body, { generateQr });
      setDisabledSubmit(true);
      try {
        await updateLocation({ id: locationId, body: dataForApi }).unwrap();
        setDisabledSubmit(false);
        onSuccess(locationId);
      } catch ({ message }) {
        if (message.name?.[0].includes(RESPONSE_MESSAGE.already_taken)) {
          setFieldError("name", "This location name already exists");
        } else if (
          message["qr_code.value"]?.[0] === RESPONSE_MESSAGE.already_taken
        ) {
          setFieldError("code", t("m2", { ns: "errors" }));
        } else {
          addSnackBar({
            title: t("locations.form.error.create"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
        }
        setDisabledSubmit(false);
      }
    },
  });

  const onCloseModal = () => {
    onClose(locationId);
    updateLocationFrom.resetForm();
    setGenerateQr(false);
  };

  return (
    <EditorModal
      label={locationData ? `Edit Location #${locationData.id}` : null}
      isOpen={isShow}
      onClose={onCloseModal}
      secondaryButtonLabel="Discard Changes"
      onSubmit={updateLocationFrom.handleSubmit}
      disabledSubmit={
        !updateLocationFrom.isWasChanged ||
        !updateLocationFrom.isFormValid ||
        disabledSubmit
      }
      form={updateLocationFrom}
      isSubmitLoading={
        updateLocationRes.isLoading || updateLocationRes.isSuccess
      }
    >
      <WrapperFileManager
        id={`update_locataion_${locationId}`}
        key={`update_locataion_${locationId}`}
        name="uploads_attributes"
        value={updateLocationFrom.values.uploads_attributes}
        setValue={updateLocationFrom.handleSetValue}
      />
      <LocationEditor
        form={updateLocationFrom}
        // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
        // teamsOptions={teams?.data}
        // teamsDefaultOptions={locationData?.teams}
        // onSearchTeams={setTeamsSeach}
        locationId={locationId}
        generateQr={generateQr}
        setGenerateQr={setGenerateQr}
        optionalFields={optionalFields}
      />
    </EditorModal>
  );
};

LocationUpdate.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;
