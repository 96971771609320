import { useTranslation } from "react-i18next";
import { LightModal } from "shared/components/Modals/LightModal";
import { EntityEditor } from "../EntityEditor";
import { useParams } from "react-router-dom";

import {
  getEntityFormatForService,
  getInitialValues,
} from "../../lib/helpers/form";
import {
  useEditEntityMutation,
  useGetEntityQuery,
} from "modules/entities/state/entitiesApi";
import { useForm } from "shared/lib/hooks/useForm";
import { useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useLazyGetTimeZonesQuery } from "modules/internalCollections";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { DeleteEntity } from "./DeleteEntity";
import { ConfirmationDialog } from "shared/components/Dialog";
import { entityValidationSchema } from "modules/entities/config/validationSchema";

export const EditEntity = ({
  isShow,
  onCloseModal,
  defaultEntityId,
  deleteOpportunity = true,
}) => {
  const { entityId } = useParams();
  const id = defaultEntityId ?? entityId;

  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [isShowCurrencyConfirmation, setIsShowCurrencyConfirmation] = useState({
    show: false,
    skip: false,
  });
  const { currentData: data } = useGetEntityQuery(id ?? skipToken);
  const [trigger, { data: timezones }] = useLazyGetTimeZonesQuery();
  const [editEntity] = useEditEntityMutation();

  useEffect(() => {
    if (isShow) {
      trigger();
    }
  }, [isShow, trigger]);

  const initialValues = useMemo(() => getInitialValues({ data }), [data]);

  const onSubmit = async (body, actions) => {
    const entity = getEntityFormatForService(body);

    if (entity.currency !== data.currency && !isShowCurrencyConfirmation.skip) {
      setIsShowCurrencyConfirmation({ show: true, skip: true });
    } else
      editEntity({ id: id, body: entity })
        .unwrap()
        .then(() => {
          onCloseModal(id);
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            actions.setFieldError("name", t("m144", { ns: "errors" }));
          } else {
            addSnackBar({
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
  };

  const { setFieldError, ...form } = useForm({
    initialValues: initialValues,
    validationSchema: entityValidationSchema(t),
    enableReinitialize: true,
    onSubmit,
  });

  const onClose = () => {
    form.resetForm();
    onCloseModal();
  };

  const onProceedCurrency = () => {
    setIsShowCurrencyConfirmation({ show: false, skip: true });
    form.handleSubmit();
  };
  const onCloseCurrencyModal = () => {
    setIsShowCurrencyConfirmation({ show: false, skip: true });
  };

  return (
    <LightModal
      isOpen={isShow}
      onClose={onClose}
      onCancel={onClose}
      secondaryButtonLabel={t("discardChanges")}
      label={t("generalSettings.editEntity")}
      onSubmit={form.handleSubmit}
      disabledSubmit={!form.isWasChanged || !form.isFormValid}
      subButton={
        deleteOpportunity && (
          <DeleteEntity closeModal={onCloseModal} entityName={data?.name} />
        )
      }
    >
      <EntityEditor form={form} timezones={timezones} />
      {isShowCurrencyConfirmation.show && (
        <ConfirmationDialog
          title={t("generalSettings.entity.confirmation.title")}
          description={t("generalSettings.entity.confirmation.description")}
          confirmButtonLabel={t("generalSettings.entity.confirmation.confirm")}
          onProceed={onProceedCurrency}
          onCancel={onCloseCurrencyModal}
        />
      )}
    </LightModal>
  );
};
