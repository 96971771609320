import styled from "styled-components";
import { MenuList } from "shared/components/MenuList";
import SearchIcon from "shared/assets/icon/search.svg?react";

const FastFilterWrapper = styled.div`
  position: relative;
`;

const FastFilterDropdown = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 4;
  overflow: hidden;

  background: var(--color_background_light);
  border-radius: 5px;
  border: 1px solid var(--color_grey200);
  box-shadow: 0 0 3px 2px rgba(19, 20, 20, 0.1);

  font-size: 14px;
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 12px;
  white-space: nowrap;
  font-size: 12px;

  border-bottom: 1px solid var(--color_grey200);
`;

const HeaderLabel = styled.span`
  font-weight: 600;
`;

const ClearButton = styled.button`
  font-weight: 400;
  color: var(--color_primary_text);

  &:disabled {
    color: var(--color_grey200);
  }
`;

const SearchInput = styled.input`
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0 8px 0 32px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid var(--color_grey200);
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--color_grey500);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px var(--color_primary);
  }
`;

const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--color_grey500);
`;

const SearchWrapper = styled.div`
  position: relative;
`;

const MenuListStyled = styled(MenuList)`
  cursor: pointer;
  white-space: nowrap;

  border-radius: unset;
  border: unset;
  box-shadow: unset;
  overflow: auto;
`;

const NoOptionsPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  color: var(--color_grey500);
  padding: 12px;
`;

export {
  FastFilterWrapper,
  FastFilterDropdown,
  DropdownHeader,
  HeaderLabel,
  ClearButton,
  SearchInput,
  SearchIconStyled,
  SearchWrapper,
  MenuListStyled,
  NoOptionsPlaceholder,
};
