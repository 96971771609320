import styled, { css } from "styled-components";

// NOTE: The arrow element is outside the CalendarContainer, so
//   "header" and "triangle" element styles are in GlobalStyles.jsx

export const HeaderWrapper = styled.div`
  padding: 0 4px 10px 4px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderControlGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ChevronButton = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  &:hover {
    background-color: var(--color_grey50);
  }
`;

export const Chevron = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
`;

const HeaderInput = css`
  border: none;
  appearance: none;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  background-color: transparent;
`;

export const Select = styled.select`
  ${HeaderInput};
`;

export const Input = styled.input`
  ${HeaderInput};

  max-width: 60px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
`;
