import { TFunction } from "i18next";
import { ReportBucket } from "modules/reporting/types/ReportBucket";

/** A list of chart buckets and labels for PDF report exports */
export function getBucketedChartsList(t: TFunction) {
  return [
    {
      bucket: "day" as ReportBucket,
      label: t("reporting.bucketOptions.dailyView"),
    },
    {
      bucket: "week" as ReportBucket,
      label: t("reporting.bucketOptions.weeklyView"),
    },
    {
      bucket: "month" as ReportBucket,
      label: t("reporting.bucketOptions.monthlyView"),
    },
    {
      bucket: "year" as ReportBucket,
      label: t("reporting.bucketOptions.yearlyView"),
    },
  ];
}
