import { useTranslation } from "react-i18next";
import { Container } from "shared/components/Container/Container";
import AddIcon from "shared/assets/icon/add.svg?react";
import { Header } from "widgets/Header";
import { AssetList } from "modules/assets";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalLink } from "shared/components/ModalLink";

export const List = () => {
  const { t } = useTranslation();

  const { assetCreatePermit, assetsListPermit, assetsListExportPermit } =
    usePermission();

  const setModal = useSetModal();

  return (
    <TablePageLayout>
      {assetsListPermit && (
        <>
          <Header
            title={t("assets.title")}
            searchPlaceholder={t("assets.searchPlaceholder")}
            action={
              assetCreatePermit && (
                <ModalLink
                  type="button"
                  icon={<AddIcon />}
                  modalParams={{ type: "asset", id: "new" }}
                >
                  Asset
                </ModalLink>
              )
            }
            horizontallySpaciousPage
          />
          <Container>
            <AssetList
              onOpenAsset={(id) =>
                id ? setModal({ type: "asset", id }) : setModal()
              }
              withExport={assetsListExportPermit}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
