import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import { format, parseISO } from "date-fns";

import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { styles } from "./ReportPDFTemplate.styled";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";
import { useTranslation } from "react-i18next";
import { PDFFooter } from "shared/components/PDFFooter";

export interface ReportPDFTemplateProps {
  reportName: string;
  reportPeriod?: ReportPeriod;
  kpis: { value: string; label: string }[];
  charts?: {
    title?: string;
    subtitle?: string;
    src: string;
  }[];
}

/** Report Template for PDF exports */
export const ReportPDFTemplate = ({
  reportPeriod,
  reportName,
  kpis,
  charts,
}: ReportPDFTemplateProps) => {
  return (
    <Document>
      <Page style={styles.body} size="LETTER">
        <View style={styles.header} fixed>
          <Text>Report</Text>
          <ReportPeriodText reportPeriod={reportPeriod} />
        </View>
        <View style={styles.main}>
          <Text style={styles.h1}>{reportName}</Text>
          <KPIs kpis={kpis} />
          {charts?.map((chart, index) => (
            <View key={index} wrap={false}>
              <Text style={styles.h2}>{chart.title}</Text>
              <Text style={styles.h3}>{chart.subtitle}</Text>
              <Image style={styles.chart} src={chart.src} />
            </View>
          ))}
        </View>
        <PDFFooter />
      </Page>
    </Document>
  );
};

const f = "yyyy-MM-dd";
const ReportPeriodText = ({
  reportPeriod,
}: {
  reportPeriod?: ReportPeriod;
}) => {
  const { t } = useTranslation();
  if (!reportPeriod) return null;

  if (reportPeriod.value === reportPeriodCurrent.value) {
    return <Text>{format(new Date(), "yyyy-MM-dd h:mma")}</Text>;
  }

  let rangeString = undefined;
  if (reportPeriod?.startDate && reportPeriod?.endDate) {
    const startFormatted = format(parseISO(reportPeriod.startDate), f);
    const endFormatted = format(parseISO(reportPeriod.endDate), f);
    rangeString = `[${startFormatted} – ${endFormatted}]`;
  }

  return (
    <Text>
      Period: {t(`reporting.periodOptions.${reportPeriod.value}`)} {rangeString}
    </Text>
  );
};

interface KPIViewProps {
  kpis: ReportPDFTemplateProps["kpis"];
}

const KPIs = ({ kpis }: KPIViewProps) => {
  return (
    <View>
      <Text style={styles.h2}>KPIs</Text>
      <View style={styles.kpis}>
        {kpis.map((kpi, index) => (
          <View key={index} style={styles.kpiItem}>
            <Text style={styles.kpiLabel}>{kpi.label}</Text>
            <Text style={styles.kpiValue}>{kpi.value}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};
