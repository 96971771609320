import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useDisplayTerm = () => {
  const { t } = useTranslation();

  return useCallback((term) => {
    const monthsLeft = term % 12;
    const years = Math.floor(term / 12);

    const yearsString = `${years} ${t("years", { count: years })}`;

    if (monthsLeft) {
      return `${yearsString} ${monthsLeft} ${t("months", {
        count: monthsLeft,
      })}`;
    }

    return yearsString;
  }, []);
};
