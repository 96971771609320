import React from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "shared/lib/hooks/useForm";

import { Button } from "shared/components/Button";
import { Input } from "shared/components/Input";
import { Box, Title, ActionBox, DividerStyled, Container } from "../styled";

import { InlineLabel } from "shared/components/widgets";
import { emailValidationSchema } from "modules/auth/config/validationSchema";
import { useForgotPasswordMutation } from "modules/auth/state/authApi";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

const initialValues = {
  email: "",
};

export const ForgotPasswordEmailForm = ({ setTabs }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [forgotPassword] = useForgotPasswordMutation();

  const onSubmit = async (body) => {
    forgotPassword(body)
      .unwrap()
      .then(() => {
        setTabs((prev) => prev + 1);
      })
      .catch(({ error }) => {
        if (error === "User not found with that email address.") {
          return addSnackBar({
            content: t("auth.resetPassword.error"),
            type: "error",
          });
        }
        addSnackBar({
          content: error,
          type: "error",
        });
      });
  };

  const form = useForm({
    initialValues,
    validationSchema: emailValidationSchema(t),
    onSubmit,
  });

  return (
    <Container>
      <Box>
        <Title>{t("auth.resetPassword.title")}</Title>
        <InlineLabel label={t("auth.resetPassword.email")} columnLabel>
          <Input
            type="text"
            name="email"
            placeholder={t("auth.resetPassword.emailPlaceholder")}
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={form.touched.email && form.errors.email}
          />
        </InlineLabel>
        <DividerStyled />
        <ActionBox>
          <Button
            disabled={!form.isFormValid}
            type="primary"
            size="normal"
            onClick={form.handleSubmit}
          >
            {t("auth.resetPassword.resetPasswordButton")}
          </Button>
        </ActionBox>
      </Box>
    </Container>
  );
};
