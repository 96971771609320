import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Input } from "shared/components/Input";
import { Button } from "shared/components/Button";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { commentValidationSchema } from "../../configs/validationSchema";
import { INITIAL_VALUES } from "../../configs/constants";

export const CommentCreate = ({ onCreate, type, targetId }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const addCommentForm = useForm({
    initialValues: INITIAL_VALUES,
    validationSchema: commentValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async ({ comment }) => {
      onCreate({ id: targetId, comment, type })
        .unwrap()
        .then(() => {
          addCommentForm.resetForm();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("work_orders.comments.failCreationTitle"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    },
  });

  return (
    <Box>
      <Input
        name="comment"
        value={addCommentForm.values.comment}
        onChange={addCommentForm.handleChange}
        onBlur={addCommentForm.handleBlur}
        placeholder={t("work_orders.comments.placeholder")}
        errorMessage={
          addCommentForm.touched.comment && addCommentForm.errors.comment
        }
      />
      <Button
        onClick={addCommentForm.handleSubmit}
        variant="secondary"
        disabled={
          !addCommentForm.dirty || !!Object.values(addCommentForm.errors).length
        }
      >
        {t("work_orders.comments.send")}
      </Button>
    </Box>
  );
};

CommentCreate.propTypes = {
  onCreate: PropTypes.func,
};

const Box = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0 22px;
`;
