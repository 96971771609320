import styled from "styled-components";
import {
  Line,
  LineChart as RechartsLineChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  LineProps,
} from "recharts";
import { ChartLoadingAnimation } from "../ChartLoadingAnimation";
import { CustomTooltip } from "../CustomTooltip";
import { CustomLegend } from "../CustomLegend";

interface LineChartProps {
  data?: unknown[];
  xAxisKey?: string;
  lines?: LineProps[];
  isLoading?: boolean;
  className?: string;
  valueFormatter?(value: number): string;
  chartMargin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
}

export const LineChart = ({
  data = [],
  xAxisKey = "x",
  lines = [],
  isLoading,
  className,
  valueFormatter = (val) => val.toString(),
  chartMargin,
}: LineChartProps) => {
  if (isLoading) {
    return <LineChartSkeleton className={className} />;
  }

  return (
    <Wrapper className={className}>
      <ChartWrapper>
        <ResponsiveContainer>
          <RechartsLineChart data={data} margin={chartMargin}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              color={"var(--color_grey200)"}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: "var(--color_grey500)", fontSize: 12 }}
              tickFormatter={valueFormatter}
            />
            <XAxis
              dataKey={xAxisKey}
              tick={{ fill: "var(--color_grey900)", fontSize: 12 }}
              tickSize={3}
              tickMargin={4}
              minTickGap={10}
              tickLine={{ stroke: "var(--color_grey200)" }}
              axisLine={{ stroke: "var(--color_grey200)" }}
            />
            <Tooltip
              cursor={{ opacity: 0.2 }}
              formatter={valueFormatter}
              content={<CustomTooltip />}
            />
            {lines.map((line) => (
              <Line
                // @ts-ignore: dataKey works just fine
                key={line.dataKey}
                isAnimationActive={false}
                {...line}
              />
            ))}
            {lines.length > 1 && <Legend content={<CustomLegend />} />}
          </RechartsLineChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </Wrapper>
  );
};

const LineChartSkeleton = ({
  className,
}: Pick<LineChartProps, "className">) => {
  const data = [
    { x: "1", y: 10 },
    { x: "2", y: 65 },
    { x: "3", y: 45 },
    { x: "4", y: 90 },
  ];
  return (
    <Wrapper className={className}>
      <ChartWrapper>
        <ResponsiveContainer>
          <RechartsLineChart data={data} margin={{ top: 10, right: 10 }}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              color={"var(--color_grey200)"}
            />
            <YAxis
              dataKey="y"
              axisLine={false}
              tickLine={false}
              tick={{ display: "none" }}
            />
            <XAxis
              dataKey="x"
              tick={{ display: "none" }}
              tickLine={false}
              axisLine={{ stroke: "var(--color_grey200)" }}
            />
            <Line
              dataKey="y"
              isAnimationActive={false}
              type="monotone"
              stroke={"var(--color_grey100)"}
              // @ts-ignore - LineProps is missing x and y, but they do exist
              shape={({ x, y, width, height }) => (
                <ChartLoadingAnimation>
                  <rect {...{ x, y, width, height }} />
                </ChartLoadingAnimation>
              )}
            />
          </RechartsLineChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ChartWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
