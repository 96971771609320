import {
  DatePickerBase,
  DatePickerBaseProps,
} from "shared/components/DatePicker/components/DatePickerBase";
import { format, parseISO } from "date-fns";

interface DateRangePickerProps
  extends Pick<
    DatePickerBaseProps,
    "dateFormat" | "name" | "onBlur" | "errorMessage" | "showIcon"
  > {
  selected?: string | null | undefined;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  onChange: (
    dates: [string | undefined, string | undefined] | undefined
  ) => void;
}

/**
 * A date range picker component that allows selecting a start and end date.
 * Uses strings for selected/onChange instead of Date objects to avoid timezone issues.
 */
export const DateRangePicker = ({
  selected,
  startDate,
  endDate,
  onChange,
  ...datePickerProps
}: DateRangePickerProps) => {
  const { dateFormat } = datePickerProps;

  return (
    <DatePickerBase
      selectsRange
      selected={stringToDate(selected)}
      startDate={stringToDate(startDate)}
      endDate={stringToDate(endDate)}
      onChange={(dates: [Date | null, Date | null] | null) => {
        if (!dates) {
          onChange(undefined);
        } else {
          onChange([dateToString(dates[0]), dateToString(dates[1])]);
        }
      }}
      placeholderText={dateFormat}
      {...datePickerProps}
    />
  );
};

function stringToDate(value: string | null | undefined) {
  let strDate = value ? parseISO(value) : undefined;
  if (!(strDate instanceof Date) || isNaN(strDate.getTime())) {
    strDate = undefined;
  }
  return strDate;
}

function dateToString(date: Date | null) {
  return date ? format(date, "yyyy-MM-dd") : undefined;
}
