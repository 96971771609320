import { useTranslation } from "react-i18next";
import { FiltersMap } from "modules/filters/configs/constants";

interface FilterConfig {
  field: keyof typeof FiltersMap.work_orders;
  name: string;
}

/** Filterable properties for reports */
export function useReportFilterOptions(): Record<string, FilterConfig> {
  const { t } = useTranslation();

  return {
    title: {
      field: "title",
      name: t("work_orders.columns.title"),
    },
    id: {
      field: "id",
      name: t("work_orders.columns.id"),
    },
    status: {
      field: "status",
      name: t("work_orders.columns.status"),
    },
    priority: {
      field: "priority",
      name: t("work_orders.columns.priority"),
    },
    createdAt: {
      field: "created_at",
      name: t("work_orders.columns.createdAt"),
    },
    type: {
      field: "type",
      name: t("work_orders.columns.type"),
    },
    assignTo: {
      field: "assign_to",
      name: t("work_orders.columns.assignedTo"),
    },
    categories: {
      field: "categories",
      name: t("work_orders.columns.categories"),
    },
    location: {
      field: "location",
      name: t("work_orders.columns.location"),
    },
    asset: {
      field: "asset",
      name: t("work_orders.columns.asset"),
    },
    vendors: {
      field: "vendors",
      name: t("work_orders.columns.vendors"),
    },
    createdBy: {
      field: "created_by_name",
      name: t("work_orders.columns.createdBy"),
    },
    dueDate: {
      field: "due_date",
      name: t("work_orders.columns.dueDate"),
    },
    completedAt: {
      field: "completed_at",
      name: t("work_orders.columns.completedAt"),
    },
  };
}
