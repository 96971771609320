import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Status } from "../Status";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { Priority } from "shared/components/Priority";
import { useDisplayDate } from "shared/lib/hooks";

export const WOList = ({
  events,
  onOpenWorkOrder,
  onOpenPM,
  date = null,
  calendar = true,
  withOverdue = false,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  return (
    <Container calendar={calendar}>
      {events.map(
        ({
          event: {
            id,
            title,
            extendedProps: {
              priority,
              status,
              pmId,
              woId,
              isPlanned,
              assigned,
              dueDate,
            },
          },
        }) => {
          const isOverdue =
            displayDate(new Date(), true, false) >
            displayDate(dueDate, true, false);
          const showOverdue =
            withOverdue && dueDate && status !== "completed" && isOverdue;
          return (
            <Event
              onClick={() =>
                calendar && isPlanned && pmId
                  ? onOpenPM(pmId)
                  : onOpenWorkOrder(woId)
              }
              key={id}
              showOverdue={showOverdue}
            >
              <EventHeader>
                <EventDate>
                  <span>
                    {calendar
                      ? isPlanned
                        ? t("work_orders.calendar.pm")
                        : t("work_orders.calendar.wo")
                      : ""}
                    #{isPlanned ? pmId : woId}
                  </span>
                  <DueDateText showOverdue={showOverdue}>
                    {`${t("work_orders.calendar.due")} ${displayDate(
                      dueDate,
                      true
                    )}`}
                  </DueDateText>
                </EventDate>
                <Priority value={priority} />
              </EventHeader>
              <EventTitle isPlanned={isPlanned}>{title}</EventTitle>
              <EventFooter>
                <Status value={status} />
                <UserAvatarGroup users={assigned} max={4} compact />
              </EventFooter>
            </Event>
          );
        }
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 560px;
  padding: ${({ calendar }) => (calendar ? "0 16px" : "0")};
  margin-top: 30px;
`;

const Event = styled.div`
  padding: 16px 16px 20px 16px;
  border-top: 1px solid rgba(220, 220, 222, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 240, 180, 0.2);
  }
  &:first-child {
    border-top: none;
  }

  ${({ showOverdue }) =>
    showOverdue &&
    css`
      border-left: 4px solid var(--color_corp_red);
    `}
`;

const EventHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2px;
`;

const EventDate = styled.div`
  color: var(--color_grey500);
  line-height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const EventTitle = styled.p`
  color: ${({ isPlanned }) =>
    isPlanned ? "var(--color_grey500)" : "var(--color_dark_blue)"};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 16px 0;
`;

const EventFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
`;

const DueDateText = styled.span`
  color: ${({ showOverdue }) =>
    showOverdue ? "var(--color_corp_red)" : "var(--color_grey500)"};
`;
