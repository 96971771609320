import { useTranslation } from "react-i18next";
import { getUser } from "shared/components/Auth";
import { useGetWorkOrderStatusesQuery } from "modules/workOrders";
import { useGetUserQuery } from "modules/users";
import { addDays, startOfDay } from "date-fns";

export const useToggleFilterConfigs = (entity) => {
  const { t } = useTranslation();
  const userId = getUser()?.id;

  const { data: userData } = useGetUserQuery(userId, { skip: !userId });
  const isUserAssignable = userData?.can_be_assigned_to_wo;
  const { data: statusData } = useGetWorkOrderStatusesQuery();

  const commonConfigs = {
    currentUser: {
      name: t("filters.toggles.currentUser"),
      id: "currentUser",
      filters: [
        {
          field: "assign_to",
          operator: "_in",
          value: [userId],
        },
      ],
    },
  };

  if (entity === "work_orders") {
    const configs = isUserAssignable ? [commonConfigs.currentUser] : [];

    const completedId = statusData?.data?.find(
      (status) => status.name === "completed"
    )?.id;
    if (typeof completedId === "number") {
      configs.unshift({
        name: t("filters.toggles.overdue"),
        filters: [
          {
            field: "due_date",
            operator: "_lt",
            value: startOfDay(addDays(new Date(), 1)),
          },
          {
            field: "status",
            operator: "_not_eq",
            value: completedId,
          },
        ],
      });
    }

    return configs;
  } else if (entity === "work_order_requests") {
    let configs = [
      {
        name: t("filters.toggles.overdue"),
        filters: [
          {
            field: "due_date",
            operator: "_lt",
            value: startOfDay(addDays(new Date(), 1)),
          },
          {
            field: "status",
            operator: "_not_in",
            value: ["approved", "declined", "completed"],
          },
        ],
      },
    ];

    if (isUserAssignable) {
      configs.push(commonConfigs.currentUser);
    }
    return configs;
  } else if (entity === "preventive_maintenances") {
    return isUserAssignable ? [commonConfigs.currentUser] : [];
  }

  return [];
};
