import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { MaintenanceHistoryByAssigneeKPIs } from "./types";
import { ReportPeriod } from "../../../types/ReportPeriod";

const reportKey = ReportKey.MaintenanceHistoryByAssignee;
export const useMaintenanceHistoryByAssigneeKPIs = (
  reportPeriod?: ReportPeriod
) => {
  const { data, ...rest } = useGetKPIs<MaintenanceHistoryByAssigneeKPIs>(
    reportKey,
    reportPeriod
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return {
    ...rest,
    data: [
      {
        value: formatNumber(data?.total_completed, language),
        label: t(`reporting.${reportKey}.kpis.total_completed`),
      },
      {
        value: formatPercent(data?.on_time_percentage, language),
        label: t(`reporting.${reportKey}.kpis.on_time_percentage`),
      },
      {
        value: formatPercent(data?.overdue_percentage, language),
        label: t(`reporting.${reportKey}.kpis.overdue_percentage`),
      },
      {
        value: formatNumber(data?.total_labor_hours, language),
        label: t(`reporting.${reportKey}.kpis.total_labor_hours`),
      },
      {
        value: formatNumber(data?.regular_labor_hours, language),
        label: t(`reporting.${reportKey}.kpis.regular_labor_hours`),
      },
      {
        value: formatPercent(data?.regular_labor_hours_percentage, language),
        label: t(`reporting.${reportKey}.kpis.regular_labor_hours`),
      },
      {
        value: formatNumber(data?.overtime_labor_hours, language),
        label: t(`reporting.${reportKey}.kpis.overtime_labor_hours`),
      },
      {
        value: formatPercent(data?.overtime_labor_hours_percentage, language),
        label: t(`reporting.${reportKey}.kpis.overtime_labor_hours`),
      },
      {
        value: formatNumber(data?.avg_time_to_complete, language),
        label: t(`reporting.${reportKey}.kpis.avg_time_to_complete`),
      },
    ],
  };
};
