import { IconButton } from "shared/components/IconButton";
import styled, { css } from "styled-components";

export const Container = styled.div`
  border: 12px solid var(--color_grey50);
  margin-bottom: 8px;
  & p {
    margin: 4px 0 12px;
  }
`;

export const SubContainer = styled.div`
  position: relative;
  padding: 16px;
  border: 2px solid var(--color_background_light);

  ${({ defaultEntity }) =>
    !defaultEntity &&
    css`
      &:hover {
        border: 2px solid var(--color_primary);
        background-color: var(--color_corp_mint_40);

        button {
          cursor: pointer;
          visibility: visible;
        }
      }
    `}
`;

export const Box = styled.div`
  display: flex;

  ${({ defaultEntity }) =>
    defaultEntity &&
    css`
      flex-direction: column;
      gap: 5px;
    `}

  & p {
    margin: 0;
  }

  & div {
    display: flex;
    margin-right: 16px;

    & p:nth-child(2) {
      margin-left: 2px;
    }
  }

  & > p:nth-child(2) {
    margin-right: 16px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 25px;
  right: 17px;
  color: var(--color_primary_text);
  visibility: ${({ defaultEntity }) => (defaultEntity ? "visible" : "hidden")};

  & > span {
    margin-right: 5px;
  }
`;
