import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Description, H1 } from "shared/components/Typography";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface PageFallbackProps {
  resetError?: () => void;
}

/** Fallback for full-page error boundary */
export const PageFallback = ({ resetError }: PageFallbackProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const locationRef = useRef(location.pathname);

  useEffect(() => {
    if (resetError && locationRef.current !== location.pathname) {
      locationRef.current = location.pathname;
      resetError();
    }
  }, [location.pathname, resetError]);

  return (
    <Wrapper>
      <Header>{t("fallback.description_a")}</Header>
      <Description>{t("fallback.description_b")}</Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 100dvh;
`;

const Header = styled(H1)`
  font-size: 19px;
  margin-bottom: 1em;
`;
