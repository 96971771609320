import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatPercent,
} from "modules/reporting/lib/formatters";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { AssetCostsKPIs } from "./types";

const reportKey = ReportKey.AssetCosts;
export const useAssetCostsReportKPIs = (reportPeriod?: ReportPeriod) => {
  let { data, ...rest } = useGetKPIs<AssetCostsKPIs>(
    reportKey,
    reportPeriod,
    "assets"
  );

  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();
  return {
    ...rest,
    data: [
      {
        value: formatCurrency(data?.total_cost, language, currency),
        label: t(`reporting.${reportKey}.kpis.total_cost`),
      },
      {
        value: formatCurrency(data?.preventive_cost, language, currency),
        label: t(`reporting.${reportKey}.kpis.preventive_cost`),
      },
      {
        value: formatCurrency(data?.reactive_cost, language, currency),
        label: t(`reporting.${reportKey}.kpis.reactive_cost`),
      },
      {
        value: formatPercent(data?.assets_with_pms_percentage, language),
        label: t(`reporting.${reportKey}.kpis.assets_with_pms_percentage`),
      },
    ],
  };
};
