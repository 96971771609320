import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { DateTimePicker } from "shared/components/DatePicker";
import { useForm } from "shared/lib/hooks/useForm";
import Icon from "../../../assets/icons/dueDate.svg?react";
import { useDisplayDate } from "shared/lib/hooks";
import { getTimezoneDateFormats } from "shared/lib/helpers/workWithDate";
import { formatISO } from "date-fns";

const INITIAL_VALUES = { due_date: "" };

export const BulkDueDate = ({ ids = [], onUpdate, count }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const displayDate = useDisplayDate();
  const { dateTimePickerFormat } = getTimezoneDateFormats();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
    form.resetForm();
  };

  const form = useForm({
    initialValues: INITIAL_VALUES,
    enableReinitialize: true,
    onSubmit: ({ due_date }) => {
      onUpdate({ ids, due_date })
        .unwrap()
        .then(() => {
          setIsConfirmationVisible(false);
          addSnackBar({
            content: t("work_orders.bulkActions.updateDueDate.success", {
              count,
              date: displayDate(due_date, true),
            }),
            type: "success",
          });
        })
        .catch(() => {
          addSnackBar({
            title: t("work_orders.bulkActions.updateDueDate.fail"),
            type: "error",
          });
        });
    },
  });

  return (
    <>
      <IconButton onClick={clickHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-due-date" />
        <ReactTooltip
          id="icon-tooltip-due-date"
          place="bottom"
          content={t("work_orders.bulkActions.hint.dueDate")}
          opacity="1"
        />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t("work_orders.bulkActions.updateDueDate.confirmation.title")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          description={
            <Description>
              <SelectedLabel>
                {t(
                  `work_orders.bulkActions.updateDueDate.confirmation.selected_${
                    count > 1 ? "other" : "one"
                  }`,
                  { count }
                )}
              </SelectedLabel>
              <FieldBox>
                <NewLabel>
                  {t("work_orders.bulkActions.updateDueDate.confirmation.new")}
                </NewLabel>
                <div style={{ width: "100%" }}>
                  <DateTimePicker
                    name="due_date"
                    selected={form.values.due_date}
                    onChange={(val) => form.setFieldValue("due_date", val)}
                    dateFormat={dateTimePickerFormat}
                    showIcon
                    minDateTime={formatISO(new Date())}
                  />
                </div>
              </FieldBox>
            </Description>
          }
          onProceed={form.handleSubmit}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

BulkDueDate.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onUpdate: PropTypes.func.isRequired,
  count: PropTypes.number,
};

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 32px;
`;

const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

const FieldBox = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const NewLabel = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
`;

const StyledIcon = styled(Icon)`
  outline: none;
`;
