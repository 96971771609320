import { useSearchParamState } from "shared/lib/hooks/useSearchParamState";

/**
 * Gets/sets the "qf" URL query parameter
 */
export function useQuickFilterParam() {
  return useSearchParamState("qf", undefined, {
    serialize: (val) => (val ? btoa(JSON.stringify(val)) : ""),
    deserialize: (val) => {
      if (!val) return undefined;
      try {
        return JSON.parse(atob(val));
      } catch (e) {
        return undefined;
      }
    },
  });
}
