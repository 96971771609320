import { eachDayOfInterval } from "date-fns";

const countObjectsByDate = (data, displayDate) => {
  const counts = {};
  data.forEach((obj) => {
    const date = displayDate(obj.created_at, false, true, true);
    counts[date] = (counts[date] || 0) + 1;
  });
  return counts;
};

const generateDateRange = (startDate, endDate, displayDate) => {
  const range = eachDayOfInterval({ start: startDate, end: endDate }).map(
    (date) => ({
      date: displayDate(date, false, true, true),
      createdCount: 0,
    })
  );
  return range;
};

export const getDatesData = (
  startDate,
  endDate,
  data = [],
  completedData = [],
  displayDate
) => {
  if (!startDate || !endDate) return [];

  const startDateVal = displayDate(startDate, false, false, true);
  const endDateVal = displayDate(endDate, false, false, true);
  const counts = countObjectsByDate(data, displayDate);
  const completedCounts = countObjectsByDate(completedData, displayDate);
  const dateRange = generateDateRange(startDateVal, endDateVal, displayDate);

  dateRange.forEach((obj) => {
    const date = obj.date;
    obj.createdCount = counts[date] || 0;
    obj.completedCount = completedCounts[date] || 0;
  });

  return dateRange;
};
