import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "widgets/Header";
import { List as WorkOrdersList } from "modules/workOrders";
import { useStopWorkOrderTimerMutation, useTimer } from "modules/workOrderTime";
import AddIcon from "shared/assets/icon/add.svg?react";
import GraphIcon from "shared/assets/icon/graph.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { Container } from "shared/components/Container";
import { usePermission } from "app/providers/PermissionsProvider";
import { ModalLink } from "shared/components/ModalLink";
import { useSetModal } from "widgets/ModalController";
import { Button } from "shared/components/Button";
import { useFlags } from "launchdarkly-react-client-sdk";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";

export const List = () => {
  const { t } = useTranslation();
  const { activeTimer } = useTimer();
  const [stopTimer] = useStopWorkOrderTimerMutation();

  const { enableWorkOrdersListKpis } = useFlags();
  const [showKPIs, setShowKPIs] = usePersistentState("workOrdersKPIs", false);

  const { woListPermit, woCalendarPermit, woListExportPermit, woCreatePermit } =
    usePermission();

  const setModal = useSetModal();

  useEffect(() => {
    async function handleBeforeUnload() {
      if (activeTimer) {
        await stopTimer({
          work_order_id: activeTimer.work_order_id,
          user_id: activeTimer.created_by_id,
          rate_type: "regular_rate",
        }).unwrap();
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      document.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeTimer]);

  const isViewWorkOrder = woCalendarPermit && woListPermit;

  return (
    <TablePageLayout>
      {isViewWorkOrder && (
        <>
          <Header
            title={t("work_orders.title")}
            searchPlaceholder={t("work_orders.searchPlaceholder")}
            action={
              <>
                {enableWorkOrdersListKpis && (
                  <Button
                    variant="tertiary"
                    onClick={() => setShowKPIs(!showKPIs)}
                    icon={<GraphIcon />}
                  >
                    {t("work_orders.kpis.button")}
                  </Button>
                )}
                {woCreatePermit && (
                  <ModalLink
                    type="button"
                    icon={<AddIcon />}
                    modalParams={{ type: "wo", id: "new" }}
                  >
                    {t("work_orders.title_one")}
                  </ModalLink>
                )}
              </>
            }
            horizontallySpaciousPage
          />
          <Container>
            <WorkOrdersList
              onOpenWorkOrder={(id) => setModal({ type: "wo", id })}
              onOpenPM={(id) => setModal({ type: "pm", id })}
              withExport={woListExportPermit}
              showKPIs={showKPIs && enableWorkOrdersListKpis}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
