import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";
import { UserCreateInvite } from "modules/users/components/UserCreateInvite";
import { UserDetailsEdit } from "modules/users/components/UserDetailsEdit";
import { UserDetailsView } from "modules/users/components/UserDetailsView";

interface UserModalProps {
  params: ModalParams;
  userType: "entityUser" | "user";
}

export const UserModal = ({ params, userType }: UserModalProps) => {
  const { id, state = "view" } = params;
  const {
    usersDetailsViewPermit,
    usersDetailsEditPermit,
    usersDetailsInvitePermit,
    adminSettingsUsersPermit,
  } = usePermission();
  const type = userType;
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (userType === "user" && !adminSettingsUsersPermit) {
    return null;
  }

  if (id === "new" && usersDetailsInvitePermit) {
    return <UserCreateInvite isShow={true} onClose={onClose} type={userType} />;
  } else if (state === "view" && usersDetailsViewPermit) {
    return (
      <UserDetailsView
        isShow={true}
        userId={id}
        onClose={onClose}
        onEdit={(id: string) => setModal({ type: userType, id, state: "edit" })}
        type={userType}
      />
    );
  } else if (state === "edit" && usersDetailsEditPermit) {
    return (
      <UserDetailsEdit
        isShow={true}
        userId={id}
        onClose={onView}
        onOpen={onView}
        type={type}
      />
    );
  }

  return null;
};
