import { skipToken } from "@reduxjs/toolkit/dist/query";

import { getUser } from "shared/components/Auth";

import { useGetUserQuery } from "modules/users";

import { useTranslation } from "react-i18next";

import { useForm } from "shared/lib/hooks/useForm";
import { userDetailsValidationSchema } from "../config/validationSchema";

import { Email } from "../Email";
import { Password } from "../Password";
import { PersonalInfo } from "../PersonalInfo";
import { PhoneNumber } from "../PhoneNumber";
import { Sessions } from "../Sessions/Sessions";
import { Box, PrivacyPolicyContainer, Wrapper } from "../styled";
import { PrivacyPolicy } from "shared/components/PrivacyPolicy";

export const ProfilePreferenceView = () => {
  const { t } = useTranslation();
  const { id: userId = "" } = getUser() ?? {};

  const { currentData: userInfo, isLoading } = useGetUserQuery(
    userId ?? skipToken
  );

  const {
    email = "",
    name = "",
    surname = "",
    phone_number = "",
    images = [],
    unconfirmed_email = "",
  } = userInfo ?? {};

  const form = useForm({
    initialValues: {
      email,
      name,
      surname,
      phone_number,
      images,
      password: "",
      oldPassword: "",
    },
    validationSchema: userDetailsValidationSchema(t),
    enableReinitialize: true,
  });

  return (
    <Wrapper>
      <PersonalInfo user={userInfo} isLoading={isLoading} form={form} />
      <PhoneNumber value={phone_number} form={form} userId={userId} />
      <Email
        form={form}
        userId={userId}
        previousEmail={email}
        unconfirmedEmail={unconfirmed_email}
      />
      <Password form={form} userId={userId} />
      <Sessions userId={userId} />
      <Box>
        <PrivacyPolicyContainer>
          <PrivacyPolicy />
        </PrivacyPolicyContainer>
      </Box>
    </Wrapper>
  );
};
