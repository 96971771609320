import PropTypes from "prop-types";

export const filePropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
});

export const imagePropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
});
