import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Header } from "widgets/Header";
import { Button } from "shared/components/Button";
import { Container } from "shared/components/Container/Container";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { useAuth } from "shared/components/Auth";

import LogOutIcon from "modules/auth/assets/icons/logout.svg?react";
import { useLogoutUserMutation } from "modules/users";
import { Tabs } from "shared/components/Tabs";
import { ProfilePreferenceView } from "shared/components/Profile";
import { NotificationSettingsView } from "shared/components/Profile";
import { usePermission } from "app/providers/PermissionsProvider";

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { notificationTabForRequestersVisible } = usePermission();

  const [doLogout] = useLogoutUserMutation();

  const successLogout = () => {
    logout();
    window.location.href = "/login";
  };

  const handleLogout = async () => {
    await doLogout()
      .unwrap()
      .then(() => {
        successLogout();
      })
      .catch(({ message }) => {
        if (message === "Signature has expired") {
          successLogout();
        }
      });
  };

  return (
    <TablePageLayout>
      <Header
        className="border-none"
        title={t("profile.title")}
        action={
          <Button onClick={handleLogout}>
            <LogoutButton>
              <LogOutIcon />
              <span>{t("auth.logout")}</span>
            </LogoutButton>
          </Button>
        }
      />
      <Container>
        <ProfileTabs>
          <Tabs.TabList>
            <Tabs.Tab id="profile">
              {t("profile.tabs.profilePreference")}
            </Tabs.Tab>
            {notificationTabForRequestersVisible && (
              <Tabs.Tab id="notifications">
                {t("profile.tabs.notificationSettings")}
              </Tabs.Tab>
            )}
          </Tabs.TabList>
          <Tabs.TabPanel id="profile">
            <ProfilePreferenceView />
          </Tabs.TabPanel>
          <Tabs.TabPanel id="notifications">
            <NotificationSettingsView />
          </Tabs.TabPanel>
        </ProfileTabs>
      </Container>
    </TablePageLayout>
  );
};

const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ProfileTabs = styled(Tabs.Root)`
  width: calc(100% - 40px);
`;
