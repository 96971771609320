import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./i18n";
import { BrowserRouter } from "react-router-dom";

import ReduxProvider from "./providers/ReduxProvider";
import SnackBarProvider from "./providers/SnackBarProvider";
import GlobalStyle from "./styles/GlobalStyle";
import "app/styles/vars.css";
import Routing from "pages";

import { PermissionsProvider } from "./providers/PermissionsProvider";
import { AuthProvider } from "shared/components/Auth";
import { UserContextListener } from "app/providers/UserContextListener";
import { NoInternetProvider } from "./providers/NoInternetProvider";
import { ModalController } from "widgets/ModalController";
import { RACRouterProvider } from "app/providers/RACRouterProvider";
import { IntercomProvider } from "app/providers/IntercomProvider";
import { TotangoProvider } from "app/providers/TotangoProvider";
function App() {
  return (
    <ReduxProvider>
      <BrowserRouter>
        <RACRouterProvider>
          <SnackBarProvider>
            <NoInternetProvider>
              <DndProvider backend={HTML5Backend}>
                <GlobalStyle />
                <UserContextListener />
                <TotangoProvider />
                <IntercomProvider />
                <AuthProvider>
                  <PermissionsProvider>
                    <Routing />
                    <ModalController />
                  </PermissionsProvider>
                </AuthProvider>
              </DndProvider>
            </NoInternetProvider>
          </SnackBarProvider>
        </RACRouterProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
