import styled from "styled-components";
import { Link } from "react-router-dom";

export const TextLink = styled(Link)`
  color: var(--color_primary_text);
  text-decoration: underline;
  font-size: 14px;

  &:hover {
    text-decoration: none;
  }
`;
