import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { setUser, useAuth } from "shared/components/Auth";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useLoginMutation } from "modules/auth";
import { useForm } from "shared/lib/hooks/useForm";

import { Button } from "shared/components/Button";
import { Input } from "shared/components/Input";
import {
  Box,
  DividerStyled,
  LabelStyled,
  Title,
  ActionBox,
  LinkStyled,
  SignUpContainer,
  ForgotPasswordContainer,
  Container,
  SessionTimedOutContainer,
  HeaderSignInButton,
} from "../styled";
import { LoginInitialValues } from "modules/auth/config/constants";
import { loginValidationSchema } from "modules/auth/config/validationSchema";
import { LoginPasswordLimit, SignUpInvitation } from "../AdditionalPages";
import { Header } from "../Header";
import { useEffect } from "react";
import { Form } from "react-aria-components";

export const LoginForm = ({ isSessionClosed, isPermissionDenied }) => {
  const [isShowResendEmailScreen, setIsShowResendEmailScreen] = useState(false);

  const { t } = useTranslation();

  const { login } = useAuth();
  const { addSnackBar } = useSnackBar();
  const [doLogin, { error }] = useLoginMutation();

  useEffect(() => {
    isPermissionDenied &&
      addSnackBar({
        content: t("m170", { ns: "errors" }),
        type: "error",
      });
  }, []);

  const onSubmit = async ({ email, loginPassword }) => {
    doLogin({ email, password: loginPassword })
      .unwrap()
      .then(({ user: { id, ...rest } }) => {
        login({ id, ...rest });
        setUser({ id, ...rest });
        window.location.href = "/";
        window.totango.track("User Logged In", "Profile");
      })
      .catch(({ message }) => {
        if (message === "invited") {
          return setIsShowResendEmailScreen(true);
        } else if (
          message === "not_found_in_database" ||
          message === "invalid"
        ) {
          return addSnackBar({
            content: t("auth.login.invalidEmailOrPassword"),
            type: "error",
          });
        }
        addSnackBar({
          content: message,
          type: "error",
        });
      });
  };

  let isLocked = error?.message === "locked";

  const form = useForm({
    initialValues: LoginInitialValues,
    validationSchema: loginValidationSchema(t),
    onSubmit,
  });

  if (isLocked) {
    return <LoginPasswordLimit />;
  }

  if (isShowResendEmailScreen) {
    return (
      <SignUpInvitation
        email={form?.values?.email}
        headerAction={
          <HeaderSignInButton onClick={() => setIsShowResendEmailScreen(false)}>
            {t("auth.login.title")}
          </HeaderSignInButton>
        }
      />
    );
  }

  return (
    <>
      <Header />
      <Container>
        <Form id="loginForm">
          <Title>{t("auth.login.title")}</Title>
          {isSessionClosed && (
            <SessionTimedOutContainer>
              Your session has timed out. Please Sign In again
            </SessionTimedOutContainer>
          )}
          <LabelStyled>{t("auth.login.emailLabel")}</LabelStyled>
          <Input
            type="email"
            name="email"
            placeholder={t("auth.login.emailPlaceholder")}
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={form.touched.email && form.errors.email}
            aria-label="Email"
          />

          <LabelStyled>{t("auth.login.passwordLabel")}</LabelStyled>
          <Input
            type="password"
            name="loginPassword"
            placeholder={t("auth.login.passwordPlaceholder")}
            value={form.values.loginPassword}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={
              form.touched.loginPassword && form.errors.loginPassword
            }
            isPassword
            aria-label="Password"
          />

          <ForgotPasswordContainer>
            <LinkStyled to="/forgot">
              {t("auth.login.forgotPassword")}
            </LinkStyled>
          </ForgotPasswordContainer>
          <DividerStyled />

          <ActionBox>
            <Button
              disabled={!form.isFormValid}
              type="primary"
              size="normal"
              onClick={form.handleSubmit}
            >
              {t("auth.login.login")}
            </Button>
            <SignUpContainer>
              {t("auth.login.noAccount")}
              <LinkStyled to="/signup">{t("auth.login.signUp")}</LinkStyled>
            </SignUpContainer>
          </ActionBox>
        </Form>
      </Container>
    </>
  );
};

LoginForm.propTypes = {};
