import { useTranslation } from "react-i18next";
import { Header } from "widgets/Header";
import { Container } from "shared/components/Container";
import { List } from "modules/requestPortals";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { useNavigate } from "react-router-dom";
import { useSetModal } from "widgets/ModalController";
import { ModalLink } from "shared/components/ModalLink";

export const RequestPortalsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setModal = useSetModal();

  return (
    <TablePageLayout>
      <Header
        title={t("request_portals.title")}
        action={
          <ModalLink
            type="button"
            icon={<AddIcon />}
            modalParams={{ type: "portal", id: "new" }}
          >
            {t("request_portals.addButton")}
          </ModalLink>
        }
        horizontallySpaciousPage
        withSearch={false}
        returnHandler={() => navigate("..")}
      />
      <Container>
        <List onOpen={(id) => setModal({ id, type: "portal" })} />
      </Container>
    </TablePageLayout>
  );
};
