import { DatePickerBase, DatePickerBaseProps } from "../DatePickerBase";
import { useTranslation } from "react-i18next";
import { parseISO, format } from "date-fns";

interface SingleDatePickerProps
  extends Pick<
    DatePickerBaseProps,
    "dateFormat" | "name" | "onBlur" | "errorMessage" | "showIcon"
  > {
  selected: string | null | undefined;
  onChange: (date: string | undefined) => void;
}

/**
 * A DatePicker component that allows selecting a single date.
 * Uses strings for selected/onChange instead of Date objects to avoid timezone issues.
 */
export const SingleDatePicker = ({
  selected,
  onChange,
  ...datePickerProps
}: SingleDatePickerProps) => {
  const { t } = useTranslation();
  const { dateFormat } = datePickerProps;

  let selectedDate = selected ? parseISO(selected) : undefined;
  if (!(selectedDate instanceof Date) || isNaN(selectedDate.getTime())) {
    selectedDate = undefined;
  }

  return (
    <DatePickerBase
      selected={selectedDate}
      onChange={(date: Date | null) =>
        onChange(date ? format(date, "yyyy-MM-dd") : undefined)
      }
      todayButton={t("datePicker.today")}
      placeholderText={dateFormat}
      {...datePickerProps}
    />
  );
};
