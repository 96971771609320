import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid:
    "list calendar" 1fr
    "buttons buttons" auto / 1fr auto;
`;

export const CalendarWrapper = styled.div`
  border-left: 1px solid var(--color_border);

  .react-datepicker {
    border: none;
  }

  // These selection states don't work well when there are two calendars visible
  //    so we're overriding them for this popover
  .react-datepicker__day--keyboard-selected {
    background-color: #fff !important;

    &:hover {
      background-color: #f0f0f0 !important;
    }
  }

  .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range {
    background-color: var(--datepicker-primary) !important;
  }
`;

export const CalendarTitle = styled.h3`
  margin: 0;
  padding: 4px 0 0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border-top: 1px solid var(--color_border);

  &:first-child {
    border-top: none;
  }
`;

export const ButtonsWrapper = styled.div`
  grid-area: buttons;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 8px;
  border-top: 1px solid var(--color_border);
`;
