import { toPattern } from "./helpers";

/*
  Locator Key:
  - names are targeted using a role (typically role='button') and the specified name
  - labels are targeted using aria-label
  - ids are targeted using data-testid
*/

const button = {
  // Table Settings - Per Page
  "25": { name: "25" },
  "50": { name: "50" },
  "75": { name: "75" },
  "100": { name: "100" },

  // Priority
  none: { name: "None" },
  low: { name: "Low" },
  medium: { name: "Medium" },
  high: { name: "High" },
  critical: { name: "Critical" },

  // Actions
  apply: { name: "Apply" },
  approve: { name: "Approve" },
  cancel: { name: "Cancel" },
  decline: { name: "Decline" },
  discardChanges: { name: "Discard Changes" },
  edit: { name: "Edit" },
  onHold: { name: "On Hold" },
  save: { name: "Save" },
  submitRequest: { name: "Submit Request" },
  visitPortal: { name: "Visit Portal" },

  // Filters
  category: { name: "Category" },
  filters: { name: "Filters" },
  overdueAndDueToday: { name: "Overdue & Due Today" },
  priority: { name: "Priority" },
  status: { name: "Status" },

  // Work Types
  reactive: { name: "Reactive" },
  preventive: { name: "Preventive" },

  // Buttons targeted using aria-label
  bulkDecline: { label: "Bulk decline" },
  bulkOnHold: { label: "Bulk on hold" },
  closeModal: { label: "Close modal" },
  exportXLSFile: { label: "Export XLS file" },
  tableSettings: { label: "Table settings" },
  submitSearch: { label: "Submit search" },

  // Buttons targeted using data-testid
  addNew: { id: "add-new-button" },
  openWorkOrder: { id: "open-work-order-button" },
};

const action = {
  fill: "fill",
  selectClick: "selectClick",
  selectFill: "selectFill",
  switch: "switch",
};

const field = {
  asset: {
    db: "asset",
    inputAction: action.selectFill,
    viewModalId: "view-modal-asset",
  },
  allowAnonymous: {
    db: "allow_anonymous",
    inputAction: action.switch,
    viewModalId: null,
  },
  assignedTo: {
    db: "assign_to",
    inputAction: action.selectFill,
    viewModalId: "view-modal-assigned-to",
  },
  category: {
    db: "categories",
    inputAction: action.selectFill,
    viewModalId: "view-modal-category",
  },
  comments: {
    db: "comments",
    inputAction: null,
    viewModalId: null,
  },
  completedWhen: {
    db: "completed_at",
    inputAction: null,
    viewModalId: null,
  },
  createdBy: {
    db: "created_by_name",
    inputAction: null,
    viewModalId: null,
  },
  createdWhen: {
    db: "created_at",
    inputAction: null,
    viewModalId: null,
  },
  description: {
    db: "description",
    inputAction: action.fill,
    viewModalId: "view-modal-description",
  },
  downtime: {
    db: "downtime_tracking",
    inputAction: null,
    viewModalId: null,
  },
  dueDate: {
    db: "due_date",
    inputAction: action.fill,
    viewModalId: "view-modal-due-date",
  },
  email: {
    db: "email",
    inputAction: action.fill,
    viewModalId: null,
  },
  entity: {
    db: "entity",
    inputAction: action.selectClick,
    viewModalId: null,
  },
  firstName: {
    db: "name",
    inputAction: action.fill,
    viewModalId: null,
  },
  lastName: {
    db: "surname",
    inputAction: action.fill,
    viewModalId: null,
  },
  link: {
    db: "link",
    inputAction: null,
    viewModalId: "view-modal-link",
  },
  id: {
    db: "id",
    inputAction: null,
    viewModalId: null,
  },
  instructions: {
    db: "instructions",
    inputAction: action.fill,
    viewModalId: null,
  },
  location: {
    db: "location",
    inputAction: action.selectFill,
    viewModalId: "view-modal-location",
  },
  name: {
    db: "name",
    inputAction: null,
    viewModalId: "view-modal-name",
  },
  neededBy: {
    db: "due_date",
    inputAction: action.fill,
    viewModalId: "view-modal-needed-by",
  },
  notifications: {
    db: null,
    inputAction: action.switch,
    viewModalId: "view-modal-notifications",
  },
  phone: {
    db: "phone_number",
    inputAction: action.fill,
    viewModalId: null,
  },
  priorityRequests: {
    db: "priority",
    inputAction: null,
    viewModalId: "view-modal-priority",
  },
  priorityWorkOrders: {
    db: "priority",
    inputAction: null,
    viewModalId: null,
  },
  request: {
    db: "request",
    inputAction: null,
    viewModalId: null,
  },
  requestSource: {
    db: "request_portal_id",
    inputAction: null,
    viewModalId: null,
  },
  statusRequests: {
    db: "status",
    inputAction: null,
    viewModalId: "view-modal-status",
  },
  statusWorkOrders: {
    db: "status",
    inputAction: action.selectFill,
    viewModalId: null,
  },
  schedule: {
    db: "schedule",
    inputAction: null,
    viewModalId: null,
  },
  title: {
    db: "title",
    inputAction: action.fill,
    viewModalId: "view-modal-title",
  },
  updatedBy: {
    db: "updated_by_name",
    inputAction: null,
    viewModalId: null,
  },
  updatedWhen: {
    db: "updated_at",
    inputAction: null,
    viewModalId: null,
  },
  vendors: {
    db: "vendors",
    inputAction: action.selectFill,
    viewModalId: "view-modal-vendor",
  },
  welcomeText: {
    db: "welcome_text",
    inputAction: action.fill,
    viewModalId: null,
  },
  workOrder: {
    db: "work_order_id",
    inputAction: null,
    viewModalId: null,
  },
  workType: {
    db: "type",
    inputAction: null,
    viewModalId: "view-modal-work-type",
  },
};

const heading = {
  editorModal: { id: "editor-heading" },
  module: { id: "module-heading" },
  manageModule: { id: "manage-module-heading" },
  requestPortal: {
    id: "request-portal-heading",
    text: "Request Portal",
  },
  requestPortalSuccess: {
    id: "request-portal-success-heading",
    text: "Request Sent Successfully",
  },
  viewModal: { id: "view-modal-heading" },
  viewModalHint: { id: "view-modal-hint-heading" },
  viewModalDetails: { id: "view-modal-details-heading" },
};

const manageModule = {
  locations: {
    navId: "manage-locations",
    headingText: "Locations",
  },
  assets: {
    navId: "manage-assets",
    headingText: "Assets",
  },
  vendors: {
    navId: "manage-vendors",
    headingText: "Vendors",
  },
  inventoryAndParts: {
    navId: "manage-inventory-&-parts",
    headingText: "Inventory & Parts",
  },
  workOrders: {
    navId: "manage-work-orders",
    headingText: "Work Orders",
  },
  workOrderRequests: {
    navId: "manage-work-order-requests",
    headingText: "Work Order Requests",
  },
  assetTypes: {
    navId: "manage-asset-types",
    headingText: "Asset Types",
  },
  categories: {
    navId: "manage-categories",
    headingText: "Categories",
  },
  storeroom: {
    navId: "manage-storerooms",
    headingText: "Storerooms",
  },
  partTypes: {
    navId: "manage-part-types",
    headingText: "Part Types",
  },
  // See module.requestPortals for more info
  requestPortals: {
    navId: "manage-request-portals",
  },
};

const module = {
  requests: {
    navId: "requests-nav",
    headingText: "Requests",
    addNewLinkName: "Request",
    addNewModalHeadingText: "New Request",
    firstColumn: field.title.db,
  },
  // See manageModule.requestPortal for more info
  requestPortals: {
    url: "/admin-settings/manage-modules/request-portals?sort=created_at%2Bdesc",
    headingText: "Request Portals",
    addNewLinkName: "Request Portal",
    addNewModalHeadingText: "New Request Portal",
    firstColumn: field.title.db,
  },
  workOrders: {
    navId: "work-orders-nav",
    headingText: "Work Orders",
    addNewLinkName: "Work Order",
    addNewModalHeadingText: "New Work Order",
    viewModalHeadingText: "Work Order",
    firstColumn: field.title.db,
  },
  pms: {
    navId: "pms-nav",
    headingText: "Preventive Maintenance",
    addNewLinkName: "Work Order",
    addNewModalHeadingText: "New Work Order",
    firstColumn: field.title.db,
  },
  assets: {
    navId: "assets-nav",
    headingText: "Assets",
    addNewLinkName: "Asset",
    addNewModalHeadingText: "New Asset",
    firstColumn: field.name.db,
  },
  locations: {
    navId: "locations-nav",
    headingText: "Locations",
    addNewLinkName: "Location",
    addNewModalHeadingText: "New Location",
    firstColumn: field.name.db,
  },
  inventoryAndParts: {
    navId: "inventory-&-parts-nav",
    headingText: "Inventory & Parts",
    addNewLinkName: "Part",
    addNewModalHeadingText: "New Part",
    firstColumn: field.name.db,
  },
  vendors: {
    navId: "vendors-nav",
    headingText: "Vendors",
    addNewLinkName: "Vendor",
    addNewModalHeadingText: "New Vendor",
    firstColumn: field.name.db,
  },
  users: {
    navId: "users-nav",
    headingText: "Users",
    addNewLinkName: "User",
    addNewModalHeadingText: "Invite User",
    firstColumn: field.title.db,
  },
  reportsAndKPIs: {
    navId: "reports-&-kpis-nav",
    headingText: "Reports & KPIs",
  },
  adminSettings: {
    navId: "admin-settings-nav",
    headingText: "Administration",
  },
};

const pattern = {
  // Uses field.db values; full ids are accessed through field.db and applicable pattern
  columnHeaderId: (db: string) => `${db}-column-header`,
  sortButtonId: (db: string) => `${db}-sort`,
  draggableFieldId: (db: string) => `${db}-draggable`,
  requiredSwitchId: (db: string) => `${db}-required`,
  visibilitySwitchId: (db: string) => `${db}-visibility`,
  inputId: (db: string) => `${db}-view-modal`,

  // Uses custom values; full ids are listed in applicable objects in shared-test-data
  moduleNavId: (text: string) => `${toPattern(text)}-nav`,
  manageModuleNavId: (title: string) => `manage-${toPattern(title)}`,
  viewModalId: (label: string) => `view-modal-${toPattern(label)}`,
};

const tab = {
  generalSettings: { name: "General Settings" },
  manageModule: { name: "Manage Modules" },
  allUsers: { name: "All Users" },
};

export { action, button, field, heading, manageModule, module, pattern, tab };
