import * as React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { times } from "lodash";
import { Cell, Row, Th } from "./TableSkeleton.styled";

const SKELETON_ROWS_COUNT = 8;

export const TableSkeleton = ({ columns }) => (
  <>
    <Row>
      {columns.map((_, i) => (
        <Th key={i} index={i}>
          <Skeleton width="80%" height={16} borderRadius={4} />
        </Th>
      ))}
    </Row>
    {times(SKELETON_ROWS_COUNT, (i) => (
      <Row key={i}>
        {columns.map((_, i) => (
          <Cell key={i}>
            <Skeleton width="80%" height={8} borderRadius={2} />
          </Cell>
        ))}
      </Row>
    ))}
  </>
);
