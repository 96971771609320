import { useLazyGetEntitiesQuery } from "modules/entities";
import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LightModal } from "shared/components/Modals/LightModal";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { validationSchema } from "../config/validationSchema";
import { EditorFields } from "../EditorFields";
import { getEntitiesOptions, getInitialValues, getValues } from "../helpers";

export const AddReferenceModal = ({ isShow, module, onClose, addElement }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [trigger, { data: entities }] = useLazyGetEntitiesQuery();
  const [add] = addElement();

  useEffect(() => {
    if (isShow) {
      trigger();
    }
  }, [isShow, trigger]);

  const entityOptions = useMemo(() => {
    return getEntitiesOptions(entities?.data, module);
  }, [entities]);

  const initialValues = useMemo(
    () =>
      getInitialValues({
        type: "create",
        module,
        t,
      }),
    [t, module]
  );

  const onCloseModal = () => {
    form.resetForm();
    onClose();
  };

  const onSubmit = async (body, { setFieldError }) => {
    const element = getValues(body);

    add(element)
      .unwrap()
      .then(({ name }) => {
        addSnackBar({
          content: t(
            `settings.referenceLists.${module}.snack.successCreatedMessage`,
            { name }
          ),
          type: "success",
        });
        onCloseModal();
      })
      .catch(({ message }) => {
        if (message.name?.[0] === "has already been taken") {
          setFieldError(
            "name",
            t(`settings.referenceLists.${module}.modal.alreadyExist`)
          );
        } else {
          addSnackBar({
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        }
      });
  };

  const { setFieldError, ...form } = useForm({
    initialValues,
    onSubmit,
    validationSchema: validationSchema(t),
  });

  return (
    <LightModal
      isOpen={isShow}
      onClose={onCloseModal}
      onCancel={onCloseModal}
      label={t(`settings.referenceLists.${module}.modalLabelAdd`)}
      onSubmit={form.handleSubmit}
      disabledSubmit={!form.isWasChanged || !form.isFormValid}
    >
      <EditorFields form={form} module={module} options={entityOptions} />
    </LightModal>
  );
};
