export const sortContacts = (data) => {
  const sortedData = data.slice().sort((a, b) => {
    if (a.is_primary && !b.is_primary) {
      return -1;
    } else if (!a.is_primary && b.is_primary) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  return sortedData;
};
