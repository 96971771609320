import { isPast, parseISO } from "date-fns";

/** Checks if a request is overdue */
export const isRequestOverdue = (
  due_date: string | undefined,
  status: string
) => {
  if (!due_date || ["approved", "declined", "completed"].includes(status))
    return false;
  return isPast(parseISO(due_date));
};
