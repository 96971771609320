import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useLazyDownloadFileQuery } from "modules/fileManager";

import { getFullUrl } from "shared/lib/helpers";
import { DialogImageView } from "shared/components/Image/DialogImageView";

export const ImageView = ({ files = [], showFileIndex, setFileIndex }) => {
  const { t } = useTranslation();
  const showModal = useMemo(() => showFileIndex !== null, [showFileIndex]);

  const currentFile = files[showFileIndex];

  const [trigger] = useLazyDownloadFileQuery();

  const handleModalClose = () => {
    setFileIndex(null);
  };

  const handleNextFile = () => {
    if (showFileIndex === files.length - 1) {
      return setFileIndex(0);
    } else {
      return setFileIndex(showFileIndex + 1);
    }
  };

  const handlePrevFile = () => {
    if (showFileIndex === 0) {
      return setFileIndex(files.length - 1);
    } else {
      return setFileIndex(showFileIndex - 1);
    }
  };

  const handleDownloadFile = () => {
    trigger({
      url: getFullUrl(currentFile.download_url),
      filename: currentFile.name,
    });
  };

  return (
    <DialogImageView
      showDialog={Boolean(showModal && currentFile)}
      scr={getFullUrl(currentFile?.attachment_urls.large)}
      fileName={currentFile?.name}
      secondaryInformation={t("files.image.imageCount", {
        count: showFileIndex + 1,
        total: files.length,
      })}
      onClose={handleModalClose}
      closeButtonLabel={t("close")}
      onDownload={handleDownloadFile}
      downloadButtonLabel={t("download")}
      onNext={handleNextFile}
      onPrev={handlePrevFile}
    />
  );
};
