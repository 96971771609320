import { getYear, isAfter, isBefore, startOfMonth } from "date-fns";

interface Interval {
  start?: Date | null | undefined;
  end?: Date | null | undefined;
}

export function clampYear(year: number, interval: Interval) {
  const { start, end } = interval;
  if (start && end) {
    return Math.min(Math.max(year, getYear(start)), getYear(end));
  } else if (start) {
    return Math.max(year, getYear(start));
  } else if (end) {
    return Math.min(year, getYear(end));
  }

  return year;
}

/** Check if a month is between start/end dates */
export function isMonthAllowed(date: Date, interval: Interval) {
  const { start, end } = interval;
  return (
    !(end && isAfter(date, end)) &&
    !(start && isBefore(date, startOfMonth(start)))
  );
}
