import * as Yup from "yup";
import {
  emailRegexp,
  validRateRegExe,
  validateNonNegativeZero,
} from "shared/lib/constants/forms";
import {
  EditUserValidationFields,
  InviteUserValidationFields,
  userDefaultValidationFields,
} from "./constant";
import { getNameSchema } from "shared/lib/helpers/validation";

const stringMaxLength = 255;
const phoneNumberMaxString = 50;

const getRateValidation = (t) => {
  return Yup.number()
    .notRequired()
    .test("is non negative", t("validation.positiveOrZero"), (value) =>
      value ? validateNonNegativeZero.test(String(value)) : true
    )
    .test(
      "is valid",
      t("validation.fractionalNumberMaxLength", {
        integerLength: 20,
        decimalLength: 2,
      }),
      (value) => (value ? validRateRegExe.test(String(value)) : true)
    );
};

export const getDefaultValidationSchema = (field, t, noAccessRoleId) => {
  const schema = {
    name: getNameSchema(t, { required: true }),
    surname: getNameSchema(t),
    job_title: Yup.string()
      .notRequired()
      .max(
        stringMaxLength,
        t(`validation.stringMaxLength`, { stringMaxLength })
      ),
    phone_number: Yup.string()
      .notRequired()
      .max(
        phoneNumberMaxString,
        t(`validation.stringMaxLength`, {
          stringMaxLength: phoneNumberMaxString,
        })
      ),
    email: Yup.string()
      .required(t("validation.required"))
      .max(
        stringMaxLength,
        t(`validation.stringMaxLength`, { stringMaxLength })
      )
      .matches(emailRegexp, t("allUsers.validation.email")),
    permissions: Yup.object().test(
      "Has Access",
      t("allUsers.validation.permissions"),
      (value) =>
        Object.values(value ?? {}).some((p) => p.value !== noAccessRoleId)
    ),
    regular_rate: getRateValidation(t),
    overtime_rate1: getRateValidation(t),
    overtime_rate2: getRateValidation(t),
  };

  return schema[field];
};

export const userDetailsValidationSchema = (t, noAccessRoleId) => {
  const userDetailsSchema = EditUserValidationFields.reduce((acc, item) => {
    acc[item] = getDefaultValidationSchema(item, t, noAccessRoleId);

    return acc;
  }, {});

  return Yup.object(userDetailsSchema);
};

export const inviteUserValidationSchema = (t, noAccessRoleId) => {
  const userDetailsSchema = InviteUserValidationFields.reduce((acc, item) => {
    acc[item] = getDefaultValidationSchema(item, t, noAccessRoleId);

    return acc;
  }, {});

  return Yup.object(userDetailsSchema);
};

export const inviteUserEditValidationSchema = (t, noAccessRoleId) => {
  const userDetailsSchema = userDefaultValidationFields.reduce((acc, item) => {
    acc[item] = getDefaultValidationSchema(item, t, noAccessRoleId);

    return acc;
  }, {});

  return Yup.object(userDetailsSchema);
};
