import { useTranslation } from "react-i18next";
import { getUser } from "shared/components/Auth";

export interface LocaleSettings {
  language: string;
  currency: string;
  timezone: string;
  dateFormat: string;
}

const DEFAULT_CURRENCY = "USD";
const DEFAULT_TIMEZONE = "UTC";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

/** Get localization information for the current user/entity */
export const useLocaleSettings = (): LocaleSettings => {
  const { i18n } = useTranslation();
  const user = getUser();

  return {
    language: i18n.language,
    currency: user?.currency ?? DEFAULT_CURRENCY,
    timezone: user?.timezone ?? DEFAULT_TIMEZONE,
    dateFormat: user?.dateFormat ?? DEFAULT_DATE_FORMAT,
  };
};
