import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { CreatedVsCompletedChartData } from "./types";
import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { BarChart } from "modules/reporting/components/charts/BarChart";

import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { bucketData } from "modules/reporting/lib/bucketData";

interface CreatedVsCompletedReportChartProps {
  bucket: ReportBucket;
  data?: CreatedVsCompletedChartData[];
  isFetching: boolean;
}

const reportKey = ReportKey.CreatedVsCompleted;
export const CreatedVsCompletedReportChart = ({
  bucket,
  data,
  isFetching,
}: CreatedVsCompletedReportChartProps) => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  const chartData = bucketData(data ?? [], bucket, language, (points) =>
    points.reduce(
      (bucket, p) => {
        bucket.created += p.created;
        bucket.completed += p.completed;
        return bucket;
      },
      { created: 0, completed: 0 }
    )
  );
  return (
    <BarChart
      isLoading={isFetching}
      data={chartData}
      dataAxisKey="date"
      bars={[
        {
          dataKey: "created",
          name: t(`reporting.${reportKey}.labels.created`) as string,
          fill: "var(--color_blue)",
        },
        {
          dataKey: "completed",
          name: t(`reporting.${reportKey}.labels.completed`) as string,
          fill: "var(--color_chart_lime)",
        },
      ]}
    />
  );
};
