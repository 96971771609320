import styled from "styled-components";

export const Box = styled.div`
  background-color: var(--color_work_order_entry_background);
  border-radius: 4px;
  padding: 16px 12px;
  display: flex;
  gap: 10px;
`;

export const Icon = styled.div`
  color: var(--color_primary_text);
  flex-shrink: 0;
  flex-grow: 0;
`;

export const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: -3px;
`;

export const Name = styled.span`
  color: var(--color_primary_text);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color_grey500);
`;
