export const transformWOtoEvents = (
  events = { data: [] },
  displayDate = () => {}
) => {
  const transformedEvents = events.data.map(
    ({
      id,
      title,
      due_date,
      priority,
      status,
      // is_overdue,
      teams,
      technicians,
    }) => ({
      event: {
        id,
        title,
        dueDate: due_date,
        extendedProps: {
          woId: id,
          priority,
          status,
          isPlanned: false,
          dueDate: due_date,
          assigned: teams.concat(technicians),
        },
      },
    })
  );

  return { data: transformedEvents, meta: events.meta };
};
