import React from "react";
import PropTypes from "prop-types";
import { Modal } from "./Modal";
import { ModalHeader } from "./components/ModalHeader";
import {
  ArrowContainer,
  BodyWrapper,
  ModalContentWrapper,
} from "./components/Modals.styled";
import { CloseButton } from "./components/CloseModal";
import { ArrowButton } from "./components/FlipModal";
import { ScrollFadeBox } from "../ScrollFadeBox";
import { useKeyboardShortcut } from "shared/lib/hooks/useKeyboardShortcut";

export const ViewModal = (props) => {
  const { isOpen, onClose, label, subHeader, children, hint, flipModal } =
    props;

  useKeyboardShortcut({
    key: "Escape",
    onKeyPressed: onClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContentWrapper>
        <ModalHeader
          label={label}
          action={<CloseButton onClick={onClose} />}
          flipAction={
            flipModal ? (
              <ArrowContainer>
                <ArrowButton onClick={() => {}} />
                <ArrowButton rotate={180} onClick={() => {}} />
              </ArrowContainer>
            ) : null
          }
          subHeader={subHeader}
        />
        {hint && hint}
        <ScrollFadeBox disableTop>
          <BodyWrapper>{children}</BodyWrapper>
        </ScrollFadeBox>
      </ModalContentWrapper>
    </Modal>
  );
};

ViewModal.defaultProps = {
  isOpen: false,
  onClose: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  label: "",
  subHeader: "",
  hint: "",
  flipModal: false,
};

ViewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  label: PropTypes.string,
  subHeader: PropTypes.element,
  hint: PropTypes.element,
  flipModal: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};
