import React from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "shared/components/Empty";
import RequestPortalIcon from "../../assets/icons/empty.svg?react";

export const ErrorRequestPortalsList = () => {
  const { t } = useTranslation();

  return (
    <Empty
      icon={<RequestPortalIcon />}
      heading={t("request_portals.error")}
      spacious
    />
  );
};
