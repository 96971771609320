import { useTranslation } from "react-i18next";
import { Header } from "../Header";
import { Box, Container, Title } from "../styled";

export const LoginPasswordLimit = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Container>
        <Box id="loginLimit">
          <Title>{t("auth.login.loginLimit")}</Title>
          <p>{t("auth.login.loginLimitDescription")}</p>
        </Box>
      </Container>
    </>
  );
};
