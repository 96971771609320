import { ModalHeader } from "./components/ModalHeader";
import { Modal } from "./Modal";
import { CloseButton } from "./components/CloseModal";
import { ActionBlock } from "./components/Modals.styled";
import { Button } from "shared/components/Button";
import styled from "styled-components";
import { ScrollFadeBox } from "../ScrollFadeBox";
import { useTranslation } from "react-i18next";
import { useKeyboardShortcut } from "shared/lib/hooks/useKeyboardShortcut";

export const LightModal = ({
  isOpen,
  onClose,
  label,
  children,
  onCancel,
  onSubmit,
  disabledSubmit,
  secondaryButtonLabel,
  primaryButtonLabel,
  subButton,
  className,
}) => {
  const { t } = useTranslation();

  useKeyboardShortcut({
    key: "Escape",
    onKeyPressed: onClose,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant="light"
      className={className}
    >
      <ModalHeader
        variant="light"
        label={label}
        action={<CloseButton background="light" onClick={onClose} />}
      />
      <ScrollFadeBox disableTop>{children}</ScrollFadeBox>
      <Footer>
        <ActionBlock>{subButton}</ActionBlock>
        <ActionBlock>
          {onCancel && (
            <Button onClick={onClose} variant="secondary">
              {secondaryButtonLabel ?? t("cancel")}
            </Button>
          )}
          {onSubmit && (
            <Button
              onClick={onSubmit}
              disabled={disabledSubmit}
              variant="primaryDark"
            >
              {primaryButtonLabel ?? t("save")}
            </Button>
          )}
        </ActionBlock>
      </Footer>
    </Modal>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 16px;
`;
