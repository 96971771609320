import { useTranslation } from "react-i18next";
import { Header } from "widgets/Header";
import { Container } from "shared/components/Container";
import { List } from "modules/requests";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { usePermission } from "app/providers/PermissionsProvider";
import { ModalLink } from "shared/components/ModalLink";
import { useSetModal } from "widgets/ModalController";

export const RequestsPage = () => {
  const { t } = useTranslation();

  const { requestCreatePermit, requestsListPermit, requestsListExportPermit } =
    usePermission();

  const setModal = useSetModal();

  return (
    <TablePageLayout>
      {requestsListPermit && (
        <>
          <Header
            title={t("work_order_requests.pageTitle")}
            searchPlaceholder={t("work_order_requests.searchPlaceholder")}
            action={
              requestCreatePermit && (
                <ModalLink
                  type="button"
                  icon={<AddIcon />}
                  modalParams={{ type: "request", id: "new" }}
                >
                  {t("work_order_requests.addButton")}
                </ModalLink>
              )
            }
            horizontallySpaciousPage
          />
          <Container>
            <List
              onOpen={(id) =>
                id ? setModal({ id, type: "request" }) : setModal()
              }
              onApprove={(id) =>
                id
                  ? setModal({ id, type: "request", state: "approve" })
                  : setModal()
              }
              withExport={requestsListExportPermit}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
