import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  useDeleteCommentMutation,
  useUpdateCommentMutation,
  useAddCommentMutation,
} from "modules/comments/state/commentsApi";
import { CommentView } from "../CommentView";
import { CommentCreate } from "../CommentCreate";

export const CommentsList = ({
  value = [],
  type,
  targetId,
  permissions = {},
}) => {
  const [deleteComment] = useDeleteCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [addComment] = useAddCommentMutation();

  const {
    create: createCommentPermit,
    update: updateCommentPermit,
    delete: deleteCommentPermit,
  } = permissions;

  return (
    <>
      {createCommentPermit && (
        <CommentCreate onCreate={addComment} type={type} targetId={targetId} />
      )}
      <Wrapper>
        {value.map((val, index) => {
          return (
            <CommentView
              key={`${val}-${index}`}
              value={val}
              onDelete={deleteComment}
              onEdit={updateComment}
              type={type}
              updateCommentPermit={updateCommentPermit}
              deleteCommentPermit={deleteCommentPermit}
            />
          );
        })}
      </Wrapper>
    </>
  );
};

CommentsList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      created_at: PropTypes.string,
      id: PropTypes.number,
      target_id: PropTypes.number,
      target_type: PropTypes.string,
    })
  ),
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
