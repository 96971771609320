import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Hint } from "shared/components/Typography";
import { formatCurrency } from "modules/reporting/lib/formatters";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { Link } from "shared/components/Link";
import NoteIcon from "shared/assets/icon/note.svg?react";
import { partsListSettings as settings } from "modules/parts/configs/constant";
import {
  NoteButton,
  Quantity,
  Transaction,
} from "modules/parts/components/PartTransactions/styled";
import { ModalLink } from "shared/components/ModalLink";

export const usePartTransactionsTableTools = ({
  displayDate,
  partTransactions,
  openPartTransactionHandler,
}) => {
  const { language, currency } = useLocaleSettings();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const map = {
      work_order_id: ({ work_order_id, linkName }) => (
        <ModalLink modalParams={{ type: "wo", id: work_order_id }}>
          {`${linkName}${work_order_id}`}
        </ModalLink>
      ),
      restock: ({ id, linkName }) => (
        <Link
          as="button"
          onClick={() => {
            openPartTransactionHandler(id);
          }}
        >{`${linkName}${id}`}</Link>
      ),
    };

    return [
      {
        field: "type",
        name: t(`parts.columns.transactions`),
        sortable: true,
        renderCell: ({ type, created_at }) => {
          const { name = "", color = "" } = settings[type] || {};
          return (
            <div>
              <Transaction color={color}>
                {t(`parts.transactions.${name}`)}
              </Transaction>
              <Hint>{displayDate(created_at, true, true)}</Hint>
            </div>
          );
        },
        width: 170,
        notPrimary: true,
      },
      {
        field: "quantity",
        name: t(`parts.columns.qty`),
        renderCell: ({ quantity, type }) => {
          const { color = "", sign = "" } = settings[type] || {};

          return (
            quantity && (
              <Quantity color={color}>{`${sign}${Math.round(
                quantity
              )}`}</Quantity>
            )
          );
        },
        width: 70,
      },
      {
        field: "cost",
        name: t(`parts.columns.unitCost`),
        renderCell: ({ type, cost }) => {
          const { isShowCost = "" } = settings[type] || {};
          return isShowCost ? (
            <span>{cost ? formatCurrency(cost, language, currency) : ""}</span>
          ) : (
            <span></span>
          );
        },
        width: 100,
      },
      {
        field: "details",
        name: t(`parts.columns.details`),
        renderCell: ({ id, work_order_id, type }) => {
          const { linkName = "", linkValue = "" } = settings[type] || {};
          return linkValue ? (
            map[linkValue]({ id, work_order_id, linkName })
          ) : (
            <span></span>
          );
        },
        width: 100,
      },
      {
        name: "Notes",
        field: "notes",
        renderCell: ({ type, note, id }) => {
          const { showNote } = settings[type] || {};
          return (
            <>
              {showNote && note && (
                <NoteButton
                  onClick={() => {
                    openPartTransactionHandler(id);
                  }}
                >
                  <NoteIcon />
                </NoteButton>
              )}
            </>
          );
        },
        width: 100,
      },
    ];
  }, [t, partTransactions]);

  return {
    columns,
  };
};
