import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export const Selector = ({ options, selected, onSelect }) => {
  return (
    <Box>
      {options.map(({ key, value }) => (
        <Entry
          key={key}
          selected={key === selected}
          onClick={() => {
            if (onSelect) {
              onSelect(key);
            }
          }}
        >
          {value}
        </Entry>
      ))}
    </Box>
  );
};

Selector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
};

const Box = styled.div`
  display: flex;
`;

const Entry = styled.button`
  flex-grow: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: var(--color_grey200);
  border-style: solid;
  border-right-style: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color_grey900);

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-right-style: solid;
    border-radius: 0 4px 4px 0;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--color_corp_mint_20);
      border-color: var(--color_primary_dark);
      border-width: 2px;
      border-style: solid;

      & ~ button {
        border-left-style: none;
      }
    `}
`;
