import React from "react";
import PropTypes from "prop-types";
import { Box, Container, Description, Icon, Name } from "./Entry.styled";
import WrenchIcon from "shared/assets/icon/wrench.svg?react";

export const Entry = ({ name, requestedBy, code }) => {
  return (
    <Box>
      <Icon>
        <WrenchIcon />
      </Icon>
      <Container>
        <Name>{name}</Name>
        <Description>
          <span>Requested by {requestedBy}</span>
          <span>#{code}</span>
        </Description>
      </Container>
    </Box>
  );
};

Entry.propTypes = {
  name: PropTypes.string.isRequired,
  requestedBy: PropTypes.string.isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
