import { getBaseApiUrl } from "shared/lib/apiTools/getBaseApiUrl";
import { formatDateValue, downloadFile } from "shared/lib/helpers";
import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const vendorsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query({
      query: (params) => ({
        url: "/vendors",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Vendor", id: "VendorList" },
        ...getTagList("Vendor", result?.data),
      ],
    }),
    addVendor: builder.mutation({
      query: (body) => ({
        url: "vendors",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        { type: "Vendor", id: "VendorList" },
        { type: "Vendor", id: "VendorListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    updateVendor: builder.mutation({
      query: ({ id, body }) => ({
        url: `vendors/${id}`,
        method: "PATCH",
        body,
        formData: true,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Vendor", id },
        { type: "VendorDetails", id },
        { type: "Vendor", id: "VendorListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getVendor: builder.query({
      query: (id) => `/vendors/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "VendorDetails", id: query },
      ],
    }),
    deleteVendor: builder.mutation({
      query: (id) => ({
        url: `/vendors/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Vendor", id: "VendorList" },
        { type: "Vendor", id: "VendorListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteVendors: builder.mutation({
      query: (ids) => ({
        url: "/bulk",
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/vendors/${id}`, false),
            method: "DELETE",
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, ids) => [
        { type: "Vendor", id: "VendorList" },
        { type: "Vendor", id: "VendorListFull" },
        ...ids.map((id) => ({ type: "Vendor", id })),
      ],
    }),
    exportVendors: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `vendors/download_report`,
          method: "POST",
          params,
          responseHandler: (response) => response.blob(),
        });
        const filename = formatDateValue(new Date()) + "-vendors.xls";
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
    updateVendorStatus: builder.mutation({
      query: (id) => ({
        url: `/vendors/${id}/toggle_active`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Vendor", id },
        { type: "VendorDetails", id },
      ],
    }),
    getVendorContacts: builder.query({
      query: (params) => ({
        url: "/vendor_contacts",
        params,
      }),
      providesTags: (result) => [
        { type: "Contact", id: "ContactsList" },
        ...getTagList("Contact", result?.data),
      ],
    }),
    getAllVendors: builder.query({
      query: (params) => ({
        url: "/vendors",
        params: { format_resource: "light", per_page: 10000, ...params },
      }),
      transformResponse: (response) => response.data,
      providesTags: () => [
        { type: "Entity" },
        { type: "Vendor", id: "VendorListFull" },
      ],
    }),
  }),
});

export const {
  useGetVendorsQuery,
  useDeleteVendorMutation,
  useDeleteVendorsMutation,
  useAddVendorMutation,
  useUpdateVendorMutation,
  useGetVendorQuery,
  useExportVendorsMutation,
  useUpdateVendorStatusMutation,
  useGetVendorContactsQuery,
  useGetAllVendorsQuery,
} = vendorsApi;
