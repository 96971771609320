import React from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import { usePermission } from "app/providers/PermissionsProvider";

const RequestAdminConfigurationFields = [
  "description",
  "priority",
  "location",
  "asset",
  "categories",
  "due_date",
  "assign_to",
  "vendors",
];
export const RequestsAdminSettings = () => {
  const { adminSettingsWorkOrderRequestPermit } = usePermission();
  const { data } = useGetAdminConfigurationQuery({
    type: "work_order_request",
  });

  if (!data || !data?.data) return null;

  const {
    data: { payload },
  } = data;

  const currentConfiguration = getConfigurationFields(
    RequestAdminConfigurationFields,
    payload
  );

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="work_order_request"
      hasPermission={adminSettingsWorkOrderRequestPermit}
    />
  );
};
