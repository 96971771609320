import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  /* Prevents HeaderRow z-index from overlapping elements outside of the table */
  isolation: isolate;
`;

export const ScrollBox = styled.div`
  display: flex;
  font-size: 14px;
  position: relative;
  overflow: scroll;
  text-align: left;
  background-color: var(--color_table_background);
  flex-grow: 1;
`;

export const BodyContainer = styled.div`
  display: grid;

  /* This keeps the sticky HeaderRow on the screen when the scroll position exceeds the height of the container */
  height: fit-content;
  flex-shrink: 1;
  flex-grow: 1;

  font-family: "Open Sans", sans-serif;
  font-variant-numeric: tabular-nums;
`;
