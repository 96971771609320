export const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const passwordRegexp =
  /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])/g;

export const validRateRegExe = /^[0-9]{0,20}[.]{0,1}[0-9]{0,2}$/;
export const validateNonNegativeZero = /^(0|\d+(\.\d+)?)$/;

export const FullDateFormats = {
  "MM/DD/YYYY - 11:59 PM": "MM/dd/yyyy - h:mm a",
  "DD/MM/YYYY - 23:59": "dd/MM/yyyy - HH:mm",
  "YYYY-MM-DD - 11:59 PM": "yyyy-MM-dd - h:mm a",
};

export const DatePickerFormats = {
  "MM/DD/YYYY - 11:59 PM": "MM/dd/yyyy hh:mm aa",
  "DD/MM/YYYY - 23:59": "dd/MM/yyyy HH:mm",
  "YYYY-MM-DD - 11:59 PM": "yyyy-MM-dd hh:mm aa",
};

export const DateFormats = {
  "MM/DD/YYYY - 11:59 PM": "MM/dd/yyyy",
  "DD/MM/YYYY - 23:59": "dd/MM/yyyy",
  "YYYY-MM-DD - 11:59 PM": "yyyy-MM-dd",
};
