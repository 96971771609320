import { useRef } from "react";
import { isEqual, cloneDeep } from "lodash";

/**
 * Determines if a loading state should be displayed.
 *
 * This allows us more control over loading state visibility. With RTK Query, `isLoading` is only true on the initial
 * load, and `isFetching` is true any time the query is fetching.
 * If we only use `isLoading`, there are no loading states on page changes, which makes them feel unresponsive.
 * If we use `isFetching` instead, any change that invalidates the cache for a list causes a loading state. This is
 * better than using `isLoading`, but results in too many loading spinners.
 *
 * This hook allows us to show loading states when the page/search/filter/sort changes, but not every time the list changes.
 *
 * @param isFetching - Is the related query currently fetching?
 * @param params - Parameters to watch for changes.
 *  Only show a loading state if `isFetching` is true and `params` has changed.
 */
export function useIsLoading(isFetching: boolean, params: unknown) {
  const prevArgs = useRef<unknown>();
  const paramsEqual = isEqual(params, prevArgs.current);

  if (paramsEqual) {
    // If params hasn't changed, don't show a loading state
    return false;
  } else if (isFetching) {
    // If params has changed and we're fetching, show a loading state
    return true;
  }

  // If we make it here it means something changed, but we're no longer fetching
  // Save the new state and hide the loading state
  prevArgs.current = cloneDeep(params);
  return false;
}
