import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { skipToken } from "@reduxjs/toolkit/query";

import { FileManager } from "modules/fileManager";
import {
  useGetLocationQuery,
  useDeleteLocationMutation,
  useDeleteLocationsMutation,
  useGetLocationHierarchicalListQuery,
  LocationCreate,
  useGetAllLocationsQuery,
} from "modules/locations";
import { WorkOrderCreate } from "modules/workOrders";

import { LocationView as LocationViewModule } from "./View";

import { Deletion } from "features/Deletion";

import { HeaderContextMenu, ViewModal } from "shared/components/Modals";
import ThinAddIcon from "shared/assets/icon/thin-add.svg?react";
import { Button } from "shared/components/Button";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";
import { Divider } from "shared/components/Divider";
import { HierarchicalList } from "shared/components/HierarchicalList";
import { useSearch } from "shared/lib/hooks";
import { usePermission } from "app/providers/PermissionsProvider";
import { getModalPath, useSetModal } from "widgets/ModalController";

export const LocationView = ({ isShow, onClose, onEdit, locationId }) => {
  const setModal = useSetModal();
  const location = useLocation();
  const { t } = useTranslation();
  const [deleteLocation] = useDeleteLocationMutation();
  const [deleteLocations] = useDeleteLocationsMutation();

  const {
    currentData: data,
    error,
    refetch: refetchDetails,
  } = useGetLocationQuery(locationId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: hierarchyTree = { tree: [] },
    error: hierarchyTreeError,
    refetch: refetchTree,
  } = useGetLocationHierarchicalListQuery(locationId ? locationId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [locations = [], onLocationsSearch] = useSearch(
    useGetAllLocationsQuery,
    true,
    //locations from the location family
    {
      "filter[g][0][ancestry_matches]": `/${data?.root_id}/%25`,
      "filter[g][0][id_eq]": data?.root_id,
      "filter[g][0][m]": "or",
    },
    undefined,
    { refetchOnMountOrArgChange: true }
  );

  const { addSnackBar } = useSnackBar();

  const [workOrderModal, setWorkOrderModal] = useState(null);
  const [addChildModal, setAddChildModal] = useState(false);

  const {
    locationUpdatePermit,
    locationDeletePermit,
    woCreatePermit,
    locationsViewSubRecordsPermit,
    locationsAddSubRecordsPermit,
  } = usePermission();

  // TODO: Add a proper 404
  useEffect(() => {
    if (error && error.status === 404) {
      onClose();
    }
  }, [error]);

  const files = data
    ? [
        ...data.images.map((file) => ({ ...file, isImage: true })),
        ...data.documents,
      ]
    : [];

  /* // TODO: Add a loader or a skeleton */
  if (!locationId || !data) return null;

  const refetchData = () => {
    refetchDetails();
    refetchTree();
  };

  const deleteErrorHandler = (message) => {
    const content =
      message.base?.[0] === RESPONSE_MESSAGE.connected_record
        ? t("locations.warningDeletionContent")
        : JSON.stringify(message);

    addSnackBar({
      title: t("locations.warningDeletionTitle"),
      content,
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
  };

  const deleteBulkErrorHandler = (error) => {
    const names = [];
    error.forEach((error) => {
      names.push(error?.body?.record?.name || "");
    });
    addSnackBar({
      title: t("locations.bulkActions.delete.subWarning.warningTitle"),
      content: t("locations.bulkActions.delete.subWarning.warningContent", {
        names: names.join(", "),
      }),
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
    onClose();
  };

  const deletingBulkSuccessHandler = (count) => {
    onClose();
    addSnackBar({
      content: t("locations.bulkActions.delete.success", { count }),
      type: "success",
    });
  };

  const onAssociationError = () => {
    addSnackBar({
      title: t("locations.warningDeletionTitle"),
      content: t("locations.warningDeletionContent"),
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
  };

  const handleCreateWO = () =>
    setWorkOrderModal({ location: { value: locationId, label: data.name } });

  return (
    <ViewModal
      label={data ? t("locations.name", { name: data.name }) : null}
      isOpen={isShow}
      onClose={onClose}
      subHeader={
        <>
          {woCreatePermit && (
            <Button onClick={handleCreateWO}>
              <WorkOrderButton>
                <ThinAddIcon />
                <span> {t("workOrder.title")}</span>
              </WorkOrderButton>
            </Button>
          )}
          <MarginedButton
            variant="secondary"
            onClick={() => onEdit(data.id)}
            disabled={!locationUpdatePermit}
          >
            {t("edit")}
          </MarginedButton>
          <HeaderContextMenu disabled={!locationDeletePermit}>
            {locationDeletePermit && (
              <Deletion
                id={locationId}
                module={data?.name}
                onSuccess={onClose}
                onBulkSuccess={deletingBulkSuccessHandler}
                onError={deleteErrorHandler}
                onBulkError={deleteBulkErrorHandler}
                onAssociationError={onAssociationError}
                confirmation={{
                  title: t("locations.deletionConfirmation.title"),
                  description: t("locations.deletionConfirmation.description"),
                  confirmButtonLabel: t(
                    "locations.deletionConfirmation.confirm"
                  ),
                }}
                subConfirmation={{
                  title: t("locations.subDeletionConfirmation.title"),
                  description: t(
                    "locations.subDeletionConfirmation.description"
                  ),
                  confirmButtonLabel: t(
                    "locations.subDeletionConfirmation.confirm"
                  ),
                  cancelButtonLabel: t(
                    "locations.subDeletionConfirmation.cancel"
                  ),
                }}
                onDelete={deleteLocation}
                onBulkDelete={deleteLocations}
                hierarchyTree={hierarchyTree}
                hasChildren={data.has_children}
                hasAssociation={
                  data?.assets?.length > 0 ||
                  data?.work_order_requests?.length > 0 ||
                  data?.work_orders?.length > 0
                }
              />
            )}
          </HeaderContextMenu>
        </>
      }
    >
      {data && (
        <LocationViewModule
          id={data.id}
          name={data.name}
          description={data.description}
          address={data.address}
          updatedAt={data.updated_at}
          // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
          // teams={data.teams}
          parentLocation={data?.parent}
          code={data.qr_code}
          fileManagerNode={<FileManager value={files} />}
        />
      )}
      {locationsViewSubRecordsPermit && (
        <>
          <DividerStyled />
          <HierarchicalList
            data={hierarchyTree}
            error={hierarchyTreeError}
            module="locations"
            onClickAdd={() => setAddChildModal(true)}
            addPermission={locationsAddSubRecordsPermit}
            getItemLink={(id) => getModalPath(location, "location", id)}
          />
        </>
      )}
      {addChildModal && (
        <LocationCreate
          isShow={true}
          onClose={() => setAddChildModal(false)}
          defaultValues={{ parent: { value: data.id, label: data.name } }}
          defaultLocations={locations}
          defaultOnLocationsSearch={onLocationsSearch}
          onCreate={refetchData}
        />
      )}
      {workOrderModal && (
        <WorkOrderCreate
          isShow={Boolean(workOrderModal)}
          defaultValues={workOrderModal}
          onClose={() => setWorkOrderModal(null)}
          onOpen={setModal}
        />
      )}
    </ViewModal>
  );
};

LocationView.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
};

const MarginedButton = styled(Button)`
  margin-left: auto;
`;

const WorkOrderButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
