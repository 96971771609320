import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 32px;
  padding: 20px 0;

  border-bottom: 1px solid var(--color_grey100);
  font-size: 20px;
`;

export const SummaryItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.color};
`;

export const Separator = styled.div`
  width: 1px;
  height: 1em;
  background-color: var(--color_grey100);
`;

export const Chart = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 16px;
  align-items: center;
`;

export const TrackBar = styled.span`
  position: absolute;
  height: 6px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Track = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 35px;

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    height: 0;
    width: 100%;
    transform: translateY(-50%);

    display: block;
    content: "";
    border-top: 1px dashed var(--color_grey200);
  }
`;

export const TrackLabel = styled.span`
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  color: var(--color_grey500);
`;

export const XAxisWrapper = styled.div`
  position: relative;
  border-top: 1px solid var(--color_grey200);
  grid-column: 2;
  height: 25px;
`;

export const Tick = styled.span`
  position: absolute;
  padding-top: 4px;
  transform: translateX(-50%);

  font-size: 12px;
  color: var(--color_grey900);

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 50%;
    height: 3px;
    width: 2px;
    background-color: var(--color_grey200);
  }

  &.isEnd {
    // Prevent final tick from overflowing the chart, if it's right at the end
    transform: translateX(-100%);

    &:before {
      left: calc(100% - 2px);
    }
  }
`;
