import { useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "modules/reporting/lib/formatters";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { Pagination } from "features/Pagination";
import { ReactiveVsPreventiveTableData } from "./types";
import { PaginationData } from "types/Response";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";

const reportKey = ReportKey.ReactiveVsPreventive;

interface ReactiveVsPreventiveReportTableProps {
  data?: ReactiveVsPreventiveTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const ReactiveVsPreventiveReportTable = ({
  data,
  paginationData,
  isFetching,
}: ReactiveVsPreventiveReportTableProps) => {
  const { t } = useTranslation();
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  return (
    <>
      <Table
        columns={columns}
        rows={data}
        getRowKey={(row: ReactiveVsPreventiveTableData) => row.type}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(): TableColumn<ReactiveVsPreventiveTableData>[] {
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();
  return [
    {
      field: "type",
      name: t(`reporting.${reportKey}.columns.title`),
      minWidth: 200,
      formatValue: (val) =>
        val?.substring(0, 1).toUpperCase() + val?.substring(1),
    },
    {
      field: "amount",
      name: t(`reporting.${reportKey}.columns.amount`),
      minWidth: 120,
      type: "number",
      renderCell: (row) =>
        `${formatNumber(row.amount, language)} (${formatPercent(
          row.amount_percentage,
          language
        )})`,
    },
    {
      field: "overdue",
      name: t(`reporting.${reportKey}.columns.overdue`),
      minWidth: 120,
      type: "number",
      renderCell: (row) =>
        `${formatNumber(row.overdue, language)} (${formatPercent(
          row.overdue_percentage,
          language
        )})`,
    },
    {
      field: "mttr",
      name: t(`reporting.${reportKey}.columns.mttr`),
      minWidth: 120,
      type: "number",
      renderCell: (row) => {
        if (row.type === "preventive") {
          return "N/A";
        }
        return typeof row.mttr === "number"
          ? t(`reporting.mttr`, {
              mttr: row.mttr / 60,
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            })
          : "-";
      },
    },
    {
      field: "on_time",
      name: t(`reporting.${reportKey}.columns.on_time`),
      minWidth: 120,
      type: "number",
      renderCell: (row) =>
        `${formatNumber(row.on_time, language)} (${formatPercent(
          row.on_time_percentage,
          language
        )})`,
    },
    {
      field: "avg_costs",
      name: t(`reporting.${reportKey}.columns.avg_costs`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatCurrency(val, language, currency),
    },
    {
      field: "total_costs",
      name: t(`reporting.${reportKey}.columns.total_costs`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatCurrency(val, language, currency),
    },
  ];
}
