import { useTranslation } from "react-i18next";
import { Priority } from "shared/components/Priority";
import {
  formatUnitDuration,
  formatNumber,
  formatPercent,
} from "modules/reporting/lib/formatters";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { BacklogByPriorityTableData } from "./types";

const reportKey = ReportKey.BacklogByPriority;
const priorityOrder: Record<string, number> = {
  critical: 0,
  high: 1,
  medium: 2,
  low: 3,
  none: 4,
};

interface BacklogByPriorityReportTableProps {
  data?: BacklogByPriorityTableData[];
  isFetching: boolean;
}

export const BacklogByPriorityReportTable = ({
  data,
  isFetching,
}: BacklogByPriorityReportTableProps) => {
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  const tableData = data
    ?.slice()
    .sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]);
  return (
    <Table
      columns={columns}
      rows={tableData}
      getRowKey={(r: BacklogByPriorityTableData) => r.priority}
      showSkeleton={isFetching}
      notFound={!isFetching && !data}
    />
  );
};

function useTableColumns(): TableColumn<BacklogByPriorityTableData>[] {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return [
    {
      field: "priority",
      name: t(`reporting.${reportKey}.columns.priority`),
      minWidth: 200,
      renderCell: (row) => <Priority value={row.priority} />,
    },
    {
      field: "amount",
      name: t(`reporting.${reportKey}.columns.amount`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatNumber(val, language),
    },
    {
      field: "amount_percentage",
      name: t(`reporting.${reportKey}.columns.amount_percentage`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatNumber(val, language, { style: "percent" }),
    },
    {
      field: "overdue",
      name: t(`reporting.${reportKey}.columns.overdue`),
      minWidth: 120,
      type: "number",
      formatValue: (val) => formatNumber(val, language),
    },
    {
      field: "avg_age",
      name: t(`reporting.${reportKey}.columns.avg_age`),
      minWidth: 120,
      type: "number",
      formatValue: (val) =>
        formatUnitDuration(val, "days", language, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
    },
    {
      field: "reactive",
      name: t(`reporting.${reportKey}.columns.reactive`),
      minWidth: 120,
      type: "number",
      renderCell: (row) =>
        `${formatNumber(row.reactive, language)} (${formatPercent(
          row.reactive_percentage,
          language
        )})`,
    },
    {
      field: "preventive",
      name: t(`reporting.${reportKey}.columns.preventive`),
      minWidth: 120,
      type: "number",
      renderCell: (row) =>
        `${formatNumber(row.preventive, language)} (${formatPercent(
          row.preventive_percentage,
          language
        )})`,
    },
  ];
}
