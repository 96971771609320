import React, { useMemo } from "react";
import { Name, RowButton } from "shared/components/Table";
// TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
// import { ShortTagsDisplay } from "shared/components/ShortTagsDisplay";
import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";
import { useTranslation } from "react-i18next";
import { ParentIcon } from "modules/filters";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLink } from "shared/components/ModalLink";

export const useLocationsTableTools = ({
  onOpenLocation,
  onFilterByParentLocations,
  quickFilter,
}) => {
  const { t } = useTranslation();
  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("location");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    return [
      {
        field: "name",
        name: t(`locations.columns.name`),
        width: 360,
        sortable: true,
        renderCell: (row, formattedValue) => (
          <Name onClick={() => onOpenLocation(row.id)} value={formattedValue} />
        ),
        primary: true,
        order: 0,
      },
      {
        field: "path",
        sortQueryParam: "path",
        name: t(`locations.columns.path`),
        width: 340,
        renderCell: (row) => <LocationPath location={row} />,
        hidden: !settings?.path?.visible,
        order: settings?.path?.order,
      },
      {
        field: "parent",
        sortQueryParam: "parent_name",
        name: t(`locations.columns.parent`),
        width: 200,
        sortable: false,
        formatValue: (raw) => raw?.name ?? null,
        renderCell: (row, value) => (
          <RowButton
            value={
              <ModalLink
                modalParams={{ type: "location", id: row?.parent?.id }}
              >
                {value}
              </ModalLink>
            }
            visible={quickFilter ? false : row?.parent?.id}
            onClick={() =>
              onFilterByParentLocations({
                id: row?.parent?.id,
                name: row?.parent?.name,
              })
            }
            icon={<ParentIcon />}
          />
        ),
        hidden: !settings?.["parent"]?.visible,
        order: settings?.["parent"]?.order,
      },
      {
        field: "description",
        name: t(`locations.columns.description`),
        width: 340,
        sortable: true,
        hidden: !settings?.["description"]?.visible,
        order: settings?.["description"]?.order,
      },
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      // {
      //   field: "teams",
      //   sortQueryParam: "teams_name",
      //   name: "Teams",
      //   width: 200,
      //   sortable: false,
      //   renderCell: (row) => <ShortTagsDisplay tags={row.teams} compact />,
      // },
      {
        field: "address",
        name: t(`locations.columns.address`),
        width: 200,
        sortable: true,
        hidden: !settings?.["address"]?.visible,
        order: settings?.["address"]?.order,
      },
    ]
      .filter(({ field }) => settings?.[field]?.visible_global !== false)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [onOpenLocation, tableSettings?.data.payload, t, quickFilter]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};
