import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { formatCurrency, formatNumber } from "modules/reporting/lib/formatters";
import { AssetCostsAndDowntimeKPIs } from "modules/reporting/components/reports/AssetCostsAndDowntimeReport/types";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";

const reportKey = ReportKey.AssetCostsAndDowntime;
export const useAssetCostsAndDowntimeReportKPIs = (
  reportPeriod?: ReportPeriod
) => {
  let { data, ...rest } = useGetKPIs<AssetCostsAndDowntimeKPIs>(
    reportKey,
    reportPeriod,
    "assets"
  );

  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();
  return {
    ...rest,
    data: [
      {
        value: formatCurrency(data?.total_cost, language, currency),
        label: t(`reporting.${reportKey}.kpis.total_cost`),
      },
      {
        value: formatHours(data?.total_downtime, language),
        label: t(`reporting.${reportKey}.kpis.total_downtime`),
      },
      {
        value: formatHours(data?.planned_downtime, language),
        label: t(`reporting.${reportKey}.kpis.planned_downtime`),
      },
      {
        value: formatHours(data?.unplanned_downtime, language),
        label: t(`reporting.${reportKey}.kpis.unplanned_downtime`),
      },
      {
        value: formatHours(data?.mttr, language),
        label: t(`reporting.mttr_label`),
      },
      {
        value: formatHours(data?.mtbf, language),
        label: t(`reporting.${reportKey}.kpis.mtbf`),
      },
    ],
  };
};

function formatHours(seconds: number | null | undefined, language: string) {
  return typeof seconds === "number"
    ? formatNumber(seconds, language, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }) + "h"
    : "-";
}
