import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Tabs } from "shared/components/Tabs";
import { Header } from "widgets/Header";

import { usePermission } from "app/providers/PermissionsProvider";
import { ModalLink } from "shared/components/ModalLink";
import AddIcon from "shared/assets/icon/add.svg?react";
import { NotAllowed } from "shared/components/NotAllowed";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const AdminSettings = () => {
  const location = useLocation();
  const tab = location.pathname.split("/")[2];

  let {
    adminManageModulesPermit,
    adminSettingsUsersPermit,
    adminSettingsCustomerPermit,
    adminSettingsEntitiesPermit,
  } = usePermission();

  const { t } = useTranslation();

  const generalSettingsPermit =
    adminSettingsCustomerPermit && adminSettingsEntitiesPermit;

  if (!tab) {
    return <Navigate to="/admin-settings/general" replace />;
  } else if (
    !generalSettingsPermit &&
    !adminManageModulesPermit &&
    !adminSettingsUsersPermit
  ) {
    return (
      <AdminPage>
        <NotAllowed style={{ gridRow: "span 2" }} />
      </AdminPage>
    );
  }

  return (
    <AdminPage>
      <Header
        title={"Administration"}
        withSearch={tab === "all-users"}
        searchPlaceholder={t("allUsers.searchPlaceholder")}
        action={
          tab === "all-users" && (
            <ModalLink
              type="button"
              icon={<AddIcon />}
              modalParams={{ type: "user", id: "new" }}
            >
              {t("allUsers.addNewUser")}
            </ModalLink>
          )
        }
        horizontallySpaciousPage
      />
      <AdminTabs selectedKey={tab}>
        <Tabs.TabList>
          {generalSettingsPermit && (
            <Tabs.Tab id="general" href="/admin-settings/general">
              {t("settings.tabs.generalSettings")}
            </Tabs.Tab>
          )}
          {adminManageModulesPermit && (
            <Tabs.Tab id="manage-modules" href="/admin-settings/manage-modules">
              {t("settings.tabs.manageModules")}
            </Tabs.Tab>
          )}
          {adminSettingsUsersPermit && (
            <Tabs.Tab id="all-users" href="/admin-settings/all-users">
              {t("settings.tabs.allUsers")}
            </Tabs.Tab>
          )}
        </Tabs.TabList>
        <AdminTabPanel id={tab}>
          <Outlet />
        </AdminTabPanel>
      </AdminTabs>
    </AdminPage>
  );
};

const AdminPage = styled.main`
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
`;

const AdminTabs = styled(Tabs.Root)`
  box-sizing: border-box;
  padding-left: 40px;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
`;

const AdminTabPanel = styled(Tabs.TabPanel)`
  overflow: auto;
`;
