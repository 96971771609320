import { pdf } from "@react-pdf/renderer";

export const downloadFile = async (doc, fileName = "clickmaint.pdf") => {
  const asPdf = pdf([]);
  asPdf.updateContainer(doc);
  const pdfBlob = await asPdf.toBlob();
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};
