import { useTranslation } from "react-i18next";
import { NoInternetModalBox, NoInternetModalWrapper } from "./styled";

import NoInternetIcon from "../../shared/assets/NoInternetIcon.svg?react";

export const NoInternetModal = ({ isShow }) => {
  const { t } = useTranslation();
  return (
    <NoInternetModalWrapper isOpen={isShow}>
      <NoInternetModalBox>
        <NoInternetIcon />
        <p>{t("m172", { ns: "errors" })}</p>
      </NoInternetModalBox>
    </NoInternetModalWrapper>
  );
};
