import { useTranslation } from "react-i18next";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { Pagination } from "features/Pagination";
import { MaintenanceHistoryByAssigneeTableData } from "./types";
import { PaginationData } from "types/Response";

const reportKey = ReportKey.MaintenanceHistoryByAssignee;

interface MaintenanceHistoryByAssigneeReportTableProps {
  data?: MaintenanceHistoryByAssigneeTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const MaintenanceHistoryByAssigneeReportTable = ({
  data,
  paginationData,
  isFetching,
}: MaintenanceHistoryByAssigneeReportTableProps) => {
  const { t } = useTranslation();
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  const sortData = (
    data: MaintenanceHistoryByAssigneeTableData[] | undefined
  ) => {
    if (!data) {
      return [];
    }
    return [...data].sort((a, b) => b.total_labor_hours - a.total_labor_hours);
  };
  const sortedData = sortData(data);
  return (
    <>
      <Table
        columns={columns}
        rows={sortedData}
        getRowKey={(row) => row.assignee}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(): TableColumn<MaintenanceHistoryByAssigneeTableData>[] {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return [
    {
      field: "assignee",
      name: t(`reporting.${reportKey}.columns.assignee`),
      sortable: true,
      minWidth: 200,
    },
    {
      field: "total_labor_hours",
      name: t(`reporting.${reportKey}.columns.total_labor_hours`),
      sortable: true,
      formatValue: (val) => formatNumber(val, language),
    },
    {
      field: "total_regular_labor_hours",
      name: t(`reporting.${reportKey}.columns.total_regular_labor_hours`),
      sortable: true,
      renderCell: (row) =>
        `${formatNumber(
          row.total_regular_labor_hours,
          language
        )} (${formatPercent(row.total_regular_hours_percentage, language)})`,
    },
    {
      field: "total_overtime_hours",
      name: t(`reporting.${reportKey}.columns.total_overtime_hours`),
      sortable: true,
      renderCell: (row) =>
        `${formatNumber(row.total_overtime_hours, language)} (${formatPercent(
          row.total_overtime_hours_percentage,
          language
        )})`,
    },
    {
      field: "completed_work_orders",
      name: t(`reporting.${reportKey}.columns.completed_work_orders`),
      sortable: true,
      formatValue: (val: number) => formatNumber(val, language),
    },
  ];
}
