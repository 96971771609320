import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Input } from "shared/components/Input";

import { useUpdateRequestMutation } from "modules/requests";

export const RequestStatusUpdate = ({ status, id, onClose }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [updateRequest] = useUpdateRequestMutation();

  const type = status === "on_hold" ? "Put on Hold" : "Declined";

  const form = useForm({
    initialValues: {
      comments: "",
    },
    enableReinitialize: true,
    onSubmit: async (form) => {
      updateRequest({
        id,
        body: {
          status,
          comments: form.comments,
        },
      })
        .then(window.totango.track(`Request ${type}`, "Requests"))
        .catch(({ message }) => {
          addSnackBar({
            title: t("work_order_requests.snack.failUpdateTitle"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
      closeModal();
    },
  });

  const closeModal = () => {
    form.resetForm();
    onClose();
  };

  return (
    <ConfirmationDialog
      title={t(`work_order_requests.header.${status}`)}
      confirmButtonLabel={t("save")}
      cancelButtonLabel={t("cancel")}
      form={form}
      disabledSubmit={!!Object.values(form.errors).length}
      description={
        <RequestContainer>
          <RequestCommentsTitle>
            {t("work_order_requests.form.comments")}
          </RequestCommentsTitle>
          <Input
            name="comments"
            value={form.values.comments}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("work_order_requests.form.comments")}
            isTextArea
          />
        </RequestContainer>
      }
      onProceed={form.handleSubmit}
      onCancel={closeModal}
    />
  );
};

const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const RequestCommentsTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`;
