import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { useActivatePMMutation } from "modules/workOrders";
import { Button } from "shared/components/Button";
import { ModalLink } from "shared/components/ModalLink";

import OpenIcon from "../../assets/icons/open.svg?react";

export const ActivateComponent = ({
  status,
  hasUncompletedWo,
  id,
  lastActiveWOId,
}) => {
  const { t } = useTranslation();
  const [activatePM] = useActivatePMMutation();

  const [disabled, setDisabled] = useState(false);

  const { addSnackBar } = useSnackBar();

  if (status === "completed") return null;

  const activateHandle = async () => {
    setDisabled(true);
    const resp = await activatePM({ id });

    setDisabled(false);

    if (resp.error?.status === "error") {
      addSnackBar({
        title: "Error",
        content: resp.error?.message,
        type: "error",
      });
    }
  };

  return (
    <>
      {hasUncompletedWo ? (
        <ModalLink
          type="button"
          variant="secondary"
          modalParams={{ type: "wo", id: lastActiveWOId }}
          icon={<OpenIcon />}
          style={{ whiteSpace: "nowrap" }}
        >
          {t(`preventive_maintenances.active`)}
        </ModalLink>
      ) : (
        <ActivateButton
          disabled={status === "paused" || disabled}
          onClick={activateHandle}
        >
          {t(`preventive_maintenances.activate`)}
        </ActivateButton>
      )}
    </>
  );
};

const ActivateButton = styled(Button)`
  white-space: nowrap;

  &:disabled {
    background: var(--color_grey10);
    border-color: var(--color_grey100);
    color: var(--color_grey500);
    cursor: initial;
  }
`;
