import { getBaseApiUrl } from "shared/lib/apiTools/getBaseApiUrl";
import { formatDateValue, downloadFile } from "shared/lib/helpers";
import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const requestsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getRequests: builder.query({
      query: (params) => ({
        url: "/requests",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Request", id: "RequestsList" },
        ...getTagList("Request", result?.data),
      ],
    }),
    addRequest: builder.mutation({
      query: (body) => ({
        url: "requests",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Request", id: "RequestsList" }],
      transformErrorResponse: (response) => response.data,
    }),
    getRequest: builder.query({
      query: (id) => `/requests/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "RequestDetails", id: query },
      ],
    }),
    updateRequest: builder.mutation({
      query: ({ id, body }) => ({
        url: `requests/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Request", id },
        { type: "RequestDetails", id },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteRequest: builder.mutation({
      query: (id) => ({
        url: `/requests/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "Request", id: "RequestsList" }],
    }),
    deleteRequests: builder.mutation({
      query: (ids) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/requests/${id}`, false),
            method: "DELETE",
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, ids) => [
        { type: "Request", id: "RequestsList" },
        ...ids.map((id) => ({ type: "Request", id })),
      ],
    }),
    cloneRequest: builder.mutation({
      query: ({ id, body }) => ({
        url: `/requests/${id}/clone`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Request", id: "RequestsList" }],
      transformErrorResponse: (response) => response.data,
    }),
    exportRequests: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `requests/download_report`,
          method: "POST",
          params,
          responseHandler: (response) => response.blob(),
        });
        const filename = formatDateValue(new Date()) + "-requests.xls";
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
    updateRequestsStatus: builder.mutation({
      query: ({ ids, status, comments }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/requests/${id}`, false),
            method: "put",
            params: {
              status,
              comments,
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "Request", id: "RequestsList" },
        ...ids.map((id) => ({ type: "Request", id })),
      ],
    }),
  }),
});

export const {
  useGetRequestsQuery,
  useAddRequestMutation,
  useGetRequestQuery,
  useUpdateRequestMutation,
  useDeleteRequestMutation,
  useDeleteRequestsMutation,
  useCloneRequestMutation,
  useExportRequestsMutation,
  useUpdateRequestsStatusMutation,
} = requestsApi;
