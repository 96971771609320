import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";

import {
  addAssetValidationSchema,
  getFormatForService as getFormatForAssetService,
  useGetAssetQuery,
  useUpdateAssetMutation,
  getInitialValues,
  getOptionalFields,
} from "modules/assets";
import { useGetFormFieldsQuery } from "modules/formFields";

import { RESPONSE_MESSAGE } from "shared/lib/constants/api";
import { useForm } from "shared/lib/hooks/useForm";
import { EditorModal } from "shared/components/Modals";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { AssetEditor } from "../AssetEditor";
import { validateURLsAttributesList } from "shared/lib/helpers/validateURLsAttributesList";

export const AssetUpdate = ({ isShow, assetId, onClose, onSuccess }) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const [generateQr, setGenerateQr] = useState(false);

  const { data } = useGetFormFieldsQuery({
    type: "assets",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(data);

  const { data: assetData } = useGetAssetQuery(assetId ?? skipToken);

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [updateAsset, updateAssetRes] = useUpdateAssetMutation();

  const assetsDataForForm = useMemo(
    () => getInitialValues({ type: "edit", data: assetData }),
    [assetData]
  );

  const { setValues, ...updateAssetFrom } = useForm({
    initialValues: assetsDataForForm,
    willUpdatedValues: assetsDataForForm,
    enableReinitialize: true,
    validationSchema: addAssetValidationSchema(optionalFields, t, generateQr),
    onSubmit: async (body, { setFieldError }) => {
      const urlsValid = validateURLsAttributesList(
        body.urls_attributes ?? [],
        setFieldError
      );
      if (!urlsValid) return;

      const dataForApi = getFormatForAssetService(body, { generateQr });
      setDisabledSubmit(true);
      try {
        await updateAsset({
          id: assetId,
          body: dataForApi,
        }).unwrap();
        setDisabledSubmit(false);
        onSuccess(assetId);
      } catch ({ message }) {
        if (message.name?.[0] === RESPONSE_MESSAGE.already_taken) {
          setFieldError("name", t("m11", { ns: "errors" }));
        } else if (
          message["qr_code.value"]?.[0] === RESPONSE_MESSAGE.already_taken
        ) {
          setFieldError("qr_code_attributes", t("m2", { ns: "errors" }));
        } else {
          addSnackBar({
            title: t("assets.failedUpdate"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
        }
        setDisabledSubmit(false);
      }
    },
  });

  const onCloseModal = () => {
    onClose();
    updateAssetFrom.resetForm();
    setGenerateQr(false);
  };

  return (
    <EditorModal
      label={assetData ? t("assets.edit", { id: assetData.id }) : null}
      isOpen={isShow}
      onClose={onCloseModal}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={updateAssetFrom.handleSubmit}
      disabledSubmit={
        (!updateAssetFrom.isWasChanged && !generateQr) ||
        !updateAssetFrom.isFormValid ||
        disabledSubmit
      }
      form={updateAssetFrom}
      isSubmitLoading={updateAssetRes.isLoading || updateAssetRes.isSuccess}
    >
      <AssetEditor
        form={updateAssetFrom}
        generateQr={generateQr}
        setGenerateQr={setGenerateQr}
        optionalFields={optionalFields}
        assetId={assetId}
      />
    </EditorModal>
  );
};

AssetUpdate.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
};
