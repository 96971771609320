import * as Yup from "yup";
import { getFormDataFromJSON } from "shared/lib/helpers/formData";
import {
  getSelectSchema,
  getStringSchema,
  getMultiSelectSchema,
  getDescriptionSchema,
} from "shared/lib/helpers/validation";
import { getAssignToIds } from "shared/lib/helpers/common";
import { phoneNumberValidation } from "modules/users";
import { PortalFormOptionalFields } from "../constants";
import { emailRegexp } from "shared/lib/constants/forms";

export const portalValidationSchema = (t, optionalFields, allow_anonymous) => {
  return Yup.object().shape({
    title: Yup.string()
      .required(t("validation.required"))
      .max(255, t("validation.stringMaxLength", { stringMaxLength: 255 })),
    description: getDescriptionSchema(t, optionalFields.description),
    notify: Yup.boolean(),
    priority: Yup.lazy((value) =>
      typeof value === "string"
        ? getStringSchema(t, optionalFields.priority)
        : getSelectSchema(t, optionalFields.priority)
    ),
    categories: getMultiSelectSchema(t, optionalFields.categories_ids),
    due_date: getStringSchema(t, optionalFields.due_date),
    uploads_attributes: Yup.array(),
    vendors: getMultiSelectSchema(t, optionalFields.vendors_ids),
    location: getSelectSchema(t, optionalFields.location_id),
    asset: getSelectSchema(t, optionalFields.asset_id),
    assign_to: getMultiSelectSchema(t, optionalFields.assign_to),
    portal_user_name: getStringSchema(t, { required: false, max: 100 }).test(
      t("validation.required"),
      t("validation.required"),
      (value, testContext) => {
        if (allow_anonymous) {
          return testContext.parent.notify ? !!value : true;
        }

        return !!value;
      }
    ),
    portal_user_surname: getStringSchema(t, { required: false, max: 100 }).test(
      t("validation.required"),
      t("validation.required"),
      (value, testContext) => {
        if (allow_anonymous) {
          return testContext.parent.notify ? !!value : true;
        }

        return !!value;
      }
    ),
    portal_user_phone_number: getStringSchema(t, {
      required: false,
      max: 50,
    }),
    portal_user_email: Yup.string()
      .max(255, t(`validation.stringMaxLength`, { stringMaxLength: 255 }))
      .matches(emailRegexp, t("contacts.validation.email"))
      .test(
        "Required when notify",
        t("validation.required"),
        (value, testContext) => {
          if (allow_anonymous) {
            return testContext.parent.notify ? !!value : true;
          }

          return !!value;
        }
      ),
  });
};

export const getOptionalFields = ({ fields }) => {
  if (!fields || fields?.status !== "success") {
    return {};
  }

  const { data } = fields;

  return PortalFormOptionalFields.reduce((acc, optionalField) => {
    const field = data.find(({ name }) => name === optionalField);
    if (field) {
      const { required } = field;
      acc[optionalField] = { required };
    }
    return acc;
  }, {});
};

export const getPortalFormatForService = (form, requestId) => {
  const {
    uploads_attributes,
    location,
    asset,
    vendors,
    categories,
    assign_to,
    priority,
    portal_user_phone_number,
    ...otherFields
  } = form;

  const { teams_ids, technicians_ids } = getAssignToIds(assign_to);

  const data = getFormDataFromJSON({
    ...otherFields,
    portal_user_phone_number: portal_user_phone_number
      ? phoneNumberValidation(portal_user_phone_number)
      : "",
    location_id: location?.value || "",
    asset_id: asset?.value || "",
    vendors_ids: vendors.map(({ value }) => value),
    categories_ids: categories.map(({ value }) => value),
    teams_ids,
    technicians_ids,
    priority: typeof priority === "object" ? priority?.value : priority,
  });

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      data.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj?.file) {
      data.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    } else if (fileObj?.id) {
      data.append(`uploads_attributes[${index}][attachment_id]`, fileObj?.id);
    }
  });

  data.append("request_portal_id", requestId);

  return data;
};
