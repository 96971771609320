import ReactSelect, { components } from "react-select";

import { Option } from "shared/components/Select/Option";
import SettingsIcon from "shared/assets/icon/table/settings.svg?react";

const SelectStyles = {
  control: (base) => ({
    ...base,
    fontSize: 12,
    border: "none",
    minHeight: "unset",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    right: 0,
    left: "unset",
  }),
};

export const PeriodSelect = ({ value, onChange, options }) => {
  return (
    <ReactSelect
      options={options}
      isSearchable={false}
      isClearable={false}
      value={value}
      onChange={(val) => onChange(val)}
      components={{
        Option,
        IndicatorSeparator: null,
        DropdownIndicator,
      }}
      styles={SelectStyles}
    />
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SettingsIcon />
    </components.DropdownIndicator>
  );
};
