import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { Status } from "modules/requests/components/Status";
import { Priority } from "shared/components/Priority";
import { RequestTitle } from "modules/requests/components/RequestTitle";
import { Comment } from "shared/components/Comment";

import { useDisplayDate } from "shared/lib/hooks";
import { isRequestOverdue } from "modules/requests/lib/helpers/isRequestOverdue";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLink } from "shared/components/ModalLink";
import { ModalLinksList } from "shared/components/ModalLinksList";

export const useRequestsTableTools = ({ onOpen, onApprove }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("work_order_request");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    return [
      {
        field: "title",
        name: t("work_order_requests.columns.title"),
        width: 400,
        sortable: true,
        renderCell: (row, value) => (
          <RequestTitle
            onClick={() => onOpen(row.id)}
            id={row.id}
            value={value}
            status={row.status}
            onApprove={onApprove}
          />
        ),
        primary: true,
        order: 0,
        hidden: false,
      },
      {
        field: "id",
        name: t("work_order_requests.columns.id"),
        width: 120,
        sortable: true,
        formatValue: (value) => `#${value}`,
        order: settings?.id?.order,
        hidden: !settings?.id?.visible,
      },
      {
        field: "created_at",
        name: t("work_order_requests.columns.createdAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.created_at?.order,
        hidden: !settings?.created_at?.visible,
      },
      {
        field: "updated_at",
        name: t("work_order_requests.columns.updatedAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.updated_at?.order,
        hidden: !settings?.updated_at?.visible,
      },
      {
        field: "created_by_name",
        name: t("work_order_requests.columns.createdBy"),
        width: 150,
        sortable: true,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
        order: settings?.created_by_name?.order,
        hidden: !settings?.created_by_name?.visible,
      },
      {
        field: "updated_by_name",
        name: t("work_order_requests.columns.updatedBy"),
        width: 150,
        sortable: true,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
        order: settings?.updated_by_name?.order,
        hidden: !settings?.updated_by_name?.visible,
      },
      {
        field: "description",
        name: t("work_order_requests.columns.description"),
        width: 320,
        sortable: true,
        order: settings?.description?.order,
        hidden: !settings?.description?.visible,
      },
      {
        field: "status",
        name: t("work_order_requests.columns.status"),
        width: 150,
        sortable: true,
        renderCell: ({ status }) => <Status value={status} />,
        order: settings?.status?.order,
        hidden: !settings?.status?.visible,
        fastFilter: true,
      },
      {
        field: "priority",
        name: t("work_order_requests.columns.priority"),
        width: 150,
        sortable: true,
        renderCell: ({ priority }) => <Priority value={priority} />,
        order: settings?.priority?.order,
        hidden: !settings?.priority?.visible,
        fastFilter: true,
        initialSort: "desc",
      },
      {
        field: "location",
        sortQueryParam: "location_name",
        name: t("work_order_requests.columns.location"),
        width: 340,
        sortable: true,
        renderCell: (row) => <LocationPath location={row.location} />,
        order: settings?.location?.order,
        hidden: !settings?.location?.visible,
      },
      {
        field: "asset",
        sortQueryParam: "asset_name",
        name: t("work_order_requests.columns.asset"),
        width: 300,
        sortable: true,
        formatValue: (asset) =>
          asset ? (
            <ModalLink modalParams={{ type: "asset", id: asset.id }}>
              {asset.name}
            </ModalLink>
          ) : null,
        order: settings?.asset?.order,
        hidden: !settings?.asset?.visible,
      },
      {
        field: "comments",
        name: t("work_order_requests.columns.comments"),
        width: 320,
        sortable: true,
        filter: false,
        renderCell: ({ comments }) => (
          <Comment value={comments?.length > 0 ? comments?.length : null} />
        ),
        order: settings?.comments?.order,
        hidden: !settings?.comments?.visible,
      },
      {
        field: "work_order_id",
        name: t("work_order_requests.columns.workOrder"),
        width: 150,
        sortable: true,
        formatValue: (value) => (value ? `#${value}` : ""),
        order: settings?.work_order_id?.order,
        hidden: !settings?.work_order_id?.visible,
      },
      {
        field: "categories",
        sortQueryParam: "categories_name",
        name: t("work_order_requests.columns.categories"),
        width: 200,
        sortable: true,
        renderCell: ({ categories }) =>
          categories.map(({ name }) => name).join(", "),
        order: settings?.categories?.order,
        hidden: !settings?.categories?.visible,
        fastFilter: true,
      },
      {
        field: "vendors",
        sortQueryParam: "vendors_name",
        name: t("work_order_requests.columns.vendors"),
        width: 200,
        sortable: true,
        formatValue: (vendors) => (
          <ModalLinksList
            type="vendor"
            items={vendors}
            layout="inline"
            separator=", "
          />
        ),
        order: settings?.vendors?.order,
        hidden: !settings?.vendors?.visible,
      },
      {
        field: "due_date",
        sortQueryParam: "needed_by",
        name: t("work_order_requests.columns.neededBy"),
        width: 200,
        sortable: true,
        renderCell: ({ due_date, status }) => (
          <span
            style={{
              color: isRequestOverdue(due_date, status) ? "#B35B5E" : undefined,
            }}
          >
            {displayDate(due_date, true)}
          </span>
        ),
        order: settings?.due_date?.order,
        hidden: !settings?.due_date?.visible,
      },
      {
        field: "assign_to",
        sortQueryParam: "assigned_to_name",
        name: t("work_order_requests.columns.assignedTo"),
        width: 200,
        sortable: false,
        renderCell: ({ technicians }) => (
          <UserAvatarGroup users={technicians} max={4} compact />
        ),
        order: settings?.assign_to?.order,
        hidden: !settings?.assign_to?.visible,
        fastFilter: true,
      },
      {
        field: "request_portal_id",
        name: t("work_order_requests.columns.requestPortalId"),
        sortQueryParam: "request_portal_title",
        width: 300,
        sortable: true,
        renderCell: ({ request_portal }) =>
          request_portal?.title
            ? request_portal.title
            : t("work_order_requests.columns.internalRequest"),
        order: settings?.request_portal_id?.order,
        hidden: !settings?.request_portal_id?.visible,
      },
      {
        field: "completed_at",
        sortQueryParam: "completed_at",
        name: t("work_order_requests.columns.completedAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.completed_at?.order,
        hidden: !settings?.completed_at?.visible,
      },
    ]
      .filter(({ field }) => settings?.[field]?.visible_global !== false)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [t, tableSettings?.data.payload, onOpen, displayDate]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};
