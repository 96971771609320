import {
  useDestroyProcedureMutation,
  useLinkProcedureTemplatesMutation,
} from "modules/procedures/state/proceduresApi";
import { IProcedure } from "modules/procedures/types";
import { useCallback, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { Button } from "shared/components/Button";
import { OptionType } from "shared/components/Select/SelectCreatable";
import styled from "styled-components";
import { useSetModal } from "widgets/ModalController";
import CheckIcon from "shared/assets/Check.svg?react";
import { Hint } from "shared/components/Typography";
import { useTranslation } from "react-i18next";
import { usePermission } from "app/providers/PermissionsProvider";
import { IconButton } from "shared/components/IconButton";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ProcedureLinkModal } from "modules/procedures/components";

interface ProcedureListProps {
  procedures: IProcedure[];
  workOrder: { id: string | number; technicians: { id: string | number }[] };
}

export const ProcedureList = ({
  procedures,
  workOrder,
}: ProcedureListProps) => {
  const { enableProcedures } = useFlags();
  const { id: workOrderId, technicians } = workOrder;
  const { t } = useTranslation();
  const { procedureDeletePermit, procedureItemUpdatePermit } = usePermission();
  const setModal = useSetModal();
  const { addSnackBar } = useSnackBar();
  const captureException = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  // State
  const [modalOpen, setModalOpen] = useState(false);
  const [procedureTemplates, setProcedureTemplates] = useState<OptionType[]>(
    []
  );
  const [isLinkingInProgress, setIsLinkingInProgress] =
    useState<boolean>(false);
  const [procedureToDelete, setProcedureToDelete] =
    useState<IProcedure | null>();

  // Mutations & queries
  const [destroyProcedure] = useDestroyProcedureMutation();
  const [triggerLinkProcedures] = useLinkProcedureTemplatesMutation();

  // Callbacks & functions
  const linkProcedures = async () => {
    setIsLinkingInProgress(true);
    const procedureTemplateIds = procedureTemplates.map((t) => t.value);
    try {
      await triggerLinkProcedures({
        targetId: workOrderId,
        targetType: "WorkOrder",
        procedureTemplateIds,
      }).unwrap();
    } catch (e) {
      captureException(e, "Failed to delete procedure", {
        workOrderId,
        procedureTemplateIds,
      });
    }

    setIsLinkingInProgress(false);
    setModalOpen(false);
  };

  const allowDelete = useCallback(
    (createdById: number | string) =>
      (procedureDeletePermit as (createdById: string | number) => boolean)(
        createdById
      ),
    [procedureDeletePermit]
  );

  const canUpdateProcedure = useCallback(
    (procedure: IProcedure) => {
      return (
        procedureItemUpdatePermit as (
          createdById: string | number,
          workOrderTechnicians: unknown[]
        ) => boolean
      )(procedure.created_by_id, technicians);
    },
    [procedureItemUpdatePermit, technicians]
  );

  const onProcedureDestroy = async (id: number | string) => {
    try {
      await destroyProcedure(id).unwrap();
      addSnackBar({
        title: t("procedures.snackbars.procedure_destroyed.success.title"),
        type: "success",
      });
    } catch (e) {
      captureException(e, "Failed to delete procedure", { procedure_id: id });
    }

    setProcedureToDelete(null);
  };

  const onSelect = (
    _: string,
    value: MultiValue<OptionType> | SingleValue<OptionType>
  ) => {
    setProcedureTemplates(value as OptionType[]);
  };

  const onModalClose = () => {
    setModalOpen(false);
    setProcedureTemplates([]);
  };

  if (!enableProcedures) {
    return null;
  }

  return (
    <>
      <FlexContainer>
        <Title>{t("procedures.title")}</Title>
        <StyledButton variant="secondary" onClick={() => setModalOpen(true)}>
          {t("procedures.add_procedure")}
        </StyledButton>
      </FlexContainer>

      {procedures.length > 0 && (
        <ListContainer>
          {procedures.map((procedure) => (
            <li key={procedure.id}>
              <Item>
                {procedure.name}
                <FlexContainer>
                  {procedure.completed_at == null && (
                    <Button
                      onClick={() =>
                        setModal({
                          type: canUpdateProcedure(procedure)
                            ? "procedure"
                            : "procedureTemplate",
                          id: canUpdateProcedure(procedure)
                            ? procedure.id
                            : procedure.procedure_template_id,
                        })
                      }
                    >
                      {t("procedures.view_procedure")}
                    </Button>
                  )}
                  {procedure.completed_at && (
                    <>
                      <Hint>{t("completed")}</Hint>
                      <CheckIcon />
                    </>
                  )}
                  {procedure.completed_at == null && (
                    <>
                      <IconButton
                        aria-label="Delete procedure"
                        onClick={() => setProcedureToDelete(procedure)}
                        disabled={!allowDelete(procedure?.created_by_id)}
                      >
                        <TrashCanIcon />
                      </IconButton>
                    </>
                  )}
                </FlexContainer>
              </Item>
            </li>
          ))}

          {procedureToDelete != null && (
            <ConfirmationDialog
              title={t("procedures.delete_dialog.confirm_title")}
              description={t("procedures.delete_dialog.confirm_description")}
              confirmButtonLabel={
                t("procedures.delete_dialog.confirm_button") as string
              }
              cancelButtonLabel={t("cancel") as string}
              onProceed={() => onProcedureDestroy(procedureToDelete.id)}
              onCancel={() => setProcedureToDelete(null)}
              onClose={() => setProcedureToDelete(null)}
              isDestructive
            />
          )}
        </ListContainer>
      )}

      <ProcedureLinkModal
        isOpen={modalOpen}
        onClose={onModalClose}
        onSubmit={linkProcedures}
        value={procedureTemplates}
        onChange={onSelect}
        isSubmitting={isLinkingInProgress}
        submitDisabled={procedureTemplates.length === 0}
      />
    </>
  );
};

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`;

const ListContainer = styled.ul`
  padding-left: 1rem;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 1;
  padding: 4px;
  height: 24px;
`;
