import * as Styled from "./ReportPeriodSelect.styled";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { useState } from "react";
import {
  offset,
  useFloating,
  shift,
  flip,
  FloatingFocusManager,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import { ReportPeriodSelectPopoverContent } from "./components/ReportPeriodSelectPopoverContent/ReportPeriodSelectPopoverContent";
import { useTranslation } from "react-i18next";
import DropdownIndicator from "shared/assets/icon/chevron-down-grey.svg?react";
import { format, parseISO } from "date-fns";

interface ReportPeriodSelectProps {
  selected: ReportPeriod;
  onChange: (value: ReportPeriod) => void;
  options: ReportPeriod[];
  disabled?: boolean;
}

/**
 * A popover for selecting a report period
 * Includes a quick select list with predefined options, and a custom range option
 */
export const ReportPeriodSelect = ({
  selected,
  onChange,
  options,
  disabled,
}: ReportPeriodSelectProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-end",
    middleware: [offset(5), shift(), flip()],
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  let rangeString;
  if (selected.value === "custom" && selected.startDate && selected.endDate) {
    const f = "yyyy-MM-dd";
    const startFormatted = format(parseISO(selected.startDate), f);
    const endFormatted = format(parseISO(selected.endDate), f);
    rangeString = `(${startFormatted} – ${endFormatted})`;
  }

  return (
    <>
      <Styled.Button
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={() => setIsOpen(!isOpen)}
        showOutline={isOpen}
        disabled={disabled}
      >
        <Styled.ButtonText>
          {t(`reporting.periodOptions.${selected.value}`)} {rangeString}
        </Styled.ButtonText>
        <DropdownIndicator />
      </Styled.Button>
      {isOpen && (
        <FloatingFocusManager context={context}>
          <Styled.Popover
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
          >
            <ReportPeriodSelectPopoverContent
              selected={selected}
              onApply={(value) => {
                onChange(value);
                setIsOpen(false);
              }}
              onCancel={() => setIsOpen(false)}
              options={[
                ...options,
                {
                  value: "custom",
                },
              ]}
            />
          </Styled.Popover>
        </FloatingFocusManager>
      )}
    </>
  );
};
