import { FilesPickerButton } from "modules/fileManager/components/FilesPicker";
import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { LightModal } from "shared/components/Modals/LightModal";
import { InlineLabel } from "shared/components/widgets";
import styled from "styled-components";
import { UserInfoContainer as Container } from "../styled";
import { Button } from "shared/components/Button";
import { Avatar } from "shared/components/Avatar";

export const Modal = ({ show, form, onClose, handleSubmit }) => {
  const { t } = useTranslation();

  const handlerSetFile = (selectedFiles) => {
    form.setFieldValue(
      "images",
      form.values.images[0]?.id
        ? [{ ...form.values?.images[0], isRemove: true }, ...selectedFiles]
        : [...selectedFiles]
    );
  };

  const handleDeleteFile = () => {
    form.setFieldValue(
      "images",
      form.values.images[0]?.id
        ? [{ ...form.values?.images[0], isRemove: true }]
        : []
    );
  };

  const avatars = form.values?.images.filter(({ isRemove }) => !isRemove);
  const avatar = avatars[0]?.url || avatars[0]?.attachment_urls?.small;

  return (
    <LightModal
      isOpen={show}
      onClose={onClose}
      onCancel={onClose}
      secondaryButtonLabel={t("discardChanges")}
      label={t("profile.personalInfo.modalTitle")}
      onSubmit={handleSubmit}
      disabledSubmit={!form.isValid}
    >
      <Box>
        <Container>
          <Avatar size="xxl" src={avatar} fallback="+" />
          <FilesPickerButton setFiles={handlerSetFile} />
          <Button
            disabled={!avatars.length}
            onClick={handleDeleteFile}
            variant="secondary"
          >
            {t("delete")}
          </Button>
        </Container>

        <InlineLabel label={t("profile.personalInfo.name")} labelWidth={110}>
          <Input
            name="name"
            value={form.values.name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("profile.personalInfo.name")}
            errorMessage={form.touched.name && form.errors.name}
            height={40}
            fontSize={14}
          />
        </InlineLabel>
        <InlineLabel label={t("profile.personalInfo.surname")} labelWidth={110}>
          <Input
            name="surname"
            value={form.values.surname}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("profile.personalInfo.surname")}
            errorMessage={form.touched.surname && form.errors.surname}
            height={40}
            fontSize={14}
          />
        </InlineLabel>
      </Box>
    </LightModal>
  );
};

const Box = styled.div`
  width: 480px;
  padding: 32px 16px;
`;
