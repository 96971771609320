import { useEffect } from "react";
import PropTypes from "prop-types";
import SuccessIcon from "shared/assets/success.svg?react";
import ErrorIcon from "shared/assets/error.svg?react";
import WarningIcon from "shared/assets/warning.svg?react";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { CloseButton } from "./CloseButton";
import {
  ActionBox,
  ContentBox,
  Heading,
  IconBox,
  Container,
  Wrapper,
  ContentWrapper,
  ButtonStyled,
} from "./SnackBar.styled";

const SNACK_BAR_TYPES = {
  success: {
    backgroundColor: "#B4F000",
    icon: <SuccessIcon />,
  },
  error: {
    backgroundColor: "#FF786E",
    icon: <ErrorIcon />,
  },
  warning: {
    backgroundColor: "#FFDC25",
    icon: <WarningIcon />,
  },
};

export const SnackBar = ({
  id,
  children,
  type,
  title,
  primaryButtonLabel,
  secondaryButtonLabel,
  handlePrimaryButtonClick,
}) => {
  const { removeSnackBar } = useSnackBar();

  useEffect(() => {
    const timer = setTimeout(() => {
      document.getElementById(`snackBar_${id}`).classList.add("hidden");
      setTimeout(() => {
        removeSnackBar(id);
      }, 1000);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeSnackBar]);

  return (
    <Wrapper id={`snackBar_${id}`}>
      <Container>
        <IconBox backgroundColor={SNACK_BAR_TYPES[type].backgroundColor}>
          {SNACK_BAR_TYPES[type].icon}
        </IconBox>
        <ContentWrapper>
          <div>
            {title && <Heading>{title}</Heading>}
            <ContentBox>{children}</ContentBox>
            <ActionBox>
              {primaryButtonLabel && (
                <ButtonStyled
                  variant="primary"
                  onClick={() => {
                    handlePrimaryButtonClick(id);
                    removeSnackBar(id);
                  }}
                >
                  {primaryButtonLabel}
                </ButtonStyled>
              )}
              {secondaryButtonLabel && (
                <ButtonStyled
                  onClick={() => removeSnackBar(id)}
                  variant="secondaryDark"
                >
                  {secondaryButtonLabel}
                </ButtonStyled>
              )}
            </ActionBox>
          </div>
          <div>
            <CloseButton onClick={() => removeSnackBar(id)} />
          </div>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

SnackBar.propTypes = {
  id: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.oneOf(["success", "error", "warning"]),
  title: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  handlePrimaryButtonClick: PropTypes.func,
};
