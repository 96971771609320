import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue, SingleValue } from "react-select";
import { EditorModal } from "shared/components/Modals";
import { SelectCreatable } from "shared/components/Select";
import { OptionType } from "shared/components/Select/SelectCreatable";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import styled from "styled-components";
import {
  useCreateProcedureTemplateMutation,
  useGetProcedureTemplatesQuery,
} from "../state/proceduresApi";
import { IProcedureTemplate } from "../types";
import { ProcedureTemplateEdit } from "./ProcedureTemplateModal/ProcedureTemplateEdit";

interface ProcedureLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  submitDisabled?: boolean;
  value: OptionType[];
  onChange: (
    _: string,
    value: MultiValue<OptionType> | SingleValue<OptionType>
  ) => void;
}

export const ProcedureLinkModal = ({
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  submitDisabled,
  value,
  onChange,
}: ProcedureLinkModalProps) => {
  const { t } = useTranslation();
  const captureException = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [modalTemplate, setModalTemplate] =
    useState<IProcedureTemplate | void>();

  const [createProcedureTemplate] = useCreateProcedureTemplateMutation();
  const { data } = useGetProcedureTemplatesQuery({
    search: searchTerm,
    per_page: 10000,
  });

  const options = useMemo(() => {
    const templates = data?.data || [];

    return templates.map((template: IProcedureTemplate) => ({
      label: template.name,
      value: template.id,
    }));
  }, [data?.data]);

  const createTemplateFromSearchTerm = async () => {
    if (!searchTerm) return;

    try {
      const response = await createProcedureTemplate({
        procedure_template: {
          name: searchTerm,
        },
      }).unwrap();
      setModalTemplate(response.data);
    } catch (e) {
      captureException(e, "Failed to create procedure template", {
        searchTerm,
      });
    }
  };

  return (
    <EditorModal
      label={t("procedures.link_procedure")}
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonLabel={t("procedures.attach_procedure")}
      onSubmit={onSubmit}
      isSubmitLoading={isSubmitting}
      disabledSubmit={submitDisabled}
    >
      <Container>
        <SelectCreatable
          onSearch={(value) => {
            setSearchTerm(value);
          }}
          options={options}
          value={value}
          setValue={onChange}
          isMulti
          name="procedure_templates"
          menuPlacement="bottom"
          preCreateHook={createTemplateFromSearchTerm}
          optionCreateModal={
            modalTemplate?.id ? (
              <ProcedureTemplateEdit procedureTemplateId={modalTemplate?.id} />
            ) : (
              <></>
            )
          }
        />
      </Container>
    </EditorModal>
  );
};

const Container = styled.div`
  height: 500px;
`;
