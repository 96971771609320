import React from "react";
import { useTranslation } from "react-i18next";
import { BulkDeletion } from "features/BulkDeletion";
import { BulkQRCodeExport } from "widgets/BulkQRCodeExport";
import { useDeletePartsMutation } from "modules/parts";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { usePermission } from "app/providers/PermissionsProvider";

export const BulkActions = ({ selectionModel }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();

  const [deleteParts] = useDeletePartsMutation();

  const { partsListExportQrCodesPermit, partsListDeletePermit } =
    usePermission();

  const deleteSuccessHandler = () => {
    addSnackBar({
      content: t("parts.bulkActions.delete.success", {
        count: selectionModel.length,
      }),
      type: "success",
    });
  };

  const deleteErrorHandler = (error) => {
    const names = [];
    error.forEach((error) => {
      names.push(error?.body?.record?.name || "");
    });
    addSnackBar({
      title: t("parts.bulkActions.delete.warningTitle"),
      content: t("parts.bulkActions.delete.warningContent", {
        names: names.join(", "),
      }),
      type: "error",
      secondaryButtonLabel: t("close"),
    });
  };

  return (
    <>
      {partsListExportQrCodesPermit && (
        <BulkQRCodeExport
          ids={selectionModel}
          selectedLabel={t("exportQRCodes.confirmation.selected", {
            count: selectionModel.length,
            module: "Parts",
          })}
          type="parts"
          module="Parts"
        />
      )}
      {partsListDeletePermit && (
        <BulkDeletion
          ids={selectionModel}
          onDelete={deleteParts}
          onSuccess={deleteSuccessHandler}
          onError={deleteErrorHandler}
          confirmation={{
            title: t("parts.bulkActions.delete.confirmation.title"),
            description: t(
              "parts.bulkActions.delete.confirmation.description",
              {
                count: selectionModel.length,
              }
            ),
            confirmButtonLabel: t(
              "parts.bulkActions.delete.confirmation.confirmButtonLabel",
              { count: selectionModel.length }
            ),
          }}
        />
      )}
    </>
  );
};
