import NoneIcon from "../assets/icons/none.svg?react";
import LowIcon from "../assets/icons/low.svg?react";
import MediumIcon from "../assets/icons/medium.svg?react";
import HighIcon from "../assets/icons/high.svg?react";
import CriticalIcon from "../assets/icons/critical.svg?react";

import NonePMIcon from "../assets/icons/none_pm.svg?react";
import LowPMIcon from "../assets/icons/low_pm.svg?react";
import MediumPMIcon from "../assets/icons/medium_pm.svg?react";
import HighPMIcon from "../assets/icons/high_pm.svg?react";
import CriticalPMIcon from "../assets/icons/critical_pm.svg?react";

// for lists and PDFs
import NoneIconUrl from "../assets/icons/none.svg?url";
import LowIconUrl from "../assets/icons/low.svg?url";
import MediumIconUrl from "../assets/icons/medium.svg?url";
import HighIconUrl from "../assets/icons/high.svg?url";
import CriticalIconUrl from "../assets/icons/critical.svg?url";

export const PriorityMap = {
  none: NoneIcon,
  none_pm: NonePMIcon,
  low: LowIcon,
  low_pm: LowPMIcon,
  medium: MediumIcon,
  medium_pm: MediumPMIcon,
  high: HighIcon,
  high_pm: HighPMIcon,
  critical: CriticalIcon,
  critical_pm: CriticalPMIcon,
};

export const PriorityIconUrlMap = {
  none: NoneIconUrl,
  low: LowIconUrl,
  medium: MediumIconUrl,
  high: HighIconUrl,
  critical: CriticalIconUrl,
};
