import { ReactNode, useReducer, createContext, Dispatch } from "react";

export interface ProcedureAction {
  type: "isSaving" | "error";
  value: unknown;
}

interface ProcedureState {
  isSaving: boolean;
  error: string | null;
}

interface ProceduresProviderProps {
  children: ReactNode;
}

const initialState: ProcedureState = {
  isSaving: false,
  error: null,
};

const procedureReducer = (state: ProcedureState, action: ProcedureAction) => {
  switch (action.type) {
    case "isSaving":
      return { ...state, isSaving: action.value as boolean };

    case "error":
      return { ...state, error: action.value as string };

    default:
      return state;
  }
};

export const ProceduresContext = createContext<{
  state: ProcedureState;
  dispatch: Dispatch<ProcedureAction>;
}>({
  state: initialState,
  dispatch: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export function ProceduresProvider({ children }: ProceduresProviderProps) {
  const [state, dispatch] = useReducer(procedureReducer, initialState);

  return (
    <ProceduresContext.Provider value={{ state, dispatch }}>
      {children}
    </ProceduresContext.Provider>
  );
}
