import React from "react";
import styled, { css } from "styled-components";
import DragKnobDots from "../../assets/drag-knob-dots.svg?react";

export const DragKnob = (props) => {
  return (
    <Box {...props}>
      <DragKnobDots />
    </Box>
  );
};

const Box = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--color_grey100);
  background-repeat: no-repeat;
  color: var(--color_primary_text);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  svg {
    margin-top: -1px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;

      &:hover,
      &:active {
        cursor: default;
      }
    `}
`;
