import React from "react";
import { useTranslation } from "react-i18next";

import { BulkPriority, BulkAssignees } from "modules/workOrders";
import { BulkPdfExport } from "shared/components/BulkPdfExport";
import {
  useUpdatePMsPriorityMutation,
  useUpdatePMsAssignesMutation,
  useUpdatePMsStatusMutation,
  usePrintPMsMutation,
} from "modules/workOrders";
import { BulkStatus } from "./BulkStatus";
import PrintPMsPdfExample from "../../assets/PrintPMsPdfExample.svg?react";
import { PMsPdfPage } from "../PMsPdfPage";
import { usePermission } from "app/providers/PermissionsProvider";

export const BulkActions = ({ selectionModel }) => {
  const { t } = useTranslation();
  const {
    pmsPrintPermit,
    canChangeAssigneePms,
    canChangePriorityPms,
    pmsCanPauseResumePms,
  } = usePermission();
  const count = selectionModel.length;

  const [updatePMsPriority] = useUpdatePMsPriorityMutation();
  const [updatePMsAssignes] = useUpdatePMsAssignesMutation();
  const [updatePMsStatus] = useUpdatePMsStatusMutation();
  const [printPMs] = usePrintPMsMutation();

  return (
    <>
      {pmsCanPauseResumePms && (
        <BulkStatus
          ids={selectionModel}
          onUpdate={updatePMsStatus}
          status="planned"
          count={count}
        />
      )}

      {pmsCanPauseResumePms && (
        <BulkStatus
          ids={selectionModel}
          onUpdate={updatePMsStatus}
          status="paused"
          count={count}
        />
      )}

      {canChangeAssigneePms && (
        <BulkAssignees
          ids={selectionModel}
          count={count}
          onUpdate={updatePMsAssignes}
          module="preventive_maintenances"
        />
      )}
      {canChangePriorityPms && (
        <BulkPriority
          ids={selectionModel}
          count={count}
          onUpdate={updatePMsPriority}
          module="preventive_maintenances"
        />
      )}

      {pmsPrintPermit && (
        <BulkPdfExport
          ids={selectionModel}
          selectedLabel={t("printPMs.confirmation.selected", { count })}
          type="button"
          title={t("printPMs.confirmation.bulkTitle")}
          resourceName="PMs"
          getExportResources={printPMs}
          PdfExampleSvg={PrintPMsPdfExample}
          PageComponent={PMsPdfPage}
        />
      )}
    </>
  );
};
