import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { NoData } from "modules/reporting/components/NoData";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "modules/reporting/lib/formatters";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { ReportWidgetTable } from "modules/reporting/components/ReportWidgetTable";
import Skeleton from "react-loading-skeleton";
import { times } from "lodash";

interface AssetCostsAndDowntimeReportWidgetData {
  chart_data: RowData[];
  other_data: {
    total_downtime: number;
  };
}

interface RowData {
  asset_name: string;
  online_pct: number;
  cost: number;
}

export const AssetCostsAndDowntimeReportWidget = () => {
  const { language, currency } = useLocaleSettings();

  const { reportPeriod } = useReportPageContext();
  let { data, isFetching } =
    useWidgetData<AssetCostsAndDowntimeReportWidgetData>(
      ReportKey.AssetCostsAndDowntime,
      reportPeriod
    );

  if (isFetching) {
    return <WidgetSkeleton />;
  } else if (!data?.chart_data) {
    return <NoData />;
  }

  return (
    <Wrapper>
      <Value>
        {formatNumber(data?.other_data?.total_downtime, language)} h
      </Value>
      <ReportWidgetTable.Table>
        <TableHeader />
        <ReportWidgetTable.Tbody>
          {data.chart_data.map((row, index) => (
            <ReportWidgetTable.Tr key={index}>
              <ReportWidgetTable.Td>{row.asset_name}</ReportWidgetTable.Td>
              <ReportWidgetTable.Td align="right">
                {formatPercent(row.online_pct, language)}
              </ReportWidgetTable.Td>
              <ReportWidgetTable.Td align="right">
                {formatCurrency(row.cost, language, currency)}
              </ReportWidgetTable.Td>
            </ReportWidgetTable.Tr>
          ))}
        </ReportWidgetTable.Tbody>
      </ReportWidgetTable.Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Value = styled.p`
  margin: 0 0 16px 0;
  font-size: 32px;
`;

const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <ReportWidgetTable.Thead>
      <ReportWidgetTable.Tr>
        <ReportWidgetTable.Th align="left">
          {t(`reporting.${ReportKey.AssetCostsAndDowntime}.widget.name`)}
        </ReportWidgetTable.Th>
        <ReportWidgetTable.Th align="right">
          {t(`reporting.${ReportKey.AssetCostsAndDowntime}.widget.online`)}
        </ReportWidgetTable.Th>
        <ReportWidgetTable.Th align="right">
          {t(`reporting.${ReportKey.AssetCostsAndDowntime}.widget.cost`)}
        </ReportWidgetTable.Th>
      </ReportWidgetTable.Tr>
    </ReportWidgetTable.Thead>
  );
};

const WidgetSkeleton = () => {
  return (
    <Wrapper>
      <Value>
        <Skeleton height="1em" width="8ch" />
      </Value>
      <ReportWidgetTable.Table>
        <TableHeader />
        <ReportWidgetTable.Tbody>
          {times(10, (index) => (
            <ReportWidgetTable.Tr key={index}>
              <ReportWidgetTable.Td>
                <Skeleton height="1em" width="15ch" />
              </ReportWidgetTable.Td>
              <ReportWidgetTable.Td align="right">
                <Skeleton height="1em" width="4ch" />
              </ReportWidgetTable.Td>
              <ReportWidgetTable.Td align="right">
                <Skeleton height="1em" width="8ch" />
              </ReportWidgetTable.Td>
            </ReportWidgetTable.Tr>
          ))}
        </ReportWidgetTable.Tbody>
      </ReportWidgetTable.Table>
    </Wrapper>
  );
};
