import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DescriptionBox,
  Title,
  Description,
  DividerStyled,
  StyledSwitch,
} from "../AdminFieldsConfiguration/AdminFieldsConfiguration.styled";

export const AdditionalFieldConfiguration = ({
  field,
  type = "",
  activated,
  onSetRequired,
  withDescription = true,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t(`settings.labels.${type}.title`)}</Title>
      <StyledSwitch onClick={() => onSetRequired(field)} activated={activated}>
        {t(`settings.labels.${type}.label`)}
      </StyledSwitch>

      {withDescription && (
        <DescriptionBox>
          <Description>{t(`settings.labels.${type}.description`)}</Description>
        </DescriptionBox>
      )}

      <DividerStyled />
    </>
  );
};

AdditionalFieldConfiguration.propTypes = {
  activated: PropTypes.bool,
  onSetRequired: PropTypes.func,
  field: PropTypes.string,
  type: PropTypes.string,
  withDescription: PropTypes.bool,
};
