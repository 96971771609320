import { ReportKey } from "modules/reporting/types/ReportKey";
import { BacklogByStatusReportChart } from "./BacklogByStatusReportChart";
import { BacklogByStatusReportTable } from "./BacklogByStatusReportTable";
import { useBacklogByPriorityKPIs } from "modules/reporting/components/reports/BacklogByPriorityReport/useBacklogByPriorityKPIs";
import { BacklogByStatusReportActionBar } from "./BacklogByStatusReportActionBar";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import { BacklogByStatusChartData, BacklogByStatusTableData } from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useRef } from "react";

const reportKey = ReportKey.BacklogByStatus;
export const BacklogByStatusReport = () => {
  const { t } = useTranslation();
  // Currently exactly the same as the BacklogByPriorityReport, so just use that
  const kpiRes = useBacklogByPriorityKPIs();
  const chartRes = useGetChartData<BacklogByStatusChartData[]>(reportKey);
  const tableRes = useGetTableData<BacklogByStatusTableData[]>(reportKey);
  const downloadXLS = useDownloadReportXLS(ReportKey.BacklogByStatus);
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider
      value={{ reportPeriod: reportPeriodCurrent, setReportPeriod: () => null }}
    >
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <BacklogByStatusReportChart
            data={chartRes.data}
            isFetching={chartRes.isFetching}
          />
        }
        actionBar={
          <BacklogByStatusReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
          />
        }
        table={
          <BacklogByStatusReportTable
            data={tableRes.data?.data}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf")}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod: reportPeriodCurrent,
          kpis: kpiRes.data,
        }}
        charts={[
          {
            title: t(`reporting.${reportKey}.title`) as string,
            chart: (
              <div style={{ width: "1000px", height: "600px" }}>
                <BacklogByStatusReportChart
                  data={chartRes.data}
                  isFetching={chartRes.isFetching}
                />
              </div>
            ),
          },
        ]}
      />
    </ReportPageProvider>
  );
};
