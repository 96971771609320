import React from "react";
import { Switch } from "shared/components/Switch";
import styled from "styled-components";

export const Setting = ({
  handleNotificationUpdate,
  settingKey,
  settingValue,
  description,
}) => {
  return (
    <Row>
      <Switch
        onClick={() => handleNotificationUpdate(settingKey, !settingValue)}
        activated={settingValue}
      />
      <Description>{description}</Description>
    </Row>
  );
};

export const Row = styled.div`
  display: flex;
  margin: 12px 0;
`;

export const Description = styled.span`
  margin-left: 8px;
`;
