import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";

import { partValidationSchema } from "../../configs/validationSchema";
import { WorkOrderPartEditor } from "../WorkOrderPartEditor";

export const WorkOrderPartEdit = ({
  onUpdate,
  onDelete,
  onClose,
  assetId,
  value,
  id,
  index,
  module = "work order",
  disabled,
}) => {
  const { t } = useTranslation();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const determinant = id || index;

  const closeModal = () => {
    editPartForm.resetForm();
    onClose();
  };

  const editPartForm = useForm({
    initialValues: value,
    validationSchema: partValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (body) => {
      onUpdate(body, determinant);
      closeModal();
    },
  });

  const onOpenDeleteModal = () => {
    setIsShowDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
  };

  const onDeleteHandle = () => {
    onDelete(determinant);
    onCloseDeleteModal();
    closeModal();
  };

  return (
    <>
      <ConfirmationDialog
        title={t(`work_order_parts.edit`)}
        confirmButtonLabel={t("save")}
        cancelButtonLabel={t("cancel")}
        form={editPartForm}
        disabledSubmit={
          !editPartForm.dirty || !!Object.values(editPartForm.errors).length
        }
        description={
          <WorkOrderPartEditor
            form={editPartForm}
            assetId={assetId}
            type="edit"
          />
        }
        onProceed={editPartForm.handleSubmit}
        onCancel={closeModal}
        onDelete={onOpenDeleteModal}
      />
      {isShowDeleteModal && (
        <ConfirmationDialog
          title={t("work_order_parts.delete.confirmation.title", { module })}
          description={t("work_order_parts.delete.confirmation.description", {
            module,
          })}
          confirmButtonLabel={t("work_order_parts.delete.confirmation.confirm")}
          onProceed={onDeleteHandle}
          onCancel={onCloseDeleteModal}
        />
      )}
    </>
  );
};
