import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/components/Button";
import { Header } from "../Header";
import { Box, Container, Title } from "../styled";

export const InvitationExpired = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Container>
        <Box id="invitationExpired">
          <Title>{t("auth.signUp.invitationExpiredTitle")}</Title>
          <p>{t("auth.signUp.invitationExpiredDescription")}</p>

          <Button
            type="primary"
            size="normal"
            onClick={() => navigate("/login")}
          >
            {t("auth.signUp.ok")}
          </Button>
        </Box>
      </Container>
    </>
  );
};
