import styled, { css } from "styled-components";
import { IconButton } from "shared/components/IconButton";
import { FileManager } from "modules/fileManager";

export const CellStyle = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Cell = styled.div`
  ${CellStyle}
`;

export const GeneralCell = styled.div`
  ${CellStyle}
  text-align: end;
`;

export const Box = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 284px repeat(2, 1fr);
  gap: 0 10px;

  &:hover {
    background: var(--color_primary_hover);
    grid-template-columns: 284px repeat(2, 1fr) 5%;

    & .actionIcon {
      display: flex;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  display: none;
  top: 16px;
  right: 16px;
  align-items: start;
  padding-top: 2px;
`;

export const Note = styled.div`
  ${CellStyle}
  font-size: 12px;
  line-height: 16px;
  color: var(--color_grey600);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const WrapperFileManager = styled(FileManager)`
  & .imageList {
    gap: 4px;
    flex-wrap: nowrap;

    & > div,
    & > button {
      width: 92px;
      height: 92px;
    }
  }
`;
