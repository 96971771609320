import styled from "styled-components";

export const Heading = styled.span`
  margin: 0;
  padding: 0;
  color: var(--color_background);
`;

export const H1 = styled(Heading).attrs({ as: "h1" })`
  font-size: 32px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.02em;
`;

export const H2 = styled(Heading).attrs({ as: "h2" })`
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
`;

export const H3 = styled(Heading).attrs({ as: "h3" })`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_text_color);
`;

export const H6 = styled(Heading).attrs({ as: "h6" })`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

export const Description = styled.p`
  color: var(--color_text_color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0;
  white-space: pre-wrap;
`;

export const Hint = styled.p`
  color: var(--color_grey500);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
`;

export const InlineHint = styled.span`
  color: var(--color_grey500);
  margin: 0;
`;
