import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PriorityMap } from "./config/constants";

export const Priority = ({ value }) => {
  const { t } = useTranslation();
  const Icon = PriorityMap[value];

  return (
    <PriorityContainer>
      <IconContainer>
        <Icon />
      </IconContainer>
      <span>{t(`work_orders.priority.${value}`)}</span>
    </PriorityContainer>
  );
};

const PriorityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-falimy: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  gap: 4px;
  color: var(--color_grey700);
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;
