export const getFormDataFromJSON = (JSON) => {
  const formData = new FormData();

  Object.keys(JSON).forEach((key) => {
    const value = JSON[key];
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((item) => {
          if (typeof item !== "object") {
            formData.append(`${key}[]`, item);
          } else {
            Object.keys(item).forEach((objectKey) => {
              formData.append(`${key}[][${objectKey}]`, item[objectKey]);
            });
          }
        });
      } else formData.append(`${key}[]`, value);
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const addFilesForFormData = (formData, data) => {
  data?.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      formData.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj.file) {
      formData.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    }
  });
};

export const addQrCodeForFormData = (formData, code, options) => {
  if (code && code.length > 0 && !options.generateQr) {
    formData.append("qr_code_attributes[value]", code);
  }

  if (code === "" && !options.generateQr) {
    formData.append("qr_code_attributes[_destroy_qr_code]", "true");
  }

  if (options.generateQr) {
    formData.append("qr_code_attributes[generate_qr_code]", "true");
  }
};
