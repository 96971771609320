import { useMemo } from "react";
import { useDisplayDate } from "shared/lib/hooks";
import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";
import { useTranslation } from "react-i18next";
import { ProcedureTitle } from "../components/List/ProcedureTitle";

interface ProceduresTableToolsProps {
  onOpen: (rowId: string | number) => void;
}

export const useProceduresTableTools = ({
  onOpen,
}: ProceduresTableToolsProps) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("procedure_template");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    return [
      {
        field: "name",
        name: t("procedures.columns.name"),
        renderCell: (row: { id: string | number }, value: string) => (
          <ProcedureTitle onClick={() => onOpen(row.id)} value={value} />
        ),
        sortable: true,
        primary: true,
        order: 0,
        width: 250,
      },
      {
        field: "description",
        name: t("procedures.columns.description"),
        sortable: false,
        order: 1,
        hidden: !settings?.description?.visible,
        width: 500,
      },
      {
        field: "assignments",
        name: t("procedures.columns.assignments.title"),
        sortable: false,
        width: 250,
        formatValue: (assignments: { target_type: string }[]) => {
          const numWorkOrderAssignments = assignments.filter(
            (a) => a.target_type === "WorkOrder"
          ).length;
          const numPmAssignments = assignments.filter(
            (a) => a.target_type === "PmSchedule"
          ).length;

          return [
            t("procedures.columns.assignments.work_order", {
              count: numWorkOrderAssignments,
            }),
            t("procedures.columns.assignments.pm_schedule", {
              count: numPmAssignments,
            }),
          ].join(" & ");
        },
        hidden: !settings?.assignments?.visible,
      },
      {
        field: "created_at",
        name: t("procedures.columns.created_at"),
        width: 200,
        sortable: true,
        formatValue: (value: string) => displayDate(value, true),
        order: 2,
        hidden: !settings?.created_at?.visible,
      },
      {
        field: "updated_at",
        name: t("procedures.columns.updated_at"),
        width: 200,
        sortable: true,
        formatValue: (value: string) => displayDate(value, true),
        order: 3,
        hidden: !settings?.updated_at?.visible,
      },
      {
        field: "created_by",
        name: t("procedures.columns.created_by"),
        sortable: false,
        formatValue: (user: { name?: string; surname?: string }) =>
          user && `${user.name || ""} ${user.surname || ""}`,
        order: 4,
        hidden: !settings?.created_by?.visible,
      },
      {
        field: "updated_by",
        name: t("procedures.columns.updated_by"),
        sortable: false,
        formatValue: (user: { name?: string; surname?: string }) =>
          user && `${user.name || ""} ${user.surname || ""}`,
        order: 5,
        hidden: !settings?.updated_by?.visible,
      },
    ];
  }, [displayDate, onOpen, t, tableSettings?.data.payload]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};
