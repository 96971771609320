import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  // min-height: 30px;
  padding: 8px 12px 8px 30px;
  align-items: center;
  margin: 4px;
  border-radius: 4px;
  line-height: 16px;
  &:hover {
    background: var(--color_primary_hover);
  }
  ${({ selected }) =>
    selected &&
    css`
      background: var(--color_primary_hover);
    `}
`;

export const IconWrapper = styled.div`
  ${({ isEnd }) =>
    isEnd &&
    css`
      margin-left: auto;
      margin-right: 8px;
    `}
  ${({ isStart }) =>
    isStart &&
    css`
      position: absolute;
      left: 15px;
    `}
`;
