import { ReactNode } from "react";

interface ShiftByProps {
  children: ReactNode;
  x?: number;
  y?: number;
}

/**
 * Simple utility component for shifting child elements a little.
 *
 * https://www.joshwcomeau.com/snippets/react-components/shift-by/
 */
export const ShiftBy = ({ x = 0, y = 0, children }: ShiftByProps) => {
  return (
    <div
      style={{
        transform: `translate(${x}px, ${y}px)`,
      }}
    >
      {children}
    </div>
  );
};
