import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { H1 } from "shared/components/Typography";
import { Button } from "shared/components/Button";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();
  const msg = `404: ${window.location}`;
  Sentry.captureMessage(msg);

  return (
    <Wrapper>
      <H1>404</H1>
      <p>Page not found</p>
      <Button onClick={() => navigate(-1)}>Go back</Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
