import React from "react";
import { useTranslation } from "react-i18next";

import { Box, ButtonsBox, CustomButton } from "./UserDetailsView.styled";

import { HeaderContextMenu } from "shared/components/Modals";

import { ChangeStatus, DeleteAccessToEntity } from "../ChangeUserStatus";

const statusMap = {
  user: { active: "inactive", inactive: "active" },
  entityUser: "removeAccess",
};

export const UserDetailsHeader = ({
  user,
  onEdit,
  type = "user",
  currentEntity,
}) => {
  const { status, full_name, id } = user;
  const { t } = useTranslation();

  return (
    <Box>
      <ButtonsBox>
        <CustomButton variant="secondary" onClick={onEdit}>
          {t("edit")}
        </CustomButton>
        <HeaderContextMenu>
          {type === "user" ? (
            <ChangeStatus
              status={statusMap[type][status]}
              fullName={full_name}
              id={id}
            />
          ) : (
            <DeleteAccessToEntity
              user={user}
              title={t(`allUsers.make.${statusMap[type]}`)}
              currentEntity={currentEntity}
            />
          )}
        </HeaderContextMenu>
      </ButtonsBox>
    </Box>
  );
};
