import LogoIcon from "modules/auth/assets/logoBlueHorizontal.svg?react";
import { Link } from "shared/components/Link";
import { Header as StyledHeader } from "../styled";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const Header = ({ showAction = false, action = null }) => {
  const { t } = useTranslation();

  const chosenAction = action || (
    <StyledLink type="button" to="/login">
      {t("auth.login.title")}
    </StyledLink>
  );

  return (
    <StyledHeader>
      <Logo />
      {showAction && chosenAction}
    </StyledHeader>
  );
};

const StyledLink = styled(Link)`
  border-radius: 10px;
`;

const Logo = styled(LogoIcon)`
  height: 35px;
  width: auto;
`;
