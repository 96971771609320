import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const downtimeTrackingApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDowntimeTrackingSettings: builder.query({
      query: ({ targetType }) => `/downtime_trackings/${targetType}/settings`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "DowntimeTracking", id: "DowntimeTrackingDetails" },
      ],
    }),
    updateDowntimeTrackingSetting: builder.mutation({
      query: ({ targetType, body }) => ({
        url: `admin/downtime_trackings/${targetType}/settings`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: () => [
        "FieldConfiguration",
        "Asset",
        { type: "DowntimeTracking", id: "DowntimeTrackingDetails" },
      ],
    }),
    getDowntimeHistoryList: builder.query({
      query: ({ targetType, id, ...params }) => ({
        url: `/downtime_trackings/${targetType}/${id}`,
        params,
      }),
      providesTags: (result) => [
        { type: "DowntimeTracking", id: "DowntimeTrackingsList" },
        ...getTagList("DowntimeTracking", result?.data),
      ],
    }),
  }),
});

export const {
  useGetDowntimeTrackingSettingsQuery,
  useUpdateDowntimeTrackingSettingMutation,
  useGetDowntimeHistoryListQuery,
} = downtimeTrackingApi;
