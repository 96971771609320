import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "widgets/Header";
import { Container } from "shared/components/Container/Container";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { usePermission } from "app/providers/PermissionsProvider";

import AddIcon from "shared/assets/icon/add.svg?react";
import { List as InventoryPartsList } from "modules/parts/components/List";
import { ModalLink } from "shared/components/ModalLink";
import { useSetModal } from "widgets/ModalController";

export const PartsPage = () => {
  const { t } = useTranslation();
  const setModal = useSetModal();
  const { partCreatePermit, partsListPermit, partsListExportPermit } =
    usePermission();

  return (
    <TablePageLayout>
      {partsListPermit && (
        <>
          <Header
            title={t("parts.title")}
            searchPlaceholder={t("parts.searchPlaceholder")}
            action={
              partCreatePermit && (
                <ModalLink
                  type="button"
                  icon={<AddIcon />}
                  modalParams={{ type: "part", id: "new" }}
                >
                  {t("parts.addButton")}
                </ModalLink>
              )
            }
            horizontallySpaciousPage
          />
          <Container>
            <InventoryPartsList
              onOpenPart={(id) => setModal({ type: "part", id })}
              withExport={partsListExportPermit}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
