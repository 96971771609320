import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import LogoArrows from "./assets/Logo-arrows.svg?react";
import LogoText from "./assets/Logo-text.svg?react";

/**
 * The Click Maint SVG logo as a component. Allows greater conrol over props and transitions.
 *
 * @param {Logo.PropTypes} props
 */
export const Logo = ({ showText }) => {
  return (
    <Wrapper showText={showText} aria-hidden>
      <LogoArrows />
      <Text $showText={showText} />
    </Wrapper>
  );
};

Logo.propTypes = {
  showText: PropTypes.bool,
};

const Wrapper = styled.div`
  margin: 20px ${(p) => (p.showText ? "12px" : 0)} 6px;
  transform: scale(${(p) => (p.showText ? 1 : 1.5)});
  transform-origin: top center;
  transition: transform 0.5s, margin 0.5s;

  svg {
    width: 100%;
  }
`;

const Text = styled(LogoText)`
  transform-origin: top center;
  transition: all 0.25s;

  ${(p) => {
    if (p.$showText) {
      return css`
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.25s;
      `;
    } else {
      return css`
        opacity: 0;
        transform: scale(0.5);
      `;
    }
  }}
`;
