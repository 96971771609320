import styled from "styled-components";
import NoDataIcon from "modules/reporting/assets/no-data.svg?react";
import { useTranslation } from "react-i18next";

/** "No Data" message for expanded report pages */
export const ReportTablePlaceholder = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <NoDataIcon />
      <Header>{t("reporting.noDataHeader")}</Header>
      <Detail>{t("reporting.noDataDetail")}</Detail>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin-top: 42px;
`;

const Header = styled.h3`
  color: var(--color_grey700);
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  margin: 16px 0 0 0;
`;

const Detail = styled.div`
  color: var(--color_grey700);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  max-width: 225px;
`;
