import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useForm } from "shared/lib/hooks/useForm";
import { getUser } from "shared/components/Auth";

import { timeValidationSchema } from "../../configs/validationSchema";
import { WorkOrderTimeEditor } from "../WorkOrderTimeEditor";
import { usePermission } from "app/providers/PermissionsProvider";

export const WorkOrderTimeCreate = ({
  onCreate,
  onClose,
  usersOptions,
  onUsersSearch,
}) => {
  const { canShowUserField } = usePermission();
  const { t } = useTranslation();
  const { id: userId } = getUser();

  const closeModal = () => {
    addTimeForm.resetForm();
    onClose();
  };

  const isShowUserField = canShowUserField();

  const addTimeForm = useForm({
    initialValues: {
      amount: "",
      rate_type: "regular_rate",
      user: isShowUserField
        ? null
        : usersOptions?.find(({ value }) => value === userId),
    },
    validationSchema: timeValidationSchema(t, isShowUserField ? "" : "stop"),
    enableReinitialize: true,

    onSubmit: (body) => {
      onCreate(body);
      closeModal();
    },
  });

  return (
    <ConfirmationDialog
      title={t(`work_order_time.add`)}
      confirmButtonLabel={t("save")}
      cancelButtonLabel={t("cancel")}
      form={addTimeForm}
      disabledSubmit={
        !addTimeForm.dirty || !!Object.values(addTimeForm.errors).length
      }
      description={
        <WorkOrderTimeEditor
          usersOptions={usersOptions}
          onUsersSearch={onUsersSearch}
          form={addTimeForm}
        />
      }
      onProceed={addTimeForm.handleSubmit}
      onCancel={closeModal}
    />
  );
};
