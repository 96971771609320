import styled from "styled-components";
import { getStockOptions } from "../../lib/helpers";

export const StockLevel = ({
  onHand = 0,
  min = 0,
  nonStock = false,
  desired = 0,
  width = "100%",
}) => {
  const { percentageColor, containerColor, percentage } = getStockOptions({
    onHand,
    min,
    nonStock,
    desired,
  });

  return (
    <StockContainer containerColor={containerColor} width={width}>
      <StockPercentage
        width={Number.isFinite(percentage) ? percentage : 0}
        percentageColor={percentageColor}
      />
    </StockContainer>
  );
};

const StockContainer = styled.div`
  height: 6px;
  width: ${({ width }) => width};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: ${({ containerColor }) => containerColor};
  overflow: hidden;
`;

const StockPercentage = styled.div`
  width: ${({ width }) => width}%;
  background-color: ${({ percentageColor }) => percentageColor};
  border-radius: 4px 0 0 4px;
`;
