import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  z-index: 4;
  padding: 0;
  margin: 0;
  overflow: auto;

  background: var(--color_background_light);
  border-radius: 5px;
  border: 1px solid var(--color_grey200);
  box-shadow: 0px 0px 3px 2px rgba(19, 20, 20, 0.1);
`;

export const Wrapper = styled.div`
  width: 100%;
  z-index: 4;
`;

export const CategoryWrapper = styled.div`
  border-bottom: 1px solid var(--color_grey200);
`;

export const CategoryLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: 1px solid var(--color_grey900);
  padding: 8px 12px;
`;

export const Triangle = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  left: 50%;
  top: -1px;
  transform: translate(-50%, -50%) rotate(45deg);
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(191, 191, 195);
  border-left: 1px solid rgb(191, 191, 195);
`;
