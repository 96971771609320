import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { CommentsList } from "modules/comments";
import { Tabs } from "shared/components/Tabs";
import { usePermission } from "app/providers/PermissionsProvider";
import { WorkOrderHistoryList } from "./WorkOrderHistoryList";

export const WOViewTabs = ({ comments = [], histories = [], targetId }) => {
  const { t } = useTranslation();
  const {
    woCommentListPermit,
    woCreateCommentPermit,
    woUpdateCommentPermit,
    woDeleteCommentPermit,
    woHistoryListPermit,
  } = usePermission();

  const count = comments.length > 0 ? `(${comments.length})` : "";

  const tabs = Object.values({
    ...(woCommentListPermit && {
      comments: {
        label: t("work_orders.tabs.comments", { count }),
        component: (
          <CommentsList
            value={comments}
            targetId={targetId}
            type="WorkOrder"
            permissions={{
              create: woCreateCommentPermit,
              update: woUpdateCommentPermit,
              delete: woDeleteCommentPermit,
            }}
          />
        ),
      },
    }),
    ...(woHistoryListPermit && {
      history: {
        label: t("work_orders.tabs.history"),
        component: <WorkOrderHistoryList value={histories} />,
      },
    }),
  });

  if (!tabs?.length) {
    return null;
  }

  return (
    <Tabs.Root>
      <Tabs.TabList>
        {tabs.map(({ label }) => (
          <Tabs.Tab key={label} id={label}>
            {label}
          </Tabs.Tab>
        ))}
      </Tabs.TabList>
      {tabs.map(({ label, component }) => (
        <Tabs.TabPanel key={label} id={label}>
          {component}
        </Tabs.TabPanel>
      ))}
    </Tabs.Root>
  );
};

WOViewTabs.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      created_at: PropTypes.string,
      id: PropTypes.number,
      target_id: PropTypes.number,
      target_type: PropTypes.string,
    })
  ),
};
