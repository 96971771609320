import React from "react";
import { useTranslation } from "react-i18next";
import { BulkDeletion } from "features/BulkDeletion";
import { BulkLocationSetting } from "features/BulkLocationSetting";
import { useGetDowntimeTrackingSettingsQuery } from "modules/downtimeTracking";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { BulkQRCodeExport } from "widgets/BulkQRCodeExport";
import {
  useDeleteAssetsMutation,
  useSetAssetsLocationMutation,
  useUpdateAssetsMutation,
} from "modules/assets/state/assetsApi";

import { usePermission } from "app/providers/PermissionsProvider";
import { BulkStatus } from "./BulkStatus";

export const BulkActions = ({ selectionModel }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();

  const { data: downtimeTrackingSettings } =
    useGetDowntimeTrackingSettingsQuery({
      targetType: "asset",
    });

  const [deleteAssets] = useDeleteAssetsMutation();
  const [setAssetLocation] = useSetAssetsLocationMutation();
  const [updateAssetsStatus] = useUpdateAssetsMutation();

  const {
    assetsListExportQrCodesPermit,
    assetsListEditPermit,
    assetsListDeletePermit,
  } = usePermission();

  const count = selectionModel.length;

  const locationSettingSuccessHandler = (locationName) => {
    addSnackBar({
      title: t("assets.bulkActions.setLocation.success", {
        count,
        locationName,
      }),
      type: "success",
    });
  };

  const deletingSuccessHandler = () => {
    addSnackBar({
      content: t("assets.bulkActions.delete.success", { count }),
      type: "success",
    });
  };

  const deleteErrorHandler = (error) => {
    const names = [];
    error.forEach((error) => {
      names.push(error?.body?.record?.name || "");
    });
    addSnackBar({
      title: t("assets.bulkActions.delete.warningTitle"),
      content: t("assets.bulkActions.delete.warningContent", {
        names: names.join(", "),
      }),
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
  };

  return (
    <>
      {assetsListEditPermit && downtimeTrackingSettings?.enabled && (
        <BulkStatus
          ids={selectionModel}
          count={count}
          onUpdate={updateAssetsStatus}
        />
      )}
      {assetsListExportQrCodesPermit && (
        <BulkQRCodeExport
          ids={selectionModel}
          selectedLabel={t("exportQRCodes.confirmation.selected", {
            count,
            module: "Assets",
          })}
          type="assets"
          module="Assets"
        />
      )}
      {assetsListEditPermit && (
        <BulkLocationSetting
          ids={selectionModel}
          confirmation={{
            title: t("assets.bulkActions.setLocation.confirmation.title"),
            confirmButtonLabel: t("save"),
            cancelButtonLabel: t("discardChanges"),
          }}
          selectedLabel={t(
            "assets.bulkActions.setLocation.confirmation.selected_one",
            { count }
          )}
          newLabel={t(
            "assets.bulkActions.setLocation.confirmation.newLocation"
          )}
          onSet={setAssetLocation}
          onSuccess={locationSettingSuccessHandler}
        />
      )}
      {assetsListDeletePermit && (
        <BulkDeletion
          ids={selectionModel}
          onDelete={deleteAssets}
          onSuccess={deletingSuccessHandler}
          onError={deleteErrorHandler}
          confirmation={{
            title: t("assets.bulkActions.delete.confirmation.title"),
            description: t(
              "assets.bulkActions.delete.confirmation.description",
              {
                count,
              }
            ),
            confirmButtonLabel: t(
              "assets.bulkActions.delete.confirmation.confirmButtonLabel",
              { count }
            ),
          }}
        />
      )}
    </>
  );
};
