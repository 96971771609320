import { useTranslation } from "react-i18next";
import { Empty } from "shared/components/Empty";
import { ModalLink } from "shared/components/ModalLink";

import PMsIcon from "../../assets/icons/empty.svg?react";

export const EmptyPMList = () => {
  const { t } = useTranslation();

  return (
    <Empty
      icon={<PMsIcon />}
      heading={t("preventive_maintenances.noPMs")}
      description={
        <>
          {t("empty.descriptionUse")}
          <ModalLink modalParams={{ type: "wo", id: "new" }}>
            {t("empty.workOrders")}
          </ModalLink>
          {t("empty.descriptionButton")}
        </>
      }
      spacious
    />
  );
};
