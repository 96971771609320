import { useSearchParamState } from "shared/lib/hooks/useSearchParamState";

/**
 * Gets/sets the "filters" URL query parameter
 * @param defaultVal - The value to return if the key is not found in the URL
 */
export function useFiltersParam(defaultVal?: unknown[]) {
  return useSearchParamState("filters", defaultVal, {
    serialize: (val) => (val ? btoa(JSON.stringify(val)) : "[]"),
    deserialize: (val) => {
      if (!val) return defaultVal;
      return JSON.parse(atob(val));
    },
  });
}
