import { LoginForm } from "modules/auth";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const LoginPage = () => {
  const location = useLocation();
  const isSessionClosed = location.pathname === "/login/session-close";
  const isPermissionDenied = location.pathname === "/login/permission-denied";
  const { t } = useTranslation();
  document.title = t("auth.login.title");

  return (
    <LoginForm
      isSessionClosed={isSessionClosed}
      isPermissionDenied={isPermissionDenied}
    />
  );
};
