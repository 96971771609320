import React from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "shared/components/Empty";
import WorkOrdersIcon from "../../assets/icons/empty.svg?react";

export const ErrorWorkOrderList = () => {
  const { t } = useTranslation();

  return (
    <Empty
      icon={<WorkOrdersIcon />}
      heading={t("work_orders.error")}
      spacious
    />
  );
};
