import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Pagination } from "features/Pagination";
import { useDisplayDate } from "shared/lib/hooks";
import { usePartTransactionsTableTools } from "modules/parts/lib/helpers";
import { useGetPartTransactionsQuery } from "modules/parts";
import { useTranslation } from "react-i18next";
import { Spinner } from "shared/components/Spinner";
import { Table } from "shared/components/Table";
import { Description } from "shared/components/Typography";
import styled from "styled-components";
import { useSearchParamState } from "shared/lib/hooks/useSearchParamState";

export const PartTransactionsList = ({
  partId,
  openPartTransactionHandler,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  const [page, setPage] = useSearchParamState("transaction_page", 1);

  const { data: partTransactions, isFetching } = useGetPartTransactionsQuery(
    {
      "filter[part_id_eq]": partId,
      sort: "updated_at+desc",
      per_page: 10,
      page,
    },
    partId ?? skipToken
  );

  const { columns } = usePartTransactionsTableTools({
    displayDate,
    partTransactions: partTransactions?.data,
    openPartTransactionHandler,
  });

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <SubInfo>
        <span>{Math.round(partTransactions?.meta?.total_used)}</span>
        {t("parts.transaction.partsUsage")}
      </SubInfo>
      <Table columns={columns} rows={partTransactions?.data} />
      <Pagination
        paginationData={partTransactions?.meta.pagination}
        currentPage={page}
        onPageChange={setPage}
        isShowInfoQuantity={false}
      />
    </>
  );
};

const SubInfo = styled(Description)`
  & > span {
    margin-right: 3px;
    font-weight: 600;
  }
`;
