import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  align_items: center;
  & .tooltip {
    background-color: var(--color_background_dark);
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: var(--color_text_light);
    max-width: 220px;
    z-index: 1;
  }
`;
