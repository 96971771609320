import { ReactNode, useRef } from "react";
import styled from "styled-components";
import { ErrorBoundary } from "@sentry/react";
import { ModalFallback } from "shared/components/Modals/components/ModalFallback";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  display: grid;
  place-items: center;
`;

type Variant = "light" | "dark";

const Content = styled.div<{ variant: Variant; allowOverflow: boolean }>`
  display: flex;
  flex-direction: column;

  min-width: 300px;
  min-height: 100px;
  max-height: calc(100vh - 50px);

  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.24);
  border-radius: ${({ variant }) => (variant === "dark" ? "10px" : "4px")};
  overflow: ${({ allowOverflow }) => (allowOverflow ? "visible" : "auto")};
`;

interface ModalProps {
  children?: ReactNode;
  onClose?: () => void;
  isOpen?: boolean;
  variant?: Variant;
  allowOverflow?: boolean;
  className?: string;
}

/**
 * Base Modal component
 */
export const Modal = ({
  isOpen = false,
  onClose = () => null,
  children,
  variant = "dark",
  allowOverflow = false,
  className,
}: ModalProps) => {
  const modalRef = useRef(null);
  return isOpen ? (
    <Overlay>
      <Content
        variant={variant}
        allowOverflow={allowOverflow}
        ref={modalRef}
        className={className}
      >
        <ErrorBoundary fallback={<ModalFallback {...{ onClose, variant }} />}>
          {children}
        </ErrorBoundary>
      </Content>
    </Overlay>
  ) : null;
};
