import React from "react";
import PropTypes from "prop-types";
import {
  usePagination,
  dotsInPagination as DOTS,
  usePageParam,
} from "shared/lib/hooks";
import {
  Container,
  Wrapper,
  Button,
  Dots,
  PaginationInfoWrapper,
} from "./styled";
import RowIcon from "shared/assets/Row.svg?react";

/**
 * Uses the "page" URL parameter via `usePageParam` by default.
 * To override this, use `currentPage` and `onPageChange` to use as a controlled component.
 */
export const Pagination = ({
  paginationData,
  label = "",
  currentPage,
  onPageChange,
  isShowOnlyQuantity = false,
  isShowInfoQuantity = true,
}) => {
  const { total, per_page, total_pages } = paginationData ?? {};
  const totalPages = Math.ceil(total_pages);
  const [pageParam, setPageParam] = usePageParam(1);
  const page = onPageChange ? currentPage : Number(pageParam);
  const range = usePagination({
    currentPage: page,
    totalPages,
    siblingCount: 1,
  });

  if (isShowOnlyQuantity && totalPages === 1)
    return (
      <Container>
        <PaginationInfoWrapper>
          {label} {1}-{total} of {total}
        </PaginationInfoWrapper>
      </Container>
    );

  if (!totalPages || totalPages === 1) return <React.Fragment />;

  const setPage = (p) => {
    if (onPageChange) {
      onPageChange(p);
    } else {
      setPageParam(p);
    }
  };

  const paginationRange = range || [1];

  const onNext = () => {
    setPage(page + 1);
  };

  const onPrevious = () => {
    setPage(page - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  const isFirstPage = page === 1;
  const isLastPage = page === lastPage;

  return (
    <Container isShowInfoQuantity={isShowInfoQuantity}>
      {isShowInfoQuantity && (
        <PaginationInfoWrapper>
          {label} {per_page * (page - 1) + 1}-
          {isLastPage ? total : per_page * page} of {total}
        </PaginationInfoWrapper>
      )}
      <Wrapper>
        <Button
          paginationItemDisabled={isFirstPage}
          onClick={onPrevious}
          paginationItemPrev
        >
          <RowIcon />
        </Button>
        {paginationRange.map((pageNumber, key) => {
          if (pageNumber === DOTS) {
            return <Dots key={key}>&#8230;</Dots>;
          }
          return (
            <Button
              key={key}
              paginationItemSelected={pageNumber === page}
              onClick={() => setPage(pageNumber)}
            >
              {pageNumber}
            </Button>
          );
        })}
        <Button paginationItemDisabled={isLastPage} onClick={onNext}>
          <RowIcon />
        </Button>
      </Wrapper>
    </Container>
  );
};

Pagination.defaultProps = {
  onPageChange: null,
  currentPage: null,
  label: "",
  isShowOnlyQuantity: false,
};

Pagination.propTypes = {
  paginationData: PropTypes.shape({
    total: PropTypes.number,
    per_page: PropTypes.number,
    total_pages: PropTypes.number,
  }),
  label: PropTypes.string,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  isShowOnlyQuantity: PropTypes.bool,
  isShowInfoQuantity: PropTypes.bool,
};
