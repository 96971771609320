import {
  Name,
  NameContainer,
  HeaderContainer,
  SubTitle,
} from "./UserDetailsView.styled";
import { UserAvatar } from "shared/components/UserAvatar";
import { User } from "modules/users/types/User";

interface UserDetailViewHeaderProps {
  user: User;
}

export const UserDetailViewHeader = ({ user }: UserDetailViewHeaderProps) => {
  const invitationPending = ![
    "active",
    "inactive",
    "change_email_in_progress",
  ].includes(user.status);

  return (
    <HeaderContainer>
      <UserAvatar user={user} size="xxl" />
      <NameContainer>
        <Name>{invitationPending ? user.email : user.full_name}</Name>
        <SubTitle>{invitationPending ? "" : user.job_title}</SubTitle>
      </NameContainer>
    </HeaderContainer>
  );
};
