import { downloadFile } from "shared/lib/helpers";
import { CMMSAPI } from "app/services";

export const filesApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    downloadFile: builder.query({
      queryFn: async ({ url, filename }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: url,
          method: "GET",
          responseHandler: (response) => response.blob(),
        });
        const downUrl = URL.createObjectURL(result.data);
        downloadFile(downUrl, filename);
        return {};
      },
    }),
  }),
});

export const { useDownloadFileQuery, useLazyDownloadFileQuery } = filesApi;
