import { NoData } from "modules/reporting/components/NoData";
import { BarChart } from "modules/reporting/components/charts/BarChart";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { transformCategoricalBarChartData } from "modules/reporting/lib/transformCategoricalBarChartData";
import { BacklogByPriorityChartData } from "./types";

interface BacklogByPriorityReportChartProps {
  data?: BacklogByPriorityChartData[];
  isFetching: boolean;
}

const reportKey = ReportKey.BacklogByPriority;
export const BacklogByPriorityReportChart = ({
  data,
  isFetching,
}: BacklogByPriorityReportChartProps) => {
  const barConfigs = useBarConfigs();
  const chartData = transformCategoricalBarChartData(barConfigs, data ?? []);

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  return (
    <BarChart
      isLoading={isFetching}
      data={chartData}
      dataAxisKey="label"
      bars={[{ dataKey: "value", name: "Total" }]}
    />
  );
};

function useBarConfigs() {
  const { t } = useTranslation();
  return {
    none: {
      order: 0,
      fill: "var(--color_grey400)",
      label: t(`reporting.${reportKey}.labels.none`),
    },
    low: {
      order: 1,
      fill: "var(--color_priority_lime)",
      label: t(`reporting.${reportKey}.labels.low`),
    },
    medium: {
      order: 2,
      fill: "var(--color_priority_yellow)",
      label: t(`reporting.${reportKey}.labels.medium`),
    },
    high: {
      order: 3,
      fill: "var(--color_priority_orange)",
      label: t(`reporting.${reportKey}.labels.high`),
    },
    critical: {
      order: 4,
      fill: "var(--color_priority_red)",
      label: t(`reporting.${reportKey}.labels.critical`),
    },
  };
}
