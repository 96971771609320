import { ModalType } from "widgets/ModalController/types";

export function encodeModalParam(
  type: ModalType,
  id: string | number,
  state?: string
) {
  return [type, id, state].filter((i) => exists(i)).join("|");
}

export function decodeModalParam(param: string | null) {
  const split = param?.split("|");
  if (!split || split.length < 2) return null;

  return {
    type: split[0] as ModalType,
    id: split[1],
    state: split[2],
  };
}

function exists(val: unknown) {
  return val !== undefined && val !== null;
}
