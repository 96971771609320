import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getTrackingFormatForService,
  updateStatusValidationSchema,
  useGetDowntimeHistoryListQuery,
} from "modules/downtimeTracking";
import { useUpdateAssetMutation } from "modules/assets";

import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { H2, H6, Hint } from "shared/components/Typography";
import { useForm } from "shared/lib/hooks/useForm";
import { Button } from "shared/components/Button";
import ThinAddIcon from "shared/assets/icon/thin-add.svg?react";

import { usePermission } from "app/providers/PermissionsProvider";

import {
  Box,
  ManualDowntimeButton,
  StatusContainer,
  ReliabilityHeader,
} from "./StatusDetails.styled";
import { DowntimeHistoryList } from "../DowntimeHistoryList";
import { ReliabilityHistoryChart } from "modules/downtimeTracking/components/ReliabilityHistoryChart";
import { DowntimeDialog } from "modules/downtimeTracking";
import { defaultReportPeriod } from "modules/reporting/lib/reportPeriodOptions";
import { ReliabilityHistoryPeriodSelect } from "modules/downtimeTracking/components/ReliabilityHistoryChart/ReliabilityHistoryPeriodSelect";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Tooltip } from "react-tooltip";

export const StatusDetails = ({ name, id, workOrders, firstEventStart }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [manualStatus, setManualStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { enableDowntimeChart } = useFlags();

  const [reportPeriod, setReportPeriod] = usePersistentState(
    "reliability_chart_period",
    defaultReportPeriod
  );

  const [updateAsset] = useUpdateAssetMutation();

  const params = {
    id,
    targetType: "asset",
    "filter[g][0][m]": "or",
    "filter[g][0][ended_at_gt]": reportPeriod.startDate,
    "filter[g][0][ended_at_blank]": true,
  };
  const { data: historyData, refetch: refetchHistory } =
    useGetDowntimeHistoryListQuery(
      {
        ...params,
        page: currentPage,
        per_page: 10,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: chartData, refetch: refetchChartData } =
    useGetDowntimeHistoryListQuery(
      {
        ...params,
        per_page: 10000,
      },
      { skip: !enableDowntimeChart }
    );

  const { assetsStatusesAddDowntimePermit, assetsViewDowntimeHistoryPermit } =
    usePermission();

  const onClose = () => {
    setManualStatus(null);
    setCurrentPage(1);
    form.resetForm();
  };

  useEffect(() => {
    if (historyData) {
      setPaginationData(historyData.meta.pagination);
    }
  }, [historyData]);

  const isManualType = !!manualStatus;

  const form = useForm({
    initialValues: {
      status: manualStatus,
      downtime_type: "unplanned",
      note: "",
      ...(isManualType ? { started_at: "", ended_at: "" } : {}),
    },
    validationSchema: updateStatusValidationSchema({
      t,
      isManual: isManualType,
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = getTrackingFormatForService(values, isManualType);
      updateAsset({
        id,
        workOrders,
        body,
      })
        .unwrap()
        .then(() => {
          refetchHistory();
          refetchChartData();
          onClose();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("assets.failedUpdate"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
          onClose();
        });
    },
  });

  return (
    <Box>
      <Hint># {id}</Hint>
      <H2>{name}</H2>
      <StatusContainer>
        {assetsStatusesAddDowntimePermit && (
          <Button
            onClick={() => setManualStatus("offline")}
            variant="secondary"
            data-tooltip-id="tooltip-update-manually"
          >
            <ManualDowntimeButton>
              <ThinAddIcon />
              <span> {t("assets.downtime_tracking.updateManually")}</span>
            </ManualDowntimeButton>
            <Tooltip
              id="tooltip-update-manually"
              place="bottom-end"
              content={t("assets.downtime_tracking.manualTooltip")}
              opacity="1"
            />
          </Button>
        )}
      </StatusContainer>
      {manualStatus && (
        <DowntimeDialog
          form={form}
          onCancel={onClose}
          endedAtRequired={true}
          firstEventStart={firstEventStart}
        />
      )}
      {assetsViewDowntimeHistoryPermit && (
        <>
          <ReliabilityHeader>
            <H6>{t("assets.reliability.title")}</H6>
            {enableDowntimeChart && (
              <ReliabilityHistoryPeriodSelect
                value={reportPeriod}
                onChange={setReportPeriod}
              />
            )}
          </ReliabilityHeader>
          {enableDowntimeChart && chartData?.data && (
            <ReliabilityHistoryChart
              startDate={reportPeriod.startDate}
              endDate={reportPeriod.endDate}
              data={chartData?.data}
            />
          )}
          <DowntimeHistoryList
            data={historyData}
            paginationData={paginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </Box>
  );
};
