import { useChangeUserPasswordMutation } from "modules/users";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Box, Title, FunctionalityLine, Label, Value } from "../styled";
import { Modal } from "./Modal";

export const Password = ({ form, userId }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [changePassword] = useChangeUserPasswordMutation();
  const { addSnackBar } = useSnackBar();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    form.resetForm();
    setShowModal(false);
  };

  const handleSubmit = async () => {
    changePassword({
      id: userId,
      body: {
        current_password: form.values.oldPassword,
        password: form.values.password,
        password_confirmation: form.values.password,
      },
    })
      .unwrap()
      .then(() => {
        handleCloseModal();
        addSnackBar({
          content: t("profile.password.successUpdate"),
          type: "success",
        });
      })
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  return (
    <>
      <Box>
        <Title>{t("profile.password.title")}</Title>
        <FunctionalityLine>
          <div>
            <Label margin="30">{t("profile.password.title")}</Label>
            <Value>••••••••</Value>
          </div>
          <Button variant="secondary" onClick={handleOpenModal}>
            {t("profile.change")}
          </Button>
        </FunctionalityLine>
      </Box>
      <Modal
        show={showModal}
        form={form}
        onClose={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
