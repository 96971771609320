import * as Yup from "yup";
import {
  getDescriptionSchema,
  getNameSchema,
} from "shared/lib/helpers/validation";

import { entityFields } from "./constants";

export const getDefaultValidationSchema = (field, t) => {
  const schema = {
    name: getNameSchema(t, { required: true }),
    description: getDescriptionSchema(t),
    address: getNameSchema(t),
    currency: Yup.object().required(t("validation.required")),
    datetime_format: Yup.object().required(t("validation.required")),
    timezone: Yup.object().required(t("validation.required")),
  };

  return schema[field];
};

export const entityValidationSchema = (t) => {
  const entitySchema = entityFields.reduce((acc, item) => {
    acc[item] = getDefaultValidationSchema(item, t);

    return acc;
  }, {});

  return Yup.object(entitySchema);
};
