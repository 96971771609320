import styled from "styled-components";

export const Name = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const Size = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color_primary_text);
  opacity: 0.5;
  margin-top: -5px;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 7px 9px 7px 12px;
  color: var(--color_primary_text);

  &:hover {
    background-color: var(--color_file_background_hover);
    color: var(--color_background);
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  svg {
    color: var(--color_primary_text);

    &:hover {
      opacity: 0.5;
    }

    &:active {
      transform: translateY(1px);
    }
  }
`;
