import * as Yup from "yup";
import {
  getStringSchema,
  getUrlSchema,
  getMultiSelectSchema,
  getCostSchema,
  getDescriptionSchema,
  getNameSchema,
} from "shared/lib/helpers/validation";

const getNumberSchema = (field, t) =>
  Yup.number()
    .typeError(t("validation.positive"))
    .test("Required test", "Required", (value, testContext) => {
      if (field?.required) {
        return !!value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

const getContactsSchema = (field) =>
  Yup.array(
    Yup.object({
      name: Yup.string(),
      phone: Yup.string(),
      email: Yup.string(),
      address: Yup.string(),
      is_primary: Yup.bool(),
    })
  ).test("Required test", "Required", (value, testContext) => {
    if (field?.required) {
      return value && value.length > 0
        ? true
        : testContext.createError({
            path: `${testContext.path}`,
            message: "required",
          });
    }
    return true;
  });

export const addVendorValidationSchema = (optionalFields, t) => {
  return Yup.object({
    name: getNameSchema(t, { required: true }),
    tax_id: getStringSchema(t, optionalFields.tax_id).max(
      255,
      t("validation.stringMaxLength", { stringMaxLength: 255 })
    ),
    description: getDescriptionSchema(t),
    urls_attributes: getUrlSchema(t, optionalFields.urls_attributes),
    vendor_contacts_attributes: getContactsSchema(
      optionalFields.vendor_contacts_attributes
    ),
    hourly_rate: getCostSchema(t).concat(
      getNumberSchema(optionalFields.hourly_rate, t)
    ),
    address: getStringSchema(t, optionalFields.address).max(
      255,
      t("validation.stringMaxLength", { stringMaxLength: 255 })
    ),
    locations: getMultiSelectSchema(t, optionalFields.locations_ids),
    assign_to_work_orders: Yup.bool().required(t("validation.required")),
    parts_supplier: Yup.bool().required(t("validation.required")),
    categories: getMultiSelectSchema(t, optionalFields.categories_ids),
    assets: getMultiSelectSchema(t, optionalFields.assets_ids),
    parts: getMultiSelectSchema(t, optionalFields.parts_ids),
    rating: getNumberSchema(optionalFields.rating, t),
  });
};
