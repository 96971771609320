import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useGetLocationsQuery } from "modules/locations";
import { useGetVendorsQuery } from "modules/vendors";
import { useGetAssetsQuery } from "modules/assets";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetWorkOrdersQuery, workOrdersApi } from "modules/workOrders";
import { FilterPriorityOptions } from "modules/filters/configs/constants";

import { Pagination } from "features/Pagination";
import { TopBar } from "widgets/TopBar";
import {
  useSearch,
  usePageParam,
  useSortParam,
  useFiltersParam,
} from "shared/lib/hooks";
import { Table } from "shared/components/Table";
import { NoResults } from "shared/components/NoResults";

import {
  useGetPMsQuery,
  useExportPMsMutation,
  useUpdatePMMutation,
} from "modules/workOrders";
import { useGetTechniciansQuery } from "modules/users";
import { usePMsTableTools } from "modules/preventiveMaintenances/lib/usePMsTableTools";
import {
  getFilterTimeToCompleteOptions,
  getFilterScheduleOptions,
} from "../../lib/helpers";
import { FilterStatusOptions } from "../../configs/constants";
import { EmptyPMList } from "./EmptyPMList";
import { ErrorPMList } from "./ErrorPMList";
import { BulkActions } from "../BulkActions";
import { usePermission } from "app/providers/PermissionsProvider";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import { PriorityIconUrlMap } from "shared/components/Priority";
import { useSearchParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";

const defaultFilters = [
  {
    field: "status",
    operator: "_in",
    value: ["planned"],
  },
];

export const List = ({ onOpen = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pmsListExportPermit } = usePermission();

  const [selected, setSelected] = useState([]);
  const [updatingPMs, setUpdatingPMs] = useState([]);

  const [exportPMs] = useExportPMsMutation();
  const [updatePM] = useUpdatePMMutation();

  const [assets, onAssetsSearch] = useSearch(useGetAssetsQuery, true);
  const [vendors, onVendorsSearch] = useSearch(useGetVendorsQuery, true);
  const [locations, onLocationsSearch] = useSearch(useGetLocationsQuery, true);
  const [categories, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    true
  );
  const [workOrders, onWorkOrdersSearch] = useSearch(
    useGetWorkOrdersQuery,
    true,
    {},
    "title"
  );
  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    true,
    {},
    "full_name"
  );

  const FilterTimeToCompleteOptions = useMemo(
    () => getFilterTimeToCompleteOptions(),
    []
  );
  const FilterScheduleOptions = useMemo(() => getFilterScheduleOptions(), []);

  const getFilterOptions = (field) => {
    if (field === "categories") {
      return categories?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "location") {
      return locations?.data.map(({ id, path }) => ({ key: id, value: path }));
    }
    if (field === "asset") {
      return assets?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "vendors") {
      return vendors?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "priority") {
      return FilterPriorityOptions;
    }
    if (field === "status") {
      return FilterStatusOptions;
    }
    if (field === "work_order_id") {
      return workOrders?.data.map(({ id, title }) => ({
        key: id,
        value: title,
      }));
    }
    if (field === "time_to_complete") {
      return FilterTimeToCompleteOptions;
    }
    if (field === "schedule") {
      return FilterScheduleOptions;
    }
    if (field === "assign_to") {
      return technicians?.data.reduce((acc, { id, name, surname, status }) => {
        if (status !== "invitation_sent") {
          acc.push({ key: id, value: `${name} ${surname}` });
        }
        return acc;
      }, []);
    }

    return [];
  };

  const getFilterSearch = (field) => {
    if (field === "location") {
      return onLocationsSearch;
    }
    if (field === "categories") {
      return onCategoriesSearch;
    }
    if (field === "asset") {
      return onAssetsSearch;
    }
    if (field === "vendors") {
      return onVendorsSearch;
    }
    if (field === "work_order_id") {
      return onWorkOrdersSearch;
    }
    if (field === "assign_to") {
      return onTechniciansSearch;
    }

    return () => {};
  };

  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const [filters] = useFiltersParam(defaultFilters);
  const stringifiedFilters = stringifyFilters(
    filters,
    "preventive_maintenances"
  );

  const handleUpdatePM = async ({ id, body }) => {
    setUpdatingPMs((prev) => [...prev, id]);
    await updatePM({ id, body, shouldUpdateList: false }).then(({ data }) => {
      setUpdatingPMs((prev) => prev.filter((val) => val !== data.data.id));
      dispatch(
        workOrdersApi.util.updateQueryData(
          "getPMs",
          {
            search,
            page,
            per_page: tableSettings?.per_page,
            sort,
            ...stringifiedFilters,
          },
          (currentData) => {
            const updatedData = currentData.data?.map((val) =>
              val.id === data.data.id ? data.data : val
            );
            return { ...currentData, data: updatedData };
          }
        )
      );
    });
  };

  const { tableSettings, applySettings, columns, areTableSettingsFetching } =
    usePMsTableTools({
      onOpen,
      updatePM: handleUpdatePM,
      updatingPMs: updatingPMs,
    });

  const {
    data,
    error = false,
    isFetching,
  } = useGetPMsQuery({
    search,
    page,
    per_page: tableSettings?.per_page,
    sort,
    ...stringifiedFilters,
  });
  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);

  const filterCount = Object.keys(filters).filter(
    (filter) => !filter.includes("repeat_frequency")
  ).length;

  useEffect(() => {
    if (data) {
      setSelected([]);
    }
  }, [data]);

  const hasFilter = Object.keys(filters).length > 0;
  const empty = data?.data?.length === 0 && !isFetching && !hasFilter;
  const notFound =
    data?.data?.length === 0 &&
    !isFetching &&
    (search?.length > 0 || hasFilter);

  if (error) {
    return <ErrorPMList />;
  } else if (empty && !notFound) {
    return <EmptyPMList />;
  } else if (empty) {
    return <NoResults query={search} />;
  }

  return (
    <>
      <TopBar
        entity="preventive_maintenances"
        columns={columns}
        tableSettings={tableSettings}
        setTableSettings={applySettings}
        filterCount={filterCount}
        getOptions={getFilterOptions}
        getSearch={getFilterSearch}
        onExportClick={() => exportPMs({ ...filters, search })}
        withExport={pmsListExportPermit}
        showToggleFilters={true}
        initialFilters={defaultFilters}
      />
      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading || areTableSettingsFetching}
        highlight={search}
        notFound={notFound}
        selectionModel={selected}
        onSelectionModelChange={setSelected}
        bulkActionsNode={<BulkActions selectionModel={selected} />}
        getRowIcon={(row) => (
          <RowDecoratorImage
            src={
              row.template_work_order.priority
                ? PriorityIconUrlMap[row.template_work_order.priority]
                : undefined
            }
            width="16px"
            height="16px"
          />
        )}
      />
      <Pagination
        paginationData={data?.meta.pagination}
        label={t("preventive_maintenances.title")}
      />
    </>
  );
};
