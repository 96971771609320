import styled from "styled-components";

export const Row = styled.div`
  display: flex;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 110px;
`;

export const Cell = styled.div`
  width: ${({ width }) => `${width}` ?? "auto"};
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 60px;
  border-bottom: 1px solid var(--color_border);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color_table_head_text_color);
`;

export const Td = styled(Cell)`
  line-height: 20px;
  color: var(--color_table_text_color);

  &:first-child {
    padding: 20px;
  }
`;

export const Th = styled(Cell)`
  line-height: 16px;
  font-weight: 600;
  min-height: 48px;
`;

export const BodyContainer = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
`;
