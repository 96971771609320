import PropTypes from "prop-types";
import { LocationsList } from "./List";
import { EmptyLocationList } from "./EmptyLocationList";

export const LocationList = ({ onOpenLocation }) => {
  return (
    <LocationsList
      onOpenLocation={onOpenLocation}
      emptyListNode={<EmptyLocationList />}
    />
  );
};

LocationList.propTypes = {
  onOpenLocation: PropTypes.func,
};
