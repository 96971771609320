import React from "react";
import RemoveIcon from "../assets/remove.svg?react";

import {
  FiltersQuickContainer,
  FilterQuickLabel,
  FilterQuick,
  FilterQuickField,
  FilterQuickRemoveButton,
} from "./Filters.styled";

import { useTranslation } from "react-i18next";

export const FiltersQuick = ({ quickFilter, removeQuickFilter }) => {
  const { t } = useTranslation();

  const { name, type } = quickFilter;

  return (
    <FiltersQuickContainer type={type}>
      <FilterQuick>
        <FilterQuickLabel>{t(`filters.quick.${type}`)}</FilterQuickLabel>
        <FilterQuickField>
          {name}
          <FilterQuickRemoveButton onClick={removeQuickFilter}>
            <RemoveIcon />
          </FilterQuickRemoveButton>
        </FilterQuickField>
      </FilterQuick>
    </FiltersQuickContainer>
  );
};
