import { DonutChart as Root } from "./DonutChart";
import { DonutChartChart as Chart } from "./components/DonutChartChart";
import { DonutChartLegend as Legend } from "./components/DonutChartLegend";
import { DonutChartLabel as Label } from "./components/DonutChartLabel";

export const DonutChart = {
  Root,
  Chart,
  Legend,
  Label,
};
