import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Image } from "../Image";

export const ImageList = ({
  className,
  images = [],
  collapsedAmount = 6,
  onDelete = null,
  onView = null,
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const imagesLength = images.length;
  const isCollapsed = Boolean(imagesLength > collapsedAmount);

  const imagesLeft = imagesLength - (collapsedAmount - 1);

  const showMoreClickHandler = () => {
    setCollapsed(true);
  };

  return (
    imagesLength > 0 && (
      <Box className={className}>
        {images
          .slice(
            0,
            !collapsed && isCollapsed ? collapsedAmount - 1 : imagesLength
          )
          .map((props) => (
            <Image
              key={props.name}
              {...props}
              onDelete={onDelete}
              onView={onView}
            />
          ))}
        {!collapsed && isCollapsed && (
          <ShowMore onClick={showMoreClickHandler}>
            {t("files.image.collapsed", { count: imagesLeft })}
          </ShowMore>
        )}
      </Box>
    )
  );
};

const Box = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  & > div,
  & > button {
    width: 174px;
    height: 174px;
  }
`;

const ShowMore = styled.button`
  border-radius: 8px;
  border: 1px solid var(--color_grey100);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color_primary_text);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background: transparent;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
