import { CustomerContainer, EditButton, Logo, SubContainer } from "./styled";

import { H2 } from "shared/components/Typography";
import { useGetCustomerQuery } from "modules/customer/state/customerApi";
import { Link } from "shared/components/Link";
import { useTranslation } from "react-i18next";
import CompanyLogo from "../assets/icons/Avatar.svg?react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const CustomerView = () => {
  const { t } = useTranslation();
  const id = JSON.parse(localStorage.getItem("user"))?.customerId;
  const { data } = useGetCustomerQuery(id ?? skipToken);

  return (
    <CustomerContainer>
      <Logo>
        <CompanyLogo />
      </Logo>
      <SubContainer>
        <H2>{data?.name}</H2>
        <EditButton variant="secondary">
          <Link
            type="button"
            to={`/admin-settings/general/customer/${id}/edit`}
          >
            {t("generalSettings.customer.companyName")}
          </Link>
        </EditButton>
      </SubContainer>
    </CustomerContainer>
  );
};
