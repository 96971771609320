import * as Yup from "yup";
import {
  getDescriptionSchema,
  getNameSchema,
  getSelectSchema,
} from "shared/lib/helpers/validation";

export const addLocationValidationSchema = (optionalFields, t) =>
  Yup.object({
    name: getNameSchema(t, { required: true }),
    address: getNameSchema(t, optionalFields.address),
    description: getDescriptionSchema(t, optionalFields.description),
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    //teams_ids: Yup.array(),
    code: Yup.string().max(50, "Must be 50 characters or less").nullable(),
    parent: getSelectSchema(t, optionalFields.ancestry),
    uploads_attributes: Yup.array()
      .max(
        10,
        "The maximum number of files was reached. You can’t upload more than 10 files."
      )
      .nullable(),
  });
