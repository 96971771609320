import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";

const FilterContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  min-width: 750px;
  width: 100%;
  min-height: 40px;
  z-index: 4;
  top: calc(100% + 8px);
  left: 0;
  padding: 0;
  margin: 0;
  background: var(--color_background_light);
  box-shadow: 0px 0px 3px 2px rgba(19, 20, 20, 0.1);
  padding: 0;
  flex-direction: column;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: var(--color_grey900);
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // width: 100%;
`;

const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid var(--color_grey100);
`;

const FilterTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  padding: 0;
`;

const FilterCount = styled.span`
  display: flex;
  border-radius: 12px;
  color: var(--color_background_light);
  background: var(--color_dark_blue);
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 16px;
`;

const FilterBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 22px;
  align-items: flex-start;
  min-height: 100px;
`;

const FilterBodyTitle = styled.p`
  margin: 0 0 16px 0;
`;

const FilterResetButton = styled.button`
  font-size: 12px;
  font-weight: 400;
  color: var(--color_primary_text);
  line-height: 16px;
`;

const FilterAddButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  color: var(--color_primary_text);
  margin-right: auto;
`;

const PickUpFilterWrapper = styled.div`
  width: 250px;
`;

const FilterFooter = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid var(--color_border);
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

const FilterColumn = styled.div`
  margin-right: 16px;
  width: 30%;
`;

const Label = styled.label`
  display: flex;
  width: 100px;
  color: var(--color_grey900);
  font-size: 14px;
  font-weight: 500;
`;

const FilterRemoveButton = styled.button`
  border-radius: 4px;
  border: 1px solid var(--color_grey100);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 40px;
`;

const FilterButton = styled.button`
  background: ${({ isActive }) =>
    isActive ? "var(--color_primary)" : "var(--color_background_light)"};
  color: ${({ isActive }) =>
    isActive ? "var(--color_dark_blue)" : "var(--color_primary_text)"};
  font-size: 14px;
  line-height: 16px;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    background: ${({ isActive }) =>
      isActive ? "var(--color_primary)" : "var(--color_primary_20)"};
  }
`;

const FiltersHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const FiltersQuickContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${({ type }) => (type === "location" ? "0" : "32px")};
`;

const FilterQuickLabel = styled.span`
  font-family: inherit;
  line-height: 20px;
  font-size: 14px;
  color: var(--color_grey700);
  margin-right: 8px;
`;

const FilterQuick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterQuickField = styled.div`
  background-color: var(--color_corp_lime);
  padding: 0 4px 0 8px;
  border-radius: 2px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
`;

const FilterQuickRemoveButton = styled(IconButton)`
  margin-left: 4px;
`;

export {
  FilterContainer,
  Container,
  FilterHeader,
  FilterTitle,
  FilterCount,
  FilterBody,
  FilterBodyTitle,
  FilterResetButton,
  FilterAddButton,
  PickUpFilterWrapper,
  FilterFooter,
  FilterRow,
  FilterColumn,
  Label,
  FilterRemoveButton,
  FilterButton,
  FiltersHeader,
  FiltersQuickContainer,
  FilterQuickLabel,
  FilterQuick,
  FilterQuickField,
  FilterQuickRemoveButton,
};
