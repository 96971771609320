import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { pickEntriesByKey } from "shared/lib/filterObjectByKeys";
import { Description, H2, Hint } from "shared/components/Typography";
import { Divider } from "shared/components/Divider";
import { TextLink } from "shared/components/TextLink";
import { ListOfDetails } from "shared/components/widgets/ListOfDetails";
import { LabeledQrCode } from "shared/components/QrCode";
import { useDisplayDate } from "shared/lib/hooks";
import { locationDetailsPropType } from "../../propTypes";
import { ModalLink } from "shared/components/ModalLink";

export const LocationView = ({
  id,
  name,
  description,
  address,
  updatedAt,
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // teams,
  parentLocation,
  code,
  fileManagerNode,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  const rows = [];

  if (address && address.length > 0) {
    rows.push({
      label: t("table.columns.address"),
      value: <AddressLinkStyled to="#">{address}</AddressLinkStyled>,
    });
  }

  if (parentLocation) {
    rows.push({
      label: t("locations.parentLocation"),
      value: parentLocation ? (
        <ModalLink modalParams={{ type: "location", id: parentLocation.id }}>
          {parentLocation.name}
        </ModalLink>
      ) : null,
    });
  }
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // if (teams && teams.length > 0) {
  //   // TODO: Fix hardcoded color when backend adds it
  //   const teamTags = teams.map(({ name }) => ({ name, color: "#FFACA6" }));
  //   rows.push({
  //     label: t("table.columns.teams"),
  //     value: <ShortTagsDisplay tags={teamTags} />,
  //   });
  // }

  return (
    <Box>
      <Hint># {id}</Hint>
      <H2>{name}</H2>
      {description && <DescriptionStyled>{description}</DescriptionStyled>}
      {fileManagerNode}
      <DividerStyled />
      <ListOfDetails
        title={t("details")}
        subTitle={t("updatedAt", { date: displayDate(updatedAt, true) })}
        rows={rows}
        RightSideNode={<LabeledQrCode data={code} />}
      />
    </Box>
  );
};

LocationView.propTypes = {
  ...pickEntriesByKey(locationDetailsPropType, [
    "id",
    "name",
    "description",
    "address",
    "updatedAt",
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // "teams",
  ]),
  fileManagerNode: PropTypes.node,
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionStyled = styled(Description)`
  margin-top: 20px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const AddressLinkStyled = styled(TextLink)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
