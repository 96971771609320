import { H3, Description, Hint } from "shared/components/Typography";
import EditIcon from "../../assets/icons/edit.svg?react";

import {
  Container,
  SubContainer,
  Box,
  StyledIconButton,
} from "./entity.styled";

export const Entity = ({ entity, openEntityEditor, defaultEntity }) => {
  const {
    id,
    name,
    description,
    address,
    currency,
    currency_name,
    timezone,
    datetime_format,
  } = entity;
  return (
    <Container>
      <SubContainer defaultEntity={defaultEntity}>
        <StyledIconButton
          onClick={() => openEntityEditor(id)}
          defaultEntity={defaultEntity}
        >
          {defaultEntity ? <span>Edit</span> : ""} <EditIcon />
        </StyledIconButton>
        <H3>{name}</H3>
        <Description>{description}</Description>
        <Hint>{address}</Hint>
        <Box defaultEntity={defaultEntity}>
          <div>
            <Hint>{currency}</Hint>
            <Hint>({currency_name})</Hint>
          </div>
          <Hint>{datetime_format}</Hint>
          <Hint>{timezone}</Hint>
        </Box>
      </SubContainer>
    </Container>
  );
};
