import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Button } from "shared/components/Button";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useEmailInvitationMutation } from "modules/users/state/usersApi";

export const InvitationResendButton = ({ user }) => {
  const userId = user?.id;
  const { t } = useTranslation();
  const [emailInvitation] = useEmailInvitationMutation();
  const { addSnackBar } = useSnackBar();

  const proceedHandler = () => {
    if (userId) {
      emailInvitation({ id: userId, type: "resend_invitation" })
        .unwrap()
        .then(() => {
          addSnackBar({
            content: t("allUsers.resendInvitation.success"),
            type: "success",
          });
        })
        .catch(() => {
          addSnackBar({
            content: t("allUsers.resendInvitation.fail"),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    }
  };

  return (
    <CustomButton variant="primary" onClick={proceedHandler}>
      {t("allUsers.resendInvitation.buttonTitle")}
    </CustomButton>
  );
};

export const CustomButton = styled(Button)`
  margin-left: auto;
`;
