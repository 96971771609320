import styled from "styled-components";

export const Button = styled.button<{ showOutline: boolean }>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 4px 2px 2px;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  border: 1px solid var(--color_grey500);
  border-radius: var(--border_radius_med);

  outline: none;
  font-size: 14px;
  box-shadow: ${(p) => (p.showOutline ? `var(--focus_ring)` : "none")};

  &:focus-visible {
    box-shadow: var(--focus_ring);
  }

  &:disabled {
    color: var(--color_grey500);
    background-color: var(--color_grey10);
  }
`;

export const ButtonText = styled.span`
  padding: 2px 8px;
`;

export const Popover = styled.div`
  background-color: var(--color_background_light);
  width: 500px;
  max-width: 95vw;
  border-radius: var(--border_radius_med);
  border: 1px solid var(--color_border);
  box-shadow: var(--shadow_med);
`;
