import { AssetCostsChartData } from "./types";
import { BarProps } from "recharts";
import styled from "styled-components";
import { formatCurrency } from "modules/reporting/lib/formatters";
import { useTranslation } from "react-i18next";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { BarChart } from "modules/reporting/components/charts/BarChart";
import { NoData } from "modules/reporting/components/NoData";

interface AssetCostsReportChartProps {
  data?: AssetCostsChartData;
  isFetching: boolean;
}

const reportKey = ReportKey.AssetCosts;

export const AssetCostsReportChart = ({
  data,
  isFetching,
}: AssetCostsReportChartProps) => {
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();

  let valueFormatter: (value: number) => string;
  let bars: BarProps[];
  let chartData = data?.by_cost || [];
  valueFormatter = (val) => formatCurrency(val, language, currency);
  bars = [
    {
      name: t(`reporting.${reportKey}.labels.cost`) as string,
      dataKey: "cost",
      fill: "var(--color_chart_green)",
    },
  ];

  if (!isFetching && !chartData?.length) {
    return <NoData />;
  }

  return (
    <Wrapper>
      <BarChart
        isLoading={isFetching}
        layout="vertical"
        bars={bars}
        data={chartData}
        dataAxisKey="name"
        valueFormatter={valueFormatter}
        xAxisProps={{
          domain: [0, "auto"],
        }}
        yAxisProps={{
          width: 150,
          tickFormatter: (val) =>
            val.length > 14 ? val.substring(0, 14) + "..." : val,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
