import React, { useRef, useState } from "react";
import {
  FloatingArrow,
  arrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { Button } from "shared/components/Button";
import PropTypes from "prop-types";
import styled from "styled-components";

export const ContextMenu = ({
  className,
  buttonSlot,
  children,
  disabled,
  placement = "bottom-end",
}) => {
  const arrowRef = useRef(null);
  const [contextOpen, setContextOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: contextOpen,
    onOpenChange: disabled ? undefined : setContextOpen,
    middleware: [
      offset({ mainAxis: 7, crossAxis: 9 }),
      arrow({ element: arrowRef }),
    ],
    placement,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {buttonSlot ?? (
          <Button className={className} variant="secondary">
            ...
          </Button>
        )}
      </div>
      {contextOpen && (
        <Menu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <Content>{children}</Content>
          <FloatingArrow
            ref={arrowRef}
            context={context}
            fill={"var(--color_background_light)"}
            stroke={"var(--color_grey200)"}
            strokeWidth={1}
            width={10}
            height={5}
          />
        </Menu>
      )}
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Menu = styled.div`
  border-radius: 8px;
  border: 1px solid var(--color_grey200);
  box-shadow: 0 2px 8px rgba(31, 30, 36, 0.16), 0 0 2px rgba(31, 30, 36, 0.16);
  background: var(--color_background_light);
  min-width: 246px;
  padding: 8px 4px;
  box-sizing: border-box;
  z-index: 1;
`;

ContextMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  buttonSlot: PropTypes.element,
  disabled: PropTypes.bool,
  placement: PropTypes.string,
};
