import { Dispatch, SetStateAction, useMemo } from "react";
import { useSearch } from "shared/lib/hooks";
import { useGetAssetsQuery } from "modules/assets";
import { useGetVendorsQuery } from "modules/vendors";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetLocationsQuery } from "modules/locations";
import { useGetTechniciansQuery } from "modules/users";
import { useGetWorkOrderStatusesQuery } from "modules/workOrders";
import { getStatusesOptions } from "modules/workOrders/lib/helpers/form";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";
import { useTranslation } from "react-i18next";
import { useGetAssetTypesQuery } from "modules/assetTypes";

export interface SearchFilterOptions {
  setSearch?: Dispatch<SetStateAction<string>>;
  filterOptions: unknown[];
}

function defaultMapOptions(data: any) {
  return data?.map(({ id, name }: any) => ({
    key: id,
    value: name,
  }));
}

export const useSearchAssetOptions = (): SearchFilterOptions => {
  const [searchRes, setSearch] = useSearch(useGetAssetsQuery);
  const filterOptions = defaultMapOptions(searchRes?.data);
  return { setSearch, filterOptions };
};

export const useSearchVendorOptions = (): SearchFilterOptions => {
  const [searchRes, setSearch] = useSearch(useGetVendorsQuery);
  const filterOptions = defaultMapOptions(searchRes?.data);
  return { setSearch, filterOptions };
};

export const useSearchCategoryOptions = (): SearchFilterOptions => {
  const [searchRes, setSearch] = useSearch(useGetCategoriesQuery);
  const filterOptions = defaultMapOptions(searchRes?.data);
  return { setSearch, filterOptions };
};

export const useSearchLocationOptions = (): SearchFilterOptions => {
  const [searchRes, setSearch] = useSearch(useGetLocationsQuery);
  const filterOptions = defaultMapOptions(searchRes?.data);
  return { setSearch, filterOptions };
};

export const useSearchTechnicianOptions = (): SearchFilterOptions => {
  const [searchRes, setSearch] = useSearch(useGetTechniciansQuery);
  const filterOptions = searchRes?.data.reduce(
    (acc: any[], { id, name, surname, status }: any) => {
      if (status !== "invitation_sent") {
        acc.push({ key: id, value: `${name} ${surname}` });
      }
      return acc;
    },
    []
  );
  return { setSearch, filterOptions };
};

export const useSearchStatusOptions = (): SearchFilterOptions => {
  const { t } = useTranslation();
  const { data: statusConfiguration } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });
  const [searchRes, setSearch] = useSearch(useGetWorkOrderStatusesQuery);

  const statusesOptions = useMemo(
    () => getStatusesOptions(searchRes?.data, statusConfiguration),
    [searchRes, statusConfiguration]
  );

  const filterOptions = statusesOptions?.map(({ id, name }) => ({
    key: id,
    value: t(`work_orders.status.${name}`),
  }));
  return { setSearch, filterOptions };
};

export const useSearchAssetTypeOptions = (): SearchFilterOptions => {
  const [searchRes, setSearch] = useSearch(useGetAssetTypesQuery);
  const filterOptions = defaultMapOptions(searchRes?.data);
  return { setSearch, filterOptions };
};
