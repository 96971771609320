import { Modal } from "shared/components/Modals";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ReportingIcon from "modules/reporting/assets/reporting-icon.svg?react";

/** Blocks screen while PDF export is in progress */
export const ExportingModal = () => {
  const { t } = useTranslation();

  return (
    <Modal isOpen>
      <Content>
        <ReportingIcon />
        <span>{t("reporting.exportMenu.pdfExporting")}</span>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-weight: bold;
`;
