import styled, { css } from "styled-components";
import { Box as SortBox } from "./Sort.styled";
import { Checkbox } from "../../../Checkbox";

export const Cell = styled.div`
  width: ${({ width }) => `${width}px` ?? "auto"};
  margin: 0;
  padding: 8px 16px 8px ${({ hasIcon }) => (hasIcon ? "10px" : "16px")};
  border-bottom: 1px solid var(--color_border);
  border-right: 1px solid var(--color_border);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color_table_head_text_color);
  white-space: nowrap;

  text-align: ${(p) => (p.type === "number" ? "right" : "left")};
  justify-content: ${(p) => (p.type === "number" ? "right" : "left")};

  ${({ notPrimary }) =>
    !notPrimary &&
    css`
      &:first-child {
        position: sticky;
        left: 0;
        background-color: var(--color_table_background);
        border-right: 1px solid var(--color_border);
        overflow: initial;
        z-index: 1;
      }
    `}

  ${({ edit }) =>
    edit &&
    css`
      & > button {
        visibility: hidden;
      }
    `}

  ${({ notPrimary }) =>
    !notPrimary &&
    css`
      &:first-child::before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: -8px;
        width: 8px;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.16) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `}

   ${({ textAndBadge }) =>
    textAndBadge &&
    css`
      & > a {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
      }
      display: flex;
      justify-content: space-between;
    `}
`;

export const Row = styled.div`
  display: contents;

  &:not(:first-child):hover {
    background: var(--color_table_hover_background);

    ${Cell} {
      background: var(--color_table_hover_background);
      & > button {
        visibility: visible;
      }
    }
    #decline-icon {
      stroke: var(--color_corp_red_dark);
    }
    #onhold-icon {
      stroke: var(--color_text_color);
    }
    #approve-icon {
      stroke: var(--color_primary_text);
    }
  }
`;

export const HeaderRow = styled.div`
  display: contents;
`;

export const Th = styled(Cell)`
  position: sticky;
  top: 0;
  background-color: var(--color_table_background);
  z-index: 3;
  flex-direction: ${(p) => (p.type === "number" ? "row-reverse" : "row")};

  white-space: nowrap;

  line-height: 16px;
  font-weight: 600;
  min-height: 48px;

  &:first-child {
    z-index: 4;
  }

  &:hover ${SortBox} {
    visibility: visible;
  }
`;

export const Td = styled(Cell)`
  line-height: 20px;
  color: var(--color_table_text_color);
`;

export const CellCheckbox = styled(Checkbox)`
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const CellTextContainer = styled.span`
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeadTextContainer = styled.span`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 0;
`;
