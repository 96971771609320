import styled from "styled-components";
import { Description } from "../Typography";

export const ContentBox = styled.div`
  padding: 32px 16px 10px;
  width: 512px;
`;

export const IconContainer = styled.div`
  text-align: center;
`;

export const Text = styled(Description)`
  margin: 32px 0;
`;
