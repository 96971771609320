import { PasswordField } from "modules/auth/components/PasswordField";
import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { LightModal } from "shared/components/Modals/LightModal";
import { InlineLabel } from "shared/components/widgets";
import styled from "styled-components";

export const Modal = ({ show, form, onClose, handleSubmit }) => {
  const { t } = useTranslation();
  return (
    <LightModal
      isOpen={show}
      onClose={onClose}
      onCancel={onClose}
      secondaryButtonLabel={t("discardChanges")}
      label={t("profile.password.modalTitle")}
      onSubmit={handleSubmit}
      disabledSubmit={!form.isWasChanged || !form.isFormValid}
    >
      <Box>
        <InlineLabel
          label={t("profile.password.oldPassword")}
          labelWidth={120}
          styles={{ fontWeight: 500 }}
        >
          <Input
            type="password"
            name="oldPassword"
            placeholder={t("profile.password.oldPassword")}
            value={form.values.oldPassword}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isPassword
          />
        </InlineLabel>
        <PasswordField form={form} variant="update" columnLabel={false} />
      </Box>
    </LightModal>
  );
};

const Box = styled.div`
  width: 480px;
  padding: 32px 16px;
`;
