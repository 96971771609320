import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Checkbox } from "../Checkbox";

export const FormCheckbox = (props) => {
  const {
    name,
    checked = false,
    disabled = false,
    onClick,
    label,
    checkboxClassName,
    multiline,
    required = false,
  } = props;

  return (
    <Container name={name} multiline={multiline}>
      <Checkbox
        checked={checked}
        disabled={disabled}
        onClick={onClick}
        className={checkboxClassName}
      />
      <Label required={required}>{label}</Label>
    </Container>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  required: false,
  multiline: false,
};

const Container = styled.div`
  display: flex;
  align-items: ${({ multiline }) => (multiline ? "flex-start" : "center")};
  gap: 10px;
  margin-bottom: 8px;
`;

const Label = styled.span`
  line-height: 16px;
  font-size: 14px;

  ${(p) =>
    p.required &&
    css`
      &::after {
        content: "*";
        margin-left: 0.2em;
        color: var(--color_alert);
      }
    `}
`;
