import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NoData } from "modules/reporting/components/NoData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";
import { BarChart } from "../../charts/BarChart";
import { formatCurrency } from "../../../lib/formatters";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";
import { BarProps, XAxisProps } from "recharts";

interface PartsInventoryReportWidgetData {
  chart_data: PartsInventoryChartData[];
  other_data: {
    costs: number;
  };
}

interface PartsInventoryChartData {
  unit: string;
  quantity: number;
  cost: number;
  part_type: string;
}

const MAX_RECORDS = 8;
export const PartsInventoryReportWidget = () => {
  const { t } = useTranslation();
  const { reportPeriod } = useReportPageContext();
  const bars = useBarConfigs();
  const { data, isFetching } = useWidgetData<PartsInventoryReportWidgetData>(
    ReportKey.PartsInventory,
    reportPeriod
  );

  const { language, currency } = useLocaleSettings();

  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  const clonedData: PartsInventoryChartData[] =
    data && data.chart_data ? data.chart_data.map((item) => ({ ...item })) : [];
  const sortedData = clonedData.sort((a, b) => b.cost - a.cost);
  const displayData = sortedData.map((item) => item).slice(0, MAX_RECORDS);

  const xAxisProps: XAxisProps = {
    dataKey: "cost",
    xAxisId: "cost",
    type: "number",
    allowDecimals: true,
    orientation: "bottom",
    tickFormatter: (value: number) => formatCurrency(value, language, currency),
    domain: [0, Math.max(...displayData.map((item) => item.cost)) * 1.2],
  };
  const kpis = data?.other_data;
  return (
    <Wrapper>
      <Stats>
        <span>
          {t(`reporting.${ReportKey.PartsInventory}.widget_kpis.costs`, {
            value: formatCurrency(
              parseFloat(kpis?.costs.toString() || ""),
              language,
              currency
            ),
          })}
        </span>
      </Stats>
      <BarChart
        data={displayData}
        isLoading={isFetching}
        layout="vertical"
        yAxisProps={{
          width: 200,
          interval: 0,
          includeHidden: true,
          tickFormatter: (value: string) =>
            value.length > 30 ? value.substring(0, 27) + "..." : value,
        }}
        dataAxisKey="part"
        xAxisProps={xAxisProps}
        bars={bars}
      />
    </Wrapper>
  );
};

function useBarConfigs(): BarProps[] {
  const { t } = useTranslation();
  return [
    {
      order: 0,
      dataKey: "cost",
      xAxisId: "cost",
      name: t(`reporting.${ReportKey.PartsInventory}.labels.cost`) as string,
      fill: "var(--color_mint)",
    },
  ];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-rows: 1fr auto auto;
  height: 100%;
`;

const Stats = styled.div`
  display: flex;
  gap: 24px;

  color: var(--color_dark_blue);
  font-size: 14px;
  font-weight: 600;
`;
