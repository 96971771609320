import { useChangeUserEmailMutation } from "modules/users";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Box, Title, FunctionalityLine, Label, Value } from "../styled";
import { Modal } from "./Modal";
import { ResendButton } from "./ResendButton";

export const Email = ({ form, userId, previousEmail, unconfirmedEmail }) => {
  const [showModal, setShowModal] = useState(false);
  const [unconfirmedEmailLocal, setUnconfirmedEmailLocal] = useState("");
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const { t } = useTranslation();
  const [changeEmail] = useChangeUserEmailMutation();
  const { addSnackBar } = useSnackBar();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    form.resetForm();
    setIsConfirmationVisible(false);
    setShowModal(false);
  };

  const handleSubmit = async () => {
    changeEmail({
      id: userId,
      body: {
        email: form.values.email,
      },
    })
      .unwrap()
      .then(() => {
        form.resetForm();
        setIsConfirmationVisible(true);
        setUnconfirmedEmailLocal(form.values.email);
      })
      .catch(({ message }) => {
        if (message === "has already been taken") {
          form.setFieldError("email", t("m112", { ns: "errors" }));
        } else {
          addSnackBar({
            title: t("profile.email.sendError"),
            content: message,
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        }
      });
  };

  return (
    <>
      <Box>
        <Title>{t("profile.email.title")}</Title>
        <FunctionalityLine>
          <div>
            <Label margin="80">{t("profile.email.title")}</Label>
            <Value>{previousEmail}</Value>
          </div>
          <div>
            <ResendButton
              show={!!unconfirmedEmail || !!unconfirmedEmailLocal}
              userId={userId}
              email={unconfirmedEmail || unconfirmedEmailLocal}
            />
            <Button variant="secondary" onClick={handleOpenModal}>
              {t("profile.change")}
            </Button>
          </div>
        </FunctionalityLine>
      </Box>
      <Modal
        show={showModal}
        form={form}
        onClose={handleCloseModal}
        handleSubmit={handleSubmit}
      />
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t(`profile.email.confirmation.title`)}
          confirmButtonLabel={t(`profile.email.confirmation.confirm`)}
          description={t(`profile.email.confirmation.description`)}
          onProceed={() => handleCloseModal()}
        />
      )}
    </>
  );
};
