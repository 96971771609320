import React from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import { usePermission } from "app/providers/PermissionsProvider";

const WorkOrderAdminConfigurationFields = [
  "description",
  "priority",
  "categories",
  "assign_to",
  "due_date",
  "location",
  "asset",
  "vendors",
  "checklist",
  "in_review",
];

export const WorkOrdersAdminSettings = () => {
  const { adminSettingsPartPermit } = usePermission();
  const { data } = useGetAdminConfigurationQuery({ type: "work_order" });
  const { data: statusData } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });
  if (!data) return null;

  const workOrderData = { ...data?.data.payload, ...statusData?.data.payload };
  const currentConfiguration = getConfigurationFields(
    WorkOrderAdminConfigurationFields,
    workOrderData
  );

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="work_order"
      additionalType="work_order_status"
      additionalField="in_review"
      hasPermission={adminSettingsPartPermit}
    />
  );
};
