export const getInitialValues = ({ type = "create", data }) => {
  if (!data) {
    return {
      name: "",
    };
  }

  const { name = "" } = data;

  return { name };
};
