import { Pagination } from "features/Pagination";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { useProceduresTableTools } from "modules/procedures/lib/useProceduresTableTools";
import { useGetProcedureTemplatesQuery } from "modules/procedures/state/proceduresApi";
import { isWorkOrderOverdue } from "modules/workOrders/lib/helpers/isWorkOrderOverdue";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "shared/components/Table";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import {
  useSearchParam,
  usePageParam,
  useSortParam,
  useFiltersParam,
} from "shared/lib/hooks";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";
import { TopBar } from "widgets/TopBar";

interface ListProps {
  onOpenProcedureTemplate: (id: string | number) => void;
}

export const List = ({ onOpenProcedureTemplate }: ListProps) => {
  const { t } = useTranslation();
  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const [filters] = useFiltersParam([]);
  const [selected, setSelected] = useState<object[]>([]);

  const { tableSettings, applySettings, columns, areTableSettingsFetching } =
    useProceduresTableTools({
      onOpen: onOpenProcedureTemplate,
    });

  const queryParams = {
    search,
    sort,
    ...stringifyFilters(filters, "procedure_templates"),
  };

  const { data, isFetching } = useGetProcedureTemplatesQuery({
    ...queryParams,
    page,
    per_page: tableSettings?.per_page,
  });

  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);
  const hasFilter = filters && filters.length > 0;
  const notFound =
    data?.data?.length === 0 &&
    !isFetching &&
    search?.length &&
    (search?.length > 0 || hasFilter);

  return (
    <>
      <TopBar
        entity="procedure_templates"
        columns={columns}
        tableSettings={tableSettings}
        filterCount={filters?.length || 0}
        setTableSettings={applySettings}
        getOptions={() => []}
        getSearch={() => []}
        showToggleFilters={true}
        withExport={false}
      />

      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading || areTableSettingsFetching}
        highlight={search}
        notFound={notFound as boolean}
        selectionModel={selected}
        onSelectionModelChange={setSelected}
        bulkActionsNode={null}
        getRowBorder={(row) =>
          isWorkOrderOverdue(row.due_date, row.status?.name)
            ? "var(--color_corp_red)"
            : undefined
        }
        getRowIcon={(_row) => (
          <RowDecoratorImage src={undefined} width="16px" height="16px" />
        )}
      />
      <Pagination
        paginationData={data?.meta.pagination}
        label={t("procedures.title_singular") as string}
      />
    </>
  );
};
