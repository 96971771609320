import React from "react";
import { TextLink } from "shared/components/TextLink";

export const LinksList = ({ links }) => {
  const linksElements = links.map(({ label, url }) => (
    <div key={`${label}-${url}`}>
      {url.startsWith("/") ? (
        <TextLink to={url}>{label}</TextLink>
      ) : (
        <TextLink as="a" href={url} target="_blank">
          {label}
        </TextLink>
      )}
    </div>
  ));

  return <div>{linksElements}</div>;
};
