import styled, { css } from "styled-components";
import { IconButton } from "shared/components/IconButton";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
`;

export const Container = styled.div`
  position: relative;
`;

export const ContentBox = styled.div`
  max-height: calc(100vh - 150px);
  position: relative;
  max-width: 1000px;
  max-height: 700px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
`;

export const Img = styled.img`
  object-fit: cover;
`;

export const Footer = styled.div`
  display: flex;
  position: absolute;
  padding-top: 12px;
  width: 100%;
  height: 40px;
`;

export const Description = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PrimeInfo = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 115%;
  color: var(--color_text_light);
  overflow-wrap: break-word;
`;

export const SecondaryInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color_grey100);
`;

export const CloseIconButton = styled(IconButton).attrs({
  variant: "primary",
})`
  & > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 5px;
  }
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 64px;
  height: 24px;
  margin-left: auto;
  margin-bottom: 10px;
`;

export const PrevRow = css`
  left: -60px;
  & > svg {
    transform: rotate(180deg);
  }
`;

export const NextRow = css`
  right: -60px;
`;

export const RowContainer = styled(IconButton).attrs({
  variant: "transparent",
  background: "light",
})`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  ${({ isPrev }) => isPrev && PrevRow};
  ${({ isNext }) => isNext && NextRow};
`;
