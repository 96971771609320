import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { LightModal } from "shared/components/Modals/LightModal";
import { InlineLabel } from "shared/components/widgets";
import { useForm } from "shared/lib/hooks/useForm";
import { getInitialValues } from "../lib/helpers/form";
import { useParams } from "react-router-dom";
import {
  useEditCustomerMutation,
  useGetCustomerQuery,
} from "modules/customer/state/customerApi";
import { Box } from "./styled";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { validationSchema } from "../config/validationShema";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { getUser, setUser } from "shared/components/Auth";

export const EditCustomerName = ({ isShow, onCloseModal }) => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const addSnackBar = useSnackBar();
  const user = getUser();

  const { data } = useGetCustomerQuery(customerId ?? skipToken);
  const [editCustomer] = useEditCustomerMutation();

  const initialValues = useMemo(() => {
    return getInitialValues({ data });
  }, [data]);

  const { setFieldValue, ...customerForm } = useForm({
    initialValues: initialValues,
    validationSchema: validationSchema(t),
    enableReinitialize: true,
    onSubmit: async (body) => {
      editCustomer({ id: customerId, body })
        .unwrap()
        .then(({ data }) => {
          onCloseModal();
          setUser({ ...user, customerName: data.name });
        })
        .catch(({ message }) => {
          addSnackBar({
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    },
  });

  const onClose = () => {
    customerForm.resetForm();
    onCloseModal();
  };

  return (
    <LightModal
      isOpen={isShow}
      onClose={onClose}
      secondaryButtonLabel={t("discardChanges")}
      label={t("generalSettings.customer.modalLabel")}
      onSubmit={customerForm.handleSubmit}
      disabledSubmit={!customerForm.isWasChanged || !customerForm.isFormValid}
    >
      <Box>
        <InlineLabel
          label={t("generalSettings.customer.companyName")}
          labelWidth={110}
        >
          <Input
            name="name"
            value={customerForm.values?.name}
            onChange={customerForm.handleChange}
            onBlur={customerForm.handleBlur}
            placeholder={t("generalSettings.customer.companyName")}
            errorMessage={customerForm.touched.name && customerForm.errors.name}
            height={40}
            fontSize={14}
          />
        </InlineLabel>
      </Box>
    </LightModal>
  );
};
