import { Pagination } from "features/Pagination";
import { useGetEntitiesQuery } from "modules/entities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "shared/components/Table";
import { usePageParam, useSortParam } from "shared/lib/hooks";

import styled from "styled-components";
import { useAdminReferenceTableTools } from "shared/components/AdministrationReference/helpers/useAdminReferenceTableTools";
import { EmptyList } from "./EmptyList";
import { NoResults } from "shared/components/NoResults";
import { useSearchParam } from "shared/lib/hooks";

export const List = ({
  getDataQuery,
  openDetailsHandler = () => {},
  module,
}) => {
  const { t } = useTranslation();
  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();

  const { currentData: entities } = useGetEntitiesQuery();

  const { data, isLoading = false } = getDataQuery({
    search,
    page,
    sort,
  });

  const { columns } = useAdminReferenceTableTools({
    onOpen: openDetailsHandler,
    module,
    entities: entities?.data,
  });

  const empty = useMemo(
    () => !data?.data?.length && !isLoading,
    [data, isLoading]
  );

  const notFound = useMemo(
    () => data?.data?.length === 0 && !isLoading && search?.length > 0,
    [data, isLoading, search]
  );

  if (notFound) {
    return <NoResults query={search} />;
  }

  if (empty) {
    return <EmptyList module={module} />;
  }

  return (
    <Container>
      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading}
        highlight={search}
      />
      <Pagination
        paginationData={data?.meta.pagination}
        label={t(`settings.referenceLists.${module}.title`)}
        isShowOnlyQuantity={true}
      />
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 113px);
  min-width: 840px;
  margin: 32px auto 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
