import { useGetUserQuery } from "modules/users";
import { skipToken } from "@reduxjs/toolkit/query";
import { getUser } from "shared/components/Auth";
import { useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { LDClient, useLDClient } from "launchdarkly-react-client-sdk";

/** Keep external tools up-to-date when the user changes */
export const UserContextListener = () => {
  const user = getUser() ?? {};
  const { data } = useGetUserQuery(user?.id ?? skipToken);
  const ldClient = useLDClient();

  useEffect(() => {
    if (data && ldClient) {
      updateLDContext(data, ldClient);
    }
  }, [data, ldClient]);

  useEffect(() => {
    updateSentryContext(data);
  }, [data]);

  return null;
};

function updateLDContext(user: any, ldClient: LDClient) {
  if (!user) return;

  if (user.email.includes("@clickmaint.com")) {
    ldClient.identify({
      kind: "multi",
      user: {
        key: user.id,
        email: user.email,
        name: user.full_name,
      },
      organization: {
        key: user.tenant,
      },
    });
  } else {
    ldClient.identify({
      kind: "organization",
      key: user.tenant,
    });
  }
}

function updateSentryContext(user: any) {
  if (user) {
    Sentry.setUser({ email: user.email });
  } else {
    Sentry.setUser(null);
  }
}
