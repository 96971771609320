import { useTranslation } from "react-i18next";
import { DonutChart } from "modules/reporting/components/charts/DonutChart";

import { DonutChartWidgetLayout } from "modules/reporting/components/widgets/DonutChartWidgetLayout";
import { NoData } from "modules/reporting/components/NoData";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatCurrency } from "modules/reporting/lib/formatters";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";

interface MaintenanceHistoryByCostReportWidgetData {
  chart_data: {
    key: string;
    value: number;
  }[];
  other_data: never;
}

export const MaintenanceHistoryByCostReportWidget = () => {
  const sliceConfigs = useSliceConfigs();
  const { language, currency } = useLocaleSettings();

  const { reportPeriod } = useReportPageContext();
  const { data, isFetching } =
    useWidgetData<MaintenanceHistoryByCostReportWidgetData>(
      ReportKey.HistoryByCost,
      reportPeriod
    );

  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  return (
    <DonutChartWidgetLayout>
      <DonutChart.Root
        data={data?.chart_data ?? []}
        isLoading={isFetching}
        sliceConfigs={sliceConfigs}
        valueFormatter={(val) => formatCurrency(val, language, currency)}
      >
        <DonutChart.Legend />
        <DonutChart.Chart>
          <DonutChart.Label />
        </DonutChart.Chart>
      </DonutChart.Root>
    </DonutChartWidgetLayout>
  );
};

function useSliceConfigs() {
  const { t } = useTranslation();
  return {
    parts_cost: {
      fill: "var(--color_light_orange)",
      label: t(`reporting.${ReportKey.HistoryByCost}.labels.parts`),
    },
    labor_cost: {
      fill: "var(--color_mint)",
      label: t(`reporting.${ReportKey.HistoryByCost}.labels.labor`),
    },
    other_cost: {
      fill: "var(--color_salmon)",
      label: t(`reporting.${ReportKey.HistoryByCost}.labels.other`),
    },
  };
}
