import styled, { css } from "styled-components";
import { H2 } from "shared/components/Typography";
import { Link } from "react-router-dom";
import InformationIcon from "../assets/icons/information.svg?url";
import { Button } from "shared/components/Button";

export const DividerStyled = styled.div`
  margin-top: 32px;
`;

export const ActionBox = styled.div`
  & > button {
    width: 100%;

    display: table-cell;
    vertical-align: middle;

    font-size: 1rem;
    font-weight: 500;
  }

  & > a {
    width: 100%;
    display: block;
    text-align: center;

    margin-top: 1.5rem;

    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
      color: var(--color_grey500);
    }
  }
`;

export const LabelStyled = styled.div`
  font-size: 1rem;
  font-weight: 500;

  margin: 2rem 0 0.5rem 0;
`;

export const Title = styled(H2)`
  text-align: left;
  color: var(--color_grey900);
`;

export const SubTitle = styled.div`
  width: 50%;
  font-size: 0.9rem;
  font-weight: 350;
  margin-top: 0.3rem;

  color: var(--color_grey500);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  margin-bottom: 100px;
`;

const description = css`
  p {
    color: var(--color_grey500);
    text-align: center;
    font-size: 14px;
  }
`;

const passwordEmailSent = css`
  & h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  & ${description}
`;

const passwordInvitationExpired = css`
  max-width: 435px;

  & h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  & ${description};
  
  & button {
    width: 100%;
    justify-content: center;
  }
`;

const invitationExpired = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  & button {
    width: 100%;
    justify-content: center;
  }

  & ${description} & p {
    width: 90%;
    text-align: center;
  }
`;

const resetPasswordSuccess = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  & svg {
    margin-bottom: 24px;
  }

  & h2 {
    margin-bottom: 24px;
  }
  & div {
    width: 100%;
  }
`;

const loginLimit = css`
  & h2 {
    text-align: center;
  }
  & ${description} & p {
    text-align: center;
  }
`;

const verifySignupEmail = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    margin-bottom: 24px;
  }
  & h2 {
    text-align: center;
  }
  & ${description} & p {
    text-align: center;
  }
`;

export const Box = styled.div`
  max-width: 420px;
  width: 100%;

  ${({ id }) => id === "verifySignupEmail" && verifySignupEmail}
  ${({ id }) => id === "loginLimit" && loginLimit}
  ${({ id }) => id === "passwordEmailSent" && passwordEmailSent}
  ${({ id }) => id === "resetPasswordSuccess" && resetPasswordSuccess}
  ${({ id }) => id === "invitationExpired" && invitationExpired}
  ${({ id }) => id === "passwordInvitationExpired" && passwordInvitationExpired}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 88px;
  min-height: 42px;
`;

export const LinkStyled = styled(Link)`
  color: rgba(2, 131, 111, 1);
  margin-left: 8px;
  text-decoration: none;
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  color: var(--color_grey500);
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 12px;
`;

export const SessionTimedOutContainer = styled.div`
  position: relative;
  margin: 24px 0;
  padding: 16px 16px 16px 34px;
  background-color: var(--color_light_blue);
  color: var(--color_dark_blue);
  border-radius: 10px;
  font-size: 12px;
  &::before {
    content: url(${InformationIcon});
    position: absolute;
    left: 9px;
    top: 16px;
  }
`;

export const PrivacyPolicyContainer = styled.div`
  font-size: 14px;
  color: var(--color_grey500);
  margin-bottom: 16px;
`;

export const FormOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`;

export const HeaderSignInButton = styled(Button)`
  border-radius: 10px;
`;
