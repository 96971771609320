import { useParams } from "react-router-dom";
import { useLazyGetEntitiesQuery } from "modules/entities";

import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LightModal } from "shared/components/Modals/LightModal";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { validationSchema } from "../config/validationSchema";
import { EditorFields } from "../EditorFields";
import { getEntitiesOptions, getInitialValues, getValues } from "../helpers";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { DeleteReference } from "../Delete";

export const EditReferenceModal = ({
  isShow,
  module,
  onClose,
  updateElement,
  getElement,
  deleteElement,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const { data } = getElement(id ?? skipToken);
  const [trigger, { data: entities }] = useLazyGetEntitiesQuery();
  const [update] = updateElement();

  useEffect(() => {
    if (isShow) {
      trigger();
    }
  }, [isShow, trigger]);

  const entityOptions = useMemo(() => {
    return getEntitiesOptions(entities?.data, module);
  }, [entities]);

  const initialValues = useMemo(
    () =>
      getInitialValues({
        type: "edit",
        module,
        t,
        data: data,
        entities: entities?.data,
      }),
    [t, module, data, entities]
  );

  const onCloseModal = () => {
    form.resetForm();
    onClose();
  };

  const onSubmit = async (body, { setFieldError }) => {
    const element = getValues(body);

    update({ id, body: element })
      .unwrap()
      .then(() => {
        onCloseModal();
      })
      .catch(({ message }) => {
        if (message.name?.[0] === "has already been taken") {
          setFieldError(
            "name",
            t(`settings.referenceLists.${module}.modal.alreadyExist`)
          );
        } else {
          addSnackBar({
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        }
      });
  };

  const { setFieldError, ...form } = useForm({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: validationSchema(t),
  });

  return (
    <LightModal
      isOpen={isShow}
      onClose={onCloseModal}
      onCancel={onCloseModal}
      label={t(`settings.referenceLists.${module}.modalLabelEdit`)}
      onSubmit={form.handleSubmit}
      disabledSubmit={!form.isWasChanged || !form.isFormValid}
      secondaryButtonLabel={t("discardChanges")}
      subButton={
        <DeleteReference
          closeModal={onCloseModal}
          deleteElement={deleteElement}
          variant={module}
          name={data?.name}
        />
      }
    >
      <EditorFields form={form} module={module} options={entityOptions} />
    </LightModal>
  );
};
