import { ReactNode } from "react";
import { RouterProvider } from "react-aria-components";
import { useHref, useNavigate } from "react-router-dom";
import type { NavigateOptions } from "react-router-dom";

declare module "react-aria-components" {
  interface RouterConfig {
    routerOptions: NavigateOptions;
  }
}

interface RACRouterProviderProps {
  children: ReactNode;
}

/**
 * Integrates react-aria-components with react-router
 *
 * [Documentation](https://react-spectrum.adobe.com/react-aria/routing.html#react-router)
 */
export const RACRouterProvider = ({ children }: RACRouterProviderProps) => {
  const navigate = useNavigate();

  return (
    <RouterProvider navigate={navigate} useHref={useHref}>
      {children}
    </RouterProvider>
  );
};
