import React from "react";
import { useTranslation } from "react-i18next";
import { Empty } from "shared/components/Empty";
import AssetsIcon from "shared/assets/icon/assets.svg?react";
import { ModalLink } from "shared/components/ModalLink";
import { usePermission } from "app/providers/PermissionsProvider";

export const EmptyAssetList = () => {
  const { t } = useTranslation();
  const { assetCreatePermit } = usePermission();

  return (
    <Empty
      icon={<AssetsIcon />}
      heading={t("assets.noAssets")}
      description={
        assetCreatePermit && (
          <>
            {t("empty.descriptionUse")}
            <ModalLink modalParams={{ type: "asset", id: "new" }}>
              {t("empty.assets")}
            </ModalLink>
            {t("empty.descriptionButton")}
          </>
        )
      }
      spacious
    />
  );
};
