import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCloneAssetMutation } from "modules/assets";
import {
  useListRows,
  convertToBackFieldsMap,
  convertToFrontFieldsMap,
} from "modules/cloneConfiguration";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";
import { LightModal } from "shared/components/Modals/LightModal";
import { Button } from "shared/components/Button";
import { CloneNumber } from "shared/components/CloneNumber";
import { CloneConfiguration } from "shared/components/CloneConfiguration";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Actions } from "shared/components/Modals";

export const AssetClone = ({ isShow, assetId, onClose }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [count, setCount] = useState(1);
  const [cloneAsset, cloneAssetRes] = useCloneAssetMutation();

  const { data: fieldsConfigurations } = useGetAdminConfigurationQuery(
    isShow
      ? {
          type: "asset",
        }
      : skipToken
  );

  const initialConfiguration = useMemo(
    () => convertToFrontFieldsMap(fieldsConfigurations?.data?.payload || {}),
    [fieldsConfigurations]
  );

  const onCloseModal = () => {
    onClose();
    addForm.resetForm();
  };

  const addForm = useForm({
    initialValues: initialConfiguration,
    enableReinitialize: true,
    onSubmit: async (values) => {
      cloneAsset({
        id: assetId,
        body: { except: convertToBackFieldsMap(values), count: Number(count) },
      })
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            content: t("assets.clone.successMessage"),
            type: "success",
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("assets.failedCreation"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
        });
    },
  });

  const rows = useListRows(addForm.values, "assets");

  return (
    <LightModal
      isOpen={isShow}
      onClose={onCloseModal}
      label={t("assets.clone.title")}
    >
      <Box>
        <CloneNumber count={count} setCount={setCount} entity="assets" />
        <CloneConfiguration
          rows={rows}
          handleSetValue={addForm.handleSetValue}
          values={addForm.values}
          entity="assets"
        />
      </Box>
      <Actions>
        <Button variant="secondary" onClick={onCloseModal}>
          {t("cancel")}
        </Button>
        <Button
          onClick={addForm.handleSubmit}
          isLoading={cloneAssetRes.isLoading || cloneAssetRes.isSuccess}
        >
          {t("create")}
        </Button>
      </Actions>
    </LightModal>
  );
};

AssetClone.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 512px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`;
