import type { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import * as Styled from "./DatePickerHeader.styled";
import { getMonth, getYear } from "date-fns";
import {
  isMonthAllowed,
  clampYear,
} from "shared/components/DatePicker/lib/datePickerUtils";

interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
}

export const DatePickerHeader = ({
  date,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  changeYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  minDate,
  maxDate,
}: DatePickerHeaderProps) => {
  const { t } = useTranslation();

  const interval = { start: minDate, end: maxDate };
  return (
    <Styled.HeaderWrapper>
      <Styled.HeaderControlGroup>
        <Styled.ChevronButton
          aria-label={t("datePicker.previousMonth") as string}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <Styled.Chevron>{"<"}</Styled.Chevron>
        </Styled.ChevronButton>
        <Styled.Select
          value={getMonth(date)}
          onChange={(e) => changeMonth(parseInt(e.target.value))}
        >
          {Array(12)
            .fill(0)
            .map((_, index) => (
              <option
                key={index}
                value={index}
                disabled={
                  !isMonthAllowed(new Date(getYear(date), index), interval)
                }
              >
                {t(`datePicker.months.${index + 1}`)}
              </option>
            ))}
        </Styled.Select>
        <Styled.ChevronButton
          aria-label={t("datePicker.nextMonth") as string}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <Styled.Chevron>{">"}</Styled.Chevron>
        </Styled.ChevronButton>
      </Styled.HeaderControlGroup>
      <Styled.HeaderControlGroup>
        <Styled.ChevronButton
          aria-label={t("datePicker.previousYear") as string}
          onClick={() => changeYear(clampYear(getYear(date) - 1, interval))}
          disabled={prevYearButtonDisabled}
        >
          <Styled.Chevron>{"<"}</Styled.Chevron>
        </Styled.ChevronButton>
        <Styled.Input
          as="input"
          type="number"
          value={getYear(date)}
          min={minDate ? getYear(minDate) : undefined}
          max={maxDate ? getYear(maxDate) : undefined}
          onChange={(e) => {
            const year = parseInt(e.target.value);
            if (!year || !isFinite(year)) return;
            changeYear(year);
          }}
        />
        <Styled.ChevronButton
          aria-label={t("datePicker.nextYear") as string}
          onClick={() => changeYear(clampYear(getYear(date) + 1, interval))}
          disabled={nextYearButtonDisabled}
        >
          <Styled.Chevron>{">"}</Styled.Chevron>
        </Styled.ChevronButton>
      </Styled.HeaderControlGroup>
    </Styled.HeaderWrapper>
  );
};
