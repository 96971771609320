import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import {
  Actions,
  Backdrop,
  Body,
  Box,
  CloseIconButton,
  Header,
  Title,
} from "./Dialog.styled";
import CloseIcon from "../../assets/icon/close.svg?react";

export const Dialog = ({ title, description, onClose, actions, styles }) => {
  return createPortal(
    <Backdrop>
      <Box styles={styles}>
        <Header>
          <Title>{title}</Title>
          {onClose && (
            <CloseIconButton onClick={onClose}>
              <CloseIcon />
            </CloseIconButton>
          )}
        </Header>
        {description && <Body>{description}</Body>}
        {actions && <Actions>{actions}</Actions>}
      </Box>
    </Backdrop>,
    document.querySelector("#app")
  );
};

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func,
  actions: PropTypes.element,
};
