import React from "react";
import { Provider } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  handlePermissionsMiddleware,
  handleUnauthorizedMiddleware,
} from "../../shared/state";

import { authApi } from "modules/auth";
import { portalsApi } from "modules/portals";

import { CMMSAPI } from "app/services";

const ReduxProvider = ({ children }) => {
  const store = configureStore({
    reducer: combineReducers({
      [CMMSAPI.reducerPath]: CMMSAPI.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [portalsApi.reducerPath]: portalsApi.reducer,
    }),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        handleUnauthorizedMiddleware,
        CMMSAPI.middleware,
        authApi.middleware,
        portalsApi.middleware,
        handlePermissionsMiddleware,
      ]),
  });

  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
