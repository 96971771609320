import { useNavigate, useLocation } from "react-router-dom";
import { ModalParams } from "widgets/ModalController/types";
import { encodeModalParam } from "widgets/ModalController/modalParamUtils";

/** A hook for managing global modals, which are rendered by `ModalController` */
export const useSetModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (modalParams?: ModalParams) => {
    if (!modalParams) {
      params.delete("m");
    } else {
      const { type, id, state } = modalParams;
      params.delete("m");
      params.append("m", encodeModalParam(type, id, state));
    }

    navigate({
      pathname: location.pathname,
      search: decodeURI(params.toString()),
    });
  };
};
