import React from "react";
import { Box } from "./Sort.styled";
import ArrowUpIcon from "../../../../assets/icon/arrow-up.svg?react";
import { pattern } from "@test-data";

export const Sort = ({
  sortable,
  field,
  sort,
  onSortModelChange,
  initialSort = "",
}) => {
  const isActive = sort === "asc" || sort === "desc";

  const clickHandler = () => {
    if (onSortModelChange) {
      onSortModelChange(
        `${field}+${(sort || initialSort) === "desc" ? "asc" : "desc"}`
      );
    }
  };

  if (sortable !== true) {
    return null;
  }

  let ariaSort = "none";
  if (isActive) {
    sort === "asc" ? (ariaSort = "ascending") : (ariaSort = "descending");
  }

  return (
    <Box
      upsideDown={isActive && sort === "desc"}
      onClick={clickHandler}
      hidden={!isActive}
      data-testid={pattern.sortButtonId(field)}
      aria-sort={ariaSort}
    >
      <ArrowUpIcon />
    </Box>
  );
};
