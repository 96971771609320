import {
  ListBox as RACListBox,
  ListBoxItem as RACListBoxItem,
} from "react-aria-components";
import styled from "styled-components";
import CheckIcon from "shared/assets/Check.svg?url";

const StyledListBox = styled(RACListBox)`
  overflow: auto;
  padding: 4px;
  outline: none;
  box-sizing: border-box;
` as typeof RACListBox;

const StyledListBoxItem = styled(RACListBoxItem)`
  position: relative;
  margin: 4px;
  padding: 4px 8px 4px 36px;
  border-radius: 4px;
  outline: none;
  cursor: default;
  color: var(--text-color);

  &[data-selected] {
    background: var(--color_primary_hover);
  }

  &[data-hovered] {
    background: var(--color_primary_hover);
  }

  &[data-pressed] {
    background: var(--color_corp_mint_40);
  }

  &[data-focused] {
    outline: 2px solid var(--color_primary_dark);
    outline-offset: 2px;
  }

  &:before {
    content: url(${CheckIcon});
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);

    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
  }

  &[data-selected]:before {
    visibility: visible;
  }
` as typeof RACListBoxItem;

/** https://react-spectrum.adobe.com/react-aria/ListBox.html */
export const ListBox = {
  Root: StyledListBox,
  Item: StyledListBoxItem,
};
export type { Selection } from "react-aria-components";
