import styled from "styled-components";
import { H3, H2, Description, Hint } from "shared/components/Typography";
import { Button } from "shared/components/Button";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  overflow: auto;
  height: calc(100vh - 115px);
`;

export const Box = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid var(--color_header_border);
`;

export const Title = styled(H3)`
  margin-bottom: 16px;
`;

export const FunctionalityLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const Label = styled(Hint)`
  margin: 0 ${({ margin }) => margin}px 0 0;
`;

export const Value = styled(Description)`
  margin: 0;
`;

export const ResendEmailButton = styled(Button)`
  margin-right: 16px;
`;

export const IconContainer = styled.div`
  width: 23px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SessionLine = styled(FunctionalityLine)`
  margin-bottom: 20px;
`;

export const LeaveOrganizationDescription = styled(Value)`
  width: 600px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const NameContainer = styled.div`
  flex: 1;
`;

export const Name = styled(H2)`
  margin-bottom: 5px;
`;

export const LeaveOrganizationDescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 0;
`;

export const PrivacyPolicyContainer = styled.p`
  margin: 0;
  font-size: 12px;
  color: var(--color_grey900);
`;
