import React from "react";
import styled from "styled-components";
import { useDrag, useDrop } from "react-dnd";
import { DragKnob } from "shared/components/DragKnob";
import { Switch } from "shared/components/Switch";
import { pattern } from "@test-data";

export const Column = ({
  index,
  field,
  setFieldVisibility,
  hidden,
  children,
  setFieldOrder,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "table-settings-column-switch",
    item: { field },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: "table-settings-column-switch",
    hover: ({ field: hoverField }) => {
      if (field !== hoverField) {
        setFieldOrder(hoverField, index);
      }
    },
  });

  const switchClickHandler = () => {
    setFieldVisibility(field, !hidden);
  };

  return (
    <Box
      ref={(node) => drag(drop(node))}
      isDragging={isDragging}
      data-testid={pattern.draggableFieldId(field)}
    >
      <DragKnob />
      <Switch
        activated={!hidden}
        onClick={switchClickHandler}
        dataTestId={pattern.visibilitySwitchId(field)}
      >
        {children}
      </Switch>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  gap: 8px;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  padding-top: 8px;
  padding-bottom: 8px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
