import React, { useState } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import EyeIcon from "../../../modules/auth/assets/icons/eye.svg?react";

export const Input = (props) => {
  const {
    name,
    type,
    value,
    onChange,
    onBlur,
    onKeyDown,
    placeholder,
    errorMessage,
    description,
    isTextArea,
    height,
    fontSize,
    disabled,
    required,
    startAddon,
    endAddon,
    isPassword,
    ...rest
  } = props;
  const atr = {
    name,
    type,
    placeholder,
    value,
    onChange,
    onBlur,
    onKeyDown,
    isInvalid: errorMessage,
    height,
    fontSize,
    autoComplete: "off",
    startAddon,
    endAddon,
  };

  const [inputType, setInputType] = useState(type);

  return (
    <InputWithLabelContainer disabled={disabled}>
      {!isTextArea ? (
        <InputContainer>
          {startAddon && <Addon type="start">{startAddon}</Addon>}
          <InputField
            withIcon={isPassword}
            disabled={disabled}
            {...atr}
            {...rest}
            type={inputType}
          />
          {endAddon && <Addon type="end">{endAddon}</Addon>}
          {isPassword && (
            <IconContainer
              isInvalid={!!errorMessage}
              hasValue={!!value}
              onClick={() =>
                setInputType(inputType === "password" ? "text" : "password")
              }
            >
              <EyeIcon />
            </IconContainer>
          )}
        </InputContainer>
      ) : (
        <TextAreaField {...atr} {...rest} />
      )}
      {description && <Description>{description}</Description>}
      {errorMessage && <Error className="error">{errorMessage}</Error>}
    </InputWithLabelContainer>
  );
};

Input.defaultProps = {
  name: "",
  type: "",
  value: "",
  onChange: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onBlur: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onKeyDown: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  placeholder: "",
  errorMessage: "",
  description: "",
  isTextArea: false,
  height: 50,
  fontSize: 20,
  disabled: false,
  required: false,
  startAddon: "",
  endAddon: "",
  isPassword: false,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  description: PropTypes.string,
  isTextArea: PropTypes.bool,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  startAddon: PropTypes.string,
  endAddon: PropTypes.string,
  isPassword: PropTypes.bool,
};

const InputWithLabelContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InputStyle = css`
  font-family: inherit;
  box-sizing: border-box;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "40px")};
  padding-left: 10px;
  outline: transparent;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  border-bottom-left-radius: ${({ startAddon }) => (startAddon ? `0` : `5`)}px;
  border-bottom-right-radius: ${({ endAddon }) => (endAddon ? `0` : `5`)}px;
  border-top-left-radius: ${({ startAddon }) => (startAddon ? `0` : `5`)}px;
  border-top-right-radius: ${({ endAddon }) => (endAddon ? `0` : `5`)}px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color_grey500);
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: var(--color_grey500);
  }

  &:placeholder-shown {
    border-color: var(--color_grey200);
  }

  &:focus {
    box-shadow: 0px 0px 0px 1px var(--color_background_light),
      0px 0px 0px 3px var(--color_primary);

    &,
    &:placeholder-shown {
      border-color: var(--color_grey900);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: var(--color_grey10);

      &,
      &:hover,
      &:placeholder-shown {
        border-color: var(--color_grey100);
      }

      &,
      &::placeholder {
        color: var(--color_grey500);
      }
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      &,
      &:placeholder-shown {
        border-width: 2px;
        border-color: var(--color_alert);
      }
    `}
`;

const InputField = styled.input`
  ${InputStyle};
  border-right: ${({ withIcon }) =>
    withIcon ? "none" : `1px solid var(--color_grey500)`};
  border-top-right-radius: ${({ withIcon, endAddon }) =>
    withIcon || endAddon ? "0" : "5px"};
  border-bottom-right-radius: ${({ withIcon, endAddon }) =>
    withIcon || endAddon ? "0" : "5px"};
`;

const TextAreaField = styled.textarea`
  ${InputStyle}
  padding-top: 8px;
  resize: vertical;
  height: ${({ height }) => (height ? `${height}px` : "60px")};
  min-height: ${({ height }) => (height ? `${height}px` : "40px")};
`;

const Description = styled.div`
  padding-top: 5px;
  padding-left: 25px;
  color: var(--color_placeholder);
  font-size: 14px;
`;

const Error = styled.div`
  position: absolute;
  color: var(--color_alert);
  font-size: 14px;
  top: calc(100% + 6px);
`;

const Addon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: 52px;
  background: #fbfafd;
  border: 1px solid var(--color_grey200);
  border-radius: ${({ type }) =>
    type === "start" ? `4px 0px 0px 4px` : `0px 4px 4px 0px`};
  font-size: 14px;
  color: var(--color_grey900);
  border-right-width: ${({ type }) => (type === "start" ? `0` : `1px`)};
  border-left-width: ${({ type }) => (type === "end" ? `0` : `1px`)};
`;

const InputContainer = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  border: 1px solid rgb(191, 191, 195);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  ${({ hasValue }) =>
    hasValue &&
    css`
      border-color: grey;
    `}
  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border-width: 2px;
      border-color: var(--color_alert);
    `};
`;
