import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const workOrderOtherCostsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getWorkOrderOtherCosts: builder.query({
      query: (params) => ({
        url: "/work_order_other_costs",
        params,
      }),
      providesTags: (result) => [
        { type: "WorkOrderOtherCost", id: "WorkOrderOtherCostsList" },
        ...getTagList("WorkOrderOtherCost", result?.data),
      ],
    }),
    addWorkOrderOtherCost: builder.mutation({
      query: (body) => ({
        url: "work_order_other_costs",
        method: "POST",
        body,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: [
        "WorkOrderDetails",
        { type: "WorkOrderOtherCost", id: "WorkOrderOtherCostsList" },
        { type: "Report" },
      ],
    }),
    updateWorkOrderOtherCost: builder.mutation({
      query: ({ id, body }) => ({
        url: `work_order_other_costs/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        "WorkOrderDetails",
        { type: "WorkOrderOtherCost", id },
        { type: "WorkOrderOtherCostsList", id },
        { type: "Report" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteWorkOrderOtherCost: builder.mutation({
      query: (id) => ({
        url: `/work_order_other_costs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        "WorkOrderDetails",
        { type: "WorkOrderOtherCost", id: "WorkOrderOtherCostsList" },
        { type: "Report" },
      ],
    }),
  }),
});

export const {
  useGetWorkOrderOtherCostsQuery,
  useAddWorkOrderOtherCostMutation,
  useUpdateWorkOrderOtherCostMutation,
  useDeleteWorkOrderOtherCostMutation,
} = workOrderOtherCostsApi;
