import { getUrl } from "./getUrl";
import { isAuthenticated, getUser } from "shared/components/Auth";

export const getBaseApiUrl = (path = "", hostname = true) => {
  const prefixedPath = `/api/v1${path}`;
  const user = getUser();

  const tenant = isAuthenticated() && user ? user.tenant : "tenant1"; // TODO: remove default tenant with feature flag

  return hostname ? getUrl(tenant, prefixedPath) : prefixedPath;
};
