import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  useLazyGetPortalFormFieldsQuery,
  useAddPortalMutation,
} from "modules/portals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import {
  portalValidationSchema,
  getOptionalFields,
  getPortalFormatForService,
} from "../../lib/helpers/form";
import { PortalCreateInitialValues } from "../../lib/constants";
import { PortalEditor } from "../PortalEditor";
import { SuccessfulBlock } from "./SuccessfulBlock";

export const PortalCreate = ({ requestId, entity, allow_anonymous }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { addSnackBar } = useSnackBar();
  const [addPortal, addPortalRes] = useAddPortalMutation();
  const [isSuccess, setIsShowSuccess] = useState(false);

  const [trigger, { data }] = useLazyGetPortalFormFieldsQuery();

  const optionalFields = getOptionalFields({ fields: data });

  useEffect(() => {
    trigger({
      type: "work_order_requests",
      action_type: "upsert",
      id,
    });
  }, [trigger, id]);

  const resetForm = () => {
    addPortalForm.resetForm({
      values: {
        ...PortalCreateInitialValues,
        portal_user_name: addPortalForm.values.portal_user_name,
        portal_user_surname: addPortalForm.values.portal_user_surname,
        portal_user_phone_number: addPortalForm.values.portal_user_phone_number,
        portal_user_email: addPortalForm.values.portal_user_email,
        notify: addPortalForm.values.notify,
      },
    });
  };

  const addPortalForm = useForm({
    initialValues: PortalCreateInitialValues,
    validationSchema: portalValidationSchema(
      t,
      optionalFields,
      allow_anonymous
    ),
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const portal = getPortalFormatForService(body, requestId);
      addPortal({ body: portal, id })
        .unwrap()
        .then(() => {
          setIsShowSuccess(true);
          resetForm();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            setFieldError("title", t("m11", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("portal.snack.failCreationTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  return (
    <Container>
      {isSuccess ? (
        <SuccessfulBlock onClick={() => setIsShowSuccess(false)} />
      ) : (
        <PortalEditor
          form={addPortalForm}
          optionalFields={optionalFields}
          onSubmit={addPortalForm.handleSubmit}
          disabledSubmit={
            !addPortalForm.dirty || !!Object.values(addPortalForm.errors).length
          }
          isSubmitLoading={addPortalRes.isLoading}
          entity={entity}
          allow_anonymous={allow_anonymous}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`;
