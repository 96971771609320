import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { useLazyGetPMsQuery } from "modules/workOrders";
import OnPausedIcon from "../../assets/icons/bulkPause.svg?react";
import OnPlannedIcon from "../../assets/icons/bulkResume.svg?react";

const IconMap = {
  paused: OnPausedIcon,
  planned: OnPlannedIcon,
};

export const BulkStatus = ({ ids = [], onUpdate, status, count }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [selectedPMs, setSelectedPMs] = useState([]);
  const [trigger] = useLazyGetPMsQuery();

  const Icon = IconMap[status];

  const cancelHandler = () => {
    setSelectedPMs([]);
  };

  const openHandler = () => {
    generateHandle();
  };

  const generateHandle = () => {
    trigger({
      "filter[id_in]": ids,
      "filter[status_eq]": status === "paused" ? "planned" : "paused",
      format_resource: "light",
    })
      .unwrap()
      .then(({ data }) => {
        if (data && data.length > 0) {
          setSelectedPMs(data);
        } else {
          addSnackBar({
            content: t(
              `preventive_maintenances.bulkActions.updateStatus.validationError.${status}`
            ),
            type: "warning",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  const proceedHandler = async () => {
    onUpdate({ ids, status })
      .unwrap()
      .then(() => {
        setSelectedPMs([]);
      })
      .catch(() => {
        addSnackBar({
          title: t("preventive_maintenances.bulkActions.updateStatus.fail"),
          type: "error",
        });
      });
  };

  return (
    <>
      <StyledIconButton onClick={openHandler}>
        <Icon data-tooltip-id={`icon-tooltip-${status}`} />
        <ReactTooltip
          id={`icon-tooltip-${status}`}
          place="bottom"
          content={t(`preventive_maintenances.bulkActions.hint.${status}`)}
          opacity="1"
        />
      </StyledIconButton>
      {selectedPMs.length > 0 && (
        <ConfirmationDialog
          title={t(
            `preventive_maintenances.bulkActions.updateStatus.confirmation.title.${status}`
          )}
          cancelButtonLabel={t("discardChanges")}
          description={
            <RequestContainer>
              <RequestsSelected>
                {t(
                  `preventive_maintenances.bulkActions.updateStatus.confirmation.selected_${
                    selectedPMs.length > 1 ? "many" : "one"
                  }`,
                  {
                    count: selectedPMs.length,
                    status: status === "paused" ? "Planned" : "Paused",
                  }
                )}
              </RequestsSelected>
            </RequestContainer>
          }
          confirmButtonLabel={t(
            `preventive_maintenances.bulkActions.updateStatus.confirmation.confirmButtonLabel.${status}`
          )}
          onProceed={proceedHandler}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

BulkStatus.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onUpdate: PropTypes.func.isRequired,
  status: PropTypes.string,
  count: PropTypes.number,
};

const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const RequestsSelected = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
  margin-bottom: 16px;
`;

const StyledIconButton = styled(IconButton)`
  & svg {
    outline: none;
  }
`;
