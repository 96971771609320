import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useDisplayDate } from "shared/lib/hooks";
import { getUser } from "shared/components/Auth";

import {
  Name,
  Content,
  Box,
  NameContainer,
  Info,
  Created,
  Container,
  Edit,
} from "./CommentView.styled";
import { CommentDelete } from "../CommentDelete";
import { CommentUpdate } from "../CommentUpdate";
import { UserAvatar } from "shared/components/UserAvatar";
import { ShiftBy } from "shared/components/ShiftBy";

export const CommentView = ({
  value = {},
  onDelete,
  onEdit,
  type,
  updateCommentPermit,
  deleteCommentPermit,
}) => {
  const displayDate = useDisplayDate();
  const { t } = useTranslation();
  const currentUser = getUser();
  const { user, created_at, updated_by_id, content, id } = value;

  const userName = `${user.name} ${user.surname}`;

  return (
    <Container>
      <Box>
        <ShiftBy y={4}>
          <UserAvatar user={user} size="md" />
        </ShiftBy>
        <Info>
          <NameContainer>
            <Name>
              {userName} {updated_by_id && t("work_orders.comments.edited")}
            </Name>
            {currentUser.id === user.id && (
              <Edit>
                <Created>{displayDate(created_at)}</Created>
                {updateCommentPermit && (
                  <CommentUpdate data={value} onEdit={onEdit} type={type} />
                )}
                {deleteCommentPermit && (
                  <CommentDelete id={id} onDelete={onDelete} type={type} />
                )}
              </Edit>
            )}
          </NameContainer>
          <Content>{content}</Content>
        </Info>
      </Box>
    </Container>
  );
};

CommentView.propTypes = {
  value: PropTypes.shape({
    content: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.number,
    target_id: PropTypes.number,
    target_type: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};
