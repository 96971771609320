import { useTranslation } from "react-i18next";

import { Button } from "shared/components/Button";
import { Header } from "../Header";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useResendConfirmEmailMutation } from "modules/auth/state/authApi";

import { Box, Container, Title } from "../styled";

export const SignUpEmailExpired = ({ email }) => {
  const { t } = useTranslation();
  const [resendConfirmEmail] = useResendConfirmEmailMutation();
  const { addSnackBar } = useSnackBar();

  const onSubmit = async (email) => {
    resendConfirmEmail({ email })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleClick = () => {
    onSubmit(email);
  };

  return (
    <>
      <Header showAction />
      <Container>
        <Box id="passwordInvitationExpired">
          <Title>{t("auth.signUp.verificationEmailExpired")}</Title>
          <p>{t("auth.signUp.verificationEmailExpiredDescription")}</p>

          <Button type="primary" size="normal" onClick={handleClick}>
            {t("auth.signUp.verificationEmailExpiredButton")}
          </Button>
        </Box>
      </Container>
    </>
  );
};
