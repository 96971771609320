import { getBaseApiUrl } from "shared/lib/apiTools/getBaseApiUrl";
import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const locationsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (params) => ({
        url: "locations",
        params,
      }),
      providesTags: (result, error, { sort }) => [
        { type: "Entity" },
        { type: "Location", id: "LocationList", sort },
        ...getTagList("Location", result?.data),
      ],
    }),
    addLocation: builder.mutation({
      query: (bodyFormData) => ({
        url: "locations",
        method: "POST",
        body: bodyFormData,
        formData: true,
      }),
      invalidatesTags: [
        { type: "Location", id: "LocationList" },
        { type: "Location", id: "LocationListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getLocation: builder.query({
      query: (id) => `/locations/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "LocationDetails", id: query },
      ],
    }),
    deleteLocation: builder.mutation({
      query: (id) => ({
        url: `/locations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        { type: "Location", id: "LocationList" },
        { type: "Location", id: "LocationListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteLocations: builder.mutation({
      query: (ids) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/locations/${id}`, false),
            method: "DELETE",
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, ids) => [
        { type: "Location", id: "LocationList" },
        { type: "Location", id: "LocationListFull" },
        ...ids.map((id) => ({ type: "Location", id })),
      ],
    }),
    updateLocation: builder.mutation({
      query: ({ id, body }) => ({
        url: `locations/${id}`,
        method: "PATCH",
        body,
        formData: true,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Location", id },
        { type: "LocationDetails", id },
        { type: "Location", id: "LocationListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getAllLocations: builder.query({
      query: (params) => ({
        url: "/locations",
        params: { format_resource: "light", per_page: 10000, ...params },
      }),
      providesTags: [
        { type: "Entity" },
        { type: "Location", id: "LocationListFull" },
      ],
      transformResponse: (response) => response.data,
    }),
    getLocationHierarchicalList: builder.query({
      query: (id) => ({
        url: `/locations/${id}`,
        params: { format_resource: "hierarchy_tree" },
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "LocationDetails", id: query },
      ],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useGetLocationQuery,
  useAddLocationMutation,
  useDeleteLocationMutation,
  useDeleteLocationsMutation,
  useUpdateLocationMutation,
  useGetAllLocationsQuery,
  useLazyGetAllLocationsQuery,
  useGetLocationHierarchicalListQuery,
} = locationsApi;
