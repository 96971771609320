import { useTranslation } from "react-i18next";
import { ChangeStatusEditor } from "modules/downtimeTracking";
import { ConfirmationDialog } from "shared/components/Dialog";
import { DowntimeDialogProps } from "./types";

export function DowntimeDialog({
  form,
  endedAtRequired,
  workOrderId,
  workOrderName,
  onCancel,
  firstEventStart,
  recentOnlineEventStart,
  workOrderCompletedAt,
}: DowntimeDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t("assets.downtime_tracking.updateManually")}
      confirmButtonLabel={t("save") ?? undefined}
      cancelButtonLabel={t("cancel") ?? undefined}
      disabledSubmit={!!Object.values(form.errors).length || !form.dirty}
      description={
        <ChangeStatusEditor
          form={form}
          type="manual"
          endedAtRequired={endedAtRequired}
          workOrderId={workOrderId}
          workOrderName={workOrderName}
          firstEventStart={firstEventStart}
          recentOnlineEventStart={recentOnlineEventStart}
          workOrderCompletedAt={workOrderCompletedAt}
        />
      }
      onProceed={form.handleSubmit}
      onCancel={onCancel}
    />
  );
}
