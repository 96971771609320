import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";
import { ProcedureTemplateCreate } from "./ProcedureTemplateCreate";
import { ProcedureTemplateEdit } from "./ProcedureTemplateEdit";
import { ProcedureTemplateView } from "./ProcedureTemplateView";

interface ProcedureTemplateModalProps {
  params: ModalParams;
}

export const ProcedureTemplateModal = ({
  params,
}: ProcedureTemplateModalProps) => {
  const { type, id, state = "view" } = params;
  const { procedureTemplateCreatePermit, procedureTemplateViewPermit } =
    usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new" && procedureTemplateCreatePermit) {
    return <ProcedureTemplateCreate isOpen={true} onClose={onClose} />;
  } else if (state === "view" && procedureTemplateViewPermit) {
    return (
      <ProcedureTemplateView
        isOpen={true}
        onClose={onClose}
        onEdit={() => setModal({ type, id, state: "edit" })}
        procedureTemplateId={id}
      />
    );
  } else if (state === "edit") {
    return (
      <ProcedureTemplateEdit
        isOpen={true}
        onClose={onView}
        procedureTemplateId={id}
      />
    );
  }

  return null;
};
