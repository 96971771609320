import { mergeObjects } from "shared/lib/helpers";
import {
  getFormDataFromJSON,
  addFilesForFormData,
  addQrCodeForFormData,
} from "shared/lib/helpers/formData";
import { LocationsFormOptionalFields } from "../configs/constants";

export const getOptionalFields = (fieldsData) => {
  if (!fieldsData || fieldsData?.status !== "success") {
    return {};
  }

  const { data } = fieldsData;

  return LocationsFormOptionalFields.reduce((acc, optionalField) => {
    const field = data.find(({ name }) => name === optionalField);
    if (field) {
      const { required } = field;
      acc[optionalField] = { required };
    }
    return acc;
  }, {});
};

export const getFormatForForm = (isShow, locationData, initialValue) => {
  return isShow && locationData
    ? {
        ...mergeObjects(initialValue, locationData),
        parent: locationData.parent
          ? { value: locationData.parent?.id, label: locationData.parent?.name }
          : "",
        // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
        // teams_ids: (locationData.teams || []).map((team) => team.id),
        uploads_attributes: [
          ...locationData.images.map((file) => ({ ...file, isImage: true })),
          ...locationData.documents,
        ],
        code: locationData?.qr_code ?? "",
      }
    : initialValue;
};

export const getFormatForService = (body, options = {}) => {
  const { uploads_attributes, code, parent, ...bodyAtr } = body;
  const formData = getFormDataFromJSON({
    ...bodyAtr,
    parent_id: parent?.value || "",
  });
  addFilesForFormData(formData, uploads_attributes);
  addQrCodeForFormData(formData, code, options);
  return formData;
};
