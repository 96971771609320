import React from "react";
import PropTypes from "prop-types";
import { Box, Description, Heading, IconBox } from "./Empty.styled";

export const Empty = ({ icon, heading, description, spacious }) => {
  return (
    <Box>
      <IconBox spacious={spacious}>{icon}</IconBox>
      <Heading>{heading}</Heading>
      {description && <Description>{description}</Description>}
    </Box>
  );
};

Empty.propTypes = {
  icon: PropTypes.element.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  spacious: PropTypes.bool,
};
