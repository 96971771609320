import React from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "shared/components/Empty";
import PartsIcon from "../../assets/icons/empty.svg?react";

export const ErrorPartList = () => {
  const { t } = useTranslation();

  return <Empty icon={<PartsIcon />} heading={t("parts.error")} spacious />;
};
