import { IconButton } from "shared/components/IconButton";
import styled from "styled-components";

export const NameBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const Role = styled.div`
  color: var(--color_grey600);
  font-size: 12px;
`;

export const EmailBox = styled.div`
  display: flex;
  gap: 4px;
  color: var(--color_primary_text);
  align-items: baseline;
`;

export const PhoneBox = styled.div`
  word-break: break-word;
  color: var(--color_text_color);
`;

export const ContactBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  line-height: 20px;
  font-size: 14px;
`;

export const PrimaryBox = styled.div`
  background: var(--color_dark_blue);
  padding: 0px 8px 0px 8px;
  border-radius: 12px;
  height: 16px;
  width: 115px;
  font-size: 12px;
  color: var(--color_text_light);
  line-height: 16px;
`;

export const Container = styled.div`
  background: var(--color_grey50);
  padding: 12px;
  border-radius: 4px;
  display: flex;
`;

export const InputBox = styled.div`
  width: 100%;
  background: var(--color_text_light);
  padding: 16px;
  border: 2px solid var(--color_text_light);
  border-radius: 2px;
  position: relative;

  &:hover {
    background: var(--color_primary_hover);
    border: 2px solid var(--color_primary_dark);

    & .editIcon {
      display: flex;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
`;
