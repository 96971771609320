import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { Box, Title, ActionBox, Container } from "../styled";
import LockIcon from "../../assets/icons/LockIcon.svg?react";
import { useNavigate } from "react-router-dom";

export const ResetPasswordSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Box id="resetPasswordSuccess">
        <LockIcon />
        <Title>{t("auth.resetPassword.resetComplete")}</Title>
        <ActionBox>
          <Button
            type="primary"
            size="normal"
            onClick={() => navigate("/login")}
          >
            {t("auth.resetPassword.backToSignInButton")}
          </Button>
        </ActionBox>
      </Box>
    </Container>
  );
};
