import { Button as UIButton } from "shared/components/Button";
import { Modal } from "shared/components/Modals";
import styled from "styled-components";

export const NoInternetModalBox = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 71px 0 45px;

  & p {
    margin: 25px 0 0;
    text-align: center;
    font-size: 18px;
  }
`;

export const NoInternetModalWrapper = styled(Modal)`
  border-radius: 14px;
`;

export const Button = styled(UIButton)`
  width: 100%;
  justify-content: center;
  border-radius: inherit;
`;
