import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { EditorModal } from "shared/components/Modals";

export const DarkDialog = ({
  children,
  label,
  onClose,
  isOpen,
  onSubmit,
  primaryButtonLabel,
  disabledSubmit,
}) => {
  return createPortal(
    <EditorModal
      isOpen={isOpen}
      label={label}
      onClose={onClose}
      onSubmit={onSubmit}
      primaryButtonLabel={primaryButtonLabel}
      disabledSubmit={disabledSubmit}
    >
      {children}
    </EditorModal>,
    document.querySelector("body")
  );
};

DarkDialog.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  disabledSubmit: PropTypes.bool,
};
