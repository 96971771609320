import { useTranslation } from "react-i18next";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { Pagination } from "features/Pagination";
import { CreatedVsCompletedTableData } from "./types";
import { PaginationData } from "types/Response";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { Status } from "shared/components/Status";
import { useDisplayDate } from "shared/lib/hooks";

const reportKey = ReportKey.CreatedVsCompleted;

interface CreatedVsCompletedReportTableProps {
  data?: CreatedVsCompletedTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const CreatedVsCompletedReportTable = ({
  data,
  paginationData,
  isFetching,
}: CreatedVsCompletedReportTableProps) => {
  const { t } = useTranslation();
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  return (
    <>
      <Table
        columns={columns}
        rows={data}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(): TableColumn<CreatedVsCompletedTableData>[] {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  return [
    {
      field: "title",
      name: t(`reporting.${reportKey}.columns.title`),
      minWidth: 400,
      sortable: true,
    },
    {
      field: "status",
      name: t(`reporting.${reportKey}.columns.status`),
      minWidth: 200,
      renderCell: (row) => <Status value={row.status} />,
    },
    {
      field: "created_at",
      name: t(`reporting.${reportKey}.columns.created_at`),
      minWidth: 200,
      sortable: true,
      // @ts-ignore: TODO: fix displayDate types
      formatValue: (val) => displayDate(val, true),
    },
    {
      field: "work_order_type",
      name: t(`reporting.${reportKey}.columns.work_order_type`),
      minWidth: 150,
      sortable: true,
      formatValue: (val) => t(`work_orders.type.${val}`),
    },
    {
      field: "assigned_to",
      name: t(`reporting.${reportKey}.columns.assigned_to`),
      minWidth: 200,
      renderCell: (row) => (
        <UserAvatarGroup users={row.assigned_to.technicians} max={4} compact />
      ),
    },
    {
      field: "category",
      name: t(`reporting.${reportKey}.columns.category`),
      minWidth: 200,
      formatValue: (cat) => cat.join(", "),
    },
    {
      field: "location",
      name: t(`reporting.${reportKey}.columns.location`),
      minWidth: 200,
    },
    {
      field: "asset",
      name: t(`reporting.${reportKey}.columns.asset`),
      minWidth: 200,
    },
    {
      field: "vendor",
      name: t(`reporting.${reportKey}.columns.vendor`),
      minWidth: 200,
      formatValue: (vendors) => vendors.join(", "),
    },
    {
      field: "created_by",
      name: t(`reporting.${reportKey}.columns.created_by`),
      minWidth: 200,
    },
    {
      field: "completed_at",
      name: t(`reporting.${reportKey}.columns.completed_at`),
      minWidth: 200,
      // @ts-ignore: TODO: fix displayDate types
      formatValue: (date) => displayDate(date, true),
      sortable: true,
    },
  ];
}
