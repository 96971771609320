import { TooltipProps } from "recharts";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatPercent } from "../../../lib/formatters";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

/**
 * Custom Recharts Tooltip content. Props are automatically passed by Recharts.
 *
 * [Recharts documentation](https://recharts.org/en-US/api/Tooltip)
 */
export const MaintenanceHistoryByAssigneeReportWidgetTooltip = ({
  active,
  payload,
  label,
  formatter,
}: TooltipProps<number, string>) => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();
  if (!active || !payload) {
    return null;
  }

  const buildTooltipRow = (
    payload: Payload<number, string>[],
    key: string,
    formatter?: (value: number) => string
  ) => {
    if (payload.length === 0 || !payload[0].payload[key]) {
      return null;
    }
    let value: string | number = payload[0].payload[key];
    if (formatter) {
      value = formatter(Number(value));
    }
    return (
      <span key={key}>
        {t(`reporting.history_by_assignee.tooltip.${key}`, { value: value })}
      </span>
    );
  };

  const percentageFormatter = (value: number) => {
    return formatPercent(value, language);
  };

  return (
    <TooltipWrapper>
      <span>{label}</span>
      {buildTooltipRow(payload, "completed_work_orders")}
      {buildTooltipRow(payload, "total_labor_hours")}
      {buildTooltipRow(
        payload,
        "total_regular_hours_percentage",
        percentageFormatter
      )}
      {buildTooltipRow(
        payload,
        "total_overtime_hours_percentage",
        percentageFormatter
      )}
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid var(--color_grey200);
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;

  padding: 8px;
`;
