import styled from "styled-components";

interface InlineProgressBarProps {
  value: number;
  maxValue: number;
  color: string;
}

export const InlineProgressBar = ({
  value,
  maxValue,
  color,
}: InlineProgressBarProps) => {
  return (
    <Wrapper>
      <ProgressBar
        color={color}
        pctWidth={Math.min((value / maxValue) * 100, 100)}
      />
    </Wrapper>
  );
};

interface ProgressProps {
  color: string;
  pctWidth: number;
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
`;

const ProgressBar = styled.div<ProgressProps>`
  position: relative;
  width: 100%;
  height: 6px;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${(p) => `${p.pctWidth}%`};

    background-color: ${(p) => p.color};
  }
`;
