import styled, { css } from "styled-components";
import { IconButton } from "shared/components/IconButton";

export const Container = styled.div`
  height: ${({ compressed }) => (compressed ? "48px" : "80px")};
  box-sizing: border-box;
  display: flex;
  border: 1px dashed var(--color_grey400);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &: hover {
    border: 2px dashed var(--color_primary_text);
  }
  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: 0px 0px 0px 1px var(--color_text_light),
        0px 0px 0px 3px var(--color_primary_dark);
    `}
  ${({ isFileHover }) =>
    isFileHover &&
    css`
      background-color: var(--color_primary_hover);
    `}
`;

export const ButtonContainer = styled.div`
  height: ${({ compressed }) => (compressed ? "48px" : "80px")};
  box-sizing: border-box;
  display: flex;
  border: 1px dashed var(--color_grey400);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &: hover {
    border: 2px dashed var(--color_primary_text);
  }
  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: "0px 0px 0px 1px var(--color_text_light), 0px 0px 0px 3px var(--color_primary_dark);
    `}
  ${({ isFileHover }) =>
    isFileHover &&
    css`
      background-color: var(--color_primary_hover);
    `}
`;

export const Icon = styled(IconButton)`
  margin-right: 10px;
  color: var(--color_primary_text);
`;
export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const ClickableText = styled.span`
  color: var(--color_primary_text);
  text-decoration: underline;
  margin-left: 5px;
`;
