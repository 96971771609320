import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useSearch } from "shared/lib/hooks/useSearch";

import { useGetEntityUsersQuery } from "modules/users";

import { WorkOrderTimeCreate } from "../WorkOrderTimeCreate";
import {
  Box,
  Title,
  StyledButton,
  Wrapper,
  ActionButtons,
  TimerBox,
  StopButton,
} from "./WorkOrderTimeList.styled";
import { WorkOrderTimeView } from "../WorkOrderTimeView";
import PlayIcon from "../../assets/icons/play.svg?react";

export const WorkOrderTimeList = ({
  value = [],
  onCreate,
  onUpdate,
  onDelete,
  onStartTimer,
  onStopTimer,
  columns,
  titleFontSize,
  timer,
  isWOActiveTimer,
  isUserActiveTimer,
  withTimer = true,
  permissions = {
    add: true,
    delete: true,
    startTimer: true,
  },
  techniciansIds,
  type,
  status,
}) => {
  const { t } = useTranslation();
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);

  const onCloseCreateForm = () => setIsOpenCreateForm(false);
  const onOpenCreateForm = () => setIsOpenCreateForm(true);

  const [users = { data: [] }, onUsersSearch] = useSearch(
    useGetEntityUsersQuery,
    true,
    {},
    "full_name"
  );

  const usersOptions = useMemo(
    () =>
      users.data
        .filter((user) => techniciansIds.includes(user.id))
        .map(({ id: value, full_name: label }) => ({
          value,
          label,
        })),
    [users, techniciansIds]
  );

  return (
    <>
      <Box>
        <Title fontSize={titleFontSize}>{t("work_order_time.title")}</Title>
        <ActionButtons>
          {withTimer && (
            <>
              {isWOActiveTimer ? (
                <StopButton onClick={onStopTimer} variant="secondary">
                  <TimerBox>{`${t(
                    "work_order_time.stop"
                  )} (${timer})`}</TimerBox>
                </StopButton>
              ) : (
                permissions?.startTimer && (
                  <StyledButton
                    onClick={onStartTimer}
                    variant="secondary"
                    disabled={isUserActiveTimer}
                  >
                    <TimerBox>
                      <PlayIcon />
                      {t("work_order_time.start")}
                    </TimerBox>
                  </StyledButton>
                )
              )}
            </>
          )}
          {permissions.add && (
            <StyledButton onClick={onOpenCreateForm} variant="secondary">
              {t("work_order_time.add")}
            </StyledButton>
          )}
        </ActionButtons>
      </Box>
      <Wrapper>
        {value.map((val, index) => (
          <WorkOrderTimeView
            key={`${val.amount}-${index}`}
            value={val}
            index={index}
            users={users}
            usersOptions={usersOptions}
            onUsersSearch={onUsersSearch}
            onUpdate={onUpdate}
            onDelete={onDelete}
            columns={columns}
            type={type}
            status={status}
            techniciansIds={techniciansIds}
          />
        ))}
      </Wrapper>
      {isOpenCreateForm && (
        <WorkOrderTimeCreate
          onCreate={onCreate}
          onClose={onCloseCreateForm}
          usersOptions={usersOptions}
          onUsersSearch={onUsersSearch}
          permissions={permissions}
        />
      )}
    </>
  );
};

WorkOrderTimeList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number,
      amount: PropTypes.string,
      rate_type: PropTypes.string,
    })
  ),
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  titleFontSize: PropTypes.string,
  columns: PropTypes.number,
  timer: PropTypes.string,
  isWOActiveTimer: PropTypes.bool,
  isUserActiveTimer: PropTypes.bool,
};
