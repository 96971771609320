import { useTranslation } from "react-i18next";

import { NoData } from "modules/reporting/components/NoData";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";
import { DonutChartWidgetLayout } from "modules/reporting/components/widgets/DonutChartWidgetLayout";
import { DonutChart } from "modules/reporting/components/charts/DonutChart";

interface ReactiveVsPreventiveReportWidgetData {
  chart_data: {
    key: string;
    value: number;
  }[];
  other_data: never;
}

export const ReactiveVsPreventiveReportWidget = () => {
  const sliceConfigs = useSliceConfigs();

  const { reportPeriod } = useReportPageContext();
  const { data, isFetching } =
    useWidgetData<ReactiveVsPreventiveReportWidgetData>(
      ReportKey.ReactiveVsPreventive,
      reportPeriod
    );

  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  return (
    <DonutChartWidgetLayout>
      <DonutChart.Root
        data={data?.chart_data ?? []}
        isLoading={isFetching}
        sliceConfigs={sliceConfigs}
      >
        <DonutChart.Legend />
        <DonutChart.Chart>
          <DonutChart.Label />
        </DonutChart.Chart>
      </DonutChart.Root>
    </DonutChartWidgetLayout>
  );
};

function useSliceConfigs() {
  const { t } = useTranslation();
  return {
    reactive: {
      fill: "var(--color_salmon)",
      label: t(`reporting.${ReportKey.ReactiveVsPreventive}.labels.reactive`),
    },
    preventive: {
      fill: "var(--color_mint)",
      label: t(`reporting.${ReportKey.ReactiveVsPreventive}.labels.preventive`),
    },
  };
}
