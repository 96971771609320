import { ReportPeriod } from "modules/reporting/types/ReportPeriod";

import {
  format,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  subDays,
  formatISO,
} from "date-fns/fp";
import flow from "lodash/fp/flow";
import { endOfToday } from "date-fns";

const f = "yyyy-MM-dd";
const today = formatISO(endOfToday());
export const reportPeriodOptions: ReportPeriod[] = [
  {
    value: "thisWeek",
    startDate: flow(startOfWeek, format(f))(new Date()),
    endDate: today,
  },
  {
    value: "thisMonth",
    startDate: flow(startOfMonth, format(f))(new Date()),
    endDate: today,
  },
  {
    value: "thisQuarter",
    startDate: flow(startOfQuarter, format(f))(new Date()),
    endDate: today,
  },
  {
    value: "thisYear",
    startDate: flow(startOfYear, format(f))(new Date()),
    endDate: today,
  },
  {
    value: "prevWeek",
    startDate: flow(startOfWeek, subWeeks(1), format(f))(new Date()),
    endDate: flow(startOfWeek, subDays(1), format(f))(new Date()),
  },
  {
    value: "prevMonth",
    startDate: flow(startOfMonth, subMonths(1), format(f))(new Date()),
    endDate: flow(startOfMonth, subDays(1), format(f))(new Date()),
  },
  {
    value: "prevQuarter",
    startDate: flow(startOfQuarter, subQuarters(1), format(f))(new Date()),
    endDate: flow(startOfQuarter, subDays(1), format(f))(new Date()),
  },
  {
    value: "prevYear",
    startDate: flow(startOfYear, subYears(1), format(f))(new Date()),
    endDate: flow(startOfYear, subDays(1), format(f))(new Date()),
  },
  {
    value: "7d",
    startDate: flow(subDays(7), format(f))(new Date()),
    endDate: today,
  },
  {
    value: "30d",
    startDate: flow(subDays(30), format(f))(new Date()),
    endDate: today,
  },
  {
    value: "90d",
    startDate: flow(subDays(90), format(f))(new Date()),
    endDate: today,
  },
];

export const defaultReportPeriod = reportPeriodOptions[1];

/** For reports that only support the "current" period */
export const reportPeriodCurrent: ReportPeriod = {
  value: "current",
};
