import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { Box, Container, Title } from "../styled";
import EmailIcon from "../../assets/icons/Email.svg?react";
import { Header } from "../Header";
import { useState } from "react";
import { useResendConfirmEmailMutation } from "modules/auth/state/authApi";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

export const SignUpInvitation = ({ email, headerAction = null }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [resendConfirmEmail] = useResendConfirmEmailMutation();

  const onSubmit = async (email) => {
    resendConfirmEmail({ email })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleClick = () => {
    onSubmit(email);
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 6000);
  };

  return (
    <>
      <Header showAction action={headerAction} />
      <Container>
        <Box id="verifySignupEmail">
          <EmailIcon />
          <Title>{t("auth.signUp.verifyEmail")}</Title>
          <p>{t("auth.signUp.verifyEmailDescription")}</p>
          <Button
            disabled={buttonDisabled}
            type="primary"
            size="normal"
            onClick={handleClick}
          >
            {t("auth.signUp.verifyEmailResendButton")}
          </Button>
        </Box>
      </Container>
    </>
  );
};
