export const enum ProcedureItemType {
  MULTIPLE_CHOICE = "multiple_choice", // eslint-disable-line no-unused-vars
}

export interface IProcedure {
  id: number;
  description: string;
  name: string;
  procedure_template_id: number;
  target_id: number;
  target_type: string;
  items: IProcedureItem[];
  completed_at?: string | Date;
  created_by_id: string | number;
}

export interface IProcedureItem {
  id: number;
  index: number;
  item_type: string;
  name: string;
  value: IProcedureItemValue;
  procedure_id: number;
  procedure_template_item_id: number;
}

export interface IProcedureItemValue {
  checked?: boolean | void;
  data?: string | number | object | void;
  choices?: string[] | void;
  date?: string | Date | void;
  selected?: string | void;
}

export interface IProcedureTemplateItemOption {
  choices?: string[];
}

export interface IProcedureTemplateItem {
  id: string | number;
  index: number;
  item_type: string;
  name: string;
  options: IProcedureTemplateItemOption;
}

export interface IProcedureTemplate {
  id: string | number;
  name: string;
  description?: string;
  items: IProcedureTemplateItem[];
  created_by_id: string | number;
}
