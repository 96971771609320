import {
  PMView,
  PMClone,
  PMEdit,
} from "modules/preventiveMaintenances/components";
import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";

interface PMModalProps {
  params: ModalParams;
}

export enum PMModalState {
  VIEW = "view",
  EDIT = "edit",
  CLONE = "clone",
}

/** Handles global PM Modals */
export const PMModal = ({ params }: PMModalProps) => {
  const { type, id, state = "view" } = params;
  const { pmsDetailsViewPermit } = usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });
  const onClone = () => setModal({ type, id, state: PMModalState.CLONE });

  if (state === PMModalState.VIEW && pmsDetailsViewPermit) {
    return (
      <PMView
        isShow={true}
        onClose={onClose}
        onClone={onClone}
        onEdit={() => setModal({ type, id, state: "edit" })}
        preventiveMaintenanceId={id}
      />
    );
  } else if (state === PMModalState.EDIT) {
    return (
      <PMEdit
        isShow={true}
        onClose={onView}
        onOpen={onView}
        preventiveMaintenanceId={id}
      />
    );
  } else if (state === PMModalState.CLONE) {
    return (
      <PMClone
        isOpen={true}
        onClose={onView}
        onView={(id) => setModal({ type, id })}
        preventiveMaintenanceId={id}
      />
    );
  }

  return null;
};
