import { FiltersMap } from "modules/filters/configs/constants.js";

/** Stringifies filters for the query string in API requests */
export const stringifyFilters = (values, entity) => {
  if (!Array.isArray(values)) {
    return undefined;
  }
  return values.reduce((acc, filter) => {
    let { value, field, operator } = filter;
    const type = FiltersMap[entity][field]?.type;

    if (value) {
      const { filterParameter } = FiltersMap[entity][field];

      if (filterParameter === "schedule") {
        if (typeof value === "string") {
          acc[`filter[type${operator}]`] = value.split("_")[0];
          acc[`filter[repeat_frequency${operator}]`] = value.split("_")[1];
        } else if (typeof value === "number") {
          acc[`filter[type${operator}]`] = value;
          acc[`filter[repeat_frequency${operator}]`] = value;
        } else {
          value.forEach((v) => {
            acc[`filter[type${operator}]`] = v.split("_")[0];
            acc[`filter[repeat_frequency${operator}]`] = v.split("_")[1];
          });
        }
      } else if (filterParameter === "rating" && operator === "_blank") {
        acc[`filter[${filterParameter}_eq]`] = 0;
      } else if (filterParameter === "rating" && operator === "_not_null") {
        acc[`filter[${filterParameter}_not_eq]`] = 0;
      } else {
        if (type === "date" && operator === "_between") {
          acc[`filter[${filterParameter}_gt]]`] = value[0] ?? "";
          acc[`filter[${filterParameter}_lt]]`] = value[1] ?? "";
        } else acc[`filter[${filterParameter}${operator}]`] = value;
      }
    }
    return acc;
  }, {});
};
