import { Button } from "shared/components/Button";
import { Entity } from "../Entity";
import { useTranslation } from "react-i18next";
import { H3 } from "shared/components/Typography";
import styled from "styled-components";
import { Link } from "shared/components/Link";
import { useGetEntitiesQuery } from "modules/entities/state/entitiesApi";

export const AllEntities = ({ openEntityEditor = () => {} }) => {
  const { data } = useGetEntitiesQuery();
  const { t } = useTranslation();

  return (
    <Container>
      <Title> {t("generalSettings.entities")}</Title>
      {data?.data.map((entity) => (
        <Entity
          entity={entity}
          key={entity.id}
          openEntityEditor={openEntityEditor}
        />
      ))}

      <CustomButton>
        <Link type="button" to="/admin-settings/general/entity/add">
          {t("generalSettings.addEntity")}
        </Link>
      </CustomButton>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Title = styled(H3)`
  margin-bottom: 16px;
`;

const CustomButton = styled(Button)`
  margin-top: 16px;
  padding: 0;
`;
