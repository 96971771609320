import {
  getInitialValuesForProcedureTemplate,
  ProcedureTemplateValidationSchema,
} from "modules/procedures/lib/form";
import { useCreateProcedureTemplateMutation } from "modules/procedures/state/proceduresApi";
import { useTranslation } from "react-i18next";
import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Editor } from "./Editor/Editor";
import * as Sentry from "@sentry/react";
import { useSetModal } from "widgets/ModalController";

interface ProcedureCreateProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ProcedureTemplateCreate = ({
  isOpen,
  onClose,
}: ProcedureCreateProps) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [createProcedureTemplate] = useCreateProcedureTemplateMutation();
  const setModal = useSetModal();

  const form = useForm({
    initialValues: getInitialValuesForProcedureTemplate("create"),
    validationSchema: ProcedureTemplateValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (body: object) => {
      try {
        const response = await createProcedureTemplate(body).unwrap();
        const { data } = response;

        addSnackBar({
          title: t("procedures.snackbars.template_created.success.title"),
          content: t("procedures.snackbars.template_created.success.content"),
          type: "success",
        });

        onCloseModal();

        setModal({ type: "procedureTemplate", state: "edit", id: data.id });
      } catch (error) {
        Sentry.addBreadcrumb({
          category: "procedures",
          message: "Failure while attempting to create Procedure",
          data: body,
        });
        Sentry.captureException(error);
        addSnackBar({
          title: t("fallback.error"),
          content: (
            <>
              <p>{t("fallback.description_a")}</p>
              <p>{t("fallback.description_b")}</p>
            </>
          ),
          type: "error",
        });
      }
    },
  });

  const onCloseModal = () => {
    form.resetForm();
    onClose();
  };

  return (
    <EditorModal
      isOpen={isOpen}
      label={t("procedures.createNew")}
      onClose={onCloseModal}
      onSubmit={form.handleSubmit}
      disabledSubmit={!form.dirty || !!Object.values(form.errors).length}
      isSubmitLoading={form.isSubmitting}
      form={form}
    >
      <Editor form={form} />
    </EditorModal>
  );
};
