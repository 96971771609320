import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const storeroomsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getStorerooms: builder.query({
      query: (params) => ({
        url: "storerooms",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Storerooms", id: "StoreroomsList" },
        ...getTagList("Storerooms", result?.data),
      ],
    }),
    getAdminStorerooms: builder.query({
      query: (params) => ({
        url: "/admin/storerooms",
        params,
      }),
      providesTags: (result) => [
        { type: "AdminStorerooms", id: "AdminStoreroomsList" },
        ...getTagList("AdminStorerooms", result?.data),
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getStoreroom: builder.query({
      query: (id) => `/admin/storerooms/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, { id }) => [
        { type: "AdminStorerooms", id },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    updateStoreroom: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/storerooms/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      invalidatesTags: ["AdminStorerooms"],
    }),
    deleteStoreroom: builder.mutation({
      query: (id) => ({
        url: `/admin/storerooms/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "AdminStorerooms", id }],
      transformErrorResponse: (response) => response.data,
    }),
    addStoreroom: builder.mutation({
      query: (body) => ({
        url: "/admin/storerooms",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "AdminStorerooms", id: "AdminStoreroomsList" }],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetStoreroomsQuery,
  useGetAdminStoreroomsQuery,
  useGetStoreroomQuery,
  useAddStoreroomMutation,
  useDeleteStoreroomMutation,
  useUpdateStoreroomMutation,
} = storeroomsApi;
