import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useClonePartMutation } from "modules/parts";
import {
  useListRows,
  convertToBackFieldsMap,
  convertToFrontFieldsMap,
} from "modules/cloneConfiguration";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";
import { LightModal } from "shared/components/Modals/LightModal";
import { Button } from "shared/components/Button";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { CloneConfiguration } from "shared/components/CloneConfiguration";
import { CloneNumber } from "shared/components/CloneNumber";

const PartCloneFields = {
  is_non_stock: { visible: true, required: false },
  on_hand_qty: { visible: true, required: false },
  min_qty: { visible: true, required: false },
  desired_qty: { visible: true, required: false },
  measurement_unit: { visible: true, required: false },
  storeroom: { visible: true, required: false },
  area: { visible: true, required: false },
};

export const PartClone = ({ isShow, onClose, partId }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [count, setCount] = useState(1);
  const [clonePart, clonePartRes] = useClonePartMutation();

  const { data: fieldsConfigurations } = useGetAdminConfigurationQuery(
    isShow
      ? {
          type: "part",
        }
      : skipToken
  );

  const initialConfiguration = useMemo(
    () => convertToFrontFieldsMap(fieldsConfigurations?.data?.payload || {}),
    [fieldsConfigurations]
  );

  const onCloseModal = () => {
    onClose();
    clonePartForm.resetForm();
  };

  const clonePartForm = useForm({
    initialValues: { ...initialConfiguration, ...PartCloneFields },
    enableReinitialize: true,
    onSubmit: async (values) => {
      clonePart({
        id: partId,
        body: { except: convertToBackFieldsMap(values), count: Number(count) },
      })
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            content: t("parts.clone.successMessage"),
            type: "success",
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("parts.failedCreation"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
        });
    },
  });

  const rows = useListRows(clonePartForm.values, "parts");

  return (
    <LightModal
      isOpen={isShow}
      onClose={onCloseModal}
      label={t("parts.clone.title")}
    >
      <Box>
        <CloneNumber count={count} setCount={setCount} entity="parts" />
        <CloneConfiguration
          entity="parts"
          rows={rows}
          handleSetValue={clonePartForm.handleSetValue}
          values={clonePartForm.values}
        />
        <Actions>
          <Button variant="secondary" onClick={onCloseModal}>
            {t("cancel")}
          </Button>
          <Button
            onClick={clonePartForm.handleSubmit}
            isLoading={clonePartRes.isLoading || clonePartRes.isSuccess}
          >
            {t("create")}
          </Button>
        </Actions>
      </Box>
    </LightModal>
  );
};

PartClone.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 512px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
`;
