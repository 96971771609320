import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { pdf } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import {
  ReportPDFTemplate,
  ReportPDFTemplateProps,
} from "modules/reporting/components/ReportPDFTemplate";
import { downloadFile } from "shared/lib/helpers";
import { ExportingModal } from "modules/reporting/components/PDFReportDownloader/ExportingModal";

interface PDFReportDownloaderProps {
  filename: string;
  /** Props to pass along to the `ReportPDFTemplate` component */
  exportProps: Omit<ReportPDFTemplateProps, "chartSrc">;
  charts: {
    title?: string;
    subtitle?: string;
    chart: React.ReactNode;
  }[];
}

export interface PDFReportDownloaderHandle {
  doDownload: () => void;
}

/**
 * Displays an "Exporting" message, renders invisible charts to use for PDF
 *  snapshots, and triggers the PDF download for a report
 *
 * Use the `doDownload` function on the ref to trigger the download
 */
export const PDFReportDownloader = forwardRef<
  PDFReportDownloaderHandle,
  PDFReportDownloaderProps
>(({ charts, filename, exportProps }, handleRef) => {
  const id = useId();
  const [renderCharts, setRenderCharts] = useState(false);

  useImperativeHandle(handleRef, () => ({
    doDownload: () => setRenderCharts(true),
  }));

  /* Why is the export triggered by `useEffect`?
   *
   * We want the PDF charts to only render when we need them, because they can be time-consuming to render. However, we
   * need the charts to be actually rendered on the page for `html2canvas` to take a snapshot. `useEffect` is executed
   * after the DOM update and paint, so it ensures that the charts are rendered and ready to go before the snapshot.
   *
   * [Hook flow](https://github.com/donavon/hook-flow/blob/master/hook-flow.pdf)
   */
  useEffect(() => {
    if (renderCharts) {
      // Wrapping in `setTimeout` ensures that the component has been rendered
      //    before taking the screenshot
      setTimeout(async () => {
        await doDownloadPDF({
          id,
          charts,
          exportProps,
          filename,
        });
        setRenderCharts(false);
      }, 0);
    }
  });

  /* Why use opacity to hide the chart, instead of `display: none` or `visibility: hidden`?
   *
   * Recharts won't render as a child of any invisible element ("visibility: hidden" or "display: none") – after a chart
   * is set to be visible, it needs to be re-rendered for Recharts to calculate element sizes. `html2canvas` can use
   * `onclone` to modify the cloned DOM, but it takes the snapshot immediately after `onclone` is done. If we use
   * `visibility`/`display`, the screenshot will be blank because Recharts can't rerender between `onclone` and the
   * snapshot. Using `opacity` instead allows the chart to render when the `PDFReportDownloader` component is rendered,
   * so sizes are already calculated when html2canvas takes the snapshot.
   */
  if (!renderCharts) return null;
  return createPortal(
    <div>
      <ExportingModal />
      {charts.map((chart, index) => (
        <div
          key={index}
          id={`${id}-${index}`}
          style={{
            position: "fixed",
            pointerEvents: "none",
            opacity: 0,
          }}
        >
          {chart.chart}
        </div>
      ))}
    </div>,
    document.body
  );
});

/** Provides a unique and stable ID */
function useId() {
  const id = useRef(crypto.randomUUID());
  return id.current;
}

interface DoDownloadPDFProps
  extends Pick<
    PDFReportDownloaderProps,
    "exportProps" | "charts" | "filename"
  > {
  id: string;
}

/** Screenshots each chart, then downloads the generated PDF report */
const doDownloadPDF = async ({
  id,
  charts,
  exportProps,
  filename,
}: DoDownloadPDFProps) => {
  // Uses html2canvas to screenshot each chart, then get the resulting image URL
  const promises = charts.map((_, index) =>
    html2canvas(document.getElementById(`${id}-${index}`)!, {
      allowTaint: true,
      onclone: (clone) => {
        clone.getElementById(`${id}-${index}`)!.style.opacity = "1";
      },
    }).then((canvas) => canvas.toDataURL("image/png"))
  );

  const canvases = await Promise.all(promises);
  const chartArray = charts.map((chart, index) => ({
    ...chart,
    src: canvases[index],
  }));

  // Generate and download the PDF
  const blob = await pdf(
    <ReportPDFTemplate {...exportProps} charts={chartArray} />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  downloadFile(url, filename);
};
