/**
 * Helper class for batching useSearchParamState updates
 *
 * This is necessary for cases where two `useSearchParamState` hooks are updated in the same function:
 * @example
 * const onSearch = (text) => {
 *   setSearch(text);
 *   setPage(1);
 * }
 */
export class SearchParamUpdateQueue {
  queue: [string, string | null][] = [];

  enqueue(key: string, value: string | null) {
    this.queue.push([key, value]);
  }

  dequeue() {
    return this.queue.splice(0, 1)[0];
  }

  count() {
    return this.queue.length;
  }

  /** Applies the updates in the queue to a URLSearchParams object */
  performUpdates(params: URLSearchParams) {
    const draft = new URLSearchParams(params);

    while (this.count()) {
      const [key, updatedValue] = this.dequeue();
      if (updatedValue === null) {
        draft.delete(key);
      } else {
        draft.set(key, updatedValue);
      }
    }
    return draft;
  }
}
