import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Box } from "./DowntimeType.styled";

const Types = ["unplanned", "planned"];

export const DowntimeType = ({ form, name }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {Types.map((type) => (
        <Box
          key={type}
          active={form.values.downtime_type === type}
          onClick={() => {
            form.handleSetValue(name, type);
          }}
        >
          <span>{t(`assets.bulkActions.status.typeOptions.${type}`)}</span>
        </Box>
      ))}
    </Container>
  );
};
