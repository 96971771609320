import styled from "styled-components";
import { StatusMap } from "../../config/constants";
import { PriorityMap } from "shared/components/Priority";
import OverdueIcon from "../../assets/icons/overdue.svg?react";

import { Status } from "shared/components/Status";

export const CalendarEvent = ({
  status,
  title,
  priority,
  type,
  woId,
  pmId,
  isOverdue = false,
  isPlanned = true,
  isCompleted = false,
}) => {
  const { Icon: StatusIcon, color } = StatusMap[status];

  const PriorityIcon = PriorityMap[priority];

  if (type === "dayGridWeek") {
    return (
      <CalendarWeekEventContainer
        color={`var(--color_${color})`}
        isOverdue={isOverdue}
        isPlanned={isPlanned}
        isCompleted={isCompleted}
      >
        <CalendarWeekEventHeader>
          <CalendarWeekEventHeaderId isCompleted={isCompleted}>
            {isPlanned && pmId ? `PM#${pmId}` : `WO#${woId}`}
          </CalendarWeekEventHeaderId>
          <CalendarEventPriorityIconContainer>
            <PriorityIcon />
          </CalendarEventPriorityIconContainer>
        </CalendarWeekEventHeader>
        <CalendarWeekEventTitle isCompleted={isCompleted}>
          {title}
        </CalendarWeekEventTitle>
        <CalendarWeekEventStatusContainer>
          <Status value={status} />
          {isOverdue && <OverdueIcon />}
        </CalendarWeekEventStatusContainer>
      </CalendarWeekEventContainer>
    );
  }
  return (
    <CalendarMonthEventContainer
      isOverdue={isOverdue}
      isPlanned={isPlanned}
      isCompleted={isCompleted}
    >
      <CalendarEventStatusIconContainer>
        <StatusIcon />
      </CalendarEventStatusIconContainer>
      <CalendarEventTitle isCompleted={isCompleted}>{title}</CalendarEventTitle>
      <CalendarEventPriorityIconContainer>
        <PriorityIcon />
      </CalendarEventPriorityIconContainer>
    </CalendarMonthEventContainer>
  );
};

const CalendarWeekEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid
    ${({ color, isOverdue, isCompleted }) =>
      isCompleted
        ? "rgba(255, 255, 255, 1)"
        : isOverdue
        ? "rgba(255, 120, 110, 1)"
        : color};
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  color: ${({ isPlanned, isCompleted }) =>
    isPlanned || isCompleted ? "rgba(191, 191, 195, 1)" : "rgba(5, 31, 64, 1)"};
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 240, 180, 0.2);
  }
  background-image: ${({ isCompleted }) =>
    isCompleted
      ? "repeating-linear-gradient(135deg, transparent,transparent 10px, rgba(191, 191, 195, 1) 0px, rgba(191, 191, 195, 1) 11px)"
      : "none"};
`;

const CalendarWeekEventStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CalendarWeekEventHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
`;

const CalendarEventStatusIconContainer = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
`;

const CalendarEventPriorityIconContainer = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
`;

const CalendarMonthEventContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  border-radius: 20px;
  border: 1px solid
    ${({ isCompleted }) => (isCompleted ? "white" : "transparent")};
  background: ${({ isOverdue, isPlanned }) =>
    isOverdue && !isPlanned ? "rgba(255, 120, 110, 0.2)" : "transparent"};
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 240, 180, 0.2);
  }
  color: ${({ isPlanned, isCompleted }) =>
    isPlanned || isCompleted ? "rgba(137, 136, 141, 1)" : "rgba(5, 31, 64, 1)"};
  background-image: ${({ isCompleted }) =>
    isCompleted
      ? "repeating-linear-gradient(135deg, transparent,transparent 10px, rgba(191, 191, 195, 1) 0px, rgba(191, 191, 195, 1) 11px)"
      : "none"};
`;

const CalendarEventTitle = styled.span`
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  text-decoration: ${({ isCompleted }) =>
    isCompleted ? "line-through" : "none"};
`;

const CalendarWeekEventTitle = styled(CalendarEventTitle)`
  font-weight: 600;
  margin-bottom: 4px;
  text-decoration: ${({ isCompleted }) =>
    isCompleted ? "line-through" : "none"};
`;

const CalendarWeekEventHeaderId = styled.span`
  text-decoration: ${({ isCompleted }) =>
    isCompleted ? "line-through" : "none"};
`;
