import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { ContextualHelp } from "shared/components/ContextualHelp";

export const InlineLabel = (props) => {
  const {
    label,
    labelWidth = 100,
    children,
    multiline,
    isRequired = false,
    multiColumn = false,
    tooltipContent = "",
    columnLabel = false,
    styles,
    fontSize = "",
    className,
  } = props;

  return (
    <Container
      multiline={multiline}
      columnLabel={columnLabel}
      className={className}
    >
      <Label
        width={labelWidth}
        columnLabel={columnLabel}
        fontWeight={styles?.fontWeight}
        fontSize={fontSize}
      >
        <Box>
          {label}
          {tooltipContent && <ContextualHelp content={tooltipContent} />}
        </Box>
        {isRequired && <AsteriskContainer>*</AsteriskContainer>}
      </Label>

      <Content multiColumn={multiColumn}>{children}</Content>
    </Container>
  );
};

InlineLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  labelWidth: PropTypes.number,
  multiline: PropTypes.bool,
  columnLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  tooltipContent: PropTypes.string,
};

const Label = styled.div`
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  margin-right: 10px;
  margin-bottom: ${({ columnLabel }) => (columnLabel ? "12px" : "0")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "14px")};
  font-size: ${({ fontSize }) => fontSize ?? "14px"};
  display: flex;
  gap: 5px;

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `}
`;

const Container = styled.div`
  display: ${({ columnLabel }) => (columnLabel ? "block" : "flex")};
  align-items: ${({ multiline }) => (multiline ? "flex-start" : "center")};
  margin-top: 30px;

  ${({ multiline }) =>
    multiline
      ? css`
          ${Label} {
            margin-top: 8px;
          }
        `
      : null}
`;

const Content = styled.div`
  width: 100%;
  display: ${({ multiColumn }) => (multiColumn ? "inline-flex" : "block")};
  gap: ${({ multiColumn }) => (multiColumn ? "10px" : "0px")};
`;

const AsteriskContainer = styled.span`
  color: var(--color_corp_red);
`;

const Box = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
`;
