import * as Yup from "yup";

import { getSelectSchema } from "shared/lib/helpers/validation";

export const partValidationSchema = (t) =>
  Yup.object().shape({
    part: getSelectSchema(t, { required: true }),
    quantity: Yup.number()
      .min(0, t("validation.positive"))
      .required(t("validation.required")),
  });
