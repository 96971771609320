import {
  useState,
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  ReactNode,
} from "react";

interface DonutChartProps {
  children: ReactNode;
  data: SliceData[];
  isLoading?: boolean;
  valueFormatter?: (value: number) => string;
  value2Formatter?: (value: number) => string;
  sliceConfigs: Record<string, SliceConfig>;
}

interface SliceData {
  key: string;
  value: number;
  value2?: number;
}

export interface SliceConfig {
  icon?: string;
  fill?: string;
  label?: string;
}

interface ActiveSlice extends SliceData {
  index: number;
}

interface DonutChartContext extends Omit<DonutChartProps, "children"> {
  total: number;
  active: ActiveSlice | undefined;
  setActive: Dispatch<SetStateAction<ActiveSlice | undefined>>;
  valueFormatter: (value: number) => string;
  value2Formatter?: (value: number) => string;
}

const Context = createContext<DonutChartContext | null>(null);
export const useDonutChartContext = () => {
  const value = useContext(Context);
  if (!value) {
    throw new Error("useDonutChartContext must be used within a Provider");
  }
  return value;
};

export const DonutChart = ({
  children,
  data,
  isLoading,
  valueFormatter = (v) => v.toString(),
  value2Formatter = (v) => v.toString(),
  sliceConfigs,
}: DonutChartProps) => {
  const [active, setActive] = useState<ActiveSlice>();
  const total = data?.reduce((acc, current) => acc + current.value, 0) ?? 0;

  return (
    <Context.Provider
      value={{
        data,
        total,
        isLoading,
        valueFormatter,
        value2Formatter,
        sliceConfigs,
        active,
        setActive,
      }}
    >
      {children}
    </Context.Provider>
  );
};
