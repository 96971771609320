import { ReportKey } from "modules/reporting/types/ReportKey";
import { OntimeVsOverdueReportChart } from "./OntimeVsOverdueReportChart";
import { OntimeVsOverdueReportTable } from "./OntimeVsOverdueReportTable";
import { OntimeVsOverdueReportActionBar } from "./OntimeVsOverdueReportActionBar";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { ChartWithBucketOptionsWrapper } from "modules/reporting/components/ReportBucketOptions";
import { useOntimeVsOverdueReportKPIs } from "./useOntimeVsOverdueReportKPIs";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import { OntimeVsOverdueChartData, OntimeVsOverdueTableData } from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { getBucketedChartsList } from "modules/reporting/lib/getBucketedChartsList";
import { useRef } from "react";

const reportKey = ReportKey.OntimeVsOverdue;
export const OntimeVsOverdueReport = () => {
  const { t } = useTranslation();
  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const [bucket, setBucket] = usePersistentState<ReportBucket>(
    "report_bucket",
    "day"
  );
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const kpiRes = useOntimeVsOverdueReportKPIs(reportPeriod);
  const chartRes = useGetChartData<OntimeVsOverdueChartData[]>(
    reportKey,
    reportPeriod,
    { bucket: "day" }
  );
  const tableRes = useGetTableData<OntimeVsOverdueTableData[]>(
    reportKey,
    reportPeriod
  );
  const downloadXLS = useDownloadReportXLS(
    ReportKey.OntimeVsOverdue,
    reportPeriod
  );

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <ChartWithBucketOptionsWrapper {...{ bucket, setBucket }}>
            <OntimeVsOverdueReportChart
              bucket={bucket}
              data={chartRes.data}
              isFetching={chartRes.isFetching}
            />
          </ChartWithBucketOptionsWrapper>
        }
        actionBar={
          <OntimeVsOverdueReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
          />
        }
        table={
          <OntimeVsOverdueReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf", reportPeriod)}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod,
          kpis: kpiRes.data,
        }}
        charts={getBucketedChartsList(t).map((b) => ({
          title: t(`reporting.${reportKey}.title`) as string,
          subtitle: b.label,
          chart: (
            <div style={{ width: "1000px", height: "420px" }}>
              <OntimeVsOverdueReportChart
                bucket={b.bucket}
                data={chartRes.data}
                isFetching={false}
              />
            </div>
          ),
        }))}
      />
    </ReportPageProvider>
  );
};
