import { useTranslation } from "react-i18next";
import { Input } from "shared/components/Input";
import { SelectInnerSearch } from "shared/components/Select/SelectInnerSearch";
import { InlineLabel } from "shared/components/widgets";
import styled from "styled-components";

export const EditorFields = ({ form, module, options }) => {
  const { t } = useTranslation();

  const checkEntities = (name, value) => {
    const allEntities =
      module === "storerooms" ? "" : value.find(({ value }) => value === "all");

    const convertedValue = [value].flat();
    form.setFieldValue(name, allEntities ? [allEntities] : convertedValue);
  };

  return (
    <Box>
      <InlineLabel
        label={t(`settings.referenceLists.${module}.modal.name`)}
        labelWidth={110}
      >
        <Input
          name="name"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t(
            `settings.referenceLists.${module}.modal.namePlaceholder`
          )}
          errorMessage={form.touched.name && form.errors.name}
          height={40}
          fontSize={14}
        />
      </InlineLabel>
      <InlineLabel
        label={t(`settings.referenceLists.${module}.modal.entities`)}
        labelWidth={110}
      >
        <SelectInnerSearch
          name="entities"
          options={options}
          value={form.values.entities}
          placeholder={t(
            `settings.referenceLists.${module}.modal.entitiesPlaceholder`
          )}
          isMulti={module !== "storerooms"}
          setValue={checkEntities}
          onBlur={form.handleBlur}
          onTouch={form.handleSetTouched}
          errorMessage={form.touched.entities && form.errors.entities}
          customStyles={{ maxMenuHeight: 130 }}
        />
      </InlineLabel>
    </Box>
  );
};

const Box = styled.div`
  width: 480px;
  padding: 32px 16px;
`;
