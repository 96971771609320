import styled from "styled-components";
import { ReactNode } from "react";

interface ScrollFadeBoxProps {
  children: ReactNode;
  disableTop?: boolean;
  disableBottom?: boolean;
  fadeColor: string;
}

/**
 * Vertical container that makes content fade out slightly as it exists the scroll area
 */
export const ScrollFadeBox = ({
  children,
  disableTop,
  disableBottom,
  fadeColor = "#fff",
}: ScrollFadeBoxProps) => {
  return <Box {...{ disableTop, disableBottom, fadeColor }}>{children}</Box>;
};

type BoxProps = Pick<
  ScrollFadeBoxProps,
  "disableTop" | "disableBottom" | "fadeColor"
>;

const Box = styled.div<BoxProps>`
  position: relative;
  overflow: hidden auto;
  width: 100%;
  height: 100%;

  &:before,
  &:after {
    content: "";
    position: sticky;
    left: 0;
    right: 0;
    height: 1rem;
    z-index: 5;
  }

  &:before {
    display: ${(p) => (p.disableTop ? "none" : "block")};
    top: 0;
    background: linear-gradient(
      180deg,
      ${(p) => p.fadeColor} 0%,
      color-mix(in srgb, ${(p) => p.fadeColor} 20%, transparent) 100%
    );
  }
  &:after {
    display: ${(p) => (p.disableBottom ? "none" : "block")};
    bottom: -1px;
    background: linear-gradient(
      0deg,
      ${(p) => p.fadeColor} 0%,
      color-mix(in srgb, ${(p) => p.fadeColor} 20%, transparent) 100%
    );
  }
`;
