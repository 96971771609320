import styled from "styled-components";

/** Shared layout for all ReportListPage widgets with a Legend and DonutChart */
export const DonutChartWidgetLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  justify-items: center;
`;
