import styled from "styled-components";

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 32px;
`;

export const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

export const FieldBox = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const NewLabel = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
`;

export const Image = styled.img`
  width: 100%;
  max-height: 100%;
`;
