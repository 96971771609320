import styled, { css } from "styled-components";

export const Box = styled.div`
  margin-top: 16px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  cursor: initial;
  position: relative;

  ${({ withCheckbox }) =>
    withCheckbox &&
    css`
    background: var(--color_text_light);
    border: 2px solid var(--color_text_light);
    &:hover {
      background: var(--color_primary_hover);

      & .actionIcon {
        display: flex;
      }
    `}
`;

export const TextBox = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: var(--color_grey900);
  display: flex;
  gap: 5px;
`;

export const Text = styled.div`
  ${(props) =>
    props.checked &&
    css`
      text-decoration: line-through from-font var(--color_grey600);
    `}
`;

export const Order = styled.div`
  color: var(--color_grey500);
`;

export const Container = styled.div`
  padding-top: 32px;
`;
