import styled from "styled-components";
import { ReportBucketOptions } from "modules/reporting/components/ReportBucketOptions/ReportBucketOptions";
import { ReportBucket } from "modules/reporting/types/ReportBucket";

interface ChartWithBucketOptionsWrapperProps {
  bucket: ReportBucket;
  setBucket: (value: ReportBucket) => void;
  children: React.ReactNode;
}

/** Layout for a chart and ReportBucketOptions */
export const ChartWithBucketOptionsWrapper = ({
  bucket,
  setBucket,
  children,
}: ChartWithBucketOptionsWrapperProps) => {
  return (
    <Wrapper>
      <BucketOptions selected={bucket} onChange={setBucket} />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const BucketOptions = styled(ReportBucketOptions)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  padding-bottom: 8px;
`;
