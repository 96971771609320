import React from "react";
import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";
import ArrowIcon from "../assets/arrow.svg?react";

export const ArrowButton = ({
  variant = "transparent",
  background = "light",
  rotate = 0,
  ...props
}) => {
  return (
    <IconButtonStyled
      variant={variant}
      background={background}
      rotate={rotate}
      {...props}
    >
      <ArrowIcon />
    </IconButtonStyled>
  );
};

const IconButtonStyled = styled(IconButton)`
  width: 24px;
  height: 24px;
  transform: rotate(${({ rotate }) => rotate}deg);
`;
