import { getUser } from "shared/components/Auth";

const locale = "en-US";

const monthFormat = new Intl.DateTimeFormat(locale, { month: "short" });

const timeFormat = new Intl.DateTimeFormat(locale, {
  timeStyle: "short",
});

export function mergeObjects(obj1, obj2) {
  const mergedObj = {};
  for (let key in obj1) {
    mergedObj[key] = obj2[key] || obj1[key];
  }
  return mergedObj;
}

export function generateNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatDate(value, includeTime = false) {
  const date = new Date(value);

  const dateString = `${monthFormat.format(
    date
  )} ${date.getDate()}, ${date.getFullYear()}`;

  if (includeTime) {
    return `${dateString} at ${timeFormat.format(date)}`;
  }

  return dateString;
}

export const formatDateValue = (dateISO) => {
  const today = new Date(dateISO);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return yyyy + "-" + mm + "-" + dd;
};

export const formatDateValueWithSlash = (date) => {
  if (!date) {
    return null;
  }
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return mm + "/" + dd + "/" + yyyy;
};

export function formatDateWithDayOfWeek(value, includeTime = false) {
  const date = new Date(value);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const dateString = date.toLocaleDateString(undefined, options);

  if (includeTime) {
    return `${dateString} at ${timeFormat.format(date)}`;
  }

  return dateString;
}

export const filterPassedTime = (time, date, currentDateHandler = null) => {
  let currentDate = currentDateHandler
    ? currentDateHandler(new Date(), true, false)
    : new Date();

  if (date && date.toDateString() === currentDate.toDateString()) {
    currentDate.setHours(currentDate.getHours());
    currentDate.setMinutes(currentDate.getMinutes());
  }

  return time > currentDate;
};

const isPrimary = (a) => a.is_primary;

const preferredSort = (a, b) =>
  a.is_preferred === b.is_preferred ? 0 : a.is_preferred ? -1 : 1;

export const sortVendors = (vendors) => {
  return [...vendors].sort(preferredSort);
};

export const filterVendorContacts = (contacts) => {
  if (contacts.length === 1) {
    return contacts;
  }

  return contacts.filter(isPrimary);
};

export const getOptions = (data, field = "name") => {
  if (!data || !data?.data?.length) {
    return [];
  }

  return data.data.map(({ id, [field]: name }) => ({ value: id, label: name }));
};

export const getAssignToOptions = (
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  //teams = { data: [] },
  technicians = { data: [] }
) => {
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const teamsOptions = {
  //   label: "Teams",
  //   options: teams.data.map(({ id, name }) => ({
  //     value: id + "_team",
  //     label: name,
  //     category: "team",
  //     id,
  //   })),
  // };

  const techniciansOptions = {
    label: "Users",
    options: technicians.data
      .map(({ id, full_name, status }) =>
        status !== "invitation_sent"
          ? {
              value: id + "_technician",
              label: full_name,
              category: "technician",
              id,
            }
          : null
      )
      .filter((item) => item !== null),
  };

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // return [teamsOptions, techniciansOptions];
  return [techniciansOptions];
};

export const getAssignToIds = (assignTo) => {
  return assignTo.reduce(
    (result, option) => {
      const { id, category } = option;
      const { technicians_ids } = result;
      // const { teams_ids, technicians_ids } = result;

      if (category === "team") {
        // teams_ids.push(id);
      } else {
        technicians_ids.push(id);
      }
      return result;
    },
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    {
      // teams_ids: [],
      technicians_ids: [],
    }
  );
};

export const getAssignToValues = (technicians) => {
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const teamsValues = teams.map(({ id, name }) => ({
  //   value: id + "_team",
  //   label: name,
  //   category: "team",
  //   id,
  // }));

  const techniciansValues = technicians.map(({ id, name }) => ({
    value: id + "_technician",
    label: name,
    category: "technician",
    id,
  }));

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // return teamsValues.concat(techniciansValues);
  return techniciansValues;
};

const numberFormatter = (language, currency) => {
  const formatter = new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });

  return formatter;
};

const getCurrency = () => {
  return getUser().currency ?? "USD";
};

export const formatCurrency = (value, localCurrency) => {
  let currency = localCurrency ?? getCurrency();
  return value ? numberFormatter(locale, currency).format(value) : "";
};
