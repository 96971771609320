import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import Icon from "../assets/icon.svg?react";

export const BulkDeletion = ({
  confirmation,
  ids = [],
  onDelete,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const proceedHandler = () => {
    if (ids.length > 0) {
      onDelete(ids)
        .unwrap()
        .then(({ results }) => {
          const errorData = results.filter(
            (data) => data.body?.status === "error"
          );
          if (errorData?.length > 0) throw new Error(JSON.stringify(errorData));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(({ message }) => {
          if (onError) {
            onError(JSON.parse(message));
          }
        });
    }
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
  };

  return (
    <>
      <IconButton onClick={clickHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-delete" />
        <ReactTooltip
          id="icon-tooltip-delete"
          place="bottom"
          content={t("work_orders.bulkActions.hint.delete")}
          opacity="1"
        />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          {...confirmation}
          onProceed={proceedHandler}
          onCancel={cancelHandler}
          isDestructive
        />
      )}
    </>
  );
};

BulkDeletion.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onDelete: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  confirmation: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
  }).isRequired,
};

const StyledIcon = styled(Icon)`
  outline: none;
`;
