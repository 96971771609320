export const convertToBackFieldsMap = (data) => {
  const commonFields = Object.values(data)
    .flatMap((obj) => (obj.visible ? [] : obj.common))
    .filter((value, index, self) => value && self.indexOf(value) === index);

  const excludedFields = Object.keys(data).filter(
    (key) => !data[key].visible && !data[key].common
  );

  return commonFields ? [...commonFields, ...excludedFields] : excludedFields;
};
