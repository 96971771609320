export const userDetailsCreateInitialValues = {
  job_title: "",
  phone_number: "",
  email: "",
  can_be_assigned_to_wo: false,
  permissions: [],
  regular_rate: "",
  overtime_rate1: "",
  overtime_rate2: "",
};

export const userDefaultValidationFields = [
  "job_title",
  "phone_number",
  "regular_rate",
  "overtime_rate1",
  "overtime_rate2",
  "permissions",
];

export const InviteUserValidationFields = [
  ...userDefaultValidationFields,
  "email",
];
export const EditUserValidationFields = [
  ...userDefaultValidationFields,
  "email",
  "name",
  "surname",
];
