import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export const IconButton = ({
  className = undefined,
  variant = "transparent",
  background = "light",
  children,
  ...props
}) => (
  <Box
    className={className}
    variant={variant}
    background={background}
    disabled={props.disabled}
    {...props}
  >
    {children}
  </Box>
);

IconButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["transparent", "primary", "light"]),
  background: PropTypes.oneOf(["light", "dark"]),
};

export const Box = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 0;
  margin: 0;
  ${({ disabled }) =>
    disabled
      ? css`
          filter: grayscale(1);
          cursor: not-allowed !important;
        `
      : ""}
  ${({ variant, disabled }) =>
    variant === "transparent" &&
    !disabled &&
    css`
      color: var(--color_dark_blue);

      &:hover {
        color: var(--color_primary_text);
      }
    `}
  ${({ variant, background }) =>
    variant === "primary" &&
    css`
      padding: 4px;
      border-radius: 4px;
      color: ${background === "light"
        ? "var(--color_primary_text)"
        : "var(--color_primary)"};

      &:hover {
        background-color: var(--color_primary_hover);
      }

      &:focus {
        box-shadow: 0 0 0 3px var(--color_primary);
      }

      &:active {
        box-shadow: 0 0 0 1px
            ${background === "light"
              ? "var(--color_background_light)"
              : "var(--color_background)"},
          0 0 0 3px var(--color_primary);
        color: var(--color_background);
        background-color: var(--color_primary);
      }
    `};
`;
