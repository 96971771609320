import styled from "styled-components";
import MissingIcon from "shared/assets/icon/image.svg?react";

interface RowDecoratorImageProps {
  src?: string;
  width?: string;
  height?: string;
}

/** Utility component for rendering an image/fallback in `RowDecorator` */
export const RowDecoratorImage = ({
  src,
  width = "100%",
  height = "100%",
}: RowDecoratorImageProps) => {
  return src ? (
    <Image src={src} style={{ width, height }} alt="" />
  ) : (
    <Fallback style={{ width, height }}>
      <MissingIcon />
    </Fallback>
  );
};

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: inherit;
  object-fit: cover;
`;

const Fallback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  color: var(--color_table_missing_icon_color);
  background-color: var(--color_table_missing_icon_background);
`;
