import { useTranslation } from "react-i18next";
import * as Styled from "./WorkOrdersKPIsReactivePreventiveChart.styled";
import { formatPercent } from "modules/reporting/lib/formatters";
import { ComponentProps } from "react";
import { WorkOrdersKPIs } from "modules/workOrders/components/WorkOrdersKPIs/WorkOrdersKPIs";
import { WorkOrdersKPIsTextItem } from "modules/workOrders/components/WorkOrdersKPIs/components/WorkOrdersKPIsTextItem";

export const WorkOrdersKPIsReactivePreventiveChart = ({
  data,
  isLoading,
}: ComponentProps<typeof WorkOrdersKPIs>) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  if (!isLoading && !data) {
    return null;
  }

  return (
    <Styled.ReactivePreventiveChartWrapper>
      <WorkOrdersKPIsTextItem
        value={data?.reactive?.reactive}
        label={t("work_orders.kpis.reactive")}
        description={formatPercent(
          data?.reactive?.reactive_percentage,
          language
        )}
        isLoading={isLoading}
      />
      <WorkOrdersKPIsTextItem
        value={data?.preventive?.preventive}
        label={t("work_orders.kpis.preventive")}
        description={formatPercent(
          data?.preventive?.preventive_percentage,
          language
        )}
        isLoading={isLoading}
      />
      <Styled.ReactivePreventiveChart>
        <Styled.ReactivePreventiveChartBar
          $width={getBarWidth(
            data?.reactive?.reactive_percentage ?? 0,
            isLoading
          )}
          $color={isLoading ? "var(--color_grey50)" : "var(--color_salmon)"}
        />
        <Styled.ReactivePreventiveChartBar
          $width={getBarWidth(
            data?.preventive?.preventive_percentage ?? 0,
            isLoading
          )}
          $color={isLoading ? "var(--color_grey50)" : "var(--color_mint)"}
        />
      </Styled.ReactivePreventiveChart>
    </Styled.ReactivePreventiveChartWrapper>
  );
};

function getBarWidth(value: number, isLoading?: boolean) {
  if (isLoading) return 50;
  return value * 100;
}
