export const partsListSettings = {
  restock: {
    name: "added",
    color: "lime",
    sign: "+",
    isShowCost: true,
    linkName: "RSTK #",
    linkValue: "restock",
    showNote: true,
    isShowEditIcon: true,
  },

  initial_inventory: {
    name: "created",
    color: "lime",
    sign: "+",
    isShowCost: true,
    showNote: false,
    details: "",
    linkValue: "",
    isShowEditIcon: false,
  },
  reservation: {
    name: "reserved",
    color: "grey400",
    sign: "-",
    isShowCost: false,
    details: "",
    linkName: "WO #",
    linkValue: "work_order_id",
    showNote: false,
    isShowEditIcon: false,
  },
  planned: {
    name: "planned",
    color: "grey400",
    sign: "-",
    isShowCost: false,
    details: "",
    linkName: "",
    linkValue: "",
    showNote: false,
    isShowEditIcon: false,
  },
  usage: {
    name: "used",
    color: "corp_red_shadow",
    sign: "-",
    isShowCost: true,
    linkName: "WO #",
    linkValue: "work_order_id",
    showNote: false,
    isShowEditIcon: false,
  },
  usage_reversal: {
    name: "reversal",
    color: "lime",
    sign: "+",
    isShowCost: true,
    details: "",
    linkName: "WO #",
    showNote: false,
    linkValue: "work_order_id",
    isShowEditIcon: false,
  },
  cost_update: {
    name: "reversal",
    color: "lime",
    sign: "+",
    isShowCost: true,
    details: "",
    showNote: false,
    linkName: "CU #",
    linkValue: "restock",
    isShowEditIcon: true,
  },
};
