import React, { useState } from "react";
import PropTypes from "prop-types";
import { getAllChildrenIds } from "shared/lib/helpers/hierarchicalList";
import { ConfirmationDialog } from "shared/components/Dialog";
import { ContextMenuEntry } from "shared/components/ContextMenu";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useTranslation } from "react-i18next";
import { isObject } from "lodash";

export const Deletion = ({
  id,
  module = "Item",
  onSuccess,
  onBulkSuccess,
  onError,
  onBulkError,
  onAssociationError,
  confirmation,
  subConfirmation,
  onDelete,
  onBulkDelete,
  titleKey = "delete",
  customAction,
  hierarchyTree = [],
  hasChildren = false,
  hasAssociation = false,
}) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const [subChildrenIds, setSubChildrenIds] = useState(null);

  const onClose = () => {
    setSubChildrenIds(null);
  };

  const deleteHandle = async () => {
    if (id) {
      try {
        await onDelete(id).unwrap();
        if (onSuccess) {
          onSuccess();
          addSnackBar({
            content: t("action.deleteSuccess", { module }),
            type: "success",
          });
        }
      } catch (e) {
        const { message } = e?.data || {};

        if (onError) {
          onError(message);
        } else if (isObject(message)) {
          Object.keys(message).forEach((key) => {
            addSnackBar({
              title: t("action.deleteFailed", { module }),
              content: `${key !== "base" ? `${key}: ` : ""} ${message[key]}`,
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          });
        } else {
          addSnackBar({
            title: t("action.deleteFailed", { module }),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        }
      }
      onClose();
    }
  };

  const bulkDeleteHandle = () => {
    if (subChildrenIds) {
      onBulkDelete([id, ...subChildrenIds])
        .unwrap()
        .then(({ results }) => {
          const errorData = results.filter(
            (data) => data.body?.status === "error"
          );
          if (errorData?.length > 0) throw new Error(JSON.stringify(errorData));
          if (onBulkSuccess) {
            onBulkSuccess(subChildrenIds.length + 1);
          }
        })
        .catch(({ message }) => {
          if (onBulkError) {
            onBulkError(JSON.parse(message));
          }
        })
        .finally(() => onClose());
    }
  };

  const handleDeleteClick = () => {
    if (hasAssociation) {
      onAssociationError();
    } else {
      if (hasChildren) {
        const childrenIds = getAllChildrenIds(hierarchyTree.tree);
        setSubChildrenIds(childrenIds);
      } else deleteHandle();
    }
  };

  return (
    <>
      <ContextMenuEntry
        confirmation={confirmation}
        onClick={customAction ?? handleDeleteClick}
        warning
      >
        {t(titleKey)}
      </ContextMenuEntry>
      {subChildrenIds && (
        <ConfirmationDialog
          {...subConfirmation}
          onProceed={bulkDeleteHandle}
          onCancel={deleteHandle}
          onClose={onClose}
          isDestructive
        />
      )}
    </>
  );
};

Deletion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSuccess: PropTypes.func,
  onBulkSuccess: PropTypes.func,
  onError: PropTypes.func,
  onBulkError: PropTypes.func,
  onAssociationError: PropTypes.func,
  confirmation: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
  }),
  subConfirmation: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onBulkDelete: PropTypes.func,
  customAction: PropTypes.func,
  hasChildren: PropTypes.bool,
  hasAssociation: PropTypes.bool,
};
