import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "features/Pagination";
import { Table } from "shared/components/Table";
import { useSortParam } from "shared/lib/hooks";
import { NoResults } from "shared/components/NoResults";
import { Tabs } from "shared/components/Tabs";
import { useUsersTableTools } from "modules/users/lib/useUsersTableTools";
import { useGetEntityUsersQuery } from "modules/users/state/usersApi";
import { ErrorAllUsersList } from "./ErrorAllUsersList";
import { UserAvatar } from "shared/components/UserAvatar";
import styled from "styled-components";
import { useSearchParam } from "shared/lib/hooks";
import { usePageParam } from "shared/lib/hooks/usePageParam";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";

export const List = ({ openUserDetailsHandler = () => {} }) => {
  const { t } = useTranslation();

  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();

  const [paginationData, setPaginationData] = useState({});

  const { columns, isEntitiesFetching } = useUsersTableTools({
    onOpen: openUserDetailsHandler,
    oneEntity: true,
  });

  const { data, error, isFetching } = useGetEntityUsersQuery({
    search,
    page,
    sort,
    per_page: 15,
  });
  const isLoading = useIsLoading(isFetching, [search, page, sort]);

  useEffect(() => {
    if (data) {
      setPaginationData(data.meta.pagination);
    }
  }, [data]);

  const empty = !data?.data?.length && !isFetching;

  if (empty) {
    return <NoResults query={search} />;
  } else if (error) {
    return <ErrorAllUsersList />;
  }

  return (
    <UsersTabs height="100%">
      <Tabs.TabList>
        <Tabs.Tab id="users"> {t("allUsers.users")}</Tabs.Tab>
      </Tabs.TabList>
      <UserListTabPanel id="users">
        <Table
          columns={columns}
          rows={data?.data}
          showSkeleton={isLoading || isEntitiesFetching}
          highlight={search}
          getRowIcon={(row) => <UserAvatar size="sm" user={row} />}
        />
        <Pagination
          paginationData={paginationData}
          label={t("allUsers.title")}
        />
      </UserListTabPanel>
    </UsersTabs>
  );
};

const UsersTabs = styled(Tabs.Root)`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  box-sizing: border-box;
`;

const UserListTabPanel = styled(Tabs.TabPanel)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
