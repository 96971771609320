import React from "react";
import { useDrag, useDrop } from "react-dnd";

import { Input } from "../Input";
import {
  ChecklistBox,
  TrashCanIconBox,
  TrashCanIconStyled,
  StyledDragKnob,
  IndexBox,
} from "./ChecklistManager.styled";

export const Checklist = ({
  text,
  index,
  moveItem,
  onChange,
  onDelete,
  order,
}) => {
  const [{ isDragging }, ref] = useDrag({
    type: "SORTABLE_ITEM",
    item: { order },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: "SORTABLE_ITEM",
    hover: ({ order: hoverOrder }) => {
      if (order !== hoverOrder) {
        moveItem(hoverOrder, index);
      }
    },
  });

  return (
    <ChecklistBox
      ref={(node) => {
        ref(drop(node));
      }}
      isDragging={isDragging}
    >
      <StyledDragKnob />
      <IndexBox>{`${index + 1}.`}</IndexBox>
      <Input
        placeholder="Input text"
        value={text}
        onChange={(e) => onChange(index, e.target.value)}
        isTextArea
      />
      <TrashCanIconBox>
        <TrashCanIconStyled onClick={() => onDelete(index)} />
      </TrashCanIconBox>
    </ChecklistBox>
  );
};
