import styled, { css } from "styled-components";
import Calendar from "shared/assets/icon/calendar.svg?react";

export const Wrapper = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const Error = styled.div`
  position: absolute;
  color: var(--color_alert);
  font-size: 14px;
  top: calc(100% + 6px);
`;

export const Icon = styled(Calendar)`
  top: 50%;
  transform: translateY(calc(-50% - 1px));
  height: 14px;
  width: 14px;
`;

export const Input = styled.input<{ isInvalid?: boolean }>`
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-left: 12px;
  outline: transparent;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color_grey500);

  .react-datepicker__view-calendar-icon & {
    padding-left: 28px;
  }

  &::placeholder {
    color: var(--color_grey500);
  }

  &:placeholder-shown {
    border-color: var(--color_grey200);
  }

  &:focus {
    box-shadow: 0px 0px 0px 1px var(--color_background_light),
      0px 0px 0px 3px var(--color_primary);

    &,
    &:placeholder-shown {
      border-color: var(--color_grey900);
    }
  }

  &:disabled {
    background-color: var(--color_grey10);

    &,
    &:hover,
    &:placeholder-shown {
      border-color: var(--color_grey100);
    }

    &,
    &::placeholder {
      color: var(--color_grey500);
    }
  }

  ${(p) =>
    p.isInvalid &&
    css`
      &,
      &:placeholder-shown {
        border-width: 2px;
        border-color: var(--color_alert);
      }
    `}
`;
