import { getNameSchema } from "shared/lib/helpers/validation";
import * as Yup from "yup";

export const validationSchema = (t) => {
  const schema = {
    name: getNameSchema(t, { required: true }),
    entities: Yup.array().min(1, t("validation.required")),
  };

  return Yup.object(schema);
};
