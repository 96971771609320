import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { BarChart } from "modules/reporting/components/charts/BarChart";
import { transformCategoricalBarChartData } from "modules/reporting/lib/transformCategoricalBarChartData";

import { BacklogByStatusChartData } from "./types";

interface BacklogByStatusReportChartProps {
  data?: BacklogByStatusChartData[];
  isFetching: boolean;
}

const reportKey = ReportKey.BacklogByStatus;
export const BacklogByStatusReportChart = ({
  data,
  isFetching,
}: BacklogByStatusReportChartProps) => {
  const barConfigs = useBarConfigs();
  const chartData = transformCategoricalBarChartData(barConfigs, data ?? []);

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  return (
    <BarChart
      isLoading={isFetching}
      data={chartData}
      dataAxisKey="label"
      bars={[{ dataKey: "value", name: "Total" }]}
    />
  );
};

function useBarConfigs() {
  const { t } = useTranslation();
  return {
    open: {
      order: 0,
      fill: "var(--color_open)",
      label: t(`reporting.${reportKey}.labels.open`),
    },
    on_hold: {
      order: 1,
      fill: "var(--color_on_hold)",
      label: t(`reporting.${reportKey}.labels.on_hold`),
    },
    in_progress: {
      order: 2,
      fill: "var(--color_in_progress)",
      label: t(`reporting.${reportKey}.labels.in_progress`),
    },
    in_review: {
      order: 3,
      fill: "var(--color_in_review)",
      label: t(`reporting.${reportKey}.labels.in_review`),
    },
    waiting_for_parts: {
      order: 4,
      fill: "var(--color_waiting_for_parts)",
      label: t(`reporting.${reportKey}.labels.waiting_for_parts`),
    },
    waiting_for_cost: {
      order: 5,
      fill: "var(--color_waiting_for_cost)",
      label: t(`reporting.${reportKey}.labels.waiting_for_cost`),
    },
    waiting_for_invoice: {
      order: 6,
      fill: "var(--color_waiting_for_invoice)",
      label: t(`reporting.${reportKey}.labels.waiting_for_invoice`),
    },
  };
}
