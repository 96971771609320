import React from "react";

import { useTranslation } from "react-i18next";

import { Box, Container, Title } from "../styled";

export const ForgotPasswordEmailSent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box id="passwordEmailSent">
        <Title>{t("auth.resetPassword.passwordReset")}</Title>
        <p>{t("auth.resetPassword.emailSentDescription")}</p>
      </Box>
    </Container>
  );
};
