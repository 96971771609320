import {
  useAddPartTypesMutation,
  useGetPartTypeQuery,
  useUpdatePartTypeMutation,
  useGetAdminPartTypesQuery,
} from "modules/parts";
import { useDeletePartTypeMutation } from "modules/parts";
import { AdministrationReference } from "shared/components/AdministrationReference";
import { usePermission } from "app/providers/PermissionsProvider";

const actions = {
  getList: useGetAdminPartTypesQuery,
  getElement: useGetPartTypeQuery,
  addElement: useAddPartTypesMutation,
  updateElement: useUpdatePartTypeMutation,
  deleteElement: useDeletePartTypeMutation,
};

export const PartTypes = () => {
  const { adminSettingsPartTypesPermit } = usePermission();
  return (
    <AdministrationReference
      actions={actions}
      module="part-types"
      hasPermission={adminSettingsPartTypesPermit}
    />
  );
};
