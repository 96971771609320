import styled from "styled-components";
import { Priority } from "./Priority";

const Priorities = [
  {
    key: "none",
    borderColor: "grey400",
    backgroundColor: "priority_background_grey",
  },
  {
    key: "low",
    borderColor: "priority_lime",
    backgroundColor: "priority_background_lime",
  },
  {
    key: "medium",
    borderColor: "priority_yellow",
    backgroundColor: "priority_background_yellow",
  },
  {
    key: "high",
    borderColor: "priority_orange",
    backgroundColor: "priority_background_orange",
  },
  {
    key: "critical",
    borderColor: "priority_red",
    backgroundColor: "priority_background_red",
  },
];

export const PriorityInput = ({ value = "none", onChange }) => (
  <PrioritiesContainer>
    {Priorities.map(({ key, borderColor, backgroundColor }) => (
      <PriorityContainer
        key={key}
        active={value === key}
        onClick={() => onChange(key)}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
      >
        <Priority value={key} onChange={onChange} />
      </PriorityContainer>
    ))}
  </PrioritiesContainer>
);

const PrioritiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 40px;
`;

const PriorityContainer = styled.button`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${({ active, borderColor }) =>
    active
      ? `2px solid var(--color_${borderColor})`
      : "1px solid var(--color_grey200)"};

  border-bottom: ${({ active, borderColor }) =>
    active
      ? `2px solid var(--color_${borderColor})`
      : "1px solid var(--color_grey200)"};
  border-left: ${({ active, borderColor }) =>
    active ? `2px solid var(--color_${borderColor})` : "none"};
  border-right: ${({ active, borderColor }) =>
    active ? `2px solid var(--color_${borderColor})` : "none"};
  background-color: ${({ active, backgroundColor }) =>
    active ? `var(--color_${backgroundColor})` : "white"};
  &:first-child {
    border-left: ${({ active, borderColor }) =>
      active
        ? `2px solid var(--color_${borderColor})`
        : "1px solid var(--color_grey200)"};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-right: ${({ active, borderColor }) =>
      active
        ? `2px solid var(--color_${borderColor})`
        : "1px solid var(--color_grey200)"};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
