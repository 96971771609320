import { Description, H3 } from "../../Typography";
import styled, { css } from "styled-components";
import ApproveIcon from "../icons/approve.svg?url";
import RejectIcon from "../icons/reject.svg?url";

export const Card = ({ permission: { title, description, permissions } }) => {
  return (
    <Box>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ListContainer>
        {permissions.map(({ id, access, text }) => (
          <Item key={id} access={access}>
            {text}
          </Item>
        ))}
      </ListContainer>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  max-width: 380px;
  flex-direction: column;
  background-color: var(--color_grey10);
  padding: 16px;
  border-radius: 4px;
`;

const ListContainer = styled.ul`
  padding-left: 22px;
`;

const Title = styled(H3)`
  color: var(--color_primary_text);
  margin-bottom: 4px;
`;

const Item = styled.li`
  position: relative;
  font-size: 12px;
  margin-bottom: 8px;
  ${({ access }) =>
    access
      ? css`
          &::before {
            content: url(${ApproveIcon});
            position: absolute;
            left: -23px;
            top: 0;
            width: 16px;
            height: 16px;
          }
        `
      : css`
          &::before {
            content: url(${RejectIcon});
            position: absolute;
            left: -23px;
            top: 0;
            width: 16px;
            height: 16px;
          }
        `};
`;
