export const getOptions = ({ timezones, dateFormat, currency }) => {
  const convertValuesToOptions = (values) =>
    values?.map((item) => ({ value: item, label: item }));

  const convertCurrencyValuesToOptions = (values) =>
    values?.map(({ code, name }) => ({
      value: `${code} (${name})`,
      label: `${code} (${name})`,
      common: { currency: code, currency_name: name },
    }));

  const timezonesOptions = convertValuesToOptions(timezones);
  const dateFormatOptions = convertValuesToOptions(dateFormat);
  const currencyOptions = convertCurrencyValuesToOptions(currency);

  return [timezonesOptions, dateFormatOptions, currencyOptions];
};
