import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  color: var(--color_grey900);
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-variant-numeric: tabular-nums;
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;
const Tr = styled.tr``;

const Th = styled.th<{ align?: "right" | "left" }>`
  padding: 4px;
  font-weight: 600;
  text-align: ${(p) => p.align};
`;

const Td = styled.td<{ align?: "right" | "left" }>`
  padding: 4px;
  border-top: 1px solid var(--color_grey100);
  text-align: ${(p) => p.align};
`;

export const ReportWidgetTable = {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
};
