import axios from "axios";

import { useState, useEffect } from "react";

export const useDefineCountry = () => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          setResponse(response?.data);
        })
        .catch((error) => {
          setResponse("US");
        });
    };
    fetchData();
  }, []);

  return { country: response?.country, timezone: response?.timezone };
};
