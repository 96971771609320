import { Box, ButtonsBox, CustomButton } from "./UserDetailsView.styled";

import {
  InvitationCancelButton,
  InvitationResendButton,
} from "../InvitationButtons";
import { useTranslation } from "react-i18next";

export const InvitationHeader = ({ user, onEdit }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ButtonsBox>
        <InvitationResendButton user={user} />
        <InvitationCancelButton user={user} />
      </ButtonsBox>

      <CustomButton variant="secondary" onClick={onEdit}>
        {t("allUsers.editProfile")}
      </CustomButton>
    </Box>
  );
};
