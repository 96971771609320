import { useTranslation } from "react-i18next";
import { Empty } from "shared/components/Empty";
import { usePermission } from "app/providers/PermissionsProvider";
import RequestsIcon from "../../assets/icons/empty.svg?react";
import { ModalLink } from "shared/components/ModalLink";

export const EmptyRequestList = () => {
  const { t } = useTranslation();
  const { requestCreatePermit } = usePermission();

  return (
    <Empty
      icon={<RequestsIcon />}
      heading={t("work_order_requests.empty")}
      description={
        requestCreatePermit && (
          <>
            {t("empty.descriptionUse")}
            <ModalLink modalParams={{ type: "request", id: "new" }}>
              {t("empty.requests")}
            </ModalLink>
            {t("empty.descriptionButton")}
          </>
        )
      }
      spacious
    />
  );
};
