import styled from "styled-components";
import { DonutChart } from "modules/reporting/components/charts/DonutChart";
import { useTranslation } from "react-i18next";
import { DonutChartWidgetLayout } from "modules/reporting/components/widgets/DonutChartWidgetLayout";
import { NoData } from "modules/reporting/components/NoData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";
import { formatPercent } from "../../../lib/formatters";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";

interface WorkOrderCompletionReportWidgetData {
  chart_data: {
    key: string;
    value: number;
  }[];
  other_data: {
    mttr: number;
  };
}

export const WorkOrderCompletionReportWidget = () => {
  const { t } = useTranslation();
  const sliceConfigs = useSliceConfigs();
  const { language } = useLocaleSettings();
  const { reportPeriod } = useReportPageContext();
  const { data, isFetching } =
    useWidgetData<WorkOrderCompletionReportWidgetData>(
      ReportKey.OntimeVsOverdue,
      reportPeriod
    );

  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  let mttr;
  if (typeof data?.other_data?.mttr === "number") {
    mttr = t(`reporting.mttr`, {
      mttr: data.other_data.mttr / 60,
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    });
  }

  let defaults;
  const onTime = getPctOnTime(data?.chart_data ?? []);
  if (onTime) {
    defaults = {
      label: t(`reporting.${ReportKey.OntimeVsOverdue}.labels.on_time`),
      value: formatPercent(onTime, language),
    };
  }

  return (
    <DonutChartWidgetLayout>
      <DonutChart.Root
        data={data?.chart_data ?? []}
        isLoading={isFetching}
        sliceConfigs={sliceConfigs}
      >
        <LegendWrapper>
          <DonutChart.Legend />
          {mttr && (
            <>
              <hr />
              <MttrRow>
                <span>{t(`reporting.mttr_label`)}</span>
                <span>{mttr}</span>
              </MttrRow>
            </>
          )}
        </LegendWrapper>
        <DonutChart.Chart>
          <DonutChart.Label defaultContents={defaults} />
        </DonutChart.Chart>
      </DonutChart.Root>
    </DonutChartWidgetLayout>
  );
};

function useSliceConfigs() {
  const { t } = useTranslation();
  return {
    on_time: {
      fill: "var(--color_mint)",
      label: t(`reporting.${ReportKey.OntimeVsOverdue}.labels.on_time`),
    },
    overdue: {
      fill: "var(--color_salmon)",
      label: t(`reporting.${ReportKey.OntimeVsOverdue}.labels.overdue`),
    },
    no_due_date: {
      fill: "var(--color_light_grey)",
      label: t(`reporting.${ReportKey.OntimeVsOverdue}.labels.no_date`),
    },
  };
}

function getPctOnTime(
  data: { key: string; value: number }[]
): number | undefined {
  const onTime = data.find((i) => i.key === "on_time");
  if (onTime) {
    const total = data.reduce((acc, current) => acc + current.value, 0) ?? 0;
    return onTime.value / total;
  }
  return undefined;
}

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;

  hr {
    width: 100%;
    border-style: solid;
    border-color: var(--color_grey100);
  }
`;

const MttrRow = styled.div`
  display: flex;
  justify-content: space-between;

  color: var(--color_grey700);
  font-size: 12px;
`;
