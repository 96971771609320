import { isAuthenticated, getUser } from "shared/components/Auth";
import { getUrl } from "../apiTools/getUrl";

export const getFullUrl = (url = null) => {
  const user = getUser();
  const tenant = isAuthenticated() && user ? user.tenant : "tenant1";

  if (!url) return "";
  return url.startsWith("/") ? getUrl(tenant, url) : url;
};

export const downloadFile = (url, filename) => {
  const tempLink = document.createElement("a");
  tempLink.href = url;
  tempLink.setAttribute("download", filename);
  tempLink.click();
};
