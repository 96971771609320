import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SettingsList } from "shared/components/SettingsList";
import { PageWrapper } from "shared/components/PageWrapper";
import { AdditionalFieldConfiguration } from "../AdditionalFieldConfiguration";
import {
  Wrapper,
  DescriptionBox,
  Title,
} from "./AdminFieldsConfiguration.styled";

export const AdminFieldsConfiguration = ({
  rows = [],
  handleSetValue,
  values,
  module = "",
  additionalField = "",
  additionalType = "",
  withDescription,
}) => {
  const { t } = useTranslation();

  const onSetVisible = (field) => {
    handleSetValue(field, {
      ...values[field],
      visible: !values[field]?.visible,
      ...(values[field]?.visible ? { required: false } : {}),
    });
  };

  const onSetRequired = (field) => {
    handleSetValue(field, {
      ...values[field],
      required: !values[field]?.required,
    });
  };

  const getIsChecked = (name, field) => values[name]?.[field];

  const getIsVisible = (field) => !values[field]?.visible;

  return (
    <PageWrapper>
      <Wrapper>
        {additionalField && (
          <AdditionalFieldConfiguration
            field={additionalField}
            type={additionalType}
            activated={getIsChecked(additionalField, "required")}
            onSetRequired={onSetRequired}
            withDescription={withDescription}
          />
        )}
        <Title>{t("settings.subtitle")}</Title>
        <DescriptionBox>
          {t("settings.subdescription", { module })}
        </DescriptionBox>
        <SettingsList
          rows={rows}
          onSetVisible={onSetVisible}
          onSetRequired={onSetRequired}
          getIsChecked={getIsChecked}
          getIsVisible={getIsVisible}
        />
      </Wrapper>
    </PageWrapper>
  );
};

AdminFieldsConfiguration.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      visible: PropTypes.bool,
      name: PropTypes.string,
      field: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  handleSetValue: PropTypes.func,
  values: PropTypes.object,
  module: PropTypes.string,
  additionalField: PropTypes.string,
  additionalType: PropTypes.string,
};
