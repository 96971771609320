import { PaginationData } from "types/Response";
import { AssetCostsAndDowntimeTableData } from "modules/reporting/components/reports/AssetCostsAndDowntimeReport/types";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { useTranslation } from "react-i18next";
import { useDisplayDate } from "shared/lib/hooks";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { Table } from "shared/components/Table";
import { Pagination } from "features/Pagination";
import { ReportKey } from "modules/reporting/types/ReportKey";
import {
  formatCurrency,
  formatPercent,
  formatDuration,
} from "modules/reporting/lib/formatters";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { ModalLink } from "shared/components/ModalLink";
import { AssetStatusBadge } from "modules/assets/components/AssetStatusBadge";

const reportKey = ReportKey.AssetCostsAndDowntime;

interface AssetCostsAndDowntimeReportTableProps {
  data?: AssetCostsAndDowntimeTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const AssetCostsAndDowntimeReportTable = ({
  data,
  paginationData,
  isFetching,
}: AssetCostsAndDowntimeReportTableProps) => {
  const { t } = useTranslation();
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  return (
    <>
      <Table
        columns={columns}
        rows={data}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(): TableColumn<AssetCostsAndDowntimeTableData>[] {
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();
  const displayDate = useDisplayDate();
  return [
    {
      field: "name",
      name: t(`assets.columns.name`),
      minWidth: 400,
      sortable: true,
    },
    {
      field: "id",
      name: t(`assets.columns.id`),
      minWidth: 80,
      sortable: true,
    },
    {
      field: "status",
      name: t("assets.columns.status"),
      width: 160,
      sortable: false,
      renderCell: (row) => <AssetStatusBadge status={row.status} />,
    },
    {
      field: "total_uptime_s",
      name: t(`reporting.${reportKey}.columns.online`),
      minWidth: 140,
      sortable: true,
      renderCell: (row) => `${formatDuration(row.total_uptime_s, language)}`,
      type: "number",
    },
    {
      field: "online_pct",
      name: t(`reporting.${reportKey}.columns.online_pct`),
      minWidth: 140,
      sortable: true,
      renderCell: (row) =>
        `${formatPercent(row.online_pct, language, {
          maximumSignificantDigits: 4,
        })}`,
      type: "number",
    },
    {
      field: "total_downtime_s",
      name: t(`reporting.${reportKey}.columns.offline`),
      minWidth: 140,
      sortable: true,
      // renderCell: (row) => `${formatNumber(row.total_downtime_s, language)}`,
      renderCell: (row) => formatDuration(row.total_downtime_s, language),
      type: "number",
    },
    {
      field: "offline_pct",
      name: t(`reporting.${reportKey}.columns.offline_pct`),
      minWidth: 140,
      sortable: true,
      renderCell: (row) =>
        `${formatPercent(row.offline_pct, language, {
          maximumSignificantDigits: 4,
        })}`,
      type: "number",
    },
    {
      field: "cost",
      name: t(`reporting.${reportKey}.columns.cost`),
      minWidth: 200,
      sortable: true,
      renderCell: (row) => `${formatCurrency(row.cost, language, currency)}`,
      type: "number",
    },
    {
      field: "parent",
      name: t(`assets.columns.parent`),
      minWidth: 300,
      sortable: false,
      renderCell: (row) =>
        row.parent ? (
          <ModalLink modalParams={{ type: "asset", id: row?.parent?.id + "" }}>
            {row.parent.name}
          </ModalLink>
        ) : null,
    },
    {
      field: "asset_types",
      name: t(`assets.columns.assetTypes`),
      minWidth: 200,
      sortable: false,
      renderCell: (row) => row.asset_types?.map((t) => t.name).join(", "),
    },
    {
      field: "purchase_cost",
      name: t(`assets.columns.purchaseCost`),
      minWidth: 200,
      sortable: true,
      formatValue: (val) => formatCurrency(val, language, currency),
      type: "number",
    },
    {
      field: "warranty_expiration_date",
      name: t(`assets.columns.warrantyExpDate`),
      minWidth: 200,
      // @ts-ignore: TODO: fix displayDate types
      formatValue: (date) => displayDate(date, false),
      sortable: true,
    },
    {
      field: "location",
      name: t(`assets.columns.location`),
      minWidth: 400,
      sortable: false,
      renderCell: (row) =>
        row.location ? (
          <ModalLink
            modalParams={{ type: "location", id: row?.location?.id + "" }}
          >
            {row.location?.name}
          </ModalLink>
        ) : null,
    },
  ];
}
