import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactStars from "react-rating-stars-component";
import Star from "shared/assets/icon/star.svg?react";

const getColor = (value) => {
  if (value >= 4) return "#a2db00";
  if (value >= 2) return "#ffb82e";
  if (value >= 1) return "#ff786e";
  return "#BFBFC3";
};

export const StarRatings = ({
  name,
  value,
  onChange,
  width = "",
  edit = true,
}) => {
  const color = getColor(value);

  return (
    <Container>
      <ReactStars
        name={name}
        count={5}
        value={value}
        onChange={(v) => {
          onChange(name, v);
        }}
        activeColor={color}
        size={24}
        emptyIcon={<Star fill="none" width={width} />}
        filledIcon={<Star fill={color} width={width} />}
        key={color}
        edit={edit}
      />
    </Container>
  );
};

StarRatings.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  width: PropTypes.string,
  edit: PropTypes.bool,
};

const Container = styled.div`
  & .react-stars {
    display: flex;
    gap: 4px;

    span {
      display: inline-flex !important;
      float: unset !important;
    }
  }
`;
