import { useTranslation } from "react-i18next";
import { Header } from "widgets/Header";
import { Container } from "shared/components/Container";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { usePermission } from "app/providers/PermissionsProvider";
import { ModalLink } from "shared/components/ModalLink";
import { useSetModal } from "widgets/ModalController";
import { List as VendorsList } from "modules/vendors/components/List/List";

export const List = () => {
  const { t } = useTranslation();
  const { vendorCreatePermit, vendorsListPermit, vendorsListExportPermit } =
    usePermission();
  const setModal = useSetModal();

  return (
    <TablePageLayout>
      {vendorsListPermit && (
        <>
          <Header
            title={t("vendors.title_other")}
            searchPlaceholder={t("vendors.searchPlaceholder")}
            action={
              vendorCreatePermit && (
                <ModalLink
                  type="button"
                  icon={<AddIcon />}
                  modalParams={{ type: "vendor", id: "new" }}
                >
                  {t("vendors.title_one")}
                </ModalLink>
              )
            }
            horizontallySpaciousPage
          />
          <Container>
            <VendorsList
              withExport={vendorsListExportPermit}
              onOpen={(id) => setModal({ type: "vendor", id })}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
