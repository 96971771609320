import React, { useState } from "react";

import EditIcon from "shared/assets/icon/edit.svg?react";
import { formatCurrency } from "shared/lib/helpers/common";

import {
  Cell,
  GeneralCell,
  Box,
  StyledIconButton,
  Container,
  Note,
  WrapperFileManager,
} from "./WorkOrderOtherCostView.styled";
import { WorkOrderOtherCostUpdate } from "../WorkOrderOtherCostUpdate";
import { useGetLocaleSettings } from "shared/lib/hooks";

export const WorkOrderOtherCostView = ({
  value = {},
  onUpdate,
  onDelete,
  index,
  permissions,
  withImageZoom = true,
  withFileDownload = true,
}) => {
  const {
    id,
    title,
    note,
    vendor,
    cost,
    images,
    documents,
    uploads_attributes,
  } = value;
  const { currency } = useGetLocaleSettings();

  const files = uploads_attributes
    ? uploads_attributes
    : images.map((file) => ({ ...file, isImage: true })).concat(documents);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const openEditModal = () => setIsShowEditModal(true);
  const closeEditModal = () => setIsShowEditModal(false);

  const vendorName = vendor?.label || vendor?.name || "";
  const costValue = formatCurrency(cost, currency);
  return (
    <Box>
      <Container>
        <Cell>{title}</Cell>
        <Note>{note}</Note>
        <WrapperFileManager
          value={files}
          imageListClassMame="imageList"
          withZoom={withImageZoom}
          withFileDownload={withFileDownload}
        />
      </Container>
      <GeneralCell>{vendorName}</GeneralCell>
      <GeneralCell>{costValue}</GeneralCell>
      <StyledIconButton
        onClick={openEditModal}
        className="actionIcon"
        disabled={!permissions?.edit}
      >
        <EditIcon />
      </StyledIconButton>
      {isShowEditModal && (
        <WorkOrderOtherCostUpdate
          id={id}
          index={index}
          data={value}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onClose={closeEditModal}
          permissions={permissions}
        />
      )}
    </Box>
  );
};
