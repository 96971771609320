import { TextLink } from "../TextLink";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface PrivacyPolicyProps {
  preamble?: React.ReactNode;
}

export const PrivacyPolicy = ({ preamble }: PrivacyPolicyProps) => {
  const { t } = useTranslation();
  return (
    <>
      {preamble}{" "}
      <CustomTextLink
        as="a"
        href={"https://www.clickmaint.com/terms-of-service"}
        target={"_blank"}
      >
        {t("privacyPolicy.termsOfService")}
      </CustomTextLink>{" "}
      {t("privacyPolicy.and")}{" "}
      <CustomTextLink
        as="a"
        href={"https://www.clickmaint.com/privacy-policy"}
        target={"_blank"}
      >
        {t("privacyPolicy.privacyPolicy")}
      </CustomTextLink>
    </>
  );
};

const CustomTextLink = styled(TextLink)`
  text-decoration: none;
  font-size: inherit;
`;
