import React from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import styled from "styled-components";

import { usePermission } from "app/providers/PermissionsProvider";

import { useGetRequestQuery } from "modules/requests";

import { ViewModal } from "shared/components/Modals";
import { useVisibleFields } from "modules/adminFieldsConfiguration/lib/hooks";
import { useDisplayDate } from "shared/lib/hooks";
import { Divider } from "shared/components/Divider";

import { FileManager } from "modules/fileManager";
import { CommentsList } from "modules/comments";
import { Description, H2, Hint } from "shared/components/Typography";
import { VendorsList } from "shared/components/VendorsList";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { Priority } from "shared/components/Priority";

import { RequestHeader } from "./RequestHeader";

import { Status } from "../Status";
import { RequestRequestedBy } from "./RequestRequestedBy";
import { Details } from "widgets/Details";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLink } from "shared/components/ModalLink";

import { heading } from "@test-data";

const fields = [
  "priority",
  "location",
  "asset",
  "categories",
  "due_date",
  "assign_to",
  "vendors",
  "created_by",
];

export const RequestView = ({
  isShow,
  onClose,
  onEdit,
  onApprove,
  requestId,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    assetViewPermit,
    worCommentListPermit,
    worCreateCommentPermit,
    worUpdateCommentPermit,
    worDeleteCommentPermit,
  } = usePermission();

  const { currentData: data, error } = useGetRequestQuery(
    requestId ?? skipToken
  );

  const { isFieldVisible, areVisibleFieldsLoading } =
    useVisibleFields("work_order_request");

  if (!requestId) {
    return null;
  }

  /* // TODO: Add a loader or a skeleton */
  if (areVisibleFieldsLoading || !data) return null;

  if (error) {
    return <div>Error request</div>;
  }

  const {
    title: name,
    id,
    description,
    status,
    due_date,
    priority,
    images = [],
    documents = [],
    updated_at,
    notify,
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // teams = [],
    technicians = [],
    created_by,
    created_by_id,
    comments,
    work_order_id,
  } = data;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const rows = fields.reduce(
    (visibleRows, field) => {
      if (!isFieldVisible(field)) {
        return visibleRows;
      }

      const value = data[field];

      if (field === "vendors") {
        visibleRows.push({
          label: t("work_order_requests.labels.vendors"),
          value: <VendorsList vendors={value} />,
          multiline: true,
        });
      }
      if (field === "categories") {
        visibleRows.push({
          label: t("work_orders.details.categories"),
          value: value.map(({ name }) => name).join(", "),
          multiline: true,
        });
        return visibleRows;
      }

      if (field === "due_date") {
        visibleRows.push({
          label: t("work_order_requests.labels.due_date"),
          value: displayDate(value, true),
        });
      }

      if (field === "priority") {
        visibleRows.push({
          label: t("work_order_requests.labels.priority"),
          value: <Priority value={value} />,
        });
      }

      if (field === "location") {
        visibleRows.push({
          label: t("work_order_requests.labels.location"),
          value: <LocationPath location={value} withTrim={false} />,
        });
      }
      if (field === "asset") {
        visibleRows.push({
          label: t("work_order_requests.labels.asset"),
          value:
            value &&
            (assetViewPermit ? (
              <ModalLink modalParams={{ type: "asset", id: value.id }}>
                {value.name}
              </ModalLink>
            ) : (
              value.name
            )),
        });
      }

      if (field === "assign_to") {
        visibleRows.push({
          label: t("work_order_requests.labels.assign"),
          value: <UserAvatarGroup users={technicians} />,
        });
      }

      if (field === "created_by") {
        visibleRows.push({
          label: t("work_order_requests.labels.createdBy"),
          value: created_by ? <RequestRequestedBy requestData={data} /> : null,
        });
      }

      return visibleRows;
    },
    [
      {
        label: t(`work_order_requests.labels.notifications`),
        value: t(`work_order_requests.details.notify.${notify}`),
      },
      {
        label: t(`work_order_requests.labels.status`),
        value: <Status value={status} />,
      },
    ]
  );

  return (
    <ViewModal
      isOpen={isShow}
      onClose={onClose}
      label={t("work_order_requests.name", { name })}
      subHeader={
        <RequestHeader
          id={id}
          name={name}
          onClose={onClose}
          onEdit={onEdit}
          onApprove={onApprove}
          status={status}
          createdById={created_by_id}
          woId={work_order_id}
        />
      }
    >
      <RequestViewHeader>
        <RequestInfo data-testid={heading.viewModalHint.id}>
          <Hint>
            #{id}
            {due_date && `:${displayDate(due_date)}`}
          </Hint>
          {isFieldVisible("priority") && <Priority value={priority} />}
        </RequestInfo>
        <RequestName data-testid="view-modal-title">{name}</RequestName>
        {isFieldVisible("description") && (
          <Description data-testid="view-modal-description">
            {description}
          </Description>
        )}
        <WrapperFileManager value={files} />
      </RequestViewHeader>
      <Details date={updated_at} rows={rows} />
      <DividerStyled />
      {worCommentListPermit && (
        <CommentsContainer>
          <CommentsTitle>
            {t("work_orders.tabs.comments", {
              count: comments.length > 0 ? `(${comments.length})` : "",
            })}
          </CommentsTitle>
          <CommentsList
            value={comments}
            type="WorkOrderRequest"
            targetId={requestId}
            permissions={{
              create: worCreateCommentPermit,
              update: worUpdateCommentPermit,
              delete: worDeleteCommentPermit,
            }}
          />
        </CommentsContainer>
      )}
    </ViewModal>
  );
};

const RequestViewHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color_grey100);
  margin-bottom: 20px;
`;

const RequestInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RequestName = styled(H2)`
  margin-bottom: 20px;
`;

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;

const CommentsTitle = styled.span`
  font-weight: 600;
  line-height: 18px;
  color: var(--color_text_color);
`;

const CommentsContainer = styled.div`
  margin-top: 30px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 32px;
`;
