import React from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import {
  useUpdateDowntimeTrackingSettingMutation,
  useGetDowntimeTrackingSettingsQuery,
} from "modules/downtimeTracking";
import { usePermission } from "app/providers/PermissionsProvider";

const AssetAdminConfigurationFields = [
  "enabled",
  "location",
  "asset_types",
  "categories",
  "model",
  "manufacturer",
  "serial_number",
  "purchase_date",
  "qr_code",
  "parent",
  "assign_to",
  "vendors",
  "parts",
  "urls",
  "warranty_exp_date",
  "life_expectancy",
  "purchase_cost",
  "replacement_cost",
  "salvage_value",
];

export const AssetsAdminSettings = () => {
  const { adminSettingsAssetPermit } = usePermission();
  const { data } = useGetAdminConfigurationQuery({ type: "asset" });
  const { data: downtimeTrackingSettings } =
    useGetDowntimeTrackingSettingsQuery({
      targetType: "asset",
    });
  const [updateAdminConfiguration] = useUpdateDowntimeTrackingSettingMutation();

  if (!data || !data?.data) return null;

  const assetData = {
    ...data?.data?.payload,
    enabled: {
      required: downtimeTrackingSettings?.enabled,
    },
  };

  const currentConfiguration = getConfigurationFields(
    AssetAdminConfigurationFields,
    assetData
  );

  const updateDowntimeTrackingSettings = (data) => {
    updateAdminConfiguration({
      targetType: "asset",
      body: { enabled: data?.enabled?.required },
    });
  };

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="asset"
      additionalType="track_downtime"
      additionalField="enabled"
      updateAddFieldConfiguration={updateDowntimeTrackingSettings}
      withDescription={false}
      hasPermission={adminSettingsAssetPermit}
    />
  );
};
