import React, { useEffect, useMemo } from "react";
import {
  getEntityFormatForService,
  getInitialValues,
} from "modules/entities/lib/helpers/form";
import { useTranslation } from "react-i18next";
import { LightModal } from "shared/components/Modals/LightModal";
import { useForm } from "shared/lib/hooks/useForm";

import { EntityEditor } from "../EntityEditor";
import { entityValidationSchema } from "modules/entities/config/validationSchema";
import { useDefineCountry } from "shared/lib/hooks/useDefineCountry";
import { useLazyGetTimeZonesQuery } from "modules/internalCollections";
import { useAddEntityMutation } from "modules/entities/state/entitiesApi";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

export const CreateEntity = ({ isShow, onCloseModal }) => {
  const { t } = useTranslation();

  const { timezone } = useDefineCountry();
  const [trigger, { data: timezones }] = useLazyGetTimeZonesQuery();
  const [addEntity] = useAddEntityMutation();
  const { addSnackBar } = useSnackBar();

  useEffect(() => {
    if (isShow) {
      trigger();
    }
  }, [isShow, trigger]);

  const defineTimezone = useMemo(() => {
    const result = timezones?.find((item) => item.includes(timezone));
    return { label: result, value: result };
  }, [timezones, timezone]);

  const entityDetails = useMemo(
    () => getInitialValues({ type: "create", currentTimezone: defineTimezone }),
    [defineTimezone]
  );
  const onClose = () => {
    entityForm.resetForm();
    onCloseModal();
  };

  const { setFieldValue, ...entityForm } = useForm({
    initialValues: entityDetails,
    validationSchema: entityValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const entity = getEntityFormatForService(body);

      addEntity({ body: entity })
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            setFieldError("name", t("m144", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("allUsers.snack.failSentInvite"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  return (
    <LightModal
      isOpen={isShow}
      onClose={onClose}
      label={t("generalSettings.addEntity")}
      onSubmit={entityForm.handleSubmit}
      disabledSubmit={!entityForm.isWasChanged || !entityForm.isFormValid}
    >
      <EntityEditor form={entityForm} timezones={timezones} />
    </LightModal>
  );
};
