import { useState, useRef } from "react";

export function useFetch() {
  const abortControllerRef = useRef(new AbortController());

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleAbort = () => {
    return abortControllerRef.current.abort();
  };
  const query = async (url) => {
    setErrorMessage(null);
    setLoading(true);
    try {
      const response = await fetch(url, {
        signal: abortControllerRef.current.signal,
      });
      let readFile = null;
      const reader = response.body.getReader();
      const contentLength = +response.headers.get("Content-Length");
      let receivedLength = 0;
      let chunks = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setLoading(false);
          if (chunks.length) {
            const blob = new Blob(chunks);
            readFile = new File([blob], "filename");
          }
          break;
        }
        chunks.push(value);
        receivedLength += value.length;
        const percent = Math.floor((receivedLength / contentLength) * 100);
        if (percent % 2 === 0 && percent !== 0 && progress !== percent) {
          setProgress(percent);
        }
      }
      return readFile;
    } catch (err) {
      setLoading(false);
      if (err.name === "AbortError") {
        setErrorMessage("Fetch abort");
        abortControllerRef.current = new AbortController();
      } else {
        setErrorMessage(err?.message);
      }
    }
  };
  return {
    fetch: query,
    fetchAbort: handleAbort,
    isLoading: loading,
    progress,
    error: errorMessage,
  };
}
