import React from "react";
import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";

export const RowButton = ({ value, onClick, icon, visible }) => {
  return (
    <RowCell>
      <Value>{value}</Value>
      {visible && <IconButtonStyled onClick={onClick}>{icon}</IconButtonStyled>}
    </RowCell>
  );
};

const RowCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Value = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 1lh;
`;

const IconButtonStyled = styled(IconButton)`
  margin-left: 16px;
`;
