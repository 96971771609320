import { useCallback, useDebugValue } from "react";
import { startOfDay } from "date-fns";
import {
  fnsDateFormat,
  getTimezoneDateFormats,
  convertTimeToEntityTimezone,
} from "../helpers";

export const useDisplayDate = (
  defaultTimeZone = null,
  defaultDateFormat = null
) => {
  useDebugValue("useDisplayDate");
  const {
    timezone, // entity timezone
    onlyDateFormat,
    dateTimeFormat,
  } = getTimezoneDateFormats(defaultTimeZone, defaultDateFormat);

  return useCallback(
    (value, timeIsRequired = false, formatted = true, skipTimezone = false) => {
      if (!value) return "";

      const entityDate = skipTimezone
        ? new Date(value)
        : convertTimeToEntityTimezone(value, defaultTimeZone);

      let dateString = "";

      timeIsRequired
        ? (dateString = fnsDateFormat(entityDate, dateTimeFormat)) // return entity date + time
        : (dateString = fnsDateFormat(
            startOfDay(entityDate), //
            onlyDateFormat
          )); // return only entity date

      return formatted ? dateString : entityDate;
    },
    [timezone, onlyDateFormat, dateTimeFormat]
  );
};
