import i18n from "i18next";

export const InitialValues = {
  name: "",
  description: "",
  address: "",
  currency: {
    label: i18n.t("generalSettings.entity.defaultCurrency"),
    value: i18n.t("generalSettings.entity.defaultCurrency"),
    common: { currency: "USD", currency_name: "United States Dollar" },
  },
  datetime_format: {
    label: "MM/DD/YYYY - 11:59 PM",
    value: "MM/DD/YYYY - 11:59 PM",
  },
  timezone: "",
};

export const entityFields = Object.keys(InitialValues);
